(function() {
  'use strict';

  angular
    .module('app.work')
    .controller('WorkController', WorkController);

  WorkController.$inject = [
    'helpers',
    '$scope',
    'workDataService',
    'workService'
  ];

  function WorkController( helpers, $scope, workDataService, workService ) {
    let vm = this;

    vm.deleteWork = deleteWork;
    vm.isLoading = true;
    vm.getWorks = getWorks;
    vm.lengthOptions = workService.getLengthOptions();
    vm.numberPerPages = 20;
    vm.refreshTable = refreshTable;
    vm.statusOptions = [
      { id: 'DOWNLOADED', name: 'Downloaded' },
      { id: 'APPROVED', name: 'Approved' },
      { id: 'DELETE', name: 'Delete' },
      { id: 'START_UPLOAD', name: 'Start Upload' },
      { id: 'UPLOADED', name: 'Uploaded' },
    ];

    init();

    function init() {}

    function deleteWork( entityId ) {
      vm.isLoading = true;

      workDataService.deleteWork( entityId ).then( function( response ) {
        if(response.status === 204 ) {
          $scope.$broadcast('refreshTable');
        }
        else {
            vm.isLoading = false;
            $scope.showNotification('The action could not be performed successfully', 'error');
        }
      })
    }

    function refreshTable() {
      $scope.$broadcast( 'refreshTable' );
    }

    function getWorks( tableState ) {
      vm.tableIsLoading = true;
      let params = helpers.buildParams(tableState, vm.numberPerPages);

      params[`filters[refId]`] = 0;
      workDataService.getWorks( { params: params } ).then( function( response ) {
        if( response.status === 200 ) {
          const total = parseInt( response.data.total );
          vm.data = response.data.data;
          tableState.pagination.numberOfPages = helpers.calculateNumberPages( total, vm.numberPerPages );
          tableState.pagination.totalItemCount = total;
          vm.tableIsLoading = false;
          vm.isLoading = false;
        }
        else {
            vm.isLoading = false;
            $scope.showNotification('The action could not be performed successfully', 'error');
        }
      } );
    }
  }
})();
