(function() {
  'use strict';

  angular
    .module('app')
    .service('lovService', lovService);

  lovService.$inject = [];

  function lovService() {

    return {
      getDetailLengthOptions: getDetailLengthOptions,
      getLengthOptions: getLengthOptions
    };

    function getLengthOptions() {
      return [
        { value: 20, label: 20 },
        { value: 40, label: 40 },
        { value: 60, label: 60 },
        { value: 100000, label: 'All' }
      ]
    }

    function getDetailLengthOptions() {
      return [
        { value: 10, label: 10 },
        { value: 20, label: 20 },
        { value: 30, label: 30 },
        { value: 30, label: 40 },
        { value: 100000, label: 'All' }
      ]
    }
  }
})();
