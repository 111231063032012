( function() {
  'use strict';

  angular
    .module( 'app.reports', [] )
    .config( config );

  config.$inject = [
    '$stateProvider',
    '$urlRouterProvider'
  ];

  function config( $stateProvider, $urlRouterProvider ) {
    $stateProvider
      .state( 'app.reports', {
        url: '/reports',
        views: {
          'content': {
            templateUrl: 'templates/reports.html',
            controller: 'ReportsController as dashboard'
          }
        },
        ncyBreadcrumb: {
          label: 'home'
        }
      } );
  }
} )();
