(function() {
  'use strict';

  angular
    .module( 'app.lovMeter' )
    .controller( 'LovMeterDetailController', LovMeterDetailController );

  LovMeterDetailController.$inject = [
    'entityId',
    'lovMeterDataService',
    '$uibModalInstance'
  ];

  function LovMeterDetailController( entityId, lovMeterDataService, $uibModalInstance ) {
    let vm = this;

    vm.cancel = cancel;
    vm.isLoading = true;

    init();

    function init() {
      getLovMeter();
    }

    function getLovMeter() {
      lovMeterDataService.getLovMeter( entityId ).then( function( response ) {
        if( response.status === 200 ) {
          vm.entity = response.data;
          vm.isLoading = false;
        }
        else {
            vm.isLoading = false;
        }
      } );
    }

    function cancel() {
      $uibModalInstance.dismiss( 'cancel' );
    }
  }
})();
