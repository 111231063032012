( function() {
  'use strict';

  angular
    .module( 'app' )
    .controller( 'MainController', MainController );

  MainController.$inject = [
    '$scope',
    '$state',
    '$timeout',
    '$translate',
    'webStorageService'
  ];

  function MainController($scope, $state, $timeout, $translate, webStorageService) {
    $scope.app = {
      name: 'Reactor',
      year: (new Date()).getFullYear(),
      layout: {
        isSmallSidebar: false,
        isChatOpen: false,
        isFixedHeader: true,
        isFixedFooter: false,
        isBoxed: false,
        isStaticSidebar: false,
        isRightSidebar: false,
        isOffscreenOpen: false,
        isConversationOpen: false,
        isQuickLaunch: false,
        sidebarTheme: '',
        headerTheme: ''
      },
      isMessageOpen: false,
      isContactOpen: false,
      isConfigOpen: false
    };
    $scope.mobileView = 767;
    $scope.alerts = [];

    $scope.user = webStorageService.getObject('session')._userName;
    const roles = webStorageService.getObject('session').roles;
    if( roles ) {
      $scope.isAdmin = roles.indexOf( 'ROLE_ADMIN' ) !== -1;
      if( !$scope.isAdmin ) $scope.isSupervisor = roles.indexOf( 'ROLE_SUPERVISOR' ) !== -1;
      if( !$scope.isAdmin && !$scope.isSupervisor ) $scope.isApi = roles.indexOf( 'ROLE_API' ) !== -1;
    }

    $scope.$on('$viewContentLoaded', function() {
      angular.element('.pageload').fadeOut(1500);
      $timeout(function() {
        angular.element('body').removeClass('page-loading');
      }, 2000);
    });

    $scope.logout = function() {
      webStorageService.removeItem('session');
      $state.go('login')
    };

    $scope.searchFocus = false;
    $scope.focusOn = function() {
      $scope.searchFocus = true;
    };
    $scope.focusOff = function() {
      $timeout(function() {
        $scope.searchFocus = false;
      }, 100);
    };

    $scope.showNotification = function (msg, type) {
      noty({
        theme: 'app-noty',
        text: msg,
        type: type || 'success',
        timeout: 3000,
        layout: 'topLeft',
        closeWith: ['button', 'click'],
        animation: {
          open: 'in',
          close: 'out'
        }
      });
    };

    $scope.langKey = webStorageService.get('language') || $translate.proposedLanguage();
    $scope.setLang = function( langKey ) {
      $scope.langKey = langKey;
      webStorageService.set('language', langKey);
      $translate.use(langKey);
    }
  }
} )();
