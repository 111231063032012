(function() {
  'use strict';

  angular
    .module('app')
    .service('valueDataService', valueDataService);

  valueDataService.$inject = [
    'config',
    '$http'
  ];

  function valueDataService( config, $http ) {

    return {
      deleteValue: deleteValue,
      getValue: getValue,
      saveValue: saveValue,
      updateValue: updateValue,
    };

    function deleteValue( entityId ) {
      return $http.delete(`${config.endpoints.value}/${entityId}`)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function getValue( entityId ) {
      return $http.get(`${config.endpoints.value}/${entityId}`)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function saveValue( data ) {
      return $http.post (`${config.endpoints.value}`, data)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function updateValue( entityId, data ) {
      return $http.put (`${config.endpoints.value}/${entityId}`, data)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }
  }
})();
