(function() {
  'use strict';

  angular
    .module('app')
    .service('lovDataService', lovDataService);

  lovDataService.$inject = [
    'config',
    '$http'
  ];

  function lovDataService( config, $http ) {

    return {
      deleteLov: deleteLov,
      getLov: getLov,
      getLovs: getLovs,
      saveLov: saveLov,
      updateLov: updateLov
    };

    function deleteLov( entityId ) {
      return $http.delete(`${config.endpoints.lov}/${entityId}`)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function getLov( entityId ) {
      return $http.get(`${config.endpoints.lov}/${entityId}`)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function getLovs( params ) {
      return $http.get(config.endpoints.lov, params)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function saveLov( data ) {
      return $http.post(`${config.endpoints.lov}`, data)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function updateLov( entityId, data ) {
      return $http.put(`${config.endpoints.lov}/${entityId}`, data)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }
  }
})();
