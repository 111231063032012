(function() {
  'use strict';

  angular
    .module('app.user')
    .controller('UserController', UserController);

  UserController.$inject = [
    'config',
    'helpers',
    '$scope',
    '$uibModal',
    'userDataService',
    'userService',
    'SweetAlert',
    '$translate'
  ];

  function UserController( config, helpers, $scope, $uibModal, userDataService, userService, SweetAlert, $translate ) {
    let vm = this;

    vm.deleteUser = deleteUser;
    vm.showDeleteModal = showDeleteModal;
    vm.getUsers = getUsers;
    vm.isLoading = true;
    vm.lengthOptions = userService.getLengthOptions();
    vm.numberPerPages = 20;
    vm.openDetailModal = openDetailModal;

    init();

    function init() {
      $translate( ['tableDeleteConfirmation', 'enableMessage', 'disableMessage', 'user'] )
        .then(function( translations ) {
          vm.enableMessage = translations.enableMessage;
          vm.disableMessage = translations.disableMessage;
          vm.tableDeleteConfirmation = translations.tableDeleteConfirmation;
          vm.label = translations.user;
        });
    }

    function showDeleteModal(entity) {
      SweetAlert.swal({
        title: vm.tableDeleteConfirmation,
        text: `${entity.enabled ? vm.disableMessage : vm.enableMessage} ${vm.label}: ${entity.name}?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: config.colors.danger,
        confirmButtonText: vm.tableDeleteConfirmButton,
        cancelButtonText: vm.tableDeleteCancelButton,
        closeOnConfirm: true,
        closeOnCancel: true
      }, function( isConfirm ) {
        if( isConfirm ) {
          entity.enabled ? deleteUser(entity.id) : enableUser(entity.id)
        }
      });
    }

    function enableUser( entityId ) {
      userDataService.partialUpdateUser( entityId, { enabled: true } ).then( function( response ) {
        if( response.status === 200 ) {
          $scope.showNotification('This user has been enabled');
          $scope.$broadcast('refreshTable');
        }
        else {
          $scope.showNotification('The action could not be performed successfully', 'error');
        }
      });
    }

    function deleteUser( entityId ) {
      userDataService.deleteUser(entityId).then(function( response ) {
        if( response.status === 204 ) {
          $scope.showNotification('This user has been disabled');
          $scope.$broadcast('refreshTable');
        }
        else {
            $scope.showNotification('The action could not be performed successfully', 'error');
        }
      });
    }

    function getUsers( tableState ) {
      vm.tableIsLoading = true;
      let params = helpers.buildParams(tableState, vm.numberPerPages);

      userDataService.getUsers({ params: Object.assign({ count: 1 }, params) })
        .then(function( response ) {
          if( response.status === 201 ) {
            const total = parseInt(response.data);
            tableState.pagination.numberOfPages = helpers.calculateNumberPages(total, vm.numberPerPages);
            tableState.pagination.totalItemCount = total;

            userDataService.getUsers({ params: params }).then(function( response ) {
              if( response.status === 201 ) {
                vm.data = response.data;
                vm.tableIsLoading = false;
                vm.isLoading = false;
              }
              else {
                  vm.isLoading = false;
                  $scope.showNotification('The data could not be loaded', 'error');
              }
            });
          }
          else {
              vm.isLoading = false;
              $scope.showNotification('The data could not be loaded', 'error');
          }
        });
    }

    function openDetailModal( user ) {
      $uibModal.open({
        templateUrl: 'templates/userDetail.html',
        controller: 'UserDetailController',
        controllerAs: 'userDetail',
        size: 'md',
        resolve: {
          entity: function() {
            return user;
          }
        }
      });
    }
  }
})();
