(function() {
  'use strict';

  angular
    .module('app')
    .service('dependentDataService', dependentDataService);

  dependentDataService.$inject = [
    'config',
    '$http'
  ];

  function dependentDataService( config, $http ) {

    return {
      deleteDependent: deleteDependent,
      saveDependent: saveDependent
    };

    function saveDependent( data ) {
      return $http.post(`${config.endpoints.dependent}`, data)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function deleteDependent( id ) {
      return $http.delete(`${config.endpoints.dependent}/${id}`)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }
  }
})();
