(function() {
  'use strict';

  angular
    .module('app')
    .service('workService', workService);

  workService.$inject = [];

  function workService() {

    return {
      getLengthOptions: getLengthOptions,
      getTreeTpl: getTreeTpl
    };

    function getLengthOptions() {
      return [
        { value: 20, label: 20 },
        { value: 40, label: 40 },
        { value: 60, label: 60 },
        { value: 100000, label: 'All' }
      ]
    }

    function getTreeTpl() {
      return ['<div class="ivh-treeview-node-content" title="{{trvw.label(node)}}">',
        '<span ivh-treeview-toggle>',
        '<span class="ivh-treeview-twistie-wrapper" ivh-treeview-twistie></span>',
        '</span>',
        '</span>',
        `<span class="ivh-treeview-node-label" data-ng-class="{'color-primary': node.selected}" data-ng-click="$emit('trvwClick', node)">`,
        '{{trvw.label(node)}}',
        '</span>',
        '<div ivh-treeview-children></div>',
        '</div>'
      ].join('\n');
    }
  }
})();
