(function() {
  'use strict';

  angular
    .module('app.settings')
    .controller('SettingsController', SettingsController);

  SettingsController.$inject = [
    '$scope',
    'settingsDataService',
    '$stateParams'
  ];

  function SettingsController( $scope, settingsDataService, $stateParams ) {
    let vm = this;

    vm.isLoading = true;
    vm.onSubmit = onSubmit;

    init();

    function init() {
      getSettings($stateParams.id)
    }

    function getSettings( entityId ) {
      settingsDataService.getSettings(entityId).then(function( response ) {
        if( response.status === 200 ) {
          vm.entity = response.data;
            vm.isLoading = false;
        }
        else {
            vm.isLoading = false;
            $scope.showNotification('The data could not be loaded', 'error');
        }
      })
    }

    function onSubmit( form ) {
      if( form.$valid ) {
        vm.isLoading = true;
        settingsDataService.updateSettings(vm.entity.id, vm.entity).then(function( response ) {
          if( response.status === 200 ) {
            vm.isLoading = false;
            $scope.showNotification('Data Saved');
          } else {
              vm.isLoading = false;
              $scope.showNotification('The action could not be performed successfully', 'error');
          }
        })
      }
    }
  }
})();
