/*
 * GoJS v1.6.16 JavaScript Library for HTML Diagrams
 * Northwoods Software, https://www.nwoods.com/
 * GoJS and Northwoods Software are registered trademarks of Northwoods Software Corporation.
 * Copyright (C) 1998-2016 by Northwoods Software Corporation.  All Rights Reserved.
 * THIS SOFTWARE IS LICENSED.  THE LICENSE AGREEMENT IS AT: https://gojs.net/1.6.16/doc/license.html.
 */
(function(window) { var g,ea={};if(!window.document||void 0===window.document.createElement("canvas").getContext)throw window.console&&window.console.log("The HTML Canvas element is not supported in this browser,or this browser is in Compatibility mode."),Error("The HTML Canvas element is not supported in this browser,or this browser is in Compatibility mode.");if(!Object.defineProperty)throw Error("GoJS requires a newer version of JavaScript");
Function.prototype.bind||(Function.prototype.bind=function(a){function b(){return f.apply(a,e.concat(d.call(arguments)))}function c(){}var d=Array.prototype.slice,e=d.call(arguments,1),f=this;c.prototype=this.prototype;b.prototype=new c;return b});
(function(){for(var a=0,b=["ms","moz","webkit","o"],c=0;c<b.length&&!window.requestAnimationFrame;++c)window.requestAnimationFrame=window[b[c]+"RequestAnimationFrame"],window.cancelAnimationFrame=window[b[c]+"CancelAnimationFrame"]||window[b[c]+"CancelRequestAnimationFrame"];window.requestAnimationFrame||(window.requestAnimationFrame=function(b){var c=(new Date).getTime(),f=Math.max(8,16-(c-a)),h=window.setTimeout(function(){b(c+f)},f);a=c+f;return h});window.cancelAnimationFrame||(window.cancelAnimationFrame=
function(a){window.clearTimeout(a)})})();ea.Debug=null;
var v={ed:1,Nc:2,Xc:4,Wc:8,Sm:void 0!==window.navigator&&0<window.navigator.userAgent.indexOf("534.30")&&0<window.navigator.userAgent.indexOf("Android"),qH:void 0!==window.navigator&&0<window.navigator.userAgent.indexOf("MSIE 10.0"),rH:void 0!==window.navigator&&0<window.navigator.userAgent.indexOf("Trident/7"),TJ:void 0!==window.navigator&&0<window.navigator.userAgent.indexOf("Edge/"),ak:void 0!==window.navigator&&void 0!==window.navigator.platform&&0<=window.navigator.platform.toUpperCase().indexOf("MAC"),
fE:void 0!==window.navigator&&void 0!==window.navigator.platform&&null!==window.navigator.platform.match(/(iPhone|iPod|iPad)/i),yD:function(a,b,c){var d=-1;return function(){var e=this,f=arguments;-1!==d&&v.clearTimeout(d);d=v.setTimeout(function(){d=-1;c||a.apply(e,f)},b);c&&!d&&a.apply(e,f)}},setTimeout:function(a,b){return window.setTimeout(a,b)},clearTimeout:function(a){window.clearTimeout(a)},createElement:function(a){return window.document.createElement(a)},k:function(a){throw Error(a);},ka:function(a,
b){var c="The object is frozen, so its properties cannot be set: "+a.toString();void 0!==b&&(c+="  to value: "+b);v.k(c)},A:function(a,b,c,d){a instanceof b||(c=v.getTypeName(c),void 0!==d&&(c+="."+d),v.Ed(a,b,c))},j:function(a,b,c,d){typeof a!==b&&(c=v.getTypeName(c),void 0!==d&&(c+="."+d),v.Ed(a,b,c))},Ue:function(a,b,c){"number"===typeof a&&isFinite(a)||(b=v.getTypeName(b),void 0!==c&&(b+="."+c),v.k(b+" must be a real number type, and not NaN or Infinity: "+a))},pb:function(a,b,c,d){a instanceof
fa&&a.pe===b||(c=v.getTypeName(c),void 0!==d&&(c+="."+d),v.Ed(a,"a constant of class "+v.$e(b),c))},vJ:function(a,b){"string"===typeof a?ga(a)||v.k('Color "'+a+'" is not a valid color string for '+b):a instanceof ha||v.k("Value for "+b+" must be a color string or a Brush, not "+a)},Ed:function(a,b,c,d){b=v.getTypeName(b);c=v.getTypeName(c);void 0!==d&&(c+="."+d);"string"===typeof a?v.k(c+" value is not an instance of "+b+': "'+a+'"'):v.k(c+" value is not an instance of "+b+": "+a)},va:function(a,
b,c,d){c=v.getTypeName(c);void 0!==d&&(c+="."+d);v.k(c+" is not in the range "+b+": "+a)},dd:function(a){v.k(v.$e(a)+" constructor cannot take any arguments.")},La:function(a){v.k("Collection was modified during iteration: "+a.toString()+"\n  Perhaps you should iterate over a copy of the collection,\n  or you could collect items to be removed from the collection after the iteration.")},trace:function(a){window.console&&window.console.log(a)},Ta:function(a){return"object"===typeof a&&null!==a},isArray:function(a){return Array.isArray(a)||
a instanceof NodeList||a instanceof HTMLCollection},sH:function(a){return Array.isArray(a)},bz:function(a,b,c){v.isArray(a)||v.Ed(a,"Array or NodeList or HTMLCollection",b,c)},Va:function(a){return a.length},dl:function(a){return Array.prototype.slice.call(a)},Pa:function(a,b){Array.isArray(a);return a[b]},kD:function(a,b,c){Array.isArray(a)?a[b]=c:v.k("Cannot replace an object in an HTMLCollection or NodeList at "+b)},az:function(a,b){if(Array.isArray(a))return a.indexOf(b);for(var c=a.length,d=
0;d<c;d++)if(a[d]===b)return d;return-1},Mi:function(a,b,c){Array.isArray(a)?b>=a.length?a.push(c):a.splice(b,0,c):v.k("Cannot insert an object into an HTMLCollection or NodeList: "+c+" at "+b)},Th:function(a,b){Array.isArray(a)?b>=a.length?a.pop():a.splice(b,1):v.k("Cannot remove an object from an HTMLCollection or NodeList at "+b)},Ax:[],L:function(){var a=v.Ax.pop();return void 0===a?new z:a},Mb:function(a,b){var c=v.Ax.pop();if(void 0===c)return new z(a,b);c.x=a;c.y=b;return c},v:function(a){v.Ax.push(a)},
LA:[],El:function(){var a=v.LA.pop();return void 0===a?new ia:a},Zj:function(a){v.LA.push(a)},Bx:[],ag:function(){var a=v.Bx.pop();return void 0===a?new B:a},gk:function(a,b,c,d){var e=v.Bx.pop();if(void 0===e)return new B(a,b,c,d);e.x=a;e.y=b;e.width=c;e.height=d;return e},Vb:function(a){v.Bx.push(a)},MA:[],ph:function(){var a=v.MA.pop();return void 0===a?new ja:a},Ze:function(a){v.MA.push(a)},Cx:null,s:function(){var a=v.Cx;return null!==a?(v.Cx=null,a):new ma},q:function(a){a.reset();v.Cx=a},KA:[],
jb:function(){var a=v.KA.pop();return void 0===a?[]:a},sa:function(a){a.length=0;v.KA.push(a)},fj:Object.freeze([]),Il:1,ec:function(a){a.__gohashid=v.Il++},ot:function(a){var b=a.__gohashid;void 0===b&&(b=v.Il++,a.__gohashid=b);return b},ld:function(a){return a.__gohashid},ea:function(a,b){b.Lx=a;ea[a]=b},Ja:function(a,b){function c(){}c.prototype=b.prototype;a.prototype=new c;a.prototype.constructor=a},Uh:function(a){a.PF=!0},defineProperty:function(a,b,c,d,e){v.j(a,"function","Util.defineProperty:classfunc");
v.j(b,"object","Util.defineProperty:propobj");v.j(c,"function","Util.defineProperty:getter");v.j(d,"function","Util.defineProperty:setter");for(var f in b){b=b[f];c={get:c,set:d,enumerable:!0};if(void 0!==e)for(var h in e)c[h]=e[h];Object.defineProperty(a.prototype,f,c);e=Object.getOwnPropertyDescriptor(a.prototype,f);b&&e&&Object.defineProperty(a.prototype,b,e);break}},XI:!1,u:function(a,b,c,d){v.j(a,"function","Util.defineReadOnlyProperty:classfunc");v.j(b,"object","Util.defineReadOnlyProperty:propobj");
v.j(c,"function","Util.defineReadOnlyProperty:getter");for(var e in b){var f=b[e];b={get:c,set:function(a){v.k('The property "'+f+'" is read-only and cannot be set to '+a)},enumerable:!0};if(void 0!==d)for(var h in d)b[h]=d[h];Object.defineProperty(a.prototype,e,b);d=Object.getOwnPropertyDescriptor(a.prototype,e);f&&d&&Object.defineProperty(a.prototype,f,d);break}},Nd:function(a,b){for(var c in b)b[c]=!0;a.prototype.LF=b},getTypeName:function(a){return void 0===a?"":"string"===typeof a?a:"function"===
typeof a?v.$e(a):null===a?"*":""},$e:function(a){if("function"===typeof a){if(a.Lx)return a.Lx;if(a.name)return a.name;var b=a.toString(),c=b.indexOf("("),b=b.substring(9,c).trim();if(""!==b)return a.Lx=b}else if("object"===typeof a&&a.constructor)return v.$e(a.constructor);return typeof a},p:function(a,b,c){v.j(a,"function","Util.defineEnumValue:classfunc");v.j(b,"string","Util.defineEnumValue:name");v.j(c,"number","Util.defineEnumValue:num");c=new fa(a,b,c);Object.freeze(c);a[b]=c;var d=a.ku;d instanceof
na||(d=new na("string",fa),a.ku=d);d.add(b,c);return c},qb:function(a,b){if(!a||!b)return null;var c=void 0;try{"function"===typeof b?c=b(a):"function"===typeof a.getAttribute?(c=a.getAttribute(b),null===c&&(c=void 0)):c=a[b]}catch(d){}return c},Ka:function(a,b,c){if(a&&b)try{"function"===typeof b?b(a,c):"function"===typeof a.setAttribute?a.setAttribute(b,c):a[b]=c}catch(d){}},Xt:function(a,b){v.j(a,"object","Setting properties requires Objects as arguments");v.j(b,"object","Setting properties requires Objects as arguments");
var c=a instanceof D,d=a instanceof E,e;for(e in b){""===e&&v.k("Setting properties requires non-empty property names");var f=a,h=e;if(c||d){var k=e.indexOf(".");if(0<k){var l=e.substring(0,k);if(c)f=a.be(l);else if(f=a[l],void 0===f||null===f)f=a.Za[l];v.Ta(f)?h=e.substr(k+1):v.k("Unable to find object named: "+l+" in "+a.toString()+" when trying to set property: "+e)}}if("_"!==h[0]&&!v.XD(f,h))if(d&&"ModelChanged"===h){a.cG(b[h]);continue}else if(d&&"Changed"===h){a.ym(b[h]);continue}else if(d&&
v.XD(a.Za,h))f=a.Za;else if(d&&qa(a,h)){a.Wy(h,b[h]);continue}else if(a instanceof F&&"Changed"===h){a.ym(b[h]);continue}else v.k('Trying to set undefined property "'+h+'" on object: '+f.toString());f[h]=b[e];"_"===h[0]&&f instanceof G&&f.$F(h)}},XD:function(a,b){if(a.hasOwnProperty(b))return!0;for(var c=Object.getPrototypeOf(a);c&&c!==Function;){if(c.hasOwnProperty(b))return!0;var d=c.LF;if(d&&d[b])return!0;c=Object.getPrototypeOf(c)}return!1},fI:function(a){for(var b=[],c=0;256>c;c++)b[c]=c;for(var d=
0,e=0,c=0;256>c;c++)d=(d+b[c]+119)%256,e=b[c],b[c]=b[d],b[d]=e;for(var d=c=0,f="",h=0;h<a.length;h++)c=(c+1)%256,d=(d+b[c])%256,e=b[c],b[c]=b[d],b[d]=e,f+=String.fromCharCode(a.charCodeAt(h)^b[(b[c]+b[d])%256]);return f},eH:function(a){for(var b=[],c=0;256>c;c++)b["0123456789abcdef".charAt(c>>4)+"0123456789abcdef".charAt(c&15)]=String.fromCharCode(c);a.length%2&&(a="0"+a);for(var d=[],e=0,c=0;c<a.length;c+=2)d[e++]=b[a.substr(c,2)];a=d.join("");return""===a?"0":a},tg:function(a){return v.fI(v.eH(a))},
Gl:null,adym:"7da71ca0ad381e90",vfo:"2be640fdb260",oG:"@COLOR1",pG:"@COLOR2"};
v.Gl=function(){var a=window.document.createElement("canvas"),b=a.getContext("2d");b[v.tg("7ca11abfd022028846")]=v.tg("398c3597c01238");
for(var c=["5da73c80a3330d854f9e5e671d6633","32ab5ff3b26f42dc0ed90f22422913b54ae6247590da4bb21c324ba3a84e385776","54a702f3e53909c447824c6706603faf4cfb236cdfda5de14c134ba1a95a2d4c7cc6f93c1387","74bf19bce72555874c86"],d=1;5>d;d++)
	b[v.tg("7ca11abfd7330390")](v.tg(c[1-1]),0,0);b[v.tg("7ca11abfd022028846")]=v.tg("39f046ebb36e4b");
for(d=1;5>d;d++)b[v.tg("7ca11abfd7330390")](v.tg(c[1-1]),10,0);
if(4!==c.length||"5"!==c[0][0]||"7"!==c[3][0])v.p=function(a,b){var c=new fa(a,b,2);Object.freeze(c);a[b]=c;var d=a.ku;d instanceof na||(d=new na("string",fa),a.ku=d);d.add(b,c);return c};return a}();function fa(a,b,c){v.ec(this);this.ZA=a;this.Ob=b;this.UF=c}v.ea("EnumValue",fa);fa.prototype.toString=function(){return v.$e(this.ZA)+"."+this.Ob};v.u(fa,{pe:"classType"},function(){return this.ZA});v.u(fa,{name:"name"},function(){return this.Ob});v.u(fa,{value:"value"},function(){return this.UF});
var ra;fa.findName=ra=function(a,b){if(void 0===b||null===b||""===b)return null;v.j(a,"function","findName:classfunc");v.j(b,"string","EnumValue.findName:name");var c=a.ku;return c instanceof na?c.ja(b):null};function sa(){this.XA=[]}sa.prototype.toString=function(){return this.XA.join("")};sa.prototype.add=function(a){""!==a&&this.XA.push(a)};function ta(){}
function va(a){void 0===a&&(a=42);this.seed=a;this.wx=48271;this.ju=2147483647;this.JA=this.ju/this.wx;this.xF=this.ju%this.wx;this.wF=1/this.ju;this.random()}va.prototype.random=function(){var a=this.seed%this.JA*this.wx-this.seed/this.JA*this.xF;this.seed=0<a?a:a+this.ju;return this.seed*this.wF};function wa(){}v.u(wa,{i:"iterator"},function(){return this});wa.prototype.reset=wa.prototype.reset=function(){};wa.prototype.next=wa.prototype.hasNext=wa.prototype.next=function(){return!1};
wa.prototype.first=wa.prototype.first=function(){return null};wa.prototype.any=function(){return!1};wa.prototype.all=function(){return!0};wa.prototype.each=function(){return this};wa.prototype.map=function(){return this};wa.prototype.filter=function(){return this};wa.prototype.concat=function(a){return a.i};v.u(wa,{count:"count"},function(){return 0});wa.prototype.Cf=function(){};wa.prototype.toString=function(){return"EmptyIterator"};var xa=new wa;function ya(a){this.key=-1;this.value=a}
v.Nd(ya,{key:!0,value:!0});v.u(ya,{i:"iterator"},function(){return this});ya.prototype.reset=ya.prototype.reset=function(){this.key=-1};ya.prototype.next=ya.prototype.hasNext=ya.prototype.next=function(){return-1===this.key?(this.key=0,!0):!1};ya.prototype.first=ya.prototype.first=function(){this.key=0;return this.value};ya.prototype.any=function(a){this.key=-1;return a(this.value)};ya.prototype.all=function(a){this.key=-1;return a(this.value)};
ya.prototype.each=function(a){this.key=-1;a(this.value);return this};ya.prototype.map=function(a){return new ya(a(this.value))};ya.prototype.filter=function(a){return a(this.value)?new ya(this.value):xa};ya.prototype.concat=function(a){return new za(this,a.i)};v.u(ya,{count:"count"},function(){return 1});ya.prototype.Cf=function(){this.value=null};ya.prototype.toString=function(){return"SingletonIterator("+this.value+")"};function za(a,b){this.Gk=a;this.Hk=b;this.lu=!1}v.Nd(za,{key:!0,value:!0});
v.u(za,{i:"iterator"},function(){return this});za.prototype.reset=za.prototype.reset=function(){this.Gk.reset();this.Hk.reset();this.lu=!1};za.prototype.next=za.prototype.hasNext=za.prototype.next=function(){if(!this.lu){var a=this.Gk;if(a.next())return this.key=a.key,this.value=a.value,!0;this.lu=!0}return this.lu&&(a=this.Hk,a.next())?(this.key=a.key,this.value=a.value,!0):!1};za.prototype.first=za.prototype.first=function(){this.reset();return this.next()?this.value:null};
za.prototype.any=function(a){return this.Gk.any(a)||this.Hk.any(a)?!0:!1};za.prototype.all=function(a){return this.Gk.all(a)&&this.Hk.all(a)?!0:!1};za.prototype.each=function(a){this.Gk.each(a);this.Hk.each(a);return this};za.prototype.map=function(a){return new za(this.Gk.map(a),this.Hk.map(a))};za.prototype.filter=function(a){return new za(this.Gk.filter(a),this.Hk.filter(a))};za.prototype.concat=function(a){return new za(this,a.i)};v.u(za,{count:"count"},function(){return this.Gk.count+this.Hk.count});
za.prototype.Cf=function(){this.value=this.key=null};za.prototype.toString=function(){return"ConcatIterator()"};function Aa(a){this.tc=a;this.Gh=null;this.reset()}v.Nd(Aa,{key:!0,value:!0});v.u(Aa,{i:"iterator"},function(){return this});v.defineProperty(Aa,{Zm:"predicate"},function(){return this.Gh},function(a){this.Gh=a});Aa.prototype.reset=Aa.prototype.reset=function(){var a=this.tc;a.Xb=null;this.eb=a.F;this.Oc=-1};
Aa.prototype.next=Aa.prototype.hasNext=Aa.prototype.next=function(){var a=this.tc;if(a.F!==this.eb){if(0>this.key)return!1;v.La(a)}var a=a.n,b=a.length,c=++this.Oc,d=this.Gh;if(null!==d)for(;c<b;){var e=a[c];if(d(e))return this.key=this.Oc=c,this.value=e,!0;c++}else{if(c<b)return this.key=c,this.value=a[c],!0;this.Cf()}return!1};
Aa.prototype.first=Aa.prototype.first=function(){var a=this.tc;this.eb=a.F;this.Oc=0;var a=a.n,b=a.length,c=this.Gh;if(null!==c){for(var d=0;d<b;){var e=a[d];if(c(e))return this.key=this.Oc=d,this.value=e;d++}return null}return 0<b?(e=a[0],this.key=0,this.value=e):null};Aa.prototype.any=function(a){var b=this.tc;b.Xb=null;var c=b.F;this.Oc=-1;for(var d=b.n,e=d.length,f=this.Gh,h=0;h<e;h++){var k=d[h];if(null===f||f(k)){if(a(k))return!0;b.F!==c&&v.La(b)}}return!1};
Aa.prototype.all=function(a){var b=this.tc;b.Xb=null;var c=b.F;this.Oc=-1;for(var d=b.n,e=d.length,f=this.Gh,h=0;h<e;h++){var k=d[h];if(null===f||f(k)){if(!a(k))return!1;b.F!==c&&v.La(b)}}return!0};Aa.prototype.each=function(a){var b=this.tc;b.Xb=null;var c=b.F;this.Oc=-1;for(var d=b.n,e=d.length,f=this.Gh,h=0;h<e;h++){var k=d[h];if(null===f||f(k))a(k),b.F!==c&&v.La(b)}return this};
Aa.prototype.map=function(a){var b=this.tc;b.Xb=null;var c=b.F;this.Oc=-1;for(var d=[],e=b.n,f=e.length,h=this.Gh,k=0;k<f;k++){var l=e[k];if(null===h||h(l))d.push(a(l)),b.F!==c&&v.La(b)}a=new I;a.n=d;a.Bc();return a.i};Aa.prototype.filter=function(a){var b=this.tc;b.Xb=null;var c=b.F;this.Oc=-1;for(var d=[],e=b.n,f=e.length,h=this.Gh,k=0;k<f;k++){var l=e[k];if(null===h||h(l))a(l)&&d.push(l),b.F!==c&&v.La(b)}a=new I(b.ia);a.n=d;a.Bc();return a.i};
Aa.prototype.concat=function(a){this.tc.Xb=null;return new za(this,a.i)};v.u(Aa,{count:"count"},function(){var a=this.Gh;if(null!==a){for(var b=0,c=this.tc.n,d=c.length,e=0;e<d;e++)a(c[e])&&b++;return b}return this.tc.n.length});Aa.prototype.Cf=function(){this.key=-1;this.value=null;this.eb=-1;this.Gh=null;this.tc.Xb=this};Aa.prototype.toString=function(){return"ListIterator@"+this.Oc+"/"+this.tc.count};function Ba(a){this.tc=a;this.reset()}v.Nd(Ba,{key:!0,value:!0});v.u(Ba,{i:"iterator"},function(){return this});
Ba.prototype.reset=Ba.prototype.reset=function(){var a=this.tc;a.uj=null;this.eb=a.F;this.Oc=a.n.length};Ba.prototype.next=Ba.prototype.hasNext=Ba.prototype.next=function(){var a=this.tc;if(a.F!==this.eb){if(0>this.key)return!1;v.La(a)}var b=--this.Oc;if(0<=b)return this.key=b,this.value=a.n[b],!0;this.Cf();return!1};Ba.prototype.first=Ba.prototype.first=function(){var a=this.tc;this.eb=a.F;var b=a.n;this.Oc=a=b.length-1;return 0<=a?(b=b[a],this.key=a,this.value=b):null};
Ba.prototype.any=function(a){var b=this.tc;b.uj=null;var c=b.F,d=b.n,e=d.length;this.Oc=e;for(e-=1;0<=e;e--){if(a(d[e]))return!0;b.F!==c&&v.La(b)}return!1};Ba.prototype.all=function(a){var b=this.tc;b.uj=null;var c=b.F,d=b.n,e=d.length;this.Oc=e;for(e-=1;0<=e;e--){if(!a(d[e]))return!1;b.F!==c&&v.La(b)}return!0};Ba.prototype.each=function(a){var b=this.tc;b.uj=null;var c=b.F,d=b.n,e=d.length;this.Oc=e;for(e-=1;0<=e;e--)a(d[e]),b.F!==c&&v.La(b);return this};
Ba.prototype.map=function(a){var b=this.tc;b.uj=null;var c=b.F,d=[],e=b.n,f=e.length;this.Oc=f;for(f-=1;0<=f;f--)d.push(a(e[f])),b.F!==c&&v.La(b);a=new I;a.n=d;a.Bc();return a.i};Ba.prototype.filter=function(a){var b=this.tc;b.uj=null;var c=b.F,d=[],e=b.n,f=e.length;this.Oc=f;for(f-=1;0<=f;f--){var h=e[f];a(h)&&d.push(h);b.F!==c&&v.La(b)}a=new I(b.ia);a.n=d;a.Bc();return a.i};Ba.prototype.concat=function(a){this.tc.uj=null;return new za(this,a.i)};v.u(Ba,{count:"count"},function(){return this.tc.n.length});
Ba.prototype.Cf=function(){this.key=-1;this.value=null;this.eb=-1;this.tc.uj=this};Ba.prototype.toString=function(){return"ListIteratorBackwards("+this.Oc+"/"+this.tc.count+")"};
function I(a){v.ec(this);this.D=!1;this.n=[];this.F=0;this.uj=this.Xb=null;void 0===a||null===a?this.ia=null:"string"===typeof a?"object"===a||"string"===a||"number"===a||"boolean"===a||"function"===a?this.ia=a:v.va(a,"the string 'object', 'number', 'string', 'boolean', or 'function'","List constructor: type"):"function"===typeof a?this.ia=a===Object?"object":a===String?"string":a===Number?"number":a===Boolean?"boolean":a===Function?"function":a:v.va(a,"null, a primitive type name, or a class type",
"List constructor: type")}v.ea("List",I);I.prototype.Bc=function(){var a=this.F;a++;999999999<a&&(a=0);this.F=a};I.prototype.freeze=I.prototype.freeze=function(){this.D=!0;return this};I.prototype.thaw=I.prototype.Oa=function(){this.D=!1;return this};I.prototype.toString=function(){return"List("+v.getTypeName(this.ia)+")#"+v.ld(this)};I.prototype.add=I.prototype.push=I.prototype.add=function(a){null!==a&&(this.D&&v.ka(this,a),this.n.push(a),this.Bc())};
I.prototype.addAll=I.prototype.Tc=function(a){if(null===a)return this;this.D&&v.ka(this);var b=this.n;if(v.isArray(a))for(var c=v.Va(a),d=0;d<c;d++){var e=v.Pa(a,d);b.push(e)}else for(a=a.i;a.next();)e=a.value,b.push(e);this.Bc();return this};I.prototype.clear=I.prototype.clear=function(){this.D&&v.ka(this);this.n.length=0;this.Bc()};I.prototype.contains=I.prototype.has=I.prototype.contains=function(a){return null===a?!1:-1!==this.n.indexOf(a)};
I.prototype.indexOf=I.prototype.indexOf=function(a){return null===a?-1:this.n.indexOf(a)};I.prototype.elt=I.prototype.get=I.prototype.da=function(a){var b=this.n;(0>a||a>=b.length)&&v.va(a,"0 <= i < length",I,"elt:i");return b[a]};I.prototype.setElt=I.prototype.set=I.prototype.Hg=function(a,b){var c=this.n;(0>a||a>=c.length)&&v.va(a,"0 <= i < length",I,"setElt:i");this.D&&v.ka(this,a);c[a]=b};I.prototype.first=I.prototype.first=function(){var a=this.n;return 0===a.length?null:a[0]};
I.prototype.last=I.prototype.Rd=function(){var a=this.n,b=a.length;return 0<b?a[b-1]:null};I.prototype.pop=I.prototype.pop=function(){this.D&&v.ka(this);var a=this.n;return 0<a.length?a.pop():null};I.prototype.any=function(a){for(var b=this.n,c=this.F,d=b.length,e=0;e<d;e++){if(a(b[e]))return!0;this.F!==c&&v.La(this)}return!1};I.prototype.all=function(a){for(var b=this.n,c=this.F,d=b.length,e=0;e<d;e++){if(!a(b[e]))return!1;this.F!==c&&v.La(this)}return!0};
I.prototype.each=function(a){for(var b=this.n,c=this.F,d=b.length,e=0;e<d;e++)a(b[e]),this.F!==c&&v.La(this);return this};I.prototype.map=function(a){for(var b=new I,c=[],d=this.n,e=this.F,f=d.length,h=0;h<f;h++)c.push(a(d[h])),this.F!==e&&v.La(this);b.n=c;b.Bc();return b};I.prototype.filter=function(a){for(var b=new I(this.ia),c=[],d=this.n,e=this.F,f=d.length,h=0;h<f;h++){var k=d[h];a(k)&&c.push(k);this.F!==e&&v.La(this)}b.n=c;b.Bc();return b};I.prototype.concat=function(a){return this.copy().Tc(a)};
I.prototype.insertAt=I.prototype.Ad=function(a,b){0>a&&v.va(a,">= 0",I,"insertAt:i");this.D&&v.ka(this,a);var c=this.n;a>=c.length?c.push(b):c.splice(a,0,b);this.Bc();return!0};I.prototype.remove=I.prototype["delete"]=I.prototype.remove=function(a){if(null===a)return!1;this.D&&v.ka(this,a);var b=this.n;a=b.indexOf(a);if(-1===a)return!1;a===b.length-1?b.pop():b.splice(a,1);this.Bc();return!0};
I.prototype.removeAt=I.prototype.Vc=function(a){var b=this.n;(0>a||a>=b.length)&&v.va(a,"0 <= i < length",I,"removeAt:i");this.D&&v.ka(this,a);a===b.length-1?b.pop():b.splice(a,1);this.Bc()};I.prototype.removeRange=I.prototype.removeRange=function(a,b){var c=this.n;(0>a||a>=c.length)&&v.va(a,"0 <= from < length",I,"elt:from");(0>b||b>=c.length)&&v.va(b,"0 <= to < length",I,"elt:to");this.D&&v.ka(this,a);var d=c.slice((b||a)+1||c.length);c.length=0>a?c.length+a:a;c.push.apply(c,d);this.Bc()};
I.prototype.copy=function(){var a=new I(this.ia),b=this.n;0<b.length&&(a.n=Array.prototype.slice.call(b));return a};I.prototype.toArray=I.prototype.dc=function(){for(var a=this.n,b=this.count,c=Array(b),d=0;d<b;d++)c[d]=a[d];return c};I.prototype.toSet=I.prototype.gF=function(){for(var a=new J(this.ia),b=this.n,c=this.count,d=0;d<c;d++)a.add(b[d]);return a};I.prototype.sort=I.prototype.sort=function(a){this.D&&v.ka(this);this.n.sort(a);this.Bc();return this};
I.prototype.sortRange=I.prototype.Jp=function(a,b,c){var d=this.n,e=d.length;void 0===b&&(b=0);void 0===c&&(c=e);this.D&&v.ka(this);var f=c-b;if(1>=f)return this;(0>b||b>=e-1)&&v.va(b,"0 <= from < length",I,"sortRange:from");if(2===f)return c=d[b],e=d[b+1],0<a(c,e)&&(d[b]=e,d[b+1]=c,this.Bc()),this;if(0===b)if(c>=e)d.sort(a);else for(f=d.slice(0,c),f.sort(a),a=0;a<c;a++)d[a]=f[a];else if(c>=e)for(f=d.slice(b),f.sort(a),a=b;a<e;a++)d[a]=f[a-b];else for(f=d.slice(b,c),f.sort(a),a=b;a<c;a++)d[a]=f[a-
b];this.Bc();return this};I.prototype.reverse=I.prototype.reverse=function(){this.D&&v.ka(this);this.n.reverse();this.Bc();return this};v.u(I,{count:"count"},function(){return this.n.length});v.u(I,{size:"size"},function(){return this.n.length});v.u(I,{length:"length"},function(){return this.n.length});v.u(I,{i:"iterator"},function(){if(0>=this.n.length)return xa;var a=this.Xb;return null!==a?(a.reset(),a):new Aa(this)});
v.u(I,{Um:"iteratorBackwards"},function(){if(0>=this.n.length)return xa;var a=this.uj;return null!==a?(a.reset(),a):new Ba(this)});function Da(a){this.Ih=a;this.reset()}v.Nd(Da,{key:!0,value:!0});v.u(Da,{i:"iterator"},function(){return this});Da.prototype.reset=Da.prototype.reset=function(){var a=this.Ih;a.Xb=null;this.eb=a.F;this.cb=null};
Da.prototype.next=Da.prototype.hasNext=Da.prototype.next=function(){var a=this.Ih;if(a.F!==this.eb){if(null===this.key)return!1;v.La(a)}var b=this.cb,b=null===b?a.Da:b.Ua;if(null!==b)return this.cb=b,this.value=b.value,this.key=b.key,!0;this.Cf();return!1};Da.prototype.first=Da.prototype.first=function(){var a=this.Ih;this.eb=a.F;a=a.Da;if(null!==a){this.cb=a;var b=a.value;this.key=a.key;return this.value=b}return null};
Da.prototype.any=function(a){var b=this.Ih;b.Xb=null;var c=b.F;this.cb=null;for(var d=b.Da;null!==d;){if(a(d.value))return!0;b.F!==c&&v.La(b);d=d.Ua}return!1};Da.prototype.all=function(a){var b=this.Ih;b.Xb=null;var c=b.F;this.cb=null;for(var d=b.Da;null!==d;){if(!a(d.value))return!1;b.F!==c&&v.La(b);d=d.Ua}return!0};Da.prototype.each=function(a){var b=this.Ih;b.Xb=null;var c=b.F;this.cb=null;for(var d=b.Da;null!==d;)a(d.value),b.F!==c&&v.La(b),d=d.Ua;return this};
Da.prototype.map=function(a){var b=this.Ih;b.Xb=null;for(var c=new I,d=b.F,e=b.Da;null!==e;)c.add(a(e.value)),b.F!==d&&v.La(b),e=e.Ua;return c.i};Da.prototype.filter=function(a){var b=this.Ih;b.Xb=null;for(var c=new I(b.ia),d=b.F,e=b.Da;null!==e;){var f=e.value;a(f)&&c.add(f);b.F!==d&&v.La(b);e=e.Ua}return c.i};Da.prototype.concat=function(a){this.Ih.Xb=null;return new za(this,a.i)};v.u(Da,{count:"count"},function(){return this.Ih.gd});
Da.prototype.Cf=function(){this.value=this.key=null;this.eb=-1;this.Ih.Xb=this};Da.prototype.toString=function(){return null!==this.cb?"SetIterator@"+this.cb.value:"SetIterator"};
function J(a){v.ec(this);this.D=!1;void 0===a||null===a?this.ia=null:"string"===typeof a?"object"===a||"string"===a||"number"===a?this.ia=a:v.va(a,"the string 'object', 'number' or 'string'","Set constructor: type"):"function"===typeof a?this.ia=a===Object?"object":a===String?"string":a===Number?"number":a:v.va(a,"null, a primitive type name, or a class type","Set constructor: type");this.hd={};this.gd=0;this.Xb=null;this.F=0;this.Dh=this.Da=null}v.ea("Set",J);
J.prototype.Bc=function(){var a=this.F;a++;999999999<a&&(a=0);this.F=a};J.prototype.freeze=J.prototype.freeze=function(){this.D=!0;return this};J.prototype.thaw=J.prototype.Oa=function(){this.D=!1;return this};J.prototype.toString=function(){return"Set("+v.getTypeName(this.ia)+")#"+v.ld(this)};
J.prototype.add=J.prototype.add=function(a){if(null===a)return!1;this.D&&v.ka(this,a);var b=a;v.Ta(a)&&(b=v.ot(a));return void 0===this.hd[b]?(this.gd++,a=new Ea(a,a),this.hd[b]=a,b=this.Dh,null===b?this.Da=a:(a.oo=b,b.Ua=a),this.Dh=a,this.Bc(),!0):!1};J.prototype.addAll=J.prototype.Tc=function(a){if(null===a)return this;this.D&&v.ka(this);if(v.isArray(a))for(var b=v.Va(a),c=0;c<b;c++)this.add(v.Pa(a,c));else for(a=a.i;a.next();)this.add(a.value);return this};
J.prototype.contains=J.prototype.has=J.prototype.contains=function(a){if(null===a)return!1;var b=a;return v.Ta(a)&&(b=v.ld(a),void 0===b)?!1:void 0!==this.hd[b]};J.prototype.containsAll=function(a){if(null===a)return!0;for(a=a.i;a.next();)if(!this.contains(a.value))return!1;return!0};J.prototype.containsAny=function(a){if(null===a)return!0;for(a=a.i;a.next();)if(this.contains(a.value))return!0;return!1};J.prototype.first=J.prototype.first=function(){var a=this.Da;return null===a?null:a.value};
J.prototype.any=function(a){for(var b=this.F,c=this.Da;null!==c;){if(a(c.value))return!0;this.F!==b&&v.La(this);c=c.Ua}return!1};J.prototype.all=function(a){for(var b=this.F,c=this.Da;null!==c;){if(!a(c.value))return!1;this.F!==b&&v.La(this);c=c.Ua}return!0};J.prototype.each=function(a){for(var b=this.F,c=this.Da;null!==c;)a(c.value),this.F!==b&&v.La(this),c=c.Ua;return this};J.prototype.map=function(a){for(var b=new J,c=this.F,d=this.Da;null!==d;)b.add(a(d.value)),this.F!==c&&v.La(this),d=d.Ua;return b};
J.prototype.filter=function(a){for(var b=new J(this.ia),c=this.F,d=this.Da;null!==d;){var e=d.value;a(e)&&b.add(e);this.F!==c&&v.La(this);d=d.Ua}return b};J.prototype.concat=function(a){return this.copy().Tc(a)};
J.prototype.remove=J.prototype["delete"]=J.prototype.remove=function(a){if(null===a)return!1;this.D&&v.ka(this,a);var b=a;if(v.Ta(a)&&(b=v.ld(a),void 0===b))return!1;a=this.hd[b];if(void 0===a)return!1;var c=a.Ua,d=a.oo;null!==c&&(c.oo=d);null!==d&&(d.Ua=c);this.Da===a&&(this.Da=c);this.Dh===a&&(this.Dh=d);delete this.hd[b];this.gd--;this.Bc();return!0};
J.prototype.removeAll=J.prototype.fA=function(a){if(null===a)return this;this.D&&v.ka(this);if(v.isArray(a))for(var b=v.Va(a),c=0;c<b;c++)this.remove(v.Pa(a,c));else for(a=a.i;a.next();)this.remove(a.value);return this};J.prototype.retainAll=function(a){if(null===a||0===this.count)return this;this.D&&v.ka(this);var b=new J(this.ia);b.Tc(a);a=[];for(var c=this.i;c.next();){var d=c.value;b.contains(d)||a.push(d)}this.fA(a);return this};
J.prototype.clear=J.prototype.clear=function(){this.D&&v.ka(this);this.hd={};this.gd=0;this.Dh=this.Da=null;this.Bc()};J.prototype.copy=function(){var a=new J(this.ia),b=this.hd,c;for(c in b)a.add(b[c].value);return a};J.prototype.toArray=J.prototype.dc=function(){var a=Array(this.gd),b=this.hd,c=0,d;for(d in b)a[c]=b[d].value,c++;return a};J.prototype.toList=function(){var a=new I(this.ia),b=this.hd,c;for(c in b)a.add(b[c].value);return a};v.u(J,{count:"count"},function(){return this.gd});
v.u(J,{size:"size"},function(){return this.gd});v.u(J,{i:"iterator"},function(){if(0>=this.gd)return xa;var a=this.Xb;return null!==a?(a.reset(),a):new Da(this)});function Ha(a){this.Ga=a;this.reset()}v.Nd(Ha,{key:!0,value:!0});v.u(Ha,{i:"iterator"},function(){return this});Ha.prototype.reset=Ha.prototype.reset=function(){this.eb=this.Ga.F;this.cb=null};
Ha.prototype.next=Ha.prototype.hasNext=Ha.prototype.next=function(){var a=this.Ga;if(a.F!==this.eb){if(null===this.key)return!1;v.La(a)}var b=this.cb,b=null===b?a.Da:b.Ua;if(null!==b)return this.cb=b,this.value=this.key=a=b.key,!0;this.Cf();return!1};Ha.prototype.first=Ha.prototype.first=function(){var a=this.Ga;this.eb=a.F;a=a.Da;return null!==a?(this.cb=a,this.value=this.key=a=a.key):null};
Ha.prototype.any=function(a){var b=this.Ga,c=b.F;this.cb=null;for(var d=b.Da;null!==d;){if(a(d.key))return!0;b.F!==c&&v.La(b);d=d.Ua}return!1};Ha.prototype.all=function(a){var b=this.Ga,c=b.F;this.cb=null;for(var d=b.Da;null!==d;){if(!a(d.key))return!1;b.F!==c&&v.La(b);d=d.Ua}return!0};Ha.prototype.each=function(a){var b=this.Ga,c=b.F;this.cb=null;for(var d=b.Da;null!==d;)a(d.key),b.F!==c&&v.La(b),d=d.Ua;return this};
Ha.prototype.map=function(a){var b=this.Ga,c=b.F;this.cb=null;for(var d=new I,e=b.Da;null!==e;)d.add(a(e.key)),b.F!==c&&v.La(b),e=e.Ua;return d.i};Ha.prototype.filter=function(a){var b=this.Ga,c=b.F;this.cb=null;for(var d=new I(b.ui),e=b.Da;null!==e;){var f=e.key;a(f)&&d.add(f);b.F!==c&&v.La(b);e=e.Ua}return d.i};Ha.prototype.concat=function(a){return new za(this,a.i)};v.u(Ha,{count:"count"},function(){return this.Ga.gd});Ha.prototype.Cf=function(){this.value=this.key=null;this.eb=-1};
Ha.prototype.toString=function(){return null!==this.cb?"MapKeySetIterator@"+this.cb.value:"MapKeySetIterator"};function Ja(a){v.ec(this);this.D=!0;this.Ga=a}v.Ja(Ja,J);Ja.prototype.freeze=function(){return this};Ja.prototype.Oa=function(){return this};Ja.prototype.toString=function(){return"MapKeySet("+this.Ga.toString()+")"};Ja.prototype.add=Ja.prototype.set=Ja.prototype.add=function(){v.k("This Set is read-only: "+this.toString());return!1};
Ja.prototype.contains=Ja.prototype.has=Ja.prototype.contains=function(a){return this.Ga.contains(a)};Ja.prototype.remove=Ja.prototype["delete"]=Ja.prototype.remove=function(){v.k("This Set is read-only: "+this.toString());return!1};Ja.prototype.clear=Ja.prototype.clear=function(){v.k("This Set is read-only: "+this.toString())};Ja.prototype.first=Ja.prototype.first=function(){var a=this.Ga.Da;return null!==a?a.key:null};
Ja.prototype.any=function(a){for(var b=this.Ga.Da;null!==b;){if(a(b.key))return!0;b=b.Ua}return!1};Ja.prototype.all=function(a){for(var b=this.Ga.Da;null!==b;){if(!a(b.key))return!1;b=b.Ua}return!0};Ja.prototype.each=function(a){for(var b=this.Ga.Da;null!==b;)a(b.key),b=b.Ua;return this};Ja.prototype.map=function(a){for(var b=new J,c=this.Ga.Da;null!==c;)b.add(a(c.key)),c=c.Ua;return b};
Ja.prototype.filter=function(a){for(var b=new J(this.Ga.ui),c=this.Ga.Da;null!==c;){var d=c.key;a(d)&&b.add(d);c=c.Ua}return b};Ja.prototype.concat=function(a){return this.gF().Tc(a)};Ja.prototype.copy=function(){return new Ja(this.Ga)};Ja.prototype.toSet=Ja.prototype.gF=function(){var a=new J(this.Ga.ui),b=this.Ga.hd,c;for(c in b)a.add(b[c].key);return a};Ja.prototype.toArray=Ja.prototype.dc=function(){var a=this.Ga.hd,b=Array(this.Ga.gd),c=0,d;for(d in a)b[c]=a[d].key,c++;return b};
Ja.prototype.toList=function(){var a=new I(this.ia),b=this.Ga.hd,c;for(c in b)a.add(b[c].key);return a};v.u(Ja,{count:"count"},function(){return this.Ga.gd});v.u(Ja,{size:"size"},function(){return this.Ga.gd});v.u(Ja,{i:"iterator"},function(){return 0>=this.Ga.gd?xa:new Ha(this.Ga)});function La(a){this.Ga=a;this.reset()}v.Nd(La,{key:!0,value:!0});v.u(La,{i:"iterator"},function(){return this});La.prototype.reset=La.prototype.reset=function(){var a=this.Ga;a.vj=null;this.eb=a.F;this.cb=null};
La.prototype.next=La.prototype.hasNext=La.prototype.next=function(){var a=this.Ga;if(a.F!==this.eb){if(null===this.key)return!1;v.La(a)}var b=this.cb,b=null===b?a.Da:b.Ua;if(null!==b)return this.cb=b,this.value=b.value,this.key=b.key,!0;this.Cf();return!1};La.prototype.first=La.prototype.first=function(){var a=this.Ga;this.eb=a.F;a=a.Da;if(null!==a){this.cb=a;var b=a.value;this.key=a.key;return this.value=b}return null};
La.prototype.any=function(a){var b=this.Ga;b.vj=null;var c=b.F;this.cb=null;for(var d=b.Da;null!==d;){if(a(d.value))return!0;b.F!==c&&v.La(b);d=d.Ua}return!1};La.prototype.all=function(a){var b=this.Ga;b.vj=null;var c=b.F;this.cb=null;for(var d=b.Da;null!==d;){if(!a(d.value))return!1;b.F!==c&&v.La(b);d=d.Ua}return!0};La.prototype.each=function(a){var b=this.Ga;b.vj=null;var c=b.F;this.cb=null;for(var d=b.Da;null!==d;)a(d.value),b.F!==c&&v.La(b),d=d.Ua;return this};
La.prototype.map=function(a){var b=this.Ga;b.vj=null;var c=b.F;this.cb=null;for(var d=new I,e=b.Da;null!==e;)d.add(a(e.value)),b.F!==c&&v.La(b),e=e.Ua;return d.i};La.prototype.filter=function(a){var b=this.Ga;b.vj=null;var c=b.F;this.cb=null;for(var d=new I(b.ui),e=b.Da;null!==e;){var f=e.value;a(f)&&d.add(f);b.F!==c&&v.La(b);e=e.Ua}return d.i};La.prototype.concat=function(a){this.Ga.vj=null;return new za(this,a.i)};v.u(La,{count:"count"},function(){return this.Ga.gd});
La.prototype.Cf=function(){this.value=this.key=null;this.eb=-1;this.Ga.vj=this};La.prototype.toString=function(){return null!==this.cb?"MapValueSetIterator@"+this.cb.value:"MapValueSetIterator"};function Ea(a,b){this.key=a;this.value=b;this.oo=this.Ua=null}v.Nd(Ea,{key:!0,value:!0});Ea.prototype.toString=function(){return"{"+this.key+":"+this.value+"}"};function Ma(a){this.Ga=a;this.reset()}v.Nd(Ma,{key:!0,value:!0});v.u(Ma,{i:"iterator"},function(){return this});
Ma.prototype.reset=Ma.prototype.reset=function(){var a=this.Ga;a.Xb=null;this.eb=a.F;this.cb=null};Ma.prototype.next=Ma.prototype.hasNext=Ma.prototype.next=function(){var a=this.Ga;if(a.F!==this.eb){if(null===this.key)return!1;v.La(a)}var b=this.cb,b=null===b?a.Da:b.Ua;if(null!==b)return this.cb=b,this.key=b.key,this.value=b.value,!0;this.Cf();return!1};
Ma.prototype.first=Ma.prototype.first=function(){var a=this.Ga;this.eb=a.F;a=a.Da;return null!==a?(this.cb=a,this.key=a.key,this.value=a.value,a):null};Ma.prototype.any=function(a){var b=this.Ga;b.Xb=null;var c=b.F;this.cb=null;for(var d=b.Da;null!==d;){if(a(d))return!0;b.F!==c&&v.La(b);d=d.Ua}return!1};Ma.prototype.all=function(a){var b=this.Ga;b.Xb=null;var c=b.F;this.cb=null;for(var d=b.Da;null!==d;){if(!a(d))return!1;b.F!==c&&v.La(b);d=d.Ua}return!0};
Ma.prototype.each=function(a){var b=this.Ga;b.Xb=null;var c=b.F;this.cb=null;for(var d=b.Da;null!==d;)a(d),b.F!==c&&v.La(b),d=d.Ua;return this};Ma.prototype.map=function(a){var b=this.Ga;b.Xb=null;var c=b.F;this.cb=null;for(var d=new I,e=b.Da;null!==e;)d.add(a(e)),b.F!==c&&v.La(b),e=e.Ua;return d.i};Ma.prototype.filter=function(a){var b=this.Ga;b.Xb=null;var c=b.F;this.cb=null;for(var d=new I,e=b.Da;null!==e;)a(e)&&d.add(e),b.F!==c&&v.La(b),e=e.Ua;return d.i};
Ma.prototype.concat=function(a){this.Ga.Xb=null;return new za(this,a.i)};v.u(Ma,{count:"count"},function(){return this.Ga.gd});Ma.prototype.Cf=function(){this.value=this.key=null;this.eb=-1;this.Ga.Xb=this};Ma.prototype.toString=function(){return null!==this.cb?"MapIterator@"+this.cb:"MapIterator"};
function na(a,b){v.ec(this);this.D=!1;void 0===a||null===a?this.ui=null:"string"===typeof a?"object"===a||"string"===a||"number"===a?this.ui=a:v.va(a,"the string 'object', 'number' or 'string'","Map constructor: keytype"):"function"===typeof a?this.ui=a===Object?"object":a===String?"string":a===Number?"number":a:v.va(a,"null, a primitive type name, or a class type","Map constructor: keytype");void 0===b||null===b?this.Hs=null:"string"===typeof b?"object"===b||"string"===b||"boolean"===b||"number"===
b||"function"===b?this.Hs=b:v.va(b,"the string 'object', 'number', 'string', 'boolean', or 'function'","Map constructor: valtype"):"function"===typeof b?this.Hs=b===Object?"object":b===String?"string":b===Number?"number":b===Boolean?"boolean":b===Function?"function":b:v.va(b,"null, a primitive type name, or a class type","Map constructor: valtype");this.hd={};this.gd=0;this.vj=this.Xb=null;this.F=0;this.Dh=this.Da=null}v.ea("Map",na);
na.prototype.Bc=function(){var a=this.F;a++;999999999<a&&(a=0);this.F=a};na.prototype.freeze=na.prototype.freeze=function(){this.D=!0;return this};na.prototype.thaw=na.prototype.Oa=function(){this.D=!1;return this};na.prototype.toString=function(){return"Map("+v.getTypeName(this.ui)+","+v.getTypeName(this.Hs)+")#"+v.ld(this)};
na.prototype.add=na.prototype.set=na.prototype.add=function(a,b){this.D&&v.ka(this,a);var c=a;v.Ta(a)&&(c=v.ot(a));var d=this.hd[c];if(void 0===d)return this.gd++,d=new Ea(a,b),this.hd[c]=d,c=this.Dh,null===c?this.Da=d:(d.oo=c,c.Ua=d),this.Dh=d,this.Bc(),!0;d.value=b;return!1};na.prototype.addAll=na.prototype.Tc=function(a){if(null===a)return this;if(v.isArray(a))for(var b=v.Va(a),c=0;c<b;c++){var d=v.Pa(a,c);this.add(d.key,d.value)}else for(a=a.i;a.next();)this.add(a.key,a.value);return this};
na.prototype.first=na.prototype.first=function(){return this.Da};na.prototype.any=function(a){for(var b=this.F,c=this.Da;null!==c;){if(a(c))return!0;this.F!==b&&v.La(this);c=c.Ua}return!1};na.prototype.all=function(a){for(var b=this.F,c=this.Da;null!==c;){if(!a(c))return!1;this.F!==b&&v.La(this);c=c.Ua}return!0};na.prototype.each=function(a){for(var b=this.F,c=this.Da;null!==c;)a(c),this.F!==b&&v.La(this),c=c.Ua;return this};
na.prototype.map=function(a){for(var b=new na(this.ui),c=this.F,d=this.Da;null!==d;)b.add(d.key,a(d)),this.F!==c&&v.La(this),d=d.Ua;return b};na.prototype.filter=function(a){for(var b=new na(this.ui,this.Hs),c=this.F,d=this.Da;null!==d;)a(d)&&b.add(d.key,d.value),this.F!==c&&v.La(this),d=d.Ua;return b};na.prototype.concat=function(a){return this.copy().Tc(a)};na.prototype.contains=na.prototype.has=na.prototype.contains=function(a){var b=a;return v.Ta(a)&&(b=v.ld(a),void 0===b)?!1:void 0!==this.hd[b]};
na.prototype.getValue=na.prototype.get=na.prototype.ja=function(a){var b=a;if(v.Ta(a)&&(b=v.ld(a),void 0===b))return null;a=this.hd[b];return void 0===a?null:a.value};
na.prototype.remove=na.prototype["delete"]=na.prototype.remove=function(a){if(null===a)return!1;this.D&&v.ka(this,a);var b=a;if(v.Ta(a)&&(b=v.ld(a),void 0===b))return!1;a=this.hd[b];if(void 0===a)return!1;var c=a.Ua,d=a.oo;null!==c&&(c.oo=d);null!==d&&(d.Ua=c);this.Da===a&&(this.Da=c);this.Dh===a&&(this.Dh=d);delete this.hd[b];this.gd--;this.Bc();return!0};na.prototype.clear=na.prototype.clear=function(){this.D&&v.ka(this);this.hd={};this.gd=0;this.Dh=this.Da=null;this.Bc()};
na.prototype.copy=function(){var a=new na(this.ui,this.Hs),b=this.hd,c;for(c in b){var d=b[c];a.add(d.key,d.value)}return a};na.prototype.toArray=na.prototype.dc=function(){var a=this.hd,b=Array(this.gd),c=0,d;for(d in a){var e=a[d];b[c]=new Ea(e.key,e.value);c++}return b};na.prototype.toKeySet=na.prototype.cj=function(){return new Ja(this)};v.u(na,{count:"count"},function(){return this.gd});v.u(na,{size:"size"},function(){return this.gd});
v.u(na,{i:"iterator"},function(){if(0>=this.count)return xa;var a=this.Xb;return null!==a?(a.reset(),a):new Ma(this)});v.u(na,{mE:"iteratorKeys"},function(){return 0>=this.count?xa:new Ha(this)});v.u(na,{nE:"iteratorValues"},function(){if(0>=this.count)return xa;var a=this.vj;return null!==a?(a.reset(),a):new La(this)});function z(a,b){void 0===a?this.y=this.x=0:(this.x=a,this.y=b);this.D=!1}v.ea("Point",z);v.Uh(z);v.Nd(z,{x:!0,y:!0});z.prototype.assign=function(a){this.x=a.x;this.y=a.y};
z.prototype.setTo=z.prototype.m=function(a,b){this.x=a;this.y=b;return this};z.prototype.set=z.prototype.set=function(a){this.Cb();this.x=a.x;this.y=a.y;return this};z.prototype.copy=function(){var a=new z;a.x=this.x;a.y=this.y;return a};g=z.prototype;g.Ea=function(){this.D=!0;Object.freeze(this);return this};g.R=function(){return Object.isFrozen(this)?this:this.copy().freeze()};g.freeze=function(){this.D=!0;return this};
g.Oa=function(){Object.isFrozen(this)&&v.k("cannot thaw constant: "+this);this.D=!1;return this};g.Cb=function(a){if(this.D){var b="The Point is frozen, so its properties cannot be set: "+this.toString();void 0!==a&&(b+="  to value: "+a);v.k(b)}};z.parse=function(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));for(var e=0;""===a[b];)b++;(d=a[b++])&&(e=parseFloat(d));return new z(c,e)}return new z};
z.stringify=function(a){return a instanceof z?a.x.toString()+" "+a.y.toString():a.toString()};z.prototype.toString=function(){return"Point("+this.x+","+this.y+")"};z.prototype.equals=z.prototype.K=function(a){return a instanceof z?this.x===a.x&&this.y===a.y:!1};z.prototype.equalTo=z.prototype.xw=function(a,b){return this.x===a&&this.y===b};z.prototype.equalsApprox=z.prototype.Fc=function(a){return K(this.x,a.x)&&K(this.y,a.y)};
z.prototype.add=z.prototype.add=function(a){this.Cb();this.x+=a.x;this.y+=a.y;return this};z.prototype.subtract=z.prototype.du=function(a){this.Cb();this.x-=a.x;this.y-=a.y;return this};z.prototype.offset=z.prototype.offset=function(a,b){this.Cb();this.x+=a;this.y+=b;return this};
z.prototype.rotate=z.prototype.rotate=function(a){this.Cb();if(0===a)return this;var b=this.x,c=this.y;if(0===b&&0===c)return this;var d=0,e=0;360<=a?a-=360:0>a&&(a+=360);90===a?(d=0,e=1):180===a?(d=-1,e=0):270===a?(d=0,e=-1):(a=a*Math.PI/180,d=Math.cos(a),e=Math.sin(a));this.x=d*b-e*c;this.y=e*b+d*c;return this};z.prototype.scale=z.prototype.scale=function(a,b){this.x*=a;this.y*=b;return this};
z.prototype.distanceSquaredPoint=z.prototype.rf=function(a){var b=a.x-this.x;a=a.y-this.y;return b*b+a*a};z.prototype.distanceSquared=z.prototype.Uo=function(a,b){var c=a-this.x,d=b-this.y;return c*c+d*d};z.prototype.normalize=z.prototype.normalize=function(){this.Cb();var a=this.x,b=this.y,c=Math.sqrt(a*a+b*b);0<c&&(this.x=a/c,this.y=b/c);return this};z.prototype.directionPoint=z.prototype.xc=function(a){return Na(a.x-this.x,a.y-this.y)};
z.prototype.direction=z.prototype.direction=function(a,b){return Na(a-this.x,b-this.y)};function Na(a,b){if(0===a)return 0<b?90:0>b?270:0;if(0===b)return 0<a?0:180;if(isNaN(a)||isNaN(b))return 0;var c=180*Math.atan(Math.abs(b/a))/Math.PI;0>a?c=0>b?c+180:180-c:0>b&&(c=360-c);return c}z.prototype.projectOntoLineSegment=function(a,b,c,d){Oa(a,b,c,d,this.x,this.y,this);return this};z.prototype.projectOntoLineSegmentPoint=function(a,b){Oa(a.x,a.y,b.x,b.y,this.x,this.y,this);return this};
z.prototype.snapToGrid=function(a,b,c,d){Pa(this.x,this.y,a,b,c,d,this);return this};z.prototype.snapToGridPoint=function(a,b){Pa(this.x,this.y,a.x,a.y,b.width,b.height,this);return this};z.prototype.setRectSpot=z.prototype.Yt=function(a,b){this.Cb();this.x=a.x+b.x*a.width+b.offsetX;this.y=a.y+b.y*a.height+b.offsetY;return this};z.prototype.setSpot=z.prototype.$t=function(a,b,c,d,e){this.Cb();this.x=a+e.x*c+e.offsetX;this.y=b+e.y*d+e.offsetY;return this};
z.prototype.transform=function(a){a.ub(this);return this};function Qa(a,b){b.Wh(a);return a}var Ta;z.distanceLineSegmentSquared=Ta=function(a,b,c,d,e,f){var h=e-c,k=f-d,l=h*h+k*k;c-=a;d-=b;var m=-c*h-d*k;if(0>=m||m>=l)return h=e-a,k=f-b,Math.min(c*c+d*d,h*h+k*k);a=h*d-k*c;return a*a/l};var Ua;z.distanceSquared=Ua=function(a,b,c,d){a=c-a;b=d-b;return a*a+b*b};var Wa;
z.direction=Wa=function(a,b,c,d){a=c-a;b=d-b;if(0===a)return 0<b?90:0>b?270:0;if(0===b)return 0<a?0:180;if(isNaN(a)||isNaN(b))return 0;d=180*Math.atan(Math.abs(b/a))/Math.PI;0>a?d=0>b?d+180:180-d:0>b&&(d=360-d);return d};z.prototype.isReal=z.prototype.G=function(){return isFinite(this.x)&&isFinite(this.y)};function ia(a,b){void 0===a?this.height=this.width=0:(this.width=a,this.height=b);this.D=!1}v.ea("Size",ia);v.Uh(ia);v.Nd(ia,{width:!0,height:!0});
ia.prototype.assign=function(a){this.width=a.width;this.height=a.height};ia.prototype.setTo=ia.prototype.m=function(a,b){this.width=a;this.height=b;return this};ia.prototype.set=ia.prototype.set=function(a){this.Cb();this.width=a.width;this.height=a.height;return this};ia.prototype.copy=function(){var a=new ia;a.width=this.width;a.height=this.height;return a};g=ia.prototype;g.Ea=function(){this.D=!0;Object.freeze(this);return this};g.R=function(){return Object.isFrozen(this)?this:this.copy().freeze()};
g.freeze=function(){this.D=!0;return this};g.Oa=function(){Object.isFrozen(this)&&v.k("cannot thaw constant: "+this);this.D=!1;return this};g.Cb=function(a){if(this.D){var b="The Size is frozen, so its properties cannot be set: "+this.toString();void 0!==a&&(b+="  to value: "+a);v.k(b)}};ia.parse=function(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));for(var e=0;""===a[b];)b++;(d=a[b++])&&(e=parseFloat(d));return new ia(c,e)}return new ia};
ia.stringify=function(a){return a instanceof ia?a.width.toString()+" "+a.height.toString():a.toString()};ia.prototype.toString=function(){return"Size("+this.width+","+this.height+")"};ia.prototype.equals=ia.prototype.K=function(a){return a instanceof ia?this.width===a.width&&this.height===a.height:!1};ia.prototype.equalTo=ia.prototype.xw=function(a,b){return this.width===a&&this.height===b};ia.prototype.equalsApprox=ia.prototype.Fc=function(a){return K(this.width,a.width)&&K(this.height,a.height)};
ia.prototype.isReal=ia.prototype.G=function(){return isFinite(this.width)&&isFinite(this.height)};
function B(a,b,c,d){void 0===a?this.height=this.width=this.y=this.x=0:a instanceof z?b instanceof z?(this.x=Math.min(a.x,b.x),this.y=Math.min(a.y,b.y),this.width=Math.abs(a.x-b.x),this.height=Math.abs(a.y-b.y)):b instanceof ia?(this.x=a.x,this.y=a.y,this.width=b.width,this.height=b.height):v.k("Incorrect arguments supplied to Rect constructor"):(this.x=a,this.y=b,this.width=c,this.height=d);this.D=!1}v.ea("Rect",B);v.Uh(B);v.Nd(B,{x:!0,y:!0,width:!0,height:!0});
B.prototype.assign=function(a){this.x=a.x;this.y=a.y;this.width=a.width;this.height=a.height};function Xa(a,b,c){a.width=b;a.height=c}B.prototype.setTo=B.prototype.m=function(a,b,c,d){this.x=a;this.y=b;this.width=c;this.height=d;return this};B.prototype.set=B.prototype.set=function(a){this.Cb();this.x=a.x;this.y=a.y;this.width=a.width;this.height=a.height;return this};B.prototype.setPoint=B.prototype.zf=function(a){this.Cb();this.x=a.x;this.y=a.y;return this};
B.prototype.setSize=function(a){this.Cb();this.width=a.width;this.height=a.height;return this};B.prototype.copy=function(){var a=new B;a.x=this.x;a.y=this.y;a.width=this.width;a.height=this.height;return a};g=B.prototype;g.Ea=function(){this.D=!0;Object.freeze(this);return this};g.R=function(){return Object.isFrozen(this)?this:this.copy().freeze()};g.freeze=function(){this.D=!0;return this};g.Oa=function(){Object.isFrozen(this)&&v.k("cannot thaw constant: "+this);this.D=!1;return this};
g.Cb=function(a){if(this.D){var b="The Rect is frozen, so its properties cannot be set: "+this.toString();void 0!==a&&(b+="  to value: "+a);v.k(b)}};B.parse=function(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));for(var e=0;""===a[b];)b++;(d=a[b++])&&(e=parseFloat(d));for(var f=0;""===a[b];)b++;(d=a[b++])&&(f=parseFloat(d));for(var h=0;""===a[b];)b++;(d=a[b++])&&(h=parseFloat(d));return new B(c,e,f,h)}return new B};
B.stringify=function(a){return a instanceof B?a.x.toString()+" "+a.y.toString()+" "+a.width.toString()+" "+a.height.toString():a.toString()};B.prototype.toString=function(){return"Rect("+this.x+","+this.y+","+this.width+","+this.height+")"};B.prototype.equals=B.prototype.K=function(a){return a instanceof B?this.x===a.x&&this.y===a.y&&this.width===a.width&&this.height===a.height:!1};B.prototype.equalTo=B.prototype.xw=function(a,b,c,d){return this.x===a&&this.y===b&&this.width===c&&this.height===d};
B.prototype.equalsApprox=B.prototype.Fc=function(a){return K(this.x,a.x)&&K(this.y,a.y)&&K(this.width,a.width)&&K(this.height,a.height)};B.prototype.containsPoint=B.prototype.Fa=function(a){return this.x<=a.x&&this.x+this.width>=a.x&&this.y<=a.y&&this.y+this.height>=a.y};B.prototype.containsRect=B.prototype.Wj=function(a){return this.x<=a.x&&a.x+a.width<=this.x+this.width&&this.y<=a.y&&a.y+a.height<=this.y+this.height};
B.prototype.contains=B.prototype.contains=function(a,b,c,d){void 0===c&&(c=0);void 0===d&&(d=0);return this.x<=a&&a+c<=this.x+this.width&&this.y<=b&&b+d<=this.y+this.height};B.prototype.reset=function(){this.Cb();this.height=this.width=this.y=this.x=0};B.prototype.offset=B.prototype.offset=function(a,b){this.Cb();this.x+=a;this.y+=b;return this};B.prototype.inflate=B.prototype.Qf=function(a,b){return Ya(this,b,a,b,a)};
B.prototype.addMargin=B.prototype.fw=function(a){return Ya(this,a.top,a.right,a.bottom,a.left)};B.prototype.subtractMargin=B.prototype.GI=function(a){return Ya(this,-a.top,-a.right,-a.bottom,-a.left)};B.prototype.grow=function(a,b,c,d){return Ya(this,a,b,c,d)};function Ya(a,b,c,d,e){a.Cb();var f=a.width;c+e<=-f?(a.x+=f/2,a.width=0):(a.x-=e,a.width+=c+e);c=a.height;b+d<=-c?(a.y+=c/2,a.height=0):(a.y-=b,a.height+=b+d);return a}B.prototype.intersectRect=function(a){return Za(this,a.x,a.y,a.width,a.height)};
B.prototype.intersect=function(a,b,c,d){return Za(this,a,b,c,d)};function Za(a,b,c,d,e){a.Cb();var f=Math.max(a.x,b),h=Math.max(a.y,c);b=Math.min(a.x+a.width,b+d);c=Math.min(a.y+a.height,c+e);a.x=f;a.y=h;a.width=Math.max(0,b-f);a.height=Math.max(0,c-h);return a}B.prototype.intersectsRect=B.prototype.xg=function(a){return this.ZD(a.x,a.y,a.width,a.height)};
B.prototype.intersects=B.prototype.ZD=function(a,b,c,d){var e=this.width,f=this.x;if(Infinity!==e&&Infinity!==c&&(e+=f,c+=a,isNaN(c)||isNaN(e)||f>c||a>e))return!1;a=this.height;c=this.y;return Infinity!==a&&Infinity!==d&&(a+=c,d+=b,isNaN(d)||isNaN(a)||c>d||b>a)?!1:!0};function $a(a,b){var c=a.width,d=b.width+10+10,e=a.x,f=b.x-10;if(e>d+f||f>c+e)return!1;c=a.height;d=b.height+10+10;e=a.y;f=b.y-10;return e>d+f||f>c+e?!1:!0}B.prototype.unionPoint=B.prototype.dj=function(a){return ab(this,a.x,a.y,0,0)};
B.prototype.unionRect=B.prototype.ai=function(a){return ab(this,a.x,a.y,a.width,a.height)};B.prototype.union=B.prototype.pF=function(a,b,c,d){this.Cb();void 0===c&&(c=0);void 0===d&&(d=0);return ab(this,a,b,c,d)};function ab(a,b,c,d,e){var f=Math.min(a.x,b),h=Math.min(a.y,c);b=Math.max(a.x+a.width,b+d);c=Math.max(a.y+a.height,c+e);a.x=f;a.y=h;a.width=b-f;a.height=c-h;return a}
B.prototype.setSpot=B.prototype.$t=function(a,b,c){this.Cb();this.x=a-c.offsetX-c.x*this.width;this.y=b-c.offsetY-c.y*this.height;return this};var db;B.contains=db=function(a,b,c,d,e,f,h,k){void 0===h&&(h=0);void 0===k&&(k=0);return a<=e&&e+h<=a+c&&b<=f&&f+k<=b+d};B.intersects=function(a,b,c,d,e,f,h,k){c+=a;h+=e;if(a>h||e>c)return!1;a=d+b;k+=f;return b>k||f>a?!1:!0};v.defineProperty(B,{left:"left"},function(){return this.x},function(a){this.Cb(a);this.x=a});
v.defineProperty(B,{top:"top"},function(){return this.y},function(a){this.Cb(a);this.y=a});v.defineProperty(B,{right:"right"},function(){return this.x+this.width},function(a){this.Cb(a);this.x+=a-(this.x+this.width)});v.defineProperty(B,{bottom:"bottom"},function(){return this.y+this.height},function(a){this.Cb(a);this.y+=a-(this.y+this.height)});v.defineProperty(B,{position:"position"},function(){return new z(this.x,this.y)},function(a){this.Cb(a);this.x=a.x;this.y=a.y});
v.defineProperty(B,{size:"size"},function(){return new ia(this.width,this.height)},function(a){this.Cb(a);this.width=a.width;this.height=a.height});v.defineProperty(B,{bl:"center"},function(){return new z(this.x+this.width/2,this.y+this.height/2)},function(a){this.Cb(a);this.x=a.x-this.width/2;this.y=a.y-this.height/2});v.defineProperty(B,{na:"centerX"},function(){return this.x+this.width/2},function(a){this.Cb(a);this.x=a-this.width/2});
v.defineProperty(B,{ua:"centerY"},function(){return this.y+this.height/2},function(a){this.Cb(a);this.y=a-this.height/2});B.prototype.isReal=B.prototype.G=function(){return isFinite(this.x)&&isFinite(this.y)&&isFinite(this.width)&&isFinite(this.height)};B.prototype.isEmpty=function(){return 0===this.width&&0===this.height};
function eb(a,b,c,d){void 0===a?this.left=this.bottom=this.right=this.top=0:void 0===b?this.left=this.bottom=this.right=this.top=a:void 0===c?(d=b,this.top=a,this.right=b,this.bottom=a,this.left=d):void 0!==d?(this.top=a,this.right=b,this.bottom=c,this.left=d):v.k("Invalid arguments to Margin constructor");this.D=!1}v.ea("Margin",eb);v.Uh(eb);v.Nd(eb,{top:!0,right:!0,bottom:!0,left:!0});eb.prototype.assign=function(a){this.top=a.top;this.right=a.right;this.bottom=a.bottom;this.left=a.left};
eb.prototype.setTo=eb.prototype.m=function(a,b,c,d){this.Cb();this.top=a;this.right=b;this.bottom=c;this.left=d;return this};eb.prototype.set=eb.prototype.set=function(a){this.Cb();this.top=a.top;this.right=a.right;this.bottom=a.bottom;this.left=a.left;return this};eb.prototype.copy=function(){var a=new eb;a.top=this.top;a.right=this.right;a.bottom=this.bottom;a.left=this.left;return a};g=eb.prototype;g.Ea=function(){this.D=!0;Object.freeze(this);return this};
g.R=function(){return Object.isFrozen(this)?this:this.copy().freeze()};g.freeze=function(){this.D=!0;return this};g.Oa=function(){Object.isFrozen(this)&&v.k("cannot thaw constant: "+this);this.D=!1;return this};g.Cb=function(a){if(this.D){var b="The Margin is frozen, so its properties cannot be set: "+this.toString();void 0!==a&&(b+="  to value: "+a);v.k(b)}};
eb.parse=function(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=NaN;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));if(isNaN(c))return new eb;for(var e=NaN;""===a[b];)b++;(d=a[b++])&&(e=parseFloat(d));if(isNaN(e))return new eb(c);for(var f=NaN;""===a[b];)b++;(d=a[b++])&&(f=parseFloat(d));if(isNaN(f))return new eb(c,e);for(var h=NaN;""===a[b];)b++;(d=a[b++])&&(h=parseFloat(d));return isNaN(h)?new eb(c,e):new eb(c,e,f,h)}return new eb};
eb.stringify=function(a){return a instanceof eb?a.top.toString()+" "+a.right.toString()+" "+a.bottom.toString()+" "+a.left.toString():a.toString()};eb.prototype.toString=function(){return"Margin("+this.top+","+this.right+","+this.bottom+","+this.left+")"};eb.prototype.equals=eb.prototype.K=function(a){return a instanceof eb?this.top===a.top&&this.right===a.right&&this.bottom===a.bottom&&this.left===a.left:!1};
eb.prototype.equalTo=eb.prototype.xw=function(a,b,c,d){return this.top===a&&this.right===b&&this.bottom===c&&this.left===d};eb.prototype.equalsApprox=eb.prototype.Fc=function(a){return K(this.top,a.top)&&K(this.right,a.right)&&K(this.bottom,a.bottom)&&K(this.left,a.left)};eb.prototype.isReal=eb.prototype.G=function(){return isFinite(this.top)&&isFinite(this.right)&&isFinite(this.bottom)&&isFinite(this.left)};function ja(){this.m11=1;this.m21=this.m12=0;this.m22=1;this.dy=this.dx=0}v.Uh(ja);
v.Nd(ja,{m11:!0,m12:!0,m21:!0,m22:!0,dx:!0,dy:!0});ja.prototype.set=ja.prototype.set=function(a){this.m11=a.m11;this.m12=a.m12;this.m21=a.m21;this.m22=a.m22;this.dx=a.dx;this.dy=a.dy;return this};ja.prototype.copy=function(){var a=new ja;a.m11=this.m11;a.m12=this.m12;a.m21=this.m21;a.m22=this.m22;a.dx=this.dx;a.dy=this.dy;return a};ja.prototype.toString=function(){return"Transform("+this.m11+","+this.m12+","+this.m21+","+this.m22+","+this.dx+","+this.dy+")"};
ja.prototype.equals=ja.prototype.K=function(a){return a instanceof ja?this.m11===a.m11&&this.m12===a.m12&&this.m21===a.m21&&this.m22===a.m22&&this.dx===a.dx&&this.dy===a.dy:!1};ja.prototype.isIdentity=ja.prototype.vt=function(){return 1===this.m11&&0===this.m12&&0===this.m21&&1===this.m22&&0===this.dx&&0===this.dy};ja.prototype.reset=ja.prototype.reset=function(){this.m11=1;this.m21=this.m12=0;this.m22=1;this.dy=this.dx=0;return this};
ja.prototype.multiply=ja.prototype.multiply=function(a){var b=this.m12*a.m11+this.m22*a.m12,c=this.m11*a.m21+this.m21*a.m22,d=this.m12*a.m21+this.m22*a.m22,e=this.m11*a.dx+this.m21*a.dy+this.dx,f=this.m12*a.dx+this.m22*a.dy+this.dy;this.m11=this.m11*a.m11+this.m21*a.m12;this.m12=b;this.m21=c;this.m22=d;this.dx=e;this.dy=f;return this};
ja.prototype.multiplyInverted=ja.prototype.vE=function(a){var b=1/(a.m11*a.m22-a.m12*a.m21),c=a.m22*b,d=-a.m12*b,e=-a.m21*b,f=a.m11*b,h=b*(a.m21*a.dy-a.m22*a.dx),k=b*(a.m12*a.dx-a.m11*a.dy);a=this.m12*c+this.m22*d;b=this.m11*e+this.m21*f;e=this.m12*e+this.m22*f;f=this.m11*h+this.m21*k+this.dx;h=this.m12*h+this.m22*k+this.dy;this.m11=this.m11*c+this.m21*d;this.m12=a;this.m21=b;this.m22=e;this.dx=f;this.dy=h;return this};
ja.prototype.invert=ja.prototype.Dz=function(){var a=1/(this.m11*this.m22-this.m12*this.m21),b=-this.m12*a,c=-this.m21*a,d=this.m11*a,e=a*(this.m21*this.dy-this.m22*this.dx),f=a*(this.m12*this.dx-this.m11*this.dy);this.m11=this.m22*a;this.m12=b;this.m21=c;this.m22=d;this.dx=e;this.dy=f;return this};
ja.prototype.rotate=ja.prototype.rotate=function(a,b,c){360<=a?a-=360:0>a&&(a+=360);if(0===a)return this;this.translate(b,c);var d=0,e=0;90===a?(d=0,e=1):180===a?(d=-1,e=0):270===a?(d=0,e=-1):(e=a*Math.PI/180,d=Math.cos(e),e=Math.sin(e));a=this.m12*d+this.m22*e;var f=this.m11*-e+this.m21*d,h=this.m12*-e+this.m22*d;this.m11=this.m11*d+this.m21*e;this.m12=a;this.m21=f;this.m22=h;this.translate(-b,-c);return this};
ja.prototype.translate=ja.prototype.translate=function(a,b){this.dx+=this.m11*a+this.m21*b;this.dy+=this.m12*a+this.m22*b;return this};ja.prototype.scale=ja.prototype.scale=function(a,b){void 0===b&&(b=a);this.m11*=a;this.m12*=a;this.m21*=b;this.m22*=b;return this};ja.prototype.transformPoint=ja.prototype.ub=function(a){var b=a.x,c=a.y;a.x=b*this.m11+c*this.m21+this.dx;a.y=b*this.m12+c*this.m22+this.dy;return a};
ja.prototype.invertedTransformPoint=ja.prototype.Wh=function(a){var b=1/(this.m11*this.m22-this.m12*this.m21),c=-this.m12*b,d=this.m11*b,e=b*(this.m12*this.dx-this.m11*this.dy),f=a.x,h=a.y;a.x=f*this.m22*b+h*-this.m21*b+b*(this.m21*this.dy-this.m22*this.dx);a.y=f*c+h*d+e;return a};
ja.prototype.transformRect=ja.prototype.nF=function(a){var b=a.x,c=a.y,d=b+a.width,e=c+a.height,f=this.m11,h=this.m12,k=this.m21,l=this.m22,m=this.dx,n=this.dy,p=b*f+c*k+m,q=b*h+c*l+n,r=d*f+c*k+m,c=d*h+c*l+n,s=b*f+e*k+m,b=b*h+e*l+n,f=d*f+e*k+m,d=d*h+e*l+n,e=p,h=q,p=Math.min(p,r),e=Math.max(e,r),h=Math.min(h,c),q=Math.max(q,c),p=Math.min(p,s),e=Math.max(e,s),h=Math.min(h,b),q=Math.max(q,b),p=Math.min(p,f),e=Math.max(e,f),h=Math.min(h,d),q=Math.max(q,d);a.x=p;a.y=h;a.width=e-p;a.height=q-h;return a};
function L(a,b,c,d){void 0===a?this.offsetY=this.offsetX=this.y=this.x=0:(void 0===b&&(b=0),void 0===c&&(c=0),void 0===d&&(d=0),this.x=a,this.y=b,this.offsetX=c,this.offsetY=d);this.D=!1}v.ea("Spot",L);v.Uh(L);v.Nd(L,{x:!0,y:!0,offsetX:!0,offsetY:!0});L.prototype.assign=function(a){this.x=a.x;this.y=a.y;this.offsetX=a.offsetX;this.offsetY=a.offsetY};L.prototype.setTo=L.prototype.m=function(a,b,c,d){this.Cb();this.x=a;this.y=b;this.offsetX=c;this.offsetY=d;return this};
L.prototype.set=L.prototype.set=function(a){this.Cb();this.x=a.x;this.y=a.y;this.offsetX=a.offsetX;this.offsetY=a.offsetY;return this};L.prototype.copy=function(){var a=new L;a.x=this.x;a.y=this.y;a.offsetX=this.offsetX;a.offsetY=this.offsetY;return a};g=L.prototype;g.Ea=function(){this.D=!0;Object.freeze(this);return this};g.R=function(){return Object.isFrozen(this)?this:this.copy().freeze()};g.freeze=function(){this.D=!0;return this};
g.Oa=function(){Object.isFrozen(this)&&v.k("cannot thaw constant: "+this);this.D=!1;return this};g.Cb=function(a){if(this.D){var b="The Spot is frozen, so its properties cannot be set: "+this.toString();void 0!==a&&(b+="  to value: "+a);v.k(b)}};function fb(a,b){a.x=NaN;a.y=NaN;a.offsetX=b;return a}var ib;
L.parse=ib=function(a){if("string"===typeof a){a=a.trim();if("None"===a)return sb;if("TopLeft"===a)return tb;if("Top"===a||"TopCenter"===a||"MiddleTop"===a)return ub;if("TopRight"===a)return vb;if("Left"===a||"LeftCenter"===a||"MiddleLeft"===a)return wb;if("Center"===a)return Ab;if("Right"===a||"RightCenter"===a||"MiddleRight"===a)return Bb;if("BottomLeft"===a)return Db;if("Bottom"===a||"BottomCenter"===a||"MiddleBottom"===a)return Eb;if("BottomRight"===a)return Fb;if("TopSide"===a)return Gb;if("LeftSide"===
a)return Hb;if("RightSide"===a)return Nb;if("BottomSide"===a)return Ob;if("TopBottomSides"===a)return Pb;if("LeftRightSides"===a)return Tb;if("TopLeftSides"===a)return Ub;if("TopRightSides"===a)return Vb;if("BottomLeftSides"===a)return Zb;if("BottomRightSides"===a)return $b;if("NotTopSide"===a)return ac;if("NotLeftSide"===a)return bc;if("NotRightSide"===a)return cc;if("NotBottomSide"===a)return dc;if("AllSides"===a)return ec;if("Default"===a)return fc;a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;
var d=a[b++];void 0!==d&&0<d.length&&(c=parseFloat(d));for(var e=0;""===a[b];)b++;d=a[b++];void 0!==d&&0<d.length&&(e=parseFloat(d));for(var f=0;""===a[b];)b++;d=a[b++];void 0!==d&&0<d.length&&(f=parseFloat(d));for(var h=0;""===a[b];)b++;d=a[b++];void 0!==d&&0<d.length&&(h=parseFloat(d));return new L(c,e,f,h)}return new L};L.stringify=function(a){return a instanceof L?a.cd()?a.x.toString()+" "+a.y.toString()+" "+a.offsetX.toString()+" "+a.offsetY.toString():a.toString():a.toString()};
L.prototype.toString=function(){return this.cd()?0===this.offsetX&&0===this.offsetY?"Spot("+this.x+","+this.y+")":"Spot("+this.x+","+this.y+","+this.offsetX+","+this.offsetY+")":this.K(sb)?"None":this.K(tb)?"TopLeft":this.K(ub)?"Top":this.K(vb)?"TopRight":this.K(wb)?"Left":this.K(Ab)?"Center":this.K(Bb)?"Right":this.K(Db)?"BottomLeft":this.K(Eb)?"Bottom":this.K(Fb)?"BottomRight":this.K(Gb)?"TopSide":this.K(Hb)?"LeftSide":this.K(Nb)?"RightSide":this.K(Ob)?"BottomSide":this.K(Pb)?"TopBottomSides":this.K(Tb)?
"LeftRightSides":this.K(Ub)?"TopLeftSides":this.K(Vb)?"TopRightSides":this.K(Zb)?"BottomLeftSides":this.K($b)?"BottomRightSides":this.K(ac)?"NotTopSide":this.K(bc)?"NotLeftSide":this.K(cc)?"NotRightSide":this.K(dc)?"NotBottomSide":this.K(ec)?"AllSides":this.K(fc)?"Default":"None"};L.prototype.equals=L.prototype.K=function(a){return a instanceof L?(this.x===a.x||isNaN(this.x)&&isNaN(a.x))&&(this.y===a.y||isNaN(this.y)&&isNaN(a.y))&&this.offsetX===a.offsetX&&this.offsetY===a.offsetY:!1};
L.prototype.opposite=function(){return new L(.5-(this.x-.5),.5-(this.y-.5),-this.offsetX,-this.offsetY)};L.prototype.includesSide=L.prototype.cp=function(a){if(!this.Ui())return!1;if(!a.Ui())if(a.K(lc))a=Hb;else if(a.K(mc))a=Nb;else if(a.K(nc))a=Gb;else if(a.K(oc))a=Ob;else return!1;a=a.offsetY;return(this.offsetY&a)===a};L.prototype.isSpot=L.prototype.cd=function(){return!isNaN(this.x)&&!isNaN(this.y)};L.prototype.isNoSpot=L.prototype.se=function(){return isNaN(this.x)||isNaN(this.y)};
L.prototype.isSide=L.prototype.Ui=function(){return this.se()&&1===this.offsetX&&0!==this.offsetY};L.prototype.isDefault=L.prototype.Uc=function(){return isNaN(this.x)&&isNaN(this.y)&&-1===this.offsetX&&0===this.offsetY};var sb;L.None=sb=fb(new L(0,0,0,0),0).Ea();var fc;L.Default=fc=fb(new L(0,0,-1,0),-1).Ea();var tb;L.TopLeft=tb=(new L(0,0,0,0)).Ea();var ub;L.TopCenter=ub=(new L(.5,0,0,0)).Ea();var vb;L.TopRight=vb=(new L(1,0,0,0)).Ea();var wb;L.LeftCenter=wb=(new L(0,.5,0,0)).Ea();var Ab;
L.Center=Ab=(new L(.5,.5,0,0)).Ea();var Bb;L.RightCenter=Bb=(new L(1,.5,0,0)).Ea();var Db;L.BottomLeft=Db=(new L(0,1,0,0)).Ea();var Eb;L.BottomCenter=Eb=(new L(.5,1,0,0)).Ea();var Fb;L.BottomRight=Fb=(new L(1,1,0,0)).Ea();var pc;L.MiddleTop=pc=ub;var qc;L.MiddleLeft=qc=wb;var rc;L.MiddleRight=rc=Bb;var sc;L.MiddleBottom=sc=Eb;var nc;L.Top=nc=ub;var lc;L.Left=lc=wb;var mc;L.Right=mc=Bb;var oc;L.Bottom=oc=Eb;var Gb;L.TopSide=Gb=fb(new L(0,0,1,v.ed),1).Ea();var Hb;
L.LeftSide=Hb=fb(new L(0,0,1,v.Nc),1).Ea();var Nb;L.RightSide=Nb=fb(new L(0,0,1,v.Xc),1).Ea();var Ob;L.BottomSide=Ob=fb(new L(0,0,1,v.Wc),1).Ea();var Pb;L.TopBottomSides=Pb=fb(new L(0,0,1,v.ed|v.Wc),1).Ea();var Tb;L.LeftRightSides=Tb=fb(new L(0,0,1,v.Nc|v.Xc),1).Ea();var Ub;L.TopLeftSides=Ub=fb(new L(0,0,1,v.ed|v.Nc),1).Ea();var Vb;L.TopRightSides=Vb=fb(new L(0,0,1,v.ed|v.Xc),1).Ea();var Zb;L.BottomLeftSides=Zb=fb(new L(0,0,1,v.Wc|v.Nc),1).Ea();var $b;
L.BottomRightSides=$b=fb(new L(0,0,1,v.Wc|v.Xc),1).Ea();var ac;L.NotTopSide=ac=fb(new L(0,0,1,v.Nc|v.Xc|v.Wc),1).Ea();var bc;L.NotLeftSide=bc=fb(new L(0,0,1,v.ed|v.Xc|v.Wc),1).Ea();var cc;L.NotRightSide=cc=fb(new L(0,0,1,v.ed|v.Nc|v.Wc),1).Ea();var dc;L.NotBottomSide=dc=fb(new L(0,0,1,v.ed|v.Nc|v.Xc),1).Ea();var ec;L.AllSides=ec=fb(new L(0,0,1,v.ed|v.Nc|v.Xc|v.Wc),1).Ea();function xc(){this.ef=[1,0,0,1,0,0]}
xc.prototype.copy=function(){var a=new xc;a.ef[0]=this.ef[0];a.ef[1]=this.ef[1];a.ef[2]=this.ef[2];a.ef[3]=this.ef[3];a.ef[4]=this.ef[4];a.ef[5]=this.ef[5];return a};function yc(a){this.type=a;this.r2=this.y2=this.x2=this.r1=this.y1=this.x1=0;this.pD=[];this.pattern=null}yc.prototype.addColorStop=function(a,b){this.pD.push({offset:a,color:b})};
function zc(a,b,c){this.fillStyle="#000000";this.font="10px sans-serif";this.globalAlpha=1;this.lineCap="butt";this.Vm=0;this.lineJoin="miter";this.lineWidth=1;this.miterLimit=10;this.shadowBlur=0;this.shadowColor="rgba(0, 0, 0, 0)";this.shadowOffsetY=this.shadowOffsetX=0;this.strokeStyle="#000000";this.textAlign="start";this.pw=!1;this.og=this.os=this.ns=0;this.document=b||document;this.GD=c;this.Mw=null;this.path=[];this.Pi=new xc;this.stack=[];this.tf=[];this.vw=a;this.ZH="http://www.w3.org/2000/svg";
this.width=this.vw.width;this.height=this.vw.height;this.Cl=Ac(this,"svg",{width:this.width+"px",height:this.height+"px",QK:"0 0 "+this.vw.width+" "+this.vw.height});this.Cl.setAttributeNS("http://www.w3.org/2000/xmlns/","xmlns","http://www.w3.org/2000/svg");this.Cl.setAttributeNS("http://www.w3.org/2000/xmlns/","xmlns:xlink","http://www.w3.org/1999/xlink");Bc(this,1,0,0,1,0,0);a=v.Il++;b=Ac(this,"clipPath",{id:"mainClip"+a});b.appendChild(Ac(this,"rect",{x:0,y:0,width:this.width,height:this.height}));
this.Cl.appendChild(b);this.tf[0].setAttributeNS(null,"clip-path","url(#mainClip"+a+")")}g=zc.prototype;g.arc=function(a,b,c,d,e,f){Cc(this,a,b,c,d,e,f)};g.beginPath=function(){this.path=[]};g.bezierCurveTo=function(a,b,c,d,e,f){this.path.push(["C",a,b,c,d,e,f])};g.clearRect=function(){};g.clip=function(){Dc(this,"clipPath",this.path,new xc)};g.closePath=function(){this.path.push(["z"])};g.createLinearGradient=function(a,b,c,d){var e=new yc("linear");e.x1=a;e.y1=b;e.x2=c;e.y2=d;return e};
g.createPattern=function(a){var b=new yc("pattern");b.pattern=a;return b};g.createRadialGradient=function(a,b,c,d,e,f){var h=new yc("radial");h.x1=a;h.y1=b;h.r1=c;h.x2=d;h.y2=e;h.r2=f;return h};
g.drawImage=function(a,b,c,d,e,f,h,k,l){var m="";a instanceof HTMLCanvasElement&&(m=a.toDataURL());a instanceof HTMLImageElement&&(m=a.src);m={x:0,y:0,width:a.naturalWidth,height:a.naturalHeight,href:m};m.preserveAspectRatio=Ec(d,k)&&Ec(e,l)?"xMidYMid slice":"none";var n="";k/=d;l/=e;if(0!==f||0!==h)n+=" translate("+f+", "+h+")";if(1!==k||1!==l)n+=" scale("+k+", "+l+")";if(0!==b||0!==c)n+=" translate("+-b+", "+-c+")";if(0!==b||0!==c||d!==a.naturalWidth||e!==a.naturalHeight)a="CLIP"+v.Il++,f=Ac(this,
"clipPath",{id:a}),f.appendChild(Ac(this,"rect",{x:b,y:c,width:d,height:e})),this.Cl.appendChild(f),m["clip-path"]="url(#"+a+")";Jc(this,"image",m,this.Pi,n);this.addElement("image",m)};g.fill=function(){Dc(this,"fill",this.path,this.Pi)};g.ug=function(){this.pw?this.clip():this.fill()};g.fillRect=function(a,b,c,d){Kc(this,"fill",[a,b,c,d],this.Pi)};
g.fillText=function(a,b,c){a=[a,b,c];b=this.textAlign;"left"===b?b="start":"right"===b?b="end":"center"===b&&(b="middle");b={x:a[1],y:a[2],style:"font: "+this.font,"text-anchor":b};Jc(this,"fill",b,this.Pi);this.addElement("text",b,a[0])};g.lineTo=function(a,b){this.path.push(["L",a,b])};g.moveTo=function(a,b){this.path.push(["M",a,b])};g.quadraticCurveTo=function(a,b,c,d){this.path.push(["Q",a,b,c,d])};g.rect=function(a,b,c,d){this.path.push(["M",a,b],["L",a+c,b],["L",a+c,b+d],["L",a,b+d],["z"])};
g.restore=function(){this.Pi=this.stack.pop();this.path=this.stack.pop();var a=this.stack.pop();this.fillStyle=a.fillStyle;this.font=a.font;this.globalAlpha=a.globalAlpha;this.lineCap=a.lineCap;this.Vm=a.Vm;this.lineJoin=a.lineJoin;this.lineWidth=a.lineWidth;this.miterLimit=a.miterLimit;this.shadowBlur=a.shadowBlur;this.shadowColor=a.shadowColor;this.shadowOffsetX=a.shadowOffsetX;this.shadowOffsetY=a.shadowOffsetY;this.strokeStyle=a.strokeStyle;this.textAlign=a.textAlign};
g.save=function(){this.stack.push({fillStyle:this.fillStyle,font:this.font,globalAlpha:this.globalAlpha,lineCap:this.lineCap,Vm:this.Vm,lineJoin:this.lineJoin,lineWidth:this.lineWidth,miterLimit:this.miterLimit,shadowBlur:this.shadowBlur,shadowColor:this.shadowColor,shadowOffsetX:this.shadowOffsetX,shadowOffsetY:this.shadowOffsetY,strokeStyle:this.strokeStyle,textAlign:this.textAlign});for(var a=[],b=0;b<this.path.length;b++)a.push(this.path[b]);this.stack.push(a);this.stack.push(this.Pi.copy())};
g.setTransform=function(a,b,c,d,e,f){1===a&&0===b&&0===c&&1===d&&0===e&&0===f||Bc(this,a,b,c,d,e,f)};g.scale=function(){};g.stroke=function(){Dc(this,"stroke",this.path,this.Pi)};g.bj=function(){this.rn||this.stroke()};g.strokeRect=function(a,b,c,d){Kc(this,"stroke",[a,b,c,d],this.Pi)};function Ac(a,b,c,d){a=a.document.createElementNS(a.ZH,b);if(v.Ta(c))for(var e in c)a.setAttributeNS("href"===e?"http://www.w3.org/1999/xlink":"",e,c[e]);void 0!==d&&(a.textContent=d);return a}
g.addElement=function(a,b,c){a=Ac(this,a,b,c);0<this.tf.length?this.tf[this.tf.length-1].appendChild(a):this.Cl.appendChild(a);return this.Mw=a};
function Jc(a,b,c,d,e){1!==a.globalAlpha&&(c.opacity=a.globalAlpha);"fill"===b?(/^rgba\(/.test(a.fillStyle)?(a=/^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(a.fillStyle),c.fill="rgb("+a[1]+","+a[2]+","+a[3]+")",c["fill-opacity"]=a[4]):c.fill=a.fillStyle instanceof yc?Lc(a,a.fillStyle):a.fillStyle,c.stroke="none"):"stroke"===b&&(c.fill="none",/^rgba\(/.test(a.strokeStyle)?(b=/^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(a.strokeStyle),
c.stroke="rgb("+b[1]+","+b[2]+","+b[3]+")",c["stroke-opacity"]=b[4]):c.stroke=a.strokeStyle instanceof yc?Lc(a,a.strokeStyle):a.strokeStyle,c["stroke-width"]=a.lineWidth,c["stroke-linecap"]=a.lineCap,c["stroke-linejoin"]=a.lineJoin,c["stroke-miterlimit"]=a.miterLimit);d=d.ef;d="matrix("+d[0]+", "+d[1]+", "+d[2]+", "+d[3]+", "+d[4]+", "+d[5]+")";void 0!==e&&(d+=e);c.transform=d}
function Lc(a,b){var c="GRAD"+v.Il++,d;if("linear"===b.type)d={x1:b.x1,x2:b.x2,y1:b.y1,y2:b.y2,id:c,gradientUnits:"userSpaceOnUse"},d=Ac(a,"linearGradient",d);else if("radial"===b.type)d={x1:b.x1,x2:b.x2,y1:b.y1,y2:b.y2,r1:b.r1,r2:b.r2,id:c},d=Ac(a,"radialGradient",d);else if("pattern"===b.type){var e=b.pattern;d={width:e.width,height:e.height,id:c,patternUnits:"userSpaceOnUse"};var f="";e instanceof HTMLCanvasElement&&(f=e.toDataURL());e instanceof HTMLImageElement&&(f=e.src);e={x:0,y:0,width:e.width,
height:e.height,href:f};d=Ac(a,"pattern",d);d.appendChild(Ac(a,"image",e))}else throw Error("invalid gradient");for(var e=b.pD,f=e.length,h=[],k=0;k<f;k++){var l=e[k],m=l.color,l={offset:l.offset,"stop-color":m};/^rgba\(/.test(m)&&(m=/^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(m),l["stop-color"]="rgb("+m[1]+","+m[2]+","+m[3]+")",l["stop-opacity"]=m[4]);h.push(l)}h.sort(function(a,b){return a.offset>b.offset?1:-1});for(k=0;k<f;k++)d.appendChild(Ac(a,"stop",
h[k]));a.Cl.appendChild(d);return"url(#"+c+")"}function Kc(a,b,c,d){c={x:c[0],y:c[1],width:c[2],height:c[3]};Jc(a,b,c,d);a.addElement("rect",c)}
function Dc(a,b,c,d){for(var e=[],f=0;f<c.length;f++){var h=v.dl(c[f]),k=[h.shift()];if("A"===k[0])k.push(h.shift()+","+h.shift(),h.shift(),h.shift()+","+h.shift(),h.shift()+","+h.shift());else for(;h.length;)k.push(h.shift()+","+h.shift());e.push(k.join(" "))}c={d:e.join(" ")};Jc(a,b,c,d);"clipPath"===b?(b="CLIP"+v.Il++,d=Ac(a,"clipPath",{id:b}),d.appendChild(Ac(a,"path",c)),a.Cl.appendChild(d),0<a.tf.length&&a.tf[a.tf.length-1].setAttributeNS(null,"clip-path","url(#"+b+")")):a.addElement("path",
c)}function Cc(a,b,c,d,e,f,h){var k=Math.abs(e-f);if(e!==f){var l=b+d*Math.cos(f);f=c+d*Math.sin(f);k>=2*Math.PI?(Cc(a,b,c,d,e,e+Math.PI,h),Cc(a,b,c,d,e+Math.PI,e+2*Math.PI,h),a.path.push(["M",l,f])):(b+=d*Math.cos(e),c+=d*Math.sin(e),k=180*k/Math.PI,e=h?0:1,h=180<=k===!!h?0:1,0!==a.path.length?a.path.push(["L",b,c]):a.path.push(["M",b,c]),a.path.push(["A",d,d,k,h,e,l,f]))}}function Bc(a,b,c,d,e,f,h){var k=new xc;k.ef=[b,c,d,e,f,h];b={};Jc(a,"g",b,k);k=a.addElement("g",b);a.tf.push(k)}
g.Ya=function(){if(0!==this.shadowOffsetX||0!==this.shadowOffsetY||0!==this.shadowBlur){var a="SHADOW"+v.Il++,b=this.addElement("filter",{id:a,x:"-100%",y:"-100%",width:"300%",height:"300%"},null),c,d,e,f,h;c=Ac(this,"feGaussianBlur",{"in":"SourceAlpha",result:"blur",GK:this.shadowBlur/2});d=Ac(this,"feFlood",{"in":"blur",result:"flood","flood-color":this.shadowColor});e=Ac(this,"feComposite",{"in":"flood",in2:"blur",operator:"in",result:"comp"});f=Ac(this,"feOffset",{"in":"comp",result:"offsetBlur",
dx:this.shadowOffsetX,dy:this.shadowOffsetY});h=Ac(this,"feMerge",{});h.appendChild(Ac(this,"feMergeNode",{"in":"offsetBlur"}));h.appendChild(Ac(this,"feMergeNode",{"in":"SourceGraphic"}));b.appendChild(c);b.appendChild(d);b.appendChild(e);b.appendChild(f);b.appendChild(h);0<this.tf.length&&this.tf[this.tf.length-1].setAttributeNS(null,"filter","url(#"+a+")")}};g.rA=function(a,b,c){this.ns=a;this.os=b;this.og=c};g.cn=function(){this.shadowBlur=this.shadowOffsetY=this.shadowOffsetX=0};
g.dn=function(){this.shadowOffsetX=this.ns;this.shadowOffsetY=this.os;this.shadowBlur=this.og};g.pz=function(){return!1};g.nz=function(){};function Mc(a,b){this.ownerDocument=void 0===b?document:b;var c=this.ownerDocument.createElement("canvas");c.tabIndex=0;this.xd=c;this.cl=new Nc(c);c.W=a;Object.seal(this)}g=Mc.prototype;g.toDataURL=function(a,b){return this.xd.toDataURL(a,b)};g.getBoundingClientRect=function(){return this.xd.getBoundingClientRect()};g.focus=function(){return this.xd.focus()};
g.addEventListener=function(a,b,c){this.xd.addEventListener(a,b,c)};g.removeEventListener=function(a,b,c){this.xd.removeEventListener(a,b,c)};v.defineProperty(Mc,{width:"width"},function(){return this.xd.width},function(a){this.xd.width=a});v.defineProperty(Mc,{height:"height"},function(){return this.xd.height},function(a){this.xd.height=a});v.defineProperty(Mc,{style:"style"},function(){return this.xd.style},function(a){this.xd.style=a});
function Nc(a){a.getContext&&a.getContext("2d")||v.k("Browser does not support HTML Canvas Element");this.wa=a.getContext("2d");this.Ix=this.Kx=this.Jx="";this.rn=!1;this.og=this.os=this.ns=0;Object.seal(this)}v.defineProperty(Nc,{fillStyle:"fillStyle"},function(){return this.wa.fillStyle},function(a){this.Ix!==a&&(this.Ix=this.wa.fillStyle=a)});v.defineProperty(Nc,{font:"font"},function(){return this.wa.font},function(a){this.Jx!==a&&(this.Jx=this.wa.font=a)});
v.defineProperty(Nc,{globalAlpha:"globalAlpha"},function(){return this.wa.globalAlpha},function(a){this.wa.globalAlpha=a});v.defineProperty(Nc,{lineCap:"lineCap"},function(){return this.wa.lineCap},function(a){this.wa.lineCap=a});v.defineProperty(Nc,{Vm:"lineDashOffset"},function(){return this.wa.Vm},function(a){this.wa.Vm=a});v.defineProperty(Nc,{lineJoin:"lineJoin"},function(){return this.wa.lineJoin},function(a){this.wa.lineJoin=a});
v.defineProperty(Nc,{lineWidth:"lineWidth"},function(){return this.wa.lineWidth},function(a){this.wa.lineWidth=a});v.defineProperty(Nc,{miterLimit:"miterLimit"},function(){return this.wa.miterLimit},function(a){this.wa.miterLimit=a});v.defineProperty(Nc,{shadowBlur:"shadowBlur"},function(){return this.wa.shadowBlur},function(a){this.wa.shadowBlur=a});v.defineProperty(Nc,{shadowColor:"shadowColor"},function(){return this.wa.shadowColor},function(a){this.wa.shadowColor=a});
v.defineProperty(Nc,{shadowOffsetX:"shadowOffsetX"},function(){return this.wa.shadowOffsetX},function(a){this.wa.shadowOffsetX=a});v.defineProperty(Nc,{shadowOffsetY:"shadowOffsetY"},function(){return this.wa.shadowOffsetY},function(a){this.wa.shadowOffsetY=a});v.defineProperty(Nc,{strokeStyle:"strokeStyle"},function(){return this.wa.strokeStyle},function(a){this.Kx!==a&&(this.Kx=this.wa.strokeStyle=a)});
v.defineProperty(Nc,{textAlign:"textAlign"},function(){return this.wa.textAlign},function(a){this.wa.textAlign=a});v.defineProperty(Nc,{pt:"imageSmoothingEnabled"},function(){return this.wa.pt},function(a){this.wa.pt=a});g=Nc.prototype;g.arc=function(a,b,c,d,e,f){this.wa.arc(a,b,c,d,e,f)};g.beginPath=function(){this.wa.beginPath()};g.bezierCurveTo=function(a,b,c,d,e,f){this.wa.bezierCurveTo(a,b,c,d,e,f)};g.clearRect=function(a,b,c,d){this.wa.clearRect(a,b,c,d)};g.clip=function(){this.wa.clip()};
g.closePath=function(){this.wa.closePath()};g.createLinearGradient=function(a,b,c,d){return this.wa.createLinearGradient(a,b,c,d)};g.createPattern=function(a,b){return this.wa.createPattern(a,b)};g.createRadialGradient=function(a,b,c,d,e,f){return this.wa.createRadialGradient(a,b,c,d,e,f)};g.drawImage=function(a,b,c,d,e,f,h,k,l){void 0===d?this.wa.drawImage(a,b,c):this.wa.drawImage(a,b,c,d,e,f,h,k,l)};g.fill=function(){this.wa.fill()};g.fillRect=function(a,b,c,d){this.wa.fillRect(a,b,c,d)};
g.fillText=function(a,b,c){this.wa.fillText(a,b,c)};g.getImageData=function(a,b,c,d){return this.wa.getImageData(a,b,c,d)};g.lineTo=function(a,b){this.wa.lineTo(a,b)};g.measureText=function(a){return this.wa.measureText(a)};g.moveTo=function(a,b){this.wa.moveTo(a,b)};g.quadraticCurveTo=function(a,b,c,d){this.wa.quadraticCurveTo(a,b,c,d)};g.rect=function(a,b,c,d){this.wa.rect(a,b,c,d)};g.restore=function(){this.wa.restore()};Nc.prototype.rotate=function(a){this.wa.rotate(a)};g=Nc.prototype;
g.save=function(){this.wa.save()};g.setTransform=function(a,b,c,d,e,f){this.wa.setTransform(a,b,c,d,e,f)};g.scale=function(a,b){this.wa.scale(a,b)};g.stroke=function(){this.wa.stroke()};g.transform=function(a,b,c,d,e,f){1===a&&0===b&&0===c&&1===d&&0===e&&0===f||this.wa.transform(a,b,c,d,e,f)};g.translate=function(a,b){this.wa.translate(a,b)};
g.ug=function(a){if(a instanceof ha&&a.type===Vc){var b=a.vn;a=a.Ox;a>b?(this.scale(b/a,1),this.translate((a-b)/2,0)):b>a&&(this.scale(1,a/b),this.translate(0,(b-a)/2));this.rn?this.clip():this.fill();a>b?(this.translate(-(a-b)/2,0),this.scale(1/(b/a),1)):b>a&&(this.translate(0,-(b-a)/2),this.scale(1,1/(a/b)))}else this.rn?this.clip():this.fill()};g.bj=function(){this.rn||this.stroke()};v.defineProperty(Nc,{pw:"clipInsteadOfFill"},function(){return this.rn},function(a){this.rn=a});g=Nc.prototype;
g.rA=function(a,b,c){this.ns=a;this.os=b;this.og=c};g.cn=function(){this.shadowBlur=this.shadowOffsetY=this.shadowOffsetX=0};g.dn=function(){this.shadowOffsetX=this.ns;this.shadowOffsetY=this.os;this.shadowBlur=this.og};g.pz=function(a,b){var c=this.wa;if(void 0!==c.setLineDash)c.setLineDash(a),c.lineDashOffset=b;else if(void 0!==c.webkitLineDash)c.webkitLineDash=a,c.webkitLineDashOffset=b;else if(void 0!==c.mozDash)c.mozDash=a,c.mozDashOffset=b;else return!1;return!0};
g.nz=function(){var a=this.wa;void 0!==a.setLineDash?(a.setLineDash(v.fj),a.lineDashOffset=0):void 0!==a.webkitLineDash?(a.webkitLineDash=v.fj,a.webkitLineDashOffset=0):void 0!==a.mozDash&&(a.mozDash=null,a.mozDashOffset=0)};function Wc(a,b){b&&(a.Jx="");a.Kx="";a.Ix=""}
var Xc=(Math.sqrt(2)-1)/3*4,Yc=(new z(0,0)).Ea(),Zc=(new B(0,0,0,0)).Ea(),$c=(new eb(0,0,0,0)).Ea(),id=(new eb(2,2,2,2)).Ea(),jd=(new z(6,6)).Ea(),kd=(new z(-Infinity,-Infinity)).Ea(),ld=(new z(Infinity,Infinity)).Ea(),md=(new ia(0,0)).Ea(),nd=(new ia(1,1)).Ea(),od=(new ia(6,6)).Ea(),pd=(new ia(8,8)).Ea(),qd=(new ia(10,10)).Ea(),Ad=(new ia(Infinity,Infinity)).Ea(),Bd=(new z(NaN,NaN)).Ea(),Cd=(new ia(NaN,NaN)).Ea(),Dd=(new B(NaN,NaN,NaN,NaN)).Ea(),Ed=(new L(.156,.156)).Ea(),Fd=(new L(.844,.844)).Ea(),
Gd=new ta,Hd=new ta,Id=null;function Jd(a){if(0>=a)return 0;var b=Id;if(null===b){for(var b=[],c=0;2E3>=c;c++)b[c]=Math.sqrt(c);Id=b}return 1>a?(c=1/a,2E3>=c?1/b[c|0]:Math.sqrt(a)):2E3>=a?b[a|0]:Math.sqrt(a)}function K(a,b){var c=a-b;return.5>c&&-.5<c}function Ec(a,b){var c=a-b;return 5E-8>c&&-5E-8<c}
function Kd(a,b,c,d,e,f,h){0>=e&&(e=1E-6);var k=0,l=0,m=0,n=0;a<c?(l=a,k=c):(l=c,k=a);b<d?(n=b,m=d):(n=d,m=b);if(a===c)return n<=h&&h<=m&&a-e<=f&&f<=a+e;if(b===d)return l<=f&&f<=k&&b-e<=h&&h<=b+e;k+=e;l-=e;if(l<=f&&f<=k&&(m+=e,n-=e,n<=h&&h<=m))if(k-l>m-n)if(a-c>e||c-a>e){if(f=(d-b)/(c-a)*(f-a)+b,f-e<=h&&h<=f+e)return!0}else return!0;else if(b-d>e||d-b>e){if(h=(c-a)/(d-b)*(h-b)+a,h-e<=f&&f<=h+e)return!0}else return!0;return!1}
function Qd(a,b,c,d,e,f,h,k,l,m,n,p){if(Kd(a,b,h,k,p,c,d)&&Kd(a,b,h,k,p,e,f))return Kd(a,b,h,k,p,m,n);var q=(a+c)/2,r=(b+d)/2,s=(c+e)/2,t=(d+f)/2;e=(e+h)/2;f=(f+k)/2;d=(q+s)/2;c=(r+t)/2;var s=(s+e)/2,t=(t+f)/2,u=(d+s)/2,y=(c+t)/2;return Qd(a,b,q,r,d,c,u,y,l,m,n,p)||Qd(u,y,s,t,e,f,h,k,l,m,n,p)}
function Rd(a,b,c,d,e,f,h,k,l,m){if(Kd(a,b,h,k,l,c,d)&&Kd(a,b,h,k,l,e,f))ab(m,a,b,0,0),ab(m,h,k,0,0);else{var n=(a+c)/2,p=(b+d)/2,q=(c+e)/2,r=(d+f)/2;e=(e+h)/2;f=(f+k)/2;d=(n+q)/2;c=(p+r)/2;var q=(q+e)/2,r=(r+f)/2,s=(d+q)/2,t=(c+r)/2;Rd(a,b,n,p,d,c,s,t,l,m);Rd(s,t,q,r,e,f,h,k,l,m)}}
function Sd(a,b,c,d,e,f,h,k,l,m){if(Kd(a,b,h,k,l,c,d)&&Kd(a,b,h,k,l,e,f))0===m.length&&(m.push(a),m.push(b)),m.push(h),m.push(k);else{var n=(a+c)/2,p=(b+d)/2,q=(c+e)/2,r=(d+f)/2;e=(e+h)/2;f=(f+k)/2;d=(n+q)/2;c=(p+r)/2;var q=(q+e)/2,r=(r+f)/2,s=(d+q)/2,t=(c+r)/2;Sd(a,b,n,p,d,c,s,t,l,m);Sd(s,t,q,r,e,f,h,k,l,m)}}
function Td(a,b,c,d,e,f,h,k,l,m,n,p,q,r){var s=1-l;a=a*s+c*l;b=b*s+d*l;c=c*s+e*l;d=d*s+f*l;e=e*s+h*l;f=f*s+k*l;k=a*s+c*l;h=b*s+d*l;c=c*s+e*l;d=d*s+f*l;m.x=a;m.y=b;n.x=k;n.y=h;p.x=k*s+c*l;p.y=h*s+d*l;q.x=c;q.y=d;r.x=e;r.y=f}function Ud(a,b,c,d,e,f,h,k,l,m){if(Kd(a,b,e,f,m,c,d))return Kd(a,b,e,f,m,k,l);var n=(a+c)/2,p=(b+d)/2;c=(c+e)/2;d=(d+f)/2;var q=(n+c)/2,r=(p+d)/2;return Ud(a,b,n,p,q,r,h,k,l,m)||Ud(q,r,c,d,e,f,h,k,l,m)}
function Vd(a,b,c,d,e,f,h,k){if(Kd(a,b,e,f,h,c,d))ab(k,a,b,0,0),ab(k,e,f,0,0);else{var l=(a+c)/2,m=(b+d)/2;c=(c+e)/2;d=(d+f)/2;var n=(l+c)/2,p=(m+d)/2;Vd(a,b,l,m,n,p,h,k);Vd(n,p,c,d,e,f,h,k)}}function Wd(a,b,c,d,e,f,h,k){if(Kd(a,b,e,f,h,c,d))0===k.length&&(k.push(a),k.push(b)),k.push(e),k.push(f);else{var l=(a+c)/2,m=(b+d)/2;c=(c+e)/2;d=(d+f)/2;var n=(l+c)/2,p=(m+d)/2;Wd(a,b,l,m,n,p,h,k);Wd(n,p,c,d,e,f,h,k)}}
function Xd(a,b,c,d,e,f,h,k,l,m,n,p,q,r){0>=q&&(q=1E-6);if(Kd(a,b,h,k,q,c,d)&&Kd(a,b,h,k,q,e,f)){var s=(a-h)*(m-p)-(b-k)*(l-n);if(0===s)return!1;q=((a*k-b*h)*(l-n)-(a-h)*(l*p-m*n))/s;s=((a*k-b*h)*(m-p)-(b-k)*(l*p-m*n))/s;if((l>n?l-n:n-l)<(m>p?m-p:p-m)){if(h=l=0,b<k?(l=b,h=k):(l=k,h=b),s<l||s>h)return!1}else if(a<h?l=a:(l=h,h=a),q<l||q>h)return!1;r.x=q;r.y=s;return!0}var s=(a+c)/2,t=(b+d)/2;c=(c+e)/2;d=(d+f)/2;e=(e+h)/2;f=(f+k)/2;var u=(s+c)/2,y=(t+d)/2;c=(c+e)/2;d=(d+f)/2;var w=(u+c)/2,x=(y+d)/2,
A=(n-l)*(n-l)+(p-m)*(p-m),H=!1;Xd(a,b,s,t,u,y,w,x,l,m,n,p,q,r)&&(b=(r.x-l)*(r.x-l)+(r.y-m)*(r.y-m),b<A&&(A=b,H=!0));a=r.x;s=r.y;Xd(w,x,c,d,e,f,h,k,l,m,n,p,q,r)&&(b=(r.x-l)*(r.x-l)+(r.y-m)*(r.y-m),b<A?H=!0:(r.x=a,r.y=s));return H}
function Yd(a,b,c,d,e,f,h,k,l,m,n,p,q){var r=0;0>=q&&(q=1E-6);if(Kd(a,b,h,k,q,c,d)&&Kd(a,b,h,k,q,e,f)){q=(a-h)*(m-p)-(b-k)*(l-n);if(0===q)return r;var s=((a*k-b*h)*(l-n)-(a-h)*(l*p-m*n))/q,t=((a*k-b*h)*(m-p)-(b-k)*(l*p-m*n))/q;if(s>=n)return r;if((l>n?l-n:n-l)<(m>p?m-p:p-m)){if(a=l=0,b<k?(l=b,a=k):(l=k,a=b),t<l||t>a)return r}else if(a<h?(l=a,a=h):l=h,s<l||s>a)return r;0<q?r++:0>q&&r--}else{var s=(a+c)/2,t=(b+d)/2,u=(c+e)/2,y=(d+f)/2;e=(e+h)/2;f=(f+k)/2;d=(s+u)/2;c=(t+y)/2;var u=(u+e)/2,y=(y+f)/2,
w=(d+u)/2,x=(c+y)/2,r=r+Yd(a,b,s,t,d,c,w,x,l,m,n,p,q),r=r+Yd(w,x,u,y,e,f,h,k,l,m,n,p,q)}return r}
function Oa(a,b,c,d,e,f,h){if(Ec(a,c)){var k=0;c=0;b<d?(k=b,c=d):(k=d,c=b);d=f;if(d<k)return h.x=a,h.y=k,!1;if(d>c)return h.x=a,h.y=c,!1;h.x=a;h.y=d;return!0}if(Ec(b,d)){a<c?k=a:(k=c,c=a);d=e;if(d<k)return h.x=k,h.y=b,!1;if(d>c)return h.x=c,h.y=b,!1;h.x=d;h.y=b;return!0}k=((a-e)*(a-c)+(b-f)*(b-d))/((c-a)*(c-a)+(d-b)*(d-b));if(-5E-6>k)return h.x=a,h.y=b,!1;if(1.000005<k)return h.x=c,h.y=d,!1;h.x=a+k*(c-a);h.y=b+k*(d-b);return!0}
function Zd(a,b,c,d,e,f,h,k,l){if(K(a,c)&&K(b,d))return l.x=a,l.y=b,!1;if(Ec(e,h)){if(Ec(a,c))return Oa(a,b,c,d,e,f,l),!1;f=(d-b)/(c-a)*(e-a)+b;return Oa(a,b,c,d,e,f,l)}k=(k-f)/(h-e);if(Ec(a,c)){f=k*(a-e)+f;c=h=0;b<d?(h=b,c=d):(h=d,c=b);if(f<h)return l.x=a,l.y=h,!1;if(f>c)return l.x=a,l.y=c,!1;l.x=a;l.y=f;return!0}h=(d-b)/(c-a);if(Ec(k,h))return Oa(a,b,c,d,e,f,l),!1;e=(h*a-k*e+f-b)/(h-k);if(Ec(h,0)){a<c?h=a:(h=c,c=a);if(e<h)return l.x=h,l.y=b,!1;if(e>c)return l.x=c,l.y=b,!1;l.x=e;l.y=b;return!0}f=
h*(e-a)+b;return Oa(a,b,c,d,e,f,l)}function $d(a,b,c,d,e,f,h,k,l){var m=1E21,n=a,p=b;if(Zd(a,b,a,d,e,f,h,k,l)){var q=(l.x-e)*(l.x-e)+(l.y-f)*(l.y-f);q<m&&(m=q,n=l.x,p=l.y)}Zd(c,b,c,d,e,f,h,k,l)&&(q=(l.x-e)*(l.x-e)+(l.y-f)*(l.y-f),q<m&&(m=q,n=l.x,p=l.y));Zd(a,b,c,b,e,f,h,k,l)&&(q=(l.x-e)*(l.x-e)+(l.y-f)*(l.y-f),q<m&&(m=q,n=l.x,p=l.y));Zd(a,d,c,d,e,f,h,k,l)&&(q=(l.x-e)*(l.x-e)+(l.y-f)*(l.y-f),q<m&&(m=q,n=l.x,p=l.y));l.x=n;l.y=p;return 1E21>m}
function ae(a,b,c,d,e,f,h,k,l){c=a-c;var m=e-h,n=h=0;0===c||0===m?0===c?(k=(f-k)/m,h=a,n=k*h+(f-k*e)):(d=(b-d)/c,h=e,n=d*h+(b-d*a)):(d=(b-d)/c,k=(f-k)/m,a=b-d*a,h=(f-k*e-a)/(d-k),n=d*h+a);l.m(h,n);return l}
function ke(a,b,c){var d=b.x,e=b.y,f=c.x,h=c.y,k=a.left,l=a.right,m=a.top,n=a.bottom;return d===f?(f=a=0,e<h?(a=e,f=h):(a=h,f=e),k<=d&&d<=l&&a<=n&&f>=m):e===h?(d<f?a=d:(a=f,f=d),m<=e&&e<=n&&a<=l&&f>=k):a.Fa(b)||a.Fa(c)||le(k,m,l,m,d,e,f,h)||le(l,m,l,n,d,e,f,h)||le(l,n,k,n,d,e,f,h)||le(k,n,k,m,d,e,f,h)?!0:!1}function le(a,b,c,d,e,f,h,k){return 0>=me(a,b,c,d,e,f)*me(a,b,c,d,h,k)&&0>=me(e,f,h,k,a,b)*me(e,f,h,k,c,d)}
function me(a,b,c,d,e,f){c-=a;d-=b;a=e-a;b=f-b;f=a*d-b*c;0===f&&(f=a*c+b*d,0<f&&(f=(a-c)*c+(b-d)*d,0>f&&(f=0)));return 0>f?-1:0<f?1:0}function ne(a){0>a&&(a+=360);360<=a&&(a-=360);return a}
function oe(a,b,c,d){var e=Math.PI;d||(b*=e/180,c*=e/180);d=b<c?1:-1;var f=[],h=e/2,k=b;for(b=Math.min(2*e,Math.abs(c-b));1E-5<b;){c=k+d*Math.min(b,h);var e=(c-k)/2,l=a*Math.cos(e),m=a*Math.sin(e),n=-m,p=l*l+n*n,q=p+l*l+n*m,p=4/3*(Math.sqrt(2*p*q)-q)/(l*m-n*l),m=l-p*n,l=n+p*l,n=-l,p=e+k,e=Math.cos(p),p=Math.sin(p);f.push([0+a*Math.cos(k),0+a*Math.sin(k),0+m*e-l*p,0+m*p+l*e,0+m*e-n*p,0+m*p+n*e,0+a*Math.cos(c),0+a*Math.sin(c)]);b-=Math.abs(c-k);k=c}return f}
function Pa(a,b,c,d,e,f,h){c=Math.floor((a-c)/e)*e+c;d=Math.floor((b-d)/f)*f+d;var k=c;c+e-a<e/2&&(k=c+e);a=d;d+f-b<f/2&&(a=d+f);h.m(k,a)}function pe(a,b){var c=Math.max(a,b),d=Math.min(a,b),e=1,f=1;do e=c%d,c=f=d,d=e;while(0<e);return f}
function qe(a,b,c,d){var e=0>c,f=0>d,h=0,k=h=0;a<b?(h=1,k=0):(h=0,k=1);var l=0,m=0,n=0,p=0,l=0===h?a:b,n=0===h?c:d;if(0===h?e:f)n=-n;h=k;m=0===h?a:b;p=0===h?c:d;if(0===h?e:f)p=-p;a=a=0;if(0<p)if(0<n){b=l*l;a=m*m;l*=n;c=m*p;d=-a+c;e=-a+Math.sqrt(l*l+c*c);m=d;for(f=0;9999999999>f;++f){m=.5*(d+e);if(m===d||m===e)break;k=l/(m+b);h=c/(m+a);k=k*k+h*h-1;if(0<k)d=m;else if(0>k)e=m;else break}n=b*n/(m+b)-n;p=a*p/(m+a)-p;a=Math.sqrt(n*n+p*p)}else a=Math.abs(p-m);else p=l*l-m*m,a=l*n,a<p?(p=a/p,a=m*Math.sqrt(Math.abs(1-
p*p)),n=l*p-n,a=Math.sqrt(n*n+a*a)):a=Math.abs(n-l);return a}function re(a){1<arguments.length&&v.k("Geometry constructor can take at most one optional argument, the Geometry type.");v.ec(this);this.D=!1;void 0===a&&(a=De);this.ia=a;this.Bb=this.vb=this.Cc=this.vc=0;this.kj=new I(Ee);this.Su=this.kj.F;this.Cu=(new B).freeze();this.$a=!0;this.Nh=tb;this.Oh=Fb;this.Tn=this.Vn=NaN;this.mi=Fe}v.ea("Geometry",re);v.Uh(re);
re.prototype.copy=function(){var a=new re;a.ia=this.ia;a.vc=this.vc;a.Cc=this.Cc;a.vb=this.vb;a.Bb=this.Bb;for(var b=this.kj.n,c=b.length,d=a.kj,e=0;e<c;e++){var f=b[e].copy();d.add(f)}a.Su=this.Su;a.Cu.assign(this.Cu);a.$a=this.$a;a.Nh=this.Nh.R();a.Oh=this.Oh.R();a.Vn=this.Vn;a.Tn=this.Tn;a.mi=this.mi;return a};var Ge;re.Line=Ge=v.p(re,"Line",0);var He;re.Rectangle=He=v.p(re,"Rectangle",1);var Ie;re.Ellipse=Ie=v.p(re,"Ellipse",2);var De;re.Path=De=v.p(re,"Path",3);
re.prototype.Ea=function(){this.freeze();Object.freeze(this);return this};re.prototype.freeze=function(){this.D=!0;var a=this.Ub;a.freeze();for(var a=a.n,b=a.length,c=0;c<b;c++)a[c].freeze();return this};re.prototype.Oa=function(){Object.isFrozen(this)&&v.k("cannot thaw constant: "+this);this.D=!1;var a=this.Ub;a.Oa();for(var a=a.n,b=a.length,c=0;c<b;c++)a[c].Oa();return this};
re.prototype.equalsApprox=re.prototype.Fc=function(a){if(!(a instanceof re))return!1;if(this.type!==a.type)return this.type===Ge&&a.type===De?Ze(this,a):a.type===Ge&&this.type===De?Ze(a,this):!1;if(this.type===De){var b=this.Ub.n;a=a.Ub.n;var c=b.length;if(c!==a.length)return!1;for(var d=0;d<c;d++)if(!b[d].Fc(a[d]))return!1;return!0}return K(this.Aa,a.Aa)&&K(this.ya,a.ya)&&K(this.I,a.I)&&K(this.J,a.J)};
function Ze(a,b){if(a.type!==Ge||b.type!==De)return!1;if(1===b.Ub.count){var c=b.Ub.da(0);if(1===c.sb.count&&K(a.Aa,c.Aa)&&K(a.ya,c.ya)&&(c=c.sb.da(0),c.type===$e&&K(a.I,c.I)&&K(a.J,c.J)))return!0}return!1}var af;re.stringify=af=function(a){return a.toString()};
re.prototype.toString=function(a){void 0===a&&(a=-1);switch(this.type){case Ge:return 0>a?"M"+this.Aa.toString()+" "+this.ya.toString()+"L"+this.I.toString()+" "+this.J.toString():"M"+this.Aa.toFixed(a)+" "+this.ya.toFixed(a)+"L"+this.I.toFixed(a)+" "+this.J.toFixed(a);case He:var b=new B(this.Aa,this.ya,0,0);b.pF(this.I,this.J,0,0);return 0>a?"M"+b.x.toString()+" "+b.y.toString()+"H"+b.right.toString()+"V"+b.bottom.toString()+"H"+b.left.toString()+"z":"M"+b.x.toFixed(a)+" "+b.y.toFixed(a)+"H"+b.right.toFixed(a)+
"V"+b.bottom.toFixed(a)+"H"+b.left.toFixed(a)+"z";case Ie:b=new B(this.Aa,this.ya,0,0);b.pF(this.I,this.J,0,0);if(0>a){var c=b.left.toString()+" "+(b.y+b.height/2).toString(),d=b.right.toString()+" "+(b.y+b.height/2).toString();return"M"+c+"A"+(b.width/2).toString()+" "+(b.height/2).toString()+" 0 0 1 "+d+"A"+(b.width/2).toString()+" "+(b.height/2).toString()+" 0 0 1 "+c}c=b.left.toFixed(a)+" "+(b.y+b.height/2).toFixed(a);d=b.right.toFixed(a)+" "+(b.y+b.height/2).toFixed(a);return"M"+c+"A"+(b.width/
2).toFixed(a)+" "+(b.height/2).toFixed(a)+" 0 0 1 "+d+"A"+(b.width/2).toFixed(a)+" "+(b.height/2).toFixed(a)+" 0 0 1 "+c;case De:for(var b="",c=this.Ub.n,d=c.length,e=0;e<d;e++){var f=c[e];0<e&&(b+=" x ");f.ut&&(b+="F ");b+=f.toString(a)}return b;default:return this.type.toString()}};
re.fillPath=function(a){"string"!==typeof a&&v.Ed(a,"string",re,"fillPath:str");a=a.split(/[Xx]/);for(var b=a.length,c="",d=0;d<b;d++)var e=a[d],c=null!==e.match(/[Ff]/)?0===d?c+e:c+("X"+(" "===e[0]?"":" ")+e):c+((0===d?"":"X ")+"F"+(" "===e[0]?"":" ")+e);return c};var bf;
re.parse=bf=function(a,b){function c(){return m>=t-1?!0:null!==l[m+1].match(/[A-Za-z]/)}function d(){m++;return l[m]}function e(){var a=new z(parseFloat(d()),parseFloat(d()));n===n.toLowerCase()&&(a.x=s.x+a.x,a.y=s.y+a.y);return a}function f(){return s=e()}function h(){return r=e()}function k(){return"c"!==p.toLowerCase()&&"s"!==p.toLowerCase()?s:new z(2*s.x-r.x,2*s.y-r.y)}void 0===b&&(b=!1);"string"!==typeof a&&v.Ed(a,"string",re,"parse:str");a=a.replace(/,/gm," ");a=a.replace(/([UuBbMmZzLlHhVvCcSsQqTtAaFf])([UuBbMmZzLlHhVvCcSsQqTtAaFf])/gm,
"$1 $2");a=a.replace(/([UuBbMmZzLlHhVvCcSsQqTtAaFf])([UuBbMmZzLlHhVvCcSsQqTtAaFf])/gm,"$1 $2");a=a.replace(/([UuBbMmZzLlHhVvCcSsQqTtAaFf])([^\s])/gm,"$1 $2");a=a.replace(/([^\s])([UuBbMmZzLlHhVvCcSsQqTtAaFf])/gm,"$1 $2");a=a.replace(/([0-9])([+\-])/gm,"$1 $2");a=a.replace(/(\.[0-9]*)(\.)/gm,"$1 $2");a=a.replace(/([Aa](\s+[0-9]+){3})\s+([01])\s*([01])/gm,"$1 $3 $4 ");a=a.replace(/[\s\r\t\n]+/gm," ");a=a.replace(/^\s+|\s+$/g,"");for(var l=a.split(" "),m=-1,n="",p="",q=new z(0,0),r=new z(0,0),s=new z(0,
0),t=l.length,u=v.s(),y=!1,w=!1,x=!0;!(m>=t-1);)if(p=n,n=d(),""!==n)switch(n.toUpperCase()){case "X":x=!0;w=y=!1;break;case "M":var A=f();null===u.Rb||!0===x?(N(u,A.x,A.y,y,!1,!w),x=!1):u.moveTo(A.x,A.y);for(q=s;!c();)A=f(),u.lineTo(A.x,A.y);break;case "L":for(;!c();)A=f(),u.lineTo(A.x,A.y);break;case "H":for(;!c();)s=A=new z((n===n.toLowerCase()?s.x:0)+parseFloat(d()),s.y),u.lineTo(s.x,s.y);break;case "V":for(;!c();)s=A=new z(s.x,(n===n.toLowerCase()?s.y:0)+parseFloat(d())),u.lineTo(s.x,s.y);break;
case "C":for(;!c();){var H=e(),C=h(),A=f();O(u,H.x,H.y,C.x,C.y,A.x,A.y)}break;case "S":for(;!c();)H=k(),C=h(),A=f(),O(u,H.x,H.y,C.x,C.y,A.x,A.y);break;case "Q":for(;!c();)C=h(),A=f(),cf(u,C.x,C.y,A.x,A.y);break;case "T":for(;!c();)r=C=k(),A=f(),cf(u,C.x,C.y,A.x,A.y);break;case "B":for(;!c();){var A=parseFloat(d()),H=parseFloat(d()),C=parseFloat(d()),T=parseFloat(d()),ba=parseFloat(d()),U=ba,M=!1;c()||(U=parseFloat(d()),c()||(M=0!==parseFloat(d())));n===n.toLowerCase()&&(C+=s.x,T+=s.y);u.arcTo(A,H,
C,T,ba,U,M)}break;case "A":for(;!c();)H=Math.abs(parseFloat(d())),C=Math.abs(parseFloat(d())),T=parseFloat(d()),ba=!!parseFloat(d()),U=!!parseFloat(d()),A=f(),df(u,H,C,T,ba,U,A.x,A.y);break;case "Z":A=u.o.Ub.n[u.o.Ub.length-1];P(u);s=q;break;case "F":A="";for(H=1;l[m+H];)if(null!==l[m+H].match(/[Uu]/))H++;else if(null===l[m+H].match(/[A-Za-z]/))H++;else{A=l[m+H];break}A.match(/[Mm]/)?y=!0:ef(u);break;case "U":A="";for(H=1;l[m+H];)if(null!==l[m+H].match(/[Ff]/))H++;else if(null===l[m+H].match(/[A-Za-z]/))H++;
else{A=l[m+H];break}A.match(/[Mm]/)?w=!0:u.Ya(!1)}q=u.o;v.q(u);if(b)for(u=q.Ub.i;u.next();)A=u.value,A.ut=!0;return q};function ff(a,b){for(var c=a.length,d=v.L(),e=0;e<c;e++){var f=a[e];d.x=f[0];d.y=f[1];b.ub(d);f[0]=d.x;f[1]=d.y;d.x=f[2];d.y=f[3];b.ub(d);f[2]=d.x;f[3]=d.y;d.x=f[4];d.y=f[5];b.ub(d);f[4]=d.x;f[5]=d.y;d.x=f[6];d.y=f[7];b.ub(d);f[6]=d.x;f[7]=d.y}v.v(d)}
re.prototype.Hz=function(){if(this.$a||this.Su!==this.Ub.F)return!0;for(var a=this.Ub.n,b=a.length,c=0;c<b;c++)if(a[c].Hz())return!0;return!1};re.prototype.CA=function(){this.$a=!1;this.Su=this.Ub.F;for(var a=this.Ub.n,b=a.length,c=0;c<b;c++)a[c].CA()};re.prototype.$g=function(){var a=this.Cu;a.Oa();isNaN(this.Vn)||isNaN(this.Tn)?a.m(0,0,0,0):a.m(0,0,this.Vn,this.Tn);gf(this,a,!1);ab(a,0,0,0,0);a.freeze()};
re.prototype.computeBoundsWithoutOrigin=re.prototype.sG=function(){var a=new B;gf(this,a,!0);return a};
function gf(a,b,c){switch(a.type){case Ge:case He:case Ie:c?b.m(a.vc,a.Cc,0,0):ab(b,a.vc,a.Cc,0,0);ab(b,a.vb,a.Bb,0,0);break;case De:var d=a.Ub;a=d.n;for(var d=d.length,e=0;e<d;e++){var f=a[e];c&&0===e?b.m(f.Aa,f.ya,0,0):ab(b,f.Aa,f.ya,0,0);for(var h=f.sb.n,k=h.length,l=f.Aa,m=f.ya,n=0;n<k;n++){var p=h[n];switch(p.type){case $e:case hf:l=p.I;m=p.J;ab(b,l,m,0,0);break;case jf:Rd(l,m,p.rc,p.Kc,p.kh,p.lh,p.I,p.J,.5,b);l=p.I;m=p.J;break;case kf:Vd(l,m,p.rc,p.Kc,p.I,p.J,.5,b);l=p.I;m=p.J;break;case lf:case mf:var q=
p.type===lf?nf(p,f):of(p,f,l,m),r=q.length;if(0===r){l=p.na;m=p.ua;ab(b,l,m,0,0);break}for(var p=null,s=0;s<r;s++)p=q[s],Rd(p[0],p[1],p[2],p[3],p[4],p[5],p[6],p[7],.5,b);null!==p&&(l=p[6],m=p[7]);break;default:v.k("Unknown Segment type: "+p.type)}}}break;default:v.k("Unknown Geometry type: "+a.type)}}re.prototype.normalize=re.prototype.normalize=function(){this.D&&v.ka(this);var a=this.sG();this.offset(-a.x,-a.y);return new z(-a.x,-a.y)};
re.prototype.offset=re.prototype.offset=function(a,b){this.D&&v.ka(this);this.transform(1,0,0,1,a,b);return this};re.prototype.scale=re.prototype.scale=function(a,b){this.D&&v.ka(this);this.transform(a,0,0,b,0,0);return this};re.prototype.rotate=re.prototype.rotate=function(a,b,c){this.D&&v.ka(this);void 0===b&&(b=0);void 0===c&&(c=0);var d=v.ph();d.reset();d.rotate(a,b,c);this.transform(d.m11,d.m12,d.m21,d.m22,d.dx,d.dy);v.Ze(d);return this};
re.prototype.transform=re.prototype.transform=function(a,b,c,d,e,f){var h=0,k=0;switch(this.type){case Ge:case He:case Ie:h=this.vc;k=this.Cc;this.vc=h*a+k*c+e;this.Cc=h*b+k*d+f;h=this.vb;k=this.Bb;this.vb=h*a+k*c+e;this.Bb=h*b+k*d+f;break;case De:for(var l=this.Ub.n,m=l.length,n=0;n<m;n++){var p=l[n],h=p.Aa,k=p.ya;p.Aa=h*a+k*c+e;p.ya=h*b+k*d+f;for(var p=p.sb.n,q=p.length,r=0;r<q;r++){var s=p[r];switch(s.type){case $e:case hf:h=s.I;k=s.J;s.I=h*a+k*c+e;s.J=h*b+k*d+f;break;case jf:h=s.rc;k=s.Kc;s.rc=
h*a+k*c+e;s.Kc=h*b+k*d+f;h=s.kh;k=s.lh;s.kh=h*a+k*c+e;s.lh=h*b+k*d+f;h=s.I;k=s.J;s.I=h*a+k*c+e;s.J=h*b+k*d+f;break;case kf:h=s.rc;k=s.Kc;s.rc=h*a+k*c+e;s.Kc=h*b+k*d+f;h=s.I;k=s.J;s.I=h*a+k*c+e;s.J=h*b+k*d+f;break;case lf:h=s.na;k=s.ua;s.na=h*a+k*c+e;s.ua=h*b+k*d+f;0!==b&&(h=180*Math.atan2(b,a)/Math.PI,0>h&&(h+=360),s.xe+=h);0>a&&(s.xe=180-s.xe,s.Af=-s.Af);0>d&&(s.xe=-s.xe,s.Af=-s.Af);s.radiusX*=Math.sqrt(a*a+c*c);void 0!==s.radiusY&&(s.radiusY*=Math.sqrt(b*b+d*d));break;case mf:h=s.I;k=s.J;s.I=h*
a+k*c+e;s.J=h*b+k*d+f;0!==b&&(h=180*Math.atan2(b,a)/Math.PI,0>h&&(h+=360),s.ej+=h);0>a&&(s.ej=180-s.ej,s.sl=!s.sl);0>d&&(s.ej=-s.ej,s.sl=!s.sl);s.radiusX*=Math.sqrt(a*a+c*c);s.radiusY*=Math.sqrt(b*b+d*d);break;default:v.k("Unknown Segment type: "+s.type)}}}}this.$a=!0;return this};
re.prototype.Fa=function(a,b,c,d){var e=a.x,f=a.y,h=this.mb.x-20;a=a.y;for(var k=0,l=0,m=0,n=0,p=0,q=0,r=this.Ub.n,s=r.length,t=0;t<s;t++){var u=r[t];if(u.ut){if(c&&u.Fa(e,f,b))return!0;for(var y=u.sb,l=u.Aa,m=u.ya,w=l,x=m,A=y.n,H=0;H<=y.length;H++){var C,T;H!==y.length?(C=A[H],T=C.type,p=C.I,q=C.J):(T=$e,p=w,q=x);switch(T){case hf:n=pf(e,f,h,a,l,m,w,x);if(isNaN(n))return!0;k+=n;w=p;x=q;break;case $e:n=pf(e,f,h,a,l,m,p,q);if(isNaN(n))return!0;k+=n;break;case jf:n=Yd(l,m,C.rc,C.Kc,C.kh,C.lh,p,q,h,
a,e,f,.5);k+=n;break;case kf:n=Yd(l,m,(l+2*C.rc)/3,(m+2*C.Kc)/3,(2*C.rc+p)/3,(2*C.Kc+q)/3,p,q,h,a,e,f,.5);k+=n;break;case lf:case mf:T=C.type===lf?nf(C,u):of(C,u,l,m);var ba=T.length;if(0===ba){n=pf(e,f,h,a,l,m,C.na,C.ua);if(isNaN(n))return!0;k+=n;break}for(var U=null,M=0;M<ba;M++){U=T[M];if(0===M){n=pf(e,f,h,a,l,m,U[0],U[1]);if(isNaN(n))return!0;k+=n}n=Yd(U[0],U[1],U[2],U[3],U[4],U[5],U[6],U[7],h,a,e,f,.5);k+=n}null!==U&&(p=U[6],q=U[7]);break;default:v.k("Unknown Segment type: "+C.type)}l=p;m=q}if(0!==
k)return!0;k=0}else if(u.Fa(e,f,d?b:b+2))return!0}return 0!==k};function pf(a,b,c,d,e,f,h,k){if(Kd(e,f,h,k,.05,a,b))return NaN;var l=(a-c)*(f-k);if(0===l)return 0;var m=((a*d-b*c)*(e-h)-(a-c)*(e*k-f*h))/l;b=(a*d-b*c)*(f-k)/l;if(m>=a)return 0;if((e>h?e-h:h-e)<(f>k?f-k:k-f)){if(e=a=0,f<k?(a=f,e=k):(a=k,e=f),b<a||b>e)return 0}else if(e<h?(a=e,e=h):a=h,m<a||m>e)return 0;return 0<l?1:-1}function vf(a,b,c,d){a=a.Ub.n;for(var e=a.length,f=0;f<e;f++)if(a[f].Fa(b,c,d))return!0;return!1}
re.prototype.getPointAlongPath=function(a){(0>a||1<a)&&v.va(a,"0 <= fraction <= 1",re,"getPointAlongPath:fraction");var b=this.Ub.first(),c=v.jb(),d=[];d.push(b.Aa);d.push(b.ya);for(var e=b.Aa,f=b.ya,h=e,k=f,l=b.sb.n,m=l.length,n=0;n<m;n++){var p=l[n];switch(p.ia){case hf:c.push(d);d=[];d.push(p.I);d.push(p.J);e=p.I;f=p.J;h=e;k=f;break;case $e:d.push(p.I);d.push(p.J);e=p.I;f=p.J;break;case jf:Sd(e,f,p.sd,p.Fe,p.Xg,p.ng,p.vb,p.Bb,.5,d);e=p.I;f=p.J;break;case kf:Wd(e,f,p.sd,p.Fe,p.vb,p.Bb,.5,d);e=p.I;
f=p.J;break;case lf:var q=nf(p,b),r=q.length;if(0===r){d.push(p.na);d.push(p.ua);e=p.na;f=p.ua;break}for(var s=0;s<r;s++){var t=q[s];Sd(e,f,t[2],t[3],t[4],t[5],t[6],t[7],.5,d);e=t[6];f=t[7]}break;case mf:q=of(p,b,e,f);r=q.length;for(s=0;s<r;s++)t=q[s],Sd(e,f,t[2],t[3],t[4],t[5],t[6],t[7],.5,d),e=t[6],f=t[7];break;default:v.k("Segment not of valid type")}p.Ch&&(d.push(h),d.push(k))}c.push(d);q=0;h=c.length;for(k=d=b=0;k<h;k++)for(l=c[k],m=l.length,n=0;n<m;n+=2)e=l[n],f=l[n+1],0!==n&&(p=Math.sqrt(Ua(b,
d,e,f)),q+=p),b=e,d=f;a*=q;for(k=q=0;k<h;k++)for(l=c[k],m=l.length,n=0;n<m;n++){e=l[n];f=l[n+1];if(0!==n){p=Math.sqrt(Ua(b,d,e,f));if(q+p>a)return n=(a-q)/p,v.sa(c),new z(b+(e-b)*n,d+(f-d)*n);q+=p}b=e;d=f}v.sa(c);return null};v.defineProperty(re,{type:"type"},function(){return this.ia},function(a){this.ia!==a&&(this.D&&v.ka(this,a),this.ia=a,this.$a=!0)});v.defineProperty(re,{Aa:"startX"},function(){return this.vc},function(a){this.vc!==a&&(this.D&&v.ka(this,a),this.vc=a,this.$a=!0)});
v.defineProperty(re,{ya:"startY"},function(){return this.Cc},function(a){this.Cc!==a&&(this.D&&v.ka(this,a),this.Cc=a,this.$a=!0)});v.defineProperty(re,{I:"endX"},function(){return this.vb},function(a){this.vb!==a&&(this.D&&v.ka(this,a),this.vb=a,this.$a=!0)});v.defineProperty(re,{J:"endY"},function(){return this.Bb},function(a){this.Bb!==a&&(this.D&&v.ka(this,a),this.Bb=a,this.$a=!0)});
v.defineProperty(re,{Ub:"figures"},function(){return this.kj},function(a){this.kj!==a&&(this.D&&v.ka(this,a),this.kj=a,this.$a=!0)});re.prototype.add=re.prototype.add=function(a){this.kj.add(a);return this};re.prototype.setSpots=function(a,b,c,d,e,f,h,k){this.D&&v.ka(this);this.Nh=(new L(a,b,e,f)).freeze();this.Oh=(new L(c,d,h,k)).freeze();return this};v.defineProperty(re,{B:"spot1"},function(){return this.Nh},function(a){this.D&&v.ka(this,a);this.Nh=a.R()});
v.defineProperty(re,{C:"spot2"},function(){return this.Oh},function(a){this.D&&v.ka(this,a);this.Oh=a.R()});v.defineProperty(re,{Md:"defaultStretch"},function(){return this.mi},function(a){this.D&&v.ka(this,a);this.mi=a});v.u(re,{mb:"bounds"},function(){this.Hz()&&(this.CA(),this.$g());return this.Cu});function Ee(a,b,c,d){v.ec(this);this.D=!1;void 0===c&&(c=!0);this.Sl=c;void 0===d&&(d=!0);this.Wn=d;this.vc=void 0!==a?a:0;this.Cc=void 0!==b?b:0;this.so=new I(wf);this.Rv=this.so.F;this.$a=!0}
v.ea("PathFigure",Ee);v.Uh(Ee);Ee.prototype.copy=function(){var a=new Ee;a.Sl=this.Sl;a.Wn=this.Wn;a.vc=this.vc;a.Cc=this.Cc;for(var b=this.so.n,c=b.length,d=a.so,e=0;e<c;e++){var f=b[e].copy();d.add(f)}a.Rv=this.Rv;a.$a=this.$a;return a};Ee.prototype.equalsApprox=Ee.prototype.Fc=function(a){if(!(a instanceof Ee&&K(this.Aa,a.Aa)&&K(this.ya,a.ya)))return!1;var b=this.sb.n;a=a.sb.n;var c=b.length;if(c!==a.length)return!1;for(var d=0;d<c;d++)if(!b[d].Fc(a[d]))return!1;return!0};g=Ee.prototype;
g.toString=function(a){void 0===a&&(a=-1);for(var b=0>a?"M"+this.Aa.toString()+" "+this.ya.toString():"M"+this.Aa.toFixed(a)+" "+this.ya.toFixed(a),c=this.sb.n,d=c.length,e=0;e<d;e++)b+=" "+c[e].toString(a);return b};g.freeze=function(){this.D=!0;var a=this.sb;a.freeze();for(var b=a.n,a=a.length,c=0;c<a;c++)b[c].freeze();return this};g.Oa=function(){this.D=!1;var a=this.sb;a.Oa();for(var a=a.n,b=a.length,c=0;c<b;c++)a[c].Oa();return this};
g.Hz=function(){if(this.$a)return!0;var a=this.sb;if(this.Rv!==a.F)return!0;for(var a=a.n,b=a.length,c=0;c<b;c++)if(a[c].$a)return!0;return!1};g.CA=function(){this.$a=!1;var a=this.sb;this.Rv=a.F;for(var a=a.n,b=a.length,c=0;c<b;c++){var d=a[c];d.$a=!1;d.cg=null}};v.defineProperty(Ee,{ut:"isFilled"},function(){return this.Sl},function(a){this.D&&v.ka(this,a);this.Sl=a});v.defineProperty(Ee,{vl:"isShadowed"},function(){return this.Wn},function(a){this.D&&v.ka(this,a);this.Wn=a});
v.defineProperty(Ee,{Aa:"startX"},function(){return this.vc},function(a){this.D&&v.ka(this,a);this.vc=a;this.$a=!0});v.defineProperty(Ee,{ya:"startY"},function(){return this.Cc},function(a){this.D&&v.ka(this,a);this.Cc=a;this.$a=!0});v.defineProperty(Ee,{sb:"segments"},function(){return this.so},function(a){this.D&&v.ka(this,a);this.so=a;this.$a=!0});Ee.prototype.add=Ee.prototype.add=function(a){this.so.add(a);return this};
Ee.prototype.Fa=function(a,b,c){for(var d=this.Aa,e=this.ya,f=d,h=e,k=this.sb.n,l=k.length,m=0;m<l;m++){var n=k[m];switch(n.type){case hf:f=n.I;h=n.J;d=n.I;e=n.J;break;case $e:if(Kd(d,e,n.I,n.J,c,a,b))return!0;d=n.I;e=n.J;break;case jf:if(Qd(d,e,n.rc,n.Kc,n.kh,n.lh,n.I,n.J,.5,a,b,c))return!0;d=n.I;e=n.J;break;case kf:if(Ud(d,e,n.rc,n.Kc,n.I,n.J,.5,a,b,c))return!0;d=n.I;e=n.J;break;case lf:case mf:var p=n.type===lf?nf(n,this):of(n,this,d,e),q=p.length;if(0===q){if(Kd(d,e,n.na,n.ua,c,a,b))return!0;
d=n.na;e=n.ua;break}for(var r=null,s=0;s<q;s++)if(r=p[s],0===s&&Kd(d,e,r[0],r[1],c,a,b)||Qd(r[0],r[1],r[2],r[3],r[4],r[5],r[6],r[7],.5,a,b,c))return!0;null!==r&&(d=r[6],e=r[7]);break;default:v.k("Unknown Segment type: "+n.type)}if(n.Gw&&(d!==f||e!==h)&&Kd(d,e,f,h,c,a,b))return!0}return!1};
function wf(a,b,c,d,e,f,h,k){v.ec(this);this.D=!1;void 0===a&&(a=$e);this.ia=a;this.vb=void 0!==b?b:0;this.Bb=void 0!==c?c:0;void 0===d&&(d=0);void 0===e&&(e=0);void 0===f&&(f=0);void 0===h&&(h=0);a===mf?(a=f%360,0>a&&(a+=360),this.sd=a,this.Fe=0,this.Xg=Math.max(d,0),this.ng=Math.max(e,0),this.ao="boolean"===typeof h?!!h:!1,this.sn=!!k):(this.sd=d,this.Fe=e,a===lf&&(f=Math.max(f,0)),this.Xg=f,"number"===typeof h?(a===lf&&(h=Math.max(h,0)),this.ng=h):this.ng=0,this.sn=this.ao=!1);this.Ch=!1;this.$a=
!0;this.cg=null}v.ea("PathSegment",wf);v.Uh(wf);wf.prototype.copy=function(){var a=new wf;a.ia=this.ia;a.vb=this.vb;a.Bb=this.Bb;a.sd=this.sd;a.Fe=this.Fe;a.Xg=this.Xg;a.ng=this.ng;a.ao=this.ao;a.sn=this.sn;a.Ch=this.Ch;a.$a=this.$a;return a};
wf.prototype.equalsApprox=wf.prototype.Fc=function(a){if(!(a instanceof wf)||this.type!==a.type||this.Gw!==a.Gw)return!1;switch(this.type){case hf:case $e:return K(this.I,a.I)&&K(this.J,a.J);case jf:return K(this.I,a.I)&&K(this.J,a.J)&&K(this.rc,a.rc)&&K(this.Kc,a.Kc)&&K(this.kh,a.kh)&&K(this.lh,a.lh);case kf:return K(this.I,a.I)&&K(this.J,a.J)&&K(this.rc,a.rc)&&K(this.Kc,a.Kc);case lf:return K(this.xe,a.xe)&&K(this.Af,a.Af)&&K(this.na,a.na)&&K(this.ua,a.ua)&&K(this.radiusX,a.radiusX)&&K(this.radiusY,
a.radiusY);case mf:return this.sl===a.sl&&this.Jw===a.Jw&&K(this.ej,a.ej)&&K(this.I,a.I)&&K(this.J,a.J)&&K(this.radiusX,a.radiusX)&&K(this.radiusY,a.radiusY);default:return!1}};
wf.prototype.toString=function(a){void 0===a&&(a=-1);var b="";switch(this.type){case hf:b=0>a?"M"+this.I.toString()+" "+this.J.toString():"M"+this.I.toFixed(a)+" "+this.J.toFixed(a);break;case $e:b=0>a?"L"+this.I.toString()+" "+this.J.toString():"L"+this.I.toFixed(a)+" "+this.J.toFixed(a);break;case jf:b=0>a?"C"+this.rc.toString()+" "+this.Kc.toString()+" "+this.kh.toString()+" "+this.lh.toString()+" "+this.I.toString()+" "+this.J.toString():"C"+this.rc.toFixed(a)+" "+this.Kc.toFixed(a)+" "+this.kh.toFixed(a)+
" "+this.lh.toFixed(a)+" "+this.I.toFixed(a)+" "+this.J.toFixed(a);break;case kf:b=0>a?"Q"+this.rc.toString()+" "+this.Kc.toString()+" "+this.I.toString()+" "+this.J.toString():"Q"+this.rc.toFixed(a)+" "+this.Kc.toFixed(a)+" "+this.I.toFixed(a)+" "+this.J.toFixed(a);break;case lf:b=0>a?"B"+this.xe.toString()+" "+this.Af.toString()+" "+this.na.toString()+" "+this.ua.toString()+" "+this.radiusX:"B"+this.xe.toFixed(a)+" "+this.Af.toFixed(a)+" "+this.na.toFixed(a)+" "+this.ua.toFixed(a)+" "+this.radiusX;
break;case mf:b=0>a?"A"+this.radiusX.toString()+" "+this.radiusY.toString()+" "+this.ej.toString()+" "+(this.Jw?1:0)+" "+(this.sl?1:0)+" "+this.I.toString()+" "+this.J.toString():"A"+this.radiusX.toFixed(a)+" "+this.radiusY.toFixed(a)+" "+this.ej.toFixed(a)+" "+(this.Jw?1:0)+" "+(this.sl?1:0)+" "+this.I.toFixed(a)+" "+this.J.toFixed(a);break;default:b=this.type.toString()}return b+(this.Ch?"z":"")};var hf;wf.Move=hf=v.p(wf,"Move",0);var $e;wf.Line=$e=v.p(wf,"Line",1);var jf;
wf.Bezier=jf=v.p(wf,"Bezier",2);var kf;wf.QuadraticBezier=kf=v.p(wf,"QuadraticBezier",3);var lf;wf.Arc=lf=v.p(wf,"Arc",4);var mf;wf.SvgArc=mf=v.p(wf,"SvgArc",4);wf.prototype.freeze=function(){this.D=!0;return this};wf.prototype.Oa=function(){this.D=!1;return this};wf.prototype.close=wf.prototype.close=function(){this.Ch=!0;return this};
function nf(a,b){if(null!==a.cg&&!1===b.$a)return a.cg;var c=a.radiusX,d=a.radiusY;void 0===d&&(d=c);if(0===c||0===d)return a.cg=[],a.cg;var e=a.sd,f=a.Fe,h=oe(c<d?c:d,a.xe,a.xe+a.Af,!1);if(c!==d){var k=v.ph();k.reset();c<d?k.scale(1,d/c):k.scale(c/d,1);ff(h,k);v.Ze(k)}c=h.length;for(d=0;d<c;d++)k=h[d],k[0]+=e,k[1]+=f,k[2]+=e,k[3]+=f,k[4]+=e,k[5]+=f,k[6]+=e,k[7]+=f;a.cg=h;return a.cg}
function of(a,b,c,d){function e(a,b,c,d){return(a*d<b*c?-1:1)*Math.acos((a*c+b*d)/(Math.sqrt(a*a+b*b)*Math.sqrt(c*c+d*d)))}if(null!==a.cg&&!1===b.$a)return a.cg;b=a.Xg;var f=a.ng;if(0===b||0===f)return a.cg=[],a.cg;var h=Math.PI/180*a.sd,k=a.ao,l=a.sn,m=a.vb,n=a.Bb,p=Math.cos(h),q=Math.sin(h),r=p*(c-m)/2+q*(d-n)/2,h=-q*(c-m)/2+p*(d-n)/2,s=r*r/(b*b)+h*h/(f*f);1<s&&(b*=Math.sqrt(s),f*=Math.sqrt(s));s=(k===l?-1:1)*Math.sqrt((b*b*f*f-b*b*h*h-f*f*r*r)/(b*b*h*h+f*f*r*r));isNaN(s)&&(s=0);k=s*b*h/f;s=s*-f*
r/b;isNaN(k)&&(k=0);isNaN(s)&&(s=0);c=(c+m)/2+p*k-q*s;d=(d+n)/2+q*k+p*s;n=e(1,0,(r-k)/b,(h-s)/f);p=(r-k)/b;m=(h-s)/f;r=(-r-k)/b;k=(-h-s)/f;h=e(p,m,r,k);r=(p*r+m*k)/(Math.sqrt(p*p+m*m)*Math.sqrt(r*r+k*k));-1>=r?h=Math.PI:1<=r&&(h=0);!l&&0<h&&(h-=2*Math.PI);l&&0>h&&(h+=2*Math.PI);l=b>f?1:b/f;r=b>f?f/b:1;b=oe(b>f?b:f,n,n+h,!0);f=v.ph();f.reset();f.translate(c,d);f.rotate(a.sd,0,0);f.scale(l,r);ff(b,f);v.Ze(f);a.cg=b;return a.cg}
v.defineProperty(wf,{Gw:"isClosed"},function(){return this.Ch},function(a){this.Ch!==a&&(this.Ch=a,this.$a=!0)});v.defineProperty(wf,{type:"type"},function(){return this.ia},function(a){this.D&&v.ka(this,a);this.ia=a;this.$a=!0});v.defineProperty(wf,{I:"endX"},function(){return this.vb},function(a){this.D&&v.ka(this,a);this.vb=a;this.$a=!0});v.defineProperty(wf,{J:"endY"},function(){return this.Bb},function(a){this.D&&v.ka(this,a);this.Bb=a;this.$a=!0});
v.defineProperty(wf,{rc:"point1X"},function(){return this.sd},function(a){this.D&&v.ka(this,a);this.sd=a;this.$a=!0});v.defineProperty(wf,{Kc:"point1Y"},function(){return this.Fe},function(a){this.D&&v.ka(this,a);this.Fe=a;this.$a=!0});v.defineProperty(wf,{kh:"point2X"},function(){return this.Xg},function(a){this.D&&v.ka(this,a);this.Xg=a;this.$a=!0});v.defineProperty(wf,{lh:"point2Y"},function(){return this.ng},function(a){this.D&&v.ka(this,a);this.ng=a;this.$a=!0});
v.defineProperty(wf,{na:"centerX"},function(){return this.sd},function(a){this.D&&v.ka(this,a);this.sd=a;this.$a=!0});v.defineProperty(wf,{ua:"centerY"},function(){return this.Fe},function(a){this.D&&v.ka(this,a);this.Fe=a;this.$a=!0});v.defineProperty(wf,{radiusX:"radiusX"},function(){return this.Xg},function(a){0>a&&v.va(a,">= zero",wf,"radiusX");this.D&&v.ka(this,a);this.Xg=a;this.$a=!0});
v.defineProperty(wf,{radiusY:"radiusY"},function(){return this.ng},function(a){0>a&&v.va(a,">= zero",wf,"radiusY");this.D&&v.ka(this,a);this.ng=a;this.$a=!0});v.defineProperty(wf,{xe:"startAngle"},function(){return this.vb},function(a){this.vb!==a&&(this.D&&v.ka(this,a),a%=360,0>a&&(a+=360),this.vb=a,this.$a=!0)});v.defineProperty(wf,{Af:"sweepAngle"},function(){return this.Bb},function(a){this.D&&v.ka(this,a);360<a&&(a=360);-360>a&&(a=-360);this.Bb=a;this.$a=!0});
v.defineProperty(wf,{sl:"isClockwiseArc"},function(){return this.sn},function(a){this.D&&v.ka(this,a);this.sn=a;this.$a=!0});v.defineProperty(wf,{Jw:"isLargeArc"},function(){return this.ao},function(a){this.D&&v.ka(this,a);this.ao=a;this.$a=!0});v.defineProperty(wf,{ej:"xAxisRotation"},function(){return this.sd},function(a){a%=360;0>a&&(a+=360);this.D&&v.ka(this,a);this.sd=a;this.$a=!0});
function xf(){this.W=null;this.Sy=(new z(0,0)).freeze();this.Wx=(new z(0,0)).freeze();this.yu=this.uv=0;this.zu=1;this.jv="";this.aw=this.Pu=!1;this.Lu=this.Bu=0;this.gj=this.Xu=this.gv=!1;this.Eq=null;this.Zv=0;this.pg=this.Yv=null}v.ea("InputEvent",xf);
xf.prototype.copy=function(){var a=new xf;a.W=this.W;a.Sy.assign(this.hf);a.Wx.assign(this.ca);a.uv=this.uv;a.yu=this.yu;a.zu=this.zu;a.jv=this.jv;a.Pu=this.Pu;a.aw=this.aw;a.Bu=this.Bu;a.Lu=this.Lu;a.gv=this.gv;a.Xu=this.Xu;a.gj=this.gj;a.Eq=this.Eq;a.Zv=this.Zv;a.Yv=this.Yv;a.pg=this.pg;return a};
xf.prototype.toString=function(){var a="^";0!==this.od&&(a+="M:"+this.od);0!==this.button&&(a+="B:"+this.button);""!==this.key&&(a+="K:"+this.key);0!==this.Ve&&(a+="C:"+this.Ve);0!==this.gl&&(a+="D:"+this.gl);this.zc&&(a+="h");this.bubbles&&(a+="b");null!==this.ca&&(a+="@"+this.ca.toString());return a};v.defineProperty(xf,{g:"diagram"},function(){return this.W},function(a){this.W=a});v.defineProperty(xf,{hf:"viewPoint"},function(){return this.Sy},function(a){v.A(a,z,xf,"viewPoint");this.Sy.assign(a)});
v.defineProperty(xf,{ca:"documentPoint"},function(){return this.Wx},function(a){v.A(a,z,xf,"documentPoint");this.Wx.assign(a)});v.defineProperty(xf,{od:"modifiers"},function(){return this.uv},function(a){this.uv=a});v.defineProperty(xf,{button:"button"},function(){return this.yu},function(a){this.yu=a;if(null===this.event)switch(a){case 0:this.buttons=1;break;case 1:this.buttons=4;break;case 2:this.buttons=2}});
v.defineProperty(xf,{buttons:"buttons"},function(){return this.zu},function(a){this.zu=a});v.defineProperty(xf,{key:"key"},function(){return this.jv},function(a){this.jv=a});v.defineProperty(xf,{il:"down"},function(){return this.Pu},function(a){this.Pu=a});v.defineProperty(xf,{up:"up"},function(){return this.aw},function(a){this.aw=a});v.defineProperty(xf,{Ve:"clickCount"},function(){return this.Bu},function(a){this.Bu=a});
v.defineProperty(xf,{gl:"delta"},function(){return this.Lu},function(a){this.Lu=a});v.defineProperty(xf,{wt:"isMultiTouch"},function(){return this.gv},function(a){this.gv=a});v.defineProperty(xf,{zc:"handled"},function(){return this.Xu},function(a){this.Xu=a});v.defineProperty(xf,{bubbles:"bubbles"},function(){return this.gj},function(a){this.gj=a});v.defineProperty(xf,{event:"event"},function(){return this.Eq},function(a){this.Eq=a});
v.u(xf,{Vi:"isTouchEvent"},function(){var a=window.TouchEvent;return a&&this.event instanceof a?!0:(a=window.PointerEvent)&&this.event instanceof a&&"touch"===this.event.pointerType});v.u(xf,{ak:"isMac"},function(){return v.ak});v.defineProperty(xf,{timestamp:"timestamp"},function(){return this.Zv},function(a){this.Zv=a});v.defineProperty(xf,{Ig:"targetDiagram"},function(){return this.Yv},function(a){this.Yv=a});
v.defineProperty(xf,{ye:"targetObject"},function(){return this.pg},function(a){this.pg=a});v.defineProperty(xf,{control:"control"},function(){return 0!==(this.od&1)},function(a){this.od=a?this.od|1:this.od&-2});v.defineProperty(xf,{shift:"shift"},function(){return 0!==(this.od&4)},function(a){this.od=a?this.od|4:this.od&-5});v.defineProperty(xf,{alt:"alt"},function(){return 0!==(this.od&2)},function(a){this.od=a?this.od|2:this.od&-3});
v.defineProperty(xf,{Ft:"meta"},function(){return 0!==(this.od&8)},function(a){this.od=a?this.od|8:this.od&-9});v.defineProperty(xf,{left:"left"},function(){var a=this.event;return null!==a&&a instanceof MouseEvent&&("mousedown"===a.type||"mouseup"===a.type)?0===this.button:0!==(this.buttons&1)},function(a){this.buttons=a?this.buttons|1:this.buttons&-2});
v.defineProperty(xf,{right:"right"},function(){var a=this.event;return null!==a&&a instanceof MouseEvent&&("mousedown"===a.type||"mouseup"===a.type)?2===this.button:0!==(this.buttons&2)},function(a){this.buttons=a?this.buttons|2:this.buttons&-3});v.defineProperty(xf,{iK:"middle"},function(){var a=this.event;return null!==a&&a instanceof MouseEvent&&("mousedown"===a.type||"mouseup"===a.type)?1===this.button:0!==(this.buttons&4)},function(a){this.buttons=a?this.buttons|4:this.buttons&-5});
function yf(){this.W=null;this.Ob="";this.Dv=this.Wv=null;this.Au=!1}v.ea("DiagramEvent",yf);yf.prototype.copy=function(){var a=new yf;a.W=this.W;a.Ob=this.Ob;a.Wv=this.Wv;a.Dv=this.Dv;a.Au=this.Au;return a};yf.prototype.toString=function(){var a="*"+this.name;this.cancel&&(a+="x");null!==this.uA&&(a+=":"+this.uA.toString());null!==this.$z&&(a+="("+this.$z.toString()+")");return a};v.defineProperty(yf,{g:"diagram"},function(){return this.W},function(a){this.W=a});
v.defineProperty(yf,{name:"name"},function(){return this.Ob},function(a){this.Ob=a});v.defineProperty(yf,{uA:"subject"},function(){return this.Wv},function(a){this.Wv=a});v.defineProperty(yf,{$z:"parameter"},function(){return this.Dv},function(a){this.Dv=a});v.defineProperty(yf,{cancel:"cancel"},function(){return this.Au},function(a){this.Au=a});function zf(){this.bq=Af;this.lm=this.tv="";this.Cr=this.Dr=this.Gr=this.Hr=this.Fr=this.W=this.Vd=null}v.ea("ChangedEvent",zf);var Bf;
zf.Transaction=Bf=v.p(zf,"Transaction",-1);var Af;zf.Property=Af=v.p(zf,"Property",0);var Cf;zf.Insert=Cf=v.p(zf,"Insert",1);var Df;zf.Remove=Df=v.p(zf,"Remove",2);zf.prototype.clear=zf.prototype.clear=function(){this.Cr=this.Dr=this.Gr=this.Hr=this.Fr=this.W=this.Vd=null};
zf.prototype.copy=function(){var a=new zf;a.bq=this.bq;a.tv=this.tv;a.lm=this.lm;a.Vd=this.Vd;a.W=this.W;a.Fr=this.Fr;var b=this.Hr;a.Hr=v.Ta(b)&&"function"===typeof b.R?b.R():b;b=this.Gr;a.Gr=v.Ta(b)&&"function"===typeof b.R?b.R():b;b=this.Dr;a.Dr=v.Ta(b)&&"function"===typeof b.R?b.R():b;b=this.Cr;a.Cr=v.Ta(b)&&"function"===typeof b.R?b.R():b;return a};
zf.prototype.toString=function(){var a="",a=this.wc===Bf?a+"* ":this.wc===Af?a+(null!==this.ba?"!m":"!d"):a+((null!==this.ba?"!m":"!d")+this.wc);this.propertyName&&"string"===typeof this.propertyName&&(a+=" "+this.propertyName);this.gf&&this.gf!==this.propertyName&&(a+=" "+this.gf);a+=": ";this.wc===Bf?null!==this.oldValue&&(a+=" "+this.oldValue):(null!==this.object&&(a+=Ef(this.object)),null!==this.oldValue&&(a+="  old: "+Ef(this.oldValue)),null!==this.Bg&&(a+=" "+this.Bg),null!==this.newValue&&
(a+="  new: "+Ef(this.newValue)),null!==this.zg&&(a+=" "+this.zg));return a};zf.prototype.getValue=zf.prototype.ja=function(a){return a?this.oldValue:this.newValue};zf.prototype.getParam=function(a){return a?this.Bg:this.zg};zf.prototype.canUndo=zf.prototype.canUndo=function(){return null!==this.ba||null!==this.g?!0:!1};zf.prototype.undo=zf.prototype.undo=function(){this.canUndo()&&(null!==this.ba?this.ba.Dm(this,!0):null!==this.g&&this.g.Dm(this,!0))};
zf.prototype.canRedo=zf.prototype.canRedo=function(){return null!==this.ba||null!==this.g?!0:!1};zf.prototype.redo=zf.prototype.redo=function(){this.canRedo()&&(null!==this.ba?this.ba.Dm(this,!1):null!==this.g&&this.g.Dm(this,!1))};v.defineProperty(zf,{ba:"model"},function(){return this.Vd},function(a){this.Vd=a});v.defineProperty(zf,{g:"diagram"},function(){return this.W},function(a){this.W=a});v.defineProperty(zf,{wc:"change"},function(){return this.bq},function(a){this.bq=a});
v.defineProperty(zf,{gf:"modelChange"},function(){return this.tv},function(a){this.tv=a});v.defineProperty(zf,{propertyName:"propertyName"},function(){return this.lm},function(a){this.lm=a});v.u(zf,{kE:"isTransactionFinished"},function(){return this.bq===Bf&&("CommittedTransaction"===this.lm||"FinishedUndo"===this.lm||"FinishedRedo"===this.lm)});v.defineProperty(zf,{object:"object"},function(){return this.Fr},function(a){this.Fr=a});
v.defineProperty(zf,{oldValue:"oldValue"},function(){return this.Hr},function(a){this.Hr=a});v.defineProperty(zf,{Bg:"oldParam"},function(){return this.Gr},function(a){this.Gr=a});v.defineProperty(zf,{newValue:"newValue"},function(){return this.Dr},function(a){this.Dr=a});v.defineProperty(zf,{zg:"newParam"},function(){return this.Cr},function(a){this.Cr=a});
function F(a){1<arguments.length&&v.k("Model constructor can only take one optional argument, the Array of node data.");v.ec(this);this.qq=this.Ob="";this.si=!1;this.zy={};this.If=[];this.uc=new na(null,Object);this.Dj="key";this.yn=this.fo=null;this.iq=this.Eu=!1;this.Tp=null;this.fm="category";this.ni=new na(null,J);this.xj=null;this.Gi=!1;this.Ry=null;this.la=new Ff;void 0!==a&&(this.Yf=a)}v.ea("Model",F);
F.prototype.cloneProtected=function(a){a.Ob=this.Ob;a.qq=this.qq;a.si=this.si;a.Dj=this.Dj;a.fo=this.fo;a.yn=this.yn;a.WI=this.Eu;a.iq=this.iq;a.Tp=this.Tp;a.fm=this.fm};F.prototype.copy=function(){var a=new this.constructor;this.cloneProtected(a);return a};F.prototype.clear=F.prototype.clear=function(){this.If=[];this.uc.clear();this.ni.clear();this.la.clear()};g=F.prototype;
g.toString=function(a){void 0===a&&(a=0);if(1<a)return this.xA();var b=(""!==this.name?this.name:"")+" Model";if(0<a){b+="\n node data:";a=this.Yf;for(var c=v.Va(a),d=0;d<c;d++)var e=v.Pa(a,d),b=b+(" "+this.gb(e)+":"+Ef(e))}return b};
g.jn=function(){var a="";""!==this.name&&(a+=',\n  "name": '+this.quote(this.name));""!==this.el&&(a+=',\n  "dataFormat": '+this.quote(this.el));this.ab&&(a+=',\n  "isReadOnly": '+this.ab);"key"!==this.xl&&"string"===typeof this.xl&&(a+=',\n  "nodeKeyProperty": '+this.quote(this.xl));this.hz&&(a+=',\n  "copiesArrays": true');this.gz&&(a+=',\n  "copiesArrayObjects": true');"category"!==this.Ym&&"string"===typeof this.Ym&&(a+=',\n  "nodeCategoryProperty": '+this.quote(this.Ym));return a};
g.Nt=function(a){a.name&&(this.name=a.name);a.dataFormat&&(this.el=a.dataFormat);a.isReadOnly&&(this.ab=a.isReadOnly);a.nodeKeyProperty&&(this.xl=a.nodeKeyProperty);a.copiesArrays&&(this.hz=a.copiesArrays);a.copiesArrayObjects&&(this.gz=a.copiesArrayObjects);a.nodeCategoryProperty&&(this.Ym=a.nodeCategoryProperty)};function Gf(a){return',\n  "modelData": '+Hf(a,a.bk)}function If(a,b){var c=b.modelData;v.Ta(c)&&(a.Qt(c),a.bk=c)}
g.FA=function(){var a=this.bk,b=!1,c;for(c in a)if(!Lf(c,a[c])){b=!0;break}a="";b&&(a=Gf(this));return a+',\n  "nodeDataArray": '+Mf(this,this.Yf,!0)};g.cA=function(a){If(this,a);a=a.nodeDataArray;v.isArray(a)&&(this.Qt(a),this.Yf=a)};
function Nf(a,b,c,d){if(b===c)return!0;if(typeof b!==typeof c||"function"===typeof b||"function"===typeof c)return!1;if(Array.isArray(b)&&Array.isArray(c)){if(d.ja(b)===c)return!0;d.add(b,c);if(b.length!==c.length)return!1;for(var e=0;e<b.length;e++)if(!Nf(a,b[e],c[e],d))return!1;return!0}if(v.Ta(b)&&v.Ta(c)){if(d.ja(b)===c)return!0;d.add(b,c);for(e in b){var f=b[e];if(!Lf(e,f)){var h=c[e];if(void 0===h||!Nf(a,f,h,d))return!1}}for(var k in c)if(h=c[k],!Lf(k,h)&&(f=b[k],void 0===f||!Nf(a,f,h,d)))return!1;
return!0}return!1}function Of(a,b,c){a[c]!==b[c]&&v.k("Model.computeJsonDifference: Model."+c+' is not the same in both models: "'+a[c]+'" and "'+b[c]+'"')}
g.GA=function(a){Of(this,a,"nodeKeyProperty");this instanceof Pf&&Of(this,a,"nodeParentKeyProperty");for(var b=new J,c=new J,d=(new J).Tc(this.uc.mE),e=new na,f=a.Yf,h=0;h<f.length;h++){var k=f[h],l=a.gb(k);if(void 0!==l){d.remove(l);var m=this.ae(l);null===m?(b.add(l),c.add(k)):Nf(this,m,k,e)||c.add(k)}else this.Nz(k),l=this.gb(k),b.add(l),c.add(k)}f="";Nf(this,this.bk,a.bk,e)||(f+=Gf(this));0<b.count&&(f+=this.Ex+Mf(this,b.dc(),!0));0<c.count&&(f+=this.OA+Mf(this,c.dc(),!0));0<d.count&&(f+=this.Gx+
Mf(this,d.dc(),!0));return f};F.prototype.computeJsonDifference=F.prototype.computeJSONDifference=function(a,b){v.A(a,F,F,"computeJsonDifference:newmodel");void 0===b&&(b=this.constructor===F?"go.Model":this.constructor===Q?"go.GraphLinksModel":this.constructor===Pf?"go.TreeModel":v.$e(this));return'{ "class": '+this.quote(b)+', "incremental": 1'+this.jn()+this.GA(a)+"}"};g=F.prototype;g.Ex=',\n  "insertedNodeKeys": ';g.OA=',\n  "modifiedNodeData": ';g.Gx=',\n  "removedNodeKeys": ';
g.EA=function(a,b){var c=this,d=!1,e=new J,f=new J,h=new J;a.Of.each(function(a){a.ba===c&&("nodeDataArray"===a.gf?a.wc===Cf?e.add(a.newValue):a.wc===Df&&h.add(a.oldValue):c.He(a.object)?f.add(a.object):c.bk===a.object&&a.wc===Af&&(d=!0))});var k=new J;e.each(function(a){k.add(c.gb(a));b||f.add(a)});var l=new J;h.each(function(a){l.add(c.gb(a));b&&f.add(a)});var m="";d&&(m+=Gf(this));0<k.count&&(m+=(b?this.Gx:this.Ex)+Mf(this,k.dc(),!0));0<f.count&&(m+=this.OA+Mf(this,f.dc(),!0));0<l.count&&(m+=(b?
this.Ex:this.Gx)+Mf(this,l.dc(),!0));return m};
g.bA=function(a){If(this,a);var b=a.insertedNodeKeys;if(v.isArray(b))for(var c=v.Va(b),d=0;d<c;d++){var e=v.Pa(b,d),f=this.ae(e);null===f&&(f=this.copyNodeData({}),this.mx(f,e),this.Xk(f))}b=a.modifiedNodeData;if(v.isArray(b))for(c=v.Va(b),d=0;d<c;d++){var h=v.Pa(b,d),e=this.gb(h),f=this.ae(e);if(null!==f)for(var k in h)"__gohashid"!==k&&k!==this.xl&&this.setDataProperty(f,k,h[k])}a=a.removedNodeKeys;if(v.isArray(a))for(c=v.Va(a),d=0;d<c;d++)e=v.Pa(a,d),f=this.ae(e),null!==f&&this.ex(f)};
F.prototype.toIncrementalJson=F.prototype.toIncrementalJSON=function(a,b){v.A(a,zf,F,"toIncrementalJson:e");a.wc!==Bf&&v.k("Model.toIncrementalJson argument is not a Transaction ChangedEvent:"+a.toString());var c=a.object;if(!(a.kE&&c instanceof Qf))return'{ "incremental": 0 }';void 0===b&&(b=this.constructor===F?"go.Model":this.constructor===Q?"go.GraphLinksModel":this.constructor===Pf?"go.TreeModel":v.$e(this));return'{ "class": '+this.quote(b)+', "incremental": 1'+this.jn()+this.EA(c,"FinishedUndo"===
a.propertyName)+"}"};F.prototype.toJson=F.prototype.toJSON=F.prototype.xA=function(a){void 0===a&&(a=this.constructor===F?"go.Model":this.constructor===Q?"go.GraphLinksModel":this.constructor===Pf?"go.TreeModel":v.$e(this));return'{ "class": '+this.quote(a)+this.jn()+this.FA()+"}"};
F.prototype.applyIncrementalJson=F.prototype.applyIncrementalJSON=function(a){var b=null;if("string"===typeof a)if(window.JSON&&window.JSON.parse)try{b=window.JSON.parse(a)}catch(c){}else v.trace("WARNING: no JSON.parse available");else"object"===typeof a?b=a:v.k("Unable to modify a Model from: "+a);var d=b.incremental;"number"!==typeof d&&v.k("Unable to apply non-incremental changes to Model: "+a);0!==d&&(this.Qb("applyIncrementalJson"),this.bA(b),this.wd("applyIncrementalJson"))};
F.fromJson=F.fromJSON=function(a,b){void 0===b&&(b=null);null!==b&&v.A(b,F,F,"fromJson:model");var c=null;if("string"===typeof a)if(window.JSON&&window.JSON.parse)try{c=window.JSON.parse(a)}catch(d){}else v.trace("WARNING: no JSON.parse available");else"object"===typeof a?c=a:v.k("Unable to construct a Model from: "+a);if(null===b){var e;e=null;var f=c["class"];if("string"===typeof f)try{var h=null;0===f.indexOf("go.")?(f=f.substr(3),h=ea[f]):(h=ea[f],void 0===h&&(h=window[f]));"function"===typeof h&&
(e=new h)}catch(k){}null===e||e instanceof F?b=e:v.k("Unable to construct a Model of declared class: "+c["class"])}null===b&&(b=new Q);b.Nt(c);b.cA(c);return b};
F.prototype.replaceJsonObjects=F.prototype.Qt=function(a){if(v.isArray(a))for(var b=v.Va(a),c=0;c<b;c++){var d=v.Pa(a,c);v.Ta(d)&&v.kD(a,c,this.Qt(d))}else if(v.Ta(a)){for(c in a)if(d=a[c],v.Ta(d)&&(d=this.Qt(d),a[c]=d,"points"===c&&Array.isArray(d))){for(var e=0===d.length%2,f=0;f<d.length;f++)if("number"!==typeof d[f]){e=!1;break}if(e){e=new I(z);for(f=0;f<d.length/2;f++)e.add(new z(d[2*f],d[2*f+1]));e.freeze();a[c]=e}}if("object"===typeof a){c=a;d=a["class"];if("NaN"===d)c=NaN;else if("Date"===
d)c=new Date(a.value);else if("go.Point"===d)c=new z(Rf(a.x),Rf(a.y));else if("go.Size"===d)c=new ia(Rf(a.width),Rf(a.height));else if("go.Rect"===d)c=new B(Rf(a.x),Rf(a.y),Rf(a.width),Rf(a.height));else if("go.Margin"===d)c=new eb(Rf(a.top),Rf(a.right),Rf(a.bottom),Rf(a.left));else if("go.Spot"===d)c="string"===typeof a["enum"]?ib(a["enum"]):new L(Rf(a.x),Rf(a.y),Rf(a.offsetX),Rf(a.offsetY));else if("go.Brush"===d){if(c=new ha,c.type=ra(ha,a.type),"string"===typeof a.color&&(c.color=a.color),a.start instanceof
L&&(c.start=a.start),a.end instanceof L&&(c.end=a.end),"number"===typeof a.startRadius&&(c.cu=Rf(a.startRadius)),"number"===typeof a.endRadius&&(c.bt=Rf(a.endRadius)),a=a.colorStops,v.Ta(a))for(b in a)c.addColorStop(parseFloat(b),a[b])}else"go.Geometry"===d?(b=null,b="string"===typeof a.path?bf(a.path):new re,b.type=ra(re,a.type),"number"===typeof a.startX&&(b.Aa=Rf(a.startX)),"number"===typeof a.startY&&(b.ya=Rf(a.startY)),"number"===typeof a.endX&&(b.I=Rf(a.endX)),"number"===typeof a.endY&&(b.J=
Rf(a.endY)),a.spot1 instanceof L&&(b.B=a.spot1),a.spot2 instanceof L&&(b.C=a.spot2),c=b):"go.EnumValue"===d&&(b=a.classType,0===b.indexOf("go.")&&(b=b.substr(3)),c=ra(ea[b],a.name));a=c}}return a};
F.prototype.quote=function(a){for(var b="",c=a.length,d=0;d<c;d++){var e=a[d];if('"'===e||"\\"===e)b+="\\"+e;else if("\b"===e)b+="\\b";else if("\f"===e)b+="\\f";else if("\n"===e)b+="\\n";else if("\r"===e)b+="\\r";else if("\t"===e)b+="\\t";else var f=a.charCodeAt(d),b=16>f?b+("\\u000"+a.charCodeAt(d).toString(16)):32>f?b+("\\u00"+a.charCodeAt(d).toString(16)):8232===f?b+"\\u2028":8233===f?b+"\\u2029":b+e}return'"'+b+'"'};
F.prototype.writeJsonValue=F.prototype.iu=function(a){return void 0===a?"undefined":null===a?"null":!0===a?"true":!1===a?"false":"string"===typeof a?this.quote(a):"number"===typeof a?Infinity===a?"9e9999":-Infinity===a?"-9e9999":isNaN(a)?'{"class":"NaN"}':a.toString():a instanceof Date?'{"class":"Date", "value":"'+a.toJSON()+'"}':a instanceof Number?this.iu(a.valueOf()):v.isArray(a)?Mf(this,a):v.Ta(a)?Hf(this,a):"function"===typeof a?"null":a.toString()};
function Mf(a,b,c){void 0===c&&(c=!1);var d=v.Va(b);if(0>=d)return"[]";var e=new sa;e.add("[ ");c&&1<d&&e.add("\n");for(var f=0;f<d;f++){var h=v.Pa(b,f);void 0!==h&&(0<f&&(e.add(","),c&&e.add("\n")),e.add(a.iu(h)))}c&&1<d&&e.add("\n");e.add(" ]");return e.toString()}function Lf(a,b){return void 0===b||"__gohashid"===a||"_"===a[0]||"function"===typeof b?!0:!1}function Sf(a){return isNaN(a)?"NaN":Infinity===a?"9e9999":-Infinity===a?"-9e9999":a}
function Hf(a,b){var c=b;if(c instanceof z){var d=c;b={"class":"go.Point",x:Sf(d.x),y:Sf(d.y)}}else if(c instanceof ia){var e=c;b={"class":"go.Size",width:Sf(e.width),height:Sf(e.height)}}else if(c instanceof B)b={"class":"go.Rect",x:Sf(c.x),y:Sf(c.y),width:Sf(c.width),height:Sf(c.height)};else if(c instanceof eb)b={"class":"go.Margin",top:Sf(c.top),right:Sf(c.right),bottom:Sf(c.bottom),left:Sf(c.left)};else if(c instanceof L)e=c,b=e.cd()?{"class":"go.Spot",x:Sf(e.x),y:Sf(e.y),offsetX:Sf(e.offsetX),
offsetY:Sf(e.offsetY)}:{"class":"go.Spot","enum":e.toString()};else if(c instanceof ha){b={"class":"go.Brush",type:c.type.name};if(c.type===Tf)b.color=c.color;else if(c.type===Uf||c.type===Vc)b.start=c.start,b.end=c.end,c.type===Vc&&(0!==c.cu&&(b.startRadius=Sf(c.cu)),isNaN(c.bt)||(b.endRadius=Sf(c.bt)));if(null!==c.Ys){for(var f={},h=c.Ys.i;h.next();)f[h.key]=h.value;b.colorStops=f}}else if(c instanceof re)b={"class":"go.Geometry",type:c.type.name},0!==c.Aa&&(b.startX=Sf(c.Aa)),0!==c.ya&&(b.startY=
Sf(c.ya)),0!==c.I&&(b.endX=Sf(c.I)),0!==c.J&&(b.endY=Sf(c.J)),c.B.K(tb)||(b.spot1=c.B),c.C.K(Fb)||(b.spot2=c.C),c.type===De&&(b.path=af(c));else if(c instanceof fa)b={"class":"go.EnumValue",classType:v.$e(c.pe),name:c.name};else if(c instanceof G||c instanceof E||c instanceof Vf||c instanceof F||c instanceof Wf||c instanceof Xf||c instanceof Yf||c instanceof Zf||c instanceof Ff||c instanceof Qf)return v.trace("ERROR: trying to convert a GraphObject or Diagram or Model or Tool or Layout or UndoManager into JSON text: "+
c.toString()),"{}";f="{";c=!0;for(d in b)if(e=v.qb(b,d),!Lf(d,e))if(c?c=!1:f+=", ",f+='"'+d+'":',"points"===d&&e instanceof I&&e.ia===z){h=e;e="[";for(h=h.i;h.next();){var k=h.value;1<e.length&&(e+=",");e+=a.iu(k.x);e+=",";e+=a.iu(k.y)}e+="]";f+=e}else f+=a.iu(e);return f+"}"}function Rf(a){return"number"===typeof a?a:"NaN"===a?NaN:"9e9999"===a?Infinity:"-9e9999"===a?-Infinity:parseFloat(a)}
v.defineProperty(F,{name:"name"},function(){return this.Ob},function(a){var b=this.Ob;b!==a&&(v.j(a,"string",F,"name"),this.Ob=a,this.h("name",b,a))});v.defineProperty(F,{el:"dataFormat"},function(){return this.qq},function(a){var b=this.qq;b!==a&&(v.j(a,"string",F,"dataFormat"),this.qq=a,this.h("dataFormat",b,a))});v.defineProperty(F,{ab:"isReadOnly"},function(){return this.si},function(a){var b=this.si;b!==a&&(v.j(a,"boolean",F,"isReadOnly"),this.si=a,this.h("isReadOnly",b,a))});
v.defineProperty(F,{bk:"modelData"},function(){return this.zy},function(a){var b=this.zy;b!==a&&(v.j(a,"object",F,"modelData"),this.zy=a,this.h("modelData",b,a),this.Hb(a))});F.prototype.addChangedListener=F.prototype.ym=function(a){v.j(a,"function",F,"addChangedListener:listener");null===this.xj&&(this.xj=new I("function"));this.xj.add(a)};
F.prototype.removeChangedListener=F.prototype.Ot=function(a){v.j(a,"function",F,"removeChangedListener:listener");null!==this.xj&&(this.xj.remove(a),0===this.xj.count&&(this.xj=null))};F.prototype.nw=function(a){this.tb||this.la.WD(a);if(null!==this.xj){var b=this.xj,c=b.length;if(1===c)b=b.da(0),b(a);else if(0!==c)for(var d=b.dc(),e=0;e<c;e++)b=d[e],b(a)}};F.prototype.raiseChangedEvent=F.prototype.Lc=function(a,b,c,d,e,f,h){$f(this,"",a,b,c,d,e,f,h)};
F.prototype.raiseChanged=F.prototype.h=function(a,b,c,d,e){$f(this,"",Af,a,this,b,c,d,e)};F.prototype.raiseDataChanged=F.prototype.aA=function(a,b,c,d,e,f){$f(this,"",Af,b,a,c,d,e,f)};function $f(a,b,c,d,e,f,h,k,l){void 0===k&&(k=null);void 0===l&&(l=null);var m=new zf;m.ba=a;m.wc=c;m.gf=b;m.propertyName=d;m.object=e;m.oldValue=f;m.Bg=k;m.newValue=h;m.zg=l;a.nw(m)}
v.defineProperty(F,{la:"undoManager"},function(){return this.Ry},function(a){var b=this.Ry;b!==a&&(v.A(a,Ff,F,"undoManager"),null!==b&&b.iI(this),this.Ry=a,null!==a&&a.bG(this))});v.defineProperty(F,{tb:"skipsUndoManager"},function(){return this.Gi},function(a){v.j(a,"boolean",F,"skipsUndoManager");this.Gi=a});
F.prototype.Dm=function(a,b){if(null!==a&&a.ba===this)if(a.wc===Af){var c=a.object,d=a.propertyName,e=a.ja(b);v.Ka(c,d,e)}else a.wc===Cf?"nodeDataArray"===a.gf?(c=a.newValue,v.Ta(c)&&(d=this.gb(c),void 0!==d&&(b?(v.Th(this.If,a.zg),this.uc.remove(d)):(v.Mi(this.If,a.zg,c),this.uc.add(d,c))))):""===a.gf?(c=a.object,!v.isArray(c)&&a.propertyName&&(c=v.qb(a.object,a.propertyName)),v.isArray(c)&&(d=a.newValue,e=a.zg,b?v.Th(c,e):v.Mi(c,e,d))):v.k("unknown ChangedEvent.Insert object: "+a.toString()):a.wc===
Df?"nodeDataArray"===a.gf?(c=a.oldValue,v.Ta(c)&&(d=this.gb(c),void 0!==d&&(b?(v.Mi(this.If,a.Bg,c),this.uc.add(d,c)):(v.Th(this.If,a.Bg),this.uc.remove(d))))):""===a.gf?(c=a.object,!v.isArray(c)&&a.propertyName&&(c=v.qb(a.object,a.propertyName)),v.isArray(c)&&(d=a.oldValue,e=a.Bg,b?v.Mi(c,e,d):v.Th(c,e))):v.k("unknown ChangedEvent.Remove object: "+a.toString()):a.wc!==Bf&&v.k("unknown ChangedEvent: "+a.toString())};F.prototype.startTransaction=F.prototype.Qb=function(a){return this.la.Qb(a)};
F.prototype.commitTransaction=F.prototype.wd=function(a){return this.la.wd(a)};F.prototype.rollbackTransaction=F.prototype.Ap=function(){return this.la.Ap()};F.prototype.updateTargetBindings=F.prototype.Hb=function(a,b){void 0===b&&(b="");$f(this,"SourceChanged",Bf,b,a,null,null)};
v.defineProperty(F,{xl:"nodeKeyProperty"},function(){return this.Dj},function(a){var b=this.Dj;b!==a&&(lg(a,F,"nodeKeyProperty"),""===a&&v.k("Model.nodeKeyProperty may not be the empty string"),0<this.uc.count&&v.k("Cannot set Model.nodeKeyProperty when there is existing node data"),this.Dj=a,this.h("nodeKeyProperty",b,a))});function lg(a,b,c){"string"!==typeof a&&"function"!==typeof a&&v.Ed(a,"string or function",b,c)}
F.prototype.getKeyForNodeData=F.prototype.gb=function(a){if(null!==a){var b=this.Dj;if(""!==b&&(b=v.qb(a,b),void 0!==b)){if(mg(b))return b;v.k("Key value for node data "+a+" is not a number or a string: "+b)}}};
F.prototype.setKeyForNodeData=F.prototype.mx=function(a,b){void 0!==b&&null!==b&&mg(b)||v.Ed(b,"number or string",F,"setKeyForNodeData:key");if(null!==a){var c=this.Dj;if(""!==c)if(this.He(a)){var d=v.qb(a,c);d!==b&&null===this.ae(b)&&(v.Ka(a,c,b),this.uc.remove(d),this.uc.add(b,a),$f(this,"nodeKey",Af,c,a,d,b),"string"===typeof c&&this.Hb(a,c),this.Pt(d,b))}else v.Ka(a,c,b)}};
v.defineProperty(F,{bK:"makeUniqueKeyFunction"},function(){return this.fo},function(a){var b=this.fo;b!==a&&(null!==a&&v.j(a,"function",F,"makeUniqueKeyFunction"),this.fo=a,this.h("makeUniqueKeyFunction",b,a))});function mg(a){return"number"===typeof a||"string"===typeof a}F.prototype.containsNodeData=F.prototype.He=function(a){var b=this.gb(a);return void 0===b?!1:this.uc.ja(b)===a};
F.prototype.findNodeDataForKey=F.prototype.ae=function(a){null===a&&v.k("Model.findNodeDataForKey:key must not be null");return void 0!==a&&mg(a)?this.uc.ja(a):null};
v.defineProperty(F,{Yf:"nodeDataArray"},function(){return this.If},function(a){var b=this.If;if(b!==a){v.bz(a,F,"nodeDataArray");this.uc.clear();this.AA();for(var c=v.Va(a),d=0;d<c;d++){var e=v.Pa(a,d);if(!v.Ta(e)){v.k("Model.nodeDataArray must only contain Objects, not: "+e);return}v.ot(e)}this.If=a;for(var f=new I(Object),d=0;d<c;d++){var e=v.Pa(a,d),h=this.gb(e);void 0===h?f.add(e):null!==this.uc.ja(h)?f.add(e):this.uc.add(h,e)}for(d=f.i;d.next();)e=d.value,this.Nz(e),f=this.gb(e),void 0!==f&&
this.uc.add(f,e);$f(this,"nodeDataArray",Af,"nodeDataArray",this,b,a);for(d=0;d<c;d++)e=v.Pa(a,d),this.zp(e),this.yp(e);this.oD();v.sH(a)||(this.ab=!0)}});
F.prototype.makeNodeDataKeyUnique=F.prototype.Nz=function(a){if(null!==a){var b=this.Dj;if(""!==b){var c=this.gb(a);if(void 0===c||this.uc.contains(c)){var d=this.fo;if(null!==d&&(c=d(this,a),void 0!==c&&null!==c&&!this.uc.contains(c))){v.Ka(a,b,c);return}if("string"===typeof c){for(d=2;this.uc.contains(c+d);)d++;v.Ka(a,b,c+d)}else if(void 0===c||"number"===typeof c){for(d=-this.uc.count-1;this.uc.contains(d);)d--;v.Ka(a,b,d)}else v.k("Model.getKeyForNodeData returned something other than a string or a number: "+
c)}}}};F.prototype.addNodeData=F.prototype.Xk=function(a){null!==a&&(v.ot(a),ng(this,a,!0))};function ng(a,b,c){var d=a.gb(b);if(void 0===d||a.uc.ja(d)!==b)if(a.Nz(b),d=a.gb(b),void 0===d)v.k("Model.makeNodeDataKeyUnique failed on "+b+".  Data not added to Model.");else{a.uc.add(d,b);if(c){var e=v.Va(a.If);v.Mi(a.If,e,b)}$f(a,"nodeDataArray",Cf,"nodeDataArray",a,null,b,null,e);a.zp(b);a.yp(b)}}
F.prototype.addNodeDataCollection=function(a){if(v.isArray(a))for(var b=v.Va(a),c=0;c<b;c++)this.Xk(v.Pa(a,c));else for(a=a.i;a.next();)this.Xk(a.value)};F.prototype.removeNodeData=F.prototype.ex=function(a){null!==a&&og(this,a,!0)};function og(a,b,c){var d=a.gb(b);if(void 0!==d&&a.uc.contains(d)){a.uc.remove(d);if(c){var e=v.az(a.If,b);if(0>e)return;v.Th(a.If,e)}$f(a,"nodeDataArray",Df,"nodeDataArray",a,b,null,e,null);a.gu(b)}}
F.prototype.removeNodeDataCollection=function(a){if(v.isArray(a))for(var b=v.Va(a),c=0;c<b;c++)this.ex(v.Pa(a,c));else for(a=a.i;a.next();)this.ex(a.value)};g=F.prototype;g.Pt=function(a,b){var c=pg(this,a);c instanceof J&&this.ni.add(b,c)};g.AA=function(){};g.zp=function(){};g.yp=function(){};g.gu=function(){};function qg(a,b,c){if(void 0!==b){var d=a.ni.ja(b);null===d&&(d=new J(Object),a.ni.add(b,d));d.add(c)}}
function rg(a,b,c){if(void 0!==b){var d=a.ni.ja(b);d instanceof J&&(void 0===c||null===c?a.ni.remove(b):(d.remove(c),0===d.count&&a.ni.remove(b)))}}function pg(a,b){if(void 0===b)return null;var c=a.ni.ja(b);return c instanceof J?c:null}F.prototype.clearUnresolvedReferences=F.prototype.oD=function(a){void 0===a?this.ni.clear():this.ni.remove(a)};
v.defineProperty(F,{xJ:"copyNodeDataFunction"},function(){return this.yn},function(a){var b=this.yn;b!==a&&(null!==a&&v.j(a,"function",F,"copyNodeDataFunction"),this.yn=a,this.h("copyNodeDataFunction",b,a))});v.defineProperty(F,{hz:"copiesArrays"},function(){return this.Eu},function(a){var b=this.Eu;b!==a&&(null!==a&&v.j(a,"boolean",F,"copiesArrays"),this.Eu=a,this.h("copiesArrays",b,a))});
v.defineProperty(F,{gz:"copiesArrayObjects"},function(){return this.iq},function(a){var b=this.iq;b!==a&&(null!==a&&v.j(a,"boolean",F,"copiesArrayObjects"),this.iq=a,this.h("copiesArrayObjects",b,a))});F.prototype.copyNodeData=function(a){if(null===a)return null;var b=null,b=this.yn,b=null!==b?b(a,this):sg(this,a,!0);v.Ta(b)&&v.ec(b);return b};
function sg(a,b,c){if(a.hz&&Array.isArray(b)){var d=[];for(c=0;c<b.length;c++){var e=sg(a,b[c],a.gz);d.push(e)}v.ec(d);return d}if(c&&v.Ta(b)){c=(c=b.constructor)?new c:{};for(d in b)if("__gohashid"!==d){var e=v.qb(b,d),f;f=e;f instanceof G||f instanceof E||f instanceof Vf||f instanceof tg||f instanceof ug||f instanceof Wf||f instanceof Xf||f instanceof Yf||f instanceof xf||f instanceof yf?("_"!==d[0]&&v.trace('Warning: found GraphObject or Diagram reference when copying model data on property "'+
d+'" of data object: '+b.toString()+"  \nModel data should not have any references to a Diagram or any part of a diagram, such as: "+f.toString()),f=!0):f=f instanceof F||f instanceof Ff||f instanceof Qf||f instanceof zf?!0:!1;f||(e=sg(a,e,!1));v.Ka(c,d,e)}v.ec(c);return c}return b instanceof z?b.copy():b instanceof ia?b.copy():b instanceof B?b.copy():b instanceof L?b.copy():b instanceof eb?b.copy():b}
v.defineProperty(F,{dG:"afterCopyFunction"},function(){return this.Tp},function(a){var b=this.Tp;b!==a&&(null!==a&&v.j(a,"function",F,"afterCopyFunction"),this.Tp=a,this.h("afterCopyFunction",b,a))});var vg=!1;
F.prototype.setDataProperty=function(a,b,c){if(this.He(a))if(b===this.xl)this.mx(a,c);else{if(b===this.Ym){this.jx(a,c);return}}else!vg&&a instanceof G&&(vg=!0,v.trace('Model.setDataProperty is modifying a GraphObject, "'+a.toString()+'"'),v.trace("  Is that really your intent?"));var d=v.qb(a,b);d!==c&&(v.Ka(a,b,c),this.aA(a,b,d,c))};F.prototype.addArrayItem=function(a,b){this.mH(a,-1,b)};
F.prototype.insertArrayItem=F.prototype.mH=function(a,b,c){a===this.If&&v.k("Model.insertArrayItem or Model.addArrayItem should not be called on the Model.nodeDataArray");0>b&&(b=v.Va(a));v.Mi(a,b,c);$f(this,"",Cf,"",a,null,c,null,b)};F.prototype.removeArrayItem=function(a,b){void 0===b&&(b=-1);a===this.If&&v.k("Model.removeArrayItem should not be called on the Model.nodeDataArray");-1===b&&(b=v.Va(a)-1);var c=v.Pa(a,b);v.Th(a,b);$f(this,"",Df,"",a,c,null,b,null)};
v.defineProperty(F,{Ym:"nodeCategoryProperty"},function(){return this.fm},function(a){var b=this.fm;b!==a&&(lg(a,F,"nodeCategoryProperty"),this.fm=a,this.h("nodeCategoryProperty",b,a))});F.prototype.getCategoryForNodeData=F.prototype.xz=function(a){if(null===a)return"";var b=this.fm;if(""===b)return"";b=v.qb(a,b);if(void 0===b)return"";if("string"===typeof b)return b;v.k("getCategoryForNodeData found a non-string category for "+a+": "+b);return""};
F.prototype.setCategoryForNodeData=F.prototype.jx=function(a,b){v.j(b,"string",F,"setCategoryForNodeData:cat");if(null!==a){var c=this.fm;if(""!==c)if(this.He(a)){var d=v.qb(a,c);void 0===d&&(d="");d!==b&&(v.Ka(a,c,b),$f(this,"nodeCategory",Af,c,a,d,b))}else v.Ka(a,c,b)}};
function Q(a,b){2<arguments.length&&v.k("GraphLinksModel constructor can only take two optional arguments, the Array of node data and the Array of link data.");F.call(this);this.Gf=[];this.co=new J(Object);this.Yc=new na(null,Object);this.Kk="";this.Ll=this.xn=this.ho=null;this.Eh="from";this.Fh="to";this.cm=this.bm="";this.am="category";this.jg="";this.lo="isGroup";this.Ug="group";this.jq=!1;void 0!==a&&(this.Yf=a);void 0!==b&&(this.yg=b)}v.Ja(Q,F);v.ea("GraphLinksModel",Q);
Q.prototype.cloneProtected=function(a){F.prototype.cloneProtected.call(this,a);a.Kk=this.Kk;a.ho=this.ho;a.xn=this.xn;a.Eh=this.Eh;a.Fh=this.Fh;a.bm=this.bm;a.cm=this.cm;a.am=this.am;a.jg=this.jg;a.lo=this.lo;a.Ug=this.Ug;a.jq=this.jq};Q.prototype.clear=Q.prototype.clear=function(){F.prototype.clear.call(this);this.Gf=[];this.Yc.clear();this.co.clear()};g=Q.prototype;
g.toString=function(a){void 0===a&&(a=0);if(2<=a)return this.xA();var b=(""!==this.name?this.name:"")+" GraphLinksModel";if(0<a){b+="\n node data:";a=this.Yf;for(var c=v.Va(a),d=0,d=0;d<c;d++)var e=v.Pa(a,d),b=b+(" "+this.gb(e)+":"+Ef(e));b+="\n link data:";a=this.yg;c=v.Va(a);for(d=0;d<c;d++)e=v.Pa(a,d),b+=" "+this.ol(e)+"--\x3e"+this.pl(e)}return b};
g.jn=function(){var a=F.prototype.jn.call(this),b="";"category"!==this.Bt&&"string"===typeof this.Bt&&(b+=',\n  "linkCategoryProperty": '+this.quote(this.Bt));""!==this.Yi&&"string"===typeof this.Yi&&(b+=',\n  "linkKeyProperty": '+this.quote(this.Yi));"from"!==this.jp&&"string"===typeof this.jp&&(b+=',\n  "linkFromKeyProperty": '+this.quote(this.jp));"to"!==this.lp&&"string"===typeof this.lp&&(b+=',\n  "linkToKeyProperty": '+this.quote(this.lp));""!==this.Ct&&"string"===typeof this.Ct&&(b+=',\n  "linkFromPortIdProperty": '+
this.quote(this.Ct));""!==this.Et&&"string"===typeof this.Et&&(b+=',\n  "linkToPortIdProperty": '+this.quote(this.Et));""!==this.Dt&&"string"===typeof this.Dt&&(b+=',\n  "linkLabelKeysProperty": '+this.quote(this.Dt));"isGroup"!==this.Kt&&"string"===typeof this.Kt&&(b+=',\n  "nodeIsGroupProperty": '+this.quote(this.Kt));"group"!==this.vp&&"string"===typeof this.vp&&(b+=',\n  "nodeGroupKeyProperty": '+this.quote(this.vp));return a+b};
g.Nt=function(a){F.prototype.Nt.call(this,a);a.linkKeyProperty&&(this.Yi=a.linkKeyProperty);a.linkFromKeyProperty&&(this.jp=a.linkFromKeyProperty);a.linkToKeyProperty&&(this.lp=a.linkToKeyProperty);a.linkFromPortIdProperty&&(this.Ct=a.linkFromPortIdProperty);a.linkToPortIdProperty&&(this.Et=a.linkToPortIdProperty);a.linkCategoryProperty&&(this.Bt=a.linkCategoryProperty);a.linkLabelKeysProperty&&(this.Dt=a.linkLabelKeysProperty);a.nodeIsGroupProperty&&(this.Kt=a.nodeIsGroupProperty);a.nodeGroupKeyProperty&&
(this.vp=a.nodeGroupKeyProperty)};g.FA=function(){var a=F.prototype.FA.call(this),b=',\n  "linkDataArray": '+Mf(this,this.yg,!0);return a+b};g.cA=function(a){F.prototype.cA.call(this,a);a=a.linkDataArray;v.isArray(a)&&(this.Qt(a),this.yg=a)};
g.GA=function(a){a instanceof Q||v.k("Model.computeJsonDifference: newmodel must be a GraphLinksModel");""===this.Yi&&v.k("GraphLinksModel.linkKeyProperty must not be an empty string for .computeJsonDifference() to succeed.");var b=F.prototype.GA.call(this,a);Of(this,a,"linkKeyProperty");Of(this,a,"linkFromKeyProperty");Of(this,a,"linkToKeyProperty");Of(this,a,"linkLabelKeysProperty");Of(this,a,"nodeIsGroupProperty");Of(this,a,"nodeGroupKeyProperty");for(var c=new J,d=new J,e=(new J).Tc(this.Yc.mE),
f=new na,h=a.yg,k=0;k<h.length;k++){var l=h[k],m=a.af(l);if(void 0!==m){e.remove(m);var n=this.et(m);null===n?(c.add(m),d.add(l)):Nf(this,n,l,f)||d.add(l)}else this.Mz(l),m=this.af(l),c.add(m),d.add(l)}a=b;0<c.count&&(a+=this.Dx+Mf(this,c.dc(),!0));0<d.count&&(a+=this.NA+Mf(this,d.dc(),!0));0<e.count&&(a+=this.Fx+Mf(this,e.dc(),!0));return a};g.Dx=',\n  "insertedLinkKeys": ';g.NA=',\n  "modifiedLinkData": ';g.Fx=',\n  "removedLinkKeys": ';
g.EA=function(a,b){""===this.Yi&&v.k("GraphLinksModel.linkKeyProperty must not be an empty string for .toIncrementalJson() to succeed.");var c=F.prototype.EA.call(this,a,b),d=this,e=new J,f=new J,h=new J;a.Of.each(function(a){a.ba===d&&("linkDataArray"===a.gf?a.wc===Cf?e.add(a.newValue):a.wc===Df&&h.add(a.oldValue):d.ah(a.object)&&f.add(a.object))});var k=new J;e.each(function(a){k.add(d.af(a));b||f.add(a)});var l=new J;h.each(function(a){l.add(d.af(a));b&&f.add(a)});0<k.count&&(c+=(b?this.Fx:this.Dx)+
Mf(this,k.dc(),!0));0<f.count&&(c+=this.NA+Mf(this,f.dc(),!0));0<l.count&&(c+=(b?this.Dx:this.Fx)+Mf(this,l.dc(),!0));return c};
g.bA=function(a){F.prototype.bA.call(this,a);var b=a.insertedLinkKeys;if(v.isArray(b))for(var c=v.Va(b),d=0;d<c;d++){var e=v.Pa(b,d),f=this.et(e);null===f&&(f=this.rw({}),this.WE(f,e),this.Os(f))}b=a.modifiedLinkData;if(v.isArray(b))for(c=v.Va(b),d=0;d<c;d++){var h=v.Pa(b,d),e=this.af(h),f=this.et(e);if(null!==f)for(var k in h)"__gohashid"!==k&&k!==this.Yi&&this.setDataProperty(f,k,h[k])}a=a.removedLinkKeys;if(v.isArray(a))for(c=v.Va(a),d=0;d<c;d++)e=v.Pa(a,d),f=this.et(e),null!==f&&this.bx(f)};
g.Dm=function(a,b){var c=null;if(a.wc===Cf){if("linkDataArray"===a.gf?c=this.Gf:"linkLabelKeys"===a.gf&&(c=this.$j(a.object)),v.isArray(c)){if(b)v.Th(c,a.zg),c===this.Gf&&(c=this.af(a.newValue),void 0!==c&&this.Yc.remove(c));else if(v.Mi(c,a.zg,a.newValue),c===this.Gf){var d=a.newValue,c=this.af(d);void 0!==c&&this.Yc.add(c,d)}return}}else if(a.wc===Df&&("linkDataArray"===a.gf?c=this.Gf:"linkLabelKeys"===a.gf&&(c=this.$j(a.object)),v.isArray(c))){b?(v.Mi(c,a.Bg,a.oldValue),c===this.Gf&&(d=a.newValue,
c=this.af(d),void 0!==c&&this.Yc.add(c,d))):(v.Th(c,a.Bg),c===this.Gf&&(c=this.af(a.newValue),void 0!==c&&this.Yc.remove(c)));return}F.prototype.Dm.call(this,a,b)};v.defineProperty(Q,{$y:"archetypeNodeData"},function(){return this.Ll},function(a){var b=this.Ll;b!==a&&(null!==a&&v.A(a,Object,Q,"archetypeNodeData"),this.Ll=a,this.h("archetypeNodeData",b,a))});Q.prototype.Xm=function(a){if(void 0!==a){var b=this.Ll;if(null!==b){var c=this.ae(a);null===c&&(c=this.copyNodeData(b),v.Ka(c,this.Dj,a),this.Xk(c))}return a}};
v.defineProperty(Q,{jp:"linkFromKeyProperty"},function(){return this.Eh},function(a){var b=this.Eh;b!==a&&(lg(a,Q,"linkFromKeyProperty"),this.Eh=a,this.h("linkFromKeyProperty",b,a))});Q.prototype.getFromKeyForLinkData=Q.prototype.ol=function(a){if(null!==a){var b=this.Eh;if(""!==b&&(b=v.qb(a,b),void 0!==b)){if(mg(b))return b;v.k("FromKey value for link data "+a+" is not a number or a string: "+b)}}};
Q.prototype.setFromKeyForLinkData=Q.prototype.kx=function(a,b){null===b&&(b=void 0);void 0===b||mg(b)||v.Ed(b,"number or string",Q,"setFromKeyForLinkData:key");if(null!==a){var c=this.Eh;if(""!==c)if(b=this.Xm(b),this.ah(a)){var d=v.qb(a,c);d!==b&&(rg(this,d,a),v.Ka(a,c,b),null===this.ae(b)&&qg(this,b,a),$f(this,"linkFromKey",Af,c,a,d,b),"string"===typeof c&&this.Hb(a,c))}else v.Ka(a,c,b)}};
v.defineProperty(Q,{lp:"linkToKeyProperty"},function(){return this.Fh},function(a){var b=this.Fh;b!==a&&(lg(a,Q,"linkToKeyProperty"),this.Fh=a,this.h("linkToKeyProperty",b,a))});Q.prototype.getToKeyForLinkData=Q.prototype.pl=function(a){if(null!==a){var b=this.Fh;if(""!==b&&(b=v.qb(a,b),void 0!==b)){if(mg(b))return b;v.k("ToKey value for link data "+a+" is not a number or a string: "+b)}}};
Q.prototype.setToKeyForLinkData=Q.prototype.ox=function(a,b){null===b&&(b=void 0);void 0===b||mg(b)||v.Ed(b,"number or string",Q,"setToKeyForLinkData:key");if(null!==a){var c=this.Fh;if(""!==c)if(b=this.Xm(b),this.ah(a)){var d=v.qb(a,c);d!==b&&(rg(this,d,a),v.Ka(a,c,b),null===this.ae(b)&&qg(this,b,a),$f(this,"linkToKey",Af,c,a,d,b),"string"===typeof c&&this.Hb(a,c))}else v.Ka(a,c,b)}};
v.defineProperty(Q,{Ct:"linkFromPortIdProperty"},function(){return this.bm},function(a){var b=this.bm;b!==a&&(lg(a,Q,"linkFromPortIdProperty"),this.bm=a,this.h("linkFromPortIdProperty",b,a))});Q.prototype.getFromPortIdForLinkData=Q.prototype.VG=function(a){if(null===a)return"";var b=this.bm;if(""===b)return"";a=v.qb(a,b);return void 0===a?"":a};
Q.prototype.setFromPortIdForLinkData=Q.prototype.nA=function(a,b){v.j(b,"string",Q,"setFromPortIdForLinkData:portname");if(null!==a){var c=this.bm;if(""!==c)if(this.ah(a)){var d=v.qb(a,c);void 0===d&&(d="");d!==b&&(v.Ka(a,c,b),$f(this,"linkFromPortId",Af,c,a,d,b),"string"===typeof c&&this.Hb(a,c))}else v.Ka(a,c,b)}};v.defineProperty(Q,{Et:"linkToPortIdProperty"},function(){return this.cm},function(a){var b=this.cm;b!==a&&(lg(a,Q,"linkToPortIdProperty"),this.cm=a,this.h("linkToPortIdProperty",b,a))});
Q.prototype.getToPortIdForLinkData=Q.prototype.YG=function(a){if(null===a)return"";var b=this.cm;if(""===b)return"";a=v.qb(a,b);return void 0===a?"":a};Q.prototype.setToPortIdForLinkData=Q.prototype.qA=function(a,b){v.j(b,"string",Q,"setToPortIdForLinkData:portname");if(null!==a){var c=this.cm;if(""!==c)if(this.ah(a)){var d=v.qb(a,c);void 0===d&&(d="");d!==b&&(v.Ka(a,c,b),$f(this,"linkToPortId",Af,c,a,d,b),"string"===typeof c&&this.Hb(a,c))}else v.Ka(a,c,b)}};
v.defineProperty(Q,{Dt:"linkLabelKeysProperty"},function(){return this.jg},function(a){var b=this.jg;b!==a&&(lg(a,Q,"linkLabelKeysProperty"),this.jg=a,this.h("linkLabelKeysProperty",b,a))});Q.prototype.getLabelKeysForLinkData=Q.prototype.$j=function(a){if(null===a)return v.fj;var b=this.jg;if(""===b)return v.fj;a=v.qb(a,b);return void 0===a?v.fj:a};
Q.prototype.setLabelKeysForLinkData=Q.prototype.XE=function(a,b){v.bz(b,Q,"setLabelKeysForLinkData:arr");if(null!==a){var c=this.jg;if(""!==c)if(this.ah(a)){var d=v.qb(a,c);void 0===d&&(d=v.fj);if(d!==b){for(var e=v.Va(d),f=0;f<e;f++){var h=v.Pa(d,f);rg(this,h,a)}v.Ka(a,c,b);e=v.Va(b);for(f=0;f<e;f++)h=v.Pa(b,f),null===this.ae(h)&&qg(this,h,a);$f(this,"linkLabelKeys",Af,c,a,d,b);"string"===typeof c&&this.Hb(a,c)}}else v.Ka(a,c,b)}};
Q.prototype.addLabelKeyForLinkData=Q.prototype.Xy=function(a,b){if(null!==b&&void 0!==b&&(mg(b)||v.Ed(b,"number or string",Q,"addLabelKeyForLinkData:key"),null!==a)){var c=this.jg;if(""!==c){var d=v.qb(a,c);void 0===d?(c=[],c.push(b),this.XE(a,c)):v.isArray(d)?0<=v.az(d,b)||(v.Mi(d,Infinity,b),this.ah(a)&&(null===this.ae(b)&&qg(this,b,a),$f(this,"linkLabelKeys",Cf,c,a,null,b))):v.k(c+" property is not an Array; cannot addLabelKeyForLinkData: "+a)}}};
Q.prototype.removeLabelKeyForLinkData=Q.prototype.EE=function(a,b){if(null!==b&&void 0!==b&&(mg(b)||v.Ed(b,"number or string",Q,"removeLabelKeyForLinkData:key"),null!==a)){var c=this.jg;if(""!==c){var d=v.qb(a,c);if(v.isArray(d)){var e=v.az(d,b);0>e||(v.Th(d,e),this.ah(a)&&(rg(this,b,a),$f(this,"linkLabelKeys",Df,c,a,b,null)))}else void 0!==d&&v.k(c+" property is not an Array; cannot removeLabelKeyforLinkData: "+a)}}};
v.defineProperty(Q,{yg:"linkDataArray"},function(){return this.Gf},function(a){var b=this.Gf;if(b!==a){v.bz(a,Q,"linkDataArray");this.Yc.clear();for(var c=v.Va(a),d=0;d<c;d++){var e=v.Pa(a,d);if(!v.Ta(e)){v.k("GraphLinksModel.linkDataArray must only contain Objects, not: "+e);return}v.ot(e)}this.Gf=a;if(""!==this.Yi){for(var f=new I(Object),d=0;d<c;d++){var e=v.Pa(a,d),h=this.af(e);void 0===h?f.add(e):null!==this.Yc.ja(h)?f.add(e):this.Yc.add(h,e)}for(d=f.i;d.next();)e=d.value,this.Mz(e),f=this.af(e),
void 0!==f&&this.Yc.add(f,e)}f=new J(Object);for(d=0;d<c;d++)e=v.Pa(a,d),f.add(e);this.co=f;$f(this,"linkDataArray",Af,"linkDataArray",this,b,a);for(d=0;d<c;d++)e=v.Pa(a,d),wg(this,e)}});v.defineProperty(Q,{Yi:"linkKeyProperty"},function(){return this.Kk},function(a){var b=this.Kk;if(b!==a){lg(a,Q,"linkKeyProperty");this.Kk=a;this.Yc.clear();for(var c=v.Va(this.yg),d=0;d<c;d++){var e=v.Pa(this.yg,d),f=this.af(e);void 0!==f&&this.Yc.add(f,e)}this.h("linkKeyProperty",b,a)}});
Q.prototype.getKeyForLinkData=Q.prototype.af=function(a){if(null!==a){var b=this.Kk;if(""!==b&&(b=v.qb(a,b),void 0!==b)){if(mg(b))return b;v.k("Key value for link data "+a+" is not a number or a string: "+b)}}};
Q.prototype.setKeyForLinkData=Q.prototype.WE=function(a,b){void 0!==b&&null!==b&&mg(b)||v.Ed(b,"number or string",Q,"setKeyForLinkData:key");if(null!==a){var c=this.Kk;if(""!==c)if(this.ah(a)){var d=v.qb(a,c);d!==b&&null===this.et(b)&&(v.Ka(a,c,b),this.Yc.remove(d),this.Yc.add(b,a),$f(this,"linkKey",Af,c,a,d,b),"string"===typeof c&&this.Hb(a,c))}else v.Ka(a,c,b)}};
v.defineProperty(Q,{cK:"makeUniqueLinkKeyFunction"},function(){return this.ho},function(a){var b=this.ho;b!==a&&(null!==a&&v.j(a,"function",Q,"makeUniqueLinkKeyFunction"),this.ho=a,this.h("makeUniqueLinkKeyFunction",b,a))});Q.prototype.findLinkDataForKey=Q.prototype.et=function(a){null===a&&v.k("GraphLinksModel.findLinkDataForKey:key must not be null");return void 0!==a&&mg(a)?this.Yc.ja(a):null};
Q.prototype.makeLinkDataKeyUnique=Q.prototype.Mz=function(a){if(null!==a){var b=this.Kk;if(""!==b){var c=this.af(a);if(void 0===c||this.Yc.contains(c)){var d=this.ho;if(null!==d&&(c=d(this,a),void 0!==c&&null!==c&&!this.Yc.contains(c))){v.Ka(a,b,c);return}if("string"===typeof c){for(d=2;this.Yc.contains(c+d);)d++;v.Ka(a,b,c+d)}else if(void 0===c||"number"===typeof c){for(d=-this.Yc.count-1;this.Yc.contains(d);)d--;v.Ka(a,b,d)}else v.k("GraphLinksModel.getKeyForLinkData returned something other than a string or a number: "+
c)}}}};Q.prototype.containsLinkData=Q.prototype.ah=function(a){return null===a?!1:this.co.contains(a)};Q.prototype.addLinkData=Q.prototype.Os=function(a){if(null!==a){if(void 0===v.ld(a))v.ec(a);else if(this.ah(a))return;xg(this,a,!0)}};
function xg(a,b,c){if(""!==a.Yi){var d=a.af(b);if(void 0!==d&&a.Yc.ja(d)===b)return;a.Mz(b);d=a.af(b);if(void 0===d){v.k("GraphLinksModel.makeLinkDataKeyUnique failed on "+b+". Data not added to model.");return}a.Yc.add(d,b)}a.co.add(b);if(c){var e=v.Va(a.Gf);v.Mi(a.Gf,e,b)}$f(a,"linkDataArray",Cf,"linkDataArray",a,null,b,null,e);wg(a,b)}Q.prototype.addLinkDataCollection=function(a){if(v.isArray(a))for(var b=v.Va(a),c=0;c<b;c++)this.Os(v.Pa(a,c));else for(a=a.i;a.next();)this.Os(a.value)};
Q.prototype.removeLinkData=Q.prototype.bx=function(a){null!==a&&yg(this,a,!0)};function yg(a,b,c){a.co.remove(b);var d=a.af(b);void 0!==d&&a.Yc.remove(d);if(c){var e=a.Gf.indexOf(b);if(0>e)return;v.Th(a.Gf,e)}$f(a,"linkDataArray",Df,"linkDataArray",a,b,null,e,null);c=a.ol(b);rg(a,c,b);c=a.pl(b);rg(a,c,b);d=a.$j(b);if(v.isArray(d))for(var e=v.Va(d),f=0;f<e;f++)c=v.Pa(d,f),rg(a,c,b)}
Q.prototype.removeLinkDataCollection=function(a){if(v.isArray(a))for(var b=v.Va(a),c=0;c<b;c++)this.bx(v.Pa(a,c));else for(a=a.i;a.next();)this.bx(a.value)};function wg(a,b){var c=a.ol(b),c=a.Xm(c);null===a.ae(c)&&qg(a,c,b);c=a.pl(b);c=a.Xm(c);null===a.ae(c)&&qg(a,c,b);var d=a.$j(b);if(v.isArray(d))for(var e=v.Va(d),f=0;f<e;f++)c=v.Pa(d,f),null===a.ae(c)&&qg(a,c,b)}
v.defineProperty(Q,{wJ:"copyLinkDataFunction"},function(){return this.xn},function(a){var b=this.xn;b!==a&&(null!==a&&v.j(a,"function",Q,"copyLinkDataFunction"),this.xn=a,this.h("copyLinkDataFunction",b,a))});Q.prototype.copyLinkData=Q.prototype.rw=function(a){if(null===a)return null;var b=null,b=this.xn,b=null!==b?b(a,this):sg(this,a,!0);v.Ta(b)&&(v.ec(b),""!==this.Eh&&v.Ka(b,this.Eh,void 0),""!==this.Fh&&v.Ka(b,this.Fh,void 0),""!==this.jg&&v.Ka(b,this.jg,[]));return b};
v.defineProperty(Q,{Kt:"nodeIsGroupProperty"},function(){return this.lo},function(a){var b=this.lo;b!==a&&(lg(a,Q,"nodeIsGroupProperty"),this.lo=a,this.h("nodeIsGroupProperty",b,a))});Q.prototype.isGroupForNodeData=Q.prototype.Fz=function(a){if(null===a)return!1;var b=this.lo;return""===b?!1:v.qb(a,b)?!0:!1};v.defineProperty(Q,{vp:"nodeGroupKeyProperty"},function(){return this.Ug},function(a){var b=this.Ug;b!==a&&(lg(a,Q,"nodeGroupKeyProperty"),this.Ug=a,this.h("nodeGroupKeyProperty",b,a))});
v.defineProperty(Q,{Hm:"copiesGroupKeyOfNodeData"},function(){return this.jq},function(a){this.jq!==a&&(v.j(a,"boolean",Q,"copiesGroupKeyOfNodeData"),this.jq=a)});Q.prototype.getGroupKeyForNodeData=Q.prototype.Om=function(a){if(null!==a){var b=this.Ug;if(""!==b&&(b=v.qb(a,b),void 0!==b)){if(mg(b))return b;v.k("GroupKey value for node data "+a+" is not a number or a string: "+b)}}};
Q.prototype.setGroupKeyForNodeData=Q.prototype.lx=function(a,b){null===b&&(b=void 0);void 0===b||mg(b)||v.Ed(b,"number or string",Q,"setGroupKeyForNodeData:key");if(null!==a){var c=this.Ug;if(""!==c)if(this.He(a)){var d=v.qb(a,c);d!==b&&(rg(this,d,a),v.Ka(a,c,b),null===this.ae(b)&&qg(this,b,a),$f(this,"nodeGroupKey",Af,c,a,d,b),"string"===typeof c&&this.Hb(a,c))}else v.Ka(a,c,b)}};
Q.prototype.copyNodeData=function(a){if(null===a)return null;a=F.prototype.copyNodeData.call(this,a);this.Hm||""===this.Ug||void 0===v.qb(a,this.Ug)||v.Ka(a,this.Ug,void 0);return a};
Q.prototype.setDataProperty=function(a,b,c){if(this.He(a))if(b===this.xl)this.mx(a,c);else{if(b===this.Ym){this.jx(a,c);return}if(b===this.vp){this.lx(a,c);return}b===this.Kt&&v.k("GraphLinksModel.setDataProperty: property name must not be the nodeIsGroupProperty: "+b)}else if(this.ah(a)){if(b===this.jp){this.kx(a,c);return}if(b===this.lp){this.ox(a,c);return}if(b===this.Ct){this.nA(a,c);return}if(b===this.Et){this.qA(a,c);return}if(b===this.Yi){this.WE(a,c);return}if(b===this.Bt){this.VE(a,c);return}if(b===
this.Dt){this.XE(a,c);return}}else!vg&&a instanceof G&&(vg=!0,v.trace('GraphLinksModel.setDataProperty is modifying a GraphObject, "'+a.toString()+'"'),v.trace("  Is that really your intent?"));var d=v.qb(a,b);d!==c&&(v.Ka(a,b,c),this.aA(a,b,d,c))};g=Q.prototype;
g.Pt=function(a,b){F.prototype.Pt.call(this,a,b);for(var c=this.uc.i;c.next();)this.hA(c.value,a,b);for(c=this.co.i;c.next();){var d=c.value,e=a,f=b;if(this.ol(d)===e){var h=this.Eh;v.Ka(d,h,f);$f(this,"linkFromKey",Af,h,d,e,f);"string"===typeof h&&this.Hb(d,h)}this.pl(d)===e&&(h=this.Fh,v.Ka(d,h,f),$f(this,"linkToKey",Af,h,d,e,f),"string"===typeof h&&this.Hb(d,h));var k=this.$j(d);if(v.isArray(k))for(var l=v.Va(k),h=this.jg,m=0;m<l;m++)v.Pa(k,m)===e&&(v.kD(k,m,f),$f(this,"linkLabelKeys",Cf,h,d,e,
f))}};g.hA=function(a,b,c){if(this.Om(a)===b){var d=this.Ug;v.Ka(a,d,c);$f(this,"nodeGroupKey",Af,d,a,b,c);"string"===typeof d&&this.Hb(a,d)}};g.AA=function(){F.prototype.AA.call(this);for(var a=this.yg,b=v.Va(a),c=0;c<b;c++){var d=v.Pa(a,c);wg(this,d)}};
g.zp=function(a){F.prototype.zp.call(this,a);a=this.gb(a);var b=pg(this,a);if(null!==b){for(var c=new I(Object),b=b.i;b.next();){var d=b.value;if(this.He(d)){if(this.Om(d)===a){var e=this.Ug;$f(this,"nodeGroupKey",Af,e,d,a,a);"string"===typeof e&&this.Hb(d,e);c.add(d)}}else{this.ol(d)===a&&(e=this.Eh,$f(this,"linkFromKey",Af,e,d,a,a),"string"===typeof e&&this.Hb(d,e),c.add(d));this.pl(d)===a&&(e=this.Fh,$f(this,"linkToKey",Af,e,d,a,a),"string"===typeof e&&this.Hb(d,e),c.add(d));var f=this.$j(d);if(v.isArray(f))for(var h=
v.Va(f),e=this.jg,k=0;k<h;k++)v.Pa(f,k)===a&&($f(this,"linkLabelKeys",Cf,e,d,a,a),c.add(d))}}for(c=c.i;c.next();)rg(this,a,c.value)}};g.yp=function(a){F.prototype.yp.call(this,a);var b=this.Om(a);null===this.ae(b)&&qg(this,b,a)};g.gu=function(a){F.prototype.gu.call(this,a);var b=this.Om(a);rg(this,b,a)};v.defineProperty(Q,{Bt:"linkCategoryProperty"},function(){return this.am},function(a){var b=this.am;b!==a&&(lg(a,Q,"linkCategoryProperty"),this.am=a,this.h("linkCategoryProperty",b,a))});
Q.prototype.getCategoryForLinkData=Q.prototype.Dw=function(a){if(null===a)return"";var b=this.am;if(""===b)return"";b=v.qb(a,b);if(void 0===b)return"";if("string"===typeof b)return b;v.k("getCategoryForLinkData found a non-string category for "+a+": "+b);return""};
Q.prototype.setCategoryForLinkData=Q.prototype.VE=function(a,b){v.j(b,"string",Q,"setCategoryForLinkData:cat");if(null!==a){var c=this.am;if(""!==c)if(this.ah(a)){var d=v.qb(a,c);void 0===d&&(d="");d!==b&&(v.Ka(a,c,b),$f(this,"linkCategory",Af,c,a,d,b),"string"===typeof c&&this.Hb(a,c))}else v.Ka(a,c,b)}};
function Pf(a){1<arguments.length&&v.k("TreeModel constructor can only take one optional argument, the Array of node data.");F.call(this);this.Vg="parent";this.kq=!1;this.im="parentLinkCategory";void 0!==a&&(this.Yf=a)}v.Ja(Pf,F);v.ea("TreeModel",Pf);Pf.prototype.cloneProtected=function(a){F.prototype.cloneProtected.call(this,a);a.Vg=this.Vg;a.kq=this.kq;a.im=this.im};
Pf.prototype.toString=function(a){void 0===a&&(a=0);if(2<=a)return this.xA();var b=(""!==this.name?this.name:"")+" TreeModel";if(0<a){b+="\n node data:";a=this.Yf;for(var c=v.Va(a),d=0;d<c;d++)var e=v.Pa(a,d),b=b+(" "+this.gb(e)+":"+Ef(e))}return b};Pf.prototype.jn=function(){var a=F.prototype.jn.call(this),b="";"parent"!==this.wp&&"string"===typeof this.wp&&(b+=',\n  "nodeParentKeyProperty": '+this.quote(this.wp));return a+b};
Pf.prototype.Nt=function(a){F.prototype.Nt.call(this,a);a.nodeParentKeyProperty&&(this.wp=a.nodeParentKeyProperty)};Pf.prototype.Xm=function(a){return a};v.defineProperty(Pf,{wp:"nodeParentKeyProperty"},function(){return this.Vg},function(a){var b=this.Vg;b!==a&&(lg(a,Pf,"nodeParentKeyProperty"),this.Vg=a,this.h("nodeParentKeyProperty",b,a))});
v.defineProperty(Pf,{Im:"copiesParentKeyOfNodeData"},function(){return this.kq},function(a){this.kq!==a&&(v.j(a,"boolean",Pf,"copiesParentKeyOfNodeData"),this.kq=a)});Pf.prototype.getParentKeyForNodeData=Pf.prototype.Pm=function(a){if(null!==a){var b=this.Vg;if(""!==b&&(b=v.qb(a,b),void 0!==b)){if(mg(b))return b;v.k("ParentKey value for node data "+a+" is not a number or a string: "+b)}}};
Pf.prototype.setParentKeyForNodeData=Pf.prototype.oh=function(a,b){null===b&&(b=void 0);void 0===b||mg(b)||v.Ed(b,"number or string",Pf,"setParentKeyForNodeData:key");if(null!==a){var c=this.Vg;if(""!==c)if(b=this.Xm(b),this.He(a)){var d=v.qb(a,c);d!==b&&(rg(this,d,a),v.Ka(a,c,b),null===this.ae(b)&&qg(this,b,a),$f(this,"nodeParentKey",Af,c,a,d,b),"string"===typeof c&&this.Hb(a,c))}else v.Ka(a,c,b)}};
v.defineProperty(Pf,{wK:"parentLinkCategoryProperty"},function(){return this.im},function(a){var b=this.im;b!==a&&(lg(a,Pf,"parentLinkCategoryProperty"),this.im=a,this.h("parentLinkCategoryProperty",b,a))});Pf.prototype.getParentLinkCategoryForNodeData=Pf.prototype.XG=function(a){if(null===a)return"";var b=this.im;if(""===b)return"";b=v.qb(a,b);if(void 0===b)return"";if("string"===typeof b)return b;v.k("getParentLinkCategoryForNodeData found a non-string category for "+a+": "+b);return""};
Pf.prototype.setParentLinkCategoryForNodeData=Pf.prototype.yI=function(a,b){v.j(b,"string",Pf,"setParentLinkCategoryForNodeData:cat");if(null!==a){var c=this.im;if(""!==c)if(this.He(a)){var d=v.qb(a,c);void 0===d&&(d="");d!==b&&(v.Ka(a,c,b),$f(this,"parentLinkCategory",Af,c,a,d,b),"string"===typeof c&&this.Hb(a,c))}else v.Ka(a,c,b)}};
Pf.prototype.copyNodeData=function(a){if(null===a)return null;a=F.prototype.copyNodeData.call(this,a);this.Im||""===this.Vg||void 0===v.qb(a,this.Vg)||v.Ka(a,this.Vg,void 0);return a};
Pf.prototype.setDataProperty=function(a,b,c){if(this.He(a))if(b===this.xl)this.mx(a,c);else{if(b===this.Ym){this.jx(a,c);return}if(b===this.wp){this.oh(a,c);return}}else!vg&&a instanceof G&&(vg=!0,v.trace('TreeModel.setDataProperty is modifying a GraphObject, "'+a.toString()+'"'),v.trace("  Is that really your intent?"));var d=v.qb(a,b);d!==c&&(v.Ka(a,b,c),this.aA(a,b,d,c))};g=Pf.prototype;g.Pt=function(a,b){F.prototype.Pt.call(this,a,b);for(var c=this.uc.i;c.next();)this.hA(c.value,a,b)};
g.hA=function(a,b,c){if(this.Pm(a)===b){var d=this.Vg;v.Ka(a,d,c);$f(this,"nodeParentKey",Af,d,a,b,c);"string"===typeof d&&this.Hb(a,d)}};g.zp=function(a){F.prototype.zp.call(this,a);a=this.gb(a);var b=pg(this,a);if(null!==b){for(var c=new I(Object),b=b.i;b.next();){var d=b.value;if(this.He(d)&&this.Pm(d)===a){var e=this.Vg;$f(this,"nodeParentKey",Af,e,d,a,a);"string"===typeof e&&this.Hb(d,e);c.add(d)}}for(c=c.i;c.next();)rg(this,a,c.value)}};
g.yp=function(a){F.prototype.yp.call(this,a);var b=this.Pm(a),b=this.Xm(b);null===this.ae(b)&&qg(this,b,a)};g.gu=function(a){F.prototype.gu.call(this,a);var b=this.Pm(a);rg(this,b,a)};
function zg(a,b,c){v.ec(this);this.D=!1;void 0===a?a="":v.j(a,"string",zg,"constructor:targetprop");void 0===b?b=a:v.j(b,"string",zg,"constructor:sourceprop");void 0===c?c=null:null!==c&&v.j(c,"function",zg,"constructor:conv");this.GC=-1;this.pg=null;this.Eo=a;this.Do=this.zs=0;this.Tv=null;this.Yq=!1;this.uo=b;this.hq=c;this.ur=Ag;this.aq=null}v.ea("Binding",zg);
zg.prototype.copy=function(){var a=new zg;a.Eo=this.Eo;a.zs=this.zs;a.Do=this.Do;a.Tv=this.Tv;a.Yq=this.Yq;a.uo=this.uo;a.hq=this.hq;a.ur=this.ur;a.aq=this.aq;return a};var Ag;zg.OneWay=Ag=v.p(zg,"OneWay",1);var Bg;zg.TwoWay=Bg=v.p(zg,"TwoWay",2);zg.parseEnum=function(a,b){v.j(a,"function",zg,"parseEnum:ctor");v.pb(b,a,zg,"parseEnum:defval");return function(c){c=ra(a,c);return null===c?b:c}};var Ef;
zg.toString=Ef=function(a){var b=a;v.Ta(a)&&(a.text?b=a.text:a.name?b=a.name:void 0!==a.key?b=a.key:void 0!==a.id?b=a.id:a.constructor===Object&&(a.Text?b=a.Text:a.Name?b=a.Name:void 0!==a.Key?b=a.Key:void 0!==a.Id?b=a.Id:void 0!==a.ID&&(b=a.ID)));return void 0===b?"undefined":null===b?"null":b.toString()};zg.prototype.toString=function(){return"Binding("+this.sx+":"+this.aF+(-1!==this.Dl?" "+this.Dl:"")+" "+this.mode.name+")"};zg.prototype.freeze=function(){this.D=!0;return this};
zg.prototype.Oa=function(){this.D=!1;return this};v.defineProperty(zg,{Dl:null},function(){return this.GC},function(a){this.D&&v.ka(this);v.j(a,"number",zg,"targetId");this.GC=a});v.defineProperty(zg,{sx:"targetProperty"},function(){return this.Eo},function(a){this.D&&v.ka(this);v.j(a,"string",zg,"targetProperty");this.Eo=a});v.defineProperty(zg,{en:"sourceName"},function(){return this.Tv},function(a){this.D&&v.ka(this);null!==a&&v.j(a,"string",zg,"sourceName");this.Tv=a;null!==a&&(this.Yq=!1)});
v.defineProperty(zg,{yt:"isToModel"},function(){return this.Yq},function(a){this.D&&v.ka(this);v.j(a,"boolean",zg,"isToModel");this.Yq=a});v.defineProperty(zg,{aF:"sourceProperty"},function(){return this.uo},function(a){this.D&&v.ka(this);v.j(a,"string",zg,"sourceProperty");this.uo=a});v.defineProperty(zg,{uG:"converter"},function(){return this.hq},function(a){this.D&&v.ka(this);null!==a&&v.j(a,"function",zg,"converter");this.hq=a});
v.defineProperty(zg,{jG:"backConverter"},function(){return this.aq},function(a){this.D&&v.ka(this);null!==a&&v.j(a,"function",zg,"backConverter");this.aq=a});v.defineProperty(zg,{mode:"mode"},function(){return this.ur},function(a){this.D&&v.ka(this);v.pb(a,zg,zg,"mode");this.ur=a});zg.prototype.makeTwoWay=zg.prototype.JH=function(a){void 0===a&&(a=null);null!==a&&v.j(a,"function",zg,"makeTwoWay");this.mode=Bg;this.jG=a;return this};
zg.prototype.ofObject=zg.prototype.Sw=function(a){void 0===a&&(a="");this.en=a;this.yt=!1;return this};zg.prototype.ofModel=function(){this.en=null;this.yt=!0;return this};zg.prototype.ofData=function(){this.en=null;this.yt=!1;return this};function Cg(a,b,c){a=a.en;var d=null;return d=null===a||""===a?b:"."===a?c:".."===a?c.S:b.be(a)}
zg.prototype.updateTarget=zg.prototype.rF=function(a,b,c){var d=this.uo;if(void 0===c||""===d||d===c){c=this.Eo;var e=this.hq;if(null===e&&""===c)v.trace("Binding error: target property is the empty string: "+this.toString());else{var f=b;""!==d&&(f=v.qb(b,d));if(void 0!==f)if(null===e)""!==c&&v.Ka(a,c,f);else try{if(""!==c){var h=e(f,a);v.Ka(a,c,h)}else e(f,a)}catch(k){}}}};
zg.prototype.updateSource=zg.prototype.tx=function(a,b,c,d){void 0===d&&(d=null);if(this.ur===Bg){var e=this.Eo;if(void 0===c||e===c){c=this.uo;var f=this.aq;if(null!==f||""!==c){var h=a;""!==e&&(h=v.qb(a,e));if(void 0!==h)if(a=null!==d?d.ba:null,null===f)null!==a?a.setDataProperty(b,c,h):v.Ka(b,c,h);else try{if(""!==c){var k=f(h,b,a);null!==a?a.setDataProperty(b,c,k):v.Ka(b,c,k)}else f(h,b,a)}catch(l){}}}}};function Qf(){this.yF=(new I(zf)).freeze();this.Ob="";this.NB=!1}v.ea("Transaction",Qf);
Qf.prototype.toString=function(a){var b="Transaction: "+this.name+" "+this.Of.count.toString()+(this.tt?"":", incomplete");if(void 0!==a&&0<a){a=this.Of.count;for(var c=0;c<a;c++){var d=this.Of.da(c);null!==d&&(b+="\n  "+d.toString())}}return b};Qf.prototype.clear=Qf.prototype.clear=function(){var a=this.Of;a.Oa();for(var b=a.count-1;0<=b;b--){var c=a.da(b);null!==c&&c.clear()}a.clear();a.freeze()};Qf.prototype.canUndo=Qf.prototype.canUndo=function(){return this.tt};
Qf.prototype.undo=Qf.prototype.undo=function(){if(this.canUndo())for(var a=this.Of.count-1;0<=a;a--){var b=this.Of.da(a);null!==b&&b.undo()}};Qf.prototype.canRedo=Qf.prototype.canRedo=function(){return this.tt};Qf.prototype.redo=Qf.prototype.redo=function(){if(this.canRedo())for(var a=this.Of.count,b=0;b<a;b++){var c=this.Of.da(b);null!==c&&c.redo()}};v.u(Qf,{Of:"changes"},function(){return this.yF});v.defineProperty(Qf,{name:"name"},function(){return this.Ob},function(a){this.Ob=a});
v.defineProperty(Qf,{tt:"isComplete"},function(){return this.NB},function(a){this.NB=a});function Ff(){this.Ay=new J(F);this.Qe=!1;this.CF=(new I(Qf)).freeze();this.Og=-1;this.bC=999;this.ti=!1;this.Ku=null;this.Vk=0;this.YA=!1;this.Tg=(new I("string")).freeze();this.ko=new I("number");this.hy=!0;this.uy=!1}v.ea("UndoManager",Ff);
Ff.prototype.toString=function(a){for(var b="UndoManager "+this.Ti+"<"+this.history.count+"<="+this.rE,b=b+"[",c=this.wE.count,d=0;d<c;d++)0<d&&(b+=" "),b+=this.wE.da(d);b+="]";if(void 0!==a&&0<a)for(c=this.history.count,d=0;d<c;d++)b+="\n "+this.history.da(d).toString(a-1);return b};
Ff.prototype.clear=Ff.prototype.clear=function(){var a=this.history;a.Oa();for(var b=a.count-1;0<=b;b--){var c=a.da(b);null!==c&&c.clear()}a.clear();this.Og=-1;a.freeze();this.ti=!1;this.Ku=null;this.Vk=0;this.Tg.Oa();this.Tg.clear();this.Tg.freeze();this.ko.clear()};Ff.prototype.addModel=Ff.prototype.bG=function(a){this.Ay.add(a)};Ff.prototype.removeModel=Ff.prototype.iI=function(a){this.Ay.remove(a)};
Ff.prototype.startTransaction=Ff.prototype.Qb=function(a){void 0===a&&(a="");null===a&&(a="");if(this.ib)return!1;!0===this.hy&&(this.hy=!1,this.Vk++,this.$c("StartingFirstTransaction",a,this.Oi),0<this.Vk&&this.Vk--);this.isEnabled&&(this.Tg.Oa(),this.Tg.add(a),this.Tg.freeze(),null===this.Oi?this.ko.add(0):this.ko.add(this.Oi.Of.count));this.Vk++;var b=1===this.rh;b&&this.$c("StartedTransaction",a,this.Oi);return b};
Ff.prototype.commitTransaction=Ff.prototype.wd=function(a){void 0===a&&(a="");return Dg(this,!0,a)};Ff.prototype.rollbackTransaction=Ff.prototype.Ap=function(){return Dg(this,!1,"")};
function Dg(a,b,c){if(a.ib)return!1;a.cz&&1>a.rh&&v.trace("Ending transaction without having started a transaction: "+c);var d=1===a.rh;d&&b&&a.isEnabled&&a.$c("CommittingTransaction",c,a.Oi);var e=0;if(0<a.rh&&(a.Vk--,a.isEnabled)){var f=a.Tg.count;0<f&&(""===c&&(c=a.Tg.da(0)),a.Tg.Oa(),a.Tg.Vc(f-1),a.Tg.freeze());f=a.ko.count;0<f&&(e=a.ko.da(f-1),a.ko.Vc(f-1))}f=a.Oi;if(d){if(b){a.uy=!1;if(a.isEnabled&&null!==f){b=f;b.tt=!0;b.name=c;d=a.history;d.Oa();for(e=d.count-1;e>a.Ti;e--)f=d.da(e),null!==
f&&f.clear(),d.Vc(e),a.uy=!0;e=a.rE;0===e&&(e=1);0<e&&d.count>=e&&(f=d.da(0),null!==f&&f.clear(),d.Vc(0),a.Og--);d.add(b);a.Og++;d.freeze();f=b}a.$c("CommittedTransaction",c,f)}else{a.ti=!0;try{a.isEnabled&&null!==f&&(f.tt=!0,f.undo())}finally{a.$c("RolledBackTransaction",c,f),a.ti=!1}null!==f&&f.clear()}a.Ku=null;return!0}if(a.isEnabled&&!b&&null!==f){a=e;c=f.Of;for(b=c.count-1;b>=a;b--)d=c.da(b),null!==d&&d.undo(),c.Oa(),c.Vc(b);c.freeze()}return!1}
Ff.prototype.canUndo=Ff.prototype.canUndo=function(){if(!this.isEnabled||0<this.rh||this.ib)return!1;var a=this.lF;return null!==a&&a.canUndo()?!0:!1};Ff.prototype.undo=Ff.prototype.undo=function(){if(this.canUndo()){var a=this.lF;try{this.$c("StartingUndo","Undo",a),this.ti=!0,this.Og--,a.undo()}catch(b){v.trace("undo error: "+b.toString())}finally{this.ti=!1,this.$c("FinishedUndo","Undo",a)}}};
Ff.prototype.canRedo=Ff.prototype.canRedo=function(){if(!this.isEnabled||0<this.rh||this.ib)return!1;var a=this.kF;return null!==a&&a.canRedo()?!0:!1};Ff.prototype.redo=Ff.prototype.redo=function(){if(this.canRedo()){var a=this.kF;try{this.$c("StartingRedo","Redo",a),this.ti=!0,this.Og++,a.redo()}catch(b){v.trace("redo error: "+b.toString())}finally{this.ti=!1,this.$c("FinishedRedo","Redo",a)}}};
Ff.prototype.$c=function(a,b,c){void 0===c&&(c=null);var d=new zf;d.wc=Bf;d.propertyName=a;d.object=c;d.oldValue=b;for(a=this.SH;a.next();)b=a.value,d.ba=b,b.nw(d)};Ff.prototype.handleChanged=Ff.prototype.WD=function(a){if(this.isEnabled&&!this.ib&&!this.skipsEvent(a)){var b=this.Oi;null===b&&(this.Ku=b=new Qf);var c=a.copy(),b=b.Of;b.Oa();b.add(c);b.freeze();this.cz&&0>=this.rh&&!this.hy&&(a=a.g,null!==a&&!1===a.Tm||v.trace("Change not within a transaction: "+c.toString()))}};
Ff.prototype.skipsEvent=function(a){if(null===a||0>a.wc.value)return!0;a=a.object;if(a instanceof G){if(a=a.layer,null!==a&&a.Gc)return!0}else if(a instanceof Vf&&a.Gc)return!0;return!1};v.u(Ff,{SH:"models"},function(){return this.Ay.i});v.defineProperty(Ff,{isEnabled:"isEnabled"},function(){return this.Qe},function(a){this.Qe=a});v.u(Ff,{lF:"transactionToUndo"},function(){return 0<=this.Ti&&this.Ti<=this.history.count-1?this.history.da(this.Ti):null});
v.u(Ff,{kF:"transactionToRedo"},function(){return this.Ti<this.history.count-1?this.history.da(this.Ti+1):null});v.u(Ff,{ib:"isUndoingRedoing"},function(){return this.ti});v.u(Ff,{history:"history"},function(){return this.CF});v.defineProperty(Ff,{rE:"maxHistoryLength"},function(){return this.bC},function(a){this.bC=a});v.u(Ff,{Ti:"historyIndex"},function(){return this.Og});v.u(Ff,{Oi:"currentTransaction"},function(){return this.Ku});v.u(Ff,{rh:"transactionLevel"},function(){return this.Vk});
v.u(Ff,{gE:"isInTransaction"},function(){return 0<this.Vk});v.defineProperty(Ff,{cz:"checksTransactionLevel"},function(){return this.YA},function(a){this.YA=a});v.u(Ff,{wE:"nestedTransactionNames"},function(){return this.Tg});function Xf(){0<arguments.length&&v.dd(Xf);v.ec(this);this.W=null;this.aB=!1;this.uB=this.bB=!0;this.dB=this.eB=this.vB=this.fB=!1;this.Ok=this.RA=null;this.ZC=1.05;this.sB=1;this.vy=NaN;this.ZB=null;this.Vy=NaN;this.Uy=Zc;this.Gj=null}v.ea("CommandHandler",Xf);
var Eg=null,Fg="";Xf.prototype.toString=function(){return"CommandHandler"};v.u(Xf,{g:"diagram"},function(){return this.W});Xf.prototype.Mc=function(a){this.W=a};
Xf.prototype.doKeyDown=function(){var a=this.g;if(null!==a){var b=a.N,c=v.ak?b.Ft:b.control,d=b.shift,e=b.alt,f=b.key;!c||"C"!==f&&"Insert"!==f?c&&"X"===f||d&&"Del"===f?this.canCutSelection()&&this.cutSelection():c&&"V"===f||d&&"Insert"===f?this.canPasteSelection()&&this.pasteSelection():c&&"Y"===f||e&&d&&"Backspace"===f?this.canRedo()&&this.redo():c&&"Z"===f||e&&"Backspace"===f?this.canUndo()&&this.undo():"Del"===f||"Backspace"===f?this.canDeleteSelection()&&this.deleteSelection():c&&"A"===f?this.canSelectAll()&&
this.selectAll():"Esc"===f?this.canStopCommand()&&this.stopCommand():"Up"===f?a.oe&&(c?a.scroll("pixel","up"):a.scroll("line","up")):"Down"===f?a.oe&&(c?a.scroll("pixel","down"):a.scroll("line","down")):"Left"===f?a.ne&&(c?a.scroll("pixel","left"):a.scroll("line","left")):"Right"===f?a.ne&&(c?a.scroll("pixel","right"):a.scroll("line","right")):"PageUp"===f?d&&a.ne?a.scroll("page","left"):a.oe&&a.scroll("page","up"):"PageDown"===f?d&&a.ne?a.scroll("page","right"):a.oe&&a.scroll("page","down"):"Home"===
f?c&&a.oe?a.scroll("document","up"):!c&&a.ne&&a.scroll("document","left"):"End"===f?c&&a.oe?a.scroll("document","down"):!c&&a.ne&&a.scroll("document","right"):" "===f?this.canScrollToPart()&&this.scrollToPart():"Subtract"===f?this.canDecreaseZoom()&&this.decreaseZoom():"Add"===f?this.canIncreaseZoom()&&this.increaseZoom():c&&"0"===f?this.canResetZoom()&&this.resetZoom():d&&"Z"===f?this.canZoomToFit()&&this.zoomToFit():c&&!d&&"G"===f?this.canGroupSelection()&&this.groupSelection():c&&d&&"G"===f?this.canUngroupSelection()&&
this.ungroupSelection():b.event&&113===b.event.which?this.canEditTextBlock()&&this.editTextBlock():b.event&&93===b.event.which?this.canShowContextMenu()&&this.showContextMenu():b.bubbles=!0:this.canCopySelection()&&this.copySelection()}};Xf.prototype.doKeyUp=function(){var a=this.g;null!==a&&(a.N.bubbles=!0)};Xf.prototype.stopCommand=function(){var a=this.g;if(null!==a){var b=a.Wa;b instanceof Rg&&a.nf&&a.ow();null!==b&&b.doCancel()}};Xf.prototype.canStopCommand=function(){return!0};
Xf.prototype.selectAll=function(){var a=this.g;if(null!==a){a.pa();try{a.$b="wait";a.Ba("ChangingSelection");for(var b=a.dk;b.next();)b.value.hb=!0;for(var c=a.Ag;c.next();)c.value.hb=!0;for(var d=a.links;d.next();)d.value.hb=!0}finally{a.Ba("ChangedSelection"),a.$b=""}}};Xf.prototype.canSelectAll=function(){var a=this.g;return null!==a&&a.nf};
Xf.prototype.deleteSelection=function(){var a=this.g;if(null!==a&&!a.Ba("SelectionDeleting",a.selection))try{a.$b="wait";a.Qb("Delete");a.Ba("ChangingSelection");for(var b=new J(R),c=a.selection.i;c.next();)Sg(b,c.value,!0,this.FG?Infinity:0,this.ED,function(a){return a.canDelete()});a.gA(b,!0);a.Ba("SelectionDeleted",b)}finally{a.Ba("ChangedSelection"),a.wd("Delete"),a.$b=""}};Xf.prototype.canDeleteSelection=function(){var a=this.g;return null===a||a.ab||a.vf||!a.zm||0===a.selection.count?!1:!0};
function Sg(a,b,c,d,e,f){void 0===f&&(f=null);if(!(a.contains(b)||null!==f&&!f(b)||b instanceof Tg))if(a.add(b),b instanceof S){if(c&&b instanceof V)for(var h=b.Ic;h.next();)Sg(a,h.value,c,d,e,f);if(e)for(h=b.te;h.next();){var k=h.value;if(!a.contains(k)){var l=k.T,m=k.Z;null!==l&&a.contains(l)&&null!==m&&a.contains(m)?Sg(a,k,c,d,e,f):null!==l&&null!==m||Sg(a,k,c,d,e,f)}}if(1<d)for(b=b.ND();b.next();)Sg(a,b.value,c,d-1,e,f)}else if(b instanceof W)for(h=b.Sf;h.next();)Sg(a,h.value,c,d,e,f)}
Xf.prototype.Ro=function(a,b,c){var d=new na(R,R);for(a=a.i;a.next();)Ug(this,a.value,b,d,c);if(null!==b){c=b.ba;a=!1;null!==b.Za.$d&&(a=b.Za.$d.Qi);for(var e=new J(W),f=new na(W,W),h=d.i;h.next();){var k=h.value;if(k instanceof W){var l=k;a||null!==l.T&&null!==l.Z||e.add(l)}else if(c instanceof Pf&&k instanceof S&&null!==k.data){var l=c,m=k,k=h.key,n=k.kl();null!==n&&(n=d.ja(n),null!==n?(l.oh(m.data,l.gb(n.data)),l=b.Pf(m.data),k=k.Yo(),null!==k&&null!==l&&f.add(k,l)):l.oh(m.data,void 0))}}0<e.count&&
b.gA(e,!1);if(0<f.count)for(c=f.i;c.next();)d.add(c.key,c.value)}if(null!==b&&(b=b.ba,c=b.dG,null!==c)){var p=new na;d.each(function(a){null!==a.key.data&&p.add(a.key.data,a.value.data)});c(p,b,this.g.ba)}for(b=d.i;b.next();)b.value.Hb();return d};
function Ug(a,b,c,d,e){if(null===b||e&&!b.canCopy())return null;if(d.contains(b))return d.ja(b);var f=null,h=b.data;if(null!==h&&null!==c){var k=c.ba;b instanceof W?k instanceof Q&&(h=k.rw(h),v.Ta(h)&&(k.Os(h),f=c.Pf(h))):(h=k.copyNodeData(h),v.Ta(h)&&(k.Xk(h),f=c.dh(h)))}else Vg(b),f=b.copy(),null!==f&&(null!==c?c.add(f):null!==h&&a.vG&&(k=a.g.ba,h=f instanceof W&&k instanceof Q?k.rw(h):k.copyNodeData(h),v.Ta(h)&&(f.data=h)));if(!(f instanceof R))return null;f.hb=!1;f.fh=!1;d.add(b,f);if(b instanceof
S){for(h=b.te;h.next();){k=h.value;if(k.T===b){var l=d.ja(k);null!==l&&(l.T=f)}k.Z===b&&(l=d.ja(k),null!==l&&(l.Z=f))}if(b instanceof V&&f instanceof V)for(h=f,b=b.Ic;b.next();)k=Ug(a,b.value,c,d,e),k instanceof W||null===k||(k.Sa=h)}else if(b instanceof W)for(h=b.T,null!==h&&(h=d.ja(h),null!==h&&(f.T=h)),h=b.Z,null!==h&&(h=d.ja(h),null!==h&&(f.Z=h)),b=b.Sf;b.next();)h=Ug(a,b.value,c,d,e),null!==h&&(h.ee=f);return f}
Xf.prototype.copySelection=function(){var a=this.g;if(null!==a){for(var b=new J(R),a=a.selection.i;a.next();)Sg(b,a.value,!0,this.yG?Infinity:0,this.wG,function(a){return a.canCopy()});this.copyToClipboard(b)}};Xf.prototype.canCopySelection=function(){var a=this.g;return null!==a&&a.Uj&&a.Yy&&0!==a.selection.count?!0:!1};Xf.prototype.cutSelection=function(){this.copySelection();this.deleteSelection()};
Xf.prototype.canCutSelection=function(){var a=this.g;return null!==a&&!a.ab&&!a.vf&&a.Uj&&a.zm&&a.Yy&&0!==a.selection.count?!0:!1};Xf.prototype.copyToClipboard=function(a){var b=this.g;if(null!==b){var c=null;if(null===a)Eg=null,Fg="";else{var c=b.ba,d=!1,e=!1,f=null;try{if(c instanceof Pf){var h=c,d=h.Im;h.Im=this.xD}c instanceof Q&&(h=c,e=h.Hm,h.Hm=this.wD);f=b.Ro(a,null,!0)}finally{c instanceof Pf&&(c.Im=d),c instanceof Q&&(c.Hm=e),c=new I(R),c.Tc(f),Eg=c,Fg=b.ba.el}}b.Ba("ClipboardChanged",c)}};
Xf.prototype.pasteFromClipboard=function(){var a=new J(R),b=Eg;if(null===b)return a;var c=this.g;if(null===c||Fg!==c.ba.el)return a;var d=c.ba,e=!1,f=!1,h=null;try{if(d instanceof Pf){var k=d,e=k.Im;k.Im=this.xD}d instanceof Q&&(k=d,f=k.Hm,k.Hm=this.wD);h=c.Ro(b,c,!1)}finally{for(d instanceof Pf&&(d.Im=e),d instanceof Q&&(d.Hm=f),b=h.i;b.next();)c=b.value,d=b.key,c.location.G()||(d.location.G()?c.location=d.location:!c.position.G()&&d.position.G()&&(c.position=d.position)),a.add(c)}return a};
Xf.prototype.pasteSelection=function(a){void 0===a&&(a=null);var b=this.g;if(null!==b)try{b.$b="wait";b.Qb("Paste");b.Ba("ChangingSelection");var c=this.pasteFromClipboard();0<c.count&&Wg(b);for(var d=c.i;d.next();)d.value.hb=!0;b.Ba("ChangedSelection");if(null!==a){var e=b.computePartsBounds(b.selection);if(e){var f=b.Za.$d;null===f&&(f=new Xg,f.Mc(b));var h=f.computeEffectiveCollection(b.selection);f.moveParts(h,new z(a.x-e.na,a.y-e.ua),!1)}}b.Ba("ClipboardPasted",c)}finally{b.wd("Paste"),b.$b=
""}};Xf.prototype.canPasteSelection=function(){var a=this.g;return null===a||a.ab||a.vf||!a.Ko||!a.Yy||null===Eg||Fg!==a.ba.el?!1:!0};Xf.prototype.undo=function(){var a=this.g;null!==a&&a.la.undo()};Xf.prototype.canUndo=function(){var a=this.g;return null===a||a.ab||a.vf?!1:a.gD&&a.la.canUndo()};Xf.prototype.redo=function(){var a=this.g;null!==a&&a.la.redo()};Xf.prototype.canRedo=function(){var a=this.g;return null===a||a.ab||a.vf?!1:a.gD&&a.la.canRedo()};
Xf.prototype.decreaseZoom=function(a){void 0===a&&(a=1/this.vx);v.Ue(a,Xf,"decreaseZoom:factor");var b=this.g;null!==b&&b.al===Yg&&(a*=b.scale,a<b.hh||a>b.gh||(b.scale=a))};Xf.prototype.canDecreaseZoom=function(a){void 0===a&&(a=1/this.vx);v.Ue(a,Xf,"canDecreaseZoom:factor");var b=this.g;if(null===b||b.al!==Yg)return!1;a*=b.scale;return a<b.hh||a>b.gh?!1:b.mw};
Xf.prototype.increaseZoom=function(a){void 0===a&&(a=this.vx);v.Ue(a,Xf,"increaseZoom:factor");var b=this.g;null!==b&&b.al===Yg&&(a*=b.scale,a<b.hh||a>b.gh||(b.scale=a))};Xf.prototype.canIncreaseZoom=function(a){void 0===a&&(a=this.vx);v.Ue(a,Xf,"canIncreaseZoom:factor");var b=this.g;if(null===b||b.al!==Yg)return!1;a*=b.scale;return a<b.hh||a>b.gh?!1:b.mw};Xf.prototype.resetZoom=function(a){void 0===a&&(a=this.tw);v.Ue(a,Xf,"resetZoom:newscale");var b=this.g;null===b||a<b.hh||a>b.gh||(b.scale=a)};
Xf.prototype.canResetZoom=function(a){void 0===a&&(a=this.tw);v.Ue(a,Xf,"canResetZoom:newscale");var b=this.g;return null===b||a<b.hh||a>b.gh?!1:b.mw};Xf.prototype.zoomToFit=function(){var a=this.g;if(null!==a){var b=a.scale,c=a.position;b===this.Vy&&!isNaN(this.vy)&&a.Zd.K(this.Uy)?(a.scale=this.vy,a.position=this.ZB,this.Vy=NaN,this.Uy=Zc):(this.vy=b,this.ZB=c.copy(),a.zoomToFit(),this.Vy=a.scale,this.Uy=a.Zd.copy())}};Xf.prototype.canZoomToFit=function(){var a=this.g;return null===a?!1:a.mw};
Xf.prototype.scrollToPart=function(a){void 0===a&&(a=null);null!==a&&v.A(a,R,Xf,"part");var b=this.g;if(null!==b){if(null===a){try{null!==this.Gj&&(this.Gj.next()?a=this.Gj.value:this.Gj=null)}catch(c){}null===a&&(0<b.Qm.count?this.Gj=b.Qm.i:0<b.selection.count&&(this.Gj=b.selection.i),null!==this.Gj&&this.Gj.next()&&(a=this.Gj.value))}if(null!==a){var d=b.Na;d.$m("Scroll To Part");var e=b.position.copy();b.kG(a.U);e.Fc(b.position)&&d.Zh()}}};
Xf.prototype.canScrollToPart=function(a){void 0===a&&(a=null);null!==a&&v.A(a,R,Xf,"part");a=this.g;return null===a||0===a.selection.count&&0===a.Qm.count?!1:a.ne&&a.oe};Xf.prototype.collapseTree=function(a){void 0===a&&(a=null);var b=this.g;if(null!==b)try{b.Qb("Collapse Tree");b.Na.$m("Collapse Tree");var c=new I(S);if(null!==a&&a.md)a.collapseTree(),c.add(a);else for(var d=b.selection.i;d.next();){var e=d.value;e instanceof S&&(a=e,a.md&&(a.collapseTree(),c.add(a)))}b.Ba("TreeCollapsed",c)}finally{b.wd("Collapse Tree")}};
Xf.prototype.canCollapseTree=function(a){void 0===a&&(a=null);var b=this.g;if(null===b||b.ab)return!1;if(null!==a){if(!a.md)return!1;if(0<a.Cw().count)return!0}else for(a=b.selection.i;a.next();)if(b=a.value,b instanceof S&&b.md&&0<b.Cw().count)return!0;return!1};
Xf.prototype.expandTree=function(a){void 0===a&&(a=null);var b=this.g;if(null!==b)try{b.Qb("Expand Tree");b.Na.$m("Expand Tree");var c=new I(S);if(null===a||a.md)for(var d=b.selection.i;d.next();){var e=d.value;e instanceof S&&(a=e,a.md||(a.expandTree(),c.add(a)))}else a.expandTree(),c.add(a);b.Ba("TreeExpanded",c)}finally{b.wd("Expand Tree")}};
Xf.prototype.canExpandTree=function(a){void 0===a&&(a=null);var b=this.g;if(null===b||b.ab)return!1;if(null!==a){if(a.md)return!1;if(0<a.Cw().count)return!0}else for(a=b.selection.i;a.next();)if(b=a.value,b instanceof S&&!b.md&&0<b.Cw().count)return!0;return!1};
Xf.prototype.groupSelection=function(){var a=this.g;if(null!==a){var b=a.ba;if(b instanceof Q){var c=this.hD;if(null!==c){var d=null;try{a.$b="wait";a.Qb("Group");a.Ba("ChangingSelection");for(var e=new I(R),f=a.selection.i;f.next();){var h=f.value;h.Pd()&&h.canGroup()&&e.add(h)}for(var k=new I(R),l=e.i;l.next();){for(var m=l.value,f=!1,n=e.i;n.next();)if(m.Xh(n.value)){f=!0;break}f||k.add(m)}if(0<k.count){var p=k.first().Sa;if(null!==p)for(;null!==p;){for(var e=!1,q=k.i;q.next();)if(!q.value.Xh(p)){e=
!0;break}if(e)p=p.Sa;else break}if(c instanceof V)Vg(c),d=c.copy(),null!==d&&a.add(d);else if(b.Fz(c)){var r=b.copyNodeData(c);v.Ta(r)&&(b.Xk(r),d=a.Bw(r))}if(null!==d){null!==p&&this.isValidMember(p,d)&&(d.Sa=p);for(var s=k.i;s.next();){var t=s.value;this.isValidMember(d,t)&&(t.Sa=d)}a.select(d)}}a.Ba("ChangedSelection");a.Ba("SelectionGrouped",d)}finally{a.wd("Group"),a.$b=""}}}}};
Xf.prototype.canGroupSelection=function(){var a=this.g;if(null===a||a.ab||a.vf||!a.Ko||!a.hw||!(a.ba instanceof Q)||null===this.hD)return!1;for(a=a.selection.i;a.next();){var b=a.value;if(b.Pd()&&b.canGroup())return!0}return!1};function Zg(a){var b=v.jb();for(a=a.i;a.next();){var c=a.value;c instanceof W||b.push(c)}a=new J(R);for(var c=b.length,d=0;d<c;d++){for(var e=b[d],f=!0,h=0;h<c;h++)if(e.Xh(b[h])){f=!1;break}f&&a.add(e)}v.sa(b);return a}
Xf.prototype.isValidMember=function(a,b){if(null===b||a===b||b instanceof W)return!1;if(null!==a){if(a===b||a.Xh(b))return!1;var c=a.Pz;if(null!==c&&!c(a,b)||null===a.data&&null!==b.data||null!==a.data&&null===b.data)return!1}c=this.Pz;return null!==c?c(a,b):!0};
Xf.prototype.ungroupSelection=function(a){void 0===a&&(a=null);var b=this.g;if(null!==b){var c=b.ba;if(c instanceof Q)try{b.$b="wait";b.Qb("Ungroup");b.Ba("ChangingSelection");var d=new I(V);if(null!==a)d.add(a);else for(var e=b.selection.i;e.next();){var f=e.value;f instanceof V&&(a=f,a.canUngroup()&&d.add(a))}if(0<d.count){b.ow();for(var h=d.i;h.next();){var k=h.value;k.expandSubGraph();var l=k.Sa,m=null!==l&&null!==l.data?c.gb(l.data):void 0,n=new I(R);n.Tc(k.Ic);for(var p=n.i;p.next();){var q=
p.value;q.hb=!0;if(!(q instanceof W)){var r=q.data;null!==r?c.lx(r,m):q.Sa=l}}b.remove(k)}}b.Ba("ChangedSelection");b.Ba("SelectionUngrouped",d,n)}finally{b.wd("Ungroup"),b.$b=""}}};Xf.prototype.canUngroupSelection=function(a){void 0===a&&(a=null);var b=this.g;if(null===b||b.ab||b.vf||!b.zm||!b.lw||!(b.ba instanceof Q))return!1;if(null!==a){if(a.canUngroup())return!0}else for(a=b.selection.i;a.next();)if(b=a.value,b instanceof V&&b.canUngroup())return!0;return!1};
Xf.prototype.addTopLevelParts=function(a,b){for(var c=!0,d=Zg(a).i;d.next();){var e=d.value;null!==e.Sa&&(!b||this.isValidMember(null,e)?e.Sa=null:c=!1)}return c};
Xf.prototype.collapseSubGraph=function(a){void 0===a&&(a=null);var b=this.g;if(null!==b)try{b.Qb("Collapse SubGraph");b.Na.$m("Collapse SubGraph");var c=new I(V);if(null!==a&&a.de)a.collapseSubGraph(),c.add(a);else for(var d=b.selection.i;d.next();){var e=d.value;e instanceof V&&(a=e,a.de&&(a.collapseSubGraph(),c.add(a)))}b.Ba("SubGraphCollapsed",c)}finally{b.wd("Collapse SubGraph")}};
Xf.prototype.canCollapseSubGraph=function(a){void 0===a&&(a=null);var b=this.g;if(null===b||b.ab)return!1;if(null!==a)return a.de?!0:!1;for(a=b.selection.i;a.next();)if(b=a.value,b instanceof V&&b.de)return!0;return!1};
Xf.prototype.expandSubGraph=function(a){void 0===a&&(a=null);var b=this.g;if(null!==b)try{b.Qb("Expand SubGraph");b.Na.$m("Expand SubGraph");var c=new I(V);if(null===a||a.de)for(var d=b.selection.i;d.next();){var e=d.value;e instanceof V&&(a=e,a.de||(a.expandSubGraph(),c.add(a)))}else a.expandSubGraph(),c.add(a);b.Ba("SubGraphExpanded",c)}finally{b.wd("Expand SubGraph")}};
Xf.prototype.canExpandSubGraph=function(a){void 0===a&&(a=null);var b=this.g;if(null===b||b.ab)return!1;if(null!==a)return a.de?!1:!0;for(a=b.selection.i;a.next();)if(b=a.value,b instanceof V&&!b.de)return!0;return!1};
Xf.prototype.editTextBlock=function(a){void 0===a&&(a=null);null!==a&&v.A(a,$g,Xf,"editTextBlock");var b=this.g;if(null!==b){var c=b.Za.vA;if(null!==c){if(null===a){a=null;for(var d=b.selection.i;d.next();){var e=d.value;if(e.canEdit()){a=e;break}}if(null===a)return;a=a.ct(function(a){return a instanceof $g&&a.oz})}null!==a&&(b.Wa=null,c.ze=a,b.Wa=c)}}};
Xf.prototype.canEditTextBlock=function(a){void 0===a&&(a=null);null!==a&&v.A(a,$g,Xf,"canEditTextBlock");var b=this.g;if(null===b||b.ab||b.vf||!b.kw||null===b.Za.vA)return!1;if(null!==a){if(a=a.Y,null!==a&&a.canEdit())return!0}else for(b=b.selection.i;b.next();)if(a=b.value,a.canEdit()&&(a=a.ct(function(a){return a instanceof $g&&a.oz}),null!==a))return!0;return!1};
Xf.prototype.showContextMenu=function(a){var b=this.g;if(null!==b){var c=b.Za.fz;if(null!==c&&(void 0===a&&(a=0<b.selection.count?b.selection.first():b),a=c.findObjectWithContextMenu(a),null!==a)){var d=new xf,e=null;a instanceof G?e=a.Xa(Ab):b.tH||(e=b.rb,e=new z(e.x+e.width/2,e.y+e.height/2));null!==e&&(d.g=b,d.hf=b.mF(e),d.ca=e,d.left=!1,d.right=!0,d.up=!0,b.N=d);b.Wa=c;ah(c,!1,a)}}};
Xf.prototype.canShowContextMenu=function(a){var b=this.g;if(null===b)return!1;var c=b.Za.fz;if(null===c)return!1;void 0===a&&(a=0<b.selection.count?b.selection.first():b);return null===c.findObjectWithContextMenu(a)?!1:!0};v.defineProperty(Xf,{vG:"copiesClipboardData"},function(){return this.aB},function(a){v.j(a,"boolean",Xf,"copiesClipboardData");this.aB=a});
v.defineProperty(Xf,{wG:"copiesConnectedLinks"},function(){return this.bB},function(a){v.j(a,"boolean",Xf,"copiesConnectedLinks");this.bB=a});v.defineProperty(Xf,{ED:"deletesConnectedLinks"},function(){return this.uB},function(a){v.j(a,"boolean",Xf,"deletesConnectedLinks");this.uB=a});v.defineProperty(Xf,{yG:"copiesTree"},function(){return this.fB},function(a){v.j(a,"boolean",Xf,"copiesTree");this.fB=a});
v.defineProperty(Xf,{FG:"deletesTree"},function(){return this.vB},function(a){v.j(a,"boolean",Xf,"deletesTree");this.vB=a});v.defineProperty(Xf,{xD:"copiesParentKey"},function(){return this.eB},function(a){v.j(a,"boolean",Xf,"copiesParentKey");this.eB=a});v.defineProperty(Xf,{wD:"copiesGroupKey"},function(){return this.dB},function(a){v.j(a,"boolean",Xf,"copiesGroupKey");this.dB=a});
v.defineProperty(Xf,{hD:"archetypeGroupData"},function(){return this.RA},function(a){null!==a&&v.A(a,Object,Xf,"archetypeGroupData");var b=this.g;null!==b&&(b=b.ba,b instanceof Q&&(a instanceof V||b.Fz(a)||v.k("CommandHandler.archetypeGroupData must be either a Group or a data object for which GraphLinksModel.isGroupForNodeData is true: "+a)));this.RA=a});v.defineProperty(Xf,{Pz:"memberValidation"},function(){return this.Ok},function(a){null!==a&&v.j(a,"function",Xf,"memberValidation");this.Ok=a});
v.defineProperty(Xf,{tw:"defaultScale"},function(){return this.sB},function(a){v.Ue(a,Xf,"defaultScale");0<a||v.k("defaultScale must be larger than zero, not: "+a);this.sB=a});v.defineProperty(Xf,{vx:"zoomFactor"},function(){return this.ZC},function(a){v.Ue(a,Xf,"zoomFactor");1<a||v.k("zoomFactor must be larger than 1.0, not: "+a);this.ZC=a});function Wf(){0<arguments.length&&v.dd(Wf);v.ec(this);this.W=null;this.Ob="";this.Qe=!0;this.LB=!1;this.VC=null;this.dw=-1}v.ea("Tool",Wf);
Wf.prototype.Mc=function(a){this.W=a};Wf.prototype.toString=function(){return""!==this.name?this.name+" Tool":v.$e(Object.getPrototypeOf(this))};Wf.prototype.updateAdornments=function(){};Wf.prototype.canStart=function(){return this.isEnabled};Wf.prototype.doStart=function(){};Wf.prototype.doActivate=function(){this.qa=!0};Wf.prototype.doDeactivate=function(){this.qa=!1};Wf.prototype.doStop=function(){};Wf.prototype.doCancel=function(){this.stopTool()};
Wf.prototype.stopTool=function(){var a=this.g;null!==a&&a.Wa===this&&(a.Wa=null,a.$b="")};Wf.prototype.doMouseDown=function(){!this.qa&&this.canStart()&&this.doActivate()};Wf.prototype.doMouseMove=function(){};Wf.prototype.doMouseUp=function(){this.stopTool()};Wf.prototype.doMouseWheel=function(){};Wf.prototype.canStartMultiTouch=function(){return!0};
Wf.prototype.standardPinchZoomStart=function(){var a=this.g;if(null!==a){var b=a.N.event,c=null,d=null;if(void 0!==b.targetTouches){if(2>b.targetTouches.length)return;c=b.targetTouches[0];d=b.targetTouches[1]}else if(null!==a.Jf[0])c=a.Jf[0],d=a.Jf[1];else return;this.doCancel();if(a.ap("hasGestureZoom")){a.iy=!0;a.kC=a.scale;var e=bh(a),f=c,h=f.clientX-e.x,c=f.clientY-e.y,f=d,d=f.clientX-e.x,f=f.clientY-e.y;v.v(e);e=d-h;c=f-c;a.DC=Math.sqrt(e*e+c*c);b.preventDefault();b.cancelBubble=!0}}};
Wf.prototype.standardPinchZoomMove=function(){var a=this.g;if(null!==a){var b=a.N.event;this.doCancel();var c=null,d=null;if(void 0!==b.targetTouches){if(2>b.targetTouches.length)return;c=b.targetTouches[0];d=b.targetTouches[1]}else if(null!==a.Jf[0])c=a.Jf[0],d=a.Jf[1];else return;if(a.ap("hasGestureZoom")&&a.iy){var e=bh(a),f=c,c=f.clientX-e.x,h=f.clientY-e.y,f=d,d=f.clientX-e.x,f=f.clientY-e.y;v.v(e);var e=d-c,k=f-h,e=Math.sqrt(e*e+k*k)/a.DC,c=new z((Math.min(d,c)+Math.max(d,c))/2,(Math.min(f,
h)+Math.max(f,h))/2),h=a.kC*e,d=a.nb;h!==a.scale&&d.canResetZoom(h)&&(e=a.Hl,a.Hl=c,d.resetZoom(h),a.Hl=e);b.preventDefault();b.cancelBubble=!0}}};Wf.prototype.doKeyDown=function(){var a=this.g;null!==a&&"Esc"===a.N.key&&this.doCancel()};Wf.prototype.doKeyUp=function(){};Wf.prototype.startTransaction=Wf.prototype.Qb=function(a){void 0===a&&(a=this.name);this.Bf=null;var b=this.g;return null===b?!1:b.Qb(a)};
Wf.prototype.stopTransaction=Wf.prototype.fk=function(){var a=this.g;return null===a?!1:null===this.Bf?a.Ap():a.wd(this.Bf)};
Wf.prototype.standardMouseSelect=function(){var a=this.g;if(null!==a&&a.nf){var b=a.N,c=a.gt(b.ca,!1);if(null!==c)if(v.ak?b.Ft:b.control){a.Ba("ChangingSelection");for(b=c;null!==b&&!b.canSelect();)b=b.Sa;null!==b&&(b.hb=!b.hb);a.Ba("ChangedSelection")}else if(b.shift){if(!c.hb){a.Ba("ChangingSelection");for(b=c;null!==b&&!b.canSelect();)b=b.Sa;null!==b&&(b.hb=!0);a.Ba("ChangedSelection")}}else{if(!c.hb){for(b=c;null!==b&&!b.canSelect();)b=b.Sa;null!==b&&a.select(b)}}else!b.left||(v.ak?b.Ft:b.control)||
b.shift||a.ow()}};Wf.prototype.standardMouseClick=function(a,b){void 0===a&&(a=null);void 0===b&&(b=function(a){return!a.layer.Gc});var c=this.g;if(null===c)return!1;var d=c.N,e=c.qe(d.ca,a,b);d.ye=e;ch(e,d,c);return d.zc};
function ch(a,b,c){b.zc=!1;var d=0;b.left?d=1===b.Ve?1:2===b.Ve?2:1:b.right&&1===b.Ve&&(d=3);var e="";if(null!==a){switch(d){case 1:e="ObjectSingleClicked";break;case 2:e="ObjectDoubleClicked";break;case 3:e="ObjectContextClicked"}0!==d&&c.Ba(e,a)}else{switch(d){case 1:e="BackgroundSingleClicked";break;case 2:e="BackgroundDoubleClicked";break;case 3:e="BackgroundContextClicked"}0!==d&&c.Ba(e)}if(null!==a)for(;null!==a;){c=null;switch(d){case 1:c=a.click;break;case 2:c=a.at?a.at:a.click;break;case 3:c=
a.ez}if(null!==c&&(c(b,a),b.zc))break;a=a.S}else{a=null;switch(d){case 1:a=c.click;break;case 2:a=c.at?c.at:c.click;break;case 3:a=c.ez}null!==a&&a(b)}}
Wf.prototype.standardMouseOver=function(){var a=this.g;if(null!==a){var b=a.N;if(null!==b.g&&!0!==a.Na.pc){var c=a.tb;a.tb=!0;var d=a.qe(b.ca,null,null);b.ye=d;var e=!1;if(d!==a.zn){var f=a.zn,h=f;a.zn=d;this.doCurrentObjectChanged(f,d);for(b.zc=!1;null!==f;){var k=f.Ht;if(null!==k){if(d===f)break;if(null!==d&&d.tl(f))break;k(b,f,d);e=!0;if(b.zc)break}f=f.S}f=h;for(b.zc=!1;null!==d;){k=d.Gt;if(null!==k){if(f===d)break;if(null!==f&&f.tl(d))break;k(b,d,f);e=!0;if(b.zc)break}d=d.S}d=a.zn}if(null!==d){f=
d;for(k="";null!==f;){k=f.cursor;if(""!==k)break;f=f.S}a.$b=k;b.zc=!1;for(f=d;null!==f;){k=f.Tz;if(null!==k&&(k(b,f),e=!0,b.zc))break;f=f.S}}else a.$b="",k=a.Tz,null!==k&&(k(b),e=!0);e&&a.ue();a.tb=c}}};Wf.prototype.doCurrentObjectChanged=function(){};
Wf.prototype.standardMouseWheel=function(){var a=this.g;if(null!==a){var b=a.N,c=b.gl;if(0!==c&&a.Zd.G()){var d=a.nb,e=a.Za.It;if((e===dh&&!b.shift||e===eh&&b.control)&&(0<c?d.canIncreaseZoom():d.canDecreaseZoom()))e=a.Hl,a.Hl=b.hf,0<c?d.increaseZoom():d.decreaseZoom(),a.Hl=e,b.bubbles=!1;else if(e===dh&&b.shift||e===eh&&!b.control){var d=a.position.copy(),e=0<c?c:-c,f=b.event.deltaX,h=b.event.deltaY;void 0===f||void 0===h||0===f&&0===h?!b.shift&&a.oe?(f=a.Ut,e=e/40*f,0<c?a.scroll("pixel","up",e):
a.scroll("pixel","down",e)):b.shift&&a.ne&&(f=a.Tt,e=e/40*f,0<c?a.scroll("pixel","left",e):a.scroll("pixel","right",e)):(0!==f&&a.ne&&(0<f?a.scroll("pixel","left",-f):a.scroll("pixel","right",f)),0!==h&&a.oe&&(0<h?a.scroll("pixel","up",-h):a.scroll("pixel","down",h)));a.position.K(d)||(b.bubbles=!1)}}}};Wf.prototype.standardWaitAfter=function(a,b){v.j(a,"number",Wf,"standardWaitAfter:delay");this.cancelWaitAfter();var c=this,d=b.copy();this.dw=v.setTimeout(function(){c.doWaitAfter(d)},a)};
Wf.prototype.cancelWaitAfter=function(){-1!==this.dw&&v.clearTimeout(this.dw);this.dw=-1};Wf.prototype.doWaitAfter=function(){};Wf.prototype.findToolHandleAt=function(a,b){var c=this.g;if(null===c)return null;c=c.qe(a,function(a){for(;null!==a&&!(a.S instanceof Tg);)a=a.S;return a});return null===c?null:c.S.gc===b?c:null};
Wf.prototype.isBeyondDragSize=function(a,b){var c=this.g;if(null===c)return!1;void 0===a&&(a=c.yc.hf);void 0===b&&(b=c.N.hf);var d=c.Za.JG,e=d.width,d=d.height;c.yc.Vi&&(e+=6,d+=6);return Math.abs(b.x-a.x)>e||Math.abs(b.y-a.y)>d};v.u(Wf,{g:"diagram"},function(){return this.W});v.defineProperty(Wf,{name:"name"},function(){return this.Ob},function(a){v.j(a,"string",Wf,"name");this.Ob=a});
v.defineProperty(Wf,{isEnabled:"isEnabled"},function(){return this.Qe},function(a){v.j(a,"boolean",Wf,"isEnabled");this.Qe=a});v.defineProperty(Wf,{qa:"isActive"},function(){return this.LB},function(a){v.j(a,"boolean",Wf,"isActive");this.LB=a});v.defineProperty(Wf,{Bf:"transactionResult"},function(){return this.VC},function(a){null!==a&&v.j(a,"string",Wf,"transactionResult");this.VC=a});
function Xg(){0<arguments.length&&v.dd(Xg);Wf.call(this);this.name="Dragging";this.cB=this.PB=!0;this.Rq=this.BB=!1;this.UB=!0;this.jy=(new ia(NaN,NaN)).freeze();this.ky=tb;this.ly=(new z(NaN,NaN)).freeze();this.AB=!1;this.yB=this.$A=this.zB=this.kB=this.Ei=null;this.Aq=this.SB=!1;this.xo=new z(NaN,NaN);this.Uv=new z;this.Xv=!1;this.OB=!0;this.In=100;this.jj=[];this.BF=(new J(R)).freeze()}v.Ja(Xg,Wf);v.ea("DraggingTool",Xg);
v.defineProperty(Xg,{dE:"isCopyEnabled"},function(){return this.PB},function(a){v.j(a,"boolean",Xg,"isCopyEnabled");this.PB=a});v.defineProperty(Xg,{xG:"copiesEffectiveCollection"},function(){return this.cB},function(a){v.j(a,"boolean",Xg,"copiesEffectiveCollection");this.cB=a});v.defineProperty(Xg,{KG:"dragsTree"},function(){return this.BB},function(a){v.j(a,"boolean",Xg,"dragsTree");this.BB=a});
v.defineProperty(Xg,{Iw:"isGridSnapEnabled"},function(){return this.Rq},function(a){v.j(a,"boolean",Xg,"isGridSnapEnabled");this.Rq=a});v.defineProperty(Xg,{nH:"isComplexRoutingRealtime"},function(){return this.OB},function(a){v.j(a,"boolean",Xg,"isComplexRoutingRealtime");this.OB=a});v.defineProperty(Xg,{pH:"isGridSnapRealtime"},function(){return this.UB},function(a){v.j(a,"boolean",Xg,"isGridSnapRealtime");this.UB=a});
v.defineProperty(Xg,{VD:"gridSnapCellSize"},function(){return this.jy},function(a){v.A(a,ia,Xg,"gridSnapCellSize");this.jy.K(a)||(this.jy=a=a.R())});v.defineProperty(Xg,{ZG:"gridSnapCellSpot"},function(){return this.ky},function(a){v.A(a,L,Xg,"gridSnapCellSpot");this.ky.K(a)||(this.ky=a=a.R())});v.defineProperty(Xg,{$G:"gridSnapOrigin"},function(){return this.ly},function(a){v.A(a,z,Xg,"gridSnapOrigin");this.ly.K(a)||(this.ly=a=a.R())});
v.defineProperty(Xg,{Qi:"dragsLink"},function(){return this.AB},function(a){v.j(a,"boolean",Xg,"dragsLink");this.AB=a});v.defineProperty(Xg,{Km:"currentPart"},function(){return this.kB},function(a){null!==a&&v.A(a,R,Xg,"currentPart");this.kB=a});v.defineProperty(Xg,{hc:"copiedParts"},function(){return this.$A},function(a){this.$A=a});v.defineProperty(Xg,{bc:"draggedParts"},function(){return this.zB},function(a){this.zB=a});
v.u(Xg,{LJ:"draggingParts"},function(){return null!==this.hc?this.hc.cj():null!==this.bc?this.bc.cj():this.BF});v.defineProperty(Xg,{ad:"draggedLink"},function(){return this.yB},function(a){null!==a&&v.A(a,W,Xg,"draggedLink");this.yB=a});v.defineProperty(Xg,{Hw:"isDragOutStarted"},function(){return this.SB},function(a){this.SB=a});v.defineProperty(Xg,{ek:"startPoint"},function(){return this.Uv},function(a){v.A(a,z,Xg,"startPoint");this.Uv.K(a)||(this.Uv=a=a.R())});
v.defineProperty(Xg,{AD:"delay"},function(){return this.In},function(a){v.j(a,"number",Xg,"delay");this.In=a});Xg.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;if(null===a||a.ab&&!a.Ss||!a.$k&&!a.Uj&&!a.Ss||!a.nf)return!1;var b=a.N;return!b.left||a.Wa!==this&&(!this.isBeyondDragSize()||b.Vi&&b.timestamp-a.yc.timestamp<this.In)?!1:null!==this.findDraggablePart()};
Xg.prototype.findDraggablePart=function(){var a=this.g;if(null===a)return null;a=a.gt(a.yc.ca,!1);if(null===a)return null;for(;null!==a&&!a.canSelect();)a=a.Sa;return null!==a&&(a.canMove()||a.canCopy())?a:null};Xg.prototype.standardMouseSelect=function(){var a=this.g;if(null!==a&&a.nf){var b=a.gt(a.yc.ca,!1);if(null!==b){for(;null!==b&&!b.canSelect();)b=b.Sa;this.Km=b;this.Km.hb||(a.Ba("ChangingSelection"),b=a.N,(v.ak?b.Ft:b.control)||b.shift||Wg(a),this.Km.hb=!0,a.Ba("ChangedSelection"))}}};
Xg.prototype.doActivate=function(){var a=this.g;if(null!==a){null===this.Km&&this.standardMouseSelect();var b=this.Km;null!==b&&(b.canMove()||b.canCopy())&&(this.qa=!0,this.xo.set(a.position),fh(this,a.selection),this.jj.length=0,this.bc=this.computeEffectiveCollection(a.selection),a.bu=!0,gh(this,this.bc),this.Qb("Drag"),this.ek=a.yc.ca,a.Me=!0,a.Ss&&(this.Hw=!0,this.Aq=!1,hh=this,ih=this.g,this.doSimulatedDragOut()))}};
function fh(a,b){if(a.Qi){var c=a.g;null!==c&&c.Am&&(c.ba instanceof Q&&1===b.count&&b.first()instanceof W?(a.ad=b.first(),a.ad.canRelinkFrom()&&a.ad.canRelinkTo()&&a.ad.Vs(),a.Ei=c.Za.BE,null===a.Ei&&(a.Ei=new qh,a.Ei.Mc(c))):(a.ad=null,a.Ei=null))}}
Xg.prototype.computeEffectiveCollection=function(a){var b=null!==this.g&&this.g.Wa===this,c=new na(R);if(null===a)return c;for(var d=a.i;d.next();)uh(this,c,d.value,b);if(null!==this.ad&&this.Qi)return c;for(d=a.i;d.next();)a=d.value,a instanceof W&&(b=a.T,null===b||c.contains(b)?(b=a.Z,null===b||c.contains(b)||c.remove(a)):c.remove(a));return c};function vh(a){return void 0===a?new wh(Yc):new wh(a.copy())}
function uh(a,b,c,d){if(!b.contains(c)&&(!d||c.canMove()||c.canCopy()))if(c instanceof S){b.add(c,vh(c.location));if(c instanceof V)for(var e=c.Ic;e.next();)uh(a,b,e.value,d);for(e=c.te;e.next();){var f=e.value;if(!b.contains(f)){var h=f.T,k=f.Z;null!==h&&b.contains(h)&&null!==k&&b.contains(k)&&uh(a,b,f,d)}}if(a.KG)for(c=c.ND();c.next();)uh(a,b,c.value,d)}else if(c instanceof W)for(f=c,b.add(f,vh()),e=f.Sf;e.next();)uh(a,b,e.value,d);else c instanceof Tg||b.add(c,vh(c.location))}
Xg.prototype.doDeactivate=function(){this.qa=!1;var a=this.g;null!==a&&xh(a);yh(this);zh(this,this.bc);this.bc=this.Km=null;this.Aq=this.Hw=!1;if(0<Ah.count){for(var b=Ah.length,c=0;c<b;c++){var d=Ah.da(c);Bh(d);Ch(d);yh(d);null!==d.g&&xh(d.g)}Ah.clear()}Bh(this);this.xo.m(NaN,NaN);hh=ih=null;Ch(this);a.Me=!1;a.$b="";a.bu=!1;this.fk()};function yh(a){var b=a.g;if(null!==b){var c=b.tb;b.tb=!0;Dh(a,b.N,null);b.tb=c}a.jj.length=0}
function Eh(){var a=hh;Ch(a);Fh(a);var b=a.g;null!==b&&a.xo.G()&&(b.position=a.xo);null!==b&&xh(b)}Xg.prototype.doCancel=function(){Ch(this);Fh(this);var a=this.g;null!==a&&this.xo.G()&&(a.position=this.xo);this.stopTool()};function gh(a,b){if(null!==b){a.Xv=!0;for(var c=b.i;c.next();){var d=c.key;d instanceof W&&(d.Kp=!0)}}}function zh(a,b){if(null!==b){for(var c=b.i;c.next();){var d=c.key;d instanceof W&&(d.Kp=!1,d.rl&&Gh(d)&&d.ic())}a.Xv=!1}}
Xg.prototype.doKeyDown=function(){var a=this.g;null!==a&&(a=a.N,null!==a&&this.qa&&("Esc"===a.key?this.doCancel():this.doMouseMove()))};Xg.prototype.doKeyUp=function(){var a=this.g;null!==a&&null!==a.N&&this.qa&&this.doMouseMove()};function Hh(a,b){for(var c=Infinity,d=Infinity,e=-Infinity,f=-Infinity,h=a.i;h.next();){var k=h.value;if(k.Pd()&&k.isVisible()){var l=k.location,k=l.x,l=l.y;isNaN(k)||isNaN(l)||(k<c&&(c=k),l<d&&(d=l),k>e&&(e=k),l>f&&(f=l))}}Infinity===c?b.m(0,0,0,0):b.m(c,d,e-c,f-d)}
function Ih(a,b){if(null===a.hc){var c=a.g;if(!(null===c||b&&(c.ab||c.vf))&&null!==a.bc){var d=c.la;d.isEnabled&&d.gE?null!==d.Oi&&0<d.Oi.Of.count&&(c.la.Ap(),c.Qb("Drag")):Fh(a);c.tb=!b;c.Rp=!b;a.ek=c.yc.ca;d=a.xG?a.bc.cj():c.selection;d=c.Ro(d,c,!0);for(c=d.i;c.next();)c.value.location=c.key.location;c=v.ag();Hh(d,c);v.Vb(c);for(var c=new na(R),e=a.bc.i;e.next();){var f=e.key;f.Pd()&&f.canCopy()&&(f=d.ja(f),null!==f&&(f.sf(),c.add(f,vh(f.location))))}for(d=d.i;d.next();)e=d.value,e instanceof W&&
e.canCopy()&&c.add(e,vh());a.hc=c;fh(a,c.cj());null!==a.ad&&(c=a.ad,d=c.bn,c.wl(a.ek.x-(d.x+d.width/2),a.ek.y-(d.y+d.height/2)))}}}function Ch(a){var b=a.g;if(null!==b){if(null!==a.hc&&(b.gA(a.hc.cj(),!1),a.hc=null,null!==a.bc))for(var c=a.bc.i;c.next();)c.key instanceof W&&(c.value.point=new z(0,0));b.tb=!1;b.Rp=!1;a.ek=b.yc.ca}}function Bh(a){if(null!==a.ad){if(a.Qi&&null!==a.Ei){var b=a.Ei;b.g.remove(b.Cd);b.g.remove(b.Dd)}a.ad=null;a.Ei=null}}
function Jh(a,b,c){var d=a.g;if(null!==d){var e=a.ek,f=v.L();f.assign(d.N.ca);a.moveParts(b,f.du(e),c);v.v(f)}}
Xg.prototype.moveParts=function(a,b,c){if(null!==a&&(v.A(a,na,Xg,"moveParts:parts"),0!==a.count)){var d=v.L(),e=v.L();e.assign(b);isNaN(e.x)&&(e.x=0);isNaN(e.y)&&(e.y=0);(b=this.Xv)||gh(this,a);for(var f=new I(Kh),h=new I(Ea),k=a.i;k.next();){var l=k.key;if(l.Pd()){var m=Lh(this,l,a);if(null!==m)f.add(new Kh(l,k.value,m));else if(!c||l.canMove()){m=k.value.point;d.assign(m);var n=v.L(),p=this.computeMove(l,d.add(e),a,n);l.location=p;k.value.YE=n.du(m);v.v(n)}}else k.key instanceof W&&h.add(k.cb)}for(c=
f.i;c.next();)f=c.value,m=f.info.point,d.assign(m),f.Jc.location=d.add(f.aH.YE);c=v.L();m=v.L();for(h=h.i;h.next();)if(k=h.value,f=k.key,f instanceof W)if(f.Kp)l=f.T,n=f.Z,null!==this.ad&&this.Qi?(k=k.value.point,a.add(f,vh(e)),l=e.x-k.x,k=e.y-k.y,f.wl(l,k)):(null!==l&&(c.assign(l.location),p=a.ja(l),null!==p&&c.du(p.point)),null!==n&&(m.assign(n.location),p=a.ja(n),null!==p&&m.du(p.point)),null!==l&&null!==n?c.Fc(m)?(k=k.value.point,l=d,l.assign(c),l.du(k),a.add(f,vh(c)),f.wl(l.x,l.y)):(f.Kp=!1,
f.ic()):(k=k.value.point,n=null!==l?c:null!==n?m:e,a.add(f,vh(n)),l=n.x-k.x,k=n.y-k.y,f.wl(l,k)));else if(null===f.T||null===f.Z)k=k.value.point,a.add(f,vh(e)),l=e.x-k.x,k=e.y-k.y,f.wl(l,k);v.v(d);v.v(e);v.v(c);v.v(m);b||zh(this,a)}};function Lh(a,b,c){b=b.Sa;if(null!==b){a=Lh(a,b,c);if(null!==a)return a;a=c.ja(b);if(null!==a)return a}return null}
function Fh(a){if(null!==a.bc){for(var b=a.g,c=a.bc.i;c.next();){var d=c.key;d.Pd()&&(d.location=c.value.point)}for(c=a.bc.i;c.next();)if(d=c.key,d instanceof W&&d.Kp){var e=c.value.point;a.bc.add(d,vh());d.wl(-e.x,-e.y)}b.Uf()}}
Xg.prototype.computeMove=function(a,b,c,d){void 0===d&&(d=new z);d.assign(b);if(null===a)return d;void 0===c&&(c=null);var e=b;if(this.Iw&&(this.pH||null===c||this.g.N.up)&&(e=v.L(),c=e,c.assign(b),null!==a)){var f=this.g;if(null!==f){var h=f.mt,k=this.VD,f=k.width,k=k.height,l=this.$G,m=l.x,l=l.y,n=this.ZG;if(null!==h){var p=h.Ew;isNaN(f)&&(f=p.width);isNaN(k)&&(k=p.height);h=h.UD;isNaN(m)&&(m=h.x);isNaN(l)&&(l=h.y)}h=v.Mb(0,0);h.$t(0,0,f,k,n);Pa(b.x,b.y,m+h.x,l+h.y,f,k,c);v.v(h)}}c=null!==a.FD?
a.FD(a,b,e):e;k=a.RH;f=k.x;isNaN(f)&&(f=a.location.x);k=k.y;isNaN(k)&&(k=a.location.y);h=a.MH;m=h.x;isNaN(m)&&(m=a.location.x);h=h.y;isNaN(h)&&(h=a.location.y);d.m(Math.max(f,Math.min(c.x,m)),Math.max(k,Math.min(c.y,h)));e!==b&&v.v(e);return d};function Mh(a,b){if(null===b)return!0;var c=b.Y;return null===c||c instanceof Tg||c.layer.Gc||a.bc&&a.bc.contains(c)||a.hc&&a.hc.contains(c)?!0:!1}
function Nh(a,b,c,d){var e=a.g;if(null!==e){a.Qi&&(null!==a.ad&&(a.ad.T=null,a.ad.Z=null),Oh(a,!1));var f=Ph(e,b,null,function(b){return!Mh(a,b)}),h=e.N;h.ye=f;var k=e.tb,l=!1;try{e.tb=!0;l=Dh(a,h,f);if(!a.qa&&null===hh)return;if(null===f){var m=e.VH;null!==m&&(m(h),l=!0)}if(!a.qa&&null===hh)return;a.doDragOver(b,f);if(!a.qa&&null===hh)return}finally{e.tb=k,l&&e.Uf()}(e.ne||e.oe)&&(c||d)&&Qh(e,h.hf)}}
function Dh(a,b,c){var d=!1,e=a.jj.length,f=0<e?a.jj[0]:null;if(c===f)return!1;b.zc=!1;for(var h=0;h<e;h++){var k=a.jj[h],l=k.UH;if(null!==l&&(l(b,k,c),d=!0,b.zc))break}a.jj.length=0;if(!a.qa&&null===hh||null===c)return d;for(b.zc=!1;null!==c;)a.jj.push(c),c=Rh(c);e=a.jj.length;for(h=0;h<e&&(k=a.jj[h],l=k.TH,null===l||(l(b,k,f),d=!0,!b.zc));h++);return d}function Rh(a){var b=a.S;return null!==b?b:a instanceof R&&!(a instanceof V)&&(a=a.Sa,null!==a&&a.dH)?a:null}
function Sh(a,b,c){var d=a.Ei;if(null===d)return null;var e=a.g.Mm(b,d.zE,function(a){return d.findValidLinkablePort(a,c)});a=v.L();for(var f=Infinity,h=null,e=e.i;e.next();){var k=e.value;if(null!==k.Y){var l=k.Xa(Ab,a),l=b.rf(l);l<f&&(h=k,f=l)}}v.v(a);return h}
function Oh(a,b){var c=a.ad;if(null!==c&&!(2>c.oa)){var d=a.g;if(null!==d&&!d.ab&&(d=a.Ei,null!==d)){var e=null,f=null;null===c.T&&(e=Sh(a,c.l(0),!1),null!==e&&(f=e.Y));var h=null,k=null;null===c.Z&&(h=Sh(a,c.l(c.oa-1),!0),null!==h&&(k=h.Y));d.isValidLink(f,e,k,h)?b?(c.Cn=c.l(0).copy(),c.Gn=c.l(c.oa-1).copy(),c.Kp=!1,c.T=f,null!==e&&(c.wg=e.Sd),c.Z=k,null!==h&&(c.qh=h.Sd)):Th(d,f,e,k,h):Th(d,null,null,null,null)}}}Xg.prototype.doDragOver=function(){};
function Uh(a,b){var c=a.g;if(null!==c){a.Qi&&Oh(a,!0);yh(a);var d=Ph(c,b,null,function(b){return!Mh(a,b)}),e=c.N;e.ye=d;if(null!==d){e.zc=!1;for(var f=d;null!==f;){var h=f.Qz;if(null!==h&&(h(e,f),e.zc))break;f=Rh(f)}}else h=c.Qz,null!==h&&h(e);if(a.qa||null!==hh)if(a.doDropOnto(b,d),a.qa||null!==hh)for(d=c.selection.i;d.next();)e=d.value,e instanceof S&&Vh(c,e.U)}}Xg.prototype.doDropOnto=function(){};
Xg.prototype.doMouseMove=function(){if(this.qa){var a=this.g;if(null!==a&&null!==this.Km&&null!==this.bc){var b=!1,c=!1;this.mayCopy()?(b=!0,a.$b="copy",Ih(this,!1),gh(this,this.hc),Jh(this,this.hc,!1),zh(this,this.hc)):this.mayMove()?(c=!0,a.$b="default",Ch(this),Jh(this,this.bc,!0)):this.mayDragOut()?(a.$b="no-drop",Ih(this,!1),Jh(this,this.hc,!1)):Ch(this);Nh(this,a.N.ca,c,b)}}};
Xg.prototype.doMouseUp=function(){if(this.qa){var a=this.g;if(null!==a){var b=!1,c=this.mayCopy();c&&null!==this.hc?(Ch(this),Ih(this,!0),gh(this,this.hc),Jh(this,this.hc,!1),zh(this,this.hc),null!==this.hc&&a.TE(this.hc.cj())):(b=!0,Ch(this),this.mayMove()&&(Jh(this,this.bc,!0),Nh(this,a.N.ca,!0,!1)));this.Aq=!0;Uh(this,a.N.ca);if(this.qa){this.hc=null;if(b&&null!==this.bc)for(b=this.bc.i;b.next();){var d=b.key;d instanceof S&&(d=d.Sa,null===d||null===d.placeholder||this.bc.contains(d)||d.tD&&d.P())}a.qc();
zh(this,this.bc);this.Bf=c?"Copy":"Move";a.Ba(c?"SelectionCopied":"SelectionMoved",a.selection)}this.stopTool()}}};Xg.prototype.mayCopy=function(){if(!this.dE)return!1;var a=this.g;if(null===a||a.ab||a.vf||!a.Ko||!a.Uj||(v.ak?!a.N.alt:!a.N.control))return!1;for(a=a.selection.i;a.next();){var b=a.value;if(b.Pd()&&b.canCopy())return!0}return null!==this.ad&&this.Qi&&this.ad.canCopy()?!0:!1};
Xg.prototype.mayDragOut=function(){if(!this.dE)return!1;var a=this.g;if(null===a||!a.Ss||!a.Uj||a.$k)return!1;for(a=a.selection.i;a.next();){var b=a.value;if(b.Pd()&&b.canCopy())return!0}return null!==this.ad&&this.Qi&&this.ad.canCopy()?!0:!1};Xg.prototype.mayMove=function(){var a=this.g;if(null===a||a.ab||!a.$k)return!1;for(a=a.selection.i;a.next();){var b=a.value;if(b.Pd()&&b.canMove())return!0}return null!==this.ad&&this.Qi&&this.ad.canMove()?!0:!1};var Ah=new I(Xg),hh=null,ih=null;
Xg.prototype.mayDragIn=function(){var a=this.g;if(null===a||!a.fD||a.ab||a.vf||!a.Ko)return!1;var b=hh;return null===b||b.g.ba.el!==a.ba.el?!1:!0};Xg.prototype.doSimulatedDragEnter=function(){if(this.mayDragIn()){var a=this.g;a.Na.Zh();Wh(a);a=hh;null!==a&&(a.g.$b="copy")}};Xg.prototype.doSimulatedDragLeave=function(){var a=hh;null!==a&&a.doSimulatedDragOut();this.doCancel()};
Xg.prototype.doSimulatedDragOver=function(){var a=this.g;if(null!==a){var b=hh;null!==b&&null!==b.bc&&this.mayDragIn()&&(a.$b="copy",Xh(this,b.bc.cj(),!1),Jh(this,this.hc,!1),Nh(this,a.N.ca,!1,!0))}};
Xg.prototype.doSimulatedDrop=function(){var a=this.g;if(null!==a){var b=hh;if(null!==b){var c=b.g;b.Aq=!0;Ch(this);this.mayDragIn()&&(this.Qb("Drop"),Xh(this,b.bc.cj(),!0),Jh(this,this.hc,!1),null!==this.hc&&a.TE(this.hc.cj()),Uh(this,a.N.ca),a.qc(),b=a.selection,null!==this.hc?this.Bf="ExternalCopy":b=new J(R),this.hc=null,a.focus(),a.Ba("ExternalObjectsDropped",b,c),this.fk())}}};
function Xh(a,b,c){if(null===a.hc){var d=a.g;if(null!==d&&!d.ab&&!d.vf){d.tb=!c;d.Rp=!c;a.ek=d.N.ca;d=d.Ro(b,d,!0);c=v.ag();Hh(b,c);var e=c.x+c.width/2,f=c.y+c.height/2;v.Vb(c);var h=a.Uv;c=new na(R);var k=v.L();for(b=b.i;b.next();){var l=b.value;if(l.Pd()&&l.canCopy()){var m=l.location,l=d.ja(l);k.m(h.x-(e-m.x),h.y-(f-m.y));l.location=k;l.sf();c.add(l,vh(k))}}v.v(k);for(d=d.i;d.next();)e=d.value,e instanceof W&&e.canCopy()&&c.add(e,vh());a.hc=c;fh(a,c.cj());null!==a.ad&&(c=a.ad,d=c.bn,c.wl(a.ek.x-
(d.x+d.width/2),a.ek.y-(d.y+d.height/2)))}}}Xg.prototype.doSimulatedDragOut=function(){var a=this.g;null!==a&&(this.mayCopy()||this.mayMove()?a.$b="":a.$b="no-drop")};function wh(a){this.point=a;this.YE=Yc}v.ea("DraggingInfo",wh);function Kh(a,b,c){this.Jc=a;this.info=b;this.aH=c}
function Yh(){0<arguments.length&&v.dd(Yh);Wf.call(this);this.uC=100;this.XB=!1;var a=new W,b=new X;b.Rf=!0;b.stroke="blue";a.add(b);b=new X;b.Lp="Standard";b.fill="blue";b.stroke="blue";a.add(b);a.Tf="Tool";this.NC=a;a=new S;b=new X;b.Sd="";b.Ab="Rectangle";b.fill=null;b.stroke="magenta";b.lb=2;b.xa=nd;a.add(b);a.zl=!1;a.Tf="Tool";this.LC=a;this.MC=b;a=new S;b=new X;b.Sd="";b.Ab="Rectangle";b.fill=null;b.stroke="magenta";b.lb=2;b.xa=nd;a.add(b);a.zl=!1;a.Tf="Tool";this.OC=a;this.PC=b;this.rC=this.qC=
this.mC=this.lC=this.nC=null;this.TB=!0;this.TF=new na(G,"boolean");this.vC=this.Lk=this.HC=null}v.Ja(Yh,Wf);v.ea("LinkingBaseTool",Yh);Yh.prototype.doStop=function(){var a=this.g;null!==a&&xh(a);this.Fg=this.Eg=this.Dg=this.Cg=this.kc=null;this.ux.clear();this.$f=null};v.defineProperty(Yh,{zE:"portGravity"},function(){return this.uC},function(a){v.j(a,"number",Yh,"portGravity");0<=a&&(this.uC=a)});
v.defineProperty(Yh,{hp:"isUnconnectedLinkValid"},function(){return this.XB},function(a){v.j(a,"boolean",Yh,"isUnconnectedLinkValid");this.XB=a});v.defineProperty(Yh,{Jg:"temporaryLink"},function(){return this.NC},function(a){v.A(a,W,Yh,"temporaryLink");this.NC=a});v.defineProperty(Yh,{Cd:"temporaryFromNode"},function(){return this.LC},function(a){v.A(a,S,Yh,"temporaryFromNode");this.LC=a});
v.defineProperty(Yh,{fn:"temporaryFromPort"},function(){return this.MC},function(a){v.A(a,G,Yh,"temporaryFromPort");this.MC=a});v.defineProperty(Yh,{Dd:"temporaryToNode"},function(){return this.OC},function(a){v.A(a,S,Yh,"temporaryToNode");this.OC=a});v.defineProperty(Yh,{gn:"temporaryToPort"},function(){return this.PC},function(a){v.A(a,G,Yh,"temporaryToPort");this.PC=a});v.defineProperty(Yh,{kc:"originalLink"},function(){return this.nC},function(a){null!==a&&v.A(a,W,Yh,"originalLink");this.nC=a});
v.defineProperty(Yh,{Cg:"originalFromNode"},function(){return this.lC},function(a){null!==a&&v.A(a,S,Yh,"originalFromNode");this.lC=a});v.defineProperty(Yh,{Dg:"originalFromPort"},function(){return this.mC},function(a){null!==a&&v.A(a,G,Yh,"originalFromPort");this.mC=a});v.defineProperty(Yh,{Eg:"originalToNode"},function(){return this.qC},function(a){null!==a&&v.A(a,S,Yh,"originalToNode");this.qC=a});
v.defineProperty(Yh,{Fg:"originalToPort"},function(){return this.rC},function(a){null!==a&&v.A(a,G,Yh,"originalToPort");this.rC=a});v.defineProperty(Yh,{Bd:"isForwards"},function(){return this.TB},function(a){v.j(a,"boolean",Yh,"isForwards");this.TB=a});v.u(Yh,{ux:"validPortsCache"},function(){return this.TF});v.defineProperty(Yh,{$f:"targetPort"},function(){return this.HC},function(a){null!==a&&v.A(a,G,Yh,"targetPort");this.HC=a});
Yh.prototype.copyPortProperties=function(a,b,c,d,e){if(null!==a&&null!==b&&null!==c&&null!==d){d.xa=b.U.size;e?(d.yb=b.yb,d.Fl=b.Fl):(d.xb=b.xb,d.ll=b.ll);c.df=Ab;var f=v.L();c.location=b.Xa(Ab,f);v.v(f);d.angle=b.nl();null!==this.Yw&&this.Yw(a,b,c,d,e)}};Yh.prototype.setNoTargetPortProperties=function(a,b,c){null!==b&&(b.xa=nd,b.xb=sb,b.yb=sb);null!==a&&(a.location=this.g.N.ca);null!==this.Yw&&this.Yw(null,null,a,b,c)};Yh.prototype.doMouseDown=function(){this.qa&&this.doMouseMove()};
Yh.prototype.doMouseMove=function(){if(this.qa){var a=this.g;if(null!==a){this.$f=this.findTargetPort(this.Bd);if(null!==this.$f){var b=this.$f.Y;if(b instanceof S){this.Bd?this.copyPortProperties(b,this.$f,this.Dd,this.gn,!0):this.copyPortProperties(b,this.$f,this.Cd,this.fn,!1);return}}this.Bd?this.setNoTargetPortProperties(this.Dd,this.gn,!0):this.setNoTargetPortProperties(this.Cd,this.fn,!1);(a.ne||a.oe)&&Qh(a,a.N.hf)}}};
Yh.prototype.findValidLinkablePort=function(a,b){if(null===a)return null;var c=a.Y;if(!(c instanceof S))return null;for(;null!==a;){var d=b?a.eF:a.PD;if(!0===d&&(null!==a.Sd||a instanceof S)&&(b?this.isValidTo(c,a):this.isValidFrom(c,a)))return a;if(!1===d)break;a=a.S}return null};
Yh.prototype.findTargetPort=function(a){var b=this.g,c=b.N.ca,d=this.zE;0>=d&&(d=.1);for(var e=this,f=b.Mm(c,d,function(b){return e.findValidLinkablePort(b,a)},null,!0),d=Infinity,b=null,f=f.i;f.next();){var h=f.value,k=h.Y;if(k instanceof S){var l=h.Xa(Ab,v.L()),m=c.x-l.x,n=c.y-l.y;v.v(l);l=m*m+n*n;l<d&&(m=this.ux.ja(h),null!==m?m&&(b=h,d=l):a&&this.isValidLink(this.Cg,this.Dg,k,h)||!a&&this.isValidLink(k,h,this.Eg,this.Fg)?(this.ux.add(h,!0),b=h,d=l):this.ux.add(h,!1))}}return null!==b&&(c=b.Y,
c instanceof S&&(null===c.layer||c.layer.Ts))?b:null};Yh.prototype.isValidFrom=function(a,b){if(null===a||null===b)return this.hp;if(this.g.Wa===this&&(null!==a.layer&&!a.layer.Ts||!0!==b.PD))return!1;var c=b.vz;if(Infinity>c){if(null!==this.kc&&a===this.Cg&&b===this.Dg)return!0;var d=b.Sd;null===d&&(d="");if(a.Aw(d).count>=c)return!1}return!0};
Yh.prototype.isValidTo=function(a,b){if(null===a||null===b)return this.hp;if(this.g.Wa===this&&(null!==a.layer&&!a.layer.Ts||!0!==b.eF))return!1;var c=b.MI;if(Infinity>c){if(null!==this.kc&&a===this.Eg&&b===this.Fg)return!0;var d=b.Sd;null===d&&(d="");if(a.vg(d).count>=c)return!1}return!0};Yh.prototype.isInSameNode=function(a,b){if(null===a||null===b)return!1;if(a===b)return!0;var c=a.Y,d=b.Y;return null!==c&&c===d};
Yh.prototype.isLinked=function(a,b){if(null===a||null===b)return!1;var c=a.Y;if(!(c instanceof S))return!1;var d=a.Sd;null===d&&(d="");var e=b.Y;if(!(e instanceof S))return!1;var f=b.Sd;null===f&&(f="");for(e=e.vg(f);e.next();)if(f=e.value,f.T===c&&f.wg===d)return!0;return!1};
Yh.prototype.isValidLink=function(a,b,c,d){if(!this.isValidFrom(a,b)||!this.isValidTo(c,d)||!(null===b||null===d||(b.TG&&d.LI||!this.isInSameNode(b,d))&&(b.SG&&d.KI||!this.isLinked(b,d)))||null!==this.kc&&(null!==a&&this.isLabelDependentOnLink(a,this.kc)||null!==c&&this.isLabelDependentOnLink(c,this.kc))||null!==a&&null!==c&&(null===a.data&&null!==c.data||null!==a.data&&null===c.data)||!this.isValidCycle(a,c,this.kc))return!1;if(null!==a){var e=a.Pw;if(null!==e&&!e(a,b,c,d,this.kc))return!1}if(null!==
c&&(e=c.Pw,null!==e&&!e(a,b,c,d,this.kc)))return!1;e=this.Pw;return null!==e?e(a,b,c,d,this.kc):!0};Yh.prototype.isLabelDependentOnLink=function(a,b){if(null===a)return!1;var c=a.ee;if(null===c)return!1;if(c===b)return!0;var d=new J(S);d.add(a);return Zh(this,c,b,d)};function Zh(a,b,c,d){if(b===c)return!0;var e=b.T;if(null!==e&&e.uf&&(d.add(e),Zh(a,e.ee,c,d)))return!0;b=b.Z;return null!==b&&b.uf&&(d.add(b),Zh(a,b.ee,c,d))?!0:!1}
Yh.prototype.isValidCycle=function(a,b,c){void 0===c&&(c=null);if(null===a||null===b)return this.hp;var d=this.g.TI;if(d!==$h){if(d===ai){if(null!==c&&!c.Hc)return!0;for(d=b.te;d.next();){var e=d.value;if(e!==c&&e.Hc&&e.Z===b)return!1}return!bi(this,a,b,c,!0)}if(d===ci){if(null!==c&&!c.Hc)return!0;for(d=a.te;d.next();)if(e=d.value,e!==c&&e.Hc&&e.T===a)return!1;return!bi(this,a,b,c,!0)}if(d===di)return a===b?a=!0:(d=new J(S),d.add(b),a=ei(this,d,a,b,c)),!a;if(d===fi)return!bi(this,a,b,c,!1);if(d===
ri)return a===b?a=!0:(d=new J(S),d.add(b),a=si(this,d,a,b,c)),!a}return!0};function bi(a,b,c,d,e){if(b===c)return!0;if(null===b||null===c)return!1;for(var f=b.te;f.next();){var h=f.value;if(h!==d&&(!e||h.Hc)&&h.Z===b&&(h=h.T,h!==b&&bi(a,h,c,d,e)))return!0}return!1}function ei(a,b,c,d,e){if(c===d)return!0;if(null===c||null===d||b.contains(c))return!1;b.add(c);for(var f=c.te;f.next();){var h=f.value;if(h!==e&&h.Z===c&&(h=h.T,h!==c&&ei(a,b,h,d,e)))return!0}return!1}
function si(a,b,c,d,e){if(c===d)return!0;if(null===c||null===d||b.contains(c))return!1;b.add(c);for(var f=c.te;f.next();){var h=f.value;if(h!==e){var k=h.T,h=h.Z,k=k===c?h:k;if(k!==c&&si(a,b,k,d,e))return!0}}return!1}v.defineProperty(Yh,{Pw:"linkValidation"},function(){return this.Lk},function(a){null!==a&&v.j(a,"function",Yh,"linkValidation");this.Lk=a});v.defineProperty(Yh,{Yw:"portTargeted"},function(){return this.vC},function(a){null!==a&&v.j(a,"function",Yh,"portTargeted");this.vC=a});
function ti(){0<arguments.length&&v.dd(ti);Yh.call(this);this.name="Linking";this.TA={};this.SA=null;this.aa=ui;this.EC=null}v.Ja(ti,Yh);v.ea("LinkingTool",ti);var ui;ti.Either=ui=v.p(ti,"Either",0);var vi;ti.ForwardsOnly=vi=v.p(ti,"ForwardsOnly",0);var wi;ti.BackwardsOnly=wi=v.p(ti,"BackwardsOnly",0);v.defineProperty(ti,{fG:"archetypeLinkData"},function(){return this.TA},function(a){null!==a&&v.A(a,Object,ti,"archetypeLinkData");a instanceof G&&v.A(a,W,ti,"archetypeLinkData");this.TA=a});
v.defineProperty(ti,{iD:"archetypeLabelNodeData"},function(){return this.SA},function(a){null!==a&&v.A(a,Object,ti,"archetypeLabelNodeData");a instanceof G&&v.A(a,S,ti,"archetypeLabelNodeData");this.SA=a});v.defineProperty(ti,{direction:"direction"},function(){return this.aa},function(a){v.pb(a,ti,ti,"direction");this.aa=a});v.defineProperty(ti,{bF:"startObject"},function(){return this.EC},function(a){null!==a&&v.A(a,G,ti,"startObject");this.EC=a});
ti.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;if(null===a||a.ab||a.vf||!a.Ts)return!1;var b=a.ba;return(b instanceof Q||b instanceof Pf)&&a.N.left&&(a.Wa===this||this.isBeyondDragSize())?null!==this.findLinkablePort():!1};
ti.prototype.findLinkablePort=function(){var a=this.g;if(null===a)return null;var b=this.bF;null===b&&(b=a.qe(a.yc.ca,null,null));if(null===b||!(b.Y instanceof S))return null;a=this.direction;if(a===ui||a===vi){var c=this.findValidLinkablePort(b,!1);if(null!==c)return this.Bd=!0,c}if(a===ui||a===wi)if(c=this.findValidLinkablePort(b,!0),null!==c)return this.Bd=!1,c;return null};
ti.prototype.doActivate=function(){var a=this.g;if(null!==a){var b=this.findLinkablePort();null!==b&&(this.Qb(this.name),a.Me=!0,a.$b="pointer",this.Bd?(null===this.Dd||this.Dd.location.G()||(this.Dd.location=a.N.ca),this.Dg=b,b=this.Dg.Y,b instanceof S&&(this.Cg=b),this.copyPortProperties(this.Cg,this.Dg,this.Cd,this.fn,!1)):(null===this.Cd||this.Cd.location.G()||(this.Cd.location=a.N.ca),this.Fg=b,b=this.Fg.Y,b instanceof S&&(this.Eg=b),this.copyPortProperties(this.Eg,this.Fg,this.Dd,this.gn,!0)),
a.add(this.Cd),a.add(this.Dd),null!==this.Jg&&(null!==this.Cd&&(this.Jg.T=this.Cd),null!==this.Dd&&(this.Jg.Z=this.Dd),this.Jg.ic(),a.add(this.Jg)),this.qa=!0)}};ti.prototype.doDeactivate=function(){this.qa=!1;var a=this.g;null!==a&&(a.remove(this.Jg),a.remove(this.Cd),a.remove(this.Dd),a.Me=!1,a.$b="",this.fk())};ti.prototype.doStop=function(){Yh.prototype.doStop.call(this);this.bF=null};
ti.prototype.doMouseUp=function(){if(this.qa){var a=this.g;if(null===a)return;var b=this.Bf=null,c=null,d=null,e=null,f=this.$f=this.findTargetPort(this.Bd);if(null!==f){var h=f.Y;h instanceof S&&(this.Bd?(null!==this.Cg&&(b=this.Cg,c=this.Dg),d=h,e=f):(b=h,c=f,null!==this.Eg&&(d=this.Eg,e=this.Fg)))}else this.Bd?null!==this.Cg&&this.hp&&(b=this.Cg,c=this.Dg):null!==this.Eg&&this.hp&&(d=this.Eg,e=this.Fg);null!==b||null!==d?(h=this.insertLink(b,c,d,e),null!==h?(null===f&&(this.Bd?h.Gn=a.N.ca.copy():
h.Cn=a.N.ca.copy()),a.nf&&a.select(h),this.Bf=this.name,a.Ba("LinkDrawn",h)):(a.ba.oD(),this.doNoLink(b,c,d,e))):this.Bd?this.doNoLink(this.Cg,this.Dg,null,null):this.doNoLink(null,null,this.Eg,this.Fg)}this.stopTool()};
ti.prototype.insertLink=function(a,b,c,d){var e=this.g;if(null===e)return null;var f=e.ba;if(f instanceof Pf){var h=a;b=c;e.Qd||(h=c,b=a);if(null!==h&&null!==b)return f.oh(b.data,f.gb(h.data)),b.Yo()}else if(f instanceof Q)if(h="",null!==a&&(null===b&&(b=a),h=b.Sd,null===h&&(h="")),b="",null!==c&&(null===d&&(d=c),b=d.Sd,null===b&&(b="")),d=this.fG,d instanceof W){if(Vg(d),f=d.copy(),null!==f)return f.T=a,f.wg=h,f.Z=c,f.qh=b,e.add(f),a=this.iD,a instanceof S&&(Vg(a),a=a.copy(),null!==a&&(a.ee=f,e.add(a))),
f}else if(null!==d&&(d=f.rw(d),v.Ta(d)))return null!==a&&f.kx(d,f.gb(a.data)),f.nA(d,h),null!==c&&f.ox(d,f.gb(c.data)),f.qA(d,b),f.Os(d),a=this.iD,null===a||a instanceof S||(a=f.copyNodeData(a),v.Ta(a)&&(f.Xk(a),a=f.gb(a),void 0!==a&&f.Xy(d,a))),f=e.Pf(d);return null};ti.prototype.doNoLink=function(){};
function qh(){0<arguments.length&&v.dd(qh);Yh.call(this);this.name="Relinking";var a=new X;a.Ab="Diamond";a.xa=pd;a.fill="lightblue";a.stroke="dodgerblue";a.cursor="pointer";a.ve=0;this.FB=a;a=new X;a.Ab="Diamond";a.xa=pd;a.fill="lightblue";a.stroke="dodgerblue";a.cursor="pointer";a.ve=-1;this.QC=a;this.Sb=null;this.oC=new B}v.Ja(qh,Yh);v.ea("RelinkingTool",qh);
qh.prototype.updateAdornments=function(a){if(null!==a&&a instanceof W){var b="RelinkFrom",c=null;if(a.hb&&!this.g.ab){var d=a.Wt;null!==d&&a.canRelinkFrom()&&a.U.G()&&a.isVisible()&&d.U.G()&&d.Xi()&&(c=a.Vo(b),null===c&&(c=this.makeAdornment(d,!1),null!==c&&(c.gc=b),a.Wk(b,c)))}null===c&&a.$i(b);b="RelinkTo";c=null;a.hb&&!this.g.ab&&(d=a.Wt,null!==d&&a.canRelinkTo()&&a.U.G()&&a.isVisible()&&d.U.G()&&d.Xi()&&(c=a.Vo(b),null===c&&(c=this.makeAdornment(d,!0),null!==c&&(c.gc=b),a.Wk(b,c))));null===c&&
a.$i(b)}};qh.prototype.makeAdornment=function(a,b){var c=new Tg;c.type=xi;var d=b?this.JI:this.RG;null!==d&&c.add(d.copy());c.Zb=a;return c};v.defineProperty(qh,{RG:"fromHandleArchetype"},function(){return this.FB},function(a){null!==a&&v.A(a,G,qh,"fromHandleArchetype");this.FB=a});v.defineProperty(qh,{JI:"toHandleArchetype"},function(){return this.QC},function(a){null!==a&&v.A(a,G,qh,"toHandleArchetype");this.QC=a});v.u(qh,{handle:"handle"},function(){return this.Sb});
qh.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;if(null===a||a.ab||a.vf||!a.Am)return!1;var b=a.ba;if(!(b instanceof Q||b instanceof Pf)||!a.N.left)return!1;b=this.findToolHandleAt(a.yc.ca,"RelinkFrom");null===b&&(b=this.findToolHandleAt(a.yc.ca,"RelinkTo"));return null!==b};
qh.prototype.doActivate=function(){var a=this.g;if(null!==a){if(null===this.kc){var b=this.findToolHandleAt(a.yc.ca,"RelinkFrom");null===b&&(b=this.findToolHandleAt(a.yc.ca,"RelinkTo"));if(null===b)return;var c=b.Y;if(!(c instanceof Tg&&c.Mf instanceof W))return;this.Sb=b;this.Bd=null===c||"RelinkTo"===c.gc;this.kc=c.Mf}this.Qb(this.name);a.Me=!0;a.$b="pointer";this.Dg=this.kc.bd;this.Cg=this.kc.T;this.Fg=this.kc.Fd;this.Eg=this.kc.Z;this.oC.set(this.kc.U);null!==this.kc&&0<this.kc.oa&&(null===this.kc.T&&
(null!==this.fn&&(this.fn.xa=md),null!==this.Cd&&(this.Cd.location=this.kc.l(0))),null===this.kc.Z&&(null!==this.gn&&(this.gn.xa=md),null!==this.Dd&&(this.Dd.location=this.kc.l(this.kc.oa-1))));this.copyPortProperties(this.Cg,this.Dg,this.Cd,this.fn,!1);this.copyPortProperties(this.Eg,this.Fg,this.Dd,this.gn,!0);a.add(this.Cd);a.add(this.Dd);null!==this.Jg&&(null!==this.Cd&&(this.Jg.T=this.Cd),null!==this.Dd&&(this.Jg.Z=this.Dd),this.copyLinkProperties(this.kc,this.Jg),this.Jg.ic(),a.add(this.Jg));
this.qa=!0}};qh.prototype.copyLinkProperties=function(a,b){if(null!==a&&null!==b){b.Qs=a.Qs;b.iz=a.iz;var c=a.Ie;if(c===yi||c===zi)c=Ai;b.Ie=c;b.sw=a.sw;b.hx=a.hx;b.Ip=a.Ip;b.xb=a.xb;b.ll=a.ll;b.kt=a.kt;b.lt=a.lt;b.yb=a.yb;b.Fl=a.Fl;b.eu=a.eu;b.fu=a.fu}};qh.prototype.doDeactivate=function(){this.qa=!1;var a=this.g;null!==a&&(a.remove(this.Jg),a.remove(this.Cd),a.remove(this.Dd),a.Me=!1,a.$b="",this.fk())};qh.prototype.doStop=function(){Yh.prototype.doStop.call(this);this.Sb=null};
qh.prototype.doMouseUp=function(){if(this.qa){var a=this.g;if(null===a)return;this.Bf=null;var b=this.Cg,c=this.Dg,d=this.Eg,e=this.Fg,f=this.kc;this.$f=this.findTargetPort(this.Bd);if(null!==this.$f){var h=this.$f.Y;h instanceof S&&(this.Bd?(d=h,e=this.$f):(b=h,c=this.$f))}else this.hp?this.Bd?e=d=null:c=b=null:f=null;null!==f?(this.reconnectLink(f,this.Bd?d:b,this.Bd?e:c,this.Bd),null===this.$f&&(this.Bd?f.Gn=a.N.ca.copy():f.Cn=a.N.ca.copy(),f.ic()),a.nf&&(f.hb=!0),this.Bf=this.name,a.Ba("LinkRelinked",
f,this.Bd?this.Fg:this.Dg)):this.doNoRelink(this.kc,this.Bd);Bi(this.kc,this.oC)}this.stopTool()};qh.prototype.reconnectLink=function(a,b,c,d){if(null===this.g)return!1;c=null!==c&&null!==c.Sd?c.Sd:"";d?(a.Z=b,a.qh=c):(a.T=b,a.wg=c);return!0};qh.prototype.doNoRelink=function(){};function Th(a,b,c,d,e){null!==b?(a.copyPortProperties(b,c,a.Cd,a.fn,!1),a.g.add(a.Cd)):a.g.remove(a.Cd);null!==d?(a.copyPortProperties(d,e,a.Dd,a.gn,!0),a.g.add(a.Dd)):a.g.remove(a.Dd)}
function Ci(){0<arguments.length&&v.dd(Ci);Wf.call(this);this.name="LinkReshaping";var a=new X;a.Ab="Rectangle";a.xa=od;a.fill="lightblue";a.stroke="dodgerblue";this.Ak=a;a=new X;a.Ab="Diamond";a.xa=od;a.fill="lightblue";a.stroke="dodgerblue";this.cC=a;this.wC=3;this.Hx=this.Sb=null;this.pC=new z;this.Av=null}v.Ja(Ci,Wf);v.ea("LinkReshapingTool",Ci);var Di;Ci.None=Di=v.p(Ci,"None",0);var Ei;Ci.Horizontal=Ei=v.p(Ci,"Horizontal",1);var Fi;Ci.Vertical=Fi=v.p(Ci,"Vertical",2);var Gi;
Ci.All=Gi=v.p(Ci,"All",3);Ci.prototype.getReshapingBehavior=Ci.prototype.TD=function(a){return a&&a.xC?a.xC:Di};Ci.prototype.setReshapingBehavior=Ci.prototype.Zt=function(a,b){v.A(a,G,Ci,"setReshapingBehavior:obj");v.pb(b,Ci,Ci,"setReshapingBehavior:behavior");a.xC=b};
Ci.prototype.updateAdornments=function(a){if(null!==a&&a instanceof W){if(a.hb&&!this.g.ab){var b=a.path;if(null!==b&&a.canReshape()&&a.U.G()&&a.isVisible()&&b.U.G()&&b.Xi()){var c=a.Vo(this.name);if(null===c||c.IF!==a.oa||c.VF!==a.Rt)c=this.makeAdornment(b),null!==c&&(c.IF=a.oa,c.VF=a.Rt,a.Wk(this.name,c));if(null!==c){c.location=a.position;return}}}a.$i(this.name)}};
Ci.prototype.makeAdornment=function(a){var b=a.Y,c=b.oa,d=b.jc,e=null;if(null!==b.points&&1<c){e=new Tg;e.type=xi;var c=b.ht,f=b.Nw,h=d?1:0;if(b.Rt&&b.Ie!==Hi)for(var k=c+h;k<f-h;k++){var l=this.makeResegmentHandle(a,k);null!==l&&(l.ve=k,l.kA=.5,l.vz=999,e.add(l))}for(k=c+1;k<f;k++)if(l=this.makeHandle(a,k),null!==l){l.ve=k;if(k!==c)if(k===c+1&&d){var h=b.l(c),m=b.l(c+1);K(h.x,m.x)&&K(h.y,m.y)&&(m=b.l(c-1));K(h.x,m.x)?(this.Zt(l,Fi),l.cursor="n-resize"):K(h.y,m.y)&&(this.Zt(l,Ei),l.cursor="w-resize")}else k===
f-1&&d?(h=b.l(f-1),m=b.l(f),K(h.x,m.x)&&K(h.y,m.y)&&(h=b.l(f+1)),K(h.x,m.x)?(this.Zt(l,Fi),l.cursor="n-resize"):K(h.y,m.y)&&(this.Zt(l,Ei),l.cursor="w-resize")):k!==f&&(this.Zt(l,Gi),l.cursor="move");e.add(l)}e.gc=this.name;e.Zb=a}return e};Ci.prototype.makeHandle=function(){var a=this.nt;return null===a?null:a.copy()};v.defineProperty(Ci,{nt:"handleArchetype"},function(){return this.Ak},function(a){null!==a&&v.A(a,G,Ci,"handleArchetype");this.Ak=a});
Ci.prototype.makeResegmentHandle=function(){var a=this.QH;return null===a?null:a.copy()};v.defineProperty(Ci,{QH:"midHandleArchetype"},function(){return this.cC},function(a){null!==a&&v.A(a,G,Ci,"midHandleArchetype");this.cC=a});v.u(Ci,{handle:"handle"},function(){return this.Sb});v.u(Ci,{Rs:"adornedLink"},function(){return this.Hx});Ci.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;return null!==a&&!a.ab&&a.iw&&a.N.left?null!==this.findToolHandleAt(a.yc.ca,this.name):!1};
Ci.prototype.doActivate=function(){var a=this.g;if(null!==a&&(this.Sb=this.findToolHandleAt(a.yc.ca,this.name),null!==this.Sb)){var b=this.Sb.Y.Mf;if(b instanceof W){this.Hx=b;a.Me=!0;this.Qb(this.name);if(b.Rt&&999===this.Sb.vz){var c=b.points.copy(),d=this.Sb.Xa(Ab);c.Ad(this.Sb.ve+1,d);b.jc&&c.Ad(this.Sb.ve+1,d);b.points=c;b.Od();this.Sb=this.findToolHandleAt(a.yc.ca,this.name);if(null===this.Sb){this.doDeactivate();return}}this.pC=b.l(this.Sb.ve);this.Av=b.points.copy();this.qa=!0}}};
Ci.prototype.doDeactivate=function(){this.fk();this.Hx=this.Sb=null;var a=this.g;null!==a&&(a.Me=!1);this.qa=!1};Ci.prototype.doCancel=function(){var a=this.Rs;null!==a&&(a.points=this.Av);this.stopTool()};Ci.prototype.doMouseMove=function(){var a=this.g;this.qa&&null!==a&&(a=this.computeReshape(a.N.ca),this.reshape(a))};
Ci.prototype.doMouseUp=function(){var a=this.g;if(this.qa&&null!==a){var b=this.computeReshape(a.N.ca);this.reshape(b);b=this.Rs;if(null!==b&&b.Rt){var c=this.handle.ve,d=b.l(c-1),e=b.l(c),f=b.l(c+1);if(b.jc){if(c>b.ht+1&&c<b.Nw-1){var h=b.l(c-2);if(Math.abs(d.x-e.x)<this.mh&&Math.abs(d.y-e.y)<this.mh&&(Ii(this,h,d,e,f,!0)||Ii(this,h,d,e,f,!1))){var k=b.points.copy();Ii(this,h,d,e,f,!0)?(k.Hg(c-2,new z(h.x,(f.y+h.y)/2)),k.Hg(c+1,new z(f.x,(f.y+h.y)/2))):(k.Hg(c-2,new z((f.x+h.x)/2,h.y)),k.Hg(c+1,
new z((f.x+h.x)/2,f.y)));k.Vc(c);k.Vc(c-1);b.points=k;b.Od()}else h=b.l(c+2),Math.abs(e.x-f.x)<this.mh&&Math.abs(e.y-f.y)<this.mh&&(Ii(this,d,e,f,h,!0)||Ii(this,d,e,f,h,!1))&&(k=b.points.copy(),Ii(this,d,e,f,h,!0)?(k.Hg(c-1,new z(d.x,(d.y+h.y)/2)),k.Hg(c+2,new z(h.x,(d.y+h.y)/2))):(k.Hg(c-1,new z((d.x+h.x)/2,d.y)),k.Hg(c+2,new z((d.x+h.x)/2,h.y))),k.Vc(c+1),k.Vc(c),b.points=k,b.Od())}}else h=v.L(),Oa(d.x,d.y,f.x,f.y,e.x,e.y,h)&&h.rf(e)<this.mh*this.mh&&(k=b.points.copy(),k.Vc(c),b.points=k,b.Od()),
v.v(h)}a.qc();this.Bf=this.name;a.Ba("LinkReshaped",this.Rs,this.Av)}this.stopTool()};function Ii(a,b,c,d,e,f){return f?Math.abs(b.y-c.y)<a.mh&&Math.abs(c.y-d.y)<a.mh&&Math.abs(d.y-e.y)<a.mh:Math.abs(b.x-c.x)<a.mh&&Math.abs(c.x-d.x)<a.mh&&Math.abs(d.x-e.x)<a.mh}v.defineProperty(Ci,{mh:"resegmentingDistance"},function(){return this.wC},function(a){v.j(a,"number",Ci,"resegmentingDistance");this.wC=a});
Ci.prototype.reshape=function(a){var b=this.Rs;b.Bl();var c=this.handle.ve,d=this.TD(this.handle);if(b.jc)if(c===b.ht+1)c=b.ht+1,d===Fi?(b.V(c,b.l(c-1).x,a.y),b.V(c+1,b.l(c+2).x,a.y)):d===Ei&&(b.V(c,a.x,b.l(c-1).y),b.V(c+1,a.x,b.l(c+2).y));else if(c===b.Nw-1)c=b.Nw-1,d===Fi?(b.V(c-1,b.l(c-2).x,a.y),b.V(c,b.l(c+1).x,a.y)):d===Ei&&(b.V(c-1,a.x,b.l(c-2).y),b.V(c,a.x,b.l(c+1).y));else{var d=c,e=b.l(d),f=b.l(d-1),h=b.l(d+1);K(f.x,e.x)&&K(e.y,h.y)?(K(f.x,b.l(d-2).x)&&!K(f.y,b.l(d-2).y)?(b.w(d,a.x,f.y),
c++,d++):b.V(d-1,a.x,f.y),K(h.y,b.l(d+2).y)&&!K(h.x,b.l(d+2).x)?b.w(d+1,h.x,a.y):b.V(d+1,h.x,a.y)):K(f.y,e.y)&&K(e.x,h.x)?(K(f.y,b.l(d-2).y)&&!K(f.x,b.l(d-2).x)?(b.w(d,f.x,a.y),c++,d++):b.V(d-1,f.x,a.y),K(h.x,b.l(d+2).x)&&!K(h.y,b.l(d+2).y)?b.w(d+1,a.x,h.y):b.V(d+1,a.x,h.y)):K(f.x,e.x)&&K(e.x,h.x)?(K(f.x,b.l(d-2).x)&&!K(f.y,b.l(d-2).y)?(b.w(d,a.x,f.y),c++,d++):b.V(d-1,a.x,f.y),K(h.x,b.l(d+2).x)&&!K(h.y,b.l(d+2).y)?b.w(d+1,a.x,h.y):b.V(d+1,a.x,h.y)):K(f.y,e.y)&&K(e.y,h.y)&&(K(f.y,b.l(d-2).y)&&!K(f.x,
b.l(d-2).x)?(b.w(d,f.x,a.y),c++,d++):b.V(d-1,f.x,a.y),K(h.y,b.l(d+2).y)&&!K(h.x,b.l(d+2).x)?b.w(d+1,h.x,a.y):b.V(d+1,h.x,a.y));b.V(c,a.x,a.y)}else b.V(c,a.x,a.y),1===c&&b.computeSpot(!0).se()&&(e=b.T,f=b.bd,null===e||e.isVisible()||(e=e.findVisibleNode(),e!==b.T&&(f=e.jl(""))),d=f.Xa(Ab,v.L()),e=b.getLinkPointFromPoint(e,f,d,a,!0,v.L()),b.V(0,e.x,e.y),v.v(d),v.v(e)),c===b.oa-2&&b.computeSpot(!1).se()&&(c=b.Z,e=b.Fd,null===c||c.isVisible()||(c=c.findVisibleNode(),c!==b.Z&&(e=c.jl(""))),d=e.Xa(Ab,v.L()),
e=b.getLinkPointFromPoint(c,e,d,a,!1,v.L()),b.V(b.oa-1,e.x,e.y),v.v(d),v.v(e));b.Ni()};Ci.prototype.computeReshape=function(a){var b=this.Rs,c=this.handle.ve;switch(this.TD(this.handle)){case Gi:return a;case Fi:return b=b.l(c),new z(b.x,a.y);case Ei:return b=b.l(c),new z(a.x,b.y);default:case Di:return b.l(c)}};v.u(Ci,{sK:"originalPoint"},function(){return this.pC});v.u(Ci,{tK:"originalPoints"},function(){return this.Av});
function Ji(){0<arguments.length&&v.dd(Ji);Wf.call(this);this.name="Resizing";this.Bj=(new ia(1,1)).freeze();this.zj=(new ia(9999,9999)).freeze();this.hj=(new ia(NaN,NaN)).freeze();this.Rq=!1;this.Db=null;var a=new X;a.Tj=Ab;a.Ab="Rectangle";a.xa=od;a.fill="lightblue";a.stroke="dodgerblue";a.lb=1;a.cursor="pointer";this.Ak=a;this.Sb=null;this.zv=new ia;this.Ey=new z;this.gy=new ia(0,0);this.ey=new ia(Infinity,Infinity);this.by=new ia(1,1);this.iC=!0}v.Ja(Ji,Wf);v.ea("ResizingTool",Ji);
Ji.prototype.updateAdornments=function(a){if(!(null===a||a instanceof W)){if(a.hb&&!this.g.ab){var b=a.KE;if(null!==b&&a.canResize()&&a.U.G()&&a.isVisible()&&b.U.G()&&b.Xi()){var c=a.Vo(this.name);if(null===c||c.Zb!==b)c=this.makeAdornment(b);if(null!==c){var d=b.nl();c.angle=d;var e=b.Xa(c.df,v.L()),f=b.Ri();c.location=e;v.v(e);e=c.placeholder;if(null!==e){var b=b.Ha,h=v.El();h.m(b.width*f,b.height*f);e.xa=h;v.Zj(h)}Ki(this,c,d);a.Wk(this.name,c);return}}}a.$i(this.name)}};
Ji.prototype.makeAdornment=function(a){var b=null,b=a.Y.JE;if(null===b){b=new Tg;b.type=Li;b.df=Ab;var c=new Mi;c.Rf=!0;b.add(c);b.add(this.makeHandle(a,tb));b.add(this.makeHandle(a,vb));b.add(this.makeHandle(a,Fb));b.add(this.makeHandle(a,Db));b.add(this.makeHandle(a,pc));b.add(this.makeHandle(a,rc));b.add(this.makeHandle(a,sc));b.add(this.makeHandle(a,qc))}else if(Vg(b),b=b.copy(),null===b)return null;b.gc=this.name;b.Zb=a;return b};
Ji.prototype.makeHandle=function(a,b){var c=this.nt;if(null===c)return null;c=c.copy();c.alignment=b;return c};
function Ki(a,b,c){if(null!==b)if(!b.alignment.Uc()&&""!==b.cursor)a:{a=b.alignment;a.se()&&(a=Ab);if(0>=a.x)c=0>=a.y?c+225:1<=a.y?c+135:c+180;else if(1<=a.x)0>=a.y?c+=315:1<=a.y&&(c+=45);else if(0>=a.y)c+=270;else if(1<=a.y)c+=90;else break a;0>c?c+=360:360<=c&&(c-=360);b.cursor=22.5>c?"e-resize":67.5>c?"se-resize":112.5>c?"s-resize":157.5>c?"sw-resize":202.5>c?"w-resize":247.5>c?"nw-resize":292.5>c?"n-resize":337.5>c?"ne-resize":"e-resize"}else if(b instanceof D)for(b=b.elements;b.next();)Ki(a,
b.value,c)}v.defineProperty(Ji,{nt:"handleArchetype"},function(){return this.Ak},function(a){null!==a&&v.A(a,G,Ji,"handleArchetype");this.Ak=a});v.u(Ji,{handle:"handle"},function(){return this.Sb});v.defineProperty(Ji,{Zb:"adornedObject"},function(){return this.Db},function(a){null!==a&&v.A(a,G,Ji,"adornedObject");this.Db=a});Ji.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;return null!==a&&!a.ab&&a.Us&&a.N.left?null!==this.findToolHandleAt(a.yc.ca,this.name)?!0:!1:!1};
Ji.prototype.doActivate=function(){var a=this.g;null!==a&&(this.Sb=this.findToolHandleAt(a.yc.ca,this.name),null!==this.Sb&&(this.Db=this.Sb.Y.Zb,this.Ey.set(this.Db.Y.location),this.zv.set(this.Db.xa),this.by=this.computeCellSize(),this.gy=this.computeMinSize(),this.ey=this.computeMaxSize(),a.Me=!0,this.iC=a.Na.isEnabled,a.Na.isEnabled=!1,this.Qb(this.name),this.qa=!0))};Ji.prototype.doDeactivate=function(){var a=this.g;null!==a&&(this.fk(),this.Db=this.Sb=null,this.qa=a.Me=!1,a.Na.isEnabled=this.iC)};
Ji.prototype.doCancel=function(){this.Db.xa=this.zv;this.Db.Y.location=this.Ey;this.stopTool()};Ji.prototype.doMouseMove=function(){var a=this.g;if(this.qa&&null!==a){var b=this.gy,c=this.ey,d=this.by,e=this.Db.RD(a.N.ca,v.L()),f=Ni;this.Db instanceof X&&(f=Oi(this.Db));b=this.computeResize(e,this.Sb.alignment,b,c,d,!(f===Pi||f===Qi||a.N.shift));this.resize(b);a.Uf();v.v(e)}};
Ji.prototype.doMouseUp=function(){var a=this.g;if(this.qa&&null!==a){var b=this.gy,c=this.ey,d=this.by,e=this.Db.RD(a.N.ca,v.L()),f=Ni;this.Db instanceof X&&(f=Oi(this.Db));b=this.computeResize(e,this.Sb.alignment,b,c,d,!(f===Pi||f===Qi||a.N.shift));this.resize(b);v.v(e);a.qc();this.Bf=this.name;a.Ba("PartResized",this.Db,this.zv)}this.stopTool()};
Ji.prototype.resize=function(a){if(null!==this.g){var b=this.Zb,c=b.Y,d=b.nl(),e=b.Ri(),f=Math.PI*d/180,h=Math.cos(f),f=Math.sin(f),k=0<d&&180>d?1:0,l=90<d&&270>d?1:0,d=180<d&&360>d?1:0,m=b.Ha.width,n=b.Ha.height;b.xa=a.size;var p=c.position.copy();c.sf();m=b.Ha.width-m;n=b.Ha.height-n;if(0!==m||0!==n)0!==m&&(p.x+=e*((a.x+m*l)*h-(a.y+n*k)*f)),0!==n&&(p.y+=e*((a.x+m*d)*f+(a.y+n*l)*h)),c.move(p),c instanceof S&&c.bf()}};
Ji.prototype.computeResize=function(a,b,c,d,e,f){b.se()&&(b=Ab);var h=this.Zb.Ha,k=h.x,l=h.y,m=h.x+h.width,n=h.y+h.height,p=1;if(!f){var p=h.width,q=h.height;0>=p&&(p=1);0>=q&&(q=1);p=q/p}q=v.L();Pa(a.x,a.y,k,l,e.width,e.height,q);a=h.copy();0>=b.x?0>=b.y?(a.x=Math.max(q.x,m-d.width),a.x=Math.min(a.x,m-c.width),a.width=Math.max(m-a.x,c.width),a.y=Math.max(q.y,n-d.height),a.y=Math.min(a.y,n-c.height),a.height=Math.max(n-a.y,c.height),f||(b=a.height/a.width,p<b?(a.height=p*a.width,a.y=n-a.height):(a.width=
a.height/p,a.x=m-a.width))):1<=b.y?(a.x=Math.max(q.x,m-d.width),a.x=Math.min(a.x,m-c.width),a.width=Math.max(m-a.x,c.width),a.height=Math.max(Math.min(q.y-l,d.height),c.height),f||(b=a.height/a.width,p<b?a.height=p*a.width:(a.width=a.height/p,a.x=m-a.width))):(a.x=Math.max(q.x,m-d.width),a.x=Math.min(a.x,m-c.width),a.width=m-a.x,f||(a.height=p*a.width,a.y=l+.5*(n-l-a.height))):1<=b.x?0>=b.y?(a.width=Math.max(Math.min(q.x-k,d.width),c.width),a.y=Math.max(q.y,n-d.height),a.y=Math.min(a.y,n-c.height),
a.height=Math.max(n-a.y,c.height),f||(b=a.height/a.width,p<b?(a.height=p*a.width,a.y=n-a.height):a.width=a.height/p)):1<=b.y?(a.width=Math.max(Math.min(q.x-k,d.width),c.width),a.height=Math.max(Math.min(q.y-l,d.height),c.height),f||(b=a.height/a.width,p<b?a.height=p*a.width:a.width=a.height/p)):(a.width=Math.max(Math.min(q.x-k,d.width),c.width),f||(a.height=p*a.width,a.y=l+.5*(n-l-a.height))):0>=b.y?(a.y=Math.max(q.y,n-d.height),a.y=Math.min(a.y,n-c.height),a.height=n-a.y,f||(a.width=a.height/p,a.x=
k+.5*(m-k-a.width))):1<=b.y&&(a.height=Math.max(Math.min(q.y-l,d.height),c.height),f||(a.width=a.height/p,a.x=k+.5*(m-k-a.width)));v.v(q);return a};Ji.prototype.computeMinSize=function(){var a=this.Zb.ih.copy(),b=this.ih;!isNaN(b.width)&&b.width>a.width&&(a.width=b.width);!isNaN(b.height)&&b.height>a.height&&(a.height=b.height);return a};
Ji.prototype.computeMaxSize=function(){var a=this.Zb.xf.copy(),b=this.xf;!isNaN(b.width)&&b.width<a.width&&(a.width=b.width);!isNaN(b.height)&&b.height<a.height&&(a.height=b.height);return a};
Ji.prototype.computeCellSize=function(){var a=new ia(NaN,NaN),b=this.Zb.Y;if(null!==b){var c=b.lI;!isNaN(c.width)&&0<c.width&&(a.width=c.width);!isNaN(c.height)&&0<c.height&&(a.height=c.height)}c=this.No;isNaN(a.width)&&!isNaN(c.width)&&0<c.width&&(a.width=c.width);isNaN(a.height)&&!isNaN(c.height)&&0<c.height&&(a.height=c.height);b=this.g;(isNaN(a.width)||isNaN(a.height))&&b&&(c=b.Za.$d,null!==c&&c.Iw&&(c=c.VD,isNaN(a.width)&&!isNaN(c.width)&&0<c.width&&(a.width=c.width),isNaN(a.height)&&!isNaN(c.height)&&
0<c.height&&(a.height=c.height)),b=b.mt,null!==b&&b.visible&&this.Iw&&(c=b.Ew,isNaN(a.width)&&!isNaN(c.width)&&0<c.width&&(a.width=c.width),isNaN(a.height)&&!isNaN(c.height)&&0<c.height&&(a.height=c.height)));if(isNaN(a.width)||0===a.width||Infinity===a.width)a.width=1;if(isNaN(a.height)||0===a.height||Infinity===a.height)a.height=1;return a};
v.defineProperty(Ji,{ih:"minSize"},function(){return this.Bj},function(a){v.A(a,ia,Ji,"minSize");if(!this.Bj.K(a)){var b=a.width;isNaN(b)&&(b=0);a=a.height;isNaN(a)&&(a=0);this.Bj.m(b,a)}});v.defineProperty(Ji,{xf:"maxSize"},function(){return this.zj},function(a){v.A(a,ia,Ji,"maxSize");if(!this.zj.K(a)){var b=a.width;isNaN(b)&&(b=Infinity);a=a.height;isNaN(a)&&(a=Infinity);this.zj.m(b,a)}});
v.defineProperty(Ji,{No:"cellSize"},function(){return this.hj},function(a){v.A(a,ia,Ji,"cellSize");this.hj.K(a)||this.hj.assign(a)});v.defineProperty(Ji,{Iw:"isGridSnapEnabled"},function(){return this.Rq},function(a){v.j(a,"boolean",Ji,"isGridSnapEnabled");this.Rq=a});v.u(Ji,{qK:"originalDesiredSize"},function(){return this.zv});v.u(Ji,{rK:"originalLocation"},function(){return this.Ey});
function Ri(){0<arguments.length&&v.dd(Ri);Wf.call(this);this.name="Rotating";this.CC=45;this.BC=2;this.Db=null;var a=new X;a.Ab="Ellipse";a.xa=pd;a.fill="lightblue";a.stroke="dodgerblue";a.lb=1;a.cursor="pointer";this.Ak=a;this.Sb=null;this.yv=0;this.yC=new z}v.Ja(Ri,Wf);v.ea("RotatingTool",Ri);
Ri.prototype.updateAdornments=function(a){if(!(null===a||a instanceof W)){if(a.hb&&!this.g.ab){var b=a.NE;if(null!==b&&a.canRotate()&&a.U.G()&&a.isVisible()&&b.U.G()&&b.Xi()){var c=a.Vo(this.name);if(null===c||c.Zb!==b)c=this.makeAdornment(b);if(null!==c){c.angle=b.nl();var d=null,e=null;b===a||b===a.cc?(d=a.cc,e=a.df):(d=b,e=Ab);for(var f=d.Ha,e=v.Mb(f.width*e.x+e.offsetX,f.height*e.y+e.offsetY);null!==d&&d!==b;)d.transform.ub(e),d=d.S;var d=e.y,f=Math.max(e.x-b.Ha.width,0),h=v.L();c.location=b.Xa(new L(1,
0,50+f,d),h);v.v(h);v.v(e);a.Wk(this.name,c);return}}}a.$i(this.name)}};Ri.prototype.makeAdornment=function(a){var b=null,b=a.Y.oI;if(null===b){b=new Tg;b.type=Si;b.df=Ab;var c=this.nt;null!==c&&b.add(c.copy())}else if(Vg(b),b=b.copy(),null===b)return null;b.gc=this.name;b.Zb=a;return b};v.defineProperty(Ri,{nt:"handleArchetype"},function(){return this.Ak},function(a){null!==a&&v.A(a,G,Ri,"handleArchetype");this.Ak=a});v.u(Ri,{handle:"handle"},function(){return this.Sb});
v.defineProperty(Ri,{Zb:"adornedObject"},function(){return this.Db},function(a){null!==a&&v.A(a,G,Ri,"adornedObject");this.Db=a});Ri.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;return null!==a&&!a.ab&&a.jw&&a.N.left?null!==this.findToolHandleAt(a.yc.ca,this.name)?!0:!1:!1};
Ri.prototype.doActivate=function(){var a=this.g;if(null!==a&&(this.Sb=this.findToolHandleAt(a.yc.ca,this.name),null!==this.Sb)){this.Db=this.Sb.Y.Zb;var b=this.Db.Y,c=b.cc;this.yC=this.Db===b||this.Db===c?c.Xa(b.df):this.Db.Xa(Ab);this.yv=this.Db.angle;a.Me=!0;a.mz=!0;this.Qb(this.name);this.qa=!0}};Ri.prototype.doDeactivate=function(){var a=this.g;null!==a&&(this.fk(),this.Db=this.Sb=null,this.qa=a.Me=!1)};Ri.prototype.doCancel=function(){var a=this.g;null!==a&&(a.mz=!1);this.rotate(this.yv);this.stopTool()};
Ri.prototype.doMouseMove=function(){var a=this.g;this.qa&&null!==a&&(a=this.computeRotate(a.N.ca),this.rotate(a))};Ri.prototype.doMouseUp=function(){var a=this.g;if(this.qa&&null!==a){a.mz=!1;var b=this.computeRotate(a.N.ca);this.rotate(b);a.qc();this.Bf=this.name;a.Ba("PartRotated",this.Db,this.yv)}this.stopTool()};Ri.prototype.rotate=function(a){null!==this.Db&&(this.Db.angle=a)};
Ri.prototype.computeRotate=function(a){a=this.yC.xc(a);var b=this.Db.S;null!==b&&(a-=b.nl(),360<=a?a-=360:0>a&&(a+=360));var b=Math.min(Math.abs(this.CI),180),c=Math.min(Math.abs(this.BI),b/2);!this.g.N.shift&&0<b&&0<c&&(a%b<c?a=Math.floor(a/b)*b:a%b>b-c&&(a=(Math.floor(a/b)+1)*b));360<=a?a-=360:0>a&&(a+=360);return a};v.defineProperty(Ri,{CI:"snapAngleMultiple"},function(){return this.CC},function(a){v.j(a,"number",Ri,"snapAngleMultiple");this.CC=a});
v.defineProperty(Ri,{BI:"snapAngleEpsilon"},function(){return this.BC},function(a){v.j(a,"number",Ri,"snapAngleEpsilon");this.BC=a});v.u(Ri,{pK:"originalAngle"},function(){return this.yv});function Ti(){0<arguments.length&&v.dd(Ti);Wf.call(this);this.name="ClickSelecting"}v.Ja(Ti,Wf);v.ea("ClickSelectingTool",Ti);Ti.prototype.canStart=function(){return!this.isEnabled||null===this.g||this.isBeyondDragSize()?!1:!0};
Ti.prototype.doMouseUp=function(){this.qa&&(this.standardMouseSelect(),!this.standardMouseClick()&&this.g.N.Vi&&this.g.Za.doToolTip());this.stopTool()};function Ui(){0<arguments.length&&v.dd(Ui);Wf.call(this);this.name="Action";this.mn=null}v.Ja(Ui,Wf);v.ea("ActionTool",Ui);
Ui.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;if(null===a)return!1;var b=a.N,c=a.qe(b.ca,function(a){for(;null!==a.S&&!a.Fw;)a=a.S;return a});if(null!==c){if(!c.Fw)return!1;this.mn=c;a.zn=a.qe(b.ca,null,null);return!0}return!1};Ui.prototype.doMouseDown=function(){if(this.qa){var a=this.g.N,b=this.mn;null!==b&&(a.ye=b,null!==b.aD&&b.aD(a,b))}else this.canStart()&&this.doActivate()};
Ui.prototype.doMouseMove=function(){if(this.qa){var a=this.g.N,b=this.mn;null!==b&&(a.ye=b,null!==b.bD&&b.bD(a,b))}};Ui.prototype.doMouseUp=function(){if(this.qa){var a=this.g,b=a.N,c=this.mn;if(null===c)return;b.ye=c;null!==c.cD&&c.cD(b,c);this.isBeyondDragSize()||ch(c,b,a)}this.stopTool()};Ui.prototype.doCancel=function(){var a=this.g;if(null!==a){var a=a.N,b=this.mn;if(null===b)return;a.ye=b;null!==b.$C&&b.$C(a,b)}this.stopTool()};Ui.prototype.doStop=function(){this.mn=null};
function Vi(){0<arguments.length&&v.dd(Vi);Wf.call(this);this.name="ClickCreating";this.Ll=null;this.RB=!0;this.EB=new z(0,0)}v.Ja(Vi,Wf);v.ea("ClickCreatingTool",Vi);
Vi.prototype.canStart=function(){if(!this.isEnabled||null===this.$y)return!1;var a=this.g;if(null===a||a.ab||a.vf||!a.Ko||!a.N.left||this.isBeyondDragSize())return!1;if(this.oH){if(1===a.N.Ve&&(this.EB=a.N.hf.copy()),2!==a.N.Ve||this.isBeyondDragSize(this.EB))return!1}else if(1!==a.N.Ve)return!1;return a.Wa!==this&&null!==a.gt(a.N.ca,!0)?!1:!0};Vi.prototype.doMouseUp=function(){var a=this.g;this.qa&&null!==a&&this.insertPart(a.N.ca);this.stopTool()};
Vi.prototype.insertPart=function(a){var b=this.g;if(null===b)return null;var c=this.$y;if(null===c)return null;this.Qb(this.name);var d=null;c instanceof R?c.Pd()&&(Vg(c),d=c.copy(),null!==d&&b.add(d)):null!==c&&(c=b.ba.copyNodeData(c),v.Ta(c)&&(b.ba.Xk(c),d=b.dh(c)));null!==d&&(d.location=a,b.nf&&b.select(d));b.qc();this.Bf=this.name;b.Ba("PartCreated",d);this.fk();return d};
v.defineProperty(Vi,{$y:"archetypeNodeData"},function(){return this.Ll},function(a){null!==a&&v.A(a,Object,Vi,"archetypeNodeData");this.Ll=a});v.defineProperty(Vi,{oH:"isDoubleClick"},function(){return this.RB},function(a){v.j(a,"boolean",Vi,"isDoubleClick");this.RB=a});function Wi(a,b,c){this.text=a;this.rD=b;this.visible=c}function Xi(){0<arguments.length&&v.dd(Xi);Wf.call(this);this.name="ContextMenu";this.jB=this.Px=this.gB=null;this.gC=new z;this.Rx=this.Hn=null;Yi(this)}v.Ja(Xi,Wf);
v.ea("ContextMenuTool",Xi);v.zD=!1;v.Ws=null;v.Xs=null;
function Yi(a){a.Hn=new Tg;a.RC=function(){a.stopTool()};if(!1===v.zD){var b=v.createElement("div"),c=v.createElement("div");b.style.cssText="top: 0px;z-index:300;position: fixed;display: none;text-align: center;left: 25%;width: 50%;background-color: #F5F5F5;padding: 16px;border: 16px solid #444;border-radius: 10px;margin-top: 10px";c.style.cssText="z-index:299;position: fixed;display: none;top: 0;left: 0;width: 100%;height: 100%;background-color: black;-moz-opacity: 0.8;opacity:.80;filter: alpha(opacity=80);";var d=
v.createElement("style");window.document.getElementsByTagName("head")[0].appendChild(d);d.sheet.insertRule(".defaultCXul { list-style: none; }",0);d.sheet.insertRule(".defaultCXli {font:700 1.5em Helvetica, Arial, sans-serif;position: relative;min-width: 60px; }",0);d.sheet.insertRule(".defaultCXa {color: #444;display: inline-block;padding: 4px;text-decoration: none;margin: 2px;border: 1px solid gray;border-radius: 10px; }",0);b.addEventListener("contextmenu",function(a){a.preventDefault();return!1},
!1);b.addEventListener("selectstart",function(a){a.preventDefault();return!1},!1);c.addEventListener("contextmenu",function(a){a.preventDefault();return!1},!1);window.document.body&&(window.document.body.appendChild(b),window.document.body.appendChild(c));v.Xs=b;v.Ws=c;v.zD=!0}}Xi.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;return null===a||this.isBeyondDragSize()||!a.N.right?!1:null!==this.Hn&&a.N.Vi||null!==this.findObjectWithContextMenu()?!0:!1};
Xi.prototype.doStart=function(){var a=this.g;null!==a&&this.gC.set(a.yc.ca)};Xi.prototype.doStop=function(){this.hideDefaultContextMenu();this.hideContextMenu();this.lz=null};
Xi.prototype.findObjectWithContextMenu=function(a){void 0===a&&(a=null);var b=this.g;if(null===b)return null;var c=b.N,d=null;a instanceof E||(d=a instanceof G?a:b.qe(c.ca,null,function(a){return!a.layer.Gc}));if(null!==d){for(a=d;null!==a;){if(null!==a.contextMenu)return a;a=a.S}if(null!==this.Hn&&b.N.Vi)return d.Y}else if(null!==b.contextMenu)return b;return null};Xi.prototype.doActivate=function(){};
Xi.prototype.doMouseDown=function(){Wf.prototype.doMouseDown.call(this);null!==this.g&&this.g.Za.Ne.contains(this)&&Zi(this)};Xi.prototype.doMouseUp=function(){Zi(this)};function Zi(a){var b=a.g;if(null!==b)if(!a.qa)a.canStart()&&(ah(a,!0),a.qa||a.stopTool());else if(null!==a.pf){var c=b.qe(b.N.ca,null,null);null!==c&&c.tl(a.pf)&&a.standardMouseClick(null,null);a.stopTool();a.canStart()&&(b.Wa=a,a.doMouseUp())}}
function ah(a,b,c){void 0===c&&(c=null);b&&a.standardMouseSelect();a.standardMouseClick()||(a.qa=!0,null===c&&(c=a.findObjectWithContextMenu()),null!==c?(b=c.contextMenu,null!==b?(a.lz=c instanceof G?c:null,a.showContextMenu(b,a.lz)):a.showDefaultContextMenu()):a.showDefaultContextMenu())}Xi.prototype.doMouseMove=function(){this.qa&&this.g.Za.doMouseMove()};
Xi.prototype.showContextMenu=function(a,b){v.A(a,Tg,Xi,"showContextMenu:contextmenu");null!==b&&v.A(b,G,Xi,"showContextMenu:obj");var c=this.g;if(null!==c){a!==this.pf&&this.hideContextMenu();a.Tf="Tool";a.zl=!1;a.scale=1/c.scale;a.gc=this.name;null!==a.placeholder&&(a.placeholder.scale=c.scale);c.add(a);if(null!==b){var c=null,d=b.Xo();null!==d&&(c=d.data);a.Zb=b;a.data=c}else a.data=c.ba;a.sf();this.positionContextMenu(a,b);this.pf=a}};
Xi.prototype.positionContextMenu=function(a){if(null===a.placeholder){var b=this.g;if(null!==b){var c=b.N.ca.copy(),d=a.Ca,e=b.rb;b.N.Vi&&(c.x-=d.width);c.x+d.width>e.right&&(c.x-=d.width+5);c.x<e.x&&(c.x=e.x);c.y+d.height>e.bottom&&(c.y-=d.height+5);c.y<e.y&&(c.y=e.y);a.position=c}}};Xi.prototype.hideContextMenu=function(){var a=this.g;null!==a&&null!==this.pf&&(a.remove(this.pf),null!==this.Px&&this.Px.$i(this.pf.gc),this.pf.data=null,this.pf=this.pf.Zb=null,this.standardMouseOver())};
function $i(a){if(null===a.g)return null;a=new I(Wi);a.add(new Wi("Copy",function(a){a.nb.copySelection()},function(a){return a.nb.canCopySelection()}));a.add(new Wi("Cut",function(a){a.nb.cutSelection()},function(a){return a.nb.canCutSelection()}));a.add(new Wi("Delete",function(a){a.nb.deleteSelection()},function(a){return a.nb.canDeleteSelection()}));a.add(new Wi("Paste",function(a){a.nb.pasteSelection(a.N.ca)},function(a){return a.nb.canPasteSelection()}));a.add(new Wi("Select All",function(a){a.nb.selectAll()},
function(a){return a.nb.canSelectAll()}));a.add(new Wi("Undo",function(a){a.nb.undo()},function(a){return a.nb.canUndo()}));a.add(new Wi("Redo",function(a){a.nb.redo()},function(a){return a.nb.canRedo()}));a.add(new Wi("Scroll To Part",function(a){a.nb.scrollToPart()},function(a){return a.nb.canScrollToPart()}));a.add(new Wi("Zoom To Fit",function(a){a.nb.zoomToFit()},function(a){return a.nb.canZoomToFit()}));a.add(new Wi("Reset Zoom",function(a){a.nb.resetZoom()},function(a){return a.nb.canResetZoom()}));
a.add(new Wi("Group Selection",function(a){a.nb.groupSelection()},function(a){return a.nb.canGroupSelection()}));a.add(new Wi("Ungroup Selection",function(a){a.nb.ungroupSelection()},function(a){return a.nb.canUngroupSelection()}));a.add(new Wi("Edit Text",function(a){a.nb.editTextBlock()},function(a){return a.nb.canEditTextBlock()}));return a}
Xi.prototype.showDefaultContextMenu=function(){var a=this.g;if(null!==a){null===this.Rx&&(this.Rx=$i(this));this.Hn!==this.pf&&this.hideContextMenu();v.Xs.innerHTML="";v.Ws.addEventListener("click",this.RC,!1);var b=this,c=v.createElement("ul");c.className="defaultCXul";v.Xs.appendChild(c);c.innerHTML="";for(var d=this.Rx.i;d.next();){var e=d.value,f=e.text,h=e.visible;if("function"===typeof e.rD&&("function"!==typeof h||h(a))){h=v.createElement("li");h.className="defaultCXli";var k=v.createElement("a");
k.className="defaultCXa";k.href="#";k.zF=e.rD;k.addEventListener("click",function(c){this.zF(a);b.stopTool();c.preventDefault();return!1},!1);k.textContent=f;h.appendChild(k);c.appendChild(h)}}v.Xs.style.display="block";v.Ws.style.display="block";this.pf=this.Hn}};Xi.prototype.hideDefaultContextMenu=function(){null!==this.pf&&this.pf===this.Hn&&(v.Xs.style.display="none",v.Ws.style.display="none",v.Ws.removeEventListener("click",this.RC,!1),this.pf=null)};
v.defineProperty(Xi,{pf:"currentContextMenu"},function(){return this.gB},function(a){null!==a&&v.A(a,Tg,Xi,"currentContextMenu");this.gB=a;this.Px=null!==a?a.Mf:null});v.defineProperty(Xi,{lz:"currentObject"},function(){return this.jB},function(a){null!==a&&v.A(a,G,Xi,"currentObject");this.jB=a});v.u(Xi,{kK:"mouseDownPoint"},function(){return this.gC});
function aj(){0<arguments.length&&v.dd(aj);Wf.call(this);this.name="DragSelecting";this.In=175;this.WB=!1;var a=new R;a.Tf="Tool";a.zl=!1;var b=new X;b.name="SHAPE";b.Ab="Rectangle";b.fill=null;b.stroke="magenta";a.add(b);this.Nl=a}v.Ja(aj,Wf);v.ea("DragSelectingTool",aj);
aj.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;if(null===a||!a.nf)return!1;var b=a.N;return!b.left||a.Wa!==this&&(!this.isBeyondDragSize()||b.timestamp-a.yc.timestamp<this.AD||null!==a.gt(b.ca,!0))?!1:!0};aj.prototype.doActivate=function(){var a=this.g;null!==a&&(this.qa=!0,a.Me=!0,a.tb=!0,a.add(this.Zg),this.doMouseMove())};aj.prototype.doDeactivate=function(){var a=this.g;null!==a&&(a.remove(this.Zg),a.tb=!1,this.qa=a.Me=!1)};
aj.prototype.doMouseMove=function(){if(null!==this.g&&this.qa&&null!==this.Zg){var a=this.computeBoxBounds(),b=this.Zg.be("SHAPE");null===b&&(b=this.Zg.Xe());b.xa=a.size;this.Zg.position=a.position}};aj.prototype.doMouseUp=function(){if(this.qa){var a=this.g;a.remove(this.Zg);try{a.$b="wait",this.selectInRect(this.computeBoxBounds())}finally{a.$b=""}}this.stopTool()};aj.prototype.computeBoxBounds=function(){var a=this.g;return null===a?new B(0,0,0,0):new B(a.yc.ca,a.N.ca)};
aj.prototype.selectInRect=function(a){var b=this.g;if(null!==b){var c=b.N;b.Ba("ChangingSelection");a=b.Yj(a,null,function(a){return a instanceof R?a.canSelect():!1},this.vH);if(v.ak?c.Ft:c.control)if(c.shift)for(a=a.i;a.next();)c=a.value,c.hb&&(c.hb=!1);else for(a=a.i;a.next();)c=a.value,c.hb=!c.hb;else{if(!c.shift){for(var c=new I(R),d=b.selection.i;d.next();){var e=d.value;a.contains(e)||c.add(e)}for(c=c.i;c.next();)c.value.hb=!1}for(a=a.i;a.next();)c=a.value,c.hb||(c.hb=!0)}b.Ba("ChangedSelection")}};
v.defineProperty(aj,{AD:"delay"},function(){return this.In},function(a){v.j(a,"number",aj,"delay");this.In=a});v.defineProperty(aj,{vH:"isPartialInclusion"},function(){return this.WB},function(a){v.j(a,"boolean",aj,"isPartialInclusion");this.WB=a});v.defineProperty(aj,{Zg:"box"},function(){return this.Nl},function(a){null!==a&&v.A(a,R,aj,"box");this.Nl=a});
function bj(){0<arguments.length&&v.dd(bj);Wf.call(this);this.name="Panning";this.Fy=new z;this.gj=!1;var a=this;this.FC=function(){window.document.removeEventListener("scroll",a.FC,!1);a.stopTool()}}v.Ja(bj,Wf);v.ea("PanningTool",bj);bj.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;return null===a||!a.ne&&!a.oe||!a.N.left||a.Wa!==this&&!this.isBeyondDragSize()?!1:!0};
bj.prototype.doActivate=function(){var a=this.g;null!==a&&(this.gj?(a.N.bubbles=!0,window.document.addEventListener("scroll",this.FC,!1)):(a.$b="move",a.Me=!0,this.Fy=a.position.copy()),this.qa=!0)};bj.prototype.doDeactivate=function(){var a=this.g;null!==a&&(a.$b="",this.qa=a.Me=!1)};bj.prototype.doCancel=function(){var a=this.g;null!==a&&(a.position=this.Fy,a.Me=!1);this.stopTool()};bj.prototype.doMouseMove=function(){this.move()};bj.prototype.doMouseUp=function(){this.move();this.stopTool()};
bj.prototype.move=function(){var a=this.g;if(this.qa&&a)if(this.gj)a.N.bubbles=!0;else{var b=a.position,c=a.yc.ca,d=a.N.ca,e=b.x+c.x-d.x,c=b.y+c.y-d.y;a.ne||(e=b.x);a.oe||(c=b.y);a.position=new z(e,c)}};v.defineProperty(bj,{bubbles:"bubbles"},function(){return this.gj},function(a){v.j(a,"boolean",bj,"bubbles");this.gj=a});v.u(bj,{uK:"originalPosition"},function(){return this.Fy});
function cj(){0<arguments.length&&v.dd(cj);Wf.call(this);this.name="TextEditing";this.mB=this.Py=this.JC=null;this.yo=dj;this.Tk=null;this.eb=ej;this.Qk=null;this.dC=1;this.zC=!0;var a=v.createElement("textarea");a.QB=!0;this.tB=a;a.addEventListener("input",function(){var a=this.textEditingTool;if(null!==a&&void 0!==a&&null!==a.ze){var c=a.JC;c.text=this.value;var d=this.II;fj(c,a.ze.kv,Infinity);this.style.width=20+c.Ca.width*d+"px";this.style.height=10+c.Ca.height*d+"px";this.rows=c.DH}},!1);a.addEventListener("keydown",
function(a){var c=a.which,d=this.textEditingTool;null!==d&&void 0!==d&&null!==d.ze&&(13===c?(!1===d.ze.Kw&&a.preventDefault(),d.acceptText(gj)):9===c?(d.acceptText(hj),a.preventDefault()):27===c&&(d.doCancel(),null!==d.g&&d.g.focus()))},!1);a.addEventListener("focus",function(a){var c=this.textEditingTool;null!==c&&void 0!==c&&c.doFocus(a)},!1);a.addEventListener("blur",function(a){var c=this.textEditingTool;null!==c&&void 0!==c&&c.doBlur(a)},!1)}v.ea("TextEditingTool",cj);v.Ja(cj,Wf);var ij;
cj.LostFocus=ij=v.p(cj,"LostFocus",0);var jj;cj.MouseDown=jj=v.p(cj,"MouseDown",1);var hj;cj.Tab=hj=v.p(cj,"Tab",2);var gj;cj.Enter=gj=v.p(cj,"Enter",3);cj.SingleClick=v.p(cj,"SingleClick",0);var dj;cj.SingleClickSelected=dj=v.p(cj,"SingleClickSelected",1);var ej=v.p(cj,"StateNone",0),kj=v.p(cj,"StateActive",1),lj=v.p(cj,"StateEditing",2),mj=v.p(cj,"StateEditing2",3),nj=v.p(cj,"StateValidating",4),oj=v.p(cj,"StateValidated",5);
v.defineProperty(cj,{ze:"textBlock"},function(){return this.Py},function(a){null!==a&&v.A(a,$g,cj,"textBlock");this.Py=a});v.defineProperty(cj,{qf:"currentTextEditor"},function(){return this.mB},function(a){this.mB=a});v.defineProperty(cj,{DG:"defaultTextEditor"},function(){return this.tB},function(a){v.A(a,Element,cj,"defaultTextEditor");this.tB=a});v.defineProperty(cj,{tA:"starting"},function(){return this.yo},function(a){v.pb(a,cj,cj,"starting");this.yo=a});
cj.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;if(null===a||a.ab||!a.N.left||this.isBeyondDragSize())return!1;a=a.qe(a.N.ca,null,function(a){return a instanceof $g});if(null===a||!a.oz||!a.Y.canEdit())return!1;a=a.Y;return null===a||this.tA===dj&&!a.hb?!1:!0};cj.prototype.doStart=function(){this.qa||null===this.ze||this.doActivate()};
cj.prototype.doActivate=function(){if(!this.qa){var a=this.g;if(null!==a){var b=this.ze;null===b&&(b=a.qe(a.N.ca,function(a){return a instanceof $g?a:null}));if(null!==b&&(this.ze=b,null!==b.Y)){this.qa=!0;this.eb=kj;var c=this.DG,d=!1;null!==b.dF&&(c=b.dF);null===c||c.QB||(d=!0);this.JC=this.ze.copy();var e=new B(this.ze.Xa(tb),this.ze.Xa(Fb));a.tI(e);if(c.QB){var f=b.Xa(Ab),h=a.position,k=a.scale,l=b.Ri()*k;l<this.uE&&(l=this.uE);var e=b.Ha.width*l,m=b.Ha.height*l,n=(f.x-h.x)*k,f=(f.y-h.y)*k;c.value=
b.text;a.hl.style.font=b.font;c.style.font="inherit";c.style.fontSize=100*l+"%";c.style.lineHeight="normal";c.style.width=e+6+"px";c.style.height=m+2+"px";c.style.left=(n-e/2|0)-1+"px";c.style.top=(f-m/2|0)-1+"px";c.style.textAlign=b.textAlign;c.style.margin="0";c.style.padding="1px";c.style.border="0";c.style.outline="none";c.style["white-space"]="pre-wrap";c.style.overflow="hidden"}a.hl.appendChild(c);c.style.position="absolute";c.style.zIndex=100;c.className="start";c.textEditingTool=this;c.II=
l;if(d&&"function"===typeof c.onActivate)c.onActivate();this.qf=c;"function"===typeof c.focus&&c.focus();"function"===typeof c.select&&this.mA&&(c.select(),c.setSelectionRange(0,9999))}}}};cj.prototype.doCancel=function(){null!==this.Qk&&(this.qf.style.border=this.Qk,this.Qk=null);this.stopTool()};cj.prototype.doMouseUp=function(){!this.qa&&this.canStart()&&this.doActivate()};cj.prototype.doMouseDown=function(){this.qa&&this.acceptText(jj)};
cj.prototype.acceptText=function(a){switch(a){case jj:if(this.eb===oj||this.eb===mj)"function"===typeof this.qf.focus&&this.qf.focus();else if(this.eb===kj||this.eb===lj)this.eb=nj,zj(this);break;case ij:case gj:case hj:if(gj===a&&!0===this.Py.Kw)break;if(this.eb===kj||this.eb===lj)this.eb=nj,zj(this)}};
function zj(a){if(null!==a.ze&&null!==a.qf){var b=a.ze,c=a.ze.text,d=a.qf.value,e="",e="function"===typeof d?d():d;if(!a.isValidText(a.ze,c,e)){a.eb=lj;null!==b.qz&&b.qz(a,c,e);null===a.Qk&&(a.Qk=a.qf.style.border,a.qf.style.border="3px solid red");"function"===typeof a.qf.focus&&a.qf.focus();return}a.Qb(a.name);a.eb=oj;c!==e&&(a.ze.text=e);a.Bf=a.name;b=a.g;null!==b&&b.Ba("TextEdited",a.ze,c);a.fk();a.stopTool();null!==b&&b.focus()}null!==a.Qk&&(a.qf.style.border=a.Qk,a.Qk=null)}
cj.prototype.doDeactivate=function(){var a=this.g;if(null!==a){this.eb=ej;this.ze=null;if(null!==this.qf){var b=this.qf;if("function"===typeof b.onDeactivate)b.onDeactivate();null!==b&&a.hl.removeChild(b)}this.qa=!1}};cj.prototype.doFocus=function(){var a=this.qf;null!==a&&(this.eb===kj?this.eb=lj:this.eb===oj?this.eb=mj:this.eb===mj&&(this.eb=lj),"function"===typeof a.select&&this.mA&&(a.select(),a.setSelectionRange(0,9999)))};
cj.prototype.doBlur=function(){var a=this.qf;null!==a&&("function"===typeof a.focus&&a.focus(),"function"===typeof a.select&&this.mA&&(a.select(),a.setSelectionRange(0,9999)))};cj.prototype.isValidText=function(a,b,c){v.A(a,$g,cj,"isValidText:textblock");var d=this.wA;if(null!==d&&!d(a,b,c))return!1;d=a.wA;return null===d||d(a,b,c)?!0:!1};v.defineProperty(cj,{wA:"textValidation"},function(){return this.Tk},function(a){null!==a&&v.j(a,"function",cj,"textValidation");this.Tk=a});
v.defineProperty(cj,{uE:"minimumEditorScale"},function(){return this.dC},function(a){null!==a&&v.j(a,"number",cj,"minimumEditorScale");this.dC=a});v.defineProperty(cj,{mA:"selectsTextOnActivate"},function(){return this.zC},function(a){null!==a&&v.j(a,"boolean",cj,"selectsTextOnActivate");this.zC=a});
function Rg(){Wf.call(this);this.name="ToolManager";this.EF=new I(Wf);this.FF=new I(Wf);this.GF=new I(Wf);this.IB=this.JB=850;this.xB=(new ia(2,2)).Ea();this.SC=5E3;this.hC=eh;this.GB=Aj;this.Ju=this.nB=null;this.wm=-1}v.Ja(Rg,Wf);v.ea("ToolManager",Rg);var eh;Rg.WheelScroll=eh=v.p(Rg,"WheelScroll",0);var dh;Rg.WheelZoom=dh=v.p(Rg,"WheelZoom",1);Rg.WheelNone=v.p(Rg,"WheelNone",2);var Aj;Rg.GestureZoom=Aj=v.p(Rg,"GestureZoom",3);var Bj;Rg.GestureCancel=Bj=v.p(Rg,"GestureCancel",4);var Cj;
Rg.GestureNone=Cj=v.p(Rg,"GestureNone",5);v.defineProperty(Rg,{It:"mouseWheelBehavior"},function(){return this.hC},function(a){v.pb(a,Rg,Rg,"mouseWheelBehavior");this.hC=a});v.defineProperty(Rg,{$o:"gestureBehavior"},function(){return this.GB},function(a){v.pb(a,Rg,Rg,"gestureBehavior");this.GB=a});
Rg.prototype.initializeStandardTools=function(){this.WF=new Ui;this.BE=new qh;this.GH=new Ci;this.ME=new Ji;this.qI=new Ri;this.qE=new ti;this.$d=new Xg;this.IG=new aj;this.$H=new bj;this.fz=new Xi;this.vA=new cj;this.lG=new Vi;this.mG=new Ti};Rg.prototype.updateAdornments=function(a){var b=this.bh;if(null!==b&&this.Ju===a){var c=b.Zb;(null!==a?c.Y===a:null===c)?this.showToolTip(b,c):this.hideToolTip()}};
Rg.prototype.doMouseDown=function(){var a=this.g;if(null!==a){var b=a.N;b.Vi&&this.$o===Bj&&(b.bubbles=!1);if(b.wt){if(this.$o===Cj){b.bubbles=!0;return}if(this.$o===Bj)return;if(a.Wa.canStartMultiTouch()){a.Wa.standardPinchZoomStart();return}}var c=a.la;c.cz&&0!==c.rh&&v.trace("WARNING: In ToolManager.doMouseDown: UndoManager.transactionLevel is not zero");for(var c=this.Ne.length,d=0;d<c;d++){var e=this.Ne.da(d);null===e.g&&e.Mc(this.g);if(e.canStart()){a.doFocus();a.Wa=e;a.Wa===e&&(e.qa||e.doActivate(),
e.doMouseDown());return}}1===a.N.button&&(this.It===eh?this.It=dh:this.It===dh&&(this.It=eh));this.doActivate();this.standardWaitAfter(this.YD,b)}};
Rg.prototype.doMouseMove=function(){var a=this.g;if(null!==a){var b=a.N;if(b.wt){if(this.$o===Cj){b.bubbles=!0;return}if(this.$o===Bj)return;if(a.Wa.canStartMultiTouch()){a.Wa.standardPinchZoomMove();return}}if(this.qa)for(var c=this.Vf.length,d=0;d<c;d++){var e=this.Vf.da(d);null===e.g&&e.Mc(this.g);if(e.canStart()){a.doFocus();a.Wa=e;a.Wa===e&&(e.qa||e.doActivate(),e.doMouseMove());return}}Dj(this,a);null===b.event||"mousemove"!==b.event.type&&b.event.cancelable||(b.bubbles=!0)}};
function Dj(a,b){a.standardMouseOver();a.isBeyondDragSize()&&a.standardWaitAfter(a.qa?a.YD:a.fH,b.N)}Rg.prototype.doCurrentObjectChanged=function(a,b){var c=this.bh;null===c||null!==b&&(b===c||b.tl(c))||this.hideToolTip()};Rg.prototype.doWaitAfter=function(a){var b=this.g;null!==b&&b.zb&&(this.doMouseHover(),this.qa||this.doToolTip(),a.Vi&&!b.N.zc&&(a=a.copy(),a.button=2,a.buttons=2,b.N=a,b.ys=!0,b.doMouseUp()))};
Rg.prototype.doMouseHover=function(){var a=this.g;if(null!==a){var b=a.N;null===b.ye&&(b.ye=a.qe(b.ca,null,null));var c=b.ye;if(null!==c)for(b.zc=!1;null!==c;){a=this.qa?c.Rz:c.Sz;if(null!==a&&(a(b,c),b.zc))break;c=c.S}else a=this.qa?a.Rz:a.Sz,null!==a&&a(b)}};
Rg.prototype.doToolTip=function(){var a=this.g;if(null!==a){var b=a.N;null===b.ye&&(b.ye=a.qe(b.ca,null,null));b=b.ye;if(null!==b){if(b!==this.bh&&!b.tl(this.bh)){for(;null!==b;){a=b.yA;if(null!==a){this.showToolTip(a,b);return}b=b.S}this.hideToolTip()}}else a=a.yA,null!==a?this.showToolTip(a,null):this.hideToolTip()}};
Rg.prototype.showToolTip=function(a,b){v.A(a,Tg,Rg,"showToolTip:tooltip");null!==b&&v.A(b,G,Rg,"showToolTip:obj");var c=this.g;if(null!==c){a!==this.bh&&this.hideToolTip();a.Tf="Tool";a.zl=!1;a.scale=1/c.scale;a.gc="ToolTip";null!==a.placeholder&&(a.placeholder.scale=c.scale);c.add(a);if(null!==b){var c=null,d=b.Xo();null!==d&&(c=d.data);a.Zb=b;a.data=c}else a.data=c.ba;a.sf();this.positionToolTip(a,b);this.bh=a;-1!==this.wm&&(v.clearTimeout(this.wm),this.wm=-1);c=this.NI;if(0<c&&Infinity!==c){var e=
this;this.wm=v.setTimeout(function(){e.hideToolTip()},c)}}};Rg.prototype.positionToolTip=function(a){if(null===a.placeholder){var b=this.g;if(null!==b){var c=b.N.ca.copy(),d=a.Ca,e=b.rb;b.N.Vi&&(c.x-=d.width);c.x+d.width>e.right&&(c.x-=d.width+5);c.x<e.x&&(c.x=e.x);c.y=c.y+20+d.height>e.bottom?c.y-(d.height+5):c.y+20;c.y<e.y&&(c.y=e.y);a.position=c}}};
Rg.prototype.hideToolTip=function(){-1!==this.wm&&(v.clearTimeout(this.wm),this.wm=-1);var a=this.g;null!==a&&null!==this.bh&&(a.remove(this.bh),null!==this.Ju&&this.Ju.$i(this.bh.gc),this.bh.data=null,this.bh=this.bh.Zb=null)};v.defineProperty(Rg,{bh:"currentToolTip"},function(){return this.nB},function(a){null!==a&&v.A(a,Tg,Rg,"currentToolTip");this.nB=a;this.Ju=null!==a?a.Mf:null});
Rg.prototype.doMouseUp=function(){this.cancelWaitAfter();var a=this.g;if(null!==a){if(this.qa)for(var b=this.Wf.length,c=0;c<b;c++){var d=this.Wf.da(c);null===d.g&&d.Mc(this.g);if(d.canStart()){a.doFocus();a.Wa=d;a.Wa===d&&(d.qa||d.doActivate(),d.doMouseUp());return}}a.doFocus();this.doDeactivate()}};Rg.prototype.doMouseWheel=function(){this.standardMouseWheel()};Rg.prototype.doKeyDown=function(){var a=this.g;null!==a&&a.nb.doKeyDown()};Rg.prototype.doKeyUp=function(){var a=this.g;null!==a&&a.nb.doKeyUp()};
Rg.prototype.doCancel=function(){null!==hh&&hh.doCancel();Wf.prototype.doCancel.call(this)};Rg.prototype.findTool=function(a){v.j(a,"string",Rg,"findTool:name");for(var b=this.Ne.length,c=0;c<b;c++){var d=this.Ne.da(c);if(d.name===a)return d}b=this.Vf.length;for(c=0;c<b;c++)if(d=this.Vf.da(c),d.name===a)return d;b=this.Wf.length;for(c=0;c<b;c++)if(d=this.Wf.da(c),d.name===a)return d;return null};
Rg.prototype.replaceTool=function(a,b){v.j(a,"string",Rg,"replaceTool:name");null!==b&&(v.A(b,Wf,Rg,"replaceTool:newtool"),b.g&&b.g!==this.g&&v.k("Cannot share tools between Diagrams: "+b.toString()),b.Mc(this.g));for(var c=this.Ne.length,d=0;d<c;d++){var e=this.Ne.da(d);if(e.name===a)return null!==b?this.Ne.Hg(d,b):this.Ne.Vc(d),e}c=this.Vf.length;for(d=0;d<c;d++)if(e=this.Vf.da(d),e.name===a)return null!==b?this.Vf.Hg(d,b):this.Vf.Vc(d),e;c=this.Wf.length;for(d=0;d<c;d++)if(e=this.Wf.da(d),e.name===
a)return null!==b?this.Wf.Hg(d,b):this.Wf.Vc(d),e;return null};function Ej(a,b,c,d){v.j(b,"string",Rg,"replaceStandardTool:name");v.A(d,I,Rg,"replaceStandardTool:list");null!==c&&(v.A(c,Wf,Rg,"replaceStandardTool:newtool"),c.g&&c.g!==a.g&&v.k("Cannot share tools between Diagrams: "+c.toString()),c.name=b,c.Mc(a.g));a.findTool(b)?a.replaceTool(b,c):null!==c&&d.add(c)}v.u(Rg,{Ne:"mouseDownTools"},function(){return this.EF});v.u(Rg,{Vf:"mouseMoveTools"},function(){return this.FF});
v.u(Rg,{Wf:"mouseUpTools"},function(){return this.GF});v.defineProperty(Rg,{fH:"hoverDelay"},function(){return this.JB},function(a){v.j(a,"number",Rg,"hoverDelay");this.JB=a});v.defineProperty(Rg,{YD:"holdDelay"},function(){return this.IB},function(a){v.j(a,"number",Rg,"holdDelay");this.IB=a});v.defineProperty(Rg,{JG:"dragSize"},function(){return this.xB},function(a){v.A(a,ia,Rg,"dragSize");this.xB=a.R()});
v.defineProperty(Rg,{NI:"toolTipDuration"},function(){return this.SC},function(a){v.j(a,"number",Rg,"toolTipDuration");this.SC=a});v.defineProperty(Rg,{WF:"actionTool"},function(){return this.findTool("Action")},function(a){Ej(this,"Action",a,this.Ne)});v.defineProperty(Rg,{BE:"relinkingTool"},function(){return this.findTool("Relinking")},function(a){Ej(this,"Relinking",a,this.Ne)});
v.defineProperty(Rg,{GH:"linkReshapingTool"},function(){return this.findTool("LinkReshaping")},function(a){Ej(this,"LinkReshaping",a,this.Ne)});v.defineProperty(Rg,{ME:"resizingTool"},function(){return this.findTool("Resizing")},function(a){Ej(this,"Resizing",a,this.Ne)});v.defineProperty(Rg,{qI:"rotatingTool"},function(){return this.findTool("Rotating")},function(a){Ej(this,"Rotating",a,this.Ne)});
v.defineProperty(Rg,{qE:"linkingTool"},function(){return this.findTool("Linking")},function(a){Ej(this,"Linking",a,this.Vf)});v.defineProperty(Rg,{$d:"draggingTool"},function(){return this.findTool("Dragging")},function(a){Ej(this,"Dragging",a,this.Vf)});v.defineProperty(Rg,{IG:"dragSelectingTool"},function(){return this.findTool("DragSelecting")},function(a){Ej(this,"DragSelecting",a,this.Vf)});
v.defineProperty(Rg,{$H:"panningTool"},function(){return this.findTool("Panning")},function(a){Ej(this,"Panning",a,this.Vf)});v.defineProperty(Rg,{fz:"contextMenuTool"},function(){return this.findTool("ContextMenu")},function(a){Ej(this,"ContextMenu",a,this.Wf)});v.defineProperty(Rg,{vA:"textEditingTool"},function(){return this.findTool("TextEditing")},function(a){Ej(this,"TextEditing",a,this.Wf)});
v.defineProperty(Rg,{lG:"clickCreatingTool"},function(){return this.findTool("ClickCreating")},function(a){Ej(this,"ClickCreating",a,this.Wf)});v.defineProperty(Rg,{mG:"clickSelectingTool"},function(){return this.findTool("ClickSelecting")},function(a){Ej(this,"ClickSelecting",a,this.Wf)});
function ug(){this.oB=Fj;this.vq=this.wq=this.W=null;this.on=this.xq=this.yq=0;this.Kl=this.pc=this.Xn=this.rj=!1;this.sj=this.Qe=!0;this.Iu=this.Hu=this.iB=null;this.hB=0;this.yo=null;this.uu=new J("string");this.Zx=600;this.KF=new z(0,0);this.WA=this.VA=this.WC=!1;this.gm=new na(G,Gj)}v.ea("AnimationManager",ug);ug.prototype.Mc=function(a){this.W=a};function Fj(a,b,c,d){a/=d/2;return 1>a?c/2*a*a+b:-c/2*(--a*(a-2)-1)+b}
v.defineProperty(ug,{tA:"starting"},function(){return this.yo},function(a){this.yo!==a&&(null!==a&&v.j(a,"function",ug,"starting"),this.yo=a)});v.u(ug,{sJ:"animationReasons"},function(){return this.uu});ug.prototype.prepareAutomaticAnimation=ug.prototype.$m=function(a){if(this.Qe&&(this.sj||this.W.Tm)){this.uu.add(a);var b=this.tA;if(null===b||b(this,a))this.rj&&this.Zh(),this.pc=!0,this.Kl=!1}};
function Hj(a){if(a.Qe&&(a.uu.clear(),a.pc))if(!a.Kl)a.pc=!1;else if(0===a.on){var b=+new Date;a.on=b;requestAnimationFrame(function(){if(!1!==a.pc&&!a.rj&&a.on===b){var c=a.W;c.Si("temporaryPixelRatio")&&(c.Mj=1);Ij(c);a.pc=!1;c.Ba("AnimationStarting");Jj(a,b)}})}}
function Kj(a,b,c,d,e,f){if(!(!a.pc||"position"===c&&d.K(e)||b instanceof R&&!b.aE)){var h=a.gm;if(h.contains(b)){b=h.ja(b);var k=b.start,l=b.end;void 0===k[c]&&(k[c]=Lj(d));b.jz&&void 0!==l[c]?b.rz[c]=Lj(e):(f||(b.rz[c]=Lj(e)),l[c]=Lj(e))}else k=new ta,l=new ta,k[c]=Lj(d),l[c]=Lj(e),c=l,d=k.position,d instanceof z&&!d.G()&&a.uu.contains("Expand SubGraph")&&d.assign(c.position),h.add(b,new Gj(k,l,f));a.Kl=!0}}function Lj(a){return a instanceof z?a.copy():a instanceof ia?a.copy():a}
function Jj(a,b){var c;function d(){if(!1!==f.rj&&f.on===b){var a=+new Date,c=a>s?m:a-r;Mj(f);Nj(f,e,q,h,c,m);f.Hu&&f.Hu();Wh(e);Oj(f);a>s?Pj(f):requestAnimationFrame(d)}}void 0===c&&(c=new ta);var e=a.W;if(null!==e){a.rj=!0;var f=a,h=c.NJ||a.oB,k=c.nK||null,l=c.oK||null,m=c.duration||a.Zx,n=a.KF;for(c=a.gm.i;c.next();){var p=c.value.start.position;p instanceof z&&(p.G()||p.assign(n))}a.iB=h;a.Hu=k;a.Iu=l;a.hB=m;a.lB=a.gm;var q=a.lB;for(c=q.i;c.next();)k=c.value.end,k["position:placeholder"]&&(l=
k["position:placeholder"],n=l.Xa(tb),n.x+=l.padding.left,n.y+=l.padding.top,k["position:placeholder"]=n);Mj(a);Nj(a,e,q,h,0,m);Wh(a.W);Oj(a);var r=+new Date,s=r+m;f.on===b&&requestAnimationFrame(function(){d()})}}
var Qj={opacity:function(a,b,c,d,e,f){a.opacity=d(e,b,c-b,f)},position:function(a,b,c,d,e,f){e!==f?a.nx(d(e,b.x,c.x-b.x,f),d(e,b.y,c.y-b.y,f)):a.position=new z(d(e,b.x,c.x-b.x,f),d(e,b.y,c.y-b.y,f))},"position:node":function(a,b,c,d,e,f){var h=a.U,k=c.U;c=k.x+k.width/2-h.width/2;h=k.y+k.height/2-h.height/2;e!==f?a.nx(d(e,b.x,c-b.x,f),d(e,b.y,h-b.y,f)):a.position=new z(d(e,b.x,c-b.x,f),d(e,b.y,h-b.y,f))},"position:placeholder":function(a,b,c,d,e,f){e!==f?a.nx(d(e,b.x,c.x-b.x,f),d(e,b.y,c.y-b.y,f)):
a.position=new z(d(e,b.x,c.x-b.x,f),d(e,b.y,c.y-b.y,f))},scale:function(a,b,c,d,e,f){a.scale=d(e,b,c-b,f)},visible:function(a,b,c,d,e,f){a.visible=e!==f?b:c}};function Mj(a){if(!a.Xn){var b=a.W;a.WC=b.tb;a.VA=b.we;a.WA=b.bu;b.tb=!0;b.we=!0;b.bu=!0;a.Xn=!0}}function Oj(a){var b=a.W;b.tb=a.WC;b.we=a.VA;b.bu=a.WA;a.Xn=!1}
function Nj(a,b,c,d,e,f){for(c=c.i;c.next();){var h=c.key,k=c.value,l=k.start,k=k.end,m;for(m in k)if(("position"!==m||!k["position:placeholder"]&&!k["position:node"])&&void 0!==Qj[m])Qj[m](h,l[m],k[m],d,e,f)}d=b.Ez;b.Ez=!0;m=a.oB;0!==a.yq&&0!==a.xq&&(c=a.yq,b.Tb=m(e,c,a.xq-c,f));null!==a.wq&&null!==a.vq&&(c=a.wq,a=a.vq,b.kb=new z(m(e,c.x,a.x-c.x,f),m(e,c.y,a.y-c.y,f)));b.Ez=d}ug.prototype.stopAnimation=ug.prototype.Zh=function(){!0===this.pc&&(this.pc=!1,this.Kl&&this.W.ue());this.rj&&this.Qe&&Pj(this)};
function Pj(a){a.rj=!1;a.on=0;a.Kl=!1;a.wq=null;a.vq=null;a.yq=0;a.xq=0;Mj(a);for(var b=a.W,c=a.iB,d=a.hB,e=a.lB.i;e.next();){var f=e.key,h=e.value,k=h.start,l=h.end,m=h.rz,n;for(n in l)if(void 0!==Qj[n]){var p=n;!h.jz||"position:node"!==p&&"position:placeholder"!==p||(p="position");Qj[p](f,k[n],void 0!==m[n]?m[n]:h.jz?k[n]:l[n],c,d,d)}h.ax&&f.nd(!1)}for(c=a.W.links;c.next();)d=c.value,null!==d.ro&&(d.points=d.ro,d.ro=null);b.Qw.clear();b.Mj=null;b.qc();b.Uf();Rj(b);Oj(a);a.gm=new na(G,Gj);a.Iu&&
a.Iu();a.Iu=null;a.Hu=null;b.Ba("AnimationFinished");b.ue()}function Sj(a,b,c){var d=b.U,e=c.U,f=null;c instanceof V&&(f=c.placeholder);null!==f?(d=f.Xa(tb),d.x+=f.padding.left,d.y+=f.padding.top,Kj(a,b,"position",d,b.position,!1)):Kj(a,b,"position",new z(e.x+e.width/2-d.width/2,e.y+e.height/2-d.height/2),b.position,!1);Kj(a,b,"scale",.01,b.scale,!1);if(b instanceof V)for(b=b.Ic;b.next();)f=b.value,f instanceof S&&Sj(a,f,c)}
function Tj(a,b,c){if(b.isVisible()){var d=null;c instanceof V&&(d=c.placeholder);null!==d?Kj(a,b,"position:placeholder",b.position,d,!0):Kj(a,b,"position:node",b.position,c,!0);Kj(a,b,"scale",b.scale,.01,!0);a.pc&&(d=a.gm,d.contains(b)&&(d.ja(b).ax=!0));if(b instanceof V)for(b=b.Ic;b.next();)d=b.value,d instanceof S&&Tj(a,d,c)}}function Uj(a,b,c){a.pc&&(null===a.wq&&b.G()&&null===a.vq&&(a.wq=b.copy()),a.vq=c.copy(),a.Kl=!0)}
function Vj(a,b,c){a.pc&&a.W.Tm&&(0===a.yq&&0===a.xq&&(a.yq=b),a.xq=c,a.Kl=!0)}v.defineProperty(ug,{isEnabled:"isEnabled"},function(){return this.Qe},function(a){v.j(a,"boolean",ug,"isEnabled");this.Qe=a});v.defineProperty(ug,{duration:"duration"},function(){return this.Zx},function(a){v.j(a,"number",ug,"duration");1>a&&v.va(a,">= 1",ug,"duration");this.Zx=a});v.u(ug,{cf:"isAnimating"},function(){return this.rj});v.u(ug,{wH:"isTicking"},function(){return this.Xn});
v.defineProperty(ug,{hE:"isInitial"},function(){return this.sj},function(a){v.j(a,"boolean",ug,"isInitial");this.sj=a});function Gj(a,b,c){this.start=a;this.end=b;this.rz=new ta;this.jz=c;this.ax=!1}function Vf(){0<arguments.length&&v.dd(Vf);v.ec(this);this.W=null;this.wb=new I(R);this.Ob="";this.Rc=1;this.ty=!1;this.Sk=this.Ty=this.qk=this.pk=this.ok=this.nk=this.lk=this.mk=this.kk=this.sk=this.jk=this.rk=this.ik=this.hk=!0;this.ny=!1;this.Bv=[]}v.ea("Layer",Vf);
Vf.prototype.Mc=function(a){this.W=a};
Vf.prototype.toString=function(a){void 0===a&&(a=0);var b='Layer "'+this.name+'"';if(0>=a)return b;for(var c=0,d=0,e=0,f=0,h=0,k=this.wb.i;k.next();){var l=k.value;l instanceof V?e++:l instanceof S?d++:l instanceof W?f++:l instanceof Tg?h++:c++}k="";0<c&&(k+=c+" Parts ");0<d&&(k+=d+" Nodes ");0<e&&(k+=e+" Groups ");0<f&&(k+=f+" Links ");0<h&&(k+=h+" Adornments ");if(1<a)for(a=this.wb.i;a.next();)c=a.value,k+="\n    "+c.toString(),d=c.data,null!==d&&v.ld(d)&&(k+=" #"+v.ld(d)),c instanceof S?k+=" "+
Ef(d):c instanceof W&&(k+=" "+Ef(c.T)+" "+Ef(c.Z));return b+" "+this.wb.count+": "+k};Vf.prototype.findObjectAt=Vf.prototype.qe=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);if(!1===this.Sk)return null;var d=!1;this.g.rb.Fa(a)&&(d=!0);for(var e=v.L(),f=this.wb.n,h=f.length;h--;){var k=f[h];if((!0!==d||!1!==Wj(k))&&k.isVisible()&&(e.assign(a),Qa(e,k.Yg),k=k.qe(e,b,c),null!==k&&(null!==b&&(k=b(k)),null!==k&&(null===c||c(k)))))return v.v(e),k}v.v(e);return null};
Vf.prototype.findObjectsAt=Vf.prototype.ft=function(a,b,c,d){void 0===b&&(b=null);void 0===c&&(c=null);d instanceof I||d instanceof J||(d=new J(G));if(!1===this.Sk)return d;var e=!1;this.g.rb.Fa(a)&&(e=!0);for(var f=v.L(),h=this.wb.n,k=h.length;k--;){var l=h[k];!0===e&&!1===Wj(l)||!l.isVisible()||(f.assign(a),Qa(f,l.Yg),l.ft(f,b,c,d)&&(null!==b&&(l=b(l)),null===l||null!==c&&!c(l)||d.add(l)))}v.v(f);return d};
Vf.prototype.findObjectsIn=Vf.prototype.Yj=function(a,b,c,d,e){void 0===b&&(b=null);void 0===c&&(c=null);void 0===d&&(d=!1);e instanceof I||e instanceof J||(e=new J(G));if(!1===this.Sk)return e;var f=!1;this.g.rb.Wj(a)&&(f=!0);for(var h=this.wb.n,k=h.length;k--;){var l=h[k];(!0!==f||!1!==Wj(l))&&l.isVisible()&&l.Yj(a,b,c,d,e)&&(null!==b&&(l=b(l)),null===l||null!==c&&!c(l)||e.add(l))}return e};
Vf.prototype.sz=function(a,b,c,d,e,f,h){if(!1===this.Sk)return e;for(var k=this.wb.n,l=k.length;l--;){var m=k[l];(!0!==h||!1!==Wj(m))&&f(m)&&m.isVisible()&&m.Yj(a,b,c,d,e)&&(null!==b&&(m=b(m)),null===m||null!==c&&!c(m)||e.add(m))}return e};
Vf.prototype.findObjectsNear=Vf.prototype.Mm=function(a,b,c,d,e,f){void 0===c&&(c=null);void 0===d&&(d=null);void 0===e&&(e=!0);if(!1!==e&&!0!==e){if(e instanceof I||e instanceof J)f=e;e=!0}f instanceof I||f instanceof J||(f=new J(G));if(!1===this.Sk)return f;var h=!1;this.g.rb.Fa(a)&&(h=!0);for(var k=v.L(),l=v.L(),m=this.wb.n,n=m.length;n--;){var p=m[n];!0===h&&!1===Wj(p)||!p.isVisible()||(k.assign(a),Qa(k,p.Yg),l.m(a.x+b,a.y),Qa(l,p.Yg),p.Mm(k,l,c,d,e,f)&&(null!==c&&(p=c(p)),null===p||null!==d&&
!d(p)||f.add(p)))}v.v(k);v.v(l);return f};g=Vf.prototype;g.yf=function(a,b){if(this.visible){var c;c=void 0===b?a.rb:b;for(var d=this.wb.n,e=d.length,f=0;f<e;f++){var h=d[f];h.aC=f;if(!(h instanceof W&&!1===h.Lf)){if(h instanceof Tg){var k=h;if(null!==k.Mf)continue}if($a(h.U,c))for(h.yf(!0),Xj(h),h=h.gw;h.next();)k=h.value,fj(k,Infinity,Infinity),k.Ec(),k.yf(!0);else h.yf(!1),null!==h.gw&&0<h.gw.count&&Xj(h)}}}};
g.We=function(a,b,c){if(this.visible&&0!==this.Rc&&(void 0===c&&(c=!0),c||!this.Gc)){c=this.wb.n;var d=c.length;if(0!==d){1!==this.Rc&&(a.globalAlpha=this.Rc);var e=this.Bv;e.length=0;for(var f=b.scale,h=0;h<d;h++){var k=c[h];if(Wj(k)){if(k instanceof W){var l=k;l.jc&&e.push(l);if(!1===l.Lf)continue}l=k.U;1<l.width*f||1<l.height*f?k.We(a,b):Yj(k,a)}}a.globalAlpha=1}}};
function Zj(a,b,c,d){if(a.visible&&0!==a.Rc){1!==a.Rc&&(b.globalAlpha=a.Rc);var e=a.Bv;e.length=0;var f=c.scale;a=a.wb.n;for(var h=a.length,k=d.length,l=0;l<h;l++){var m=a[l];if(Wj(m)){if(m instanceof W){var n=m;n.jc&&e.push(n);if(!1===n.Lf)continue}var n=ak(m,m.U),p;a:{p=n;for(var q=d,r=k,s=2/f,t=4/f,u=0;u<r;u++){var y=q[u];if(0!==y.width&&0!==y.height&&p.ZD(y.x-s,y.y-s,y.width+t,y.height+t)){p=!0;break a}}p=!1}p&&(1<n.width*f||1<n.height*f?m.We(b,c):Yj(m,b))}}b.globalAlpha=1}}
g.h=function(a,b,c,d,e){var f=this.g;null!==f&&f.Lc(Af,a,this,b,c,d,e)};g.ep=function(a,b,c){var d=this.wb;b.lv=this;if(a>=d.count)a=d.count;else if(d.da(a)===b)return-1;d.Ad(a,b);b.qt(c);d=this.g;null!==d&&(c?d.pa():d.ep(b));bk(this,a,b);return a};
g.Le=function(a,b,c){if(!c&&b.layer!==this&&null!==b.layer)return b.layer.Le(a,b,c);var d=this.wb;if(0>a||a>=d.length){if(a=d.indexOf(b),0>a)return-1}else if(d.da(a)!==b&&(a=d.indexOf(b),0>a))return-1;b.rt(c);d.Vc(a);d=this.g;null!==d&&(c?d.pa():d.Le(b));b.lv=null;return a};
function bk(a,b,c){b=ck(a,b,c);if(c instanceof V&&null!==c&&isNaN(c.ln)){if(0!==c.Ic.count){for(var d=-1,e=a.wb.n,f=e.length,h=0;h<f;h++){var k=e[h];if(k===c&&(b=h,0<=d))break;if(0>d&&k.Sa===c&&(d=h,0<=b))break}!(0>d)&&d<b&&(e=a.wb,e.Vc(b),e.Ad(d,c))}c=c.Sa;null!==c&&bk(a,-1,c)}}
function ck(a,b,c){var d=c.ln;if(isNaN(d))return b;a=a.wb;var e=a.count;if(1>=e)return b;0>b&&(b=a.indexOf(c));if(0>b)return-1;for(var f=b-1,h=NaN;0<=f;){h=a.da(f).ln;if(!isNaN(h))break;f--}for(var k=b+1,l=NaN;k<e;){l=a.da(k).ln;if(!isNaN(l))break;k++}if(!isNaN(h)&&h>d)for(;;){if(-1===f||h<=d){f++;if(f===b)break;a.Vc(b);a.Ad(f,c);return f}for(h=NaN;0<=--f&&(h=a.da(f).ln,isNaN(h)););}else if(!isNaN(l)&&l<d)for(;;){if(k===e||l>=d){k--;if(k===b)break;a.Vc(b);a.Ad(k,c);return k}for(l=NaN;++k<e&&(l=a.da(k).ln,
isNaN(l)););}return b}g.clear=function(){for(var a=this.wb.dc(),b=a.length,c=0;c<b;c++)a[c].yf(!1),this.Le(-1,a[c],!1)};v.u(Vf,{dk:"parts"},function(){return this.wb.i});v.u(Vf,{xK:"partsBackwards"},function(){return this.wb.Um});v.u(Vf,{g:"diagram"},function(){return this.W});
v.defineProperty(Vf,{name:"name"},function(){return this.Ob},function(a){v.j(a,"string",Vf,"name");var b=this.Ob;if(b!==a){var c=this.g;if(null!==c)for(""===b&&v.k("Cannot rename default Layer to: "+a),c=c.Ow;c.next();)c.value.name===a&&v.k("Layer.name is already present in this diagram: "+a);this.Ob=a;this.h("name",b,a);for(a=this.wb.i;a.next();)a.value.Tf=this.Ob}});
v.defineProperty(Vf,{opacity:"opacity"},function(){return this.Rc},function(a){var b=this.Rc;b!==a&&(v.j(a,"number",Vf,"opacity"),(0>a||1<a)&&v.va(a,"0 <= value <= 1",Vf,"opacity"),this.Rc=a,this.h("opacity",b,a),a=this.g,null!==a&&a.pa())});v.defineProperty(Vf,{Gc:"isTemporary"},function(){return this.ty},function(a){var b=this.ty;b!==a&&(v.j(a,"boolean",Vf,"isTemporary"),this.ty=a,this.h("isTemporary",b,a))});
v.defineProperty(Vf,{visible:"visible"},function(){return this.Ty},function(a){var b=this.Ty;if(b!==a){v.j(a,"boolean",Vf,"visible");this.Ty=a;this.h("visible",b,a);for(b=this.wb.i;b.next();)b.value.nd(a);a=this.g;null!==a&&a.pa()}});v.defineProperty(Vf,{Gg:"pickable"},function(){return this.Sk},function(a){var b=this.Sk;b!==a&&(v.j(a,"boolean",Vf,"pickable"),this.Sk=a,this.h("pickable",b,a))});
v.defineProperty(Vf,{bE:"isBoundsIncluded"},function(){return this.ny},function(a){this.ny!==a&&(this.ny=a,null!==this.g&&this.g.qc())});v.defineProperty(Vf,{Uj:"allowCopy"},function(){return this.hk},function(a){var b=this.hk;b!==a&&(v.j(a,"boolean",Vf,"allowCopy"),this.hk=a,this.h("allowCopy",b,a))});v.defineProperty(Vf,{zm:"allowDelete"},function(){return this.ik},function(a){var b=this.ik;b!==a&&(v.j(a,"boolean",Vf,"allowDelete"),this.ik=a,this.h("allowDelete",b,a))});
v.defineProperty(Vf,{kw:"allowTextEdit"},function(){return this.rk},function(a){var b=this.rk;b!==a&&(v.j(a,"boolean",Vf,"allowTextEdit"),this.rk=a,this.h("allowTextEdit",b,a))});v.defineProperty(Vf,{hw:"allowGroup"},function(){return this.jk},function(a){var b=this.jk;b!==a&&(v.j(a,"boolean",Vf,"allowGroup"),this.jk=a,this.h("allowGroup",b,a))});
v.defineProperty(Vf,{lw:"allowUngroup"},function(){return this.sk},function(a){var b=this.sk;b!==a&&(v.j(a,"boolean",Vf,"allowUngroup"),this.sk=a,this.h("allowUngroup",b,a))});v.defineProperty(Vf,{Ts:"allowLink"},function(){return this.kk},function(a){var b=this.kk;b!==a&&(v.j(a,"boolean",Vf,"allowLink"),this.kk=a,this.h("allowLink",b,a))});
v.defineProperty(Vf,{Am:"allowRelink"},function(){return this.mk},function(a){var b=this.mk;b!==a&&(v.j(a,"boolean",Vf,"allowRelink"),this.mk=a,this.h("allowRelink",b,a))});v.defineProperty(Vf,{$k:"allowMove"},function(){return this.lk},function(a){var b=this.lk;b!==a&&(v.j(a,"boolean",Vf,"allowMove"),this.lk=a,this.h("allowMove",b,a))});
v.defineProperty(Vf,{iw:"allowReshape"},function(){return this.nk},function(a){var b=this.nk;b!==a&&(v.j(a,"boolean",Vf,"allowReshape"),this.nk=a,this.h("allowReshape",b,a))});v.defineProperty(Vf,{Us:"allowResize"},function(){return this.ok},function(a){var b=this.ok;b!==a&&(v.j(a,"boolean",Vf,"allowResize"),this.ok=a,this.h("allowResize",b,a))});
v.defineProperty(Vf,{jw:"allowRotate"},function(){return this.pk},function(a){var b=this.pk;b!==a&&(v.j(a,"boolean",Vf,"allowRotate"),this.pk=a,this.h("allowRotate",b,a))});v.defineProperty(Vf,{nf:"allowSelect"},function(){return this.qk},function(a){var b=this.qk;b!==a&&(v.j(a,"boolean",Vf,"allowSelect"),this.qk=a,this.h("allowSelect",b,a))});
function E(a){function b(){window.document.removeEventListener("DOMContentLoaded",b,!1);dk(c)}1<arguments.length&&v.k("Diagram constructor can only take one optional argument, the DIV HTML element or its id.");v.ec(this);ek=[];this.Pc=!0;this.QA=new ug;this.QA.Mc(this);this.vd=17;this.Uq=!1;this.Gy="default";var c=this;null!==window.document.body?dk(this):window.document.addEventListener("DOMContentLoaded",b,!1);this.Yb=new I(Vf);this.Jb=this.Eb=0;this.Fj=this.Kb=this.kf=this.zb=null;this.IE();this.Un=
null;this.HE();this.kb=(new z(NaN,NaN)).freeze();this.Tb=1;this.cv=(new z(NaN,NaN)).freeze();this.dv=NaN;this.sv=1E-4;this.qv=100;this.me=new ja;this.ew=(new z(NaN,NaN)).freeze();this.Uu=(new B(NaN,NaN,NaN,NaN)).freeze();this.Nv=(new eb(0,0,0,0)).freeze();this.Ov=fk;this.Kv=this.Gv=null;this.Ml=Yg;this.wn=fc;this.Dk=Yg;this.Rn=fc;this.ev=this.bv=tb;this.je=!0;this.Oq=!1;this.fg=new J(G);this.xk=new na(W,B);this.Jn=!0;this.UA=250;this.pn=-1;this.xu=(new eb(16,16,16,16)).freeze();this.Mu=this.Kf=!1;
this.Nn=!0;this.qi=new xf;this.Sc=new xf;this.Nb=new xf;this.Rg=this.ei=null;this.ys=!1;this.Ux=this.Vx=null;gk(this);this.mo=new J(S);this.Uk=new J(V);this.eo=new J(W);this.wb=new J(R);this.iv=!0;this.$v=hk;this.MB=!1;this.bw=$h;this.Qx=this.Sx=this.Qy=null;this.Gu="";this.uq="auto";this.ji=this.Hi=this.yi=this.vv=this.zi=this.Ai=this.Bi=this.ii=this.oi=this.fi=null;this.By=!1;this.tC={};this.Fv=0;this.Jf=[null,null];this.Mx=null;this.Rp=this.Tx=this.Ky=this.AC=this.Gi=!1;this.YB=!0;this.py=this.Kd=
!1;this.Vd=null;var d=this;this.eC=function(a){if(a.ba===d.ba&&d.Qa){d.Qa=!1;try{var b=a.wc;""===a.gf&&b===Af&&ik(d,a.object,a.propertyName)}finally{d.Qa=!0}}};this.fC=function(a){jk(d,a)};this.YC=!0;this.Og=-2;this.ki=new na(Object,R);this.wk=new na(Object,W);this.Wl=new na(Object,Array);this.no=new na("string",Array);this.sC=new I(kk);this.si=!1;this.ik=this.hk=this.mu=this.Qe=!0;this.ou=this.nu=!1;this.tu=this.ru=this.qk=this.pk=this.ok=this.nk=this.lk=this.mk=this.kk=this.qu=this.sk=this.jk=this.rk=
!0;this.Tl=this.VB=!1;this.su=this.pu=this.$u=this.Zu=!0;this.Qv=this.Mv=16;this.Hy=this.Lv=!1;this.cs=this.Pv=null;this.Iy=this.Jy=0;this.Se=(new eb(5)).freeze();this.Sv=(new J(R)).freeze();this.rv=999999999;this.av=(new J(R)).freeze();this.Ek=this.Rl=this.qj=!0;this.Bk=this.pj=!1;this.Ud=null;this.vu=!0;this.Qg=!1;this.DF=new J(W);this.KB=new J(lk);this.td=null;this.kC=1;this.iy=!1;this.DC=0;this.XC=(new B(NaN,NaN,NaN,NaN)).freeze();this.Nu=(new B(NaN,NaN,NaN,NaN)).freeze();this.Cv=new J(mk);nk(this);
this.nv=this.Wu=this.wv=this.qB=this.pB=this.rB=this.wj=this.zk=this.Ci=null;ok(this);this.jd=null;this.Vu=!1;this.zn=null;this.Za=new Rg;this.Za.initializeStandardTools();this.Wa=this.$s=this.Za;this.nb=new Xf;this.ba=new Q;this.Gi=!0;this.Pb=new Yf;this.Gi=!1;this.CB=this.Xx=null;this.rd=1;this.Mj=null;this.Ck=new pk;void 0!==a&&qk(this,a);this.Yl=1;this.Zl=0;this.$B=new z;this.UC=500;this.wu=new z;this.qs=null;this.Pc=this.Fk=!1}v.ea("Diagram",E);
function ok(a){a.Ci=new na("string",R);var b=new S,c=new $g;c.bind(new zg("text","",Ef));b.add(c);a.rB=b;a.Ci.add("",b);b=new S;c=new $g;c.stroke="brown";c.bind(new zg("text","",Ef));b.add(c);a.Ci.add("Comment",b);b=new S;b.zl=!1;b.mD=!1;c=new X;c.Ab="Ellipse";c.fill="black";c.stroke=null;c.xa=(new ia(3,3)).Ea();b.add(c);a.Ci.add("LinkLabel",b);a.zk=new na("string",V);b=new V;b.lA="GROUPPANEL";b.type=rk;c=new $g;c.font="bold 12pt sans-serif";c.bind(new zg("text","",Ef));b.add(c);c=new D(sk);c.name=
"GROUPPANEL";var d=new X;d.Ab="Rectangle";d.fill="rgba(128,128,128,0.2)";d.stroke="black";c.add(d);d=new Mi;d.padding=(new eb(5,5,5,5)).Ea();c.add(d);b.add(c);a.pB=b;a.zk.add("",b);a.wj=new na("string",W);b=new W;c=new X;c.Rf=!0;b.add(c);c=new X;c.Lp="Standard";c.fill="black";c.stroke=null;c.lb=0;b.add(c);a.qB=b;a.wj.add("",b);b=new W;c=new X;c.Rf=!0;c.stroke="brown";b.add(c);a.wj.add("Comment",b);b=new Tg;b.type=sk;c=new X;c.fill=null;c.stroke="dodgerblue";c.lb=3;b.add(c);c=new Mi;c.margin=(new eb(1.5,
1.5,1.5,1.5)).Ea();b.add(c);a.wv=b;a.Wu=b;b=new Tg;b.type=xi;c=new X;c.Rf=!0;c.fill=null;c.stroke="dodgerblue";c.lb=3;b.add(c);a.nv=b}
function dk(a){var b=v.createElement("p");b.style.width="100%";b.style.height="200px";b.style.boxSizing="content-box";var c=v.createElement("div");c.style.position="absolute";c.style.visibility="hidden";c.style.width="200px";c.style.height="150px";c.style.overflow="hidden";c.style.boxSizing="content-box";c.appendChild(b);window.document.body.appendChild(c);var d=b.offsetWidth;c.style.overflow="scroll";b=b.offsetWidth;d===b&&(b=c.clientWidth);window.document.body.removeChild(c);c=d-b;0!==c||v.fE||
(c=11);a.vd=c;c=v.createElement("div");c.dir="rtl";c.style.cssText="font-size: 14px; width: 1px; height: 1px; position: absolute; top: -1000px; overflow: scroll;";c.textContent="A";window.document.body.appendChild(c);d="reverse";0<c.scrollLeft?d="default":(c.scrollLeft=1,0===c.scrollLeft&&(d="negative"));window.document.body.removeChild(c);a.Gy=d}
E.prototype.toString=function(a){void 0===a&&(a=0);var b="";this.id&&(b=this.id);this.hl&&this.hl.id&&(b=this.hl.id);b='Diagram "'+b+'"';if(0>=a)return b;for(var c=this.Yb.i;c.next();)b+="\n  "+c.value.toString(a-1);return b};E.fromDiv=function(a){var b=a;"string"===typeof a&&(b=window.document.getElementById(a));return b instanceof HTMLDivElement&&b.W instanceof E?b.W:null};
v.defineProperty(E,{hl:"div"},function(){return this.Kb},function(a){null!==a&&v.A(a,HTMLDivElement,E,"div");if(this.Kb!==a){ek=[];var b=this.Kb;null!==b?(b.W=void 0,b.innerHTML="",null!==this.zb&&(this.zb.removeEventListener("touchstart",this.jF,!1),this.zb.removeEventListener("touchmove",this.iF,!1),this.zb.removeEventListener("touchend",this.hF,!1),this.zb.xd.W=null),b=this.Za,null!==b&&(b.Ne.each(function(a){a.cancelWaitAfter()}),b.Vf.each(function(a){a.cancelWaitAfter()}),b.Wf.each(function(a){a.cancelWaitAfter()})),
b.cancelWaitAfter(),this.Wa.doCancel(),this.kf=this.zb=null,window.removeEventListener("resize",this.tF,!1),window.removeEventListener("mousemove",this.pp,!0),window.removeEventListener("mousedown",this.op,!0),window.removeEventListener("mouseup",this.rp,!0),window.removeEventListener("mousewheel",this.jh,!0),window.removeEventListener("DOMMouseScroll",this.jh,!0),window.removeEventListener("mouseout",this.qp,!0)):this.Qg=!1;this.Kb=null;if(null!==a){if(b=a.W)b.hl=null;qk(this,a);this.$w()}}});
function tk(a){var b=a.zb;b.addEventListener("touchstart",a.jF,!1);b.addEventListener("touchmove",a.iF,!1);b.addEventListener("touchend",a.hF,!1);b.addEventListener("mousemove",a.pp,!1);b.addEventListener("mousedown",a.op,!1);b.addEventListener("mouseup",a.rp,!1);b.addEventListener("mousewheel",a.jh,!1);b.addEventListener("DOMMouseScroll",a.jh,!1);b.addEventListener("mouseout",a.qp,!1);b.addEventListener("keydown",a.zH,!1);b.addEventListener("keyup",a.AH,!1);b.addEventListener("selectstart",function(a){a.preventDefault();
return!1},!1);b.addEventListener("contextmenu",function(a){a.preventDefault();return!1},!1);b.addEventListener("gesturechange",function(b){a.Za.$o===Bj&&b.preventDefault()},!1);b.addEventListener("pointerdown",a.aI,!1);b.addEventListener("pointermove",a.cI,!1);b.addEventListener("pointerleave",a.bI,!1);window.addEventListener("resize",a.tF,!1)}E.prototype.computePixelRatio=function(){return null!==this.Mj?this.Mj:this.HF};E.prototype.doMouseMove=function(){this.Wa.doMouseMove()};
E.prototype.doMouseDown=function(){this.Wa.doMouseDown()};E.prototype.doMouseUp=function(){this.Wa.doMouseUp()};E.prototype.doMouseWheel=function(){this.Wa.doMouseWheel()};E.prototype.doKeyDown=function(){this.Wa.doKeyDown()};E.prototype.doKeyUp=function(){this.Wa.doKeyUp()};E.prototype.doFocus=function(){this.focus()};E.prototype.focus=E.prototype.focus=function(){this.zb&&this.zb.focus()};
function Ij(a){if(null!==a.zb){var b=a.Kb;if(0!==b.clientWidth&&0!==b.clientHeight){var c=a.Bk?a.vd:0,d=a.pj?a.vd:0,e=a.rd;a.rd=a.computePixelRatio();a.rd!==e&&(a.Oq=!0,a.ue());if(b.clientWidth!==a.Eb+c||b.clientHeight!==a.Jb+d)a.Rl=!0,a.je=!0,b=a.Pb,null!==b&&b.Lw&&a.al===Yg&&b.H(),a.Kd||a.ue()}}}
function nk(a){var b=new Vf;b.name="Background";a.Ns(b);b=new Vf;b.name="";a.Ns(b);b=new Vf;b.name="Foreground";a.Ns(b);b=new Vf;b.name="Adornment";b.Gc=!0;a.Ns(b);b=new Vf;b.name="Tool";b.Gc=!0;b.bE=!0;a.Ns(b);b=new Vf;b.name="Grid";b.nf=!1;b.Gg=!1;b.Gc=!0;a.aG(b,a.dt("Background"))}
function uk(a){a.jd=new D(vk);a.jd.name="GRID";var b=new X;b.Ab="LineH";b.stroke="lightgray";b.lb=.5;b.interval=1;a.jd.add(b);b=new X;b.Ab="LineH";b.stroke="gray";b.lb=.5;b.interval=5;a.jd.add(b);b=new X;b.Ab="LineH";b.stroke="gray";b.lb=1;b.interval=10;a.jd.add(b);b=new X;b.Ab="LineV";b.stroke="lightgray";b.lb=.5;b.interval=1;a.jd.add(b);b=new X;b.Ab="LineV";b.stroke="gray";b.lb=.5;b.interval=5;a.jd.add(b);b=new X;b.Ab="LineV";b.stroke="gray";b.lb=1;b.interval=10;a.jd.add(b);b=new R;b.add(a.jd);
b.Tf="Grid";b.ln=0;b.Gz=!1;b.aE=!1;b.Gg=!1;b.Lz="GRID";a.add(b);a.wb.remove(b);a.jd.visible=!1}E.prototype.JF=function(){this.W.isEnabled?this.W.HG(this):wk(this.W)};E.prototype.jC=function(a){this.W.isEnabled?(this.W.Hy=!0,this.W.Jy=a.target.scrollTop,this.W.Iy=a.target.scrollLeft):wk(this.W)};
E.prototype.diagramScroll=E.prototype.HG=function(a){if(this.Hy&&null!==this.zb){this.Lv=!0;var b=this.Zd,c=this.rb,d=b.width,e=c.width,f=b.height,h=c.height,k=b.right,l=c.right,m=b.bottom,n=c.bottom,p=b.x,q=c.x,b=b.y,c=c.y,r=this.scale,s;s=a.scrollLeft;if(this.Uq)switch(this.Gy){case "negative":s=s+a.scrollWidth-a.clientWidth;break;case "reverse":s=a.scrollWidth-s-a.clientWidth}var t=s;e<d||h<f?(s=v.Mb(this.position.x,this.position.y),this.ne&&this.Iy!==t&&(s.x=t/r+p,this.Iy=t),this.oe&&this.Jy!==
a.scrollTop&&(s.y=a.scrollTop/r+b,this.Jy=a.scrollTop),this.position=s,v.v(s),this.Rl=this.Lv=!1):(s=v.L(),a.NF&&this.ne&&(p<q&&(this.position=s.m(t+p,this.position.y)),k>l&&(this.position=s.m(-(this.Pv.scrollWidth-this.Eb)+t-this.Eb/r+k,this.position.y))),a.OF&&this.oe&&(b<c&&(this.position=s.m(this.position.x,a.scrollTop+b)),m>n&&(this.position=s.m(this.position.x,-(this.Pv.scrollHeight-this.Jb)+a.scrollTop-this.Jb/r+m))),v.v(s),xk(this),this.Rl=this.Lv=!1,b=this.Zd,c=this.rb,k=b.right,l=c.right,
m=b.bottom,n=c.bottom,p=b.x,q=c.x,b=b.y,c=c.y,e>=d&&p>=q&&k<=l&&(this.cs.style.width="1px"),h>=f&&b>=c&&m<=n&&(this.cs.style.height="1px"))}};E.prototype.computeBounds=E.prototype.$g=function(){0<this.fg.count&&yk(this);return zk(this)};
function zk(a){if(a.OD.G()){var b=a.OD.copy();b.fw(a.padding);return b}for(var c=!0,d=a.Yb.n,e=d.length,f=0;f<e;f++){var h=d[f];if(h.visible&&(!h.Gc||h.bE))for(var h=h.wb.n,k=h.length,l=0;l<k;l++){var m=h[l];m.Gz&&m.isVisible()&&(m=m.U,m.G()&&(c?(c=!1,b=m.copy()):b.ai(m)))}}c&&(b=new B(0,0,0,0));b.fw(a.padding);return b}
E.prototype.computePartsBounds=function(a){var b=null;for(a=a.i;a.next();){var c=a.value;c instanceof W||(c.sf(),null===b?b=c.U.copy():b.ai(c.U))}return null===b?new B(NaN,NaN,0,0):b};
function Ak(a,b){if((b||a.Qg)&&!a.Pc&&null!==a.zb&&!a.Na.cf&&a.Zd.G()){a.Pc=!0;var c=a.Ml;b&&a.Dk!==Yg&&(c=a.Dk);var d=c!==Yg?Bk(a,c):a.scale,c=a.rb.copy(),e=a.Eb/d,f=a.Jb/d,h=null,k=a.Na;k.pc&&(h=a.kb.copy());a.position.Oa();var l=a.wn;b&&!l.cd()&&a.Rn.cd()&&(l=a.Rn);Ck(a,a.kb,a.Zd,e,f,l,b);a.position.freeze();null!==h&&Uj(k,h,a.kb);a.scale=d;a.Pc=!1;d=a.rb;d.Fc(c)||a.Lt(c,d)}}
function Bk(a,b){var c=a.nb.tw;if(null===a.zb)return c;a.qj&&Dk(a,a.$g());var d=a.Zd;if(!d.G())return c;var e=d.width,d=d.height,f=a.Eb,h=a.Jb,k=f/e,l=h/d;return b===Ek?(e=Math.min(l,k),e>c&&(e=c),e<a.hh&&(e=a.hh),e>a.gh&&(e=a.gh),e):b===Fk?(e=l>k?(h-a.vd)/d:(f-a.vd)/e,e>c&&(e=c),e<a.hh&&(e=a.hh),e>a.gh&&(e=a.gh),e):a.scale}E.prototype.zoomToFit=E.prototype.zoomToFit=function(){this.scale=Bk(this,Ek)};
E.prototype.zoomToRect=function(a,b){void 0===b&&(b=Ek);var c=a.width,d=a.height;if(!(0===c||0===d||isNaN(c)&&isNaN(d))){var e=1;if(b===Ek||b===Fk)if(isNaN(c))e=this.rb.height*this.scale/d;else if(isNaN(d))e=this.rb.width*this.scale/c;else var e=this.Eb,f=this.Jb,e=b===Fk?f/d>e/c?(f-(this.pj?this.vd:0))/d:(e-(this.Bk?this.vd:0))/c:Math.min(f/d,e/c);this.scale=e;this.position=new z(a.x,a.y)}};v.defineProperty(E,{Ez:null},function(){return this.Pc},function(a){this.Pc=a});
E.prototype.alignDocument=function(a,b){this.qj&&Dk(this,this.$g());var c=this.Zd,d=this.rb;this.position=new z(c.x+(a.x*c.width+a.offsetX)-(b.x*d.width-b.offsetX),c.y+(a.y*c.height+a.offsetY)-(b.y*d.height-b.offsetY))};
function Ck(a,b,c,d,e,f,h){var k=b.x,l=b.y;if(h||a.jA===fk)f.cd()&&(d>c.width&&(k=c.x+(f.x*c.width+f.offsetX)-(f.x*d-f.offsetX)),e>c.height&&(l=c.y+(f.y*c.height+f.offsetY)-(f.y*e-f.offsetY))),f=a.PE,h=d-c.width,d<c.width+f.left+f.right?(k=Math.min(k+d/2,c.right+Math.max(h,f.right)-d/2),k=Math.max(k,c.left-Math.max(h,f.left)+d/2),k-=d/2):k>c.left?k=c.left:k<c.right-d&&(k=c.right-d),d=e-c.height,e<c.height+f.top+f.bottom?(l=Math.min(l+e/2,c.bottom+Math.max(d,f.bottom)-e/2),l=Math.max(l,c.top-Math.max(d,
f.top)+e/2),l-=e/2):l>c.top?l=c.top:l<c.bottom-e&&(l=c.bottom-e);b.x=isFinite(k)?k:-a.padding.left;b.y=isFinite(l)?l:-a.padding.top;null!==a.AE&&(a=a.AE(a,b),b.x=a.x,b.y=a.y)}E.prototype.findPartAt=E.prototype.gt=function(a,b){var c=b?Ph(this,a,function(a){return a.Y},function(a){return a.canSelect()}):Ph(this,a,function(a){return a.Y});return c instanceof R?c:null};
E.prototype.findObjectAt=E.prototype.qe=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);yk(this);for(var d=this.Yb.Um;d.next();){var e=d.value;if(e.visible&&(e=e.qe(a,b,c),null!==e))return e}return null};function Ph(a,b,c,d){void 0===c&&(c=null);void 0===d&&(d=null);yk(a);for(a=a.Yb.Um;a.next();){var e=a.value;if(e.visible&&!e.Gc&&(e=e.qe(b,c,d),null!==e))return e}return null}
E.prototype.findObjectsAt=E.prototype.ft=function(a,b,c,d){void 0===b&&(b=null);void 0===c&&(c=null);d instanceof I||d instanceof J||(d=new J(G));yk(this);for(var e=this.Yb.Um;e.next();){var f=e.value;f.visible&&f.ft(a,b,c,d)}return d};E.prototype.findObjectsIn=E.prototype.Yj=function(a,b,c,d,e){void 0===b&&(b=null);void 0===c&&(c=null);void 0===d&&(d=!1);e instanceof I||e instanceof J||(e=new J(G));yk(this);for(var f=this.Yb.Um;f.next();){var h=f.value;h.visible&&h.Yj(a,b,c,d,e)}return e};
E.prototype.sz=function(a,b,c,d,e,f){var h=new J(G);yk(this);for(var k=this.Yb.Um;k.next();){var l=k.value;l.visible&&l.sz(a,b,c,d,h,e,f)}return h};E.prototype.findObjectsNear=E.prototype.Mm=function(a,b,c,d,e,f){void 0===c&&(c=null);void 0===d&&(d=null);void 0===e&&(e=!0);if(!1!==e&&!0!==e){if(e instanceof I||e instanceof J)f=e;e=!0}f instanceof I||f instanceof J||(f=new J(G));yk(this);for(var h=this.Yb.Um;h.next();){var k=h.value;k.visible&&k.Mm(a,b,c,d,e,f)}return f};
E.prototype.acceptEvent=function(a){var b=this.Sc;this.Sc=this.Nb;this.Nb=b;Gk(this,this,a,b,a instanceof MouseEvent);return b};
function Gk(a,b,c,d,e){d.g=b;d.event=c;e?Hk(a,c,d):(d.hf=b.Nb.hf,d.ca=b.Nb.ca);a=0;c.ctrlKey&&(a+=1);c.altKey&&(a+=2);c.shiftKey&&(a+=4);c.metaKey&&(a+=8);d.od=a;d.button=c.button;c.buttons&&(d.buttons=c.buttons);v.ak&&0===c.button&&c.ctrlKey&&(d.button=2);d.il=!1;d.up=!1;d.Ve=1;d.gl=0;d.zc=!1;d.bubbles=!1;d.timestamp=Date.now();d.wt=!1;d.Ig=c.target.W?c.target.W:null;d.ye=null}
function Ik(a,b,c,d,e){d.g=a;Hk(a,c,d);d.od=0;d.button=0;d.buttons=1;d.il=!0;d.up=!1;d.Ve=1;d.gl=0;d.zc=!1;d.bubbles=!0;d.event=b;d.timestamp=Date.now();d.wt=e;d.Ig=b.target.W?b.target.W:null;d.ye=null;a.qi=d.copy();hh=null}
function Jk(a,b,c,d,e){var f=null;d.g=a;null!==c?((f=window.document.elementFromPoint(c.clientX,c.clientY))&&f.W?f=f.W:(c=b.changedTouches[0],f=a),d.Ig=f,Hk(a,c,d)):null!==a.Sc?(d.ca=a.Sc.ca,d.hf=a.Sc.hf,d.Ig=a.Sc.Ig):null!==a.qi&&(d.ca=a.qi.ca,d.hf=a.qi.hf,d.Ig=a.qi.Ig);d.od=0;d.button=0;d.buttons=1;d.il=!1;d.up=!1;d.Ve=1;d.gl=0;d.zc=!1;d.bubbles=!1;d.event=b;d.timestamp=Date.now();d.wt=e;d.ye=null}
function Kk(a,b){if(a.bubbles)return!0;void 0!==b.stopPropagation&&b.stopPropagation();(void 0===b.touches||2>b.touches.length)&&b.preventDefault();b.cancelBubble=!0;return!1}
E.prototype.zH=function(a){if(!this.W.isEnabled)return!1;var b=this.W.Nb;Gk(this.W,this.W,a,b,!1);b.key=String.fromCharCode(a.which);b.il=!0;switch(a.which){case 8:b.key="Backspace";break;case 33:b.key="PageUp";break;case 34:b.key="PageDown";break;case 35:b.key="End";break;case 36:b.key="Home";break;case 37:b.key="Left";break;case 38:b.key="Up";break;case 39:b.key="Right";break;case 40:b.key="Down";break;case 45:b.key="Insert";break;case 46:b.key="Del";break;case 48:b.key="0";break;case 187:case 61:case 107:b.key=
"Add";break;case 189:case 173:case 109:b.key="Subtract";break;case 27:b.key="Esc"}this.W.doKeyDown();return 187!==a.which&&189!==a.which&&48!==a.which&&107!==a.which&&109!==a.which&&61!==a.which&&173!==a.which||!0!==a.ctrlKey?Kk(b,a):(a.cancelBubble=!0,a.preventDefault(),a.stopPropagation(),!1)};
E.prototype.AH=function(a){if(!this.W.isEnabled)return!1;var b=this.W.Nb;Gk(this.W,this.W,a,b,!1);b.key=String.fromCharCode(a.which);b.up=!0;switch(a.which){case 8:b.key="Backspace";break;case 33:b.key="PageUp";break;case 34:b.key="PageDown";break;case 35:b.key="End";break;case 36:b.key="Home";break;case 37:b.key="Left";break;case 38:b.key="Up";break;case 39:b.key="Right";break;case 40:b.key="Down";break;case 45:b.key="Insert";break;case 46:b.key="Del";break;case 93:a.preventDefault()}this.W.doKeyUp();
return Kk(b,a)};E.prototype.Jq=function(a){var b=this.zb;if(null===b)return new z(0,0);var c=this.Eb,d=this.Jb,b=b.getBoundingClientRect(),c=a.clientX-c/b.width*b.left;a=a.clientY-d/b.height*b.top;return null!==this.me?(a=new z(c,a),Qa(a,this.me),a):new z(c,a)};function Hk(a,b,c){var d=a.zb,e=a.Eb,f=a.Jb,h=0,k=0;null!==d&&(d=d.getBoundingClientRect(),h=b.clientX-e/d.width*d.left,k=b.clientY-f/d.height*d.top);c.hf.m(h,k);null!==a.me?(b=v.Mb(h,k),a.me.Wh(b),c.ca.assign(b),v.v(b)):c.ca.m(h,k)}
E.prototype.invalidateDocumentBounds=E.prototype.qc=function(){this.qj||(this.qj=!0,this.ue(!0))};function Rj(a){a.Kd||yk(a);a.qj&&Dk(a,a.$g())}E.prototype.redraw=E.prototype.$w=function(){this.Pc||this.Kd||(this.pa(),Lk(this),xk(this),this.qc(),this.Uf())};E.prototype.isUpdateRequested=function(){return this.Kf};E.prototype.delayInitialization=function(a){void 0===a&&(a=null);var b=this.Na,c=b.isEnabled;b.Zh();b.isEnabled=!1;Wh(this);this.Qg=!1;b.isEnabled=c;null!==a&&v.setTimeout(a,1)};
E.prototype.requestUpdate=E.prototype.ue=function(a){void 0===a&&(a=!1);if(!0!==this.Kf&&!(this.Pc||!1===a&&this.Kd)){this.Kf=!0;var b=this;requestAnimationFrame(function(){b.Kf&&b.Uf()})}};E.prototype.maybeUpdate=E.prototype.Uf=function(){if(!this.Nn||this.Kf)this.Nn&&(this.Nn=!1),Wh(this)};function Mk(a,b){a.Na.cf||a.Pc||!a.Rl||wk(a)||(b&&yk(a),Ak(a,!1))}
function Wh(a){if(!a.Kd&&(a.Kf=!1,null!==a.Kb)){a.Kd=!0;var b=a.Na,c=a.sC;if(!b.Xn&&0!==c.length){for(var d=c.n,e=d.length,f=0;f<e;f++){var h=d[f];Qk(h,!1);h.P()}c.clear()}c=a.KB;0<c.count&&(c.each(function(a){a.BA()}),c.clear());d=c=!1;b.cf&&(d=!0,c=a.tb,a.tb=!0);b.pc||Ij(a);Mk(a,!1);null!==a.jd&&(a.jd.visible&&!a.Vu&&(Zk(a),a.Vu=!0),!a.jd.visible&&a.Vu&&(a.Vu=!1));yk(a);e=!1;if(!a.Qg||a.vu)a.Qg?$k(a,!a.Mu):(a.Qb("Initial Layout"),!1===b.isEnabled&&b.Zh(),$k(a,!1)),e=!0;a.Mu=!1;yk(a);a.Ky||b.cf||
Rj(a);Mk(a,!0);e&&(a.Qg||al(a),a.Ba("LayoutCompleted"));yk(a);e&&!a.Qg&&(a.Qg=!0,a.wd("Initial Layout"),a.tb||a.la.clear(),v.setTimeout(function(){a.Yh=!1},1));bl(a);Hj(b);a.We();d&&(a.tb=c);a.Kd=!1}}v.u(E,{Tm:null},function(){return this.Qg});
function al(a){var b=a.Yb.n;a.yf(b,b.length,a);a.Dk!==Yg?a.scale=Bk(a,a.Dk):a.Ml!==Yg?a.scale=Bk(a,a.Ml):(b=a.jH,isFinite(b)&&0<b&&(a.scale=b));b=a.iH;if(b.G())a.position=b;else{b=v.L();b.Yt(a.Zd,a.hH);var c=a.rb,c=v.gk(0,0,c.width,c.height),d=v.L();d.Yt(c,a.kH);d.m(b.x-d.x,b.y-d.y);a.position=d;v.Vb(c);v.v(d);v.v(b);Mk(a,!0);Ak(a,!0)}a.Ba("InitialLayoutCompleted");Zk(a)}
function yk(a){if((a.Kd||!a.Na.cf)&&0!==a.fg.count)for(var b=0;23>b;b++){var c=a.fg.i;if(null===c||0===a.fg.count)break;a.fg=new J(G);a.BA(c,a.fg)}}
E.prototype.BA=function(a,b){for(a.reset();a.next();){var c=a.value;!c.Pd()||c instanceof V||!c.isVisible()||(c.ul()?(fj(c,Infinity,Infinity),c.Ec()):b.add(c))}for(a.reset();a.next();)c=a.value,c instanceof V&&c.isVisible()&&cl(this,c);for(a.reset();a.next();)c=a.value,c instanceof W&&c.isVisible()&&(c.ul()?(fj(c,Infinity,Infinity),c.Ec()):b.add(c));for(a.reset();a.next();)c=a.value,c instanceof Tg&&c.isVisible()&&(c.ul()?(fj(c,Infinity,Infinity),c.Ec()):b.add(c))};
function cl(a,b){for(var c=v.jb(),d=v.jb(),e=b.Ic;e.next();){var f=e.value;f.isVisible()&&(f instanceof V?(dl(f)||el(f)||fl(f))&&cl(a,f):f instanceof W?f.T===b||f.Z===b?d.push(f):c.push(f):(fj(f,Infinity,Infinity),f.Ec()))}for(var e=c.length,h=0;h<e;h++)f=c[h],fj(f,Infinity,Infinity),f.Ec();v.sa(c);fj(b,Infinity,Infinity);b.Ec();e=d.length;for(h=0;h<e;h++)f=d[h],fj(f,Infinity,Infinity),f.Ec();v.sa(d)}E.prototype.yf=function(a,b,c,d){var e=this.Na;if(this.Ek||e.cf)for(e=0;e<b;e++)a[e].yf(c,d)};
E.prototype.We=function(a,b){void 0===a&&(a=this.kf);void 0===b&&(b=null);null===this.Kb&&v.k("No div specified");var c=this.zb;null===c&&v.k("No canvas specified");var d=this.Na;if(!d.pc&&(gl(this),"0"!==this.Kb.style.opacity)){var e=a!==this.kf,f=this.Yb.n,h=f.length,k=this;this.yf(f,h,k);if(e)Wc(a,!0),xk(this);else if(!this.je&&null===b&&!d.rj)return;var h=this.kb,l=this.Tb,m=Math.round(h.x*l)/l,n=Math.round(h.y*l)/l,d=this.me;d.reset();1!==l&&d.scale(l);0===h.x&&0===h.y||d.translate(-m,-n);l=
this.rd;v.Sm?(c.width=c.width,Wc(a,!0),a.scale(l,l)):(a.setTransform(1,0,0,1,0,0),a.scale(l,l),a.clearRect(0,0,this.Eb,this.Jb));a.setTransform(1,0,0,1,0,0);a.scale(l,l);a.transform(d.m11,d.m12,d.m21,d.m22,d.dx,d.dy);c=null!==b?function(c){var d=a,e=b;if(c.visible&&0!==c.Rc){var f=c.wb.n,h=f.length;if(0!==h){1!==c.Rc&&(d.globalAlpha=c.Rc);c=c.Bv;c.length=0;for(var l=k.scale,m=0;m<h;m++){var n=f[m];if(Wj(n)&&!e.contains(n)){if(n instanceof W){var x=n;x.jc&&c.push(x);if(!1===x.Lf)continue}x=n.U;1<x.width*
l||1<x.height*l?n.We(d,k):Yj(n,d)}}d.globalAlpha=1}}}:function(b){b.We(a,k)};hl(this,a);h=f.length;for(m=0;m<h;m++)a.setTransform(1,0,0,1,0,0),a.scale(l,l),a.transform(d.m11,d.m12,d.m21,d.m22,d.dx,d.dy),c(f[m]);this.Ck?this.Ck.Gl(this)&&this.Yx():this.Jq=function(){return new z(0,0)};e?(Wc(this.kf,!0),xk(this)):this.je=this.Ek=!1}};
function il(a,b,c,d,e){null===a.Kb&&v.k("No div specified");var f=a.zb;null===f&&v.k("No canvas specified");if(!a.Na.pc){var h=a.kf;if(a.je){gl(a);var k=a.rd;v.Sm?(f.width=f.width,Wc(h,!0)):(h.setTransform(1,0,0,1,0,0),h.clearRect(0,0,a.Eb*k,a.Jb*k));h.pt=!1;h.drawImage(a.Xx.xd,0<d?0:Math.round(-d),0<e?0:Math.round(-e));e=a.kb;var f=a.Tb,l=Math.round(e.x*f)/f,m=Math.round(e.y*f)/f;d=a.me;d.reset();1!==f&&d.scale(f);0===e.x&&0===e.y||d.translate(-l,-m);h.save();h.beginPath();e=c.length;for(f=0;f<e;f++)l=
c[f],0!==l.width&&0!==l.height&&h.rect(Math.floor(l.x),Math.floor(l.y),Math.ceil(l.width),Math.ceil(l.height));h.clip();h.setTransform(1,0,0,1,0,0);h.scale(k,k);h.transform(d.m11,d.m12,d.m21,d.m22,d.dx,d.dy);c=a.Yb.n;e=c.length;a.yf(c,e,a);hl(a,h);for(f=0;f<e;f++)Zj(c[f],h,a,b);h.restore();Wc(h,!0);a.Ck?a.Ck.Gl(a)&&a.Yx():a.Jq=function(){return new z(0,0)};a.Ek=!1;a.je=!1;a.eA()}}}
function jl(a,b,c,d,e,f,h,k,l,m){null===a.Kb&&v.k("No div specified");null===a.zb&&v.k("No canvas specified");void 0===h&&(h=null);void 0===k&&(k=null);void 0===l&&(l=!1);void 0===m&&(m=!1);gl(a);Wc(a.kf,!0);xk(a);a.py=!0;var n=new B(f.x,f.y,d.width/e,d.height/e),p=n.copy();p.fw(c);Zk(a,p);yk(a);var p=a.Yb.n,q=p.length;a.yf(p,q,a,n);n=a.rd;b.setTransform(1,0,0,1,0,0);b.scale(n,n);b.clearRect(0,0,d.width,d.height);null!==k&&""!==k&&(b.fillStyle=k,b.fillRect(0,0,d.width,d.height));d=v.ph();d.reset();
d.translate(c.left,c.top);d.scale(e);0===f.x&&0===f.y||d.translate(-f.x,-f.y);b.setTransform(d.m11,d.m12,d.m21,d.m22,d.dx,d.dy);v.Ze(d);hl(a,b);if(null!==h){var r=new J(G);c=h.i;for(c.reset();c.next();)e=c.value,!1===m&&"Grid"===e.layer.name||null===e||r.add(e);c=function(c){var d=l;if(c.visible&&0!==c.Rc&&(void 0===d&&(d=!0),d||!c.Gc)){var d=c.wb.n,e=d.length;if(0!==e){1!==c.Rc&&(b.globalAlpha=c.Rc);c=c.Bv;c.length=0;for(var f=a.scale,h=0;h<e;h++){var k=d[h];if(Wj(k)&&r.contains(k)){if(k instanceof
W){var m=k;m.jc&&c.push(m);if(!1===m.Lf)continue}m=k.U;1<m.width*f||1<m.height*f?k.We(b,a):Yj(k,b)}}b.globalAlpha=1}}}}else if(!l&&m){var s=a.mt.Y,t=s.layer;c=function(c){c===t?s.We(b,a):c.We(b,a,l,m)}}else c=function(c){c.We(b,a,l,m)};for(e=0;e<q;e++)c(p[e]);a.py=!1;a.Ck?a.Ck.Gl(a)&&a.Yx():a.Jq=function(){return new z(0,0)};Wc(a.kf,!0);xk(a);a.yf(p,q,a);Zk(a)}E.prototype.getRenderingHint=E.prototype.Si=function(a){return this.Fj[a]};
E.prototype.setRenderingHint=E.prototype.zI=function(a,b){this.Fj[a]=b;this.$w()};E.prototype.resetRenderingHints=E.prototype.IE=function(){this.Fj=new ta;this.Fj.drawShadows=!0;this.Fj.textGreeking=!0;this.Fj.viewportOptimizations=v.fE?!1:!0;this.Fj.temporaryPixelRatio=!0;this.Fj.pictureRatioOptimization=!0};function hl(a,b){var c=a.Fj;if(null!==c){if(void 0!==c.imageSmoothingEnabled){var d=!!c.imageSmoothingEnabled;b.pt=d;b.lK=d}c=c.defaultFont;void 0!==c&&null!==c&&(b.font=c)}}
E.prototype.getInputOption=E.prototype.ap=function(a){return this.Un[a]};E.prototype.setInputOption=function(a,b){this.Un[a]=b};E.prototype.resetInputOptions=E.prototype.HE=function(){this.Un=new ta;this.Un.extraTouchArea=10;this.Un.extraTouchThreshold=10;this.Un.hasGestureZoom=!0};E.prototype.setProperties=function(a){v.Xt(this,a)};function bl(a){if(0===a.la.rh&&0!==a.xk.count){for(;0<a.xk.count;){var b=a.xk;a.xk=new na(W,B);for(b=b.i;b.next();){var c=b.key;Bi(c,b.value);c.re()}}a.pa()}}
E.prototype.pa=function(a){void 0===a&&(a=null);if(null===a)this.je=!0,this.ue();else{var b=this.rb;null!==a&&a.G()&&b.xg(a)&&(this.je=!0,this.ue())}for(b=this.Cv.i;b.next();)b.value.pa(a)};
E.prototype.$D=function(a,b){if(!0!==this.je){this.je=!0;var c=!0===this.Si("temporaryPixelRatio");if(!0===this.Si("viewportOptimizations")&&this.jA!==kl&&this.PE.xw(0,0,0,0)&&b.width===a.width&&b.height===a.height){var d=this.scale,e=v.ag(),f=Math.max(a.x,b.x),h=Math.max(a.y,b.y),k=Math.min(a.x+a.width,b.x+b.width),l=Math.min(a.y+a.height,b.y+b.height);e.x=f;e.y=h;e.width=Math.max(0,k-f)*d;e.height=Math.max(0,l-h)*d;if(0<e.width&&0<e.height){if(!this.Kd&&(this.Kf=!1,null!==this.Kb)){this.Kd=!0;bl(this);
this.Zd.G()||Dk(this,this.$g());var m=this.zb;if(null!==m){var n=this.rd,h=this.Eb*n,k=this.Jb*n,f=this.scale*n,d=Math.round(Math.round(b.x*f)-Math.round(a.x*f)),f=Math.round(Math.round(b.y*f)-Math.round(a.y*f)),l=this.Xx,p=this.CB;l.width!==h&&(l.width=h);l.height!==k&&(l.height=k);p.clearRect(0,0,h,k);var l=190*this.rd,q=70*this.rd,r=Math.max(d,0),s=Math.max(f,0),t=Math.floor(h-r),u=Math.floor(k-s);p.pt=!1;p.drawImage(m.xd,r,s,t,u,0,0,t,u);this.Ck.Gl(this)&&p.clearRect(0,0,l,q);var m=v.jb(),p=v.jb(),
u=Math.abs(d),t=Math.abs(f),y=0===r?0:h-u,r=v.Mb(y,0),u=v.Mb(u+y,k);p.push(new B(Math.min(r.x,u.x),Math.min(r.y,u.y),Math.abs(r.x-u.x),Math.abs(r.y-u.y)));var w=this.me;w.reset();w.scale(n,n);1!==this.Tb&&w.scale(this.Tb);n=this.kb;(0!==n.x||0!==n.y)&&isFinite(n.x)&&isFinite(n.y)&&w.translate(-n.x,-n.y);Qa(r,w);Qa(u,w);m.push(new B(Math.min(r.x,u.x),Math.min(r.y,u.y),Math.abs(r.x-u.x),Math.abs(r.y-u.y)));y=0===s?0:k-t;r.m(0,y);u.m(h,t+y);p.push(new B(Math.min(r.x,u.x),Math.min(r.y,u.y),Math.abs(r.x-
u.x),Math.abs(r.y-u.y)));Qa(r,w);Qa(u,w);m.push(new B(Math.min(r.x,u.x),Math.min(r.y,u.y),Math.abs(r.x-u.x),Math.abs(r.y-u.y)));this.Ck.Gl(this)&&(h=0<d?0:-d,k=0<f?0:-f,r.m(h,k),u.m(l+h,q+k),p.push(new B(Math.min(r.x,u.x),Math.min(r.y,u.y),Math.abs(r.x-u.x),Math.abs(r.y-u.y))),Qa(r,w),Qa(u,w),m.push(new B(Math.min(r.x,u.x),Math.min(r.y,u.y),Math.abs(r.x-u.x),Math.abs(r.y-u.y))));v.v(r);v.v(u);Mk(this,!1);il(this,m,p,d,f);v.sa(m);v.sa(p);this.Kd=!1}}}else this.Uf();v.Vb(e);c&&(this.Mj=1,this.Uf(),
this.Mj=null,this.eA())}else c?(this.Mj=1,this.Uf(),this.Mj=null,this.eA()):this.Uf()}};function Lk(a){!1===a.Rl&&(a.Rl=!0)}function xk(a){!1===a.Ek&&(a.Ek=!0)}function gl(a){!1!==a.Oq&&(a.Oq=!1,ll(a,a.Eb,a.Jb))}function ll(a,b,c){var d=a.zb,e=a.rd,f=b*e,e=c*e;if(d.width!==f||d.height!==e)d.width=f,d.height=e,d.style.width=b+"px",d.style.height=c+"px",a.je=!0,Wc(a.kf,!0)}function bh(a){var b=a.Eb,c=a.Jb;a=a.zb.getBoundingClientRect();return v.Mb(b/a.width*a.left,c/a.height*a.top)}
function wk(a){var b=a.zb;if(null===b)return!0;var c=a.Kb,d=a.Eb,e=a.Jb,f=a.XC.copy();if(!f.G())return!0;var h=!1,k=a.Bk?a.vd:0,l=a.pj?a.vd:0,m=c.clientWidth||d+k,c=c.clientHeight||e+l;if(m!==d+k||c!==e+l)a.Bk=!1,a.pj=!1,l=k=0,a.Eb=m,a.Jb=c,h=a.Oq=!0;a.Rl=!1;var n=a.rb,p=a.Zd,m=p.width,c=p.height,q=n.width,r=n.height,s=p.x,t=n.x,u=p.right,y=n.right+k,w=p.y,x=n.y,p=p.bottom,n=n.bottom+l,A="1px",H="1px",C=a.scale;a.jA===fk&&m<q+k&&c<r+l||(a.Az&&a.ne&&(A=1,s+1<t&&(A=Math.max((t-s)*C+a.Eb,A)),u>y+1&&
(A=Math.max((u-y)*C+a.Eb,A)),q+k+1<m&&(A=Math.max((m-q+k)*C+a.Eb,A)),A+="px"),a.Bz&&a.oe&&(H=1,w+1<x&&(H=Math.max((x-w)*C+a.Jb,H)),p>n+1&&(H=Math.max((p-n)*C+a.Jb,H)),r+l+1<c&&(H=Math.max((c-r+l)*C+a.Jb,H)),H+="px"));k="1px"!==A;l="1px"!==H;k&&l||!k&&!l||(l&&(y-=a.vd),k&&(n-=a.vd),a.Az&&a.ne&&(A=1,s+1<t&&(A=Math.max((t-s)*C+a.Eb,A)),u>y+1&&(A=Math.max((u-y)*C+a.Eb,A)),q+1<m&&(A=Math.max((m-q)*C+a.Eb,A)),A+="px"),k="1px"!==A,l=k!==a.pj?a.Jb-a.vd:a.Jb,a.Bz&&a.oe&&(H=1,w+1<x&&(H=Math.max((x-w)*C+l,H)),
p>n+1&&(H=Math.max((p-n)*C+l,H)),r+1<c&&(H=Math.max((c-r)*C+l,H)),H+="px"),l="1px"!==H);if(a.Lv&&k===a.pj&&l===a.Bk)return d===a.Eb&&e===a.Jb||a.Uf(),!1;k!==a.pj&&(a.Jb="1px"===A?a.Jb+a.vd:Math.max(a.Jb-a.vd,1),h=!0);a.pj=k;a.cs.style.width=A;l!==a.Bk&&(a.Eb="1px"===H?a.Eb+a.vd:Math.max(a.Eb-a.vd,1),h=!0,a.Uq&&(k=v.L(),l?(b.style.left=a.vd+"px",a.position=k.m(a.kb.x+a.vd/a.scale,a.kb.y)):(b.style.left="0px",a.position=k.m(a.kb.x-a.vd/a.scale,a.kb.y)),v.v(k)));a.Bk=l;a.cs.style.height=H;b=a.Pv;k=b.scrollLeft;
a.Az&&a.ne&&(q+1<m?k=(a.position.x-s)*C:s+1<t?k=b.scrollWidth-b.clientWidth:u>y+1&&(k=a.position.x*C));if(a.Uq)switch(a.Gy){case "negative":k=-(b.scrollWidth-k-b.clientWidth);break;case "reverse":k=b.scrollWidth-k-b.clientWidth}b.scrollLeft=k;a.Bz&&a.oe&&(r+1<c?b.scrollTop=(a.position.y-w)*C:w+1<x?b.scrollTop=b.scrollHeight-b.clientHeight:p>n+1&&(b.scrollTop=a.position.y*C));h&&(a.Oq=!0);m=a.Eb;c=a.Jb;b.style.width=m+(a.Bk?a.vd:0)+"px";b.style.height=c+(a.pj?a.vd:0)+"px";a.Hy=!1;return d!==m||e!==
c||a.Na.pc?(n=a.rb,a.Lt(f,n,h),!1):!0}
E.prototype.add=E.prototype.add=function(a){v.A(a,R,E,"add:part");var b=a.g;if(b!==this){null!==b&&v.k("Cannot add part "+a.toString()+" to "+this.toString()+". It is already a part of "+b.toString());this.Rp&&(a.Ik="Tool");var c=a.Tf,b=this.dt(c);null===b&&(b=this.dt(""));null===b&&v.k('Cannot add a Part when unable find a Layer named "'+c+'" and there is no default Layer');a.layer!==b&&(c=b.ep(99999999,a,a.g===this),0<=c&&this.Lc(Cf,"parts",b,null,a,null,c),b.Gc||this.qc(),a.H(ml),c=a.zt,null!==
c&&c(a,null,b))}};
E.prototype.ep=function(a){if(a instanceof S){if(this.mo.add(a),a instanceof V){var b=a.Sa;null===b?this.Uk.add(a):b.jo.add(a);b=a.Pb;null!==b&&(b.g=this)}}else a instanceof W?this.eo.add(a):a instanceof Tg||this.wb.add(a);var c=this;nl(a,function(a){ol(c,a)});a.Gb&&a.P();b=a.data;null!==b&&(a instanceof Tg||(a instanceof W?this.wk.add(b,a):this.ki.add(b,a)),nl(a,function(a){pl(c,a)}));!0!==el(a)&&!0!==fl(a)||this.fg.add(a);ql(a,!0,this);rl(a)?(a.U.G()&&this.pa(ak(a,a.U)),this.qc()):a.isVisible()&&
a.U.G()&&this.pa(ak(a,a.U));this.ue()};
E.prototype.Le=function(a){a.Vs();if(a instanceof S){if(this.mo.remove(a),a instanceof V){var b=a.Sa;null===b?this.Uk.remove(a):b.jo.remove(a);b=a.Pb;null!==b&&(b.g=null)}}else a instanceof W?this.eo.remove(a):a instanceof Tg||this.wb.remove(a);var c=this;nl(a,function(a){sl(c,a)});b=a.data;null!==b&&(a instanceof Tg||(a instanceof W?this.wk.remove(b):this.ki.remove(b)),nl(a,function(a){tl(c,a)}));this.fg.remove(a);rl(a)?(a.U.G()&&this.pa(ak(a,a.U)),this.qc()):a.isVisible()&&a.U.G()&&this.pa(ak(a,
a.U));this.ue()};E.prototype.remove=E.prototype.remove=function(a){v.A(a,R,E,"remove:part");a.hb=!1;a.fh=!1;var b=a.layer;if(null!==b&&b.g===this){a.H(ul);a.Lm();var c=b.Le(-1,a,!1);0<=c&&this.Lc(Df,"parts",b,a,null,c,null);c=a.zt;null!==c&&c(a,b,null)}};E.prototype.removeParts=E.prototype.gA=function(a,b){if(v.isArray(a))for(var c=v.Va(a),d=0;d<c;d++){var e=v.Pa(a,d);b&&!e.canDelete()||this.remove(e)}else for(e=new J(R),e.Tc(a),c=e.i;c.next();)e=c.value,b&&!e.canDelete()||this.remove(e)};
E.prototype.copyParts=E.prototype.Ro=function(a,b,c){return this.nb.Ro(a,b,c)};E.prototype.moveParts=E.prototype.moveParts=function(a,b,c){v.A(b,z,E,"moveParts:offset");var d=this.Za;if(null!==d){d=d.$d;null===d&&(d=new Xg,d.Mc(this));var e=new na(R,Object);if(null!==a)a=a.i;else{for(a=this.dk;a.next();)uh(d,e,a.value,c);for(a=this.Ag;a.next();)uh(d,e,a.value,c);a=this.links}for(;a.next();)uh(d,e,a.value,c);d.moveParts(e,b,c)}};
function vl(a,b,c){v.A(b,Vf,E,"addLayer:layer");null!==b.g&&b.g!==a&&v.k("Cannot share a Layer with another Diagram: "+b+" of "+b.g);null===c?null!==b.g&&v.k("Cannot add an existing Layer to this Diagram again: "+b):(v.A(c,Vf,E,"addLayer:existingLayer"),c.g!==a&&v.k("Existing Layer must be in this Diagram: "+c+" not in "+c.g),b===c&&v.k("Cannot move a Layer before or after itself: "+b));if(b.g!==a){b=b.name;a=a.Yb;c=a.count;for(var d=0;d<c;d++)a.da(d).name===b&&v.k("Cannot add Layer with the name '"+
b+"'; a Layer with the same name is already present in this Diagram.")}}E.prototype.addLayer=E.prototype.Ns=function(a){vl(this,a,null);a.Mc(this);var b=this.Yb,c=b.count-1;if(!a.Gc)for(;0<=c&&b.da(c).Gc;)c--;b.Ad(c+1,a);null!==this.Vd&&this.Lc(Cf,"layers",this,null,a,null,c+1);this.pa();this.qc()};
E.prototype.addLayerBefore=E.prototype.aG=function(a,b){vl(this,a,b);a.Mc(this);var c=this.Yb,d=c.indexOf(a);0<=d&&(c.remove(a),null!==this.Vd&&this.Lc(Df,"layers",this,a,null,d,null));for(var e=c.count,f=0;f<e;f++)if(c.da(f)===b){c.Ad(f,a);break}null!==this.Vd&&this.Lc(Cf,"layers",this,null,a,null,f);this.pa();0>d&&this.qc()};
E.prototype.addLayerAfter=function(a,b){vl(this,a,b);a.Mc(this);var c=this.Yb,d=c.indexOf(a);0<=d&&(c.remove(a),null!==this.Vd&&this.Lc(Df,"layers",this,a,null,d,null));for(var e=c.count,f=0;f<e;f++)if(c.da(f)===b){c.Ad(f+1,a);break}null!==this.Vd&&this.Lc(Cf,"layers",this,null,a,null,f+1);this.pa();0>d&&this.qc()};
E.prototype.removeLayer=function(a){v.A(a,Vf,E,"removeLayer:layer");a.g!==this&&v.k("Cannot remove a Layer from another Diagram: "+a+" of "+a.g);if(""!==a.name){var b=this.Yb,c=b.indexOf(a);if(b.remove(a)){for(b=a.wb.copy().i;b.next();){var d=b.value,e=d.Tf;d.Tf=e!==a.name?e:""}null!==this.Vd&&this.Lc(Df,"layers",this,a,null,c,null);this.pa();this.qc()}}};E.prototype.findLayer=E.prototype.dt=function(a){for(var b=this.Ow;b.next();){var c=b.value;if(c.name===a)return c}return null};
E.prototype.addModelChangedListener=E.prototype.cG=function(a){v.j(a,"function",E,"addModelChangedListener:listener");null===this.Rg&&(this.Rg=new I("function"));this.Rg.add(a);this.ba.ym(a)};E.prototype.removeModelChangedListener=function(a){v.j(a,"function",E,"removeModelChangedListener:listener");null!==this.Rg&&(this.Rg.remove(a),0===this.Rg.count&&(this.Rg=null));this.ba.Ot(a)};
E.prototype.addChangedListener=E.prototype.ym=function(a){v.j(a,"function",E,"addChangedListener:listener");null===this.ei&&(this.ei=new I("function"));this.ei.add(a)};E.prototype.removeChangedListener=E.prototype.Ot=function(a){v.j(a,"function",E,"removeChangedListener:listener");null!==this.ei&&(this.ei.remove(a),0===this.ei.count&&(this.ei=null))};
E.prototype.nw=function(a){this.tb||this.la.WD(a);a.wc!==Bf&&(this.Yh=!0);if(null!==this.ei){var b=this.ei,c=b.length;if(1===c)b=b.da(0),b(a);else if(0!==c)for(var d=b.dc(),e=0;e<c;e++)b=d[e],b(a)}};E.prototype.raiseChangedEvent=E.prototype.Lc=function(a,b,c,d,e,f,h){void 0===f&&(f=null);void 0===h&&(h=null);var k=new zf;k.g=this;k.wc=a;k.propertyName=b;k.object=c;k.oldValue=d;k.Bg=f;k.newValue=e;k.zg=h;this.nw(k)};
E.prototype.raiseChanged=E.prototype.h=function(a,b,c,d,e){this.Lc(Af,a,this,b,c,d,e)};v.u(E,{Na:"animationManager"},function(){return this.QA});v.u(E,{la:"undoManager"},function(){return this.Vd.la});v.defineProperty(E,{tb:"skipsUndoManager"},function(){return this.Gi},function(a){v.j(a,"boolean",E,"skipsUndoManager");this.Gi=a;this.Vd.Gi=a});v.defineProperty(E,{mz:"delaysLayout"},function(){return this.Tx},function(a){this.Tx=a});
E.prototype.Dm=function(a,b){if(null!==a&&a.g===this){var c=this.we;try{this.we=!0;var d=a.wc,e;if(d===Af){var f=a.object,h=a.propertyName,k=a.ja(b);v.Ka(f,h,k);if(f instanceof G){var l,m=f.Y;null!==m&&m.Od()}this.Yh=!0}else if(d===Cf){var n=a.object,p=a.zg,f=a.newValue;if(n instanceof D)if("number"===typeof p&&f instanceof G){l=f;var q=n;b?q.Le(p):q.Ad(p,l);m=n.Y;null!==m&&m.Od()}else{if("number"===typeof p&&f instanceof tg){var r=f,q=n;b?r.ce?q.GE(p):q.CE(p):(e=r.ce?q.zd(r.index):q.yd(r.index),
e.Zs(r))}}else if(n instanceof Vf){var s=!0===a.Bg;if("number"===typeof p&&f instanceof R){var m=f,t=n;b?(m.Od(),t.Le(s?p:-1,m,s)):t.ep(p,m,s)}}else if(n instanceof E){if("number"===typeof p&&f instanceof Vf){var u=f;b?this.Yb.Vc(p):(d=u,d.Mc(this),this.Yb.Ad(p,d))}}else v.k("unknown ChangedEvent.Insert object: "+a.toString());this.Yh=!0}else d===Df?(n=a.object,p=a.Bg,f=a.oldValue,n instanceof D?"number"===typeof p&&f instanceof G?(q=n,b?q.Ad(p,f):q.Le(p)):"number"===typeof p&&f instanceof tg&&(r=
f,q=n,b?(e=r.ce?q.zd(r.index):q.yd(r.index),e.Zs(r)):r.ce?q.GE(p):q.CE(p)):n instanceof Vf?(s=!0===a.zg,"number"===typeof p&&f instanceof R&&(m=f,t=n,b?t.ep(p,m,s):(m.Od(),t.Le(s?p:-1,m,s)))):n instanceof E?"number"===typeof p&&f instanceof Vf&&(u=f,b?(d=u,d.Mc(this),this.Yb.Ad(p,d)):this.Yb.Vc(p)):v.k("unknown ChangedEvent.Remove object: "+a.toString()),this.Yh=!0):d!==Bf&&v.k("unknown ChangedEvent: "+a.toString())}finally{this.we=c}}};E.prototype.startTransaction=E.prototype.Qb=function(a){return this.la.Qb(a)};
E.prototype.commitTransaction=E.prototype.wd=function(a){return this.la.wd(a)};E.prototype.rollbackTransaction=E.prototype.Ap=function(){return this.la.Ap()};E.prototype.updateAllTargetBindings=E.prototype.qF=function(a){void 0===a&&(a="");for(var b=this.dk;b.next();)b.value.Hb(a);for(b=this.Ag;b.next();)b.value.Hb(a);for(b=this.links;b.next();)b.value.Hb(a)};
E.prototype.updateAllRelationshipsFromData=E.prototype.SI=function(){if(0<arguments.length){for(var a=this.ba,b=new J,c=a.Yf,d=0;d<c.length;d++){var e=c[d];b.add(e)}var f=[];this.Ag.each(function(a){null===a.data||b.contains(a.data)||f.push(a.data)});this.dk.each(function(a){null===a.data||b.contains(a.data)||f.push(a.data)});f.forEach(function(b){og(a,b,!1)});for(d=0;d<c.length;d++){var e=c[d],h=this.dh(e);null===h&&ng(a,e,!1)}if(a instanceof Q){for(var k=new J,c=a.yg,d=0;d<c.length;d++)e=c[d],k.add(e);
var l=[];this.links.each(function(a){null===a.data||k.contains(a.data)||l.push(a.data)});l.forEach(function(b){yg(a,b,!1)});for(d=0;d<c.length;d++)e=c[d],h=this.Pf(e),null===h&&xg(a,e,!1)}}for(d=this.dk;d.next();)h=d.value,h.updateRelationshipsFromData();for(d=this.Ag;d.next();)d.value.updateRelationshipsFromData();for(d=this.links;d.next();)h=d.value,h.updateRelationshipsFromData()};
function wl(a,b,c){if(a.Pc||a.Kd)a.Tb=c,c=a.Na,c.pc&&Vj(c,b,a.Tb);else if(a.Pc=!0,null===a.zb)a.Tb=c;else{var d=a.rb.copy(),e=a.Eb,f=a.Jb;d.width=a.Eb/b;d.height=a.Jb/b;var h=a.Hl.x,k=a.Hl.y,l=a.vD;isNaN(h)&&(l.Ui()?l.cp(Hb)?h=0:l.cp(Nb)&&(h=e-1):h=l.cd()?l.x*(e-1):e/2);isNaN(k)&&(l.Ui()?l.cp(Gb)?k=0:l.cp(Ob)&&(k=f-1):k=l.cd()?l.y*(f-1):f/2);null!==a.OE&&(c=a.OE(a,c));c<a.hh&&(c=a.hh);c>a.gh&&(c=a.gh);e=v.Mb(a.kb.x+h/b-h/c,a.kb.y+k/b-k/c);a.position=e;v.v(e);a.Tb=c;a.Lt(d,a.rb);a.Pc=!1;Ak(a,!1);c=
a.Na;c.pc&&Vj(c,b,a.Tb);a.pa();Lk(a)}}E.prototype.Lt=function(a,b,c){if(!a.K(b)){void 0===c&&(c=!1);c||Lk(this);xk(this);var d=this.Pb;null===d||!d.Lw||this.al!==Yg||c||a.width===b.width&&a.height===b.height||d.H();d=this.Wa;!0===this.Tl&&d instanceof Rg&&(this.N.ca=this.oF(this.N.hf),Dj(d,this));this.Pc||this.$D(a,b);Zk(this);this.Ba("ViewportBoundsChanged",c?v.fj:null,a)}};
function Zk(a,b){void 0===b&&(b=null);var c=a.jd;if(null!==c&&c.visible){for(var d=v.El(),e=1,f=1,h=c.za.n,k=h.length,l=0;l<k;l++){var m=h[l],n=m.interval;2>n||(xl(m.Ab)?f=f*n/pe(f,n):e=e*n/pe(e,n))}h=c.Ew;d.m(f*h.width,e*h.height);h=f=l=k=0;if(null!==b)k=b.width,l=b.height,f=b.x,h=b.y;else{e=v.ag();f=a.rb;e.m(f.x,f.y,f.width,f.height);for(h=a.Cv.i;h.next();)f=h.value.rb,f.G()&&ab(e,f.x,f.y,f.width,f.height);if(!e.G()){v.Vb(e);return}k=e.width;l=e.height;f=e.x;h=e.y;v.Vb(e)}c.width=k+2*d.width;c.height=
l+2*d.height;e=v.L();Pa(f,h,0,0,d.width,d.height,e);e.offset(-d.width,-d.height);v.Zj(d);c.Y.location=e;v.v(e)}}E.prototype.clearSelection=E.prototype.ow=function(){var a=0<this.selection.count;a&&this.Ba("ChangingSelection");Wg(this);a&&this.Ba("ChangedSelection")};function Wg(a){a=a.selection;if(0<a.count){for(var b=a.dc(),c=b.length,d=0;d<c;d++)b[d].hb=!1;a.Oa();a.clear();a.freeze()}}
E.prototype.select=E.prototype.select=function(a){null!==a&&(v.A(a,R,E,"select:part"),a.layer.g===this&&(!a.hb||1<this.selection.count)&&(this.Ba("ChangingSelection"),Wg(this),a.hb=!0,this.Ba("ChangedSelection")))};
E.prototype.selectCollection=E.prototype.TE=function(a){this.Ba("ChangingSelection");Wg(this);if(v.isArray(a))for(var b=v.Va(a),c=0;c<b;c++){var d=v.Pa(a,c);d instanceof R||v.k("Diagram.selectCollection given something that is not a Part: "+d);d.hb=!0}else for(a=a.i;a.next();)d=a.value,d instanceof R||v.k("Diagram.selectCollection given something that is not a Part: "+d),d.hb=!0;this.Ba("ChangedSelection")};
E.prototype.clearHighlighteds=E.prototype.nD=function(){var a=this.Qm;if(0<a.count){for(var b=a.dc(),c=b.length,d=0;d<c;d++)b[d].fh=!1;a.Oa();a.clear();a.freeze()}};E.prototype.highlight=function(a){null!==a&&a.layer.g===this&&(v.A(a,R,E,"highlight:part"),!a.fh||1<this.Qm.count)&&(this.nD(),a.fh=!0)};
E.prototype.highlightCollection=function(a){this.nD();if(v.isArray(a))for(var b=v.Va(a),c=0;c<b;c++){var d=v.Pa(a,c);d instanceof R||v.k("Diagram.highlightCollection given something that is not a Part: "+d);d.fh=!0}else for(a=a.i;a.next();)d=a.value,d instanceof R||v.k("Diagram.highlightCollection given something that is not a Part: "+d),d.fh=!0};
E.prototype.scroll=E.prototype.scroll=function(a,b,c){void 0===c&&(c=1);var d="up"===b||"down"===b,e=0;if("pixel"===a)e=c;else if("line"===a)e=c*(d?this.Ut:this.Tt);else if("page"===a)a=d?this.rb.height:this.rb.width,a*=this.scale,0!==a&&(e=Math.max(a-(d?this.Ut:this.Tt),0),e*=c);else{if("document"===a){e=this.Zd;d=this.rb;c=v.L();"up"===b?this.position=c.m(d.x,e.y):"left"===b?this.position=c.m(e.x,d.y):"down"===b?this.position=c.m(d.x,e.bottom-d.height):"right"===b&&(this.position=c.m(e.right-d.width,
d.y));v.v(c);return}v.k("scrolling unit must be 'pixel', 'line', 'page', or 'document', not: "+a)}e/=this.scale;c=this.position.copy();"up"===b?c.y=this.position.y-e:"down"===b?c.y=this.position.y+e:"left"===b?c.x=this.position.x-e:"right"===b?c.x=this.position.x+e:v.k("scrolling direction must be 'up', 'down', 'left', or 'right', not: "+b);this.position=c};E.prototype.scrollToRect=E.prototype.tI=function(a){var b=this.rb;b.Wj(a)||(a=a.bl,a.x-=b.width/2,a.y-=b.height/2,this.position=a)};
E.prototype.centerRect=E.prototype.kG=function(a){var b=this.rb;a=a.bl;a.x-=b.width/2;a.y-=b.height/2;this.position=a};E.prototype.transformDocToView=E.prototype.mF=function(a){var b=this.me;b.reset();1!==this.Tb&&b.scale(this.Tb);var c=this.kb;(0!==c.x||0!==c.y)&&isFinite(c.x)&&isFinite(c.y)&&b.translate(-c.x,-c.y);return a.copy().transform(this.me)};
E.prototype.transformViewToDoc=E.prototype.oF=function(a){var b=this.me;b.reset();1!==this.Tb&&b.scale(this.Tb);var c=this.kb;(0!==c.x||0!==c.y)&&isFinite(c.x)&&isFinite(c.y)&&b.translate(-c.x,-c.y);return Qa(a.copy(),this.me)};var Yg;E.None=Yg=v.p(E,"None",0);var Ek;E.Uniform=Ek=v.p(E,"Uniform",1);var Fk;E.UniformToFill=Fk=v.p(E,"UniformToFill",2);var $h;E.CycleAll=$h=v.p(E,"CycleAll",10);var di;E.CycleNotDirected=di=v.p(E,"CycleNotDirected",11);var fi;
E.CycleNotDirectedFast=fi=v.p(E,"CycleNotDirectedFast",12);var ri;E.CycleNotUndirected=ri=v.p(E,"CycleNotUndirected",13);var ai;E.CycleDestinationTree=ai=v.p(E,"CycleDestinationTree",14);var ci;E.CycleSourceTree=ci=v.p(E,"CycleSourceTree",15);var fk;E.DocumentScroll=fk=v.p(E,"DocumentScroll",1);var kl;E.InfiniteScroll=kl=v.p(E,"InfiniteScroll",2);var hk;E.TreeParentCollapsed=hk=v.p(E,"TreeParentCollapsed",1);E.AllParentsCollapsed=v.p(E,"AllParentsCollapsed",2);
v.defineProperty(E,{TI:"validCycle"},function(){return this.bw},function(a){var b=this.bw;b!==a&&(v.pb(a,E,E,"validCycle"),this.bw=a,this.h("validCycle",b,a))});v.u(E,{Ow:"layers"},function(){return this.Yb.i});v.defineProperty(E,{vf:"isModelReadOnly"},function(){var a=this.Vd;return null===a?!1:a.ab},function(a){var b=this.Vd;null!==b&&(b.ab=a)});
v.defineProperty(E,{ab:"isReadOnly"},function(){return this.si},function(a){var b=this.si;b!==a&&(v.j(a,"boolean",E,"isReadOnly"),this.si=a,this.h("isReadOnly",b,a))});v.defineProperty(E,{isEnabled:"isEnabled"},function(){return this.Qe},function(a){var b=this.Qe;b!==a&&(v.j(a,"boolean",E,"isEnabled"),this.Qe=a,this.h("isEnabled",b,a))});
v.defineProperty(E,{Yy:"allowClipboard"},function(){return this.mu},function(a){var b=this.mu;b!==a&&(v.j(a,"boolean",E,"allowClipboard"),this.mu=a,this.h("allowClipboard",b,a))});v.defineProperty(E,{Uj:"allowCopy"},function(){return this.hk},function(a){var b=this.hk;b!==a&&(v.j(a,"boolean",E,"allowCopy"),this.hk=a,this.h("allowCopy",b,a))});
v.defineProperty(E,{zm:"allowDelete"},function(){return this.ik},function(a){var b=this.ik;b!==a&&(v.j(a,"boolean",E,"allowDelete"),this.ik=a,this.h("allowDelete",b,a))});v.defineProperty(E,{Ss:"allowDragOut"},function(){return this.nu},function(a){var b=this.nu;b!==a&&(v.j(a,"boolean",E,"allowDragOut"),this.nu=a,this.h("allowDragOut",b,a))});
v.defineProperty(E,{fD:"allowDrop"},function(){return this.ou},function(a){var b=this.ou;b!==a&&(v.j(a,"boolean",E,"allowDrop"),this.ou=a,this.h("allowDrop",b,a))});v.defineProperty(E,{kw:"allowTextEdit"},function(){return this.rk},function(a){var b=this.rk;b!==a&&(v.j(a,"boolean",E,"allowTextEdit"),this.rk=a,this.h("allowTextEdit",b,a))});
v.defineProperty(E,{hw:"allowGroup"},function(){return this.jk},function(a){var b=this.jk;b!==a&&(v.j(a,"boolean",E,"allowGroup"),this.jk=a,this.h("allowGroup",b,a))});v.defineProperty(E,{lw:"allowUngroup"},function(){return this.sk},function(a){var b=this.sk;b!==a&&(v.j(a,"boolean",E,"allowUngroup"),this.sk=a,this.h("allowUngroup",b,a))});
v.defineProperty(E,{Ko:"allowInsert"},function(){return this.qu},function(a){var b=this.qu;b!==a&&(v.j(a,"boolean",E,"allowInsert"),this.qu=a,this.h("allowInsert",b,a))});v.defineProperty(E,{Ts:"allowLink"},function(){return this.kk},function(a){var b=this.kk;b!==a&&(v.j(a,"boolean",E,"allowLink"),this.kk=a,this.h("allowLink",b,a))});
v.defineProperty(E,{Am:"allowRelink"},function(){return this.mk},function(a){var b=this.mk;b!==a&&(v.j(a,"boolean",E,"allowRelink"),this.mk=a,this.h("allowRelink",b,a))});v.defineProperty(E,{$k:"allowMove"},function(){return this.lk},function(a){var b=this.lk;b!==a&&(v.j(a,"boolean",E,"allowMove"),this.lk=a,this.h("allowMove",b,a))});
v.defineProperty(E,{iw:"allowReshape"},function(){return this.nk},function(a){var b=this.nk;b!==a&&(v.j(a,"boolean",E,"allowReshape"),this.nk=a,this.h("allowReshape",b,a))});v.defineProperty(E,{Us:"allowResize"},function(){return this.ok},function(a){var b=this.ok;b!==a&&(v.j(a,"boolean",E,"allowResize"),this.ok=a,this.h("allowResize",b,a))});
v.defineProperty(E,{jw:"allowRotate"},function(){return this.pk},function(a){var b=this.pk;b!==a&&(v.j(a,"boolean",E,"allowRotate"),this.pk=a,this.h("allowRotate",b,a))});v.defineProperty(E,{nf:"allowSelect"},function(){return this.qk},function(a){var b=this.qk;b!==a&&(v.j(a,"boolean",E,"allowSelect"),this.qk=a,this.h("allowSelect",b,a))});
v.defineProperty(E,{gD:"allowUndo"},function(){return this.ru},function(a){var b=this.ru;b!==a&&(v.j(a,"boolean",E,"allowUndo"),this.ru=a,this.h("allowUndo",b,a))});v.defineProperty(E,{mw:"allowZoom"},function(){return this.tu},function(a){var b=this.tu;b!==a&&(v.j(a,"boolean",E,"allowZoom"),this.tu=a,this.h("allowZoom",b,a))});
v.defineProperty(E,{Bz:"hasVerticalScrollbar"},function(){return this.$u},function(a){var b=this.$u;b!==a&&(v.j(a,"boolean",E,"hasVerticalScrollbar"),this.$u=a,Lk(this),this.pa(),this.h("hasVerticalScrollbar",b,a),Ak(this,!1))});v.defineProperty(E,{Az:"hasHorizontalScrollbar"},function(){return this.Zu},function(a){var b=this.Zu;b!==a&&(v.j(a,"boolean",E,"hasHorizontalScrollbar"),this.Zu=a,Lk(this),this.pa(),this.h("hasHorizontalScrollbar",b,a),Ak(this,!1))});
v.defineProperty(E,{ne:"allowHorizontalScroll"},function(){return this.pu},function(a){var b=this.pu;b!==a&&(v.j(a,"boolean",E,"allowHorizontalScroll"),this.pu=a,this.h("allowHorizontalScroll",b,a),Ak(this,!1))});v.defineProperty(E,{oe:"allowVerticalScroll"},function(){return this.su},function(a){var b=this.su;b!==a&&(v.j(a,"boolean",E,"allowVerticalScroll"),this.su=a,this.h("allowVerticalScroll",b,a),Ak(this,!1))});
v.defineProperty(E,{Tt:"scrollHorizontalLineChange"},function(){return this.Mv},function(a){var b=this.Mv;b!==a&&(v.j(a,"number",E,"scrollHorizontalLineChange"),0>a&&v.va(a,">= 0",E,"scrollHorizontalLineChange"),this.Mv=a,this.h("scrollHorizontalLineChange",b,a))});
v.defineProperty(E,{Ut:"scrollVerticalLineChange"},function(){return this.Qv},function(a){var b=this.Qv;b!==a&&(v.j(a,"number",E,"scrollVerticalLineChange"),0>a&&v.va(a,">= 0",E,"scrollVerticalLineChange"),this.Qv=a,this.h("scrollVerticalLineChange",b,a))});v.defineProperty(E,{N:"lastInput"},function(){return this.Nb},function(a){this.Nb=a});v.defineProperty(E,{yc:"firstInput"},function(){return this.qi},function(a){this.qi=a});
v.defineProperty(E,{$b:"currentCursor"},function(){return this.Gu},function(a){""===a&&(a=this.uq);this.Gu!==a&&(v.j(a,"string",E,"currentCursor"),null!==this.zb&&(this.Gu=a,this.zb.style.cursor=a,this.Kb.style.cursor=a))});v.defineProperty(E,{CJ:"defaultCursor"},function(){return this.uq},function(a){""===a&&(a="auto");var b=this.uq;b!==a&&(v.j(a,"string",E,"defaultCursor"),this.uq=a,this.h("defaultCursor",b,a))});
v.defineProperty(E,{click:"click"},function(){return this.fi},function(a){var b=this.fi;b!==a&&(null!==a&&v.j(a,"function",E,"click"),this.fi=a,this.h("click",b,a))});v.defineProperty(E,{at:"doubleClick"},function(){return this.oi},function(a){var b=this.oi;b!==a&&(null!==a&&v.j(a,"function",E,"doubleClick"),this.oi=a,this.h("doubleClick",b,a))});
v.defineProperty(E,{ez:"contextClick"},function(){return this.ii},function(a){var b=this.ii;b!==a&&(null!==a&&v.j(a,"function",E,"contextClick"),this.ii=a,this.h("contextClick",b,a))});v.defineProperty(E,{Tz:"mouseOver"},function(){return this.Bi},function(a){var b=this.Bi;b!==a&&(null!==a&&v.j(a,"function",E,"mouseOver"),this.Bi=a,this.h("mouseOver",b,a))});
v.defineProperty(E,{Sz:"mouseHover"},function(){return this.Ai},function(a){var b=this.Ai;b!==a&&(null!==a&&v.j(a,"function",E,"mouseHover"),this.Ai=a,this.h("mouseHover",b,a))});v.defineProperty(E,{Rz:"mouseHold"},function(){return this.zi},function(a){var b=this.zi;b!==a&&(null!==a&&v.j(a,"function",E,"mouseHold"),this.zi=a,this.h("mouseHold",b,a))});
v.defineProperty(E,{VH:"mouseDragOver"},function(){return this.vv},function(a){var b=this.vv;b!==a&&(null!==a&&v.j(a,"function",E,"mouseDragOver"),this.vv=a,this.h("mouseDragOver",b,a))});v.defineProperty(E,{Qz:"mouseDrop"},function(){return this.yi},function(a){var b=this.yi;b!==a&&(null!==a&&v.j(a,"function",E,"mouseDrop"),this.yi=a,this.h("mouseDrop",b,a))});
v.defineProperty(E,{yA:"toolTip"},function(){return this.Hi},function(a){var b=this.Hi;b!==a&&(null!==a&&v.A(a,Tg,E,"toolTip"),this.Hi=a,this.h("toolTip",b,a))});v.defineProperty(E,{contextMenu:"contextMenu"},function(){return this.ji},function(a){var b=this.ji;b!==a&&(null!==a&&v.A(a,Tg,E,"contextMenu"),this.ji=a,this.h("contextMenu",b,a))});
v.defineProperty(E,{nb:"commandHandler"},function(){return this.Mx},function(a){var b=this.Mx;b!==a&&(v.A(a,Xf,E,"commandHandler"),null!==a.g&&v.k("Cannot share CommandHandlers between Diagrams: "+a.toString()),null!==b&&b.Mc(null),this.Mx=a,a.Mc(this))});v.defineProperty(E,{Za:"toolManager"},function(){return this.Qy},function(a){var b=this.Qy;b!==a&&(v.A(a,Rg,E,"toolManager"),null!==a.g&&v.k("Cannot share ToolManagers between Diagrams: "+a.toString()),null!==b&&b.Mc(null),this.Qy=a,a.Mc(this))});
v.defineProperty(E,{$s:"defaultTool"},function(){return this.Sx},function(a){var b=this.Sx;b!==a&&(v.A(a,Wf,E,"defaultTool"),this.Sx=a,this.Wa===b&&(this.Wa=a))});v.defineProperty(E,{Wa:"currentTool"},function(){return this.Qx},function(a){var b=this.Qx;null!==b&&(b.qa&&b.doDeactivate(),b.cancelWaitAfter(),b.doStop());null===a&&(a=this.$s);null!==a&&(v.A(a,Wf,E,"currentTool"),this.Qx=a,a.Mc(this),a.doStart())});v.u(E,{selection:"selection"},function(){return this.Sv});
v.defineProperty(E,{NH:"maxSelectionCount"},function(){return this.rv},function(a){var b=this.rv;if(b!==a)if(v.j(a,"number",E,"maxSelectionCount"),0<=a&&!isNaN(a)){if(this.rv=a,this.h("maxSelectionCount",b,a),!this.la.ib&&(a=this.selection.count-a,0<a)){this.Ba("ChangingSelection");for(var b=this.selection.dc(),c=0;c<a;c++)b[c].hb=!1;this.Ba("ChangedSelection")}}else v.va(a,">= 0",E,"maxSelectionCount")});
v.defineProperty(E,{YH:"nodeSelectionAdornmentTemplate"},function(){return this.wv},function(a){var b=this.wv;b!==a&&(v.A(a,Tg,E,"nodeSelectionAdornmentTemplate"),this.wv=a,this.h("nodeSelectionAdornmentTemplate",b,a))});v.defineProperty(E,{bH:"groupSelectionAdornmentTemplate"},function(){return this.Wu},function(a){var b=this.Wu;b!==a&&(v.A(a,Tg,E,"groupSelectionAdornmentTemplate"),this.Wu=a,this.h("groupSelectionAdornmentTemplate",b,a))});
v.defineProperty(E,{HH:"linkSelectionAdornmentTemplate"},function(){return this.nv},function(a){var b=this.nv;b!==a&&(v.A(a,Tg,E,"linkSelectionAdornmentTemplate"),this.nv=a,this.h("linkSelectionAdornmentTemplate",b,a))});v.u(E,{Qm:"highlighteds"},function(){return this.av});
v.defineProperty(E,{Yh:"isModified"},function(){var a=this.la;return a.isEnabled?null!==a.Oi?!0:this.sy&&this.Og!==a.Ti:this.sy},function(a){if(this.sy!==a){v.j(a,"boolean",E,"isModified");this.sy=a;var b=this.la;!a&&b.isEnabled&&(this.Og=b.Ti);a||yl(this)}});function yl(a){var b=a.Yh;a.YC!==b&&(a.YC=b,a.Ba("Modified"))}
v.defineProperty(E,{ba:"model"},function(){return this.Vd},function(a){var b=this.Vd;if(b!==a){v.A(a,F,E,"model");this.Wa.doCancel();null!==b&&b.la!==a.la&&b.la.gE&&v.k("Do not replace a Diagram.model while a transaction is in progress.");this.Na.Zh();this.ow();this.Qg=!1;this.Nn=!0;this.Og=-2;this.Kf=!1;var c=this.Kd;this.Kd=!0;this.Na.$m("Model");null!==b&&(null!==this.Rg&&this.Rg.each(function(a){b.Ot(a)}),b.Ot(this.fC),b instanceof Q&&zl(this,b.yg),zl(this,b.Yf));this.Vd=a;a.ym(this.eC);Al(this,
a.Yf);a instanceof Q&&Bl(this,a.yg);a.Ot(this.eC);a.ym(this.fC);null!==this.Rg&&this.Rg.each(function(b){a.ym(b)});this.Kd=c;this.Pc||this.pa();null!==b&&(a.la.isEnabled=b.la.isEnabled)}});v.defineProperty(E,{Qa:null},function(){return this.YB},function(a){this.YB=a});v.u(E,{Qw:null},function(){return this.DF});
function jk(a,b){if(b.ba===a.ba){var c=b.wc,d=b.propertyName;if(c===Bf&&"S"===d[0])if("StartingFirstTransaction"===d)c=a.Za,c.Ne.each(function(b){b.Mc(a)}),c.Vf.each(function(b){b.Mc(a)}),c.Wf.each(function(b){b.Mc(a)}),a.Kd||a.Qg||(a.Mu=!0,a.Nn&&(a.Kf=!0));else if("StartingUndo"===d||"StartingRedo"===d){var e=a.Na;e.cf&&!a.tb&&e.Zh();a.Ba("ChangingSelection")}else"StartedTransaction"===d&&(e=a.Na,e.cf&&!a.tb&&e.Zh());else if(a.Qa){a.Qa=!1;try{var f=b.gf;if(""!==f)if(c===Af){if("linkFromKey"===f){var h=
b.object,k=a.Pf(h);if(null!==k){var l=b.newValue,m=a.Ye(l);k.T=m}}else if("linkToKey"===f)h=b.object,k=a.Pf(h),null!==k&&(l=b.newValue,m=a.Ye(l),k.Z=m);else if("linkFromPortId"===f){if(h=b.object,k=a.Pf(h),null!==k){var n=b.newValue;"string"===typeof n&&(k.wg=n)}}else if("linkToPortId"===f)h=b.object,k=a.Pf(h),null!==k&&(n=b.newValue,"string"===typeof n&&(k.qh=n));else if("nodeGroupKey"===f){var h=b.object,p=a.dh(h);if(null!==p){var q=b.newValue;if(void 0!==q){var r=a.Ye(q);p.Sa=r instanceof V?r:
null}else p.Sa=null}}else if("linkLabelKeys"===f){if(h=b.object,k=a.Pf(h),null!==k){var s=b.oldValue,t=b.newValue;if(v.isArray(s))for(var u=v.Va(s),y=0;y<u;y++){var w=v.Pa(s,y),m=a.Ye(w);null!==m&&(m.ee=null)}if(v.isArray(t))for(u=v.Va(t),y=0;y<u;y++)w=v.Pa(t,y),m=a.Ye(w),null!==m&&(m.ee=k)}}else if("nodeParentKey"===f){var x=b.object,A=a.Ye(b.newValue),H=a.Bw(x);if(null!==H){var C=H.Yo();null!==C?null===A?a.remove(C):a.Qd?C.T=A:C.Z=A:Cl(a,A,H)}}else if("parentLinkCategory"===f){var x=b.object,H=
a.Bw(x),T=b.newValue;null!==H&&"string"===typeof T&&(C=H.Yo(),null!==C&&(C.gc=T))}else if("nodeCategory"===f){var h=b.object,ba=a.dh(h),T=b.newValue;null!==ba&&"string"===typeof T&&(ba.gc=T)}else if("linkCategory"===f){var h=b.object,U=a.Pf(h),T=b.newValue;null!==U&&"string"===typeof T&&(U.gc=T)}else if("nodeDataArray"===f){var M=b.oldValue;zl(a,M);var aa=b.newValue;Al(a,aa)}else"linkDataArray"===f&&(M=b.oldValue,zl(a,M),aa=b.newValue,Bl(a,aa));a.Yh=!0}else c===Cf?(aa=b.newValue,"nodeDataArray"===
f&&v.Ta(aa)?Dl(a,aa):"linkDataArray"===f&&v.Ta(aa)?El(a,aa):"linkLabelKeys"===f&&mg(aa)&&(k=a.Pf(b.object),m=a.Ye(aa),null!==k&&null!==m&&(m.ee=k)),a.Yh=!0):c===Df?(M=b.oldValue,"nodeDataArray"===f&&v.Ta(M)?Fl(a,M):"linkDataArray"===f&&v.Ta(M)?Fl(a,M):"linkLabelKeys"===f&&mg(M)&&(m=a.Ye(M),null!==m&&(m.ee=null)),a.Yh=!0):c===Bf&&("SourceChanged"===f?null!==b.object?ik(a,b.object,b.propertyName):(a.SI(),a.qF()):"ModelDisplaced"===f&&a.yl());else if(c===Af){var Ia=b.propertyName,h=b.object;if(h===a.ba){if("nodeKeyProperty"===
Ia||"nodeCategoryProperty"===Ia||"linkFromKeyProperty"===Ia||"linkToKeyProperty"===Ia||"linkFromPortIdProperty"===Ia||"linkToPortIdProperty"===Ia||"linkLabelKeysProperty"===Ia||"nodeIsGroupProperty"===Ia||"nodeGroupKeyProperty"===Ia||"nodeParentKeyProperty"===Ia||"linkCategoryProperty"===Ia)a.la.ib||a.yl()}else ik(a,h,Ia);a.Yh=!0}else if(c===Cf||c===Df)Gl(a,b),a.Yh=!0;else if(c===Bf){if("FinishedUndo"===d||"FinishedRedo"===d)a.la.ti=!0,a.Ba("ChangedSelection"),yk(a),a.la.ti=!1;e=a.Na;"RolledBackTransaction"===
d&&e.Zh();a.Mu=!0;a.Uf();0===a.la.rh&&Hj(e);"CommittedTransaction"===d&&a.la.uy&&(a.Og=Math.min(a.Og,a.la.Ti-1));var oa=b.kE;oa&&(yl(a),a.Qw.clear());!a.By&&oa&&(a.By=!0,v.setTimeout(function(){a.Wa.standardMouseOver();a.By=!1},10))}}finally{a.Qa=!0}}}}
function ik(a,b,c){if("string"===typeof c){var d=a.dh(b);if(null!==d)d.Hb(c),a.ba instanceof Pf&&(d=a.Pf(b),null!==d&&d.Hb(c));else{for(var d=null,e=a.Wl.i;e.next();){for(var f=e.value,h=0;h<f.length;h++){var k=f[h].NG(b);null!==k&&(null===d&&(d=v.jb()),d.push(k))}if(null!==d)break}if(null!==d){for(e=0;e<d.length;e++)d[e].Hb(c);v.sa(d)}}b===a.ba.bk&&a.qF(c)}}v.defineProperty(E,{we:"skipsModelSourceBindings"},function(){return this.AC},function(a){this.AC=a});
v.defineProperty(E,{bu:null},function(){return this.Ky},function(a){this.Ky=a});function Gl(a,b){var c=b.wc===Cf,d=c?b.zg:b.Bg,e=c?b.newValue:b.oldValue,f=a.Wl.ja(b.object);if(Array.isArray(f))for(var h=0;h<f.length;h++){var k=f[h];if(c)Hl(k,e,d);else{var l=d;if(!(0>l)){var m=l;Il(k)&&m++;k.Le(m);Jl(k,m,l)}}}}function pl(a,b){var c=b.tj;if(v.isArray(c)){var d=a.Wl.ja(c);if(null===d)d=[],d.push(b),a.Wl.add(c,d);else{for(c=0;c<d.length;c++)if(d[c]===b)return;d.push(b)}}}
function tl(a,b){var c=b.tj;if(v.isArray(c)){var d=a.Wl.ja(c);if(null!==d)for(var e=0;e<d.length;e++)if(d[e]===b){d.splice(e,1);0===d.length&&a.Wl.remove(c);break}}}function ol(a,b){for(var c=b.za.n,d=c.length,e=0;e<d;e++){var f=c[e];f instanceof kk&&Kl(a,f)}}function Kl(a,b){var c=b.element;if(null!==c&&c instanceof HTMLImageElement){var c=c.src,d=a.no.ja(c);if(null===d)d=[],d.push(b),a.no.add(c,d);else{for(c=0;c<d.length;c++)if(d[c]===b)return;d.push(b)}}}
function sl(a,b){for(var c=b.za.n,d=c.length,e=0;e<d;e++){var f=c[e];f instanceof kk&&Ll(a,f)}}function Ll(a,b){var c=b.element;if(null!==c&&c instanceof HTMLImageElement){var c=c.src,d=a.no.ja(c);if(null!==d)for(var e=0;e<d.length;e++)if(d[e]===b){d.splice(e,1);0===d.length&&a.no.remove(c);break}}}
E.prototype.clear=E.prototype.clear=function(){var a=null;null!==this.jd&&(a=this.jd.Y);this.ba.clear();for(var b=this.Yb.length,c=0;c<b;c++)this.Yb.n[c].clear();this.fg.clear();this.xk.clear();this.mo.clear();this.Uk.clear();this.eo.clear();this.wb.clear();this.ki.clear();this.wk.clear();this.Wl.clear();this.Sv.Oa();this.Sv.clear();this.Sv.freeze();this.av.Oa();this.av.clear();this.av.freeze();Eg=this.zn=null;Fg="";this.Nu=(new B(NaN,NaN,NaN,NaN)).freeze();null!==a&&(this.add(a),this.wb.remove(a));
this.pa()};
E.prototype.reset=E.prototype.reset=function(){this.Pc=!0;this.clear();this.Yb=new I(Vf);this.IE();this.HE();this.kb=(new z(NaN,NaN)).freeze();this.Tb=1;this.cv=(new z(NaN,NaN)).freeze();this.dv=NaN;this.sv=1E-4;this.qv=100;this.ew=(new z(NaN,NaN)).freeze();this.Uu=(new B(NaN,NaN,NaN,NaN)).freeze();this.Nv=(new eb(0,0,0,0)).freeze();this.Ov=fk;this.Kv=this.Gv=null;this.Ml=Yg;this.wn=fc;this.Dk=Yg;this.Rn=fc;this.ev=this.bv=tb;this.UA=250;this.xu=(new eb(16,16,16,16)).freeze();this.iv=!0;this.$v=hk;
this.bw=$h;this.uq="auto";this.ji=this.Hi=this.yi=this.vv=this.zi=this.Ai=this.Bi=this.ii=this.oi=this.fi=null;this.si=!1;this.ik=this.hk=this.mu=this.Qe=!0;this.ou=this.nu=!1;this.su=this.pu=this.$u=this.Zu=this.tu=this.ru=this.qk=this.pk=this.ok=this.nk=this.lk=this.mk=this.kk=this.qu=this.sk=this.jk=this.rk=!0;this.Qv=this.Mv=16;this.Se=(new eb(5)).freeze();this.rv=999999999;this.Ud=null;ok(this);this.jd=null;this.Za=new Rg;this.Za.initializeStandardTools();this.Wa=this.$s=this.Za;this.nb=new Xf;
this.Gi=!0;nk(this);this.Pb=new Yf;this.Gi=!1;this.ba=new Q;this.Qg=!1;this.Nn=!0;this.Pc=this.Kf=!1;this.pa();this.Rg=this.ei=null;gk(this);this.Gu=""};
E.prototype.rebuildParts=E.prototype.yl=function(){for(var a=this.Xz.i;a.next();){var b=a.value,c=a.key;(!b.Pd()||b instanceof V)&&v.k('Invalid node template in Diagram.nodeTemplateMap: template for "'+c+'" must be a Node or a simple Part, not a Group or Link: '+b)}for(a=this.zz.i;a.next();)b=a.value,c=a.key,b instanceof V||v.k('Invalid group template in Diagram.groupTemplateMap: template for "'+c+'" must be a Group, not a normal Node or Link: '+b);for(a=this.Jz.i;a.next();)b=a.value,c=a.key,b instanceof
W||v.k('Invalid link template in Diagram.linkTemplateMap: template for "'+c+'" must be a Link, not a normal Node or simple Part: '+b);a=v.jb();for(b=this.selection.i;b.next();)(c=b.value.data)&&a.push(c);for(var b=v.jb(),d=this.Qm.i;d.next();)(c=d.value.data)&&b.push(c);c=v.jb();for(d=this.Ag.i;d.next();){var e=d.value;null!==e.data&&(c.push(e.data),c.push(e.location))}for(d=this.links.i;d.next();)e=d.value,null!==e.data&&(c.push(e.data),c.push(e.location));for(d=this.dk.i;d.next();)e=d.value,null!==
e.data&&(c.push(e.data),c.push(e.location));d=this.ba;d instanceof Q&&zl(this,d.yg);zl(this,d.Yf);Al(this,d.Yf);d instanceof Q&&Bl(this,d.yg);for(d=0;d<a.length;d++)e=this.dh(a[d]),null!==e&&(e.hb=!0);for(d=0;d<b.length;d++)e=this.dh(b[d]),null!==e&&(e.fh=!0);for(d=0;d<c.length;d+=2)e=this.dh(c[d]),null!==e&&(e.location=c[d+1]);v.sa(a);v.sa(b);v.sa(c)};
function Al(a,b){if(null!==b){for(var c=a.ba,d=v.Va(b),e=0;e<d;e++){var f=v.Pa(b,e);c.He(f)?Dl(a,f,!1):c instanceof Q&&El(a,f)}if(c instanceof Q||c instanceof Pf){for(e=0;e<d;e++)f=v.Pa(b,e),c.He(f)&&Ml(a,f);if(c instanceof Q)for(c=a.links;c.next();)Nl(c.value)}Ol(a,!1)}}
function Dl(a,b,c){if(void 0!==b&&null!==b&&!a.la.ib&&!a.ki.contains(b)){void 0===c&&(c=!0);var d=a.xz(b),e=Pl(a,b,d);if(null!==e&&(Vg(e),e=e.copy(),null!==e)){var f=a.we;a.we=!0;e.di=d;e.ie=b;a.add(e);e.ie=null;e.data=b;c&&Ml(a,b);a.we=f}}}E.prototype.xz=function(a){return this.ba.xz(a)};var Ql=!1,Rl=!1;
function Pl(a,b,c){var d=!1,e=a.ba;e instanceof Q&&(d=e.Fz(b));d?(b=a.zz.ja(c),null===b&&(b=a.zz.ja(""),null===b&&(Rl||(Rl=!0,v.trace('No Group template found for category "'+c+'"'),v.trace("  Using default group template")),b=a.pB))):(b=a.Xz.ja(c),null===b&&(b=a.Xz.ja(""),null===b&&(Ql||(Ql=!0,v.trace('No Node template found for category "'+c+'"'),v.trace("  Using default node template")),b=a.rB)));return b}
function Ml(a,b){var c=a.ba;if(c instanceof Q||c instanceof Pf){var d=c.gb(b);if(void 0!==d){var e=pg(c,d),f=a.dh(b);if(null!==e&&null!==f){for(e=e.i;e.next();){var h=e.value;if(c instanceof Q){var k=c;if(k.He(h)){if(f instanceof V&&k.Om(h)===d){var l=f,h=a.dh(h);null!==h&&(h.Sa=l)}}else{var m=a.Pf(h);if(null!==m&&f instanceof S&&(l=f,k.ol(h)===d&&(m.T=l),k.pl(h)===d&&(m.Z=l),h=k.$j(h),v.isArray(h)))for(k=0;k<v.Va(h);k++)if(v.Pa(h,k)===d){l.ee=m;break}}}else c instanceof Pf&&(m=c,m.He(h)&&f instanceof
S&&(l=f,m.Pm(h)===d&&(h=a.Bw(h),Cl(a,l,h))))}rg(c,d)}c instanceof Q?(c=c.Om(b),void 0!==c&&(c=a.Ye(c),c instanceof V&&(f.Sa=c))):c instanceof Pf&&(c=c.Pm(b),void 0!==c&&f instanceof S&&(l=f,f=a.Ye(c),Cl(a,f,l)))}}}
function Cl(a,b,c){if(null!==b&&null!==c){var d=a.Za.qE,e=b,f=c;if(a.Qd)for(b=f.te;b.next();){if(b.value.Z===f)return}else for(e=c,f=b,b=e.te;b.next();)if(b.value.T===e)return;if(null===d||!bi(d,e,f,null,!0))if(d=a.Dw(c.data),b=Sl(a,d),null!==b&&(Vg(b),b=b.copy(),null!==b)){var h=a.we;a.we=!0;b.di=d;b.ie=c.data;b.T=e;b.Z=f;a.add(b);b.ie=null;b.data=c.data;a.we=h}}}function Bl(a,b){if(null!==b){for(var c=v.Va(b),d=0;d<c;d++){var e=v.Pa(b,d);El(a,e)}Ol(a,!1)}}
function El(a,b){if(void 0!==b&&null!==b&&!a.la.ib&&!a.wk.contains(b)){var c=a.Dw(b),d=Sl(a,c);if(null!==d&&(Vg(d),d=d.copy(),null!==d)){var e=a.we;a.we=!0;d.di=c;d.ie=b;var c=a.ba,f=c.VG(b);""!==f&&(d.wg=f);f=c.ol(b);void 0!==f&&(f=a.Ye(f),f instanceof S&&(d.T=f));f=c.YG(b);""!==f&&(d.qh=f);f=c.pl(b);void 0!==f&&(f=a.Ye(f),f instanceof S&&(d.Z=f));c=c.$j(b);if(v.isArray(c))for(var f=v.Va(c),h=0;h<f;h++){var k=v.Pa(c,h),k=a.Ye(k);null!==k&&(k.ee=d)}a.add(d);d.ie=null;d.data=b;a.we=e}}}
E.prototype.Dw=function(a){var b=this.ba,c="";b instanceof Q?c=b.Dw(a):b instanceof Pf&&(c=b.XG(a));return c};var Tl=!1;function Sl(a,b){var c=a.Jz.ja(b);null===c&&(c=a.Jz.ja(""),null===c&&(Tl||(Tl=!0,v.trace('No Link template found for category "'+b+'"'),v.trace("  Using default link template")),c=a.qB));return c}function zl(a,b){for(var c=v.Va(b),d=0;d<c;d++){var e=v.Pa(b,d);Fl(a,e)}}
function Fl(a,b){if(void 0!==b&&null!==b){var c=a.dh(b);if(null!==c){c.hb=!1;c.fh=!1;var d=c.layer;if(null!==d&&d.g===a){var e=a.ba;if(e instanceof Q&&c instanceof S){var f=c,h=e.gb(f.data);if(void 0!==h){for(var k=f.te;k.next();)qg(e,h,k.value.data);f.uf&&(k=f.ee,null!==k&&qg(e,h,k.data));if(f instanceof V)for(f=f.Ic;f.next();)k=f.value.data,e.He(k)&&qg(e,h,k)}}else if(e instanceof Pf&&c instanceof S){f=c;k=a.Pf(f.data);if(null!==k){k.hb=!1;k.fh=!1;var l=k.layer;if(null!==l){var m=l.Le(-1,k,!1);
0<=m&&a.Lc(Df,"parts",l,k,null,m,null);m=k.zt;null!==m&&m(k,l,null)}}k=a.Qd;for(f=f.te;f.next();)l=f.value,l=(k?l.Z:l.T).data,e.He(l)&&qg(e,h,l)}e=d.Le(-1,c,!1);0<=e&&a.Lc(Df,"parts",d,c,null,e,null);e=c.zt;null!==e&&e(c,d,null)}}}}E.prototype.findPartForKey=E.prototype.QG=function(a){if(null===a||void 0===a)return null;a=this.ba.ae(a);return null===a?null:this.ki.ja(a)};
E.prototype.findNodeForKey=E.prototype.Ye=function(a){if(null===a||void 0===a)return null;a=this.ba.ae(a);if(null===a)return null;a=this.ki.ja(a);return a instanceof S?a:null};E.prototype.findPartForData=E.prototype.dh=function(a){if(null===a)return null;var b=this.ki.ja(a);return null!==b?b:b=this.wk.ja(a)};E.prototype.findNodeForData=E.prototype.Bw=function(a){if(null===a)return null;a=this.ki.ja(a);return a instanceof S?a:null};
E.prototype.findLinkForData=E.prototype.Pf=function(a){return null===a?null:this.wk.ja(a)};E.prototype.findNodesByExample=function(a){for(var b=new J,c=this.mo.i;c.next();){var d=c.value,e=d.data;if(null!==e)for(var f=0;f<arguments.length;f++){var h=arguments[f];if(v.Ta(h)&&Ul(this,e,h)){b.add(d);break}}}return b.i};
E.prototype.findLinksByExample=function(a){for(var b=new J,c=this.eo.i;c.next();){var d=c.value,e=d.data;if(null!==e)for(var f=0;f<arguments.length;f++){var h=arguments[f];if(v.Ta(h)&&Ul(this,e,h)){b.add(d);break}}}return b.i};function Ul(a,b,c){for(var d in c){var e=b[d],f=c[d];if(v.isArray(f)){if(!v.isArray(e)||e.length<f.length)return!1;for(var h=0;h<e.length;h++){var k=e[h],l=f[h];if(void 0!==l&&!Vl(a,k,l))return!1}}else if(!Vl(a,e,f))return!1}return!0}
function Vl(a,b,c){if("function"===typeof c){if(!c(b))return!1}else if(c instanceof RegExp){if(!b||!c.test(b.toString()))return!1}else if(v.Ta(b)&&v.Ta(c)){if(!Ul(a,b,c))return!1}else if(b!==c)return!1;return!0}v.defineProperty(E,{mK:"nodeTemplate"},function(){return this.Ci.ja("")},function(a){var b=this.Ci.ja("");b!==a&&(v.A(a,R,E,"nodeTemplate"),this.Ci.add("",a),this.h("nodeTemplate",b,a),this.la.ib||this.yl())});
v.defineProperty(E,{Xz:"nodeTemplateMap"},function(){return this.Ci},function(a){var b=this.Ci;b!==a&&(v.A(a,na,E,"nodeTemplateMap"),this.Ci=a,this.h("nodeTemplateMap",b,a),this.la.ib||this.yl())});v.defineProperty(E,{OJ:"groupTemplate"},function(){return this.zk.ja("")},function(a){var b=this.zk.ja("");b!==a&&(v.A(a,V,E,"groupTemplate"),this.zk.add("",a),this.h("groupTemplate",b,a),this.la.ib||this.yl())});
v.defineProperty(E,{zz:"groupTemplateMap"},function(){return this.zk},function(a){var b=this.zk;b!==a&&(v.A(a,na,E,"groupTemplateMap"),this.zk=a,this.h("groupTemplateMap",b,a),this.la.ib||this.yl())});v.defineProperty(E,{aK:"linkTemplate"},function(){return this.wj.ja("")},function(a){var b=this.wj.ja("");b!==a&&(v.A(a,W,E,"linkTemplate"),this.wj.add("",a),this.h("linkTemplate",b,a),this.la.ib||this.yl())});
v.defineProperty(E,{Jz:"linkTemplateMap"},function(){return this.wj},function(a){var b=this.wj;b!==a&&(v.A(a,na,E,"linkTemplateMap"),this.wj=a,this.h("linkTemplateMap",b,a),this.la.ib||this.yl())});v.defineProperty(E,{tH:null},function(){return this.Tl},function(a){this.Tl=a});
v.defineProperty(E,{Me:"isMouseCaptured"},function(){return this.VB},function(a){var b=this.zb;null!==b&&(a?(this.N.bubbles=!1,b.removeEventListener("mousemove",this.pp,!1),b.removeEventListener("mousedown",this.op,!1),b.removeEventListener("mouseup",this.rp,!1),b.removeEventListener("mousewheel",this.jh,!1),b.removeEventListener("DOMMouseScroll",this.jh,!1),b.removeEventListener("mouseout",this.qp,!1),window.addEventListener("mousemove",this.pp,!0),window.addEventListener("mousedown",this.op,!0),
window.addEventListener("mouseup",this.rp,!0),window.addEventListener("mousewheel",this.jh,!0),window.addEventListener("DOMMouseScroll",this.jh,!0),window.addEventListener("mouseout",this.qp,!0),window.addEventListener("selectstart",this.preventDefault,!1)):(window.removeEventListener("mousemove",this.pp,!0),window.removeEventListener("mousedown",this.op,!0),window.removeEventListener("mouseup",this.rp,!0),window.removeEventListener("mousewheel",this.jh,!0),window.removeEventListener("DOMMouseScroll",
this.jh,!0),window.removeEventListener("mouseout",this.qp,!0),window.removeEventListener("selectstart",this.preventDefault,!1),b.addEventListener("mousemove",this.pp,!1),b.addEventListener("mousedown",this.op,!1),b.addEventListener("mouseup",this.rp,!1),b.addEventListener("mousewheel",this.jh,!1),b.addEventListener("DOMMouseScroll",this.jh,!1),b.addEventListener("mouseout",this.qp,!1)),this.VB=a)});
v.defineProperty(E,{position:"position"},function(){return this.kb},function(a){var b=this.kb;if(!b.K(a)){v.A(a,z,E,"position");var c=this.rb.copy();a=a.copy();if(!this.Pc&&null!==this.zb){this.Pc=!0;var d=this.scale;Ck(this,a,this.Zd,this.Eb/d,this.Jb/d,this.wn,!1);this.Pc=!1}this.kb=a.R();a=this.Na;a.pc&&Uj(a,b,this.kb);this.Pc||this.Lt(c,this.rb)}});v.defineProperty(E,{iH:"initialPosition"},function(){return this.cv},function(a){this.cv.K(a)||(v.A(a,z,E,"initialPosition"),this.cv=a.R())});
v.defineProperty(E,{jH:"initialScale"},function(){return this.dv},function(a){this.dv!==a&&(v.j(a,"number",E,"initialScale"),this.dv=a)});v.defineProperty(E,{mt:"grid"},function(){null===this.jd&&uk(this);return this.jd},function(a){var b=this.jd;if(b!==a){null===b&&(uk(this),b=this.jd);v.A(a,D,E,"grid");a.type!==vk&&v.k("Diagram.grid must be a Panel of type Panel.Grid");var c=b.S;null!==c&&c.remove(b);this.jd=a;a.name="GRID";null!==c&&c.add(a);Zk(this);this.pa();this.h("grid",b,a)}});
v.u(E,{rb:"viewportBounds"},function(){var a=this.XC;if(null===this.zb)return a;var b=this.kb,c=this.Tb;a.m(b.x,b.y,Math.max(this.Eb,0)/c,Math.max(this.Jb,0)/c);return a});v.defineProperty(E,{OD:"fixedBounds"},function(){return this.Uu},function(a){var b=this.Uu;b.K(a)||(v.A(a,B,E,"fixedBounds"),-Infinity!==a.width&&Infinity!==a.height&&-Infinity!==a.height||v.k("fixedBounds width/height must not be Infinity"),this.Uu=a=a.R(),this.qc(),this.h("fixedBounds",b,a))});
v.defineProperty(E,{PE:"scrollMargin"},function(){return this.Nv},function(a){"number"===typeof a?a=new eb(a):v.A(a,eb,E,"scrollMargin");var b=this.Nv;b.K(a)||(this.Nv=a=a.R(),this.qc(),this.h("scrollMargin",b,a))});v.defineProperty(E,{jA:"scrollMode"},function(){return this.Ov},function(a){var b=this.Ov;b!==a&&(v.pb(a,E,E,"scrollMode"),this.Ov=a,a===fk&&Ak(this,!1),this.h("scrollMode",b,a))});
v.defineProperty(E,{AE:"positionComputation"},function(){return this.Gv},function(a){var b=this.Gv;b!==a&&(null!==a&&v.j(a,"function",E,"positionComputation"),this.Gv=a,Ak(this,!1),this.h("positionComputation",b,a))});v.defineProperty(E,{OE:"scaleComputation"},function(){return this.Kv},function(a){var b=this.Kv;b!==a&&(null!==a&&v.j(a,"function",E,"scaleComputation"),this.Kv=a,wl(this,this.scale,this.scale),this.h("scaleComputation",b,a))});v.u(E,{Zd:"documentBounds"},function(){return this.Nu});
function Dk(a,b){a.qj=!1;var c=a.Nu;c.K(b)||(b=b.R(),a.Nu=b,Ak(a,!1),a.Ba("DocumentBoundsChanged",null,c.copy()),Lk(a))}v.defineProperty(E,{scale:"scale"},function(){return this.Tb},function(a){var b=this.Tb;v.Ue(a,E,"scale");b!==a&&wl(this,b,a)});v.defineProperty(E,{al:"autoScale"},function(){return this.Ml},function(a){var b=this.Ml;b!==a&&(v.pb(a,E,E,"autoScale"),this.Ml=a,this.h("autoScale",b,a),a!==Yg&&Ak(this,!1))});
v.defineProperty(E,{QJ:"initialAutoScale"},function(){return this.Dk},function(a){var b=this.Dk;b!==a&&(v.pb(a,E,E,"initialAutoScale"),this.Dk=a,this.h("initialAutoScale",b,a))});v.defineProperty(E,{kH:"initialViewportSpot"},function(){return this.ev},function(a){var b=this.ev;b!==a&&(v.A(a,L,E,"initialViewportSpot"),a.cd()||v.k("initialViewportSpot must be a specific Spot: "+a),this.ev=a,this.h("initialViewportSpot",b,a))});
v.defineProperty(E,{hH:"initialDocumentSpot"},function(){return this.bv},function(a){var b=this.bv;b!==a&&(v.A(a,L,E,"initialDocumentSpot"),a.cd()||v.k("initialViewportSpot must be a specific Spot: "+a),this.bv=a,this.h("initialDocumentSpot",b,a))});v.defineProperty(E,{hh:"minScale"},function(){return this.sv},function(a){v.Ue(a,E,"minScale");var b=this.sv;b!==a&&(0<a?(this.sv=a,this.h("minScale",b,a),a>this.scale&&(this.scale=a)):v.va(a,"> 0",E,"minScale"))});
v.defineProperty(E,{gh:"maxScale"},function(){return this.qv},function(a){v.Ue(a,E,"maxScale");var b=this.qv;b!==a&&(0<a?(this.qv=a,this.h("maxScale",b,a),a<this.scale&&(this.scale=a)):v.va(a,"> 0",E,"maxScale"))});v.defineProperty(E,{Hl:"zoomPoint"},function(){return this.ew},function(a){this.ew.K(a)||(v.A(a,z,E,"zoomPoint"),this.ew=a=a.R())});
v.defineProperty(E,{vD:"contentAlignment"},function(){return this.wn},function(a){var b=this.wn;b.K(a)||(v.A(a,L,E,"contentAlignment"),this.wn=a=a.R(),this.h("contentAlignment",b,a),Ak(this,!1))});v.defineProperty(E,{RJ:"initialContentAlignment"},function(){return this.Rn},function(a){var b=this.Rn;b.K(a)||(v.A(a,L,E,"initialContentAlignment"),this.Rn=a=a.R(),this.h("initialContentAlignment",b,a))});
v.defineProperty(E,{padding:"padding"},function(){return this.Se},function(a){"number"===typeof a?a=new eb(a):v.A(a,eb,E,"padding");var b=this.Se;b.K(a)||(this.Se=a=a.R(),this.qc(),this.h("padding",b,a))});v.u(E,{Ag:"nodes"},function(){return this.mo.i});v.u(E,{links:"links"},function(){return this.eo.i});v.u(E,{dk:"parts"},function(){return this.wb.i});
E.prototype.findTopLevelNodesAndLinks=function(){for(var a=new J(R),b=this.mo.i;b.next();){var c=b.value;c.gp&&a.add(c)}for(b=this.eo.i;b.next();)c=b.value,c.gp&&a.add(c);return a.i};E.prototype.findTopLevelGroups=function(){return this.Uk.i};v.defineProperty(E,{Pb:"layout"},function(){return this.Ud},function(a){var b=this.Ud;b!==a&&(v.A(a,Yf,E,"layout"),null!==b&&(b.g=null,b.group=null),this.Ud=a,a.g=this,a.group=null,this.vu=!0,this.h("layout",b,a),this.ue())});
E.prototype.layoutDiagram=function(a){yk(this);a&&Ol(this,!0);$k(this,!1)};function Ol(a,b){for(var c=a.Uk.i;c.next();)Wl(a,c.value,b);null!==a.Pb&&(b?a.Pb.wf=!1:a.Pb.H())}function Wl(a,b,c){if(null!==b){for(var d=b.jo.i;d.next();)Wl(a,d.value,c);null!==b.Pb&&(c?b.Pb.wf=!1:b.Pb.H())}}
function $k(a,b){if(!a.Tx){var c=a.Pb,d=a.Qa;a.Qa=!0;try{a.Qb("Layout");var e=a.Na;1!==a.la.rh||e.cf||e.pc||e.$m("Layout");for(var f=a.Uk.i;f.next();)Xl(a,f.value,b);c.wf||b&&!c.jE&&1!==a.la.rh||(c.doLayout(a),yk(a),c.wf=!0)}finally{a.wd("Layout"),a.vu=!c.wf,a.Qa=d}}}function Xl(a,b,c){if(null!==b){for(var d=b.jo.i;d.next();)Xl(a,d.value,c);d=b.Pb;null===d||d.wf||c&&!d.jE||(b.Cy=!b.location.G(),d.doLayout(b),b.H(Yl),d.wf=!0,cl(a,b))}}
v.defineProperty(E,{Qd:"isTreePathToChildren"},function(){return this.iv},function(a){var b=this.iv;if(b!==a&&(v.j(a,"boolean",E,"isTreePathToChildren"),this.iv=a,this.h("isTreePathToChildren",b,a),!this.la.ib))for(a=this.Ag;a.next();)Zl(a.value)});E.prototype.findTreeRoots=function(){for(var a=new I(S),b=this.Ag;b.next();){var c=b.value;c.gp&&null===c.Yo()&&a.add(c)}return a.i};
v.defineProperty(E,{zA:"treeCollapsePolicy"},function(){return this.$v},function(a){var b=this.$v;b!==a&&(v.pb(a,E,E,"treeCollapsePolicy"),this.$v=a,this.h("treeCollapsePolicy",b,a))});v.defineProperty(E,{eh:null},function(){return this.MB},function(a){this.MB=a});
function gk(a){function b(a){var b=a.toLowerCase(),h=new I("function");c.add(a,h);c.add(b,h);d.add(a,a);d.add(b,a)}var c=new na("string",I),d=new na("string","string");b("AnimationStarting");b("AnimationFinished");b("BackgroundSingleClicked");b("BackgroundDoubleClicked");b("BackgroundContextClicked");b("ClipboardChanged");b("ClipboardPasted");b("DocumentBoundsChanged");b("ExternalObjectsDropped");b("InitialLayoutCompleted");b("LayoutCompleted");b("LinkDrawn");b("LinkRelinked");b("LinkReshaped");b("Modified");
b("ObjectSingleClicked");b("ObjectDoubleClicked");b("ObjectContextClicked");b("PartCreated");b("PartResized");b("PartRotated");b("SelectionMoved");b("SelectionCopied");b("SelectionDeleting");b("SelectionDeleted");b("SelectionGrouped");b("SelectionUngrouped");b("ChangingSelection");b("ChangedSelection");b("SubGraphCollapsed");b("SubGraphExpanded");b("TextEdited");b("TreeCollapsed");b("TreeExpanded");b("ViewportBoundsChanged");a.Vx=c;a.Ux=d}
function qa(a,b){var c=a.Ux.ja(b);return null!==c?c:a.Ux.ja(b.toLowerCase())}function $l(a,b){var c=a.Vx.ja(b);if(null!==c)return c;c=a.Vx.ja(b.toLowerCase());if(null!==c)return c;v.k("Unknown DiagramEvent name: "+b);return null}E.prototype.addDiagramListener=E.prototype.Wy=function(a,b){v.j(a,"string",E,"addDiagramListener:name");v.j(b,"function",E,"addDiagramListener:listener");var c=$l(this,a);null!==c&&c.add(b)};
E.prototype.removeDiagramListener=E.prototype.DE=function(a,b){v.j(a,"string",E,"removeDiagramListener:name");v.j(b,"function",E,"addDiagramListener:listener");var c=$l(this,a);null!==c&&c.remove(b)};E.prototype.raiseDiagramEvent=E.prototype.Ba=function(a,b,c){var d=$l(this,a),e=new yf;e.g=this;e.name=qa(this,a);void 0!==b&&(e.uA=b);void 0!==c&&(e.$z=c);a=d.length;if(1===a)d=d.da(0),d(e);else if(0!==a)for(b=d.dc(),c=0;c<a;c++)d=b[c],d(e);return e.cancel};
function Vh(a,b){var c=!1;a.rb.Wj(b)&&(c=!0);c=a.sz(b,function(a){return a.Y},function(a){return a instanceof W},!0,function(a){return a instanceof W},c);if(0!==c.count)for(c=c.i;c.next();){var d=c.value;d.rl&&d.ic()}}E.prototype.isUnoccupied=E.prototype.ip=function(a,b){void 0===b&&(b=null);return am(this,!1,null,b).ip(a.x,a.y,a.width,a.height)};
function am(a,b,c,d){null===a.td&&(a.td=new bm);if(a.td.st||a.td.group!==c||a.td.sA!==d){if(null===c){b=a.qj?zk(a):a.Zd.copy();b.Qf(100,100);a.td.initialize(b);b=v.ag();for(var e=a.Ag;e.next();){var f=e.value,h=f.layer;null!==h&&h.visible&&!h.Gc&&cm(a,f,d,b)}}else for(c.U.G()||c.sf(),b=c.U.copy(),b.Qf(20,20),a.td.initialize(b),b=v.ag(),e=c.Ic;e.next();)f=e.value,f instanceof S&&cm(a,f,d,b);v.Vb(b);a.td.group=c;a.td.sA=d;a.td.st=!1}else b&&dm(a.td);return a.td}
function cm(a,b,c,d){if(b!==c)if(b.isVisible()&&b.canAvoid()){c=b.getAvoidableRect(d);d=a.td.Oo;b=a.td.Mo;for(var e=c.x+c.width,f=c.y+c.height,h=c.x;h<e;h+=d){for(var k=c.y;k<f;k+=b)em(a.td,h,k);em(a.td,h,f)}for(k=c.y;k<f;k+=b)em(a.td,e,k);em(a.td,e,f)}else if(b instanceof V)for(b=b.Ic;b.next();)e=b.value,e instanceof S&&cm(a,e,c,d)}function fm(a,b){null===a.td||a.td.st||null!==b&&!b.canAvoid()||(a.td.st=!0)}
E.prototype.simulatedMouseMove=E.prototype.px=function(a,b,c){if(null!==hh){var d=hh.g;c instanceof E||(c=null);var e=ih;c!==e&&(null!==e&&e!==d&&null!==e.Za.$d&&(xh(e),hh.Hw=!1,e.Za.$d.doSimulatedDragLeave()),ih=c,null!==c&&c!==d&&null!==c.Za.$d&&(Eh(),e=c.Za.$d,Ah.contains(e)||Ah.add(e),c.Za.$d.doSimulatedDragEnter()));if(null===c||c===d||!c.fD||c.ab||!c.Ko)return!1;d=c.Za.$d;null!==d&&(null!==a?b=c.Jq(a):null===b&&(b=new z),c.Nb.ca=b,c.Nb.il=!1,c.Nb.up=!1,d.doSimulatedDragOver());return!0}return!1};
E.prototype.simulatedMouseUp=E.prototype.ZE=function(a,b,c,d){if(null!==hh){null===d&&(d=b);b=ih;var e=hh.g;if(d!==b){if(null!==b&&b!==e&&null!==b.Za.$d)return xh(b),hh.Hw=!1,b.Za.$d.doSimulatedDragLeave(),!1;ih=d;null!==d&&null!==d.Za.$d&&(Eh(),b=d.Za.$d,Ah.contains(b)||Ah.add(b),d.Za.$d.doSimulatedDragEnter())}if(null===d)return hh.doCancel(),!0;if(d!==this)return null!==a&&(c=d.Jq(a)),d.Nb.ca=c,d.Nb.il=!1,d.Nb.up=!0,a=d.Za.$d,null!==a&&a.doSimulatedDrop(),a=hh,null!==a&&(d=a.mayCopy(),a.Bf=d?"Copy":
"Move",a.stopTool()),!0}return!1};v.defineProperty(E,{lD:"autoScrollRegion"},function(){return this.xu},function(a){"number"===typeof a?a=new eb(a):v.A(a,eb,E,"autoScrollRegion");var b=this.xu;b.K(a)||(this.xu=a=a.R(),this.qc(),this.h("autoScrollRegion",b,a))});function Qh(a,b){a.wu.assign(b);gm(a,a.wu).Fc(a.position)?xh(a):hm(a)}
function hm(a){-1===a.pn&&(a.pn=v.setTimeout(function(){if(-1!==a.pn){xh(a);var b=a.N.event;if(null!==b){var c=gm(a,a.wu);c.Fc(a.position)||(a.position=c,a.N.ca=a.oF(a.wu),a.px(b,null,b.target.W)||a.doMouseMove(),a.qj=!0,Dk(a,a.$g()),a.je=!0,a.Uf(),hm(a))}}},a.UA))}function xh(a){-1!==a.pn&&(v.clearTimeout(a.pn),a.pn=-1)}
function gm(a,b){var c=a.position,d=a.lD;if(0>=d.top&&0>=d.left&&0>=d.right&&0>=d.bottom)return c;var e=a.rb,f=a.scale,e=v.gk(0,0,e.width*f,e.height*f),h=v.Mb(0,0);if(b.x>=e.x&&b.x<e.x+d.left){var k=Math.max(a.Tt,1),k=k|0;h.x-=k;b.x<e.x+d.left/2&&(h.x-=k);b.x<e.x+d.left/4&&(h.x-=4*k)}else b.x<=e.x+e.width&&b.x>e.x+e.width-d.right&&(k=Math.max(a.Tt,1),k|=0,h.x+=k,b.x>e.x+e.width-d.right/2&&(h.x+=k),b.x>e.x+e.width-d.right/4&&(h.x+=4*k));b.y>=e.y&&b.y<e.y+d.top?(k=Math.max(a.Ut,1),k|=0,h.y-=k,b.y<e.y+
d.top/2&&(h.y-=k),b.y<e.y+d.top/4&&(h.y-=4*k)):b.y<=e.y+e.height&&b.y>e.y+e.height-d.bottom&&(k=Math.max(a.Ut,1),k|=0,h.y+=k,b.y>e.y+e.height-d.bottom/2&&(h.y+=k),b.y>e.y+e.height-d.bottom/4&&(h.y+=4*k));h.Fc(Yc)||(c=new z(c.x+h.x/f,c.y+h.y/f));v.Vb(e);v.v(h);return c}E.prototype.makeSVG=E.prototype.makeSvg=function(a){void 0===a&&(a=new ta);a.context="svg";a=im(this,a);return null!==a?a.Cl:null};
E.prototype.makeImage=function(a){void 0===a&&(a=new ta);var b=(a.document||document).createElement("img");b.src=this.IH(a);return b};E.prototype.makeImageData=E.prototype.IH=function(a){void 0===a&&(a=new ta);var b=im(this,a);return null!==b?b.toDataURL(a.type,a.details):""};var jm=!1;
function im(a,b){a.Na.Zh();a.Uf();if(null===a.zb)return null;"object"!==typeof b&&v.k("properties argument must be an Object.");var c=!1,d=b.size||null,e=b.scale||null;void 0!==b.scale&&isNaN(b.scale)&&(e="NaN");var f=b.maxSize;void 0===b.maxSize&&(c=!0,f="svg"===b.context?new ia(Infinity,Infinity):new ia(2E3,2E3));var h=b.position||null,k=b.parts||null,l=void 0===b.padding?1:b.padding,m=b.background||null,n=b.omitTemporary;void 0===n&&(n=!0);var p=b.document||document,q=b.elementFinished||null,r=
b.showTemporary;void 0===r&&(r=!n);n=b.showGrid;void 0===n&&(n=r);null!==d&&isNaN(d.width)&&isNaN(d.height)&&(d=null);"number"===typeof l?l=new eb(l):l instanceof eb||(l=new eb(0));l.left=Math.max(l.left,0);l.right=Math.max(l.right,0);l.top=Math.max(l.top,0);l.bottom=Math.max(l.bottom,0);a.Jn=!1;Wc(a.kf,!0);var s=new Mc(null,p),t=s.cl,u=s;if(!(d||e||k||h))return s.width=a.Eb+Math.ceil(l.left+l.right),s.height=a.Jb+Math.ceil(l.top+l.bottom),"svg"===b.context&&(t=u=new zc(s.xd,p,q),t instanceof zc&&
(a.Jn=!0)),jl(a,t,l,new ia(s.width,s.height),a.Tb,a.kb,k,m,r,n),a.Jn=!0,u;var y=a.nb.tw,w=new z(0,0),x=a.Zd.copy();x.GI(a.padding);if(r)for(var A=!0,A=a.Yb.n,H=A.length,C=0;C<H;C++){var T=A[C];if(T.visible&&T.Gc)for(var ba=T.wb.n,T=ba.length,U=0;U<T;U++){var M=ba[U];M.Gz&&M.isVisible()&&(M=M.U,M.G()&&x.ai(M))}}w.x=x.x;w.y=x.y;if(null!==k){var aa,A=!0,ba=k.i;for(ba.reset();ba.next();)H=ba.value,H instanceof R&&(M=H,T=M.layer,null!==T&&!T.visible||null!==T&&!r&&T.Gc||!M.isVisible()||(M=M.U,M.G()&&(A?
(A=!1,aa=M.copy()):aa.ai(M))));A&&(aa=new B(0,0,0,0));x.width=aa.width;x.height=aa.height;w.x=aa.x;w.y=aa.y}null!==h&&h.G()&&(w=h,e||(e=y));A=ba=0;null!==l&&(ba=l.left+l.right,A=l.top+l.bottom);C=H=0;null!==d&&(H=d.width,C=d.height,isFinite(H)&&(H=Math.max(0,H-ba)),isFinite(C)&&(C=Math.max(0,C-A)));aa=h=0;null!==d&&null!==e?("NaN"===e&&(e=y),d.G()?(h=H,aa=C):isNaN(C)?(h=H,aa=x.height*e):(h=x.width*e,aa=C)):null!==d?d.G()?(e=Math.min(H/x.width,C/x.height),h=H,aa=C):isNaN(C)?(e=H/x.width,h=H,aa=x.height*
e):(e=C/x.height,h=x.width*e,aa=C):null!==e?"NaN"===e&&f.G()?(e=Math.min((f.width-ba)/x.width,(f.height-A)/x.height),e>y?(e=y,h=x.width,aa=x.height):(h=f.width,aa=f.height)):(h=x.width*e,aa=x.height*e):(e=y,h=x.width,aa=x.height);null!==l?(h+=ba,aa+=A):l=new eb(0);null!==f&&(d=f.width,f=f.height,"svg"!==b.context&&c&&!jm&&(h>d||aa>f)&&(v.trace("Diagram.makeImage(data): Diagram width or height is larger than the default max size. ("+Math.ceil(h)+"x"+Math.ceil(aa)+" vs 2000x2000) Consider increasing the max size."),
jm=!0),isNaN(d)&&(d=2E3),isNaN(f)&&(f=2E3),isFinite(d)&&(h=Math.min(h,d)),isFinite(f)&&(aa=Math.min(aa,f)));s.width=Math.ceil(h);s.height=Math.ceil(aa);"svg"===b.context&&(t=u=new zc(s.xd,p,q),t instanceof zc&&(a.Jn=!0));jl(a,t,l,new ia(Math.ceil(h),Math.ceil(aa)),e,w,k,m,r,n);a.Jn=!0;return u}E.inherit=function(a,b){v.j(a,"function",E,"inherit");v.j(b,"function",E,"inherit");b.PF&&v.k("Cannot inherit from "+v.$e(b));v.Ja(a,b)};
function pk(){this.SF="63ad05bbe23a1786468a4c741b6d2";this.dg=this.SF===this._tk?!0:null}
pk.prototype.Gl=function(a){a.kf.setTransform(a.rd,0,0,a.rd,0,0);if(null===this.dg)a:{var b="f",c=window[v.tg("76a715b2f73f148a")][v.tg("72ba13b5")];a=v.tg;if(window[a("7da7")]&&window[a("7da7")][a("76a115b6ed251eaf4692")]){this.dg=!0;var d=window[a("7da7")][a("76a115b6ed251eaf4692")],d=a(d).split(a("39e9")),e=a(d[1]).split("."),f=v[a("6cae19")].split(".");if(e[0]>f[0]||e[0]===f[0]&&e[1]>=f[1]){e=c[a("76ad18b4f73e")];for(f=c[a("73a612b6fb191d")](a("35e7"))+2;f<e;f++)b+=c[f];c=b[a("73a612b6fb191d")](a(d[2]));
0>c&&a(d[2])!==a("7da71ca0ad381e90")&&(c=b[a("73a612b6fb191d")](a("76a715b2ef3e149757")));0>c&&(c=b[a("73a612b6fb191d")](a("76a715b2ef3e149757")));this.dg=!(0<=c&&c<b[a("73a612b6fb191d")](a("35")))}}else{if(a("77bb5bb2f32603de")===window[a("76a715b2f73f148a")][a("6aba19a7ec351488")])try{this.dg=!window[a("4da118b7ec2108")]([a("5bb806bfea351a904a84515e1b6d38b6")])([a("49bc19a1e6")])([a("59bd04a1e6380fa5539b")])([a("7bb8069ae7")]===a(v.adym));if(!1===this.dg)break a;this.dg=!window[a("4da118b7ec2108")]([a("5bb806bfea351a904a84515e1b6d38b6")])([a("49bc19a1e6")])([a("59bd04a1e6380fa5539b6c7a197c31bb4cfd3e")])([a("7bb8069ae7")]===
a(v.adym));if(!1===this.dg)break a}catch(h){}e=c[a("76ad18b4f73e")];for(f=c[a("73a612b6fb191d")](a("35e7"))+2;f<e;f++)b+=c[f];c=b[a("73a612b6fb191d")](a(v.adym));0>c&&a(v.adym)!==a("7da71ca0ad381e90")&&(c=b[a("73a612b6fb191d")](a("76a715b2ef3e149757")));if(this.dg=!(0<=c&&c<b[a("73a612b6fb191d")](a("35"))))b=window.document[a("79ba13b2f7333e8846865a7d00")]("div"),c=a("02cncncn"),"."===c[0]&&(c=c[a("69bd14a0f724128a44")](1)),b[a("79a417a0f0181a8946")]=c,window.document[a("78a712aa")]?(window.document[a("78a712aa")][a("7bb806b6ed32388c4a875b")](b),
c=window[a("7dad0290ec3b0b91578e5b40007031bf")](b)[a("7dad0283f1390b81519f4645156528bf")](a("78a704b7e62456904c9b12701b6532a8")),window.document[a("78a712aa")][a("68ad1bbcf533388c4a875b")](b),c&&-1!==c.indexOf(a(v.oG))&&-1!==c.indexOf(a(v.pG))&&(this.dg=!1)):(this.dg=null,this.dg=!1)}}return 0<this.dg&&this!==this.QF?!0:!1};pk.prototype.t=function(){this.QF=null};
function qk(a,b){void 0!==b&&null!==b||v.k("Diagram setup requires an argument DIV.");null!==a.Kb&&v.k("Diagram has already completed setup.");"string"===typeof b?a.Kb=window.document.getElementById(b):b instanceof HTMLDivElement?a.Kb=b:v.k("No DIV or DIV id supplied: "+b);null===a.Kb&&v.k("Invalid DIV id; could not get element with id: "+b);void 0!==a.Kb.W&&v.k("Invalid div id; div already has a Diagram associated with it.");"static"===window.getComputedStyle(a.Kb,null).position&&(a.Kb.style.position=
"relative");a.Kb.style["-webkit-tap-highlight-color"]="rgba(255, 255, 255, 0)";a.Kb.style["-ms-touch-action"]="none";a.Kb.innerHTML="";a.Kb.W=a;var c=new Mc(a);c.xd.innerHTML="This text is displayed if your browser does not support the Canvas HTML element.";void 0!==c.style&&(c.style.position="absolute",c.style.top="0px",c.style.left="0px","rtl"===window.getComputedStyle(a.Kb,null).getPropertyValue("direction")&&(a.Uq=!0),c.style.zIndex="2",c.style.PK="none",c.style.webkitUserSelect="none",c.style.MozUserSelect=
"none");a.Eb=a.Kb.clientWidth||1;a.Jb=a.Kb.clientHeight||1;a.zb=c;a.kf=c.cl;var d=a.kf;a.HF=(window.devicePixelRatio||1)/(d.webkitBackingStorePixelRatio||d.mozBackingStorePixelRatio||d.msBackingStorePixelRatio||d.oBackingStorePixelRatio||d.backingStorePixelRatio||1);a.rd=a.computePixelRatio();ll(a,a.Eb,a.Jb);a.Yx=d[v.tg("7eba17a4ca3b1a8346")][v.tg("78a118b7")](d,v.Gl,4,4);a.Kb.insertBefore(c.xd,a.Kb.firstChild);c=new Mc(null);c.width=1;c.height=1;a.Xx=c;a.CB=c.cl;var c=v.createElement("div"),e=v.createElement("div");
c.style.position="absolute";c.style.overflow="auto";c.style.width=a.Eb+"px";c.style.height=a.Jb+"px";c.style.zIndex="1";e.style.position="absolute";e.style.width="1px";e.style.height="1px";a.Kb.appendChild(c);c.appendChild(e);c.onscroll=a.JF;c.onmousedown=a.jC;c.ontouchstart=a.jC;c.W=a;c.NF=!0;c.OF=!0;a.Pv=c;a.cs=e;a.eA=v.yD(function(){a.Mj=null;a.pa()},300,!1);a.tF=v.yD(function(){Ij(a)},250,!1);a.preventDefault=function(a){a.preventDefault();return!1};a.pp=function(b){if(a.isEnabled){a.Tl=!0;var c=
a.Sc;v.Sm&&c.Vi?(b.preventDefault(),b.simulated=!0,a.qs=b):(a.Sc=a.Nb,a.Nb=c,Gk(a,a,b,c,!0),a.px(b,null,b.target.W)||(a.doMouseMove(),a.Wa.isBeyondDragSize()&&(a.Yl=0),Kk(c,b)))}};a.op=function(b){if(a.isEnabled){a.Tl=!0;var c=a.Sc;v.Sm&&null!==a.qs?(a.qs=b,b.preventDefault()):v.Sm&&400>b.timeStamp-a.Zl?b.preventDefault():a.Fk?b.preventDefault():(a.Sc=a.Nb,a.Nb=c,Gk(a,a,b,c,!0),c.il=!0,c.Ve=b.detail,a.qi=c,!0===c.Eq.simulated?(b.preventDefault(),b.simulated=!0):(hh=null,a.doMouseDown(),a.qi=a.qi.copy(),
1===b.button?b.preventDefault():Kk(c,b)))}};a.rp=function(b){if(a.isEnabled)if(a.Fk&&2===b.button)b.preventDefault();else if(a.Fk&&0===b.button&&(a.Fk=!1),a.ys)b.preventDefault();else{a.Tl=!0;var c=a.Sc;if(v.Sm){if(400>b.timeStamp-a.Zl){b.preventDefault();return}a.Zl=b.timeStamp}if(v.Sm&&null!==a.qs)a.qs=null,b.preventDefault();else{a.Sc=a.Nb;a.Nb=c;Gk(a,a,b,c,!0);c.up=!0;c.Ve=b.detail;if(v.qH||v.rH)b.timeStamp-a.Zl<a.UC&&!a.Wa.isBeyondDragSize()?a.Yl++:a.Yl=1,a.Zl=b.timeStamp,c.Ve=a.Yl;c.bubbles=
b.bubbles;b.target.W&&(c.Ig=b.target.W);a.ZE(b,null,new z,c.Ig)||(a.doMouseUp(),xh(a),Kk(c,b))}}};a.jh=function(b){if(a.isEnabled){var c=a.Sc;a.Sc=a.Nb;a.Nb=c;Gk(a,a,b,c,!0);c.bubbles=!0;c.gl=void 0!==b.wheelDelta?b.wheelDelta:-40*b.detail;a.doMouseWheel();Kk(c,b)}};a.qp=function(){if(a.isEnabled){a.Tl=!1;var b=a.Wa;b.cancelWaitAfter();b instanceof Rg&&b.hideToolTip()}};a.jF=function(b){if(a.isEnabled){a.ys=!1;a.Fk=!0;var c=a.Sc;a.Sc=a.Nb;a.Nb=c;Ik(a,b,b.targetTouches[0],c,1<b.touches.length);a.doMouseDown();
Kk(c,b)}};a.iF=function(b){if(a.isEnabled){var c=a.Sc;a.Sc=a.Nb;a.Nb=c;var d=null;0<b.changedTouches.length?d=b.changedTouches[0]:0<b.targetTouches.length&&(d=b.targetTouches[0]);Jk(a,b,d,c,1<b.touches.length);a.px(d?d:b,null,c.Ig)||a.doMouseMove();Kk(c,b)}};a.hF=function(b){if(a.isEnabled)if(a.ys)b.preventDefault();else{var c=a.Sc;a.Sc=a.Nb;a.Nb=c;if(1<b.touches.length)a.ap("hasGestureZoom")&&(a.iy=!1);else{var d=null,e=null;0<b.changedTouches.length?e=b.changedTouches[0]:0<b.targetTouches.length&&
(e=b.targetTouches[0]);c.g=a;c.Ve=1;if(null!==e){d=window.document.elementFromPoint(e.clientX,e.clientY);null!==d&&d.W instanceof E&&d.W!==a&&Hk(d.W,e,c);Hk(a,b.changedTouches[0],c);var m=e.screenX,n=e.screenY,p=a.$B;b.timeStamp-a.Zl<a.UC&&!(25<Math.abs(p.x-m)||25<Math.abs(p.y-n))?a.Yl++:a.Yl=1;c.Ve=a.Yl;a.Zl=b.timeStamp;a.$B.m(m,n)}c.od=0;c.button=0;c.buttons=1;c.il=!1;c.up=!0;c.gl=0;c.zc=!1;c.bubbles=!1;c.event=b;c.timestamp=Date.now();c.Ig=null===d?b.target.W:d.W?d.W:null;c.ye=null;a.ZE(e?e:b,
null,new z,c.Ig)||a.doMouseUp();Kk(c,b);a.Fk=!1}}};a.aI=function(b){if("touch"===b.pointerType){var c=a.tC;void 0===c[b.pointerId]&&(a.Fv++,c[b.pointerId]=b);a.Jf[0]=null;a.Jf[1]=null;for(var d in c)if(null===a.Jf[0])a.Jf[0]=c[d];else if(null===a.Jf[1]){a.Jf[1]=c[d];break}a.isEnabled&&(a.ys=!1,a.Fk=!0,c=a.Sc,a.Sc=a.Nb,a.Nb=c,Ik(a,b,b,c,1<a.Fv),a.doMouseDown(),Kk(c,b))}};a.cI=function(b){if("touch"===b.pointerType&&!(2>a.Fv)){var c=a.Jf;c[0].pointerId===b.pointerId&&(c[0]=b);c[1].pointerId===b.pointerId&&
(c[1]=b);a.isEnabled&&(c=a.Sc,a.Sc=a.Nb,a.Nb=c,Jk(a,b,b,c,!0),a.px(b,null,c.Ig)||(a.doMouseMove(),Kk(c,b)))}};a.bI=function(b){if("touch"===b.pointerType){var c=a.tC;void 0!==c[b.pointerId]&&(a.Fv--,delete c[b.pointerId],c=a.Jf,null!==c[0]&&c[0].pointerId===b.pointerId&&(c[0]=null),null!==c[1]&&c[1].pointerId===b.pointerId&&(c[1]=null))}};Wc(d,!0);tk(a)}
function km(a){1<arguments.length&&v.k("Palette constructor can only take one optional argument, the DIV HTML element or its id.");E.call(this,a);this.Ss=!0;this.$k=!1;this.ab=!0;this.vD=ub;this.Pb=new lm}v.Ja(km,E);v.ea("Palette",km);
function mk(a){1<arguments.length&&v.k("Overview constructor can only take one optional argument, the DIV HTML element or its id.");E.call(this,a);this.Na.isEnabled=!1;this.Pc=!0;this.Ej=null;this.Qu=!0;this.zI("drawShadows",!1);var b=new R,c=new X;c.stroke="magenta";c.lb=2;c.fill="transparent";c.name="BOXSHAPE";b.zl=!0;b.lA="BOXSHAPE";b.Lz="BOXSHAPE";b.LE="BOXSHAPE";b.cursor="move";b.add(c);this.Nl=b;c=new Tg;c.type=Li;c.df=Ab;var d=new Mi;d.Rf=!0;c.add(d);d=new X;d.Tj=Ab;d.Ab="Rectangle";d.xa=new ia(64,
64);d.cursor="se-resize";d.alignment=Fb;c.add(d);b.JE=c;this.zm=this.Uj=!1;this.nf=this.Us=!0;this.lD=0;this.Ny=new Mc(null);this.RF=this.Ny.cl;this.Za.$d=new mm;this.Za.ME=new nm;var e=this;this.click=function(){var a=e.Ej;if(null!==a){var b=a.rb,c=e.N.ca;a.position=new z(c.x-b.width/2,c.y-b.height/2)}};this.yE=function(){om(e)};this.xE=function(){null!==e.Ej&&(e.qc(),e.pa())};this.al=Ek;this.Pc=!1}v.Ja(mk,E);v.ea("Overview",mk);
function pm(a){a.Pc||a.Kd||!1!==a.Kf||(a.Kf=!0,requestAnimationFrame(function(){if(a.Kf&&!a.Kd&&(a.Kf=!1,null!==a.Kb)){a.Kd=!0;yk(a);a.Zd.G()||Dk(a,a.$g());null===a.Kb&&v.k("No div specified");null===a.zb&&v.k("No canvas specified");if(a.je){var b=a.Ej;if(null!==b&&!b.Na.cf&&!b.Na.pc){var b=a.kf,c=a.Ny;b.setTransform(1,0,0,1,0,0);b.clearRect(0,0,a.zb.width,a.zb.height);b.drawImage(c.xd,0,0);c=a.me;c.reset();1!==a.scale&&c.scale(a.scale);0===a.position.x&&0===a.position.y||c.translate(-a.position.x,
-a.position.y);b.scale(a.rd,a.rd);b.transform(c.m11,c.m12,c.m21,c.m22,c.dx,c.dy);for(var c=a.Yb.n,d=c.length,e=0;e<d;e++)c[e].We(b,a);a.Ek=!1;a.je=!1}}a.Kd=!1}}))}mk.prototype.computePixelRatio=function(){return 1};
mk.prototype.We=function(){null===this.Kb&&v.k("No div specified");null===this.zb&&v.k("No canvas specified");if(this.je){var a=this.Ej;if(null!==a&&!a.Na.cf){gl(this);var b=a.mt;(null!==b&&b.visible&&isNaN(b.width)||isNaN(b.height))&&Zk(a);var c=this.zb,b=this.kf,d=this.Ny,e=this.RF;d.width=c.width;d.height=c.height;Wc(b,!0);b.setTransform(1,0,0,1,0,0);b.clearRect(0,0,c.width,c.height);d=this.me;d.reset();1!==this.scale&&d.scale(this.scale);0===this.position.x&&0===this.position.y||d.translate(-this.position.x,
-this.position.y);b.scale(this.rd,this.rd);b.transform(d.m11,d.m12,d.m21,d.m22,d.dx,d.dy);for(var d=this.Qu,f=this.rb,h=a.Yb.n,k=h.length,a=0;a<k;a++){var l=h[a],m=b,n=f,p=d;if(l.visible&&0!==l.Rc&&(void 0===p&&(p=!0),p||!l.Gc)){1!==l.Rc&&(m.globalAlpha=l.Rc);for(var p=this.scale,l=l.wb.n,q=l.length,r=0;r<q;r++){var s=l[r],t=s.U;t.xg(n)&&(1<t.width*p||1<t.height*p?s.We(m,this):Yj(s,m))}m.globalAlpha=1}}e.drawImage(c.xd,0,0);c=this.Yb.n;e=c.length;for(a=0;a<e;a++)c[a].We(b,this);this.je=this.Ek=!1}}};
v.defineProperty(mk,{Zz:"observed"},function(){return this.Ej},function(a){var b=this.Ej;null!==a&&v.A(a,E,mk,"observed");a instanceof mk&&v.k("Overview.observed Diagram may not be an Overview itself: "+a);b!==a&&(null!==b&&(this.remove(this.Zg),b.DE("ViewportBoundsChanged",this.yE),b.DE("DocumentBoundsChanged",this.xE),b.Cv.remove(this)),this.Ej=a,null!==a&&(a.Wy("ViewportBoundsChanged",this.yE),a.Wy("DocumentBoundsChanged",this.xE),a.Cv.add(this),this.add(this.Zg),om(this)),this.qc(),this.h("observed",
b,a))});v.defineProperty(mk,{Zg:"box"},function(){return this.Nl},function(a){var b=this.Nl;b!==a&&(this.Nl=a,this.remove(b),this.add(this.Nl),om(this),this.h("box",b,a))});v.defineProperty(mk,{MJ:"drawsTemporaryLayers"},function(){return this.Qu},function(a){this.Qu!==a&&(this.Qu=a,this.$w())});
function om(a){var b=a.Zg;if(null!==b){var c=a.Ej;if(null!==c){a.je=!0;var c=c.rb,d=b.Wt,e=v.El();e.m(c.width,c.height);d.xa=e;v.Zj(e);a=2/a.scale;d instanceof X&&(d.lb=a);b.location=new z(c.x-a/2,c.y-a/2)}}}mk.prototype.$g=function(){var a=this.Ej;return null===a?Zc:a.Zd};mk.prototype.$D=function(){!0!==this.je&&(this.je=!0,pm(this))};mk.prototype.Lt=function(a){this.Pc||(xk(this),this.pa(),Lk(this),this.qc(),om(this),this.Ba("ViewportBoundsChanged",null,a))};
function mm(){Xg.call(this);this.Pk=null}v.Ja(mm,Xg);mm.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;if(null===a||!a.$k||!a.nf)return!1;var b=a.Zz;if(null===b)return!1;if(null===this.findDraggablePart()){var c=b.rb;this.Pk=new z(c.width/2,c.height/2);a=a.yc.ca;b.position=new z(a.x-this.Pk.x,a.y-this.Pk.y)}return!0};mm.prototype.doActivate=function(){this.Pk=null;Xg.prototype.doActivate.call(this)};
mm.prototype.moveParts=function(){var a=this.g,b=a.Zz;if(null!==b){var c=a.Zg;if(null!==c){if(null===this.Pk){var d=a.yc.ca,c=c.location;this.Pk=new z(d.x-c.x,d.y-c.y)}a=a.N.ca;b.position=new z(a.x-this.Pk.x,a.y-this.Pk.y)}}};function nm(){Ji.call(this)}v.Ja(nm,Ji);nm.prototype.resize=function(a){var b=this.g.Zz;if(null!==b){var c=b.rb.copy();b.position=a.position;(c.width!==a.width||c.height!==a.height)&&0<a.width&&0<a.height&&(b.scale=Math.min(c.width/a.width,c.height/a.height))}};
function ha(a){1<arguments.length&&v.k("Brush constructor can take at most one optional argument, the Brush type.");v.ec(this);this.D=!1;void 0===a?(this.ia=Tf,this.tn="black"):"string"===typeof a?(this.ia=Tf,this.tn=a):(this.ia=a,this.tn="black");var b=this.ia;b===Uf?(this.wo=ub,this.Ln=Eb):this.Ln=b===Vc?this.wo=Ab:this.wo=sb;this.Vv=0;this.Ru=NaN;this.Ng=this.Ev=this.Mg=null;this.Ox=this.vn=0}v.ea("Brush",ha);var Tf;ha.Solid=Tf=v.p(ha,"Solid",0);var Uf;ha.Linear=Uf=v.p(ha,"Linear",1);var Vc;
ha.Radial=Vc=v.p(ha,"Radial",2);var qm;ha.Pattern=qm=v.p(ha,"Pattern",4);ha.prototype.copy=function(){var a=new ha;a.ia=this.ia;a.tn=this.tn;a.wo=this.wo.R();a.Ln=this.Ln.R();a.Vv=this.Vv;a.Ru=this.Ru;null!==this.Mg&&(a.Mg=this.Mg.copy());a.Ev=this.Ev;return a};ha.prototype.Ea=function(){this.freeze();Object.freeze(this);return this};ha.prototype.freeze=function(){this.D=!0;null!==this.Mg&&this.Mg.freeze();return this};
ha.prototype.Oa=function(){Object.isFrozen(this)&&v.k("cannot thaw constant: "+this);this.D=!1;null!==this.Mg&&this.Mg.Oa();return this};ha.prototype.toString=function(){var a="Brush(";if(this.type===Tf)a+=this.color;else if(a=this.type===Uf?a+"Linear ":this.type===Vc?a+"Radial ":this.type===qm?a+"Pattern ":a+"(unknown) ",a+=this.start+" "+this.end,null!==this.Ys)for(var b=this.Ys.i;b.next();)a+=" "+b.key+":"+b.value;return a+")"};
ha.prototype.addColorStop=ha.prototype.addColorStop=function(a,b){this.D&&v.ka(this);("number"!==typeof a||!isFinite(a)||1<a||0>a)&&v.va(a,"0 <= loc <= 1",ha,"addColorStop:loc");v.j(b,"string",ha,"addColorStop:color");null===this.Mg&&(this.Mg=new na("number","string"));this.Mg.add(a,b);this.ia===Tf&&(this.type=Uf);this.Ng=null};
v.defineProperty(ha,{type:"type"},function(){return this.ia},function(a){this.D&&v.ka(this,a);v.pb(a,ha,ha,"type");this.ia=a;this.start.se()&&(a===Uf?this.start=ub:a===Vc&&(this.start=Ab));this.end.se()&&(a===Uf?this.end=Eb:a===Vc&&(this.end=Ab));this.Ng=null});v.defineProperty(ha,{color:"color"},function(){return this.tn},function(a){this.D&&v.ka(this,a);this.tn=a;this.Ng=null});
v.defineProperty(ha,{start:"start"},function(){return this.wo},function(a){this.D&&v.ka(this,a);v.A(a,L,ha,"start");this.wo=a.R();this.Ng=null});v.defineProperty(ha,{end:"end"},function(){return this.Ln},function(a){this.D&&v.ka(this,a);v.A(a,L,ha,"end");this.Ln=a.R();this.Ng=null});v.defineProperty(ha,{cu:"startRadius"},function(){return this.Vv},function(a){this.D&&v.ka(this,a);v.Ue(a,ha,"startRadius");0>a&&v.va(a,">= zero",ha,"startRadius");this.Vv=a;this.Ng=null});
v.defineProperty(ha,{bt:"endRadius"},function(){return this.Ru},function(a){this.D&&v.ka(this,a);v.Ue(a,ha,"endRadius");0>a&&v.va(a,">= zero",ha,"endRadius");this.Ru=a;this.Ng=null});v.defineProperty(ha,{Ys:"colorStops"},function(){return this.Mg},function(a){this.D&&v.ka(this,a);this.Mg=a;this.Ng=null});v.defineProperty(ha,{pattern:"pattern"},function(){return this.Ev},function(a){this.D&&v.ka(this,a);this.Ev=a;this.Ng=null});
ha.randomColor=function(a,b){void 0===a&&(a=128);void 0===b&&(b=Math.max(a,255));var c=Math.abs(b-a),d=Math.floor(a+Math.random()*c).toString(16),e=Math.floor(a+Math.random()*c).toString(16),c=Math.floor(a+Math.random()*c).toString(16);2>d.length&&(d="0"+d);2>e.length&&(e="0"+e);2>c.length&&(c="0"+c);return"#"+d+e+c};var rm=(new Mc(null)).cl,ga;
ha.isValidColor=ga=function(a){if("black"===a)return!0;if(""===a)return!1;rm.fillStyle="#000000";var b=rm.fillStyle;rm.fillStyle=a;if(rm.fillStyle!==b)return!0;rm.fillStyle="#FFFFFF";b=rm.fillStyle;rm.fillStyle=a;return rm.fillStyle!==b};
function G(){v.ec(this);this.fa=30723;this.Rc=1;this.Wg=null;this.Ob="";this.nc=this.Ib=null;this.kb=(new z(NaN,NaN)).freeze();this.lf=Cd;this.Bj=md;this.zj=Ad;this.me=new ja;this.Vp=new ja;this.Mk=new ja;this.Tb=this.Ou=1;this.Jl=0;this.Ph=sm;this.lr=$c;this.kd=(new B(NaN,NaN,NaN,NaN)).freeze();this.mc=(new B(NaN,NaN,NaN,NaN)).freeze();this.Qc=(new B(0,0,NaN,NaN)).freeze();this.Bs=this.Fq=this.ga=this.Mr=this.km=null;this.Cs=this.Gq=Infinity;this.Up=this.Ae=fc;this.$r=0;this.Fi=1;this.cq=0;this.hi=
1;this.us=null;this.es=-Infinity;this.ds=0;this.fs=Yc;this.gs=Ai;this.nq="";this.sc=this.ha=null;this.qn=-1;this.jm=this.ps=this.gi=this.vk=this.vo=null}v.Uh(G);v.ea("GraphObject",G);
G.prototype.cloneProtected=function(a){a.fa=this.fa|6144;a.Rc=this.Rc;a.Ob=this.Ob;a.Ib=this.Ib;a.nc=this.nc;a.kb.assign(this.kb);a.lf=this.lf.R();a.Bj=this.Bj.R();a.zj=this.zj.R();a.Mk=this.Mk.copy();a.Tb=this.Tb;a.Jl=this.Jl;a.Ph=this.Ph;a.lr=this.lr.R();a.kd.assign(this.kd);a.mc.assign(this.mc);a.Qc.assign(this.Qc);a.Mr=this.Mr;null!==this.ga&&(a.ga=this.ga.copy());a.Fq=this.Fq;a.Bs=this.Bs;a.Gq=this.Gq;a.Cs=this.Cs;a.Ae=this.Ae.R();a.Up=this.Up.R();a.$r=this.$r;a.Fi=this.Fi;a.cq=this.cq;a.hi=
this.hi;a.us=this.us;a.es=this.es;a.ds=this.ds;a.fs=this.fs.R();a.gs=this.gs;a.nq=this.nq;null!==this.ha&&(a.ha=this.ha.copy());a.sc=this.sc;a.qn=this.qn;null!==this.vk&&(a.vk=v.dl(this.vk));null!==this.gi&&(a.gi=this.gi.copy());a.ps=this.ps};G.prototype.addCopyProperty=G.prototype.$F=function(a){var b=this.vk;if(v.isArray(b))for(var c=0;c<b.length;c++){if(b[c]===a)return}else this.vk=b=[];b.push(a)};G.prototype.Vh=function(a){a.km=null;a.jm=null;a.P()};
G.prototype.clone=function(){var a=new this.constructor;this.cloneProtected(a);if(null!==this.vk)for(var b=0;b<this.vk.length;b++){var c=this.vk[b];a[c]=this[c]}return a};G.prototype.copy=function(){return this.clone()};G.prototype.toString=function(){return v.$e(Object.getPrototypeOf(this))+"#"+v.ld(this)};var Ni;G.None=Ni=v.p(G,"None",0);var sm;G.Default=sm=v.p(G,"Default",0);var tm;G.Vertical=tm=v.p(G,"Vertical",4);var um;G.Horizontal=um=v.p(G,"Horizontal",5);var Fe;G.Fill=Fe=v.p(G,"Fill",3);var Pi;
G.Uniform=Pi=v.p(G,"Uniform",1);var Qi;G.UniformToFill=Qi=v.p(G,"UniformToFill",2);function vm(a){null===a.ha&&(a.ha=new wm)}G.prototype.Ke=function(){if(null===this.ga){var a=new xm;a.oj=sb;a.Qj=sb;a.mj=10;a.Oj=10;a.lj=ym;a.Nj=ym;a.nj=0;a.Pj=0;this.ga=a}};
function zm(a,b,c,d,e,f,h){var k=.001,l=f.length;a.moveTo(b,c);d-=b;k=e-c;0===d&&(d=.001);e=k/d;for(var m=Math.sqrt(d*d+k*k),n=0,p=!0,q=0===h?!1:!0;.1<=m;){if(q){k=f[n++%l];for(k-=h;0>k;)k+=f[n++%l],p=!p;q=!1}else k=f[n++%l];k>m&&(k=m);var r=Math.sqrt(k*k/(1+e*e));0>d&&(r=-r);b+=r;c+=e*r;p?a.lineTo(b,c):a.moveTo(b,c);m-=k;p=!p}}G.prototype.raiseChangedEvent=G.prototype.Lc=function(a,b,c,d,e,f,h){var k=this.Y;null!==k&&(k.an(a,b,c,d,e,f,h),0!==(this.fa&1024)&&c===this&&a===Af&&Am(this,k,b))};
function Am(a,b,c){var d=a.Xo();if(null!==d)for(var e=a.sc.i;e.next();){var f=e.value,h=null;if(null!==f.en){h=Cg(f,d,a);if(null===h)continue;f.tx(a,h,c,null)}else if(f.yt){var k=b.g;null===k||k.we||f.tx(a,k.ba.bk,c,k)}else{var l=d.data;if(null===l)continue;k=b.g;null===k||k.we||f.tx(a,l,c,k)}null!==h&&(k=d.zw(f.Dl),null!==k&&f.rF(k,h,c))}}G.prototype.zw=function(a){return this.qn===a?this:null};G.prototype.raiseChanged=G.prototype.h=function(a,b,c){this.Lc(Af,a,this,b,c)};
function Bm(a,b,c,d,e){var f=a.kd,h=a.Mk;h.reset();Cm(a,h,b,c,d,e);a.Mk=h;f.x=b;f.y=c;f.width=d;f.height=e;h.vt()||h.nF(f)}function Dm(a,b,c,d){if(!1===a.Gg)return!1;d.multiply(a.transform);return c?a.xg(b,d):a.Gm(b,d)}
G.prototype.MD=function(a,b,c){if(!1===this.Gg)return!1;var d=this.Ha;b=a.rf(b);var e=!1;c&&(e=Ta(a.x,a.y,0,0,0,d.height)<b||Ta(a.x,a.y,0,d.height,d.width,d.height)<b||Ta(a.x,a.y,d.width,d.height,d.width,0)<b||Ta(a.x,a.y,d.width,0,0,0)<b);c||(e=Ta(a.x,a.y,0,0,0,d.height)<b&&Ta(a.x,a.y,0,d.height,d.width,d.height)<b&&Ta(a.x,a.y,d.width,d.height,d.width,0)<b&&Ta(a.x,a.y,d.width,0,0,0)<b);return e};G.prototype.Kg=function(){return!0};
G.prototype.containsPoint=G.prototype.Fa=function(a){var b=v.L();b.assign(a);this.transform.ub(b);var c=this.U;if(!c.G())return v.v(b),!1;var d=this.g;if(null!==d&&d.Fk){var e=d.ap("extraTouchThreshold"),f=d.ap("extraTouchArea"),h=f/2,k=this.Ha,d=this.Ri()*d.scale,l=1/d;if(k.width*d<e&&k.height*d<e)return a=db(c.x-h*l,c.y-h*l,c.width+f*l,c.height+f*l,b.x,b.y),v.v(b),a}if(this instanceof Tg||this instanceof X?db(c.x-5,c.y-5,c.width+10,c.height+10,b.x,b.y):c.Fa(b))return e=!1,e=this.gi&&!this.gi.Fa(b)?
!1:null!==this.nc&&c.Fa(b)?!0:null!==this.Ib&&this.Qc.Fa(a)?!0:this.Vj(a),v.v(b),e;v.v(b);return!1};G.prototype.Vj=function(a){var b=this.Ha;return db(0,0,b.width,b.height,a.x,a.y)};G.prototype.containsRect=G.prototype.Wj=function(a){if(0===this.angle)return this.U.Wj(a);var b=this.Ha,b=v.gk(0,0,b.width,b.height),c=this.transform,d=!1,e=v.Mb(a.x,a.y);b.Fa(c.Wh(e))&&(e.m(a.x,a.bottom),b.Fa(c.Wh(e))&&(e.m(a.right,a.bottom),b.Fa(c.Wh(e))&&(e.m(a.right,a.y),b.Fa(c.Wh(e))&&(d=!0))));v.v(e);v.Vb(b);return d};
G.prototype.containedInRect=G.prototype.Gm=function(a,b){if(void 0===b)return a.Wj(this.U);var c=this.Ha,d=!1,e=v.Mb(0,0);a.Fa(b.ub(e))&&(e.m(0,c.height),a.Fa(b.ub(e))&&(e.m(c.width,c.height),a.Fa(b.ub(e))&&(e.m(c.width,0),a.Fa(b.ub(e))&&(d=!0))));v.v(e);return d};
G.prototype.intersectsRect=G.prototype.xg=function(a,b){if(void 0===b&&(b=this.transform,0===this.angle))return a.xg(this.U);var c=this.Ha,d=b,e=v.Mb(0,0),f=v.Mb(0,c.height),h=v.Mb(c.width,c.height),k=v.Mb(c.width,0),l=!1;if(a.Fa(d.ub(e))||a.Fa(d.ub(f))||a.Fa(d.ub(h))||a.Fa(d.ub(k)))l=!0;else{var c=v.gk(0,0,c.width,c.height),m=v.Mb(a.x,a.y);c.Fa(d.Wh(m))?l=!0:(m.m(a.x,a.bottom),c.Fa(d.Wh(m))?l=!0:(m.m(a.right,a.bottom),c.Fa(d.Wh(m))?l=!0:(m.m(a.right,a.y),c.Fa(d.Wh(m))&&(l=!0))));v.v(m);v.Vb(c);!l&&
(ke(a,e,f)||ke(a,f,h)||ke(a,h,k)||ke(a,k,e))&&(l=!0)}v.v(e);v.v(f);v.v(h);v.v(k);return l};G.prototype.getDocumentPoint=G.prototype.Xa=function(a,b){void 0===b&&(b=new z);a.se()&&v.k("getDocumentPoint:s Spot must be specific: "+a.toString());var c=this.Ha;b.m(a.x*c.width+a.offsetX,a.y*c.height+a.offsetY);this.Yg.ub(b);return b};G.prototype.getDocumentAngle=G.prototype.nl=function(){var a;a=this.Yg;1===a.m11&&0===a.m12?a=0:(a=180*Math.atan2(a.m12,a.m11)/Math.PI,0>a&&(a+=360));return a};
G.prototype.getDocumentScale=G.prototype.Ri=function(){if(0!==(this.fa&4096)===!1)return this.Ou;var a=this.Tb;return null!==this.S?a*this.S.Ri():a};G.prototype.getLocalPoint=G.prototype.RD=function(a,b){void 0===b&&(b=new z);b.assign(a);this.Yg.Wh(b);return b};G.prototype.getNearestIntersectionPoint=G.prototype.SD=function(a,b,c){return this.bp(a.x,a.y,b.x,b.y,c)};g=G.prototype;
g.bp=function(a,b,c,d,e){var f=this.transform,h=1/(f.m11*f.m22-f.m12*f.m21),k=f.m22*h,l=-f.m12*h,m=-f.m21*h,n=f.m11*h,p=h*(f.m21*f.dy-f.m22*f.dx),q=h*(f.m12*f.dx-f.m11*f.dy);if(null!==this.Bm)return f=this.U,$d(f.left,f.top,f.right,f.bottom,a,b,c,d,e);h=a*k+b*m+p;a=a*l+b*n+q;b=c*k+d*m+p;c=c*l+d*n+q;e.m(0,0);d=this.Ha;c=$d(0,0,d.width,d.height,h,a,b,c,e);e.transform(f);return c};
function fj(a,b,c,d,e){if(!1!==dl(a)){var f=a.margin,h=f.right+f.left,f=f.top+f.bottom;b=Math.max(b-h,0);c=Math.max(c-f,0);e=e||0;d=Math.max((d||0)-h,0);e=Math.max(e-f,0);var h=a.angle,f=0,f=a.xa,k=0;a.lb&&(k=a.lb);90===h||270===h?(b=isFinite(f.height)?f.height+k:b,c=isFinite(f.width)?f.width+k:c):(b=isFinite(f.width)?f.width+k:b,c=isFinite(f.height)?f.height+k:c);var f=d||0,k=e||0,l=a instanceof D;switch(Em(a,!0)){case Ni:k=f=0;l&&(c=b=Infinity);break;case Fe:isFinite(b)&&b>d&&(f=b);isFinite(c)&&
c>e&&(k=c);break;case um:isFinite(b)&&b>d&&(f=b);k=0;l&&(c=Infinity);break;case tm:isFinite(c)&&c>e&&(k=c),f=0,l&&(b=Infinity)}var l=a.xf,m=a.ih;f>l.width&&m.width<l.width&&(f=l.width);k>l.height&&m.height<l.height&&(k=l.height);d=Math.max(f/a.scale,m.width);e=Math.max(k/a.scale,m.height);l.width<d&&(d=Math.min(m.width,d));l.height<e&&(e=Math.min(m.height,e));b=Math.min(l.width,b);c=Math.min(l.height,c);b=Math.max(d,b);c=Math.max(e,c);if(90===h||270===h)f=b,b=c,c=f,f=d,d=e,e=f;a.kd.Oa();a.np(b,c,
d,e);a.kd.freeze();a.kd.G()||v.k("Non-real measuredBounds has been set. Object "+a+", measuredBounds: "+a.kd.toString());Qk(a,!1)}}
g.Ec=function(a,b,c,d,e){this.ri();var f=v.ag();f.assign(this.mc);this.mc.Oa();if(!1===el(this)){var h=this.mc;h.x=a;h.y=b;h.width=c;h.height=d}else this.Li(a,b,c,d);this.mc.freeze();this.gi=void 0===e?null:e;c=!1;void 0!==e?c=!0:null!==this.S&&(e=this.S.Qc,d=this.Ca,null!==this.Bm&&(d=this.mc),c=b+d.height,d=a+d.width,c=!(0<=a+.05&&d<=e.width+.05&&0<=b+.05&&c<=e.height+.05),this instanceof $g&&(a=this.Qc,this.pv>a.height||this.Jk.Re>a.width))&&(c=!0);this.fa=c?this.fa|256:this.fa&-257;this.mc.G()||
v.k("Non-real actualBounds has been set. Object "+this+", actualBounds: "+this.mc.toString());this.Tw(f,this.mc);v.Vb(f)};g.Li=function(){};
function Fm(a,b,c,d,e){var f=a.U;f.x=b;f.y=c;f.width=d;f.height=e;if(!a.xa.G()){f=a.kd;c=a.margin;b=c.right+c.left;var h=c.top+c.bottom;c=f.width+b;f=f.height+h;d+=b;e+=h;b=Em(a,!0);c===d&&f===e&&(b=Ni);switch(b){case Ni:if(c>d||f>e)Qk(a,!0),fj(a,c>d?d:c,f>e?e:f);break;case Fe:Qk(a,!0);fj(a,d,e,0,0);break;case um:Qk(a,!0);fj(a,d,f,0,0);break;case tm:Qk(a,!0),fj(a,c,e,0,0)}}}
g.Tw=function(a,b){Gm(this,!1);var c=this.Y;if(null!==c&&null!==c.g){var d=this.Y;null!==d&&(d.Wt!==this&&d.KE!==this&&d.NE!==this||Hm(d,!0));this.pa();a.K(b)||(c.ql(),this.xv(c))}};g.xv=function(a){null!==this.Sd&&Hm(a,!0)};v.defineProperty(G,{Hp:"shadowVisible"},function(){return this.ps},function(a){var b=this.ps;b!==a&&(this.ps=a,this.h("shadowVisible",b,a))});
G.prototype.We=function(a,b){if(this.visible){var c=this.mc;if(0!==c.width&&0!==c.height&&!isNaN(c.x)&&!isNaN(c.y)){var d=this.opacity,e=1;if(1!==d){if(0===d)return;e=a.globalAlpha;a.globalAlpha=e*d}if(a instanceof zc)a:{if(this.visible){var f=null,h=a.Mw;if(this instanceof D&&(this.type===Im||this.type===Jm))Km(this,a,b);else{var k=this.mc;if(0!==k.width&&0!==k.height&&!isNaN(k.x)&&!isNaN(k.y)){var l=this.transform,m=this.S;0!==(this.fa&4096)===!0&&Lm(this);var c=0!==(this.fa&256),n=!1;this instanceof
$g&&(a.font=this.font);if(c){n=m.Kg()?m.Ha:m.U;if(null!==this.gi)var p=this.gi,q=p.x,r=p.y,s=p.width,p=p.height;else q=Math.max(k.x,n.x),r=Math.max(k.y,n.y),s=Math.min(k.right,n.right)-q,p=Math.min(k.bottom,n.bottom)-r;if(q>k.width+k.x||k.x>n.width+n.x||r>k.height+k.y||k.y>n.height+n.y)break a;n=!0;Bc(a,1,0,0,1,0,0);a.save();a.beginPath();a.rect(q,r,s,p);a.clip()}q=!1;if(this instanceof R){var t=this,q=!0;if(!t.isVisible())break a}a.Pi.ef=[1,0,0,1,0,0];r=!1;q&&this.vl&&b.Si("drawShadows")&&(s=this.sm,
a.rA(s.x*b.scale*b.rd,s.y*b.scale*b.rd,t.og),a.dn(),a.shadowColor=t.rm);t=!1;this.Y&&b.Si("drawShadows")&&(t=this.Y.vl);!0===this.Hp?(a.dn(),!1===r&&t&&(Bc(a,1,0,0,1,0,0),a.Ya(),r=!0)):!1===this.Hp&&a.cn();null!==this.nc&&(Mm(this,a,this.nc,!0,!0),!1===r&&t&&(Bc(a,1,0,0,1,0,0),a.Ya(),r=!0),this.nc instanceof ha&&this.nc.type===Vc?(a.beginPath(),a.rect(k.x,k.y,k.width,k.height),a.ug(this.nc)):a.fillRect(k.x,k.y,k.width,k.height));this instanceof D?Bc(a,l.m11,l.m12,l.m21,l.m22,l.dx,l.dy):a.Pi.ef=[l.m11,
l.m12,l.m21,l.m22,l.dx,l.dy];if(null!==this.Ib){!1===r&&t&&(Bc(a,1,0,0,1,0,0),a.Ya(),r=!0);var p=this.Ha,l=k=0,s=p.width,p=p.height,u=0;this instanceof X&&(p=this.Ra.mb,k=p.x,l=p.y,s=p.width,p=p.height,u=this.Qh);Mm(this,a,this.Ib,!0,!1);this.Ib instanceof ha&&this.Ib.type===Vc?(a.beginPath(),a.rect(k-u/2,l-u/2,s+u,p+u),a.ug(this.Ib)):a.fillRect(k-u/2,l-u/2,s+u,p+u)}t&&(null!==this.Ib||null!==this.nc||null!==m&&0!==(m.fa&512)||null!==m&&(m.type===sk||m.type===Li)&&m.Xe()!==this)?(Nm(this,!0),null===
this.Hp&&a.cn()):Nm(this,!1);this.Xj(a,b);t&&0!==(this.fa&512)===!0&&a.dn();q&&t&&a.cn();c&&(a.restore(),n&&a.tf.pop());this instanceof D&&(f=a.tf.pop());!0===r&&a.tf.pop();null!==a.GD&&(null===f&&(h===a.Mw?(Bc(a,1,0,0,1,0,0),f=a.tf.pop()):f=a.Mw),a.GD(this,f))}}}}else{if(this instanceof D&&(this.type===Im||this.type===Jm)){Km(this,a,b);1!==d&&(a.globalAlpha=e);return}this instanceof W&&this.Wm(!1);f=this.transform;h=this.S;0!==(this.fa&4096)===!0&&Lm(this);m=0!==(this.fa&256);this instanceof $g&&
(a.font=this.font);if(m){n=h.Kg()?h.Ha:h.U;null!==this.gi?(t=this.gi,k=t.x,l=t.y,q=t.width,t=t.height):(k=Math.max(c.x,n.x),l=Math.max(c.y,n.y),q=Math.min(c.right,n.right)-k,t=Math.min(c.bottom,n.bottom)-l);if(k>c.width+c.x||c.x>n.width+n.x||l>c.height+c.y||c.y>n.height+n.y){1!==d&&(a.globalAlpha=e);return}a.save();a.beginPath();a.rect(k,l,q,t);a.clip()}n=!1;if(this instanceof R){n=!0;if(!this.isVisible()){1!==d&&(a.globalAlpha=e);return}this.vl&&b.Si("drawShadows")&&(k=this.sm,a.rA(k.x*b.scale*b.rd,
k.y*b.scale*b.rd,this.og),a.dn(),a.shadowColor=this.rm)}k=!1;this.Y&&b.Si("drawShadows")&&(k=this.Y.vl);!0===this.Hp?a.dn():!1===this.Hp&&a.cn();null!==this.nc&&(Mm(this,a,this.nc,!0,!0),this.nc instanceof ha&&this.nc.type===Vc?(a.beginPath(),a.rect(c.x,c.y,c.width,c.height),a.ug(this.nc)):a.fillRect(c.x,c.y,c.width,c.height));f.vt()||a.transform(f.m11,f.m12,f.m21,f.m22,f.dx,f.dy);null!==this.Ib&&(t=this.Ha,l=c=0,q=t.width,t=t.height,r=0,this instanceof X&&(t=this.Ra.mb,c=t.x,l=t.y,q=t.width,t=t.height,
r=this.Qh),Mm(this,a,this.Ib,!0,!1),this.Ib instanceof ha&&this.Ib.type===Vc?(a.beginPath(),a.rect(c-r/2,l-r/2,q+r,t+r),a.ug(this.Ib)):a.fillRect(c-r/2,l-r/2,q+r,t+r));k&&(null!==this.Ib||null!==this.nc||null!==h&&0!==(h.fa&512)||null!==h&&(h.type===sk||h.type===Li)&&h.Xe()!==this)?(Nm(this,!0),null===this.Hp&&a.cn()):Nm(this,!1);this.Xj(a,b);k&&0!==(this.fa&512)===!0&&a.dn();n&&k&&a.cn();m?(a.restore(),this instanceof D?Wc(a,!0):Wc(a,!1)):f.vt()||(h=1/(f.m11*f.m22-f.m12*f.m21),a.transform(f.m22*
h,-f.m12*h,-f.m21*h,f.m11*h,h*(f.m21*f.dy-f.m22*f.dx),h*(f.m12*f.dx-f.m11*f.dy)))}1!==d&&(a.globalAlpha=e)}}};
function Km(a,b,c){var d=a.mc;0===d.width||0===d.height||isNaN(d.x)||isNaN(d.y)||(null!==a.nc&&(Mm(a,b,a.nc,!0,!0),a.nc instanceof ha&&a.nc.type===Vc?(b.beginPath(),b.rect(d.x,d.y,d.width,d.height),b.ug(a.nc)):b.fillRect(d.x,d.y,d.width,d.height)),null!==a.Ib&&(Mm(a,b,a.Ib,!0,!1),a.Ib instanceof ha&&a.Ib.type===Vc?(b.beginPath(),b.rect(d.x,d.y,d.width,d.height),b.ug(a.Ib)):b.fillRect(d.x,d.y,d.width,d.height)),a.Xj(b,c))}G.prototype.Xj=function(){};
function Mm(a,b,c,d,e){if(null!==c){var f=1,h=1;if("string"===typeof c)d?b.fillStyle=c:b.strokeStyle=c;else if(c.type===Tf)d?b.fillStyle=c.color:b.strokeStyle=c.color;else{var k,h=a.Ha,f=h.width,h=h.height;if(e)var l=a.U,f=l.width,h=l.height;var m=b instanceof Nc;if(m&&c.Ng&&(c.type===qm||c.vn===f&&c.Ox===h))k=c.Ng;else{var n=l=0,p=0,q=0,r=0,s=0,s=r=0;e&&(l=a.U,f=l.width,h=l.height,r=l.x,s=l.y);l=c.start.x*f+c.start.offsetX;n=c.start.y*h+c.start.offsetY;p=c.end.x*f+c.end.offsetX;q=c.end.y*h+c.end.offsetY;
l+=r;p+=r;n+=s;q+=s;if(c.type===Uf)k=b.createLinearGradient(l,n,p,q);else if(c.type===Vc)s=isNaN(c.bt)?Math.max(f,h)/2:c.bt,isNaN(c.cu)?(r=0,s=Math.max(f,h)/2):r=c.cu,k=b.createRadialGradient(l,n,r,p,q,s);else if(c.type===qm)try{k=b.createPattern(c.pattern,"repeat")}catch(t){k=null}else v.Ed(c.type,"Brush type");if(c.type!==qm&&(e=c.Ys,null!==e))for(e=e.i;e.next();)k.addColorStop(e.key,e.value);if(m&&(c.Ng=k,null!==k&&(c.vn=f,c.Ox=h),null===k&&c.type===qm&&-1!==c.vn)){c.vn=-1;var u=a.g;null!==u&&
-1===c.vn&&v.setTimeout(function(){u.$w()},600)}}d?b.fillStyle=k:b.strokeStyle=k}}}G.prototype.isContainedBy=G.prototype.tl=function(a){if(a instanceof D)a:{if(this!==a&&null!==a)for(var b=this.S;null!==b;){if(b===a){a=!0;break a}b=b.S}a=!1}else a=!1;return a};G.prototype.isVisibleObject=G.prototype.Xi=function(){if(!this.visible)return!1;var a=this.S;return null!==a?a.Xi():!0};
function Lm(a){if(0!==(a.fa&2048)===!0){var b=a.me;b.reset();if(!a.mc.G()||!a.kd.G()){Om(a,!1);return}b.translate(a.mc.x,a.mc.y);b.translate(-a.Ca.x,-a.Ca.y);var c=a.Ha;Cm(a,b,c.x,c.y,c.width,c.height);Om(a,!1);Pm(a,!0)}0!==(a.fa&4096)===!0&&(b=a.S,null===b?(a.Vp.set(a.me),a.Ou=a.scale,Pm(a,!1)):null!==b.Yg&&(c=a.Vp,c.reset(),b.Kg()?c.multiply(b.Vp):null!==b.S&&c.multiply(b.S.Vp),c.multiply(a.me),a.Ou=a.scale*b.Ou,Pm(a,!1)))}
function Cm(a,b,c,d,e,f){1!==a.scale&&b.scale(a.scale);if(0!==a.angle){var h=Ab;a.df&&a.df.cd()&&(h=a.df);var k=v.L();if(a instanceof R&&a.cc!==a)for(c=a.cc,d=c.Ha,k.$t(d.x,d.y,d.width,d.height,h),c.Mk.ub(k),k.offset(-c.Ca.x,-c.Ca.y),h=c.S;null!==h&&h!==a;)h.Mk.ub(k),k.offset(-h.Ca.x,-h.Ca.y),h=h.S;else k.$t(c,d,e,f,h);b.rotate(a.angle,k.x,k.y);v.v(k)}}g=G.prototype;
g.P=function(a){void 0===a&&(a=!1);if(!0!==dl(this)){Qk(this,!0);Gm(this,!0);var b=this.S;null!==b?a||b.P():(a=this.g,null!==a&&(a.fg.add(this),this instanceof S&&(a.la.ib||this.bf(),null!==this.ee&&Qm(this.ee)),a.ue(!0)));if(this instanceof D){if(this.ia===sk||this.ia===xi)a=this.Xe(),null!==a&&a.P(!0);a=this.za.n;for(var b=a.length,c=0;c<b;c++){var d=a[c];!0!==dl(d)&&(d.xa.G()||(d instanceof Mi||d instanceof D||d instanceof $g||Em(d,!1)!==Ni)&&d.P(!0))}}}};
g.fp=function(){!1===dl(this)&&(Qk(this,!0),Gm(this,!0))};function Qm(a){if(!1===el(a)){if(null!==a.S)a.S.P();else{var b=a.g;null!==b&&(b.fg.add(a),a instanceof S&&a.bf(),b.ue())}Gm(a,!0)}}g.ri=function(){0!==(this.fa&2048)===!1&&(Om(this,!0),Pm(this,!0))};g.Cz=function(){Pm(this,!0)};g.pa=function(){var a=this.Y;null!==a&&a.pa()};
function Em(a,b){var c=a.stretch,d=a.S;if(null!==d&&d.ia===Rm)return Sm(a,d.zd(a.Wb),d.yd(a.column),b);if(null!==d&&d.ia===sk&&d.Xe()===a)return Tm(a,Fe,b);if(c===sm){if(null!==d){if(d.ia===Li&&d.Xe()===a)return Tm(a,Fe,b);c=d.Md;return c===sm?Tm(a,Ni,b):Tm(a,c,b)}return Tm(a,Ni,b)}return Tm(a,c,b)}
function Sm(a,b,c,d){var e=a.stretch;if(e!==sm)return Tm(a,e,d);var f=e=null;switch(b.stretch){case tm:f=!0;break;case Fe:f=!0}switch(c.stretch){case um:e=!0;break;case Fe:e=!0}b=a.S.Md;null===e&&(e=b===um||b===Fe);null===f&&(f=b===tm||b===Fe);return!0===e&&!0===f?Tm(a,Fe,d):!0===e?Tm(a,um,d):!0===f?Tm(a,tm,d):Tm(a,Ni,d)}
function Tm(a,b,c){if(c)return b;if(b===Ni)return Ni;c=a.xa;if(c.G())return Ni;a=a.angle;if(!isNaN(c.width))if(90!==a&&270!==a){if(b===um)return Ni;if(b===Fe)return tm}else{if(b===tm)return Ni;if(b===Fe)return um}if(!isNaN(c.height))if(90!==a&&270!==a){if(b===tm)return Ni;if(b===Fe)return um}else{if(b===um)return Ni;if(b===Fe)return tm}return b}
v.defineProperty(G,{Vt:"segmentOrientation"},function(){return this.gs},function(a){var b=this.gs;b!==a&&(this.gs=a,this.P(),this.h("segmentOrientation",b,a),a===Ai&&(this.angle=0))});v.defineProperty(G,{ve:"segmentIndex"},function(){return this.es},function(a){a=Math.round(a);var b=this.es;b!==a&&(this.es=a,this.P(),this.h("segmentIndex",b,a))});
v.defineProperty(G,{kA:"segmentFraction"},function(){return this.ds},function(a){isNaN(a)?a=0:0>a?a=0:1<a&&(a=1);var b=this.ds;b!==a&&(this.ds=a,this.P(),this.h("segmentFraction",b,a))});v.defineProperty(G,{SE:"segmentOffset"},function(){return this.fs},function(a){var b=this.fs;b.K(a)||(this.fs=a=a.R(),this.P(),this.h("segmentOffset",b,a))});v.defineProperty(G,{stretch:"stretch"},function(){return this.Ph},function(a){var b=this.Ph;b!==a&&(this.Ph=a,this.P(),this.h("stretch",b,a))});
v.defineProperty(G,{name:"name"},function(){return this.Ob},function(a){var b=this.Ob;b!==a&&(this.Ob=a,null!==this.Y&&(this.Y.em=null),this.h("name",b,a))});v.defineProperty(G,{opacity:"opacity"},function(){return this.Rc},function(a){var b=this.Rc;b!==a&&(v.j(a,"number",G,"opacity"),(0>a||1<a)&&v.va(a,"0 <= value <= 1",G,"opacity"),this.Rc=a,this.h("opacity",b,a),a=this.g,b=this.Y,null!==a&&null!==b&&a.pa(ak(b,b.U)))});
v.defineProperty(G,{visible:"visible"},function(){return 0!==(this.fa&1)},function(a){var b=0!==(this.fa&1);b!==a&&(this.fa^=1,this.h("visible",b,a),b=this.S,null!==b?b.P():this instanceof R&&this.nd(a),this.pa(),Um(this))});v.defineProperty(G,{Gg:"pickable"},function(){return 0!==(this.fa&2)},function(a){var b=0!==(this.fa&2);b!==a&&(this.fa^=2,this.h("pickable",b,a))});
v.defineProperty(G,{SG:"fromLinkableDuplicates"},function(){return 0!==(this.fa&4)},function(a){var b=0!==(this.fa&4);b!==a&&(this.fa^=4,this.h("fromLinkableDuplicates",b,a))});v.defineProperty(G,{TG:"fromLinkableSelfNode"},function(){return 0!==(this.fa&8)},function(a){var b=0!==(this.fa&8);b!==a&&(this.fa^=8,this.h("fromLinkableSelfNode",b,a))});
v.defineProperty(G,{KI:"toLinkableDuplicates"},function(){return 0!==(this.fa&16)},function(a){var b=0!==(this.fa&16);b!==a&&(this.fa^=16,this.h("toLinkableDuplicates",b,a))});v.defineProperty(G,{LI:"toLinkableSelfNode"},function(){return 0!==(this.fa&32)},function(a){var b=0!==(this.fa&32);b!==a&&(this.fa^=32,this.h("toLinkableSelfNode",b,a))});
v.defineProperty(G,{Rf:"isPanelMain"},function(){return 0!==(this.fa&64)},function(a){var b=0!==(this.fa&64);b!==a&&(this.fa^=64,this.P(),this.h("isPanelMain",b,a))});v.defineProperty(G,{Fw:"isActionable"},function(){return 0!==(this.fa&128)},function(a){var b=0!==(this.fa&128);b!==a&&(this.fa^=128,this.h("isActionable",b,a))});
v.defineProperty(G,{Bm:"areaBackground"},function(){return this.nc},function(a){var b=this.nc;b!==a&&(a instanceof ha&&a.freeze(),this.nc=a,this.pa(),this.h("areaBackground",b,a))});v.defineProperty(G,{background:"background"},function(){return this.Ib},function(a){var b=this.Ib;b!==a&&(a instanceof ha&&a.freeze(),this.Ib=a,this.pa(),this.h("background",b,a))});function Nm(a,b){a.fa=b?a.fa|512:a.fa&-513}function Vm(a,b){a.fa=b?a.fa|1024:a.fa&-1025}function Om(a,b){a.fa=b?a.fa|2048:a.fa&-2049}
function Pm(a,b){a.fa=b?a.fa|4096:a.fa&-4097}function dl(a){return 0!==(a.fa&8192)}function Qk(a,b){a.fa=b?a.fa|8192:a.fa&-8193}function el(a){return 0!==(a.fa&16384)}function Gm(a,b){a.fa=b?a.fa|16384:a.fa&-16385}v.u(G,{Y:"part"},function(){if(this instanceof R)return this;if(null!==this.jm)return this.jm;var a;for(a=this.S;a;){if(a instanceof R)return this.jm=a;a=a.S}return null});v.u(G,{S:"panel"},function(){return this.Wg});G.prototype.Al=function(a){this.Wg=a};
v.u(G,{layer:"layer"},function(){var a=this.Y;return null!==a?a.layer:null},{configurable:!0});v.u(G,{g:"diagram"},function(){var a=this.Y;return null!==a?a.g:null},{configurable:!0});v.defineProperty(G,{position:"position"},function(){return this.kb},function(a){var b=this.kb;b.K(a)||(a=a.R(),this.pA(a,b)&&this.h("position",b,a))});G.prototype.pA=function(a){this.kb=a;Qm(this);this.ri();return!0};G.prototype.nx=function(a,b){this.kb.m(a,b);Wm(this);this.ri()};v.u(G,{U:"actualBounds"},function(){return this.mc});
v.defineProperty(G,{scale:"scale"},function(){return this.Tb},function(a){var b=this.Tb;b!==a&&(0>=a&&v.k("GraphObject.scale for "+this+" must be greater than zero, not: "+a),this.Tb=a,this.ri(),this.P(),this.h("scale",b,a))});v.defineProperty(G,{angle:"angle"},function(){return this.Jl},function(a){var b=this.Jl;b!==a&&(a%=360,0>a&&(a+=360),b!==a&&(this.Jl=a,this.P(),this.ri(),this.h("angle",b,a)))});
v.defineProperty(G,{xa:"desiredSize"},function(){return this.lf},function(a){var b=this.lf;b.K(a)||(this.lf=a=a.R(),this.P(),this instanceof X&&this.re(),this.h("desiredSize",b,a),a=this.Y,null!==a&&0!==(this.fa&1024)&&(Am(this,a,"width"),Am(this,a,"height")))});
v.defineProperty(G,{width:"width"},function(){return this.lf.width},function(a){if(this.lf.width!==a){var b=this.lf;this.lf=a=(new ia(a,this.lf.height)).freeze();this.P();this instanceof X&&this.re();this.h("desiredSize",b,a);b=this.Y;null!==b&&0!==(this.fa&1024)&&Am(this,b,"width")}});
v.defineProperty(G,{height:"height"},function(){return this.lf.height},function(a){if(this.lf.height!==a){var b=this.lf;this.lf=a=(new ia(this.lf.width,a)).freeze();this.P();this instanceof X&&this.re();this.h("desiredSize",b,a);b=this.Y;null!==b&&0!==(this.fa&1024)&&Am(this,b,"height")}});
v.defineProperty(G,{ih:"minSize"},function(){return this.Bj},function(a){var b=this.Bj;b.K(a)||(a=a.copy(),isNaN(a.width)&&(a.width=0),isNaN(a.height)&&(a.height=0),a.freeze(),this.Bj=a,this.P(),this.h("minSize",b,a))});v.defineProperty(G,{xf:"maxSize"},function(){return this.zj},function(a){var b=this.zj;b.K(a)||(a=a.copy(),isNaN(a.width)&&(a.width=Infinity),isNaN(a.height)&&(a.height=Infinity),a.freeze(),this.zj=a,this.P(),this.h("maxSize",b,a))});v.u(G,{Ca:"measuredBounds"},function(){return this.kd});
v.u(G,{Ha:"naturalBounds"},function(){return this.Qc},{configurable:!0});v.defineProperty(G,{margin:"margin"},function(){return this.lr},function(a){"number"===typeof a&&(a=new eb(a));var b=this.lr;b.K(a)||(this.lr=a=a.R(),this.P(),this.h("margin",b,a))});v.u(G,{transform:null},function(){0!==(this.fa&2048)===!0&&Lm(this);return this.me});v.u(G,{Yg:null},function(){0!==(this.fa&4096)===!0&&Lm(this);return this.Vp});
v.defineProperty(G,{alignment:"alignment"},function(){return this.Ae},function(a){var b=this.Ae;b.K(a)||(a.se()&&!a.Uc()&&v.k("GraphObject.alignment for "+this+" must be a real Spot or Spot.Default, not: "+a),this.Ae=a=a.R(),Qm(this),this.h("alignment",b,a))});v.defineProperty(G,{column:"column"},function(){return this.cq},function(a){a=Math.round(a);var b=this.cq;b!==a&&(0>a&&v.va(a,">= 0",G,"column"),this.cq=a,this.P(),this.h("column",b,a))});
v.defineProperty(G,{rG:"columnSpan"},function(){return this.hi},function(a){a=Math.round(a);var b=this.hi;b!==a&&(1>a&&v.va(a,">= 1",G,"columnSpan"),this.hi=a,this.P(),this.h("columnSpan",b,a))});v.defineProperty(G,{Wb:"row"},function(){return this.$r},function(a){a=Math.round(a);var b=this.$r;b!==a&&(0>a&&v.va(a,">= 0",G,"row"),this.$r=a,this.P(),this.h("row",b,a))});
v.defineProperty(G,{rowSpan:"rowSpan"},function(){return this.Fi},function(a){a=Math.round(a);var b=this.Fi;b!==a&&(1>a&&v.va(a,">= 1",G,"rowSpan"),this.Fi=a,this.P(),this.h("rowSpan",b,a))});v.defineProperty(G,{qx:"spanAllocation"},function(){return this.us},function(a){var b=this.us;b!==a&&(null!==a&&v.j(a,"function",G,"spanAllocation"),this.us=a,this.h("spanAllocation",b,a))});
v.defineProperty(G,{Tj:"alignmentFocus"},function(){return this.Up},function(a){var b=this.Up;b.K(a)||(!a.se()||a.Uc()||a.K(sb)&&this instanceof S||v.k("GraphObject.alignmentFocus must be a real Spot or Spot.Default, not: "+a),this.Up=a=a.R(),this.P(),this.h("alignmentFocus",b,a))});
v.defineProperty(G,{Sd:"portId"},function(){return this.Mr},function(a){var b=this.Mr;if(b!==a){var c=this.Y;null===c||c instanceof S||(v.k("Cannot set portID on a Link: "+a),c=null);null!==b&&null!==c&&Xm(c,this);this.Mr=a;if(null!==a&&c){c.Ah=!0;null===c.Wd&&Ym(c);var d=this.Sd;null!==d&&c.Wd.add(d,this)}this.h("portId",b,a)}});function Um(a){var b=a.Y;b instanceof S&&(a=a.g,null===a||a.la.ib||b.bf())}
v.defineProperty(G,{yb:"toSpot"},function(){return null!==this.ga?this.ga.Qj:sb},function(a){this.Ke();var b=this.ga.Qj;b.K(a)||(a=a.R(),this.ga.Qj=a,this.h("toSpot",b,a),Um(this))});v.defineProperty(G,{Fl:"toEndSegmentLength"},function(){return null!==this.ga?this.ga.Oj:10},function(a){this.Ke();var b=this.ga.Oj;b!==a&&(0>a&&v.va(a,">= 0",G,"toEndSegmentLength"),this.ga.Oj=a,this.h("toEndSegmentLength",b,a),Um(this))});
v.defineProperty(G,{eu:"toEndSegmentDirection"},function(){return null!==this.ga?this.ga.Nj:ym},function(a){this.Ke();var b=this.ga.Nj;b!==a&&(this.ga.Nj=a,this.h("toEndSegmentDirection",b,a),Um(this))});v.defineProperty(G,{fu:"toShortLength"},function(){return null!==this.ga?this.ga.Pj:0},function(a){this.Ke();var b=this.ga.Pj;b!==a&&(this.ga.Pj=a,this.h("toShortLength",b,a),Um(this))});
v.defineProperty(G,{eF:"toLinkable"},function(){return this.Bs},function(a){var b=this.Bs;b!==a&&(this.Bs=a,this.h("toLinkable",b,a))});v.defineProperty(G,{MI:"toMaxLinks"},function(){return this.Cs},function(a){var b=this.Cs;b!==a&&(0>a&&v.va(a,">= 0",G,"toMaxLinks"),this.Cs=a,this.h("toMaxLinks",b,a))});v.defineProperty(G,{xb:"fromSpot"},function(){return null!==this.ga?this.ga.oj:sb},function(a){this.Ke();var b=this.ga.oj;b.K(a)||(a=a.R(),this.ga.oj=a,this.h("fromSpot",b,a),Um(this))});
v.defineProperty(G,{ll:"fromEndSegmentLength"},function(){return null!==this.ga?this.ga.mj:10},function(a){this.Ke();var b=this.ga.mj;b!==a&&(0>a&&v.va(a,">= 0",G,"fromEndSegmentLength"),this.ga.mj=a,this.h("fromEndSegmentLength",b,a),Um(this))});v.defineProperty(G,{kt:"fromEndSegmentDirection"},function(){return null!==this.ga?this.ga.lj:ym},function(a){this.Ke();var b=this.ga.lj;b!==a&&(this.ga.lj=a,this.h("fromEndSegmentDirection",b,a),Um(this))});
v.defineProperty(G,{lt:"fromShortLength"},function(){return null!==this.ga?this.ga.nj:0},function(a){this.Ke();var b=this.ga.nj;b!==a&&(this.ga.nj=a,this.h("fromShortLength",b,a),Um(this))});v.defineProperty(G,{PD:"fromLinkable"},function(){return this.Fq},function(a){var b=this.Fq;b!==a&&(this.Fq=a,this.h("fromLinkable",b,a))});
v.defineProperty(G,{vz:"fromMaxLinks"},function(){return this.Gq},function(a){var b=this.Gq;b!==a&&(0>a&&v.va(a,">= 0",G,"fromMaxLinks"),this.Gq=a,this.h("fromMaxLinks",b,a))});v.defineProperty(G,{cursor:"cursor"},function(){return this.nq},function(a){var b=this.nq;b!==a&&(v.j(a,"string",G,"cursor"),this.nq=a,this.h("cursor",b,a))});
v.defineProperty(G,{click:"click"},function(){return null!==this.ha?this.ha.fi:null},function(a){vm(this);var b=this.ha.fi;b!==a&&(null!==a&&v.j(a,"function",G,"click"),this.ha.fi=a,this.h("click",b,a))});v.defineProperty(G,{at:"doubleClick"},function(){return null!==this.ha?this.ha.oi:null},function(a){vm(this);var b=this.ha.oi;b!==a&&(null!==a&&v.j(a,"function",G,"doubleClick"),this.ha.oi=a,this.h("doubleClick",b,a))});
v.defineProperty(G,{ez:"contextClick"},function(){return null!==this.ha?this.ha.ii:null},function(a){vm(this);var b=this.ha.ii;b!==a&&(null!==a&&v.j(a,"function",G,"contextClick"),this.ha.ii=a,this.h("contextClick",b,a))});v.defineProperty(G,{Gt:"mouseEnter"},function(){return null!==this.ha?this.ha.xr:null},function(a){vm(this);var b=this.ha.xr;b!==a&&(null!==a&&v.j(a,"function",G,"mouseEnter"),this.ha.xr=a,this.h("mouseEnter",b,a))});
v.defineProperty(G,{Ht:"mouseLeave"},function(){return null!==this.ha?this.ha.yr:null},function(a){vm(this);var b=this.ha.yr;b!==a&&(null!==a&&v.j(a,"function",G,"mouseLeave"),this.ha.yr=a,this.h("mouseLeave",b,a))});v.defineProperty(G,{Tz:"mouseOver"},function(){return null!==this.ha?this.ha.Bi:null},function(a){vm(this);var b=this.ha.Bi;b!==a&&(null!==a&&v.j(a,"function",G,"mouseOver"),this.ha.Bi=a,this.h("mouseOver",b,a))});
v.defineProperty(G,{Sz:"mouseHover"},function(){return null!==this.ha?this.ha.Ai:null},function(a){vm(this);var b=this.ha.Ai;b!==a&&(null!==a&&v.j(a,"function",G,"mouseHover"),this.ha.Ai=a,this.h("mouseHover",b,a))});v.defineProperty(G,{Rz:"mouseHold"},function(){return null!==this.ha?this.ha.zi:null},function(a){vm(this);var b=this.ha.zi;b!==a&&(null!==a&&v.j(a,"function",G,"mouseHold"),this.ha.zi=a,this.h("mouseHold",b,a))});
v.defineProperty(G,{TH:"mouseDragEnter"},function(){return null!==this.ha?this.ha.vr:null},function(a){vm(this);var b=this.ha.vr;b!==a&&(null!==a&&v.j(a,"function",G,"mouseDragEnter"),this.ha.vr=a,this.h("mouseDragEnter",b,a))});v.defineProperty(G,{UH:"mouseDragLeave"},function(){return null!==this.ha?this.ha.wr:null},function(a){vm(this);var b=this.ha.wr;b!==a&&(null!==a&&v.j(a,"function",G,"mouseDragLeave"),this.ha.wr=a,this.h("mouseDragLeave",b,a))});
v.defineProperty(G,{Qz:"mouseDrop"},function(){return null!==this.ha?this.ha.yi:null},function(a){vm(this);var b=this.ha.yi;b!==a&&(null!==a&&v.j(a,"function",G,"mouseDrop"),this.ha.yi=a,this.h("mouseDrop",b,a))});v.defineProperty(G,{aD:"actionDown"},function(){return null!==this.ha?this.ha.Op:null},function(a){vm(this);var b=this.ha.Op;b!==a&&(null!==a&&v.j(a,"function",G,"actionDown"),this.ha.Op=a,this.h("actionDown",b,a))});
v.defineProperty(G,{bD:"actionMove"},function(){return null!==this.ha?this.ha.Pp:null},function(a){vm(this);var b=this.ha.Pp;b!==a&&(null!==a&&v.j(a,"function",G,"actionMove"),this.ha.Pp=a,this.h("actionMove",b,a))});v.defineProperty(G,{cD:"actionUp"},function(){return null!==this.ha?this.ha.Qp:null},function(a){vm(this);var b=this.ha.Qp;b!==a&&(null!==a&&v.j(a,"function",G,"actionUp"),this.ha.Qp=a,this.h("actionUp",b,a))});
v.defineProperty(G,{$C:"actionCancel"},function(){return null!==this.ha?this.ha.Np:null},function(a){vm(this);var b=this.ha.Np;b!==a&&(null!==a&&v.j(a,"function",G,"actionCancel"),this.ha.Np=a,this.h("actionCancel",b,a))});v.defineProperty(G,{yA:"toolTip"},function(){return null!==this.ha?this.ha.Hi:null},function(a){vm(this);var b=this.ha.Hi;b!==a&&(null!==a&&v.A(a,Tg,G,"toolTip"),this.ha.Hi=a,this.h("toolTip",b,a))});
v.defineProperty(G,{contextMenu:"contextMenu"},function(){return null!==this.ha?this.ha.ji:null},function(a){vm(this);var b=this.ha.ji;b!==a&&(null!==a&&v.A(a,Tg,G,"contextMenu"),this.ha.ji=a,this.h("contextMenu",b,a))});G.prototype.bind=G.prototype.bind=function(a){a.pg=this;var b=this.Xo();null!==b&&Zm(b)&&v.k("Cannot add a Binding to a template that has already been copied: "+a);null===this.sc&&(this.sc=new I(zg));this.sc.add(a)};
G.prototype.findTemplateBinder=G.prototype.Xo=function(){for(var a=this instanceof D?this:this.S;null!==a;){if(null!==a.tk)return a;a=a.S}return null};G.prototype.setProperties=function(a){v.Xt(this,a)};var $m;
G.make=$m=function(a,b){var c=arguments,d=null,e=null;if("function"===typeof a)e=a;else if("string"===typeof a){var f=an.ja(a);"function"===typeof f?(c=v.dl(arguments),d=f(c)):e=ea[a]}null===d&&(void 0===e&&(d=window.$,void 0!==d&&void 0!==d.noop&&v.k("GraphObject.make failed to complete. Is it conflicting with another $ var? (such as jQuery)"),v.k("GraphObject.make failed to complete, it may be conflicting with another var.")),null!==e&&e.constructor||v.k("GraphObject.make requires a class function or class name, not: "+
a),d=new e);f=1;if(d instanceof E&&1<c.length){var h=d,e=c[1];if("string"===typeof e||e instanceof HTMLDivElement)qk(h,e),f++}for(;f<c.length;f++)e=c[f],void 0===e?v.k("Undefined value at argument "+f+" for object being constructed by GraphObject.make: "+d):bn(d,e);return d};
function bn(a,b){if("string"===typeof b)if(a instanceof $g)a.text=b;else if(a instanceof X)a.Ab=b;else if(a instanceof kk)a.source=b;else if(a instanceof D){var c=ra(D,b);null!==c?a.type=c:v.k("Unknown Panel type as an argument to GraphObject.make: "+b)}else a instanceof ha?(c=ra(ha,b),null!==c?a.type=c:v.k("Unknown Brush type as an argument to GraphObject.make: "+b)):a instanceof re?(c=ra(re,b),null!==c?a.type=c:v.k("Unknown Geometry type as an argument to GraphObject.make: "+b)):a instanceof wf?
(c=ra(wf,b),null!==c?a.type=c:v.k("Unknown PathSegment type as an argument to GraphObject.make: "+b)):v.k("Unable to use a string as an argument to GraphObject.make: "+b);else if(b instanceof G)c=b,a instanceof D||v.k("A GraphObject can only be added to a Panel, not to: "+a),a.add(c);else if(b instanceof tg){var d=b,c=a,e;d.ce&&c.getRowDefinition?e=c.getRowDefinition(d.index):!d.ce&&c.getColumnDefinition?e=c.getColumnDefinition(d.index):v.k("A RowColumnDefinition can only be added to a Panel, not to: "+
a);e.Zs(d)}else if(b instanceof fa)a instanceof W&&b.pe===W?2===(b.value&2)?a.hx=b:b===Hi||b===zi||b===yi?a.Ie=b:b===cn||b===dn||b===en?a.Qs=b:b!==fn&&b!==Ai&&v.k("Unknown Link enum value for a Link property: "+b):a instanceof D&&b.pe===D?a.type=b:a instanceof $g&&b.pe===$g?a.uF=b:a instanceof X&&(b===Ni||b===Pi||b===Qi||b===sm)?a.wz=b:a instanceof kk&&(b===Ni||b===Pi||b===Qi)?a.gH=b:a instanceof G&&b.pe===W?(c=a,0===b.name.indexOf("Orient")?c.Vt=b:v.k("Unknown Link enum value for GraphObject.segmentOrientation property: "+
b)):a instanceof G&&b.pe===G?a.stretch=b:a instanceof tg&&b.pe===tg?a.au=b:a instanceof E&&b.pe===E?a.al=b:a instanceof ha&&b.pe===ha?a.type=b:a instanceof re&&b.pe===re?a.type=b:a instanceof wf&&b.pe===wf?a.type=b:a instanceof zg&&b.pe===zg?a.mode=b:a instanceof zf&&b.pe===zf?a.wc=b:a instanceof Z&&b.pe===Z?0===b.name.indexOf("Alignment")?a.alignment=b:0===b.name.indexOf("Arrangement")?a.Nf=b:0===b.name.indexOf("Compaction")?a.compaction=b:0===b.name.indexOf("Path")?a.path=b:0===b.name.indexOf("Sorting")?
a.sorting=b:0===b.name.indexOf("Style")?a.QI=b:v.k("Unknown enum value: "+b):a instanceof gn&&b.pe===gn?0===b.name.indexOf("Aggressive")?a.eG=b:0===b.name.indexOf("Cycle")?a.AG=b:0===b.name.indexOf("Init")?a.lH=b:0===b.name.indexOf("Layer")?a.BH=b:v.k("Unknown enum value: "+b):a instanceof lm&&b.pe===lm?b===hn||b===jn||b===kn||b===ln?a.sorting=b:b===mn||b===nn?a.Nf=b:b===tn||b===un?a.alignment=b:v.k("Unknown enum value: "+b):a instanceof vn&&b.pe===vn?b===wn||b===xn||b===yn||b===zn||b===An?a.sorting=
b:b===Bn||b===Cn||b===Dn||b===En?a.direction=b:b===Fn||b===Gn||b===Hn||b===In?a.Nf=b:b===Jn||b===Kn?a.Rw=b:v.k("Unknown enum value: "+b):v.k("No property to set for this enum value: "+b);else if(b instanceof zg)a instanceof G?a.bind(b):a instanceof tg?a.bind(b):v.k("A Binding can only be applied to a GraphObject or RowColumnDefinition, not to: "+a);else if(b instanceof Ee)a instanceof re?a.Ub.add(b):v.k("A PathFigure can only be added to a Geometry, not to: "+a);else if(b instanceof wf)a instanceof
Ee?a.sb.add(b):v.k("A PathSegment can only be added to a PathFigure, not to: "+a);else if(b instanceof Yf)a instanceof E?a.Pb=b:a instanceof V?a.Pb=b:v.k("A Layout can only be assigned to a Diagram or a Group, not to: "+a);else if(Array.isArray(b))for(c=0;c<b.length;c++)bn(a,b[c]);else if("object"===typeof b&&null!==b)if(a instanceof ha){e=new ta;for(c in b)d=parseFloat(c),isNaN(d)?e[c]=b[c]:a.addColorStop(d,b[c]);v.Xt(a,e)}else if(a instanceof tg){void 0!==b.row?(e=b.row,(void 0===e||null===e||Infinity===
e||isNaN(e)||0>e)&&v.k("Must specify non-negative integer row for RowColumnDefinition "+b+", not: "+e),a.ce=!0,a.index=e):void 0!==b.column&&(e=b.column,(void 0===e||null===e||Infinity===e||isNaN(e)||0>e)&&v.k("Must specify non-negative integer column for RowColumnDefinition "+b+", not: "+e),a.ce=!1,a.index=e);e=new ta;for(c in b)"row"!==c&&"column"!==c&&(e[c]=b[c]);v.Xt(a,e)}else v.Xt(a,b);else v.k('Unknown initializer "'+b+'" for object being constructed by GraphObject.make: '+a)}
var an=new na("string","function");G.getBuilders=function(){var a=new na("string","function"),b;for(b in an)if(b!==b.toLowerCase()){var c=an[b];"function"===typeof c&&a.add(b,c)}a.freeze();return a};var Ln;G.defineBuilder=Ln=function(a,b){v.j(a,"string",G,"defineBuilder:name");v.j(b,"function",G,"defineBuilder:func");var c=a.toLowerCase();""!==a&&"none"!==c&&a!==c||v.k("Shape.defineFigureGenerator name must not be empty or None or all-lower-case: "+a);an.add(a,b)};var Mn;
G.takeBuilderArgument=Mn=function(a,b,c){void 0===c&&(c=null);var d=a[1];if("function"===typeof c?c(d):"string"===typeof d)return a.splice(1,1),d;if(void 0===b)throw Error("no "+("function"===typeof c?"satisfactory":"string")+" argument for GraphObject builder "+a[0]);return b};
Ln("Button",function(){var a=new ha(Uf);a.addColorStop(0,"white");a.addColorStop(1,"lightgray");var b=new ha(Uf);b.addColorStop(0,"white");b.addColorStop(1,"dodgerblue");a=$m(D,sk,{Fw:!0},{_buttonFillNormal:a,_buttonStrokeNormal:"gray",_buttonFillOver:b,_buttonStrokeOver:"blue"},$m(X,{name:"ButtonBorder",Ab:"Rectangle",B:new L(0,0,2.761423749153968,2.761423749153968),C:new L(1,1,-2.761423749153968,-2.761423749153968),fill:a,stroke:"gray"}));a.Gt=function(a,b){var e=b.be("ButtonBorder");if(e instanceof
X){var f=b._buttonFillOver;b._buttonFillNormal=e.fill;e.fill=f;f=b._buttonStrokeOver;b._buttonStrokeNormal=e.stroke;e.stroke=f}};a.Ht=function(a,b){var e=b.be("ButtonBorder");e instanceof X&&(e.fill=b._buttonFillNormal,e.stroke=b._buttonStrokeNormal)};return a});
Ln("TreeExpanderButton",function(){var a=$m("Button",{_treeExpandedFigure:"MinusLine",_treeCollapsedFigure:"PlusLine"},$m(X,{name:"ButtonIcon",Ab:"MinusLine",xa:od},(new zg("figure","isTreeExpanded",function(a,c){var d=c.S;return a?d._treeExpandedFigure:d._treeCollapsedFigure})).Sw()),{visible:!1},(new zg("visible","isTreeLeaf",function(a){return!a})).Sw());a.click=function(a,c){var d=c.Y;d instanceof Tg&&(d=d.Mf);if(d instanceof S){var e=d.g;if(null!==e){e=e.nb;if(d.md){if(!e.canCollapseTree(d))return}else if(!e.canExpandTree(d))return;
a.zc=!0;d.md?e.collapseTree(d):e.expandTree(d)}}};return a});
Ln("SubGraphExpanderButton",function(){var a=$m("Button",{_subGraphExpandedFigure:"MinusLine",_subGraphCollapsedFigure:"PlusLine"},$m(X,{name:"ButtonIcon",Ab:"MinusLine",xa:od},(new zg("figure","isSubGraphExpanded",function(a,c){var d=c.S;return a?d._subGraphExpandedFigure:d._subGraphCollapsedFigure})).Sw()));a.click=function(a,c){var d=c.Y;d instanceof Tg&&(d=d.Mf);if(d instanceof V){var e=d.g;if(null!==e){e=e.nb;if(d.de){if(!e.canCollapseSubGraph(d))return}else if(!e.canExpandSubGraph(d))return;a.zc=
!0;d.de?e.collapseSubGraph(d):e.expandSubGraph(d)}}};return a});Ln("ContextMenuButton",function(){var a=$m("Button");a.stretch=um;var b=a.be("ButtonBorder");b instanceof X&&(b.Ab="Rectangle",b.B=new L(0,0,2,3),b.C=new L(1,1,-2,-2));return a});
Ln("PanelExpanderButton",function(a){var b=Mn(a,"COLLAPSIBLE");a=$m("Button",$m(X,"TriangleUp",{xa:new ia(6,4)},(new zg("figure","visible",function(a){return a?"TriangleUp":"TriangleDown"})).Sw(b)));var c=a.be("ButtonBorder");c instanceof X&&(c.stroke=null,c.fill="transparent");a.click=function(a,c){var f=c.g;if(null!==f&&!f.ab){var h=c.Y.be(b);null!==h&&(f.Qb("Collapse/Expand Panel"),h.visible=!h.visible,f.wd("Collapse/Expand Panel"))}};return a});
Ln("CheckBoxButton",function(a){var b=Mn(a);a=$m("Button",{"ButtonBorder.fill":"white","ButtonBorder.stroke":"gray",width:14,height:14},$m(X,{name:"ButtonIcon",UG:"M0 4 L3 9 9 0",lb:2,stretch:Fe,wz:Pi,visible:!1},""!==b?(new zg("visible",b)).JH():[]));a.click=function(a,d){if(!(a.g.ab||""!==b&&a.g.ba.ab)){a.zc=!0;var e=d.be("ButtonIcon");e.g.Qb("checkbox");e.visible=!e.visible;"function"===typeof d._doClick&&d._doClick(a,d);e.g.wd("checkbox")}};return a});
Ln("CheckBox",function(a){a=Mn(a);a=$m("CheckBoxButton",a,{name:"Button",margin:new eb(0,1,0,0)});var b=$m(D,"Horizontal",a,{Fw:!0,margin:1,_buttonFillNormal:a._buttonFillNormal,_buttonStrokeNormal:a._buttonStrokeNormal,_buttonFillOver:a._buttonFillOver,_buttonStrokeOver:a._buttonStrokeOver,Gt:a.Gt,Ht:a.Ht,click:a.click,_buttonClick:a.click});a.Gt=null;a.Ht=null;a.click=null;return b});
function wm(){this.ji=this.Hi=this.Np=this.Qp=this.Pp=this.Op=this.yi=this.wr=this.vr=this.zi=this.Ai=this.Bi=this.yr=this.xr=this.ii=this.oi=this.fi=null}wm.prototype.copy=function(){var a=new wm;a.fi=this.fi;a.oi=this.oi;a.ii=this.ii;a.xr=this.xr;a.yr=this.yr;a.Bi=this.Bi;a.Ai=this.Ai;a.zi=this.zi;a.vr=this.vr;a.wr=this.wr;a.yi=this.yi;a.Op=this.Op;a.Pp=this.Pp;a.Qp=this.Qp;a.Np=this.Np;a.Hi=this.Hi;a.ji=this.ji;return a};
function D(a){G.call(this);void 0===a?0===arguments.length?this.ia=Si:v.k("invalid argument to Panel constructor: undefined"):(v.pb(a,D,D,"type"),this.ia=a);this.za=new I(G);this.Se=$c;this.Pg=!1;this.ia===vk&&(this.Pg=!0);this.Pq=!1;this.rq=fc;this.mi=sm;this.ia===Rm&&Nn(this);this.Io=Pi;this.Kq=qd;this.Lq=Yc;this.tk=this.ie=null;this.cr=NaN;this.hg=this.tj=null;this.Zn="category";this.gg=null;this.Ji=new B(NaN,NaN,NaN,NaN);this.um=null;this.Ah=!1;this.as=null}v.Ja(D,G);v.Uh(D);v.ea("Panel",D);
function Nn(a){a.ij=$c;a.xh=1;a.li=null;a.Ql=null;a.wh=1;a.vh=null;a.Pl=null;a.Ld=[];a.Gd=[];a.pm=On;a.Ol=On;a.Ii=0;a.vi=0}
D.prototype.cloneProtected=function(a){G.prototype.cloneProtected.call(this,a);a.ia=this.ia;a.Se=this.Se.R();a.Pg=this.Pg;a.Pq=this.Pq;a.rq=this.rq.R();a.mi=this.mi;if(a.ia===Rm){a.ij=this.ij.R();a.xh=this.xh;a.li=this.li;a.Ql=this.Ql;a.wh=this.wh;a.vh=this.vh;a.Pl=this.Pl;var b=[];if(0<this.Ld.length)for(var c=this.Ld,d=c.length,e=0;e<d;e++)if(void 0!==c[e]){var f=c[e].copy();f.Al(a);b[e]=f}a.Ld=b;b=[];if(0<this.Gd.length)for(c=this.Gd,d=c.length,e=0;e<d;e++)void 0!==c[e]&&(f=c[e].copy(),f.Al(a),
b[e]=f);a.Gd=b;a.pm=this.pm;a.Ol=this.Ol;a.Ii=this.Ii;a.vi=this.vi}a.Io=this.Io;a.Kq=this.Kq.R();a.Lq=this.Lq.R();a.ie=this.ie;a.tk=this.tk;a.cr=this.cr;a.tj=this.tj;a.hg=this.hg;a.Zn=this.Zn;a.Ji.assign(this.Ji);a.Ah=this.Ah;null!==this.as&&(a.as=this.as)};D.prototype.Vh=function(a){G.prototype.Vh.call(this,a);a.za=this.za;for(var b=a.za.n,c=b.length,d=0;d<c;d++)b[d].Wg=a;a.um=null};
D.prototype.copy=function(){var a=G.prototype.copy.call(this);if(null!==a){for(var b=this.za.n,c=b.length,d=0;d<c;d++){var e=b[d].copy(),f=a;e.Al(f);e.jm=null;var h=f.za,k=h.count;h.Ad(k,e);h=f.Y;if(null!==h){h.em=null;null!==e.Sd&&h instanceof S&&(h.Ah=!0);var l=f.g;null!==l&&l.la.ib||h.Lc(Cf,"elements",f,null,e,null,k)}}return a}return null};D.prototype.toString=function(){return"Panel("+this.type+")#"+v.ld(this)};var Si;D.Position=Si=v.p(D,"Position",0);D.Horizontal=v.p(D,"Horizontal",1);var rk;
D.Vertical=rk=v.p(D,"Vertical",2);var Li;D.Spot=Li=v.p(D,"Spot",3);var sk;D.Auto=sk=v.p(D,"Auto",4);var Rm;D.Table=Rm=v.p(D,"Table",5);D.Viewbox=v.p(D,"Viewbox",6);var Im;D.TableRow=Im=v.p(D,"TableRow",7);var Jm;D.TableColumn=Jm=v.p(D,"TableColumn",8);var xi;D.Link=xi=v.p(D,"Link",9);var vk;D.Grid=vk=v.p(D,"Grid",10);
v.defineProperty(D,{type:"type"},function(){return this.ia},function(a){var b=this.ia;b!==a&&(b!==Im&&b!==Jm||v.k("Cannot change Panel.type when it is already a TableRow or a TableColumn: "+a),this.ia=a,this.ia===vk?this.Pg=!0:this.ia===Rm&&Nn(this),this.P(),this.h("type",b,a))});v.u(D,{elements:"elements"},function(){return this.za.i});v.u(D,{Ha:"naturalBounds"},function(){return this.Qc});
v.defineProperty(D,{padding:"padding"},function(){return this.Se},function(a){"number"===typeof a?(0>a&&v.va(a,">= 0",D,"padding"),a=new eb(a)):(v.A(a,eb,D,"padding"),0>a.left&&v.va(a.left,">= 0",D,"padding:value.left"),0>a.right&&v.va(a.right,">= 0",D,"padding:value.right"),0>a.top&&v.va(a.top,">= 0",D,"padding:value.top"),0>a.bottom&&v.va(a.bottom,">= 0",D,"padding:value.bottom"));var b=this.Se;b.K(a)||(this.Se=a=a.R(),this.P(),this.h("padding",b,a))});
v.defineProperty(D,{fl:"defaultAlignment"},function(){return this.rq},function(a){var b=this.rq;b.K(a)||(this.rq=a=a.R(),this.P(),this.h("defaultAlignment",b,a))});v.defineProperty(D,{Md:"defaultStretch"},function(){return this.mi},function(a){var b=this.mi;b!==a&&(v.pb(a,G,D,"defaultStretch"),this.mi=a,this.P(),this.h("defaultStretch",b,a))});
v.defineProperty(D,{IJ:"defaultSeparatorPadding"},function(){return void 0===this.ij?$c:this.ij},function(a){if(void 0!==this.ij){"number"===typeof a&&(a=new eb(a));var b=this.ij;b.K(a)||(this.ij=a=a.R(),this.h("defaultSeparatorPadding",b,a))}});
v.defineProperty(D,{GJ:"defaultRowSeparatorStroke"},function(){return void 0===this.li?null:this.li},function(a){var b=this.li;b!==a&&(null===a||"string"===typeof a||a instanceof ha)&&(a instanceof ha&&a.freeze(),this.li=a,this.h("defaultRowSeparatorStroke",b,a))});v.defineProperty(D,{HJ:"defaultRowSeparatorStrokeWidth"},function(){return void 0===this.xh?1:this.xh},function(a){if(void 0!==this.xh){var b=this.xh;b!==a&&isFinite(a)&&0<=a&&(this.xh=a,this.h("defaultRowSeparatorStrokeWidth",b,a))}});
v.defineProperty(D,{FJ:"defaultRowSeparatorDashArray"},function(){return void 0===this.Ql?null:this.Ql},function(a){if(void 0!==this.Ql){var b=this.Ql;if(b!==a){null===a||Array.isArray(a)||v.Ed(a,"Array",D,"defaultRowSeparatorDashArray:value");if(null!==a){for(var c=a.length,d=0,e=0;e<c;e++){var f=a[e];"number"===typeof f&&0<=f&&isFinite(f)||v.k("defaultRowSeparatorDashArray value "+f+" at index "+e+" must be a positive number or zero.");d+=f}if(0===d){if(null===b)return;a=null}}this.Ql=a;this.pa();
this.h("defaultRowSeparatorDashArray",b,a)}}});v.defineProperty(D,{AJ:"defaultColumnSeparatorStroke"},function(){return void 0===this.vh?null:this.vh},function(a){if(void 0!==this.vh){var b=this.vh;b!==a&&(null===a||"string"===typeof a||a instanceof ha)&&(a instanceof ha&&a.freeze(),this.vh=a,this.h("defaultColumnSeparatorStroke",b,a))}});
v.defineProperty(D,{BJ:"defaultColumnSeparatorStrokeWidth"},function(){return void 0===this.wh?1:this.wh},function(a){if(void 0!==this.wh){var b=this.wh;b!==a&&isFinite(a)&&0<=a&&(this.wh=a,this.h("defaultColumnSeparatorStrokeWidth",b,a))}});
v.defineProperty(D,{zJ:"defaultColumnSeparatorDashArray"},function(){return void 0===this.Pl?null:this.Pl},function(a){if(void 0!==this.Pl){var b=this.Pl;if(b!==a){null===a||Array.isArray(a)||v.Ed(a,"Array",D,"defaultColumnSeparatorDashArray:value");if(null!==a){for(var c=a.length,d=0,e=0;e<c;e++){var f=a[e];"number"===typeof f&&0<=f&&isFinite(f)||v.k("defaultColumnSeparatorDashArray value "+f+" at index "+e+" must be a positive number or zero.");d+=f}if(0===d){if(null===b)return;a=null}}this.Pl=
a;this.pa();this.h("defaultColumnSeparatorDashArray",b,a)}}});v.defineProperty(D,{RK:"viewboxStretch"},function(){return this.Io},function(a){var b=this.Io;b!==a&&(v.pb(a,G,D,"viewboxStretch"),this.Io=a,this.h("viewboxStretch",b,a))});
v.defineProperty(D,{Ew:"gridCellSize"},function(){return this.Kq},function(a){var b=this.Kq;b.K(a)||(v.A(a,ia,D,"gridCellSize"),a.G()&&0!==a.width&&0!==a.height||v.k("Invalid Panel.gridCellSize: "+a),this.Kq=a.R(),null!==this.g&&this===this.g.mt&&Zk(this.g),this.pa(),this.h("gridCellSize",b,a))});
v.defineProperty(D,{UD:"gridOrigin"},function(){return this.Lq},function(a){var b=this.Lq;b.K(a)||(v.A(a,z,D,"gridOrigin"),a.G()||v.k("Invalid Panel.gridOrigin: "+a),this.Lq=a.R(),null!==this.g&&Zk(this.g),this.pa(),this.h("gridOrigin",b,a))});g=D.prototype;g.xv=function(a){G.prototype.xv.call(this,a);for(var b=this.za.n,c=b.length,d=0;d<c;d++)b[d].xv(a)};
g.Xj=function(a,b){if(this.ia===vk){var c=this.Ri()*b.scale;0>=c&&(c=1);var d=this.Ew,e=d.width,d=d.height,f=this.Ha,h=f.width,f=f.height,k=Math.ceil(h/e),l=Math.ceil(f/d),m=this.UD;a.save();a.beginPath();a.rect(0,0,h,f);a.clip();for(var n=[],p=this.za.n,q=p.length,r=0;r<q;r++){var s=p[r],t=[];n.push(t);if(s.visible)for(var s=xl(s.Ab),u=r+1;u<q;u++){var y=p[u];y.visible&&xl(y.Ab)===s&&(y=y.interval,2<=y&&t.push(y))}}p=this.za.n;q=p.length;for(r=0;r<q;r++){var w=p[r];if(w.visible&&(t=w.interval,!(2>
e*t*c))){var s=n[r],u=!1,x=!0,A=w.cF;null!==A&&(u=!0,x=a.pz(A,w.ke));if("LineV"===w.Ab){a.lineWidth=w.lb;Mm(this,a,w.stroke,!1,!1);a.beginPath();for(var H=y=Math.floor(-m.x/e);H<=y+k;H++){var C=H*e+m.x;0<=C&&C<h&&Pn(H,t,s)&&(u&&!x?zm(a,C,0,C,f,A,w.ke):(a.moveTo(C,0),a.lineTo(C,f)))}a.stroke()}else if("LineH"===w.Ab){a.lineWidth=w.lb;Mm(this,a,w.stroke,!1,!1);a.beginPath();for(H=y=Math.floor(-m.y/d);H<=y+l;H++)C=H*d+m.y,0<=C&&C<=f&&Pn(H,t,s)&&(u&&!x?zm(a,0,C,h,C,A,w.ke):(a.moveTo(0,C),a.lineTo(h,C)));
a.stroke()}else if("BarV"===w.Ab)for(Mm(this,a,w.fill,!0,!1),w=w.width,isNaN(w)&&(w=e),H=y=Math.floor(-m.x/e);H<=y+k;H++)C=H*e+m.x,0<=C&&C<h&&Pn(H,t,s)&&a.fillRect(C,0,w,f);else if("BarH"===w.Ab)for(Mm(this,a,w.fill,!0,!1),w=w.height,isNaN(w)&&(w=d),H=y=Math.floor(-m.y/d);H<=y+l;H++)C=H*d+m.y,0<=C&&C<=f&&Pn(H,t,s)&&a.fillRect(0,C,h,w);u&&a.nz()}}a.restore();Wc(a,!1)}else{this.ia===Rm&&(a.lineCap="butt",Qn(this,a,!0,this.Ld,!0),Qn(this,a,!1,this.Gd,!0),Rn(this,a,!0,this.Ld),Rn(this,a,!1,this.Gd),Qn(this,
a,!0,this.Ld,!1),Qn(this,a,!1,this.Gd,!1));(c=this.cE)&&a.save();e=this.Xe();d=this.za.n;h=d.length;for(f=0;f<h;f++)k=d[f],c&&k===e&&(a.pw=!0),k.We(a,b),c&&k===e&&(a.pw=!1);c&&(a.restore(),Wc(a,!0))}};
function Rn(a,b,c,d){for(var e=d.length,f=a.U,h=c?a.zd(0):a.yd(0),k=0;k<e;k++){var l=d[k];if(void 0!==l&&l!==h&&0!==l.Ia){if(c){if(l.position>f.height)continue}else if(l.position>f.width)continue;var m=l.Fp;isNaN(m)&&(m=c?a.xh:a.wh);var n=l.Ep;null===n&&(n=c?a.li:a.vh);if(0!==m&&null!==n){Mm(a,b,n,!1,!1);var n=!1,p=!0,q=l.xI;null!==q&&(n=!0,void 0!==b.setLineDash?(b.setLineDash(q),b.lineDashOffset=a.ke):void 0!==b.webkitLineDash?(b.webkitLineDash=q,b.webkitLineDashOffset=a.ke):void 0!==b.mozDash?
(b.mozDash=q,b.mozDashOffset=a.ke):p=!1);b.beginPath();var r=l.position+m;c?r>f.height&&(m-=r-f.height):r>f.width&&(m-=r-f.width);l=l.position+m/2;b.lineWidth=m;r=a.padding;c?(l+=r.top,m=r.left,r=f.width-r.right,n&&!p?zm(b,m,l,r,l,q,0):(b.moveTo(m,l),b.lineTo(r,l))):(l+=r.left,m=r.top,r=f.height-r.bottom,n&&!p?zm(b,l,m,l,r,q,0):(b.moveTo(l,m),b.lineTo(l,r)));b.stroke();n&&(void 0!==b.setLineDash?(b.setLineDash(v.fj),b.lineDashOffset=0):void 0!==b.webkitLineDash?(b.webkitLineDash=v.fj,b.webkitLineDashOffset=
0):void 0!==b.mozDash&&(b.mozDash=null,b.mozDashOffset=0))}}}}
function Qn(a,b,c,d,e){for(var f=d.length,h=a.U,k=0;k<f;k++){var l=d[k];if(void 0!==l&&null!==l.background&&l.kz!==e&&0!==l.Ia){var m=c?h.height:h.width;if(!(l.position>m)){var n=l.of(),p=l.Fp;isNaN(p)&&(p=c?a.xh:a.wh);var q=l.Ep;null===q&&(q=c?a.li:a.vh);null===q&&(p=0);n-=p;p=l.position+p;n+=l.Ia;p+n>m&&(n=m-p);0>=n||(m=a.padding,Mm(a,b,l.background,!0,!1),c?b.fillRect(m.left,p+m.top,h.width-(m.left+m.right),n):b.fillRect(p+m.left,m.top,n,h.height-(m.top+m.bottom)))}}}}
function Pn(a,b,c){if(0!==a%b)return!1;b=c.length;for(var d=0;d<b;d++)if(0===a%c[d])return!1;return!0}function xl(a){return"LineV"===a||"BarV"===a}
g.bp=function(a,b,c,d,e){var f=this.Kg(),h=this.transform,k=1/(h.m11*h.m22-h.m12*h.m21),l=h.m22*k,m=-h.m12*k,n=-h.m21*k,p=h.m11*k,q=k*(h.m21*h.dy-h.m22*h.dx),r=k*(h.m12*h.dx-h.m11*h.dy);if(null!==this.Bm)return h=this.U,$d(h.left,h.top,h.right,h.bottom,a,b,c,d,e);if(null!==this.background)f=a*l+b*n+q,k=a*m+b*p+r,a=c*l+d*n+q,c=c*m+d*p+r,e.m(0,0),d=this.Ha,c=$d(0,0,d.width,d.height,f,k,a,c,e),e.transform(h);else{f||(l=1,n=m=0,p=1,r=q=0);k=a*l+b*n+q;a=a*m+b*p+r;l=c*l+d*n+q;d=c*m+d*p+r;e.m(l,d);m=(l-
k)*(l-k)+(d-a)*(d-a);c=!1;p=this.za.n;r=p.length;n=v.L();for(q=0;q<r;q++)b=p[q],b.visible&&b.bp(k,a,l,d,n)&&(c=!0,b=(k-n.x)*(k-n.x)+(a-n.y)*(a-n.y),b<m&&(m=b,e.set(n)));v.v(n);f&&e.transform(h)}return c};g.P=function(a){G.prototype.P.call(this,a);this.ia===sk&&(a=this.Xe(),null!==a&&a.P(!0));a=this.za.n;for(var b=a.length,c=0;c<b;c++){var d=a[c];!0!==dl(d)&&(d.xa.G()||(d instanceof Mi||d instanceof D||d instanceof $g||Em(d,!1)!==Ni)&&d.P(!0))}};
g.fp=function(){if(!1===dl(this)){Qk(this,!0);Gm(this,!0);for(var a=this.za.n,b=a.length,c=0;c<b;c++)a[c].fp()}};g.ri=function(){if(0!==(this.fa&2048)===!1){Om(this,!0);Pm(this,!0);for(var a=this.za.n,b=a.length,c=0;c<b;c++)a[c].Cz()}};g.Cz=function(){Pm(this,!0);for(var a=this.za.n,b=a.length,c=0;c<b;c++)a[c].Cz()};
g.np=function(a,b,c,d){var e=this.Ji;e.width=0;e.height=0;var f=this.xa,h=this.ih;void 0===c&&(c=h.width,d=h.height);c=Math.max(c,h.width);d=Math.max(d,h.height);var k=this.xf;isNaN(f.width)||(a=Math.min(f.width,k.width));isNaN(f.height)||(b=Math.min(f.height,k.height));a=Math.max(c,a);b=Math.max(d,b);var l=this.padding;a=Math.max(a-l.left-l.right,0);b=Math.max(b-l.top-l.bottom,0);var m=this.za.n;if(0!==m.length){var n=this.ia.Ob;switch(n){case "Position":var p=a,q=b,r=c,s=d,t=m.length;e.x=0;e.y=
0;e.width=0;for(var u=e.height=0;u<t;u++){var y=m[u];if(y.visible||y===this.cc){var w=y.margin,x=w.right+w.left,A=w.top+w.bottom;fj(y,p,q,r,s);var H=y.Ca,C=Math.max(H.width+x,0),T=Math.max(H.height+A,0),ba=y.position.x,U=y.position.y;isFinite(ba)||(ba=0);isFinite(U)||(U=0);if(y instanceof X){var M=y;if(M.eE)var aa=M.lb/2,ba=ba-aa,U=U-aa}ab(e,ba,U,C,T)}}break;case "Vertical":for(var Ia=a,oa=c,Ga=m.length,pa=v.jb(),Ca=0;Ca<Ga;Ca++){var ua=m[Ca];if(ua.visible||ua===this.cc){var gc=Em(ua,!1);if(gc!==
Ni&&gc!==tm)pa.push(ua);else{var rd=ua.margin,sd=rd.right+rd.left,Je=rd.top+rd.bottom;fj(ua,Ia,Infinity,oa,0);var Wb=ua.Ca,Gg=Math.max(Wb.width+sd,0),td=Math.max(Wb.height+Je,0);e.width=Math.max(e.width,Gg);e.height+=td}}}var qb=pa.length;if(0!==qb){this.xa.width?Ia=Math.min(this.xa.width,this.xf.width):0!==e.width&&(Ia=Math.min(e.width,this.xf.width));for(Ca=0;Ca<qb;Ca++)if(ua=pa[Ca],ua.visible||ua===this.cc)rd=ua.margin,sd=rd.right+rd.left,Je=rd.top+rd.bottom,fj(ua,Ia,Infinity,oa,0),Wb=ua.Ca,Gg=
Math.max(Wb.width+sd,0),td=Math.max(Wb.height+Je,0),e.width=Math.max(e.width,Gg),e.height+=td;v.sa(pa)}break;case "Horizontal":for(var gb=b,ka=d,la=m.length,rb=v.jb(),Va=0;Va<la;Va++){var Ra=m[Va];if(Ra.visible||Ra===this.cc){var se=Em(Ra,!1);if(se!==Ni&&se!==um)rb.push(Ra);else{var ud=Ra.margin,be=ud.right+ud.left,Ld=ud.top+ud.bottom;fj(Ra,Infinity,gb,0,ka);var ce=Ra.Ca,Hg=Math.max(ce.width+be,0),ad=Math.max(ce.height+Ld,0);e.width+=Hg;e.height=Math.max(e.height,ad)}}}var gi=rb.length;if(0!==gi){this.xa.height?
gb=Math.min(this.xa.height,this.xf.height):0!==e.height&&(gb=Math.min(e.height,this.xf.height));for(Va=0;Va<gi;Va++)if(Ra=rb[Va],Ra.visible||Ra===this.cc)ud=Ra.margin,be=ud.right+ud.left,Ld=ud.top+ud.bottom,fj(Ra,Infinity,gb,0,ka),ce=Ra.Ca,Hg=Math.max(ce.width+be,0),ad=Math.max(ce.height+Ld,0),e.width+=Hg,e.height=Math.max(e.height,ad);v.sa(rb)}break;case "Spot":a:{var xb=a,Xb=b,Md=c,jh=d,Lb=m.length,jb=this.Xe(),bb=jb.margin,Ke=0,Nd=0,Ig=bb.right+bb.left,Jg=bb.top+bb.bottom;fj(jb,xb,Xb,Md,jh);var hc=
jb.Ca,ic=hc.width,de=hc.height,bd=Math.max(ic+Ig,0),lb=Math.max(de+Jg,0);e.x=-bb.left;e.y=-bb.top;e.width=bd;e.height=lb;for(var Oc=0;Oc<Lb;Oc++){var Ka=m[Oc];if(Ka!==jb&&(Ka.visible||Ka===this.cc)){bb=Ka.margin;Ke=bb.right+bb.left;Nd=bb.top+bb.bottom;fj(Ka,xb,Xb,0,0);var hc=Ka.Ca,bd=Math.max(hc.width+Ke,0),lb=Math.max(hc.height+Nd,0),mb=Ka.alignment;mb.Uc()&&(mb=this.fl);mb.cd()||(mb=Ab);var tc=Ka.Tj;tc.Uc()&&(tc=Ab);ab(e,mb.x*ic+mb.offsetX-(tc.x*hc.width-tc.offsetX)-bb.left,mb.y*de+mb.offsetY-(tc.y*
hc.height-tc.offsetY)-bb.top,bd,lb)}}var vd=jb.stretch;vd===sm&&(vd=Em(jb,!1));switch(vd){case Ni:break a;case Fe:if(!isFinite(xb)&&!isFinite(Xb))break a;break;case um:if(!isFinite(xb))break a;break;case tm:if(!isFinite(Xb))break a}hc=jb.Ca;ic=hc.width;de=hc.height;bd=Math.max(ic+Ig,0);lb=Math.max(de+Jg,0);bb=jb.margin;e.x=-bb.left;e.y=-bb.top;e.width=bd;e.height=lb;for(Oc=0;Oc<Lb;Oc++)Ka=m[Oc],Ka===jb||!Ka.visible&&Ka!==this.cc||(bb=Ka.margin,Ke=bb.right+bb.left,Nd=bb.top+bb.bottom,hc=Ka.Ca,bd=Math.max(hc.width+
Ke,0),lb=Math.max(hc.height+Nd,0),mb=Ka.alignment,mb.Uc()&&(mb=this.fl),mb.cd()||(mb=Ab),tc=Ka.Tj,tc.Uc()&&(tc=Ab),ab(e,mb.x*ic+mb.offsetX-(tc.x*hc.width-tc.offsetX)-bb.left,mb.y*de+mb.offsetY-(tc.y*hc.height-tc.offsetY)-bb.top,bd,lb))}break;case "Auto":var Ib=a,ee=b,qf=c,Le=d,Jf=m.length,Jb=this.Xe(),te=Jb.margin,Mb=te.right+te.left,jc=te.top+te.bottom;fj(Jb,Ib,ee,qf,Le);var Qb=Jb.Ca,Fc=0;if(Jb instanceof X)var nb=Jb,Fc=nb.lb*nb.scale;var Cb=Math.max(Qb.width+Mb,0),Kb=Math.max(Qb.height+jc,0),Od=
Sn(Jb),Kg=Od.x*Cb+Od.offsetX,kh=Od.y*Kb+Od.offsetY,Od=Tn(Jb),lh=Od.x*Cb+Od.offsetX,ue=Od.y*Kb+Od.offsetY,cd=Ib,rf=ee;isFinite(Ib)&&(cd=Math.max(Math.abs(Kg-lh)-Fc,0));isFinite(ee)&&(rf=Math.max(Math.abs(kh-ue)-Fc,0));var kc=v.El();kc.m(0,0);for(var yb=0;yb<Jf;yb++){var zb=m[yb];if(zb!==Jb&&(zb.visible||zb===this.cc)){var te=zb.margin,Gc=te.right+te.left,Lg=te.top+te.bottom;fj(zb,cd,rf,0,0);Qb=zb.Ca;Cb=Math.max(Qb.width+Gc,0);Kb=Math.max(Qb.height+Lg,0);kc.m(Math.max(Cb,kc.width),Math.max(Kb,kc.height))}}if(1===
Jf)e.width=Cb,e.height=Kb,v.Zj(kc);else{var Pc=Sn(Jb),Qc=Tn(Jb),kb=0,Rc=0;Qc.x!==Pc.x&&Qc.y!==Pc.y&&(kb=kc.width/Math.abs(Qc.x-Pc.x),Rc=kc.height/Math.abs(Qc.y-Pc.y));v.Zj(kc);Fc=0;Jb instanceof X&&(nb=Jb,Fc=nb.lb*nb.scale,Oi(nb)===Pi&&(kb=Rc=Math.max(kb,Rc)));var kb=kb+(Math.abs(Pc.offsetX)+Math.abs(Qc.offsetX)+Fc),Rc=Rc+(Math.abs(Pc.offsetY)+Math.abs(Qc.offsetY)+Fc),Pd=Jb.stretch;Pd===sm&&(Pd=Em(Jb,!1));switch(Pd){case Ni:Le=qf=0;break;case Fe:isFinite(Ib)&&(kb=Ib);isFinite(ee)&&(Rc=ee);break;case um:isFinite(Ib)&&
(kb=Ib);Le=0;break;case tm:qf=0,isFinite(ee)&&(Rc=ee)}Jb instanceof X&&!Jb.xa.G()&&(nb=Jb,nb.Jh?nb.un=null:nb.Ra=null);Jb.fp();fj(Jb,kb,Rc,qf,Le);e.width=Jb.Ca.width+Mb;e.height=Jb.Ca.height+jc}break;case "Table":for(var fe=a,sf=b,hi=c,pj=d,Fa=m.length,Me=v.jb(),ge=v.jb(),ca=0;ca<Fa;ca++){var da=m[ca],Mg=da instanceof D?da:null;if(null===Mg||Mg.type!==Im&&Mg.type!==Jm||!da.visible)Me.push(da);else{ge.push(da);for(var mh=Mg.za.n,ii=mh.length,uc=0;uc<ii;uc++){var ji=mh[uc];Mg.type===Im?ji.Wb=da.Wb:
Mg.type===Jm&&(ji.column=da.column);Me.push(ji)}}}Fa=Me.length;0===Fa&&(this.zd(0),this.yd(0));for(var Yb=[],ca=0;ca<Fa;ca++)da=Me[ca],Qk(da,!0),Gm(da,!0),Yb[da.Wb]||(Yb[da.Wb]=[]),Yb[da.Wb][da.column]||(Yb[da.Wb][da.column]=[]),Yb[da.Wb][da.column].push(da);v.sa(Me);for(var nh=v.jb(),dd=v.jb(),ve=v.jb(),Hc={count:0},ed={count:0},vc=fe,fd=sf,ag=this.Ld,Fa=ag.length,ca=0;ca<Fa;ca++){var Y=ag[ca];void 0!==Y&&(Y.Ia=0)}ag=this.Gd;Fa=ag.length;for(ca=0;ca<Fa;ca++)Y=ag[ca],void 0!==Y&&(Y.Ia=0);for(var we=
Yb.length,Ne=0,ca=0;ca<we;ca++)Yb[ca]&&(Ne=Math.max(Ne,Yb[ca].length));for(var ki=Math.min(this.Ii,we-1),li=Math.min(this.vi,Ne-1),Ic=0,we=Yb.length,ca=0;ca<we;ca++)if(Yb[ca])for(var Ne=Yb[ca].length,ob=this.zd(ca),uc=ob.Ia=0;uc<Ne;uc++)if(Yb[ca][uc]){var pb=this.yd(uc);void 0===nh[uc]&&(pb.Ia=0,nh[uc]=!0);for(var Nk=Yb[ca][uc],bg=Nk.length,Ng=0;Ng<bg;Ng++)if(da=Nk[Ng],da.visible||da===this.cc){var Og=1<da.Fi||1<da.hi;Og&&(ca<ki||uc<li||dd.push(da));var Rb=da.margin,cg=Rb.right+Rb.left,dg=Rb.top+
Rb.bottom,Sc=Sm(da,ob,pb,!1),xe=da.xa,on=!isNaN(xe.height),mi=!isNaN(xe.width)&&on;Og||Sc===Ni||mi||ca<ki||uc<li||(void 0!==Hc[uc]||Sc!==Fe&&Sc!==um||(Hc[uc]=-1,Hc.count++),void 0!==ed[ca]||Sc!==Fe&&Sc!==tm||(ed[ca]=-1,ed.count++),ve.push(da));fj(da,Infinity,Infinity,0,0);if(!(ca<ki||uc<li)){var Oe=da.Ca,Pe=Math.max(Oe.width+cg,0),wd=Math.max(Oe.height+dg,0);if(1===da.Fi&&(Sc===Ni||Sc===um)){var Y=this.zd(ca),gd=Y.of(),Ic=Math.max(wd-Y.Ia,0);Ic+gd>fd&&(Ic=Math.max(fd-gd,0));var oh=0===Y.Ia;Y.Ia+=
Ic;fd=Math.max(fd-(Ic+(oh?gd:0)),0)}1!==da.hi||Sc!==Ni&&Sc!==tm||(Y=this.yd(uc),gd=Y.of(),Ic=Math.max(Pe-Y.Ia,0),Ic+gd>vc&&(Ic=Math.max(vc-gd,0)),oh=0===Y.Ia,Y.Ia+=Ic,vc=Math.max(vc-(Ic+(oh?gd:0)),0));Og&&da.fp()}}}v.sa(nh);for(var ye=0,ze=0,Fa=this.Qo,ca=0;ca<Fa;ca++)void 0!==this.Gd[ca]&&(ye+=this.yd(ca).bb);Fa=this.Dp;for(ca=0;ca<Fa;ca++)void 0!==this.Ld[ca]&&(ze+=this.zd(ca).bb);for(var vc=Math.max(fe-ye,0),Ok=fd=Math.max(sf-ze,0),Pk=vc,Fa=ve.length,ca=0;ca<Fa;ca++){var da=ve[ca],ob=this.zd(da.Wb),
pb=this.yd(da.column),Tc=da.Ca,Rb=da.margin,cg=Rb.right+Rb.left,dg=Rb.top+Rb.bottom;Hc[da.column]=0===pb.Ia&&void 0!==Hc[da.column]?Math.max(Tc.width+cg,Hc[da.column]):null;ed[da.Wb]=0===ob.Ia&&void 0!==ed[da.Wb]?Math.max(Tc.height+dg,ed[da.Wb]):null}var tf=0,Ae=0;for(ca in ed)"count"!==ca&&(tf+=ed[ca]);for(ca in Hc)"count"!==ca&&(Ae+=Hc[ca]);for(var Sa=v.El(),ca=0;ca<Fa;ca++)if(da=ve[ca],da.visible||da===this.cc){var ob=this.zd(da.Wb),pb=this.yd(da.column),uf=0;isFinite(pb.width)?uf=pb.width:(uf=
isFinite(vc)&&null!==Hc[da.column]?0===Ae?pb.Ia+vc:Hc[da.column]/Ae*Pk:null!==Hc[da.column]?vc:pb.Ia||vc,uf=Math.max(0,uf-pb.of()));var Be=0;isFinite(ob.height)?Be=ob.height:(Be=isFinite(fd)&&null!==ed[da.Wb]?0===tf?ob.Ia+fd:ed[da.Wb]/tf*Ok:null!==ed[da.Wb]?fd:ob.Ia||fd,Be=Math.max(0,Be-ob.of()));Sa.m(Math.max(pb.Zi,Math.min(uf,pb.ff)),Math.max(ob.Zi,Math.min(Be,ob.ff)));Sc=Sm(da,ob,pb,!1);switch(Sc){case um:Sa.height=Math.max(Sa.height,ob.Ia+fd);break;case tm:Sa.width=Math.max(Sa.width,pb.Ia+vc)}Rb=
da.margin;cg=Rb.right+Rb.left;dg=Rb.top+Rb.bottom;da.fp();fj(da,Sa.width,Sa.height,pb.Zi,ob.Zi);Oe=da.Ca;Pe=Math.max(Oe.width+cg,0);wd=Math.max(Oe.height+dg,0);isFinite(vc)&&(Pe=Math.min(Pe,Sa.width));isFinite(fd)&&(wd=Math.min(wd,Sa.height));var eg=0,eg=ob.Ia;ob.Ia=Math.max(ob.Ia,wd);Ic=ob.Ia-eg;fd=Math.max(fd-Ic,0);eg=pb.Ia;pb.Ia=Math.max(pb.Ia,Pe);Ic=pb.Ia-eg;vc=Math.max(vc-Ic,0)}v.sa(ve);for(var Kf=v.El(),Fa=dd.length,ca=0;ca<Fa;ca++)if(da=dd[ca],da.visible||da===this.cc){ob=this.zd(da.Wb);pb=
this.yd(da.column);Sa.m(Math.max(pb.Zi,Math.min(fe,pb.ff)),Math.max(ob.Zi,Math.min(sf,ob.ff)));Sc=Sm(da,ob,pb,!1);switch(Sc){case Fe:0!==pb.Ia&&(Sa.width=Math.min(Sa.width,pb.Ia));0!==ob.Ia&&(Sa.height=Math.min(Sa.height,ob.Ia));break;case um:0!==pb.Ia&&(Sa.width=Math.min(Sa.width,pb.Ia));break;case tm:0!==ob.Ia&&(Sa.height=Math.min(Sa.height,ob.Ia))}isFinite(pb.width)&&(Sa.width=pb.width);isFinite(ob.height)&&(Sa.height=ob.height);Kf.m(0,0);for(var cb=1;cb<da.Fi&&!(da.Wb+cb>=this.Dp);cb++)Y=this.zd(da.Wb+
cb),Kf.height+=Math.max(Y.Zi,isNaN(Y.Te)?Y.ff:Math.min(Y.Te,Y.ff));for(cb=1;cb<da.hi&&!(da.column+cb>=this.Qo);cb++)Y=this.yd(da.column+cb),Kf.width+=Math.max(Y.Zi,isNaN(Y.Te)?Y.ff:Math.min(Y.Te,Y.ff));Sa.width+=Kf.width;Sa.height+=Kf.height;Rb=da.margin;cg=Rb.right+Rb.left;dg=Rb.top+Rb.bottom;fj(da,Sa.width,Sa.height,hi,pj);for(var Oe=da.Ca,Pe=Math.max(Oe.width+cg,0),wd=Math.max(Oe.height+dg,0),Qe=0,cb=0;cb<da.Fi&&!(da.Wb+cb>=this.Dp);cb++)Y=this.zd(da.Wb+cb),Qe+=Y.total||0;if(Qe<wd){var wc=wd-Qe,
ni=wd-Qe;if(null!==da.qx)for(var fg=da.qx,cb=0;cb<da.Fi&&!(0>=wc)&&!(da.Wb+cb>=this.Dp);cb++){var Y=this.zd(da.Wb+cb),hb=Y.bb||0,xd=fg(da,Y,ni);Y.Ia=Math.min(Y.ff,hb+xd);Y.bb!==hb&&(wc-=Y.bb-hb)}for(;0<wc;){hb=Y.bb||0;isNaN(Y.height)&&Y.ff>hb&&(Y.Ia=Math.min(Y.ff,hb+wc),Y.bb!==hb&&(wc-=Y.bb-hb));if(0===Y.index)break;Y=this.zd(Y.index-1)}}for(var Ce=0,cb=0;cb<da.hi&&!(da.column+cb>=this.Qo);cb++)Y=this.yd(da.column+cb),Ce+=Y.total||0;if(Ce<Pe){wc=Pe-Ce;ni=Pe-Ce;if(null!==da.qx)for(fg=da.qx,cb=0;cb<
da.hi&&!(0>=wc)&&!(da.column+cb>=this.Qo);cb++)Y=this.yd(da.column+cb),hb=Y.bb||0,xd=fg(da,Y,ni),Y.Ia=Math.min(Y.ff,hb+xd),Y.bb!==hb&&(wc-=Y.bb-hb);for(;0<wc;){hb=Y.bb||0;isNaN(Y.width)&&Y.ff>hb&&(Y.Ia=Math.min(Y.ff,hb+wc),Y.bb!==hb&&(wc-=Y.bb-hb));if(0===Y.index)break;Y=this.yd(Y.index-1)}}}v.sa(dd);v.Zj(Kf);v.Zj(Sa);for(var he=0,ie=0,Sc=Em(this,!0),ph=this.xa,qj=this.xf,yd=ze=ye=0,Re=0,Fa=this.Qo,ca=0;ca<Fa;ca++)void 0!==this.Gd[ca]&&(Y=this.yd(ca),isFinite(Y.width)?(yd+=Y.width,yd+=Y.of()):Un(Y)===
Vn?(yd+=Y.bb,yd+=Y.of()):0!==Y.bb&&(ye+=Y.bb,ye+=Y.of()));var he=isFinite(ph.width)?Math.min(ph.width,qj.width):Sc!==Ni&&isFinite(fe)?fe:ye,he=Math.max(he,this.ih.width),he=Math.max(he-yd,0),gg=Math.max(he/ye,1);isFinite(gg)||(gg=1);for(ca=0;ca<Fa;ca++)void 0!==this.Gd[ca]&&(Y=this.yd(ca),isFinite(Y.width)||Un(Y)===Vn||(Y.Ia=Y.bb*gg),Y.position=e.width,0!==Y.bb&&(e.width+=Y.bb,e.width+=Y.of()));Fa=this.Dp;for(ca=0;ca<Fa;ca++)void 0!==this.Ld[ca]&&(Y=this.zd(ca),isFinite(Y.height)?(Re+=Y.height,Re+=
Y.of()):Un(Y)===Vn?(Re+=Y.bb,Re+=Y.of()):0!==Y.bb&&(ze+=Y.bb,ze+=Y.of()));var ie=isFinite(ph.height)?Math.min(ph.height,qj.height):Sc!==Ni&&isFinite(sf)?sf:ze,ie=Math.max(ie,this.ih.height),ie=Math.max(ie-Re,0),Se=Math.max(ie/ze,1);isFinite(Se)||(Se=1);for(ca=0;ca<Fa;ca++)void 0!==this.Ld[ca]&&(Y=this.zd(ca),isFinite(Y.height)||Un(Y)===Vn||(Y.Ia=Y.bb*Se),Y.position=e.height,0!==Y.bb&&(e.height+=Y.bb,e.height+=Y.of()));Fa=ge.length;for(ca=0;ca<Fa;ca++){var Uc=ge[ca];Uc.type===Im?(uf=e.width,Y=this.zd(Uc.Wb),
Be=Y.Ia):(Y=this.yd(Uc.column),uf=Y.Ia,Be=e.height);Uc.kd.m(0,0,uf,Be);Qk(Uc,!1);Yb[Uc.Wb]||(Yb[Uc.Wb]=[]);Yb[Uc.Wb][Uc.column]||(Yb[Uc.Wb][Uc.column]=[]);Yb[Uc.Wb][Uc.column].push(Uc)}v.sa(ge);this.as=Yb;break;case "Viewbox":var Pg=a,Te=b,rj=c,Rq=d;1<m.length&&v.k("Viewbox Panel cannot contain more than one GraphObject.");var Ue=m[0];Ue.Tb=1;Ue.fp();fj(Ue,Infinity,Infinity,rj,Rq);var sj=Ue.Ca,Rk=Ue.margin,Sq=Rk.right+Rk.left,Tq=Rk.top+Rk.bottom;if(isFinite(Pg)||isFinite(Te)){var bt=Ue.scale,Sk=sj.width,
Tk=sj.height,Uq=Math.max(Pg-Sq,0),Vq=Math.max(Te-Tq,0),tj=1;this.Io===Pi?0!==Sk&&0!==Tk&&(tj=Math.min(Uq/Sk,Vq/Tk)):0!==Sk&&0!==Tk&&(tj=Math.max(Uq/Sk,Vq/Tk));0===tj&&(tj=1E-4);Ue.Tb*=tj;bt!==Ue.scale&&(Qk(Ue,!0),fj(Ue,Infinity,Infinity,rj,Rq))}sj=Ue.Ca;e.width=isFinite(Pg)?Pg:Math.max(sj.width+Sq,0);e.height=isFinite(Te)?Te:Math.max(sj.height+Tq,0);break;case "Link":var Wq=m.length,hg=this instanceof Tg?this.Mf:this;if(hg instanceof W)if(0===Wq){var Uk=this.Qc;Xa(Uk,0,0);var je=this.Ca;je.m(0,0,
0,0)}else{var Vk=this instanceof Tg?null:hg.path,ig=hg.bn,Ve=this.Ji;Ve.assign(ig);Ve.x=0;var Qg=Ve.y=0,jg=hg.points,Qg=void 0!==this.oa?this.oa:jg.count;this instanceof Tg?this.Wm(!1):hg.Wm(!1);var Xq=ig.width,Yq=ig.height;this.wi.m(ig.x,ig.y);null===this.Lg&&(this.Lg=new I(B));this.Lg.clear();null!==Vk&&(Wn(Vk,Xq,Yq,this),je=Vk.Ca,Ve.ai(je),this.Lg.add(je));for(var oi=v.ph(),uj=v.L(),kg=v.L(),pn=0;pn<Wq;pn++){var Sb=m[pn];if(Sb!==Vk)if(Sb.Rf&&Sb instanceof X)Wn(Sb,Xq,Yq,this),je=Sb.Ca,Ve.ai(je),
this.Lg.add(je);else if(2>Qg)fj(Sb,Infinity,Infinity),je=Sb.Ca,Ve.ai(je),this.Lg.add(je);else{var hd=Sb.ve,$q=Sb.kA,qn=Sb.Tj;qn.se()&&(qn=Ab);var vj=Sb.Vt,ct=Sb.SE,wj=0,xj=0,Wk=0;if(hd<-Qg||hd>=Qg){var ar=hg.tE,pi=hg.sE;vj!==Ai&&(Wk=hg.computeAngle(Sb,vj,pi),Sb.Jl=Wk);wj=ar.x-ig.x;xj=ar.y-ig.y}else{var zd,We;if(0<=hd)zd=jg.da(hd),We=hd<Qg-1?jg.da(hd+1):zd;else{var rh=Qg+hd;zd=jg.da(rh);We=0<rh?jg.da(rh-1):zd}if(zd.Fc(We)){var sh,th;0<=hd?(sh=0<hd?jg.da(hd-1):zd,th=hd<Qg-2?jg.da(hd+2):We):(sh=rh<Qg-
1?jg.da(rh+1):zd,th=1<rh?jg.da(rh-2):We);var br=sh.rf(zd),cr=We.rf(th),pi=br>cr+10?0<=hd?sh.xc(zd):zd.xc(sh):cr>br+10?0<=hd?We.xc(th):th.xc(We):0<=hd?sh.xc(th):th.xc(sh)}else pi=0<=hd?zd.xc(We):We.xc(zd);vj!==Ai&&(Wk=hg.computeAngle(Sb,vj,pi),Sb.Jl=Wk);wj=zd.x+(We.x-zd.x)*$q-ig.x;xj=zd.y+(We.y-zd.y)*$q-ig.y}fj(Sb,Infinity,Infinity);var je=Sb.Ca,Uk=Sb.Ha,yj=0;Sb instanceof X&&(yj=Sb.lb);var Xk=Uk.width+yj,rn=Uk.height+yj;oi.reset();oi.translate(-je.x,-je.y);oi.scale(Sb.scale,Sb.scale);oi.rotate(vj===
Ai?Sb.angle:pi,Xk/2,rn/2);var qi=new B(0,0,Xk,rn);uj.Yt(qi,qn);oi.ub(uj);var dt=-uj.x+yj/2,et=-uj.y+yj/2;kg.assign(ct);isNaN(kg.x)&&(kg.x=0<=hd?Xk/2+3:-(Xk/2+3));isNaN(kg.y)&&(kg.y=-(rn/2+3));kg.rotate(pi);wj+=kg.x;xj+=kg.y;qi.set(je);qi.x=wj+dt;qi.y=xj+et;this.Lg.add(qi);Ve.ai(qi)}}if(this.Ce)for(var dr=this.Sf;dr.next();)fj(dr.value,Infinity,Infinity);this.Ji=Ve;var sn=this.wi;sn.m(sn.x+Ve.x,sn.y+Ve.y);Xa(e,Ve.width||0,Ve.height||0);v.Ze(oi);v.v(uj);v.v(kg)}break;case "Grid":break;case "TableRow":case "TableColumn":v.k(this.toString()+
" is not an element of a Table Panel. TableRow and TableColumn Panels can only be elements of a Table Panel.");break;default:v.k("Unknown panel type: "+n)}}var Xe=e.width,Ye=e.height,Yk=this.padding,ft=Yk.top+Yk.bottom,Xe=Xe+(Yk.left+Yk.right),Ye=Ye+ft;isFinite(f.width)&&(Xe=f.width);isFinite(f.height)&&(Ye=f.height);Xe=Math.min(k.width,Xe);Ye=Math.min(k.height,Ye);Xe=Math.max(h.width,Xe);Ye=Math.max(h.height,Ye);Xe=Math.max(c,Xe);Ye=Math.max(d,Ye);e.width=Xe;e.height=Ye;Xa(this.Qc,Xe,Ye);Bm(this,
0,0,Xe,Ye)};D.prototype.findMainElement=D.prototype.Xe=function(){if(null===this.um){var a=this.za.n,b=a.length;if(0===b)return null;for(var c=0;c<b;c++){var d=a[c];if(!0===d.Rf)return this.um=d}this.um=a[0]}return this.um};
D.prototype.Li=function(a,b,c,d){var e=this.Ji,f=this.za.n,h=v.gk(0,0,0,0);if(0===f.length){var k=this.U;k.x=a;k.y=b;k.width=c;k.height=d}else{if(!this.xa.G()){var l=Em(this,!0),m=this.kd,n=m.width,p=m.height,q=this.margin,r=q.left+q.right,s=q.top+q.bottom;n===c&&p===d&&(l=Ni);switch(l){case Ni:if(n>c||p>d)this.P(),fj(this,n>c?c:n,p>d?d:p);break;case Fe:this.P(!0);fj(this,c+r,d+s,0,0);break;case um:this.P(!0);fj(this,c+r,p+s,0,0);break;case tm:this.P(!0),fj(this,n+r,d+s,0,0)}}k=this.U;k.x=a;k.y=b;
k.width=c;k.height=d;var t=this.ia.Ob;switch(t){case "Position":for(var u=f.length,y=e.x-this.padding.left,w=e.y-this.padding.top,x=0;x<u;x++){var A=f[x],H=A.Ca,C=A.margin,T=A.position.x,ba=A.position.y;h.x=isNaN(T)?-y:T-y;h.y=isNaN(ba)?-w:ba-w;if(A instanceof X){var U=A;if(U.eE){var M=U.lb/2;h.x-=M;h.y-=M}}h.x+=C.left;h.y+=C.top;h.width=H.width;h.height=H.height;A.visible&&A.Ec(h.x,h.y,h.width,h.height)}break;case "Vertical":for(var aa=f.length,Ia=this.padding.left,oa=this.padding.top,Ga=0;Ga<aa;Ga++){var pa=
Ia,Ca=f[Ga];if(Ca.visible){var ua=Ca.Ca,gc=Ca.margin,rd=gc.left+gc.right,sd=Ia+this.padding.right,Je=ua.width,Wb=Em(Ca,!1);if(isNaN(Ca.xa.width)&&Wb===Fe||Wb===um)Je=Math.max(e.width-rd-sd,0);var Gg=Je+rd+sd,td=Ca.alignment;td.Uc()&&(td=this.fl);td.cd()||(td=Ab);Ca.Ec(pa+td.offsetX+gc.left+(e.width*td.x-Gg*td.x),oa+td.offsetY+gc.top,Je,ua.height);oa+=ua.height+gc.bottom+gc.top}}break;case "Horizontal":for(var qb=f.length,gb=this.padding.top,ka=this.padding.left,la=0;la<qb;la++){var rb=gb,Va=f[la];
if(Va.visible){var Ra=Va.Ca,se=Va.margin,ud=se.top+se.bottom,be=gb+this.padding.bottom,Ld=Ra.height,ce=Em(Va,!1);if(isNaN(Va.xa.height)&&ce===Fe||ce===tm)Ld=Math.max(e.height-ud-be,0);var Hg=Ld+ud+be,ad=Va.alignment;ad.Uc()&&(ad=this.fl);ad.cd()||(ad=Ab);Va.Ec(ka+ad.offsetX+se.left,rb+ad.offsetY+se.top+(e.height*ad.y-Hg*ad.y),Ra.width,Ld);ka+=Ra.width+se.left+se.right}}break;case "Spot":var gi=f.length,xb=this.Xe(),Xb=xb.Ca,Md=Xb.width,jh=Xb.height,Lb=this.padding,jb=Lb.left,bb=Lb.top;h.x=jb-e.x;
h.y=bb-e.y;xb.Ec(h.x,h.y,Md,jh);for(var Ke=0;Ke<gi;Ke++){var Nd=f[Ke];if(Nd!==xb){var Ig=Nd.Ca,Jg=Ig.width,hc=Ig.height,ic=Nd.alignment;ic.Uc()&&(ic=this.fl);ic.cd()||(ic=Ab);var de=Nd.Tj;de.Uc()&&(de=Ab);h.x=ic.x*Md+ic.offsetX-(de.x*Jg-de.offsetX);h.y=ic.y*jh+ic.offsetY-(de.y*hc-de.offsetY);h.x-=e.x;h.y-=e.y;Nd.visible&&Nd.Ec(jb+h.x,bb+h.y,Jg,hc)}}break;case "Auto":var bd=f.length,lb=this.Xe(),Oc=lb.Ca,Ka=v.ag();Ka.m(0,0,1,1);var mb=lb.margin,tc=mb.left,vd=mb.top,Ib=this.padding,ee=Ib.left,qf=Ib.top;
h.x=tc;h.y=vd;h.width=Oc.width;h.height=Oc.height;lb.Ec(ee+h.x,qf+h.y,h.width,h.height);var Le=Sn(lb),Jf=Tn(lb),Jb=0+Le.y*Oc.height+Le.offsetY,te=0+Jf.x*Oc.width+Jf.offsetX,Mb=0+Jf.y*Oc.height+Jf.offsetY;Ka.x=0+Le.x*Oc.width+Le.offsetX;Ka.y=Jb;ab(Ka,te,Mb,0,0);Ka.x+=tc+ee;Ka.y+=vd+qf;for(var jc=0;jc<bd;jc++){var Qb=f[jc];if(Qb!==lb){var Fc=Qb.Ca,mb=Qb.margin,nb=Math.max(Fc.width+mb.right+mb.left,0),Cb=Math.max(Fc.height+mb.top+mb.bottom,0),Kb=Qb.alignment;Kb.Uc()&&(Kb=this.fl);Kb.cd()||(Kb=Ab);h.x=
Ka.width*Kb.x+Kb.offsetX-nb*Kb.x+mb.left+Ka.x;h.y=Ka.height*Kb.y+Kb.offsetY-Cb*Kb.y+mb.top+Ka.y;h.width=Ka.width;h.height=Ka.height;Qb.visible&&(db(Ka.x,Ka.y,Ka.width,Ka.height,h.x,h.y,Fc.width,Fc.height)?Qb.Ec(h.x,h.y,Fc.width,Fc.height):Qb.Ec(h.x,h.y,Fc.width,Fc.height,new B(Ka.x,Ka.y,Ka.width,Ka.height)))}}v.Vb(Ka);break;case "Table":for(var Od=f.length,Kg=this.padding,kh=Kg.left,lh=Kg.top,ue=this.as,cd=0,rf=0,kc=ue.length,yb=0,zb=0;zb<kc;zb++)ue[zb]&&(yb=Math.max(yb,ue[zb].length));for(var Gc=
Math.min(this.Ii,kc-1);Gc!==kc&&(void 0===this.Ld[Gc]||0===this.Ld[Gc].bb);)Gc++;for(var Gc=Math.min(Gc,kc-1),Lg=-this.Ld[Gc].kb,Pc=Math.min(this.vi,yb-1);Pc!==yb&&(void 0===this.Gd[Pc]||0===this.Gd[Pc].bb);)Pc++;for(var Pc=Math.min(Pc,yb-1),Qc=-this.Gd[Pc].kb,kb=v.El(),zb=0;zb<kc;zb++)if(ue[zb])for(var yb=ue[zb].length,Rc=this.zd(zb),rf=Rc.kb+Lg+lh+Rc.sD(),Pd=0;Pd<yb;Pd++)if(ue[zb][Pd])for(var fe=this.yd(Pd),cd=fe.kb+Qc+kh+fe.sD(),sf=ue[zb][Pd],hi=sf.length,pj=0;pj<hi;pj++){var Fa=sf[pj],Me=Fa.Ca,
ge=Fa instanceof D?Fa:null;if(null===ge||ge.type!==Im&&ge.type!==Jm){kb.m(0,0);for(var ca=1;ca<Fa.rowSpan&&!(zb+ca>=this.Dp);ca++){var da=this.zd(zb+ca);kb.height+=da.total}for(ca=1;ca<Fa.rG&&!(Pd+ca>=this.Qo);ca++){var Mg=this.yd(Pd+ca);kb.width+=Mg.total}var mh=fe.bb+kb.width,ii=Rc.bb+kb.height;h.x=cd;h.y=rf;h.width=mh;h.height=ii;var uc=cd,ji=rf,Yb=mh,nh=ii;cd+mh>e.width&&(Yb=Math.max(e.width-cd,0));rf+ii>e.height&&(nh=Math.max(e.height-rf,0));var dd=Fa.alignment,ve=0,Hc=0,ed=0,vc=0;if(dd.Uc()){dd=
this.fl;dd.cd()||(dd=Ab);var ve=dd.x,Hc=dd.y,ed=dd.offsetX,vc=dd.offsetY,fd=fe.alignment,ag=Rc.alignment;fd.cd()&&(ve=fd.x,ed=fd.offsetX);ag.cd()&&(Hc=ag.y,vc=ag.offsetY)}else ve=dd.x,Hc=dd.y,ed=dd.offsetX,vc=dd.offsetY;if(isNaN(ve)||isNaN(Hc))Hc=ve=.5,vc=ed=0;var Y=Me.width,we=Me.height,Ne=Fa.margin,ki=Ne.left+Ne.right,li=Ne.top+Ne.bottom,Ic=Sm(Fa,Rc,fe,!1);!isNaN(Fa.xa.width)||Ic!==Fe&&Ic!==um||(Y=Math.max(mh-ki,0));!isNaN(Fa.xa.height)||Ic!==Fe&&Ic!==tm||(we=Math.max(ii-li,0));var ob=Fa.xf,pb=
Fa.ih,Y=Math.min(ob.width,Y),we=Math.min(ob.height,we),Y=Math.max(pb.width,Y),we=Math.max(pb.height,we),Nk=we+li;h.x+=h.width*ve-(Y+ki)*ve+ed+Ne.left;h.y+=h.height*Hc-Nk*Hc+vc+Ne.top;Fa.visible&&(db(uc,ji,Yb,nh,h.x,h.y,Me.width,Me.height)?Fa.Ec(h.x,h.y,Y,we):Fa.Ec(h.x,h.y,Y,we,new B(uc,ji,Yb,nh)))}else{Fa.ri();Fa.mc.Oa();var bg=Fa.mc;bg.x=ge.type===Im?kh:cd;bg.y=ge.type===Jm?lh:rf;bg.width=Me.width;bg.height=Me.height;Fa.mc.freeze();Gm(Fa,!1)}}v.Zj(kb);for(zb=0;zb<Od;zb++)Fa=f[zb],ge=Fa instanceof
D?Fa:null,null===ge||ge.type!==Im&&ge.type!==Jm||(bg=Fa.mc,Fa.Qc.Oa(),Fa.Qc.m(0,0,bg.width,bg.height),Fa.Qc.freeze());break;case "Viewbox":var Ng=f[0],Og=Ng.Ca,Rb=Ng.margin,cg=Rb.top+Rb.bottom,dg=Math.max(Og.width+(Rb.right+Rb.left),0),Sc=Math.max(Og.height+cg,0),xe=Ng.alignment;xe.Uc()&&(xe=this.fl);xe.cd()||(xe=Ab);h.x=e.width*xe.x-dg*xe.x+xe.offsetX;h.y=e.height*xe.y-Sc*xe.y+xe.offsetY;h.width=Og.width;h.height=Og.height;Ng.Ec(h.x,h.y,h.width,h.height);break;case "Link":var on=f.length,mi=this instanceof
Tg?this.Mf:this;if(mi instanceof W){var Oe=this instanceof Tg?null:mi.path;if(null!==this.Lg){var Pe=this.Lg.n,wd=0;if(null!==Oe&&wd<this.Lg.count){var gd=Pe[wd];wd++;Oe.Ec(gd.x-this.Ji.x,gd.y-this.Ji.y,gd.width,gd.height)}for(var oh=0;oh<on;oh++){var ye=f[oh];ye!==Oe&&wd<this.Lg.count&&(gd=Pe[wd],wd++,ye.Ec(gd.x-this.Ji.x,gd.y-this.Ji.y,gd.width,gd.height))}}var ze=mi.points,Ok=ze.count;if(2<=Ok&&this.Ce)for(var Pk=this.Sf;Pk.next();){var Tc=Pk.value,tf=Ok,Ae=ze,Sa=Tc.ve,uf=Tc.kA,Be=Tc.Tj,eg=Tc.Vt,
Kf=Tc.SE,cb=0,Qe=0,wc=0;if(Sa<-tf||Sa>=tf){var ni=this.tE,fg=this.sE;eg!==Ai&&(wc=this.computeAngle(Tc,eg,fg),Tc.angle=wc);cb=ni.x;Qe=ni.y}else{var hb=void 0,xd=void 0;if(0<=Sa)hb=Ae.n[Sa],xd=Sa<tf-1?Ae.n[Sa+1]:hb;else var Ce=tf+Sa,hb=Ae.n[Ce],xd=0<Ce?Ae.n[Ce-1]:hb;if(hb.Fc(xd)){var he=void 0,ie=void 0;0<=Sa?(he=0<Sa?Ae.n[Sa-1]:hb,ie=Sa<tf-2?Ae.n[Sa+2]:xd):(he=Ce<tf-1?Ae.n[Ce+1]:hb,ie=1<Ce?Ae.n[Ce-2]:xd);var ph=he.rf(hb),qj=xd.rf(ie),fg=ph>qj+10?0<=Sa?he.xc(hb):hb.xc(he):qj>ph+10?0<=Sa?xd.xc(ie):
ie.xc(xd):0<=Sa?he.xc(ie):ie.xc(he)}else fg=0<=Sa?hb.xc(xd):xd.xc(hb);eg!==Ai&&(wc=this.computeAngle(Tc,eg,fg),Tc.angle=wc);cb=hb.x+(xd.x-hb.x)*uf;Qe=hb.y+(xd.y-hb.y)*uf}if(Be.K(sb))Tc.location=new z(cb,Qe);else{Be.se()&&(Be=Ab);var yd=v.ph();yd.reset();yd.scale(Tc.scale,Tc.scale);yd.rotate(Tc.angle,0,0);var Re=Tc.Ha,gg=v.gk(0,0,Re.width,Re.height),Se=v.L();Se.Yt(gg,Be);yd.ub(Se);var Uc=-Se.x,Pg=-Se.y,Te=Kf.copy();isNaN(Te.x)&&(Te.x=0<=Sa?Se.x+3:-(Se.x+3));isNaN(Te.y)&&(Te.y=-(Se.y+3));Te.rotate(fg);
cb+=Te.x;Qe+=Te.y;yd.nF(gg);var Uc=Uc+gg.x,Pg=Pg+gg.y,rj=v.Mb(cb+Uc,Qe+Pg);Tc.move(rj);v.v(rj);v.v(Se);v.Vb(gg);v.Ze(yd)}}this instanceof Tg?this.Wm(!1):mi.Wm(!1)}break;case "Grid":break;case "TableRow":case "TableColumn":v.k(this.toString()+" is not an element of a Table Panel.TableRow and TableColumn panels can only be elements of a Table Panel.");break;default:v.k("Unknown panel type: "+t)}v.Vb(h)}};
D.prototype.Vj=function(a){var b=this.Ha;if(db(0,0,b.width,b.height,a.x,a.y)){for(var b=this.za.n,c=b.length,d=v.Mb(0,0);c--;){var e=b[c];if(e.visible||e===this.cc)if(Qa(d.set(a),e.transform),e.Fa(d))return v.v(d),!0}v.v(d);return null===this.Ib&&null===this.nc?!1:!0}return!1};D.prototype.zw=function(a){if(this.qn===a)return this;for(var b=this.za.n,c=b.length,d=0;d<c;d++){var e=b[d].zw(a);if(null!==e)return e}return null};
function Xn(a,b,c){c(a,b);if(b instanceof D){b=b.za.n;for(var d=b.length,e=0;e<d;e++)Xn(a,b[e],c)}}function nl(a,b){Yn(a,a,b)}function Yn(a,b,c){c(b);b=b.za.n;for(var d=b.length,e=0;e<d;e++){var f=b[e];f instanceof D&&Yn(a,f,c)}}D.prototype.walkVisualTree=D.prototype.UI=function(a){Zn(this,this,a)};function Zn(a,b,c){c(b);if(b instanceof D){b=b.za.n;for(var d=b.length,e=0;e<d;e++)Zn(a,b[e],c)}}D.prototype.findInVisualTree=D.prototype.ct=function(a){return $n(this,this,a)};
function $n(a,b,c){if(c(b))return b;if(b instanceof D){b=b.za.n;for(var d=b.length,e=0;e<d;e++){var f=$n(a,b[e],c);if(null!==f)return f}}return null}D.prototype.findObject=D.prototype.be=function(a){if(this.name===a)return this;for(var b=this.za.n,c=b.length,d=0;d<c;d++){var e=b[d];if(e.name===a)return e;if(e instanceof D)if(null===e.tj&&null===e.hg){if(e=e.be(a),null!==e)return e}else if(Il(e)&&(e=e.za.first(),null!==e&&(e=e.be(a),null!==e)))return e}return null};
function ao(a){a=a.za.n;for(var b=a.length,c=0,d=0;d<b;d++){var e=a[d];if(e instanceof D)c=Math.max(c,ao(e));else if(e instanceof X){a:{if(!e.Jh)switch(e.Mn){case "None":case "Square":case "Ellipse":case "Circle":case "LineH":case "LineV":case "FramedRectangle":case "RoundedRectangle":case "Line1":case "Line2":case "Border":case "Cube1":case "Cube2":case "Junction":case "Cylinder1":case "Cylinder2":case "Cylinder3":case "Cylinder4":case "PlusLine":case "XLine":case "ThinCross":case "ThickCross":e=
0;break a}e=e.Qh/2*e.tm*e.Ri()}c=Math.max(c,e)}}return c}g=D.prototype;g.Kg=function(){return!(this.type===Im||this.type===Jm)};
g.qe=function(a,b,c){if(!1===this.Gg)return null;void 0===b&&(b=null);void 0===c&&(c=null);if(el(this))return null;var d=this.Ha,e=1/this.Ri(),f=this.Kg(),h=f?a:Qa(v.Mb(a.x,a.y),this.transform),k=this.g,l=10,m=5;null!==k&&(l=k.ap("extraTouchArea"),m=l/2);if(db(-(m*e),-(m*e),d.width+l*e,d.height+l*e,h.x,h.y)){if(!this.Pg){var e=this.za.n,n=e.length,k=v.L(),m=(l=this.cE)?this.Xe():null;if(l&&(m.Kg()?Qa(k.set(a),m.transform):k.set(a),!m.Fa(k)))return v.v(k),f||v.v(h),null;for(;n--;){var p=e[n];if(p.visible||
p===this.cc)if(p.Kg()?Qa(k.set(a),p.transform):k.set(a),!l||p!==m){var q=null;p instanceof D?q=p.qe(k,b,c):!0===p.Gg&&p.Fa(k)&&(q=p);if(null!==q&&(null!==b&&(q=b(q)),null!==q&&(null===c||c(q))))return v.v(k),f||v.v(h),q}}v.v(k)}if(null===this.background&&null===this.Bm)return f||v.v(h),null;a=db(0,0,d.width,d.height,h.x,h.y)?this:null;f||v.v(h);return a}f||v.v(h);return null};
g.ft=function(a,b,c,d){if(!1===this.Gg)return!1;void 0===b&&(b=null);void 0===c&&(c=null);d instanceof I||d instanceof J||(d=new I(G));var e=this.Ha,f=this.Kg(),h=f?a:Qa(v.Mb(a.x,a.y),this.transform);if(db(0,0,e.width,e.height,h.x,h.y)){if(!this.Pg){for(var e=this.za.n,k=e.length,l=v.L();k--;){var m=e[k];if(m.visible||m===this.cc){m.Kg()?Qa(l.set(a),m.transform):l.set(a);var n=m,m=m instanceof D?m:null;(null!==m?m.ft(l,b,c,d):n.Fa(l))&&!1!==n.Gg&&(null!==b&&(n=b(n)),null===n||null!==c&&!c(n)||d.add(n))}}v.v(l)}f||
v.v(h);return null!==this.background||null!==this.Bm}f||v.v(h);return!1};
g.Yj=function(a,b,c,d,e,f){if(!1===this.Gg)return!1;void 0===b&&(b=null);void 0===c&&(c=null);var h=f;void 0===f&&(h=v.ph(),h.reset());h.multiply(this.transform);if(this.Gm(a,h))return bo(this,b,c,e),void 0===f&&v.Ze(h),!0;if(this.xg(a,h)){if(!this.Pg)for(var k=this.za.n,l=k.length;l--;){var m=k[l];if(m.visible||m===this.cc){var n=m.U,p=this.Ha;if(!(n.x>p.width||n.y>p.height||0>n.x+n.width||0>n.y+n.height)){n=m;m=m instanceof D?m:null;p=v.ph();p.set(h);if(null!==m?m.Yj(a,b,c,d,e,p):Dm(n,a,d,p))null!==
b&&(n=b(n)),null===n||null!==c&&!c(n)||e.add(n);v.Ze(p)}}}void 0===f&&v.Ze(h);return d}void 0===f&&v.Ze(h);return!1};function bo(a,b,c,d){for(var e=a.za.n,f=e.length;f--;){var h=e[f];if(h.visible){var k=h.U,l=a.Ha;k.x>l.width||k.y>l.height||0>k.x+k.width||0>k.y+k.height||(h instanceof D&&bo(h,b,c,d),null!==b&&(h=b(h)),null===h||null!==c&&!c(h)||d.add(h))}}}
g.Mm=function(a,b,c,d,e,f){if(!1===this.Gg)return!1;void 0===c&&(c=null);void 0===d&&(d=null);var h=this.Ha,k=this.Kg(),l=k?a:Qa(v.Mb(a.x,a.y),this.transform),m=k?b:Qa(v.Mb(b.x,b.y),this.transform),n=l.rf(m),p=0<l.x&&l.x<h.width&&0<l.y&&l.y<h.height||Ta(l.x,l.y,0,0,0,h.height)<n||Ta(l.x,l.y,0,h.height,h.width,h.height)<n||Ta(l.x,l.y,h.width,h.height,h.width,0)<n||Ta(l.x,l.y,h.width,0,0,0)<n,h=0<l.x&&l.x<h.width&&0<l.y&&l.y<h.height&&Ta(l.x,l.y,0,0,0,h.height)<n&&Ta(l.x,l.y,0,h.height,h.width,h.height)<
n&&Ta(l.x,l.y,h.width,h.height,h.width,0)<n&&Ta(l.x,l.y,h.width,0,0,0)<n;k||(v.v(l),v.v(m));if(p){if(!this.Pg){k=v.L();l=v.L();m=this.za.n;for(n=m.length;n--;){var q=m[n];if(q.visible||q===this.cc){var r=q.U,s=this.Ha;r.x>s.width||r.y>s.height||0>r.x+r.width||0>r.y+r.height||(q.Kg()?(r=q.transform,Qa(k.set(a),r),Qa(l.set(b),r)):(k.set(a),l.set(b)),r=q,q=q instanceof D?q:null,null!==q?!q.Mm(k,l,c,d,e,f):!r.MD(k,l,e))||(null!==c&&(r=c(r)),null===r||null!==d&&!d(r)||f.add(r))}}v.v(k);v.v(l)}return e?
p:h}return!1};function Sn(a){var b=a.B;if(void 0===b||b===fc)b=null;null===b&&a instanceof X&&(a=a.Ra,null!==a&&(b=a.B));null===b&&(b=tb);return b}function Tn(a){var b=a.C;if(void 0===b||b===fc)b=null;null===b&&a instanceof X&&(a=a.Ra,null!==a&&(b=a.C));null===b&&(b=Fb);return b}D.prototype.add=D.prototype.add=function(a){v.A(a,G,D,"add:element");this.Ad(this.za.count,a)};D.prototype.elt=D.prototype.da=function(a){return this.za.da(a)};
D.prototype.insertAt=D.prototype.Ad=function(a,b){b instanceof R&&v.k("Cannot add a Part to a Panel: "+b);if(this===b||this.tl(b))this===b&&v.k("Cannot make a Panel contain itself: "+this.toString()),v.k("Cannot make a Panel indirectly contain itself: "+this.toString()+" already contains "+b.toString());var c=b.S;null!==c&&c!==this&&v.k("Cannot add a GraphObject that already belongs to another Panel to this Panel: "+b.toString()+", already contained by "+c.toString()+", cannot be shared by this Panel: "+
this.toString());this.ia!==vk||b instanceof X||v.k("Can only add Shapes to a Grid Panel, not: "+b);b.Al(this);b.jm=null;if(null!==this.lE){var d=b.data;null!==d&&"object"===typeof d&&(null===this.gg&&(this.gg=new na(Object,D)),this.gg.add(d,b))}var e=this.za,d=-1;if(c===this){for(var f=-1,h=this.za.n,k=h.length,l=0;l<k;l++)if(h[l]===b){f=l;break}if(-1!==f){if(f===a||f+1>=e.count&&a>=e.count)return;e.Vc(f);d=f}else v.k("element "+b.toString()+" has panel "+c.toString()+" but is not contained by it.")}if(0>
a||a>e.count)a=e.count;e.Ad(a,b);this.P();b.P();null!==b.Sd?this.Ah=!0:b instanceof D&&!0===b.Ah&&(this.Ah=!0);c=this.Y;null!==c&&(c.em=null,c.yj=NaN,this.Ah&&c instanceof S&&(c.Ah=!0),c.Ah&&(c.Wd=null),e=this.g,null!==e&&e.la.ib||(-1!==d&&c.Lc(Df,"elements",this,b,null,d,null),c.Lc(Cf,"elements",this,null,b,null,a)))};D.prototype.remove=D.prototype.remove=function(a){v.A(a,G,D,"remove:element");for(var b=this.za.n,c=b.length,d=-1,e=0;e<c;e++)if(b[e]===a){d=e;break}-1!==d&&this.Le(d)};
D.prototype.removeAt=D.prototype.Vc=function(a){0<=a&&this.Le(a)};D.prototype.Le=function(a){var b=this.za,c=b.da(a);c.jm=null;c.Al(null);if(null!==this.gg){var d=c.data;"object"===typeof d&&this.gg.remove(d)}b.Vc(a);Qk(this,!1);this.P();this.um===c&&(this.um=null);b=this.Y;null!==b&&(b.em=null,b.yj=NaN,b.Od(),d=this.g,null!==d&&d.la.ib||b.Lc(Df,"elements",this,c,null,a,null))};v.u(D,{Dp:"rowCount"},function(){return void 0===this.Ld?0:this.Ld.length});
D.prototype.getRowDefinition=D.prototype.zd=function(a){0>a&&v.va(a,">= 0",D,"getRowDefinition:idx");a=Math.round(a);var b=this.Ld;if(void 0===b[a]){var c=new tg;c.Al(this);c.ce=!0;c.index=a;b[a]=c}return b[a]};D.prototype.removeRowDefinition=D.prototype.GE=function(a){0>a&&v.va(a,">= 0",D,"removeRowDefinition:idx");a=Math.round(a);var b=this.Ld;this.Lc(Df,"coldefs",this,b[a],null,a,null);b[a]&&delete b[a];this.P()};v.u(D,{Qo:"columnCount"},function(){return void 0===this.Gd?0:this.Gd.length});
D.prototype.getColumnDefinition=D.prototype.yd=function(a){0>a&&v.va(a,">= 0",D,"getColumnDefinition:idx");a=Math.round(a);var b=this.Gd;if(void 0===b[a]){var c=new tg;c.Al(this);c.ce=!1;c.index=a;b[a]=c}return b[a]};D.prototype.removeColumnDefinition=D.prototype.CE=function(a){0>a&&v.va(a,">= 0",D,"removeColumnDefinition:idx");a=Math.round(a);var b=this.Gd;this.Lc(Df,"coldefs",this,b[a],null,a,null);b[a]&&delete b[a];this.P()};
v.defineProperty(D,{sI:"rowSizing"},function(){return void 0===this.pm?On:this.pm},function(a){if(void 0!==this.pm){var b=this.pm;b!==a&&(a!==On&&a!==Vn&&v.k("Panel.rowSizing must be RowColumnDefinition.ProportionalExtra or RowColumnDefinition.None, not: "+a),this.pm=a,this.P(),this.h("rowSizing",b,a))}});
v.defineProperty(D,{qG:"columnSizing"},function(){return void 0===this.Ol?On:this.Ol},function(a){if(void 0!==this.Ol){var b=this.Ol;b!==a&&(a!==On&&a!==Vn&&v.k("Panel.columnSizing must be RowColumnDefinition.ProportionalExtra or RowColumnDefinition.None, not: "+a),this.Ol=a,this.P(),this.h("columnSizing",b,a))}});
v.defineProperty(D,{OK:"topIndex"},function(){return void 0===this.Ii?0:this.Ii},function(a){if(void 0!==this.Ii){var b=this.Ii;b!==a&&((!isFinite(a)||0>a)&&v.k("Panel.topIndex must be greater than zero and a real number, not: "+a),this.Ii=a,this.P(),this.h("topIndex",b,a))}});
v.defineProperty(D,{$J:"leftIndex"},function(){return void 0===this.vi?0:this.vi},function(a){if(void 0!==this.vi){var b=this.vi;b!==a&&((!isFinite(a)||0>a)&&v.k("Panel.leftIndex must be greater than zero and a real number, not: "+a),this.vi=a,this.P(),this.h("leftIndex",b,a))}});D.prototype.findRowForLocalY=function(a){if(0>a)return-1;if(this.type!==Rm)return NaN;for(var b=0,c=this.Ld,d=c.length,e=this.Ii;e<d;e++){var f=c[e];if(void 0!==f&&(b+=f.total,a<b))return e}return-1};
D.prototype.findColumnForLocalX=function(a){if(0>a)return-1;if(this.type!==Rm)return NaN;for(var b=0,c=this.Gd,d=c.length,e=this.vi;e<d;e++){var f=c[e];if(void 0!==f&&(b+=f.total,a<b))return e}return-1};
v.defineProperty(D,{data:"data"},function(){return this.ie},function(a){var b=this.ie;if(b!==a){var c=this instanceof R&&!(this instanceof Tg);c&&v.j(a,"object",D,"data");Vg(this);this.ie=a;var d=this.g;null!==d&&(c?this instanceof W?(null!==b&&d.wk.remove(b),null!==a&&d.wk.add(a,this)):this instanceof R&&(null!==b&&d.ki.remove(b),null!==a&&d.ki.add(a,this)):(c=this.S,null!==c&&null!==c.gg&&(null!==b&&c.gg.remove(b),null!==a&&c.gg.add(a,this))));this.h("data",b,a);null!==d&&d.la.ib||null!==a&&this.Hb()}});
v.defineProperty(D,{xH:"itemIndex"},function(){return this.cr},function(a){var b=this.cr;b!==a&&(this.cr=a,this.h("itemIndex",b,a))});function Zm(a){a=a.tk;return null!==a&&a.D}
function Vg(a){var b=a.tk;if(null===b)null!==a.data&&v.k("Template cannot have .data be non-null: "+a),a.tk=b=new I(zg);else if(b.D)return;var c=new I(G);Xn(a,a,function(a,d){var e=d.sc;if(null!==e)for(Vm(d,!1),e=e.i;e.next();){var f=e.value;f.mode===Bg&&Vm(d,!0);if(null!==f.en){var h=Cg(f,a,d);null!==h&&(c.add(h),null===h.vo&&(h.vo=new I(zg)),h.vo.add(f))}b.add(f)}if(d instanceof D&&d.type===Rm){if(0<d.Ld.length)for(e=d.Ld,f=e.length,h=0;h<f;h++){var k=e[h];if(void 0!==k&&null!==k.sc)for(var l=k.sc.i;l.next();){var u=
l.value;u.pg=k;u.zs=2;u.Do=k.index;b.add(u)}}if(0<d.Gd.length)for(e=d.Gd,f=e.length,h=0;h<f;h++)if(k=e[h],void 0!==k&&null!==k.sc)for(l=k.sc.i;l.next();)u=l.value,u.pg=k,u.zs=1,u.Do=k.index,b.add(u)}});for(var d=c.i;d.next();){var e=d.value;if(null!==e.vo){Vm(e,!0);for(var f=e.vo.i;f.next();){var h=f.value;null===e.sc&&(e.sc=new I(zg));e.sc.add(h)}}e.vo=null}for(d=b.i;d.next();)if(e=d.value,f=e.pg,null!==f){e.pg=null;var k=e.sx,l=k.indexOf(".");0<l&&f instanceof D&&(h=k.substring(0,l),k=k.substr(l+
1),l=f.be(h),null!==l?(f=l,e.sx=k):v.trace('Warning: unable to find GraphObject named "'+h+'" for Binding: '+e.toString()));f instanceof tg?(e.Dl=v.ld(f.S),f.S.qn=e.Dl):(e.Dl=v.ld(f),f.qn=e.Dl)}b.freeze();a instanceof R&&a.Pd()&&(fj(a,Infinity,Infinity),a.Ec())}D.prototype.copyTemplate=function(){var a=this.copy();a.UI(function(a){a instanceof D&&(a.tk=null,a.ie=null);var c=a.sc;null!==c&&(a.sc=null,c.each(function(c){a.bind(c.copy())}))});return a};
D.prototype.updateTargetBindings=D.prototype.Hb=function(a){var b=this.tk;if(null!==b)for(void 0===a&&(a=""),b=b.i;b.next();){var c=b.value,d=c.aF;if(""===a||""===d||d===a)if(d=c.sx,null!==c.uG||""!==d){var d=this.data,e=c.en;if(null!==e)d=""===e?this:"."===e?this:".."===e?this:this.be(e);else{var f=this.g;null!==f&&c.yt&&(d=f.ba.bk)}if(null!==d){var f=this,h=c.Dl;if(-1!==h){if(f=this.zw(h),null===f)continue}else null!==c.pg&&(f=c.pg);"."===e?d=f:".."===e&&(d=f.S);e=c.zs;if(0!==e){if(!(f instanceof
D))continue;h=f;1===e?f=h.yd(c.Do):2===e&&(f=h.zd(c.Do))}void 0!==f&&c.rF(f,d)}}}};v.defineProperty(D,{lE:"itemArray"},function(){return this.tj},function(a){var b=this.tj;if(b!==a){var c=this.g;null!==c&&null!==b&&tl(c,this);this.tj=a;null!==c&&null!==a&&pl(c,this);this.h("itemArray",b,a);null!==c&&c.la.ib||this.dA()}});function Il(a){return a.type===Li||a.type===sk||a.type===xi||a.type===Rm&&0<a.za.length&&(a=a.za.da(0),a.Rf&&a instanceof D&&(a.type===Im||a.type===Jm))?!0:!1}
D.prototype.rebuildItemElements=D.prototype.dA=function(){var a=0;for(Il(this)&&(a=1);this.za.length>a;)this.Le(this.za.length-1);a=this.lE;if(null!==a)for(var b=v.Va(a),c=0;c<b;c++)Hl(this,v.Pa(a,c),c)};D.prototype.findItemPanelForData=D.prototype.NG=function(a){if(void 0===a||null===a||null===this.gg)return null;v.j(a,"object",D,"findItemPanelForData");return this.gg.ja(a)};
function Hl(a,b,c){if(!(void 0===b||null===b||0>c)){var d;d=co(a,b);var e=a.yH,f=null;null!==e&&(f=e.ja(d));null===f&&(eo||(eo=!0,v.trace('No item template Panel found for category "'+d+'" on '+a),v.trace("  Using default item template."),d=new D,e=new $g,e.bind(new zg("text","",Ef)),d.add(e),fo=d),f=fo);d=f;null!==d&&(Vg(d),d=d.copy(),"object"===typeof b&&(null===a.gg&&(a.gg=new na(Object,D)),a.gg.add(b,d)),e=c,Il(a)&&e++,a.Ad(e,d),Jl(a,e,c),d.ie=null,d.data=b)}}
function Jl(a,b,c){for(a=a.za;b<a.length;){var d=a.da(b);if(d instanceof D){var e=b,f=c;d.type===Im?d.Wb=e:d.type===Jm&&(d.column=e);d.xH=f}b++;c++}}
v.defineProperty(D,{YJ:"itemTemplate"},function(){return null===this.hg?null:this.hg.ja("")},function(a){if(null===this.hg){if(null===a)return;this.hg=new na("string",D)}var b=this.hg.ja("");b!==a&&(v.A(a,D,D,"itemTemplate"),(a instanceof R||a.Rf)&&v.k("Panel.itemTemplate must not be a Part or be Panel.isPanelMain: "+a),this.hg.add("",a),this.h("itemTemplate",b,a),a=this.g,null!==a&&a.la.ib||this.dA())});
v.defineProperty(D,{yH:"itemTemplateMap"},function(){return this.hg},function(a){var b=this.hg;if(b!==a){v.A(a,na,D,"itemTemplateMap");for(var c=a.i;c.next();){var d=c.value;(d instanceof R||d.Rf)&&v.k("Template in Panel.itemTemplateMap must not be a Part or be Panel.isPanelMain: "+d)}this.hg=a;this.h("itemTemplateMap",b,a);a=this.g;null!==a&&a.la.ib||this.dA()}});
v.defineProperty(D,{XJ:"itemCategoryProperty"},function(){return this.Zn},function(a){var b=this.Zn;b!==a&&("string"!==typeof a&&"function"!==typeof a&&v.Ed(a,"string or function",D,"itemCategoryProperty"),this.Zn=a,this.h("itemCategoryProperty",b,a))});
function co(a,b){if(null===b)return"";var c=a.Zn,d="";if("function"===typeof c)d=c(b);else if("string"===typeof c&&"object"===typeof b){if(""===c)return"";d=v.qb(b,c)}else return"";if(void 0===d)return"";if("string"===typeof d)return d;v.k("Panel.getCategoryForItemData found a non-string category for "+b+": "+d);return""}var eo=!1,fo=null;v.defineProperty(D,{SJ:"isAtomic"},function(){return this.Pg},function(a){var b=this.Pg;b!==a&&(v.j(a,"boolean",D,"isAtomic"),this.Pg=a,this.h("isAtomic",b,a))});
v.defineProperty(D,{cE:"isClipping"},function(){return this.Pq},function(a){var b=this.Pq;b!==a&&(v.j(a,"boolean",D,"isClipping"),this.Pq=a,this.h("isClipping",b,a))});function tg(){v.ec(this);this.Wg=null;this.hv=!0;this.Oc=0;this.Te=NaN;this.Cj=0;this.Aj=Infinity;this.Ae=fc;this.kb=this.bb=0;this.sc=null;this.rs=ho;this.Ph=sm;this.ls=this.Hj=null;this.ms=NaN;this.Ib=this.Hh=null;this.mq=!1}v.ea("RowColumnDefinition",tg);
tg.prototype.copy=function(){var a=new tg;a.hv=this.hv;a.Oc=this.Oc;a.Te=this.Te;a.Cj=this.Cj;a.Aj=this.Aj;a.Ae=this.Ae;a.bb=this.bb;a.kb=this.kb;a.Ph=this.Ph;a.rs=this.rs;a.Hj=null===this.Hj?null:this.Hj.R();a.ls=this.ls;a.ms=this.ms;a.Hh=null;null!==this.Hh&&(a.Hh=v.dl(this.Hh));a.Ib=this.Ib;a.mq=this.mq;a.sc=this.sc;return a};
tg.prototype.Zs=function(a){v.A(a,tg,tg,"copyFrom:pd");a.ce?this.height=a.height:this.width=a.width;this.Zi=a.Zi;this.ff=a.ff;this.alignment=a.alignment;this.stretch=a.stretch;this.au=a.au;this.Hj=null===a.Hj?null:a.Hj.R();this.Ep=a.Ep;this.Fp=a.Fp;this.Hh=null;a.Hh&&(this.Hh=v.dl(a.Hh));this.background=a.background;this.kz=a.kz;this.sc=a.sc};tg.prototype.toString=function(){return"RowColumnDefinition "+(this.ce?"(Row ":"(Column ")+this.index+") #"+v.ld(this)};var ho;
tg.Default=ho=v.p(tg,"Default",0);var Vn;tg.None=Vn=v.p(tg,"None",1);var On;tg.ProportionalExtra=On=v.p(tg,"ProportionalExtra",2);tg.prototype.Al=function(a){this.Wg=a};tg.prototype.computeEffectiveSpacingTop=tg.prototype.sD=function(){var a=0;if(0!==this.index){var b=this.Wg,c=this.Ep;null===c&&null!==b&&(c=this.ce?b.li:b.vh);null!==c&&(a=this.Fp,isNaN(a)&&(a=null!==b?this.ce?b.xh:b.wh:0))}b=this.UE;if(null===b)if(b=this.Wg,null!==b)b=b.ij;else return a;return a+(this.ce?b.top:b.left)};
tg.prototype.computeEffectiveSpacing=tg.prototype.of=function(){var a=0;if(0!==this.index){var b=this.Wg,c=this.Ep;null===c&&null!==b&&(c=this.ce?b.li:b.vh);null!==c&&(a=this.Fp,isNaN(a)&&(a=null!==b?this.ce?b.xh:b.wh:0))}b=this.UE;if(null===b)if(b=this.Wg,null!==b)b=b.ij;else return a;return a+(this.ce?b.top+b.bottom:b.left+b.right)};
tg.prototype.$c=function(a,b,c,d,e){var f=this.Wg;if(null!==f&&(f.Lc(Af,a,this,b,c,d,e),null!==this.sc&&(b=f.g,null!==b&&!b.we&&(f=f.Xo(),null!==f&&(f=f.data,null!==f)))))for(c=this.sc.i;c.next();)c.value.tx(this,f,a,b)};v.u(tg,{S:"panel"},function(){return this.Wg});v.defineProperty(tg,{ce:"isRow"},function(){return this.hv},function(a){this.hv=a});v.defineProperty(tg,{index:"index"},function(){return this.Oc},function(a){this.Oc=a});
v.defineProperty(tg,{height:"height"},function(){return this.Te},function(a){var b=this.Te;b!==a&&(0>a&&v.va(a,">= 0",tg,"height"),this.Te=a,this.Ia=this.bb,null!==this.S&&this.S.P(),this.$c("height",b,a))});v.defineProperty(tg,{width:"width"},function(){return this.Te},function(a){var b=this.Te;b!==a&&(0>a&&v.va(a,">= 0",tg,"width"),this.Te=a,this.Ia=this.bb,null!==this.S&&this.S.P(),this.$c("width",b,a))});
v.defineProperty(tg,{Zi:"minimum"},function(){return this.Cj},function(a){var b=this.Cj;b!==a&&((0>a||!isFinite(a))&&v.va(a,">= 0",tg,"minimum"),this.Cj=a,this.Ia=this.bb,null!==this.S&&this.S.P(),this.$c("minimum",b,a))});v.defineProperty(tg,{ff:"maximum"},function(){return this.Aj},function(a){var b=this.Aj;b!==a&&(0>a&&v.va(a,">= 0",tg,"maximum"),this.Aj=a,this.Ia=this.bb,null!==this.S&&this.S.P(),this.$c("maximum",b,a))});
v.defineProperty(tg,{alignment:"alignment"},function(){return this.Ae},function(a){var b=this.Ae;b.K(a)||(this.Ae=a.R(),null!==this.S&&this.S.P(),this.$c("alignment",b,a))});v.defineProperty(tg,{stretch:"stretch"},function(){return this.Ph},function(a){var b=this.Ph;b!==a&&(this.Ph=a,null!==this.S&&this.S.P(),this.$c("stretch",b,a))});
v.defineProperty(tg,{UE:"separatorPadding"},function(){return this.Hj},function(a){"number"===typeof a&&(a=new eb(a));var b=this.Hj;null!==a&&null!==b&&b.K(a)||(null!==a&&(a=a.R()),this.Hj=a,null!==this.S&&this.S.P(),this.$c("separatorPadding",b,a))});v.defineProperty(tg,{Ep:"separatorStroke"},function(){return this.ls},function(a){var b=this.ls;b!==a&&(null===a||"string"===typeof a||a instanceof ha)&&(a instanceof ha&&a.freeze(),this.ls=a,null!==this.S&&this.S.P(),this.$c("separatorStroke",b,a))});
v.defineProperty(tg,{Fp:"separatorStrokeWidth"},function(){return this.ms},function(a){var b=this.ms;b!==a&&(this.ms=a,null!==this.S&&this.S.P(),this.$c("separatorStrokeWidth",b,a))});
v.defineProperty(tg,{xI:"separatorDashArray"},function(){return this.Hh},function(a){var b=this.Hh;if(b!==a){null===a||Array.isArray(a)||v.Ed(a,"Array",tg,"separatorDashArray:value");if(null!==a){for(var c=a.length,d=0,e=0;e<c;e++){var f=a[e];"number"===typeof f&&0<=f&&isFinite(f)||v.k("separatorDashArray value "+f+" at index "+e+" must be a positive number or zero.");d+=f}if(0===d){if(null===b)return;a=null}}this.Hh=a;null!==this.S&&this.S.pa();this.$c("separatorDashArray",b,a)}});
v.defineProperty(tg,{background:"background"},function(){return this.Ib},function(a){var b=this.Ib;b!==a&&(null===a||"string"===typeof a||a instanceof ha)&&(a instanceof ha&&a.freeze(),this.Ib=a,null!==this.S&&this.S.pa(),this.$c("background",b,a))});v.defineProperty(tg,{kz:"coversSeparators"},function(){return this.mq},function(a){var b=this.mq;b!==a&&(v.j(a,"boolean",tg,"coversSeparators"),this.mq=a,null!==this.S&&this.S.pa(),this.$c("coversSeparators",b,a))});
v.defineProperty(tg,{au:"sizing"},function(){return this.rs},function(a){var b=this.rs;b!==a&&(this.rs=a,null!==this.S&&this.S.P(),this.$c("sizing",b,a))});function Un(a){if(a.au===ho){var b=a.Wg;return a.ce?b.sI:b.qG}return a.au}v.defineProperty(tg,{Ia:"actual"},function(){return this.bb},function(a){this.bb=isNaN(this.Te)?Math.max(Math.min(this.Aj,a),this.Cj):Math.max(Math.min(this.Aj,this.Te),this.Cj)});
v.defineProperty(tg,{total:"total"},function(){return this.bb+this.of()},function(a){this.bb=isNaN(this.Te)?Math.max(Math.min(this.Aj,a),this.Cj):Math.max(Math.min(this.Aj,this.Te),this.Cj);this.bb=Math.max(0,this.bb-this.of())});v.defineProperty(tg,{position:"position"},function(){return this.kb},function(a){this.kb=a});
tg.prototype.bind=tg.prototype.bind=function(a){a.pg=this;var b=this.S;if(null!==b){var c=b.Xo();null!==c&&Zm(c)&&v.k("Cannot add a Binding to a RowColumnDefinition that is already frozen: "+a+" on "+b)}null===this.sc&&(this.sc=new I(zg));this.sc.add(a)};
function X(){G.call(this);this.Ra=null;this.Mn="None";this.Jh=!1;this.Iq=sm;this.un=null;this.Dc=this.pd="black";this.Qh=1;this.zo="butt";this.Bo="miter";this.tm=10;this.Ao=null;this.ke=0;this.Oh=this.Nh=fc;this.Jr=this.Ir=NaN;this.Qq=!1;this.Lr=null;this.Pn=this.Fo="None";this.Nq=1}v.Ja(X,G);v.ea("Shape",X);
X.prototype.cloneProtected=function(a){G.prototype.cloneProtected.call(this,a);a.Ra=this.Ra;a.Mn=this.Mn;a.Jh=this.Jh;a.Iq=this.Iq;a.un=this.un;a.pd=this.pd;a.Dc=this.Dc;a.Qh=this.Qh;a.zo=this.zo;a.Bo=this.Bo;a.tm=this.tm;null!==this.Ao&&(a.Ao=v.dl(this.Ao));a.ke=this.ke;a.Nh=this.Nh.R();a.Oh=this.Oh.R();a.Ir=this.Ir;a.Jr=this.Jr;a.Qq=this.Qq;a.Lr=this.Lr;a.Fo=this.Fo;a.Pn=this.Pn;a.Nq=this.Nq};
X.prototype.toString=function(){return"Shape("+("None"!==this.Ab?this.Ab:"None"!==this.Lp?this.Lp:this.uz)+")#"+v.ld(this)};
function io(a,b,c,d){var e=c.length;if(!(4>e)){for(var f=d.Ca,h=Math.max(1,f.width),f=f.height,k=c[0],l=c[1],m=0,n=0,p=0,q=0,r=0,s=0,t=q=0,u=v.jb(),y=2;y<e;y+=2)m=c[y],n=c[y+1],p=m-k,q=n-l,0===p&&(p=.001),r=q/p,s=Math.atan2(q,p),q=Math.sqrt(p*p+q*q),u.push([p,s,r,q]),t+=q,k=m,l=n;k=c[0];l=c[1];p=d.Ca.width;d instanceof X&&(p-=d.lb);1>p&&(p=1);c=p;for(var e=0,m=c,n=h/2,y=0===n?!1:!0,w=0,q=u[w],p=q[0],s=q[1],r=q[2],q=q[3],x=0;.1<=t;){0===x&&(y?(m=c,e++,m-=n,t-=n,y=!1):(m=c,e++),0===m&&(m=1));if(m>t){v.sa(u);
return}m>q?(x=m-q,m=q):x=0;var A=Math.sqrt(m*m/(1+r*r));0>p&&(A=-A);k+=A;l+=r*A;a.translate(k,l);a.rotate(s);a.translate(-(h/2),-(f/2));0===x&&d.Xj(a,b);a.translate(h/2,f/2);a.rotate(-s);a.translate(-k,-l);t-=m;q-=m;if(0!==x){w++;if(w===u.length){v.sa(u);return}q=u[w];p=q[0];s=q[1];r=q[2];q=q[3];m=x}}v.sa(u)}}
X.prototype.Xj=function(a,b){if(null!==this.Dc||null!==this.pd){null!==this.pd&&Mm(this,a,this.pd,!0,!1);null!==this.Dc&&Mm(this,a,this.Dc,!1,!1);var c=this.Qh;if(0===c){var d=this.Y;d instanceof Tg&&d.type===xi&&"Selection"===d.gc&&d.Zb instanceof X&&d.Mf.Xe()===d.Zb&&(c=d.Zb.lb)}a.lineWidth=c;a.lineJoin=this.Bo;a.lineCap=this.zo;a.miterLimit=this.tm;var e=!1;this.Y&&b.Si("drawShadows")&&(e=this.Y.vl);var f=!0;null!==this.Dc&&null===this.pd&&(f=!1);var d=!1,h=!0,k=this.cF;null!==k&&(d=!0,h=a.pz(k,
this.ke));var l=this.Ra;if(null!==l){if(l.ia===Ge)a.beginPath(),d&&!h?zm(a,l.vc,l.Cc,l.vb,l.Bb,k,this.ke):(a.moveTo(l.vc,l.Cc),a.lineTo(l.vb,l.Bb)),null!==this.pd&&a.ug(this.pd),0!==c&&null!==this.Dc&&a.bj();else if(l.ia===He){var m=l.vc,n=l.Cc,p=l.vb,q=l.Bb,l=Math.min(m,p),r=Math.min(n,q),m=Math.abs(p-m),n=Math.abs(q-n);null!==this.pd&&(a.beginPath(),a.rect(l,r,m,n),a.ug(this.pd));if(null!==this.Dc){var s=p=0,t=0;f&&e&&(p=a.shadowOffsetX,s=a.shadowOffsetY,t=a.shadowBlur,a.shadowOffsetX=0,a.shadowOffsetY=
0,a.shadowBlur=0);d&&!h?(h=v.jb(),h.push(l),h.push(r),h.push(l+m),h.push(r),h.push(l+m),h.push(r+n),h.push(l),h.push(r+n),h.push(l),h.push(r),a.beginPath(),jo(a,h,k,this.ke),a.bj(),v.sa(h)):0!==c&&(a.beginPath(),a.rect(l,r,m,n),a.bj());f&&e&&(a.shadowOffsetX=p,a.shadowOffsetY=s,a.shadowBlur=t)}}else if(l.ia===Ie)m=l.vc,n=l.Cc,p=l.vb,q=l.Bb,l=Math.abs(p-m)/2,r=Math.abs(q-n)/2,m=Math.min(m,p)+l,n=Math.min(n,q)+r,a.beginPath(),a.moveTo(m,n-r),a.bezierCurveTo(m+Xc*l,n-r,m+l,n-Xc*r,m+l,n),a.bezierCurveTo(m+
l,n+Xc*r,m+Xc*l,n+r,m,n+r),a.bezierCurveTo(m-Xc*l,n+r,m-l,n+Xc*r,m-l,n),a.bezierCurveTo(m-l,n-Xc*r,m-Xc*l,n-r,m,n-r),a.closePath(),null!==this.pd&&a.ug(this.pd),d&&!h&&(h=v.jb(),Sd(m,n-r,m+Xc*l,n-r,m+l,n-Xc*r,m+l,n,.5,h),Sd(m+l,n,m+l,n+Xc*r,m+Xc*l,n+r,m,n+r,.5,h),Sd(m,n+r,m-Xc*l,n+r,m-l,n+Xc*r,m-l,n,.5,h),Sd(m-l,n,m-l,n-Xc*r,m-Xc*l,n-r,m,n-r,.5,h),a.beginPath(),jo(a,h,k,this.ke),v.sa(h)),0!==c&&null!==this.Dc&&(f&&e?(p=a.shadowOffsetX,s=a.shadowOffsetY,t=a.shadowBlur,a.shadowOffsetX=0,a.shadowOffsetY=
0,a.shadowBlur=0,a.bj(),a.shadowOffsetX=p,a.shadowOffsetY=s,a.shadowBlur=t):a.bj());else if(l.ia===De){r=l.kj;n=r.length;for(q=0;q<n;q++){m=r.n[q];a.beginPath();a.moveTo(m.Aa,m.ya);for(var p=m.sb.n,s=p.length,u=null,t=0;t<s;t++){var y=p[t];switch(y.ia){case hf:a.moveTo(y.I,y.J);break;case $e:a.lineTo(y.I,y.J);break;case jf:a.bezierCurveTo(y.sd,y.Fe,y.Xg,y.ng,y.vb,y.Bb);break;case kf:a.quadraticCurveTo(y.sd,y.Fe,y.vb,y.Bb);break;case lf:if(y.radiusX===y.radiusY)u=Math.PI/180,a.arc(y.sd,y.Fe,y.radiusX,
y.xe*u,(y.xe+y.Af)*u,0>y.Af);else{var u=nf(y,m),w=u.length;if(0===w){a.lineTo(y.na,y.ua);break}for(var x=0;x<w;x++){var A=u[x];0===x&&a.lineTo(A[0],A[1]);a.bezierCurveTo(A[2],A[3],A[4],A[5],A[6],A[7])}}break;case mf:x=w=0;if(null!==u&&u.type===lf){u=nf(u,m);A=u.length;if(0===A){a.lineTo(y.na,y.ua);break}u=u[A-1]||null;null!==u&&(w=u[6],x=u[7])}else w=null!==u?u.I:m.Aa,x=null!==u?u.J:m.ya;u=of(y,m,w,x);w=u.length;if(0===w){a.lineTo(y.na,y.ua);break}for(x=0;x<w;x++)A=u[x],a.bezierCurveTo(A[2],A[3],
A[4],A[5],A[6],A[7]);break;default:v.k("Segment not of valid type: "+y.ia)}y.Ch&&a.closePath();u=y}e?(t=s=p=0,m.Wn?(!0===m.Sl&&null!==this.pd?(a.ug(this.pd),f=!0):f=!1,0!==c&&null!==this.Dc&&(f&&(p=a.shadowOffsetX,s=a.shadowOffsetY,t=a.shadowBlur,a.shadowOffsetX=0,a.shadowOffsetY=0,a.shadowBlur=0),d&&!h||a.bj(),f&&(a.shadowOffsetX=p,a.shadowOffsetY=s,a.shadowBlur=t))):(f&&(p=a.shadowOffsetX,s=a.shadowOffsetY,t=a.shadowBlur,a.shadowOffsetX=0,a.shadowOffsetY=0,a.shadowBlur=0),!0===m.Sl&&null!==this.pd&&
a.ug(this.pd),0!==c&&null!==this.Dc&&(d&&!h||a.bj()),f&&(a.shadowOffsetX=p,a.shadowOffsetY=s,a.shadowBlur=t))):(!0===m.Sl&&null!==this.pd&&a.ug(this.pd),0===c||null===this.Dc||d&&!h||a.bj())}if(d&&!h)for(c=f,f=l.kj,h=f.length,l=0;l<h;l++){r=f.n[l];a.beginPath();n=v.jb();n.push(r.Aa);n.push(r.ya);q=r.Aa;m=r.ya;p=q;s=m;t=r.sb.n;y=t.length;for(u=0;u<y;u++){w=t[u];switch(w.ia){case hf:jo(a,n,k,this.ke);n.length=0;n.push(w.I);n.push(w.J);q=w.I;m=w.J;p=q;s=m;break;case $e:n.push(w.I);n.push(w.J);q=w.I;
m=w.J;break;case jf:Sd(q,m,w.sd,w.Fe,w.Xg,w.ng,w.vb,w.Bb,.5,n);q=w.I;m=w.J;break;case kf:Wd(q,m,w.sd,w.Fe,w.vb,w.Bb,.5,n);q=w.I;m=w.J;break;case lf:x=nf(w,r);A=x.length;if(0===A){n.push(w.na);n.push(w.ua);q=w.na;m=w.ua;break}for(var H=0;H<A;H++){var C=x[H];Sd(q,m,C[2],C[3],C[4],C[5],C[6],C[7],.5,n);q=C[6];m=C[7]}break;case mf:x=of(w,r,q,m);A=x.length;if(0===A){n.push(w.na);n.push(w.ua);q=w.na;m=w.ua;break}for(H=0;H<A;H++)C=x[H],Sd(q,m,C[2],C[3],C[4],C[5],C[6],C[7],.5,n),q=C[6],m=C[7];break;default:v.k("Segment not of valid type: "+
w.ia)}w.Ch&&(n.push(p),n.push(s),jo(a,n,k,this.ke))}jo(a,n,k,this.ke);v.sa(n);null!==this.Dc&&(q=n=r=0,c&&e&&(r=a.shadowOffsetX,n=a.shadowOffsetY,q=a.shadowBlur,a.shadowOffsetX=0,a.shadowOffsetY=0,a.shadowBlur=0),a.bj(),c&&e&&(a.shadowOffsetX=r,a.shadowOffsetY=n,a.shadowBlur=q))}}d&&a.nz();if(null!==this.Vw){d=this.Vw;fj(d,Infinity,Infinity);k=d.Ca;d.Ec(0,0,k.width,k.height);c=this.ml;a.save();a.beginPath();k=v.jb();if(c.type===Ge)k.push(c.Aa),k.push(c.ya),k.push(c.I),k.push(c.J),io(a,b,k,d);else if(c.type===
De)for(h=c.Ub.i;h.next();){l=h.value;k.length=0;k.push(l.Aa);k.push(l.ya);f=l.Aa;r=l.ya;n=f;q=r;m=l.sb.n;p=m.length;for(s=0;s<p;s++){t=m[s];switch(t.ia){case hf:io(a,b,k,d);k.length=0;k.push(t.I);k.push(t.J);f=t.I;r=t.J;n=f;q=r;break;case $e:k.push(t.I);k.push(t.J);f=t.I;r=t.J;break;case jf:Sd(f,r,t.sd,t.Fe,t.Xg,t.ng,t.vb,t.Bb,.5,k);f=t.I;r=t.J;break;case kf:Wd(f,r,t.sd,t.Fe,t.vb,t.Bb,.5,k);f=t.I;r=t.J;break;case lf:c=nf(t,l);e=c.length;if(0===e){k.push(t.na);k.push(t.ua);f=t.na;r=t.ua;break}for(y=
0;y<e;y++)u=c[y],Sd(f,r,u[2],u[3],u[4],u[5],u[6],u[7],.5,k),f=u[6],r=u[7];break;case mf:c=of(t,l,f,r);e=c.length;if(0===e){k.push(t.na);k.push(t.ua);f=t.na;r=t.ua;break}for(y=0;y<e;y++)u=c[y],Sd(f,r,u[2],u[3],u[4],u[5],u[6],u[7],.5,k),f=u[6],r=u[7];break;default:v.k("Segment not of valid type: "+t.ia)}t.Ch&&(k.push(n),k.push(q),io(a,b,k,d))}io(a,b,k,d)}else if(c.type===He)k.push(c.Aa),k.push(c.ya),k.push(c.I),k.push(c.ya),k.push(c.I),k.push(c.J),k.push(c.Aa),k.push(c.J),k.push(c.Aa),k.push(c.ya),
io(a,b,k,d);else if(c.type===Ie){h=new Ee;h.Aa=c.I;h.ya=(c.ya+c.J)/2;f=new wf(lf);f.xe=0;f.Af=360;f.na=(c.Aa+c.I)/2;f.ua=(c.ya+c.J)/2;f.radiusX=Math.abs(c.Aa-c.I)/2;f.radiusY=Math.abs(c.ya-c.J)/2;h.add(f);c=nf(f,h);e=c.length;if(0===e)k.push(f.na),k.push(f.ua);else for(f=h.Aa,r=h.ya,y=0;y<e;y++)u=c[y],Sd(f,r,u[2],u[3],u[4],u[5],u[6],u[7],.5,k),f=u[6],r=u[7];io(a,b,k,d)}v.sa(k);a.restore();Wc(a,!1)}}}};
function jo(a,b,c,d){var e=b.length;if(!(4>e)){var f=.001,h=c.length,k=b[0],l=b[1];if(4===e)zm(a,k,l,b[2],b[3],c,d);else{a.moveTo(k,l);for(var m=f=0,n=0,p=0,q=0,r=p=0,s=v.jb(),t=2;t<e;t+=2)f=b[t],m=b[t+1],n=f-k,p=m-l,0===n&&(n=.001),q=p/n,p=Math.sqrt(n*n+p*p),s.push([n,q,p]),r+=p,k=f,l=m;k=b[0];l=b[1];b=0;for(var e=!0,f=c[b%h],m=0!==d,t=0,p=s[t],n=p[0],q=p[1],p=p[2],u=0;.1<=r;){0===u&&(f=c[b%h],b++,m&&(d%=f,f-=d,m=!1));f>r&&(f=r);f>p?(u=f-p,f=p):u=0;var y=Math.sqrt(f*f/(1+q*q));0>n&&(y=-y);k+=y;l+=
q*y;e?a.lineTo(k,l):a.moveTo(k,l);r-=f;p-=f;if(0!==u){t++;if(t===s.length){v.sa(s);return}p=s[t];n=p[0];q=p[1];p=p[2];f=u}else e=!e}v.sa(s)}}}X.prototype.getDocumentPoint=X.prototype.Xa=function(a,b){void 0===b&&(b=new z);a.se()&&v.k("getDocumentPoint Spot must be a real, specific Spot, not: "+a.toString());var c=this.Ha,d=this.lb;b.m(a.x*(c.width+d)-d/2+c.x+a.offsetX,a.y*(c.height+d)-d/2+c.y+a.offsetY);this.Yg.ub(b);return b};
X.prototype.Vj=function(a,b){var c=this.Ra;if(null===c||null===this.fill&&null===this.stroke)return!1;var d=c.mb,e=this.lb/2;c.type!==Ge||b||(e+=2);var f=v.ag();f.assign(d);f.Qf(e+2,e+2);if(!f.Fa(a))return v.Vb(f),!1;d=e+1E-4;if(c.type===Ge){if(null===this.stroke)return!1;d=(c.I-c.Aa)*(a.x-c.Aa)+(c.J-c.ya)*(a.y-c.ya);if(0>(c.Aa-c.I)*(a.x-c.I)+(c.ya-c.J)*(a.y-c.J)||0>d)return!1;v.Vb(f);return Kd(c.Aa,c.ya,c.I,c.J,e,a.x,a.y)}if(c.type===He){var h=c.Aa,k=c.ya,l=c.I,m=c.J;f.x=Math.min(h,l);f.y=Math.min(k,
m);f.width=Math.abs(l-h);f.height=Math.abs(m-k);if(null===this.fill){f.Qf(-d,-d);if(f.Fa(a))return v.Vb(f),!1;f.Qf(d,d)}null!==this.stroke&&f.Qf(e,e);e=f.Fa(a);v.Vb(f);return e}if(c.type===Ie){var h=c.Aa,k=c.ya,l=c.I,m=c.J,c=Math.min(h,l),n=Math.min(k,m),h=Math.abs(l-h)/2,k=Math.abs(m-k)/2,c=a.x-(c+h),n=a.y-(n+k);if(null===this.fill){h-=d;k-=d;if(0>=h||0>=k||1>=c*c/(h*h)+n*n/(k*k))return v.Vb(f),!1;h+=d;k+=d}null!==this.stroke&&(h+=e,k+=e);v.Vb(f);return 0>=h||0>=k?!1:1>=c*c/(h*h)+n*n/(k*k)}if(c.type===
De)return v.Vb(f),null===this.fill?vf(c,a.x,a.y,e):c.Fa(a,e,1<this.lb,b);v.k("Unknown Geometry type: "+c.type);return!1};
X.prototype.np=function(a,b,c,d){var e=this.xa,f=this.Qh;a=Math.max(a,0);b=Math.max(b,0);var h;if(this.Jh)h=this.ml.mb;else{var k=this.Ab,l=Gd[k];if(void 0===l){var m=ko[k];"string"===typeof m&&(m=ko[m]);"function"===typeof m?(l=m(null,100,100),Gd[k]=l):v.k("Unsupported Figure: "+k)}h=l.mb}var k=h.width,l=h.height,m=h.width,n=h.height;switch(Em(this,!0)){case Ni:d=c=0;break;case Fe:m=Math.max(a-f,0);n=Math.max(b-f,0);break;case um:m=Math.max(a-f,0);d=0;break;case tm:c=0,n=Math.max(b-f,0)}isFinite(e.width)&&
(m=e.width);isFinite(e.height)&&(n=e.height);e=this.xf;h=this.ih;c=Math.max(c,h.width)-f;d=Math.max(d,h.height)-f;m=Math.min(e.width,m);n=Math.min(e.height,n);m=isFinite(m)?Math.max(c,m):Math.max(k,c);n=isFinite(n)?Math.max(d,n):Math.max(l,d);c=Oi(this);switch(c){case Ni:break;case Fe:k=m;l=n;break;case Pi:c=Math.min(m/k,n/l);isFinite(c)||(c=1);k*=c;l*=c;break;default:v.k(c+" is not a valid geometryStretch.")}if(this.Jh)k=Math.max(k,.01),l=Math.max(l,.01),h=this.ml,e=k,d=l,c=h.copy(),h=h.mb,e/=h.width,
d/=h.height,isFinite(e)||(e=1),isFinite(d)||(d=1),1===e&&1===d||c.scale(e,d),this.Ra=c;else if(null===this.Ra||this.Ra.Vn!==a-f||this.Ra.Tn!==b-f)this.Ra=X.makeGeometry(this,k,l);h=this.Ra.mb;Infinity===a||Infinity===b?Bm(this,h.x-f/2,h.y-f/2,0===a&&0===k?0:h.width+f,0===b&&0===l?0:h.height+f):Bm(this,-(f/2),-(f/2),m+f,n+f)};
function Wn(a,b,c,d){if(!1!==dl(a)){a.kd.Oa();var e=a.Qh;0===e&&d instanceof Tg&&d.type===xi&&d.Zb instanceof X&&(e=d.Zb.lb);e*=a.Tb;d instanceof W&&null!==d.Ra?(b=d.Ra.mb,Bm(a,b.x-e/2,b.y-e/2,b.width+e,b.height+e)):Bm(a,-(e/2),-(e/2),b+e,c+e);a.kd.freeze();a.kd.G()||v.k("Non-real measuredBounds has been set. Object "+a+", measuredBounds: "+a.kd.toString());Qk(a,!1)}}function Oi(a){var b=a.wz;return a.Jh?b===sm?Fe:b:b===sm?Gd[a.Ab].Md:b}X.prototype.Li=function(a,b,c,d){Fm(this,a,b,c,d)};
X.prototype.getNearestIntersectionPoint=X.prototype.SD=function(a,b,c){return this.bp(a.x,a.y,b.x,b.y,c)};
X.prototype.bp=function(a,b,c,d,e){var f=this.transform,h=1/(f.m11*f.m22-f.m12*f.m21),k=f.m22*h,l=-f.m12*h,m=-f.m21*h,n=f.m11*h,p=h*(f.m21*f.dy-f.m22*f.dx),q=h*(f.m12*f.dx-f.m11*f.dy),f=a*k+b*m+p,h=a*l+b*n+q,k=c*k+d*m+p,l=c*l+d*n+q,m=this.lb/2,p=this.Ra;null===p&&(fj(this,Infinity,Infinity),p=this.Ra);q=p.mb;n=!1;if(p.type===Ge)if(1.5>=this.lb)n=Zd(p.vc,p.Cc,p.vb,p.Bb,f,h,k,l,e);else{var r=0,s=0;p.vc===p.vb?(r=m,s=0):(b=(p.Bb-p.Cc)/(p.vb-p.vc),s=m/Math.sqrt(1+b*b),r=s*b);d=v.jb();b=new z;Zd(p.vc+
r,p.Cc+s,p.vb+r,p.Bb+s,f,h,k,l,b)&&d.push(b);b=new z;Zd(p.vc-r,p.Cc-s,p.vb-r,p.Bb-s,f,h,k,l,b)&&d.push(b);b=new z;Zd(p.vc+r,p.Cc+s,p.vc-r,p.Cc-s,f,h,k,l,b)&&d.push(b);b=new z;Zd(p.vb+r,p.Bb+s,p.vb-r,p.Bb-s,f,h,k,l,b)&&d.push(b);b=d.length;if(0===b)return v.sa(d),!1;n=!0;s=Infinity;for(r=0;r<b;r++){var k=d[r],t=(k.x-f)*(k.x-f)+(k.y-h)*(k.y-h);t<s&&(s=t,e.x=k.x,e.y=k.y)}v.sa(d)}else if(p.type===He)b=q.x-m,n=$d(b,q.y-m,q.x+q.width+m,q.y+q.height+m,f,h,k,l,e);else if(p.type===Ie)a:if(b=q.copy().Qf(m,
m),0===b.width)n=Zd(b.x,b.y,b.x,b.y+b.height,f,h,k,l,e);else if(0===b.height)n=Zd(b.x,b.y,b.x+b.width,b.y,f,h,k,l,e);else{a=b.width/2;var u=b.height/2;d=b.x+a;b=b.y+u;c=9999;f!==k&&(c=(h-l)/(f-k));if(9999>Math.abs(c)){n=h-b-c*(f-d);if(0>a*a*c*c+u*u-n*n){e.x=NaN;e.y=NaN;n=!1;break a}m=Math.sqrt(a*a*c*c+u*u-n*n);k=(-(a*a*c*n)+a*u*m)/(u*u+a*a*c*c)+d;a=(-(a*a*c*n)-a*u*m)/(u*u+a*a*c*c)+d;l=c*(k-d)+n+b;b=c*(a-d)+n+b;d=Math.abs((f-k)*(f-k))+Math.abs((h-l)*(h-l));h=Math.abs((f-a)*(f-a))+Math.abs((h-b)*(h-
b));d<h?(e.x=k,e.y=l):(e.x=a,e.y=b)}else{k=u*u;l=f-d;k-=k/(a*a)*l*l;if(0>k){e.x=NaN;e.y=NaN;n=!1;break a}m=Math.sqrt(k);l=b+m;b-=m;d=Math.abs(l-h);h=Math.abs(b-h);d<h?(e.x=f,e.y=l):(e.x=f,e.y=b)}n=!0}else if(p.type===De){var y=0,w=0,x=t=0,q=v.L(),r=k-f,s=l-h,s=r*r+s*s;e.x=k;e.y=l;for(r=0;r<p.Ub.count;r++)for(var A=p.Ub.n[r],H=A.sb,y=A.Aa,w=A.ya,C=y,T=w,ba=0;ba<H.count;ba++){var U=H.n[ba],M=U.type,t=U.I,x=U.J,aa=!1;switch(M){case hf:C=t;T=x;break;case $e:aa=lo(y,w,t,x,f,h,k,l,q);break;case jf:var aa=
U.rc,M=U.Kc,Ia=U.kh,oa=U.lh,aa=Xd(y,w,aa,M,Ia,oa,t,x,f,h,k,l,.5,q);break;case kf:aa=(y+2*U.rc)/3;M=(w+2*U.Kc)/3;Ia=(2*U.rc+t)/3;oa=(2*U.rc+t)/3;aa=Xd(y,w,aa,M,Ia,oa,t,x,f,h,k,l,.5,q);break;case lf:case mf:M=U.type===lf?nf(U,A):of(U,A,y,w);Ia=M.length;if(0===Ia){aa=lo(y,w,U.na,U.ua,f,h,k,l,q);break}for(x=0;x<Ia;x++)u=M[x],0===x&&lo(y,w,u[0],u[1],f,h,k,l,q)&&(t=mo(f,h,q,s,e),t<s&&(s=t,n=!0)),Xd(u[0],u[1],u[2],u[3],u[4],u[5],u[6],u[7],f,h,k,l,.5,q)&&(t=mo(f,h,q,s,e),t<s&&(s=t,n=!0));t=u[6];x=u[7];break;
default:v.k("Unknown Segment type: "+M)}y=t;w=x;aa&&(t=mo(f,h,q,s,e),t<s&&(s=t,n=!0));U.Gw&&(t=C,x=T,lo(y,w,t,x,f,h,k,l,q)&&(t=mo(f,h,q,s,e),t<s&&(s=t,n=!0)))}f=c-a;h=d-b;b=Math.sqrt(f*f+h*h);0!==b&&(f/=b,h/=b);e.x-=f*m;e.y-=h*m;v.v(q)}else v.k("Unknown Geometry type: "+p.type);if(!n)return!1;this.transform.ub(e);return!0};function mo(a,b,c,d,e){a=c.x-a;b=c.y-b;b=a*a+b*b;return b<d?(e.x=c.x,e.y=c.y,b):d}
function lo(a,b,c,d,e,f,h,k,l){var m=!1,n=(e-h)*(b-d)-(f-k)*(a-c);if(0===n)return!1;l.x=((e*k-f*h)*(a-c)-(e-h)*(a*d-b*c))/n;l.y=((e*k-f*h)*(b-d)-(f-k)*(a*d-b*c))/n;(a>c?a-c:c-a)<(b>d?b-d:d-b)?(e=b<d?b:d,a=b<d?d:b,(l.y>e||Ec(l.y,e))&&(l.y<a||Ec(l.y,a))&&(m=!0)):(e=a<c?a:c,a=a<c?c:a,(l.x>e||Ec(l.x,e))&&(l.x<a||Ec(l.x,a))&&(m=!0));return m}
X.prototype.containedInRect=X.prototype.Gm=function(a,b){if(void 0===b)return a.Wj(this.U);var c=this.Ra;null===c&&(fj(this,Infinity,Infinity),c=this.Ra);var c=c.mb,d=this.lb/2,e=!1,f=v.L();f.m(c.x-d,c.y-d);a.Fa(b.ub(f))&&(f.m(c.x-d,c.bottom+d),a.Fa(b.ub(f))&&(f.m(c.right+d,c.bottom+d),a.Fa(b.ub(f))&&(f.m(c.right+d,c.y-d),a.Fa(b.ub(f))&&(e=!0))));v.v(f);return e};
X.prototype.intersectsRect=X.prototype.xg=function(a,b){if(this.Gm(a,b)||void 0===b&&(b=this.transform,a.Wj(this.U)))return!0;var c=v.ph();c.set(b);c.Dz();var d=a.left,e=a.right,f=a.top,h=a.bottom,k=v.L();k.m(d,f);c.ub(k);if(this.Vj(k,!0))return v.v(k),!0;k.m(e,f);c.ub(k);if(this.Vj(k,!0))return v.v(k),!0;k.m(d,h);c.ub(k);if(this.Vj(k,!0))return v.v(k),!0;k.m(e,h);c.ub(k);if(this.Vj(k,!0))return v.v(k),!0;var l=v.L(),m=v.L();c.set(b);c.vE(this.transform);c.Dz();l.x=e;l.y=f;l.transform(c);k.x=d;k.y=
f;k.transform(c);var n=!1;no(this,k,l,m)?n=!0:(k.x=e,k.y=h,k.transform(c),no(this,k,l,m)?n=!0:(l.x=d,l.y=h,l.transform(c),no(this,k,l,m)?n=!0:(k.x=d,k.y=f,k.transform(c),no(this,k,l,m)&&(n=!0))));v.v(k);v.Ze(c);v.v(l);v.v(m);return n};function no(a,b,c,d){if(!a.SD(b,c,d))return!1;a=b.x;b=b.y;var e=c.x;c=c.y;var f=d.x;d=d.y;if(a===e){var h=0;a=0;b<c?(h=b,a=c):(h=c,a=b);return d>=h&&d<=a}a<e?(h=a,a=e):h=e;return f>=h&&f<=a}
X.prototype.MD=function(a,b,c){function d(a,b){for(var c=a.length,d=0;d<c;d+=2)if(b.Uo(a[d],a[d+1])>e)return!0;return!1}if(c&&null!==this.fill&&this.Vj(a,!0))return!0;var e=a.rf(b);b=e;1.5<this.lb&&(e=this.lb/2+Math.sqrt(e),e*=e);var f=this.Ra;if(null===f&&(fj(this,Infinity,Infinity),f=this.Ra,null===f))return!1;if(!c){var h=f.mb,k=h.x,l=h.y,m=h.x+h.width,h=h.y+h.height;if(Ua(a.x,a.y,k,l)<=e&&Ua(a.x,a.y,m,l)<=e&&Ua(a.x,a.y,k,h)<=e&&Ua(a.x,a.y,m,h)<=e)return!0}k=f.vc;l=f.Cc;m=f.vb;h=f.Bb;if(f.type===
Ge){if(c=Ta(a.x,a.y,k,l,m,h),f=(k-m)*(a.x-m)+(l-h)*(a.y-h),c<=(0<=(m-k)*(a.x-k)+(h-l)*(a.y-l)&&0<=f?e:b))return!0}else{if(f.type===He)return b=!1,c&&(b=Ta(a.x,a.y,k,l,k,h)<=e||Ta(a.x,a.y,k,l,m,l)<=e||Ta(a.x,a.y,m,l,m,h)<=e||Ta(a.x,a.y,k,h,m,h)<=e),b;if(f.type===Ie){b=a.x-(k+m)/2;var f=a.y-(l+h)/2,n=Math.abs(m-k)/2,p=Math.abs(h-l)/2;if(0===n||0===p)return c=Ta(a.x,a.y,k,l,m,h),c<=e?!0:!1;if(c){if(a=qe(n,p,b,f),a*a<=e)return!0}else return Ua(b,f,-n,0)>=e||Ua(b,f,0,-p)>=e||Ua(b,f,0,p)>=e||Ua(b,f,n,0)>=
e?!1:!0}else if(f.type===De){h=f.mb;k=h.x;l=h.y;m=h.x+h.width;h=h.y+h.height;if(a.x>m&&a.x<k&&a.y>h&&a.y<l&&Ta(a.x,a.y,k,l,k,h)>e&&Ta(a.x,a.y,k,l,m,l)>e&&Ta(a.x,a.y,m,h,k,h)>e&&Ta(a.x,a.y,m,h,m,l)>e)return!1;b=Math.sqrt(e);if(c){if(null===this.fill?vf(f,a.x,a.y,b):f.Fa(a,b,!0))return!0}else{c=f.Ub;for(b=0;b<c.count;b++){k=c.n[b];n=k.Aa;p=k.ya;if(a.Uo(n,p)>e)return!1;l=k.sb.n;m=l.length;for(h=0;h<m;h++){var q=l[h];switch(q.type){case hf:case $e:n=q.I;p=q.J;if(a.Uo(n,p)>e)return!1;break;case jf:f=v.jb();
Sd(n,p,q.rc,q.Kc,q.kh,q.lh,q.I,q.J,.8,f);n=d(f,a);v.sa(f);if(n)return!1;n=q.I;p=q.J;if(a.Uo(n,p)>e)return!1;break;case kf:f=v.jb();Wd(n,p,q.rc,q.Kc,q.I,q.J,.8,f);n=d(f,a);v.sa(f);if(n)return!1;n=q.I;p=q.J;if(a.Uo(n,p)>e)return!1;break;case lf:case mf:var r=q.type===lf?nf(q,k):of(q,k,n,p),s=r.length;if(0===s){n=q.na;p=q.ua;if(a.Uo(n,p)>e)return!1;break}q=null;f=v.jb();for(b=0;b<s;b++)if(q=r[b],f.length=0,Sd(q[0],q[1],q[2],q[3],q[4],q[5],q[6],q[7],.8,f),d(f,a))return v.sa(f),!1;v.sa(f);null!==q&&(n=
q[6],p=q[7]);break;default:v.k("Unknown Segment type: "+q.type)}}}return!0}}}return!1};v.defineProperty(X,{ml:"geometry"},function(){return this.Ra?this.Ra:this.un},function(a){var b=this.Ra;if(b!==a){this.un=null!==a?this.Ra=a.freeze():this.Ra=null;var c=this.Y;null!==c&&(c.yj=NaN);this.Jh=!0;this.P();this.h("geometry",b,a);a=this.Y;null!==a&&0!==(this.fa&1024)&&Am(this,a,"geometryString")}});
v.defineProperty(X,{UG:"geometryString"},function(){return null===this.ml?"":this.ml.toString()},function(a){a=bf(a);var b=a.normalize();this.ml=a;this.position=a=v.Mb(-b.x,-b.y);v.v(a)});v.defineProperty(X,{eE:"isGeometryPositioned"},function(){return this.Qq},function(a){var b=this.Qq;b!==a&&(this.Qq=a,this.P(),this.h("isGeometryPositioned",b,a))});X.prototype.re=function(){this.Ra=null;this.P()};
v.defineProperty(X,{fill:"fill"},function(){return this.pd},function(a){var b=this.pd;b!==a&&(a instanceof ha&&a.freeze(),this.pd=a,this.pa(),this.h("fill",b,a))});v.defineProperty(X,{stroke:"stroke"},function(){return this.Dc},function(a){var b=this.Dc;b!==a&&(a instanceof ha&&a.freeze(),this.Dc=a,this.pa(),this.h("stroke",b,a))});
v.defineProperty(X,{lb:"strokeWidth"},function(){return this.Qh},function(a){var b=this.Qh;if(b!==a)if(0<=a){this.Qh=a;this.P();var c=this.Y;null!==c&&(c.yj=NaN);this.h("strokeWidth",b,a)}else v.va(a,"value >= 0",X,"strokeWidth:value")});v.defineProperty(X,{HK:"strokeCap"},function(){return this.zo},function(a){var b=this.zo;b!==a&&("string"!==typeof a||"butt"!==a&&"round"!==a&&"square"!==a?v.va(a,'"butt", "round", or "square"',X,"strokeCap"):(this.zo=a,this.pa(),this.h("strokeCap",b,a)))});
v.defineProperty(X,{JK:"strokeJoin"},function(){return this.Bo},function(a){var b=this.Bo;b!==a&&("string"!==typeof a||"miter"!==a&&"bevel"!==a&&"round"!==a?v.va(a,'"miter", "bevel", or "round"',X,"strokeJoin"):(this.Bo=a,this.pa(),this.h("strokeJoin",b,a)))});v.defineProperty(X,{KK:"strokeMiterLimit"},function(){return this.tm},function(a){var b=this.tm;if(b!==a)if(1<=a){this.tm=a;this.pa();var c=this.Y;null!==c&&(c.yj=NaN);this.h("strokeMiterLimit",b,a)}else v.va(a,"value >= 1",X,"strokeWidth:value")});
v.defineProperty(X,{cF:"strokeDashArray"},function(){return this.Ao},function(a){var b=this.Ao;if(b!==a){null===a||Array.isArray(a)||v.Ed(a,"Array",X,"strokeDashArray:value");if(null!==a){for(var c=a.length,d=0,e=0;e<c;e++){var f=a[e];"number"===typeof f&&0<=f&&isFinite(f)||v.k("strokeDashArray:value "+f+" at index "+e+" must be a positive number or zero.");d+=f}if(0===d){if(null===b)return;a=null}}this.Ao=a;this.pa();this.h("strokeDashArray",b,a)}});
v.defineProperty(X,{IK:"strokeDashOffset"},function(){return this.ke},function(a){var b=this.ke;b!==a&&0<=a&&(this.ke=a,this.pa(),this.h("strokeDashOffset",b,a))});v.defineProperty(X,{Ab:"figure"},function(){return this.Mn},function(a){var b=this.Mn;if(b!==a){var c=ko[a];"function"===typeof c?c=a:(c=ko[a.toLowerCase()])||v.k("Unknown Shape.figure: "+a);b!==c&&(a=this.Y,null!==a&&(a.yj=NaN),this.Mn=c,this.Jh=!1,this.re(),this.h("figure",b,c))}});
v.defineProperty(X,{Lp:"toArrow"},function(){return this.Fo},function(a){var b=this.Fo;!0===a?a="Standard":!1===a&&(a="");if(b!==a){var c=oo(a);null===c?v.k("Unknown Shape.toArrow: "+a):b!==c&&(this.Fo=c,this.Jh=!1,this.re(),po(this),this.h("toArrow",b,c))}});
v.defineProperty(X,{uz:"fromArrow"},function(){return this.Pn},function(a){var b=this.Pn;!0===a?a="Standard":!1===a&&(a="");if(b!==a){var c=oo(a);null===c?v.k("Unknown Shape.fromArrow: "+a):b!==c&&(this.Pn=c,this.Jh=!1,this.re(),po(this),this.h("fromArrow",b,c))}});function po(a){var b=a.g;null!==b&&b.la.ib||(a.Vt=qo,"None"!==a.Fo?(a.ve=-1,a.Tj=rc):"None"!==a.Pn&&(a.ve=0,a.Tj=new L(1-rc.x,rc.y)))}
v.defineProperty(X,{B:"spot1"},function(){return this.Nh},function(a){v.A(a,L,X,"spot1");var b=this.Nh;b.K(a)||(this.Nh=a=a.R(),this.P(),this.h("spot1",b,a))});v.defineProperty(X,{C:"spot2"},function(){return this.Oh},function(a){v.A(a,L,X,"spot2");var b=this.Oh;b.K(a)||(this.Oh=a=a.R(),this.P(),this.h("spot2",b,a))});v.defineProperty(X,{Ac:"parameter1"},function(){return this.Ir},function(a){var b=this.Ir;b!==a&&(this.Ir=a,this.re(),this.h("parameter1",b,a))});
v.defineProperty(X,{Mt:"parameter2"},function(){return this.Jr},function(a){var b=this.Jr;b!==a&&(this.Jr=a,this.re(),this.h("parameter2",b,a))});v.u(X,{Ha:"naturalBounds"},function(){if(null!==this.Ra)return this.Qc.assign(this.Ra.mb),this.Qc;var a=this.xa;return new B(0,0,a.width,a.height)});v.defineProperty(X,{Vw:"pathPattern"},function(){return this.Lr},function(a){var b=this.Lr;b!==a&&(this.Lr=a,this.pa(),this.h("pathPattern",b,a))});
v.defineProperty(X,{yK:"pathObject"},function(){return this.Vw},function(a){this.Vw=a});v.defineProperty(X,{wz:"geometryStretch"},function(){return this.Iq},function(a){var b=this.Iq;b!==a&&(v.pb(a,G,X,"geometryStretch"),this.Iq=a,this.h("geometryStretch",b,a))});v.defineProperty(X,{interval:"interval"},function(){return this.Nq},function(a){var b=this.Nq;a=Math.floor(a);b!==a&&0<=a&&(this.Nq=a,null!==this.g&&Zk(this.g),this.P(),this.h("interval",b,a))});
X.makeGeometry=function(a,b,c){var d=a.xa,e=d.width,d=d.height;void 0!==b&&!isNaN(b)&&isFinite(b)&&(e=b);void 0!==c&&!isNaN(c)&&isFinite(c)&&(d=c);isFinite(e)||(e=100);isFinite(d)||(d=100);b=null;"None"!==a.Lp?b=Hd[a.Lp]:"None"!==a.uz?b=Hd[a.uz]:(c=ko[a.Ab],"string"===typeof c&&(c=ko[c]),void 0===c&&v.k("Unknown Shape.figure: "+a.Ab),b=c(a,e,d),b.Vn=e,b.Tn=d);null===b&&(c=ko.Rectangle,"function"===typeof c&&(b=c(a,e,d)));return b};
X.getFigureGenerators=function(){var a=new na("string","function"),b;for(b in ko)if(b!==b.toLowerCase()){var c=ko[b];"function"===typeof c&&a.add(b,c)}a.freeze();return a};
X.defineFigureGenerator=function(a,b){v.j(a,"string",X,"defineFigureGenerator:name");"string"===typeof b?""!==b&&ko[b]||v.k("Shape.defineFigureGenerator synonym must not be empty or None or not a defined figure name: "+b):v.j(b,"function",X,"defineFigureGenerator:func");var c=a.toLowerCase();""!==a&&"none"!==c&&a!==c||v.k("Shape.defineFigureGenerator name must not be empty or None or all-lower-case: "+a);ko[a]=b;ko[c]=a};
X.getArrowheadGeometries=function(){var a=new na("string",re),b;for(b in Hd)if(b!==b.toLowerCase()){var c=Hd[b];c instanceof re&&a.add(b,c)}a.freeze();return a};
X.defineArrowheadGeometry=function(a,b){v.j(a,"string",X,"defineArrowheadGeometry:name");var c=null;"string"===typeof b?(v.j(b,"string",X,"defineArrowheadGeometry:pathstr"),c=bf(b,!1)):(v.A(b,re,X,"defineArrowheadGeometry:pathstr"),c=b);var d=a.toLowerCase();""!==a&&"none"!==d&&a!==d||v.k("Shape.defineArrowheadGeometry name must not be empty or None or all-lower-case: "+a);Hd[a]=c;Hd[d]=a};
function $g(){G.call(this);this.le="";this.Dc="black";this.yh="13px sans-serif";this.vm="start";this.Sq=!0;this.Ul=this.Vl=!1;this.Rk=ro;this.xm=so;this.pv=this.De=0;this.On=this.xy=this.yy=null;this.Jk=new to;this.Cq=!1;this.mf=this.Tk=this.As=null;this.Kj=this.Jj=0;this.xi=Infinity;this.kv=0}v.Ja($g,G);v.ea("TextBlock",$g);var uo=new ta,vo=0,wo=new ta,xo=0,yo="...",zo="",Ao=(new Mc(null)).cl;$g.getEllipsis=function(){return yo};$g.setEllipsis=function(a){yo=a;wo=new ta;xo=0};
$g.prototype.cloneProtected=function(a){G.prototype.cloneProtected.call(this,a);a.le=this.le;a.Dc=this.Dc;a.yh=this.yh;a.vm=this.vm;a.Sq=this.Sq;a.Vl=this.Vl;a.Ul=this.Ul;a.Rk=this.Rk;a.xm=this.xm;a.De=this.De;a.pv=this.pv;a.yy=this.yy;a.xy=this.xy;a.On=this.On;a.Jk.Zs(this.Jk);a.Cq=this.Cq;a.As=this.As;a.Tk=this.Tk;a.mf=this.mf;a.Jj=this.Jj;a.Kj=this.Kj;a.xi=this.xi;a.kv=this.kv};
$g.prototype.toString=function(){return 22<this.le.length?'TextBlock("'+this.le.substring(0,20)+'"...)':'TextBlock("'+this.le+'")'};var Bo;$g.None=Bo=v.p($g,"None",0);var Co;$g.WrapFit=Co=v.p($g,"WrapFit",1);var so;$g.WrapDesiredSize=so=v.p($g,"WrapDesiredSize",2);var ro;$g.OverflowClip=ro=v.p($g,"OverflowClip",0);var Do;$g.OverflowEllipsis=Do=v.p($g,"OverflowEllipsis",1);$g.prototype.P=function(){G.prototype.P.call(this);this.xy=this.yy=null};
v.defineProperty($g,{font:"font"},function(){return this.yh},function(a){var b=this.yh;b!==a&&(this.yh=a,this.On=null,this.P(),this.h("font",b,a))});$g.isValidFont=function(a){var b=Ao.font;if(a===b||"10px sans-serif"===a)return!0;Ao.font="10px sans-serif";var c;Ao.font=a;var d=Ao.font;if("10px sans-serif"!==d)return Ao.font=b,!0;Ao.font="19px serif";c=Ao.font;Ao.font=a;d=Ao.font;Ao.font=b;return d!==c};
v.defineProperty($g,{text:"text"},function(){return this.le},function(a){var b=this.le;a=null!==a&&void 0!==a?a.toString():"";b!==a&&(this.le=a,this.P(),this.h("text",b,a))});v.defineProperty($g,{textAlign:"textAlign"},function(){return this.vm},function(a){var b=this.vm;b!==a&&("start"===a||"end"===a||"left"===a||"right"===a||"center"===a?(this.vm=a,this.pa(),this.h("textAlign",b,a)):v.va(a,'"start", "end", "left", "right", or "center"',$g,"textAlign"))});
v.u($g,{Ha:"naturalBounds"},function(){if(!this.Qc.G()){var a=Eo(this,this.le,this.Jk,999999).width,b=Fo(this,a,this.Jk),c=this.xa;isNaN(c.width)||(a=c.width);isNaN(c.height)||(b=c.height);Xa(this.Qc,a,b)}return this.Qc});v.defineProperty($g,{Kw:"isMultiline"},function(){return this.Sq},function(a){var b=this.Sq;b!==a&&(this.Sq=a,this.P(),this.h("isMultiline",b,a))});
v.defineProperty($g,{WJ:"isUnderline"},function(){return this.Vl},function(a){var b=this.Vl;b!==a&&(this.Vl=a,this.pa(),this.h("isUnderline",b,a))});v.defineProperty($g,{VJ:"isStrikethrough"},function(){return this.Ul},function(a){var b=this.Ul;b!==a&&(this.Ul=a,this.pa(),this.h("isStrikethrough",b,a))});v.defineProperty($g,{uF:"wrap"},function(){return this.xm},function(a){var b=this.xm;b!==a&&(this.xm=a,this.P(),this.h("wrap",b,a))});
v.defineProperty($g,{overflow:"overflow"},function(){return this.Rk},function(a){var b=this.Rk;b!==a&&(this.Rk=a,this.P(),this.h("overflow",b,a))});v.defineProperty($g,{stroke:"stroke"},function(){return this.Dc},function(a){var b=this.Dc;b!==a&&(a instanceof ha&&a.freeze(),this.Dc=a,this.pa(),this.h("stroke",b,a))});v.u($g,{DH:"lineCount"},function(){return this.De});v.defineProperty($g,{oz:"editable"},function(){return this.Cq},function(a){var b=this.Cq;b!==a&&(this.Cq=a,this.h("editable",b,a))});
v.defineProperty($g,{dF:"textEditor"},function(){return this.As},function(a){var b=this.As;b!==a&&(a instanceof HTMLElement||v.k("textEditor must be an HTMLElement, not: "+a),this.As=a,this.h("textEditor",b,a))});v.defineProperty($g,{qz:"errorFunction"},function(){return this.mf},function(a){var b=this.mf;b!==a&&(null!==a&&v.j(a,"function",$g,"errorFunction"),this.mf=a,this.h("errorFunction",b,a))});
$g.prototype.Xj=function(a,b){if(null!==this.Dc&&0!==this.le.length&&null!==this.yh){var c=this.Ha.width,d=Go(this);a.textAlign=this.vm;Mm(this,a,this.Dc,!0,!1);(this.Vl||this.Ul)&&Mm(this,a,this.Dc,!1,!1);var e=this.Jk,f=0,h=!1,k=v.Mb(0,0);this.Yg.ub(k);var l=v.Mb(0,d);this.Yg.ub(l);var m=k.rf(l);v.v(k);v.v(l);k=b.scale;8>m*k*k&&(h=!0);b.kf!==a&&(h=!1);!1===b.Si("textGreeking")&&(h=!1);for(var m=b.Uq,k=this.Jj,l=this.Kj,n=this.De,p=0;p<n;p++){var q=e.jf[p],r=e.Be[p];q>c&&(q=c);var f=f+k,s=r,r=a,
t=f,u=c,y=d,w=0,x=this.vm;"start"===x&&(x=m?"right":"left");"end"===x&&(x=m?"left":"right");h?("left"===x?w=0:"right"===x?w=u-q:"center"===x&&(w=(u-q)/2),r.fillRect(0+w,t+.25*y,q,1)):("left"===x?w=0:"right"===x?w=u:"center"===x&&(w=u/2),r.fillText(s,0+w,t+y-.25*y),s=y/20|0,0===s&&(s=1),"right"===x?w-=q:"center"===x&&(w-=q/2),this.Vl&&(r.beginPath(),r.lineWidth=s,r.moveTo(0+w,t+y-.2*y),r.lineTo(0+w+q,t+y-.2*y),r.stroke()),this.Ul&&(r.beginPath(),r.lineWidth=s,t=t+y-y/2.2|0,0!==s%2&&(t+=.5),r.moveTo(0+
w,t),r.lineTo(0+w+q,t),r.stroke()));f+=d+l}}};
$g.prototype.np=function(a,b,c,d){this.kv=a;var e=this.Jk;e.reset();var f=0,h=0;if(isNaN(this.xa.width)){f=this.le.replace(/\r\n/g,"\n").replace(/\r/g,"\n");if(0===f.length)f=0;else if(this.Kw){for(var k=h=0,l=!1;!l;){var m=f.indexOf("\n",k);-1===m&&(m=f.length,l=!0);k=Ho(f.substr(k,m-k).replace(/^\s+|\s+$/g,""),this.yh);k>h&&(h=k);k=m+1}f=h}else h=f.indexOf("\n",0),0<=h&&(f=f.substr(0,h)),f=k=Ho(f,this.yh);f=Math.min(f,a/this.scale);f=Math.max(8,f)}else f=this.xa.width;null!==this.S&&(f=Math.min(f,
this.S.xf.width),f=Math.max(f,this.S.ih.width));h=Fo(this,f,e);m=h=isNaN(this.xa.height)?Math.min(h,b/this.scale):this.xa.height;if(0!==e.Re&&1!==e.Be.length&&this.Rk===Do&&(b=this.yh,l=this.Rk===Do?Io(b):0,k=this.Jj+this.Kj,k=Math.max(0,Go(this)+k),m=Math.min(this.LH-1,Math.max(Math.floor(m/k+.01)-1,0)),!(m+1>=e.Be.length))){k=e.Be[m];for(a=Math.max(1,a-l);Ho(k,b)>a&&1<k.length;)k=k.substr(0,k.length-1);k+=yo;a=Ho(k,b);e.Be[m]=k;e.Be=e.Be.slice(0,m+1);e.jf[m]=a;e.jf=e.jf.slice(0,m+1);e.Ki=e.Be.length;
e.Re=Math.max(e.Re,a);this.De=e.Ki}if(this.uF===Co||isNaN(this.xa.width))f=e.Re,isNaN(this.xa.width)&&(f=Math.max(8,f));f=Math.max(c,f);h=Math.max(d,h);Xa(this.Qc,f,h);Bm(this,0,0,f,h)};$g.prototype.Li=function(a,b,c,d){Fm(this,a,b,c,d)};
function Eo(a,b,c,d){b=b.replace(/^\s+|\s+$/g,"");var e=0,f=0,h=0,k=a.yh,f=a.Jj+a.Kj,l=Math.max(0,Go(a)+f),h=a.Rk===Do?Io(k):0;if(a.De>=a.xi)return new ia(0,l);if(a.xm===Bo){c.Ki=1;f=Ho(b,k);if(0===h||f<=d)return c.Re=f,c.jf.push(c.Re),c.Be.push(b),new ia(f,l);var m=Jo(b);b=b.substr(m.length);for(var n=Jo(b),f=Ho(m+n,k);0<n.length&&f<=d;)m+=n,b=b.substr(n.length),n=Jo(b),f=Ho((m+n).replace(/^\s+|\s+$/g,""),k);m+=n.replace(/^\s+|\s+$/g,"");for(d=Math.max(1,d-h);Ho(m,k)>d&&1<m.length;)m=m.substr(0,
m.length-1);m+=yo;h=Ho(m,k);c.jf.push(h);c.Re=h;c.Be.push(m);return new ia(h,l)}var p=0;0===b.length&&(p=1,c.jf.push(0),c.Be.push(b));for(;0<b.length;){m=Jo(b);for(b=b.substr(m.length);Ho(m,k)>d;){n=1;f=Ho(m.substr(0,n),k);for(h=0;f<=d;)n++,h=f,f=Ho(m.substr(0,n),k);1===n?(c.jf[a.De+p]=f,e=Math.max(e,f)):(c.jf[a.De+p]=h,e=Math.max(e,h));n--;1>n&&(n=1);c.Be[a.De+p]=m.substr(0,n);p++;m=m.substr(n);if(a.De+p>a.xi)break}n=Jo(b);for(f=Ho(m+n,k);0<n.length&&f<=d;)m+=n,b=b.substr(n.length),n=Jo(b),f=Ho((m+
n).replace(/^\s+|\s+$/g,""),k);m=m.replace(/^\s+|\s+$/g,"");if(""!==m&&(0===n.length?(c.jf.push(f),e=Math.max(e,f)):(h=Ho(m,k),c.jf.push(h),e=Math.max(e,h)),c.Be.push(m),p++,a.De+p>a.xi))break}c.Ki=Math.min(a.xi,p);c.Re=Math.max(c.Re,e);return new ia(c.Re,l*c.Ki)}function Jo(a){for(var b=a.length,c=0;c<b&&" "!==a.charAt(c);)c++;for(;c<b&&" "===a.charAt(c);)c++;return c>=b?a:a.substr(0,c)}function Ho(a,b){zo!==b&&(zo=Ao.font=b);return Ao.measureText(a).width}
function Go(a){if(null!==a.On)return a.On;var b=a.yh;zo!==b&&(zo=Ao.font=b);var c=0;void 0!==uo[b]&&5E3>vo?c=uo[b]:(c=1.3*Ao.measureText("M").width,uo[b]=c,vo++);return a.On=c}function Io(a){zo!==a&&(zo=Ao.font=a);var b=0;void 0!==wo[a]&&5E3>xo?b=wo[a]:(b=Ao.measureText(yo).width,wo[a]=b,xo++);return b}
function Fo(a,b,c){var d=a.le.replace(/\r\n/g,"\n").replace(/\r/g,"\n"),e=a.Jj+a.Kj,e=Math.max(0,Go(a)+e);if(0===d.length)return c.Re=0,a.De=1,e;if(!a.Kw){var f=d.indexOf("\n",0);0<=f&&(d=d.substr(0,f))}for(var f=0,h=a.De=0,k=-1,l=!1;!l;)k=d.indexOf("\n",h),-1===k&&(k=d.length,l=!0),h<=k&&(h=d.substr(h,k-h),a.xm!==Bo?(c.Ki=0,h=Eo(a,h,c,b),f+=h.height,a.De+=c.Ki):(Eo(a,h,c,b),f+=e,a.De++),a.De===a.xi&&(l=!0)),h=k+1;return a.pv=f}
v.defineProperty($g,{wA:"textValidation"},function(){return this.Tk},function(a){var b=this.Tk;b!==a&&(null!==a&&v.j(a,"function",$g,"textValidation"),this.Tk=a,this.h("textValidation",b,a))});v.defineProperty($g,{EK:"spacingAbove"},function(){return this.Jj},function(a){var b=this.Jj;b!==a&&(this.Jj=a,this.h("spacingAbove",b,a))});v.defineProperty($g,{FK:"spacingBelow"},function(){return this.Kj},function(a){var b=this.Kj;b!==a&&(this.Kj=a,this.h("spacingBelow",b,a))});
v.defineProperty($g,{LH:"maxLines"},function(){return this.xi},function(a){var b=this.xi;b!==a&&(a=Math.floor(a),0>=a&&v.va(a,"> 0",$g,"maxLines"),this.xi=a,this.h("maxLines",b,a),this.P())});v.u($g,{hK:"metrics"},function(){return this.Jk});function to(){this.Re=this.Ki=0;this.jf=[];this.Be=[]}to.prototype.reset=function(){this.Re=this.Ki=0;this.jf=[];this.Be=[]};to.prototype.Zs=function(a){this.Ki=a.Ki;this.Re=a.Re;this.jf=v.dl(a.jf);this.Be=v.dl(a.Be)};v.u(to,{tJ:"arrSize"},function(){return this.jf});
v.u(to,{uJ:"arrText"},function(){return this.Be});v.u(to,{gK:"maxLineWidth"},function(){return this.Re});function kk(){G.call(this);this.Pe=null;this.ts="";this.Ij=Dd;this.Qn=Fe;this.Co=this.Lj=this.mf=null;this.oy=!1;this.Tu=!0;this.to=null}v.Ja(kk,G);v.ea("Picture",kk);kk.prototype.cloneProtected=function(a){G.prototype.cloneProtected.call(this,a);a.element=this.Pe;a.ts=this.ts;a.Ij=this.Ij.R();a.Qn=this.Qn;a.mf=this.mf;a.Lj=this.Lj;a.Tu=this.Tu;a.to=this.to};var Ko=new ta,Lo=0,ek=[];
function Mo(){var a=ek;if(0===a.length)for(var b=window.document.getElementsByTagName("canvas"),c=b.length,d=0;d<c;d++){var e=b[d];e.parentElement&&e.parentElement.W&&a.push(e.parentElement.W)}return a}var No;kk.clearCache=No=function(a){void 0===a&&(a="");v.j(a,"string",kk,"clearCache:url");""!==a?Ko[a]&&(delete Ko[a],Lo--):(Ko=new ta,Lo=0)};kk.prototype.toString=function(){return"Picture("+this.source+")#"+v.ld(this)};
v.defineProperty(kk,{element:"element"},function(){return this.Pe},function(a){var b=this.Pe;if(b!==a){null===a||a instanceof HTMLImageElement||a instanceof HTMLVideoElement||a instanceof HTMLCanvasElement||v.k("Picture.element must be an instance of Image, Canvas, or Video, not: "+a);this.oy=a instanceof HTMLCanvasElement;this.Pe=a;if(null!==a)if(!0===a.complete||void 0===a.complete)a.Yu instanceof Event&&null!==this.mf&&this.mf(this,a.Yu),!0===a.qy&&null!==this.Lj&&this.Lj(this,null),a.qy=!0,this.xa.G()||
(Qk(this,!1),this.P());else{var c=this;a.HB||(a.addEventListener("load",function(b){Oo(a,b);c.xa.G()||(Qk(c,!1),c.P())}),a.addEventListener("error",function(b){Po(a,b)}),a.HB=!0)}this.h("element",b,a);this.pa()}});
v.defineProperty(kk,{source:"source"},function(){return this.ts},function(a){var b=this.ts;if(b!==a){v.j(a,"string",kk,"source");this.ts=a;var c=Ko,d=this.g;if(void 0!==c[a])var e=c[a].Lo[0].source;else{30<Lo&&(No(),c=Ko);var e=v.createElement("img"),f=this;e.addEventListener("load",function(a){Oo(e,a);f.xa.G()||(Qk(f,!1),f.P())});e.addEventListener("error",function(a){Po(e,a)});e.HB=!0;e.src=a;var h=this.to;null!==h&&(e.crossOrigin=h(this));c[a]=new Qo(e);Lo++}null!==d&&Ll(d,this);this.element=e;
null!==d&&Kl(d,this);this.P();this.pa();this.h("source",b,a)}});function Oo(a,b){a.qy=!0;a.Yu=!1;for(var c=null,d=Mo(),e=d.length,f=0;f<e;f++){var h=d[f],k=h.no.ja(a.src);if(null!==k)for(e=k.length,f=0;f<e;f++)c=k[f],h.sC.add(c),h.ue(),null!==c.Lj&&c.Lj(c,b)}}function Po(a,b){a.Yu=b;for(var c=null,d=Mo(),e=d.length,f=0;f<e;f++)if(c=d[f].no.ja(a.src),null!==c){for(var e=c.length,h=v.jb(),f=0;f<e;f++)h.push(c[f]);for(f=0;f<e;f++)c=h[f],null!==c.mf&&c.mf(c,b);v.sa(h)}}
v.defineProperty(kk,{DK:"sourceCrossOrigin"},function(){return this.to},function(a){if(this.to!==a&&(null!==a&&v.j(a,"function",kk,"sourceCrossOrigin"),this.to=a,null!==this.element)){var b=this.element.src;null===a&&"string"===typeof b?this.element.crossOrigin=null:null!==a&&(this.element.crossOrigin=a(this))}});v.defineProperty(kk,{aj:"sourceRect"},function(){return this.Ij},function(a){var b=this.Ij;b.K(a)||(v.A(a,B,kk,"sourceRect"),this.Ij=a=a.R(),this.pa(),this.h("sourceRect",b,a))});
v.defineProperty(kk,{gH:"imageStretch"},function(){return this.Qn},function(a){var b=this.Qn;b!==a&&(v.pb(a,G,kk,"imageStretch"),this.Qn=a,this.pa(),this.h("imageStretch",b,a))});v.defineProperty(kk,{qz:"errorFunction"},function(){return this.mf},function(a){var b=this.mf;b!==a&&(null!==a&&v.j(a,"function",kk,"errorFunction"),this.mf=a,this.h("errorFunction",b,a))});
v.defineProperty(kk,{NK:"successFunction"},function(){return this.Lj},function(a){var b=this.Lj;b!==a&&(null!==a&&v.j(a,"function",kk,"successFunction"),this.Lj=a,this.h("successFunction",b,a))});
kk.prototype.Xj=function(a,b){var c=this.Pe;if(null!==c){var d=c.src;null!==d&&""!==d||v.k('Element has no source ("src") attribute: '+c);if(!(c.Yu instanceof Event)&&!0===c.qy){var d=this.Ha,e=0,f=0,h=this.oy,k=h?+c.width:c.naturalWidth,h=h?+c.height:c.naturalHeight;void 0===k&&c.videoWidth&&(k=c.videoWidth);void 0===h&&c.videoHeight&&(h=c.videoHeight);k=k||d.width;h=h||d.height;if(0!==k&&0!==h){var l=k,m=h;this.aj.G()&&(e=this.Ij.x,f=this.Ij.y,k=this.Ij.width,h=this.Ij.height);var n=k,p=h,q=this.Qn;
switch(q){case Ni:if(this.aj.G())break;e+=Math.max((n-d.width)/2,0);f+=Math.max((p-d.height)/2,0);k=Math.min(d.width,n);h=Math.min(d.height,p);break;case Fe:n=d.width;p=d.height;break;case Pi:case Qi:var r=0;q===Pi?(r=Math.min(d.height/p,d.width/n),n*=r,p*=r):q===Qi&&(r=Math.max(d.height/p,d.width/n),n*=r,p*=r,e+=(n-d.width)/2/n*k,f+=(p-d.height)/2/p*h,k*=1/(n/d.width),h*=1/(p/d.height),n=d.width,p=d.height)}var q=this.Ri()*b.scale,s=k*h/(n*q*p*q),r=Ko[this.source],q=null;if(void 0!==r&&16<s){2>r.Lo.length&&
(Ro(r,4,l,m),Ro(r,16,l,m));for(var l=r.Lo,m=l.length,q=l[0],t=0;t<m;t++)if(l[t].xp*l[t].xp<s)q=l[t];else break}if(!b.Jn){if(null===this.Co)if(null===this.Pe)this.Co=!1;else{l=(new Mc(null)).cl;l.drawImage(this.Pe,0,0);try{l.getImageData(0,0,1,1).data[3]&&(this.Co=!1),this.Co=!1}catch(u){this.Co=!0}}if(this.Co)return}if(b.Si("pictureRatioOptimization")&&!b.py&&void 0!==r&&null!==q&&1!==q.xp){a.save();r=q.xp;try{a.drawImage(q.source,e/r,f/r,Math.min(q.source.width,k/r),Math.min(q.source.height,h/r),
Math.max((d.width-n)/2,0),Math.max((d.height-p)/2,0),Math.min(d.width,n),Math.min(d.height,p))}catch(y){this.Tu=!1}a.restore()}else try{a.drawImage(c,e,f,k,h,Math.max((d.width-n)/2,0),Math.max((d.height-p)/2,0),Math.min(d.width,n),Math.min(d.height,p))}catch(w){this.Tu=!1}}}}};v.u(kk,{Ha:"naturalBounds"},function(){return this.Qc});
kk.prototype.np=function(a,b,c,d){var e=this.xa,f=Em(this,!0),h=this.Pe,k=this.oy;if(k||!this.wy&&h&&h.complete)this.wy=!0;null===h&&(isFinite(a)||(a=0),isFinite(b)||(b=0));isFinite(e.width)||f===Fe||f===um?(isFinite(a)||(a=this.aj.G()?this.aj.width:k?+h.width:h.naturalWidth),c=0):null!==h&&!1!==this.wy&&(a=this.aj.G()?this.aj.width:k?+h.width:h.naturalWidth);isFinite(e.height)||f===Fe||f===tm?(isFinite(b)||(b=this.aj.G()?this.aj.height:k?+h.height:h.naturalHeight),d=0):null!==h&&!1!==this.wy&&(b=
this.aj.G()?this.aj.height:k?+h.height:h.naturalHeight);isFinite(e.width)&&(a=e.width);isFinite(e.height)&&(b=e.height);e=this.xf;f=this.ih;c=Math.max(c,f.width);d=Math.max(d,f.height);a=Math.min(e.width,a);b=Math.min(e.height,b);a=Math.max(c,a);b=Math.max(d,b);null===h||h.complete||(isFinite(a)||(a=0),isFinite(b)||(b=0));Xa(this.Qc,a,b);Bm(this,0,0,a,b)};kk.prototype.Li=function(a,b,c,d){Fm(this,a,b,c,d)};function Qo(a){this.Lo=[new So(a,1)]}
function Ro(a,b,c,d){var e=new Mc(null),f=e.cl,h=1/b;e.width=c/b;e.height=d/b;b=new So(e.xd,b);c=a.Lo[a.Lo.length-1];f.setTransform(h*c.xp,0,0,h*c.xp,0,0);f.drawImage(c.source,0,0);a.Lo.push(b)}function So(a,b){this.source=a;this.xp=b}function ma(){this.o=new re;this.Rb=null}g=ma.prototype;g.reset=function(){this.o=new re;this.Rb=null};
function N(a,b,c,d,e,f){null===a.o&&v.k("StreamGeometryContext has been closed");void 0!==e&&!0===e?(null===a.Rb&&v.k("Need to call beginFigure first"),d=new wf(hf),d.I=b,d.J=c,a.Rb.sb.add(d)):(a.Rb=new Ee,a.Rb.Aa=b,a.Rb.ya=c,a.Rb.ut=d,a.o.Ub.add(a.Rb));void 0!==f&&(a.Rb.Wn=f)}function P(a){null===a.o&&v.k("StreamGeometryContext has been closed");null===a.Rb&&v.k("Need to call beginFigure first");var b=a.Rb.sb.length;0<b&&a.Rb.sb.da(b-1).close()}
function ef(a){null===a.o&&v.k("StreamGeometryContext has been closed");null===a.Rb&&v.k("Need to call beginFigure first");0<a.Rb.sb.length&&(a.Rb.ut=!0)}g.Ya=function(a){null===this.o&&v.k("StreamGeometryContext has been closed");null===this.Rb&&v.k("Need to call beginFigure first");this.Rb.vl=a};g.moveTo=function(a,b,c){void 0===c&&(c=!1);null===this.o&&v.k("StreamGeometryContext has been closed");null===this.Rb&&v.k("Need to call beginFigure first");var d=new wf(hf);d.I=a;d.J=b;c&&d.close();this.Rb.sb.add(d)};
g.lineTo=function(a,b,c){void 0===c&&(c=!1);null===this.o&&v.k("StreamGeometryContext has been closed");null===this.Rb&&v.k("Need to call beginFigure first");var d=new wf($e);d.I=a;d.J=b;c&&d.close();this.Rb.sb.add(d)};function O(a,b,c,d,e,f,h,k){void 0===k&&(k=!1);null===a.o&&v.k("StreamGeometryContext has been closed");null===a.Rb&&v.k("Need to call beginFigure first");var l=new wf(jf);l.rc=b;l.Kc=c;l.kh=d;l.lh=e;l.I=f;l.J=h;k&&l.close();a.Rb.sb.add(l)}
function cf(a,b,c,d,e){var f;void 0===f&&(f=!1);null===a.o&&v.k("StreamGeometryContext has been closed");null===a.Rb&&v.k("Need to call beginFigure first");var h=new wf(kf);h.rc=b;h.Kc=c;h.I=d;h.J=e;f&&h.close();a.Rb.sb.add(h)}g.arcTo=function(a,b,c,d,e,f,h){void 0===f&&(f=0);void 0===h&&(h=!1);null===this.o&&v.k("StreamGeometryContext has been closed");null===this.Rb&&v.k("Need to call beginFigure first");var k=new wf(lf);k.xe=a;k.Af=b;k.na=c;k.ua=d;k.radiusX=e;k.radiusY=0!==f?f:e;h&&k.close();this.Rb.sb.add(k)};
function df(a,b,c,d,e,f,h,k){var l;void 0===l&&(l=!1);null===a.o&&v.k("StreamGeometryContext has been closed");null===a.Rb&&v.k("Need to call beginFigure first");b=new wf(mf,h,k,b,c,d,e,f);l&&b.close();a.Rb.sb.add(b)}function To(a){a=Uo(a);var b=v.jb();b[0]=a[0];for(var c=1,d=1;d<a.length;)b[c]=a[d],b[c+1]=a[d],b[c+2]=a[d+1],d+=2,c+=3;v.sa(a);return b}
function Uo(a){var b=Vo(a),c=v.jb(),d=Math.floor(b.length/2),e=b.length-1;a=0===a%2?2:1;for(var f=0;f<e;f++){var h=b[f],k=b[f+1],l=b[(d+f-1)%e],m=b[(d+f+a)%e];c[2*f]=h;c[2*f+1]=ae(h.x,h.y,l.x,l.y,k.x,k.y,m.x,m.y,new z)}c[c.length]=c[0];v.sa(b);return c}function Vo(a){for(var b=v.jb(),c=1.5*Math.PI,d=0,e=0;e<a;e++)d=2*Math.PI/a*e+c,b[e]=new z(.5+.5*Math.cos(d),.5+.5*Math.sin(d));b.push(b[0]);return b}
var ko={None:"Rectangle",Rectangle:function(a,b,c){a=new re;a.type=He;a.Aa=0;a.ya=0;a.I=b;a.J=c;return a},Square:function(a,b,c){a=new re;a.Md=Pi;a.type=He;a.Aa=0;a.ya=0;a.I=Math.min(b,c);a.J=Math.min(b,c);return a},Ellipse:function(a,b,c){a=new re;a.type=Ie;a.Aa=0;a.ya=0;a.I=b;a.J=c;a.B=Ed;a.C=Fd;return a},Circle:function(a,b,c){a=new re;a.Md=Pi;a.type=Ie;a.Aa=0;a.ya=0;a.I=Math.min(b,c);a.J=Math.min(b,c);a.B=Ed;a.C=Fd;return a},Connector:"Ellipse",TriangleRight:function(a,b,c){a=new re;var d=new Ee,
e=new wf;e.I=b;e.J=.5*c;d.sb.add(e);b=new wf;b.I=0;b.J=c;d.sb.add(b.close());a.Ub.add(d);a.B=new L(0,.25);a.C=new L(.5,.75);return a},TriangleDown:function(a,b,c){a=new re;var d=new Ee,e=new wf;e.I=b;e.J=0;d.sb.add(e);e=new wf;e.I=.5*b;e.J=c;d.sb.add(e.close());a.Ub.add(d);a.B=new L(.25,0);a.C=new L(.75,.5);return a},TriangleLeft:function(a,b,c){a=new re;var d=new Ee;d.Aa=b;d.ya=c;var e=new wf;e.I=0;e.J=.5*c;d.sb.add(e);c=new wf;c.I=b;c.J=0;d.sb.add(c.close());a.Ub.add(d);a.B=new L(.5,.25);a.C=new L(1,
.75);return a},TriangleUp:function(a,b,c){a=new re;var d=new Ee;d.Aa=b;d.ya=c;var e=new wf;e.I=0;e.J=c;d.sb.add(e);c=new wf;c.I=.5*b;c.J=0;d.sb.add(c.close());a.Ub.add(d);a.B=new L(.25,.5);a.C=new L(.75,1);return a},Line1:function(a,b,c){a=new re;a.type=Ge;a.Aa=0;a.ya=0;a.I=b;a.J=c;return a},Line2:function(a,b,c){a=new re;a.type=Ge;a.Aa=b;a.ya=0;a.I=0;a.J=c;return a},MinusLine:"LineH",LineH:function(a,b,c){a=new re;a.type=Ge;a.Aa=0;a.ya=c/2;a.I=b;a.J=c/2;return a},LineV:function(a,b,c){a=new re;a.type=
Ge;a.Aa=b/2;a.ya=0;a.I=b/2;a.J=c;return a},BarH:"Rectangle",BarV:"Rectangle",Curve1:function(a,b,c){a=v.s();N(a,0,0,!1);O(a,Xc*b,0,1*b,(1-Xc)*c,b,c);b=a.o;v.q(a);return b},Curve2:function(a,b,c){a=v.s();N(a,0,0,!1);O(a,0,Xc*c,(1-Xc)*b,c,b,c);b=a.o;v.q(a);return b},Curve3:function(a,b,c){a=v.s();N(a,1*b,0,!1);O(a,1*b,Xc*c,Xc*b,1*c,0,1*c);b=a.o;v.q(a);return b},Curve4:function(a,b,c){a=v.s();N(a,1*b,0,!1);O(a,(1-Xc)*b,0,0,(1-Xc)*c,0,1*c);b=a.o;v.q(a);return b},Alternative:"Triangle",Merge:"Triangle",
Triangle:function(a,b,c){a=v.s();N(a,.5*b,0*c,!0);a.lineTo(0*b,1*c);a.lineTo(1*b,1*c,!0);b=a.o;b.B=new L(.25,.5);b.C=new L(.75,1);v.q(a);return b},Decision:"Diamond",Diamond:function(a,b,c){a=v.s();N(a,.5*b,0,!0);a.lineTo(0,.5*c);a.lineTo(.5*b,1*c);a.lineTo(1*b,.5*c,!0);b=a.o;b.B=new L(.25,.25);b.C=new L(.75,.75);v.q(a);return b},Pentagon:function(a,b,c){var d=Vo(5);a=v.s();N(a,d[0].x*b,d[0].y*c,!0);for(var e=1;5>e;e++)a.lineTo(d[e].x*b,d[e].y*c);v.sa(d);P(a);b=a.o;b.B=new L(.2,.22);b.C=new L(.8,
.9);v.q(a);return b},DataTransmission:"Hexagon",Hexagon:function(a,b,c){var d=Vo(6);a=v.s();N(a,d[0].x*b,d[0].y*c,!0);for(var e=1;6>e;e++)a.lineTo(d[e].x*b,d[e].y*c);v.sa(d);P(a);b=a.o;b.B=new L(.07,.25);b.C=new L(.93,.75);v.q(a);return b},Heptagon:function(a,b,c){var d=Vo(7);a=v.s();N(a,d[0].x*b,d[0].y*c,!0);for(var e=1;7>e;e++)a.lineTo(d[e].x*b,d[e].y*c);v.sa(d);P(a);b=a.o;b.B=new L(.2,.15);b.C=new L(.8,.85);v.q(a);return b},Octagon:function(a,b,c){var d=Vo(8);a=v.s();N(a,d[0].x*b,d[0].y*c,!0);
for(var e=1;8>e;e++)a.lineTo(d[e].x*b,d[e].y*c);v.sa(d);P(a);b=a.o;b.B=new L(.15,.15);b.C=new L(.85,.85);v.q(a);return b},Nonagon:function(a,b,c){var d=Vo(9);a=v.s();N(a,d[0].x*b,d[0].y*c,!0);for(var e=1;9>e;e++)a.lineTo(d[e].x*b,d[e].y*c);v.sa(d);P(a);b=a.o;b.B=new L(.17,.13);b.C=new L(.82,.82);v.q(a);return b},Decagon:function(a,b,c){var d=Vo(10);a=v.s();N(a,d[0].x*b,d[0].y*c,!0);for(var e=1;10>e;e++)a.lineTo(d[e].x*b,d[e].y*c);v.sa(d);P(a);b=a.o;b.B=new L(.16,.16);b.C=new L(.84,.84);v.q(a);return b},
Dodecagon:function(a,b,c){var d=Vo(12);a=v.s();N(a,d[0].x*b,d[0].y*c,!0);for(var e=1;12>e;e++)a.lineTo(d[e].x*b,d[e].y*c);v.sa(d);P(a);b=a.o;b.B=new L(.16,.16);b.C=new L(.84,.84);v.q(a);return b},FivePointedStar:function(a,b,c){var d=Uo(5);a=v.s();N(a,d[0].x*b,d[0].y*c,!0);for(var e=1;10>e;e++)a.lineTo(d[e].x*b,d[e].y*c);v.sa(d);P(a);b=a.o;b.B=new L(.312,.383);b.C=new L(.693,.765);v.q(a);return b},SixPointedStar:function(a,b,c){var d=Uo(6);a=v.s();N(a,d[0].x*b,d[0].y*c,!0);for(var e=1;12>e;e++)a.lineTo(d[e].x*
b,d[e].y*c);v.sa(d);P(a);b=a.o;b.B=new L(.17,.251);b.C=new L(.833,.755);v.q(a);return b},SevenPointedStar:function(a,b,c){var d=Uo(7);a=v.s();N(a,d[0].x*b,d[0].y*c,!0);for(var e=1;14>e;e++)a.lineTo(d[e].x*b,d[e].y*c);v.sa(d);P(a);b=a.o;b.B=new L(.363,.361);b.C=new L(.641,.709);v.q(a);return b},EightPointedStar:function(a,b,c){var d=Uo(8);a=v.s();N(a,d[0].x*b,d[0].y*c,!0);for(var e=1;16>e;e++)a.lineTo(d[e].x*b,d[e].y*c);v.sa(d);P(a);b=a.o;b.B=new L(.252,.255);b.C=new L(.75,.75);v.q(a);return b},NinePointedStar:function(a,
b,c){var d=Uo(9);a=v.s();N(a,d[0].x*b,d[0].y*c,!0);for(var e=1;18>e;e++)a.lineTo(d[e].x*b,d[e].y*c);v.sa(d);P(a);b=a.o;b.B=new L(.355,.361);b.C=new L(.645,.651);v.q(a);return b},TenPointedStar:function(a,b,c){var d=Uo(10);a=v.s();N(a,d[0].x*b,d[0].y*c,!0);for(var e=1;20>e;e++)a.lineTo(d[e].x*b,d[e].y*c);v.sa(d);P(a);b=a.o;b.B=new L(.281,.261);b.C=new L(.723,.748);v.q(a);return b},FivePointedBurst:function(a,b,c){var d=To(5);a=v.s();N(a,d[0].x*b,d[0].y*c,!0);for(var e=1;e<d.length;e+=3)O(a,d[e].x*
b,d[e].y*c,d[e+1].x*b,d[e+1].y*c,d[e+2].x*b,d[e+2].y*c);v.sa(d);P(a);b=a.o;b.B=new L(.312,.383);b.C=new L(.693,.765);v.q(a);return b},SixPointedBurst:function(a,b,c){var d=To(6);a=v.s();N(a,d[0].x*b,d[0].y*c,!0);for(var e=1;e<d.length;e+=3)O(a,d[e].x*b,d[e].y*c,d[e+1].x*b,d[e+1].y*c,d[e+2].x*b,d[e+2].y*c);v.sa(d);P(a);b=a.o;b.B=new L(.17,.251);b.C=new L(.833,.755);v.q(a);return b},SevenPointedBurst:function(a,b,c){var d=To(7);a=v.s();N(a,d[0].x*b,d[0].y*c,!0);for(var e=1;e<d.length;e+=3)O(a,d[e].x*
b,d[e].y*c,d[e+1].x*b,d[e+1].y*c,d[e+2].x*b,d[e+2].y*c);v.sa(d);P(a);b=a.o;b.B=new L(.363,.361);b.C=new L(.641,.709);v.q(a);return b},EightPointedBurst:function(a,b,c){var d=To(8);a=v.s();N(a,d[0].x*b,d[0].y*c,!0);for(var e=1;e<d.length;e+=3)O(a,d[e].x*b,d[e].y*c,d[e+1].x*b,d[e+1].y*c,d[e+2].x*b,d[e+2].y*c);v.sa(d);P(a);b=a.o;b.B=new L(.252,.255);b.C=new L(.75,.75);v.q(a);return b},NinePointedBurst:function(a,b,c){var d=To(9);a=v.s();N(a,d[0].x*b,d[0].y*c,!0);for(var e=1;e<d.length;e+=3)O(a,d[e].x*
b,d[e].y*c,d[e+1].x*b,d[e+1].y*c,d[e+2].x*b,d[e+2].y*c);v.sa(d);P(a);b=a.o;b.B=new L(.355,.361);b.C=new L(.645,.651);v.q(a);return b},TenPointedBurst:function(a,b,c){var d=To(10);a=v.s();N(a,d[0].x*b,d[0].y*c,!0);for(var e=1;e<d.length;e+=3)O(a,d[e].x*b,d[e].y*c,d[e+1].x*b,d[e+1].y*c,d[e+2].x*b,d[e+2].y*c);v.sa(d);P(a);b=a.o;b.B=new L(.281,.261);b.C=new L(.723,.748);v.q(a);return b},Cloud:function(a,b,c){a=v.s();N(a,.08034461*b,.1944299*c,!0);O(a,-.09239631*b,.07836421*c,.1406031*b,-.0542823*c,.2008615*
b,.05349299*c);O(a,.2450511*b,-.00697547*c,.3776197*b,-.01112067*c,.4338609*b,.074219*c);O(a,.4539471*b,0,.6066018*b,-.02526587*c,.6558228*b,.07004196*c);O(a,.6914277*b,-.01904177*c,.8921095*b,-.01220843*c,.8921095*b,.08370865*c);O(a,1.036446*b,.04105738*c,1.020377*b,.3022052*c,.9147671*b,.3194596*c);O(a,1.04448*b,.360238*c,.992256*b,.5219009*c,.9082935*b,.562044*c);O(a,1.032337*b,.5771781*c,1.018411*b,.8120651*c,.9212406*b,.8217117*c);O(a,1.028411*b,.9571472*c,.8556702*b,1.052487*c,.7592566*b,.9156953*
c);O(a,.7431877*b,1.009325*c,.5624123*b,1.021761*c,.5101666*b,.9310455*c);O(a,.4820677*b,1.031761*c,.3030112*b,1.002796*c,.2609328*b,.9344623*c);O(a,.2329994*b,1.01518*c,.03213784*b,1.01518*c,.08034461*b,.870098*c);O(a,-.02812061*b,.9032597*c,-.01205169*b,.6835638*c,.06829292*b,.6545475*c);O(a,-.01812061*b,.6089503*c,-.00606892*b,.4555777*c,.06427569*b,.4265613*c);O(a,-.01606892*b,.3892545*c,-.01205169*b,.1944299*c,.08034461*b,.1944299*c);P(a);b=a.o;b.B=new L(.1,.1);b.C=new L(.9,.9);v.q(a);return b},
Gate:"Crescent",Crescent:function(a,b,c){a=v.s();N(a,0,0,!0);O(a,1*b,0,1*b,1*c,0,1*c);O(a,.5*b,.75*c,.5*b,.25*c,0,0);P(a);b=a.o;b.B=new L(.511,.19);b.C=new L(.776,.76);v.q(a);return b},FramedRectangle:function(a,b,c){var d=v.s(),e=a?a.Ac:NaN;a=a?a.Mt:NaN;isNaN(e)&&(e=.1);isNaN(a)&&(a=.1);N(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(0,1*c,!0);N(d,e*b,a*c,!1,!0);d.lineTo(e*b,(1-a)*c);d.lineTo((1-e)*b,(1-a)*c);d.lineTo((1-e)*b,a*c,!0);b=d.o;b.B=new L(e,a);b.C=new L(1-e,1-a);v.q(d);return b},
Delay:"HalfEllipse",HalfEllipse:function(a,b,c){a=v.s();N(a,0,0,!0);O(a,Xc*b,0,1*b,(.5-Xc/2)*c,1*b,.5*c);O(a,1*b,(.5+Xc/2)*c,Xc*b,1*c,0,1*c);P(a);b=a.o;b.B=new L(0,.2);b.C=new L(.75,.8);v.q(a);return b},Heart:function(a,b,c){a=v.s();N(a,.5*b,1*c,!0);O(a,.1*b,.8*c,0,.5*c,0*b,.3*c);O(a,0*b,0,.45*b,0,.5*b,.3*c);O(a,.55*b,0,1*b,0,1*b,.3*c);O(a,b,.5*c,.9*b,.8*c,.5*b,1*c);P(a);b=a.o;b.B=new L(.15,.29);b.C=new L(.86,.68);v.q(a);return b},Spade:function(a,b,c){a=v.s();N(a,.5*b,0,!0);a.lineTo(.51*b,.01*c);
O(a,.6*b,.2*c,b,.25*c,b,.5*c);O(a,b,.8*c,.6*b,.8*c,.55*b,.7*c);O(a,.5*b,.75*c,.55*b,.95*c,.75*b,c);a.lineTo(.25*b,c);O(a,.45*b,.95*c,.5*b,.75*c,.45*b,.7*c);O(a,.4*b,.8*c,0,.8*c,0,.5*c);O(a,0,.25*c,.4*b,.2*c,.49*b,.01*c);P(a);b=a.o;b.B=new L(.19,.26);b.C=new L(.8,.68);v.q(a);return b},Club:function(a,b,c){a=v.s();N(a,.4*b,.6*c,!0);O(a,.5*b,.75*c,.45*b,.95*c,.15*b,1*c);a.lineTo(.85*b,c);O(a,.55*b,.95*c,.5*b,.75*c,.6*b,.6*c);var d=.2,e=.3,f=0,h=4*(Math.SQRT2-1)/3*d;O(a,(.5-d+e)*b,(.5+h+f)*c,(.5-h+e)*
b,(.5+d+f)*c,(.5+e)*b,(.5+d+f)*c);O(a,(.5+h+e)*b,(.5+d+f)*c,(.5+d+e)*b,(.5+h+f)*c,(.5+d+e)*b,(.5+f)*c);O(a,(.5+d+e)*b,(.5-h+f)*c,(.5+h+e)*b,(.5-d+f)*c,(.5+e)*b,(.5-d+f)*c);O(a,(.5-h+e)*b,(.5-d+f)*c,(.5-d+e+.05)*b,(.5-h+f-.02)*c,.65*b,.36771243*c);d=.2;e=0;f=-.3;h=4*(Math.SQRT2-1)/3*d;O(a,(.5+h+e)*b,(.5+d+f)*c,(.5+d+e)*b,(.5+h+f)*c,(.5+d+e)*b,(.5+f)*c);O(a,(.5+d+e)*b,(.5-h+f)*c,(.5+h+e)*b,(.5-d+f)*c,(.5+e)*b,(.5-d+f)*c);O(a,(.5-h+e)*b,(.5-d+f)*c,(.5-d+e)*b,(.5-h+f)*c,(.5-d+e)*b,(.5+f)*c);O(a,(.5-d+
e)*b,(.5+h+f)*c,(.5-h+e)*b,(.5+d+f)*c,.35*b,.36771243*c);d=.2;e=-.3;f=0;h=4*(Math.SQRT2-1)/3*d;O(a,(.5+d+e-.05)*b,(.5-h+f-.02)*c,(.5+h+e)*b,(.5-d+f)*c,(.5+e)*b,(.5-d+f)*c);O(a,(.5-h+e)*b,(.5-d+f)*c,(.5-d+e)*b,(.5-h+f)*c,(.5-d+e)*b,(.5+f)*c);O(a,(.5-d+e)*b,(.5+h+f)*c,(.5-h+e)*b,(.5+d+f)*c,(.5+e)*b,(.5+d+f)*c);O(a,(.5+h+e)*b,(.5+d+f)*c,(.5+d+e)*b,(.5+h+f)*c,.4*b,.6*c);P(a);b=a.o;b.B=new L(.06,.39);b.C=new L(.93,.58);v.q(a);return b},Ring:function(a,b,c){a=v.s();var d=4*(Math.SQRT2-1)/3*.5;N(a,b,.5*
c,!0);O(a,b,(.5-d)*c,(.5+d)*b,0,.5*b,0);O(a,(.5-d)*b,0,0,(.5-d)*c,0,.5*c);O(a,0,(.5+d)*c,(.5-d)*b,c,.5*b,c);O(a,(.5+d)*b,c,b,(.5+d)*c,b,.5*c);d=4*(Math.SQRT2-1)/3*.4;N(a,.5*b,.1*c,!0,!0);O(a,(.5+d)*b,.1*c,.9*b,(.5-d)*c,.9*b,.5*c);O(a,.9*b,(.5+d)*c,(.5+d)*b,.9*c,.5*b,.9*c);O(a,(.5-d)*b,.9*c,.1*b,(.5+d)*c,.1*b,.5*c);O(a,.1*b,(.5-d)*c,(.5-d)*b,.1*c,.5*b,.1*c);b=a.o;b.B=new L(.146,.146);b.C=new L(.853,.853);b.Md=Pi;v.q(a);return b},YinYang:function(a,b,c){var d=.5;a=v.s();d=.5;N(a,.5*b,0,!0);a.arcTo(270,
180,.5*b,.5*b,.5*b);O(a,1*b,d*c,0,d*c,d*b,0,!0);var d=.1,e=.25;N(a,(.5+d)*b,e*c,!0,!0);a.arcTo(0,-360,.5*b,c*e,d*b);P(a);N(a,.5*b,0,!1);a.arcTo(270,-180,.5*b,.5*b,.5*b);a.Ya(!1);e=.75;N(a,(.5+d)*b,e*c,!0);a.arcTo(0,360,.5*b,c*e,d*b);P(a);b=a.o;b.Md=Pi;v.q(a);return b},Peace:function(a,b,c){a=v.s();var d=4*(Math.SQRT2-1)/3*.5;N(a,b,.5*c,!0);O(a,b,(.5-d)*c,(.5+d)*b,0,.5*b,0);O(a,(.5-d)*b,0,0,(.5-d)*c,0,.5*c);O(a,0,(.5+d)*c,(.5-d)*b,c,.5*b,c);O(a,(.5+d)*b,c,b,(.5+d)*c,b,.5*c);d=4*(Math.SQRT2-1)/3*.4;
N(a,.5*b,.1*c,!0,!0);O(a,(.5+d)*b,.1*c,.9*b,(.5-d)*c,.9*b,.5*c);O(a,.9*b,(.5+d)*c,(.5+d)*b,.9*c,.5*b,.9*c);O(a,(.5-d)*b,.9*c,.1*b,(.5+d)*c,.1*b,.5*c);O(a,.1*b,(.5-d)*c,(.5-d)*b,.1*c,.5*b,.1*c);var d=.07,e=0,f=-.707*.11,h=4*(Math.SQRT2-1)/3*d;N(a,(.5+d+e)*b,(.5+f)*c,!0);O(a,(.5+d+e)*b,(.5-h+f)*c,(.5+h+e)*b,(.5-d+f)*c,(.5+e)*b,(.5-d+f)*c);O(a,(.5-h+e)*b,(.5-d+f)*c,(.5-d+e)*b,(.5-h+f)*c,(.5-d+e)*b,(.5+f)*c);O(a,(.5-d+e)*b,(.5+h+f)*c,(.5-h+e)*b,(.5+d+f)*c,(.5+e)*b,(.5+d+f)*c);O(a,(.5+h+e)*b,(.5+d+f)*
c,(.5+d+e)*b,(.5+h+f)*c,(.5+d+e)*b,(.5+f)*c);d=.07;e=-.707*.11;f=.707*.11;h=4*(Math.SQRT2-1)/3*d;N(a,(.5+d+e)*b,(.5+f)*c,!0);O(a,(.5+d+e)*b,(.5-h+f)*c,(.5+h+e)*b,(.5-d+f)*c,(.5+e)*b,(.5-d+f)*c);O(a,(.5-h+e)*b,(.5-d+f)*c,(.5-d+e)*b,(.5-h+f)*c,(.5-d+e)*b,(.5+f)*c);O(a,(.5-d+e)*b,(.5+h+f)*c,(.5-h+e)*b,(.5+d+f)*c,(.5+e)*b,(.5+d+f)*c);O(a,(.5+h+e)*b,(.5+d+f)*c,(.5+d+e)*b,(.5+h+f)*c,(.5+d+e)*b,(.5+f)*c);d=.07;e=.707*.11;f=.707*.11;h=4*(Math.SQRT2-1)/3*d;N(a,(.5+d+e)*b,(.5+f)*c,!0);O(a,(.5+d+e)*b,(.5-h+
f)*c,(.5+h+e)*b,(.5-d+f)*c,(.5+e)*b,(.5-d+f)*c);O(a,(.5-h+e)*b,(.5-d+f)*c,(.5-d+e)*b,(.5-h+f)*c,(.5-d+e)*b,(.5+f)*c);O(a,(.5-d+e)*b,(.5+h+f)*c,(.5-h+e)*b,(.5+d+f)*c,(.5+e)*b,(.5+d+f)*c);O(a,(.5+h+e)*b,(.5+d+f)*c,(.5+d+e)*b,(.5+h+f)*c,(.5+d+e)*b,(.5+f)*c);b=a.o;b.B=new L(.146,.146);b.C=new L(.853,.853);b.Md=Pi;v.q(a);return b},NotAllowed:function(a,b,c){var d=.5*Xc,e=.5;a=v.s();N(a,.5*b,(.5-e)*c,!0);O(a,(.5-d)*b,(.5-e)*c,(.5-e)*b,(.5-d)*c,(.5-e)*b,.5*c);O(a,(.5-e)*b,(.5+d)*c,(.5-d)*b,(.5+e)*c,.5*b,
(.5+e)*c);O(a,(.5+d)*b,(.5+e)*c,(.5+e)*b,(.5+d)*c,(.5+e)*b,.5*c);O(a,(.5+e)*b,(.5-d)*c,(.5+d)*b,(.5-e)*c,.5*b,(.5-e)*c);var e=.4,d=.4*Xc,f=v.L(),h=v.L(),k=v.L(),l=v.L();Td(.5,.5-e,.5+d,.5-e,.5+e,.5-d,.5+e,.5,.42,f,h,k,l,l);var m=v.L(),n=v.L(),p=v.L();Td(.5,.5-e,.5+d,.5-e,.5+e,.5-d,.5+e,.5,.58,l,l,p,m,n);var q=v.L(),r=v.L(),s=v.L();Td(.5,.5+e,.5-d,.5+e,.5-e,.5+d,.5-e,.5,.42,q,r,s,l,l);var t=v.L(),u=v.L(),y=v.L();Td(.5,.5+e,.5-d,.5+e,.5-e,.5+d,.5-e,.5,.58,l,l,y,t,u);N(a,y.x*b,y.y*c,!0,!0);O(a,t.x*b,
t.y*c,u.x*b,u.y*c,(.5-e)*b,.5*c);O(a,(.5-e)*b,(.5-d)*c,(.5-d)*b,(.5-e)*c,.5*b,(.5-e)*c);O(a,f.x*b,f.y*c,h.x*b,h.y*c,k.x*b,k.y*c);a.lineTo(y.x*b,y.y*c);P(a);N(a,s.x*b,s.y*c,!0,!0);a.lineTo(p.x*b,p.y*c);O(a,m.x*b,m.y*c,n.x*b,n.y*c,(.5+e)*b,.5*c);O(a,(.5+e)*b,(.5+d)*c,(.5+d)*b,(.5+e)*c,.5*b,(.5+e)*c);O(a,q.x*b,q.y*c,r.x*b,r.y*c,s.x*b,s.y*c);P(a);v.v(f);v.v(h);v.v(k);v.v(l);v.v(m);v.v(n);v.v(p);v.v(q);v.v(r);v.v(s);v.v(t);v.v(u);v.v(y);b=a.o;v.q(a);b.Md=Pi;return b},Fragile:function(a,b,c){a=v.s();N(a,
0,0,!0);a.lineTo(.25*b,0);a.lineTo(.2*b,.15*c);a.lineTo(.3*b,.25*c);a.lineTo(.29*b,.33*c);a.lineTo(.35*b,.25*c);a.lineTo(.3*b,.15*c);a.lineTo(.4*b,0);a.lineTo(1*b,0);O(a,1*b,.25*c,.75*b,.5*c,.55*b,.5*c);a.lineTo(.55*b,.9*c);a.lineTo(.7*b,.9*c);a.lineTo(.7*b,1*c);a.lineTo(.3*b,1*c);a.lineTo(.3*b,.9*c);a.lineTo(.45*b,.9*c);a.lineTo(.45*b,.5*c);O(a,.25*b,.5*c,0,.25*c,0,0);P(a);b=a.o;b.B=new L(.25,0);b.C=new L(.75,.4);v.q(a);return b},HourGlass:function(a,b,c){a=v.s();N(a,.65*b,.5*c,!0);a.lineTo(1*b,
1*c);a.lineTo(0,1*c);a.lineTo(.35*b,.5*c);a.lineTo(0,0);a.lineTo(1*b,0);P(a);b=a.o;v.q(a);return b},Lightning:function(a,b,c){a=v.s();N(a,0*b,.55*c,!0);a.lineTo(.75*b,0);a.lineTo(.25*b,.45*c);a.lineTo(.9*b,.48*c);a.lineTo(.4*b,1*c);a.lineTo(.65*b,.55*c);P(a);b=a.o;v.q(a);return b},Parallelogram1:function(a,b,c){a=a?a.Ac:NaN;isNaN(a)&&(a=.1);var d=v.s();N(d,a*b,0,!0);d.lineTo(1*b,0);d.lineTo((1-a)*b,1*c);d.lineTo(0,1*c);P(d);b=d.o;b.B=new L(a,0);b.C=new L(1-a,1);v.q(d);return b},Input:"Output",Output:function(a,
b,c){a=v.s();N(a,0,1*c,!0);a.lineTo(.1*b,0);a.lineTo(1*b,0);a.lineTo(.9*b,1*c);P(a);b=a.o;b.B=new L(.1,0);b.C=new L(.9,1);v.q(a);return b},Parallelogram2:function(a,b,c){a=a?a.Ac:NaN;isNaN(a)&&(a=.25);var d=v.s();N(d,a*b,0,!0);d.lineTo(1*b,0);d.lineTo((1-a)*b,1*c);d.lineTo(0,1*c);P(d);b=d.o;b.B=new L(a,0);b.C=new L(1-a,1);v.q(d);return b},ThickCross:function(a,b,c){a=a?a.Ac:NaN;isNaN(a)&&(a=.25);var d=v.s();N(d,(.5-a/2)*b,0,!0);d.lineTo((.5+a/2)*b,0);d.lineTo((.5+a/2)*b,(.5-a/2)*c);d.lineTo(1*b,(.5-
a/2)*c);d.lineTo(1*b,(.5+a/2)*c);d.lineTo((.5+a/2)*b,(.5+a/2)*c);d.lineTo((.5+a/2)*b,1*c);d.lineTo((.5-a/2)*b,1*c);d.lineTo((.5-a/2)*b,(.5+a/2)*c);d.lineTo(0,(.5+a/2)*c);d.lineTo(0,(.5-a/2)*c);d.lineTo((.5-a/2)*b,(.5-a/2)*c);P(d);b=d.o;b.B=new L(.5-a/2,.5-a/2);b.C=new L(.5+a/2,.5+a/2);v.q(d);return b},ThickX:function(a,b,c){a=.25/Math.SQRT2;var d=v.s();N(d,.3*b,0,!0);d.lineTo(.5*b,.2*c);d.lineTo(.7*b,0);d.lineTo(1*b,.3*c);d.lineTo(.8*b,.5*c);d.lineTo(1*b,.7*c);d.lineTo(.7*b,1*c);d.lineTo(.5*b,.8*
c);d.lineTo(.3*b,1*c);d.lineTo(0,.7*c);d.lineTo(.2*b,.5*c);d.lineTo(0,.3*c);P(d);b=d.o;b.B=new L(.5-a,.5-a);b.C=new L(.5+a,.5+a);v.q(d);return b},ThinCross:function(a,b,c){var d=a?a.Ac:NaN;isNaN(d)&&(d=.1);a=v.s();N(a,(.5-d/2)*b,0,!0);a.lineTo((.5+d/2)*b,0);a.lineTo((.5+d/2)*b,(.5-d/2)*c);a.lineTo(1*b,(.5-d/2)*c);a.lineTo(1*b,(.5+d/2)*c);a.lineTo((.5+d/2)*b,(.5+d/2)*c);a.lineTo((.5+d/2)*b,1*c);a.lineTo((.5-d/2)*b,1*c);a.lineTo((.5-d/2)*b,(.5+d/2)*c);a.lineTo(0,(.5+d/2)*c);a.lineTo(0,(.5-d/2)*c);a.lineTo((.5-
d/2)*b,(.5-d/2)*c);P(a);b=a.o;v.q(a);return b},ThinX:function(a,b,c){a=v.s();N(a,.1*b,0,!0);a.lineTo(.5*b,.4*c);a.lineTo(.9*b,0);a.lineTo(1*b,.1*c);a.lineTo(.6*b,.5*c);a.lineTo(1*b,.9*c);a.lineTo(.9*b,1*c);a.lineTo(.5*b,.6*c);a.lineTo(.1*b,1*c);a.lineTo(0,.9*c);a.lineTo(.4*b,.5*c);a.lineTo(0,.1*c);P(a);b=a.o;v.q(a);return b},RightTriangle:function(a,b,c){a=v.s();N(a,0,0,!0);a.lineTo(1*b,1*c);a.lineTo(0,1*c);P(a);b=a.o;b.B=new L(0,.5);b.C=new L(.5,1);v.q(a);return b},RoundedIBeam:function(a,b,c){a=
v.s();N(a,0,0,!0);a.lineTo(1*b,0);O(a,.5*b,.25*c,.5*b,.75*c,1*b,1*c);a.lineTo(0,1*c);O(a,.5*b,.75*c,.5*b,.25*c,0,0);P(a);b=a.o;v.q(a);return b},RoundedRectangle:function(a,b,c){var d=a?a.Ac:NaN;isNaN(d)&&(d=5);d=Math.min(d,b/3);d=Math.min(d,c/3);a=d*Xc;var e=v.s();N(e,d,0,!0);e.lineTo(b-d,0);O(e,b-a,0,b,a,b,d);e.lineTo(b,c-d);O(e,b,c-a,b-a,c,b-d,c);e.lineTo(d,c);O(e,a,c,0,c-a,0,c-d);e.lineTo(0,d);O(e,0,a,a,0,d,0);P(e);b=e.o;1<a?(b.B=new L(0,0,a,a),b.C=new L(1,1,-a,-a)):(b.B=tb,b.C=Fb);v.q(e);return b},
Border:function(a,b,c){var d=a?a.Ac:NaN;isNaN(d)&&(d=5);d=Math.min(d,b/3);d=Math.min(d,c/3);a=v.s();N(a,d,0,!0);a.lineTo(b-d,0);O(a,b-0,0,b,0,b,d);a.lineTo(b,c-d);O(a,b,c-0,b-0,c,b-d,c);a.lineTo(d,c);O(a,0,c,0,c-0,0,c-d);a.lineTo(0,d);O(a,0,0,0,0,d,0);P(a);b=a.o;b.B=tb;b.C=Fb;v.q(a);return b},SquareIBeam:function(a,b,c){var d=a?a.Ac:NaN;isNaN(d)&&(d=.2);a=v.s();N(a,0,0,!0);a.lineTo(1*b,0);a.lineTo(1*b,d*c);a.lineTo((.5+d/2)*b,d*c);a.lineTo((.5+d/2)*b,(1-d)*c);a.lineTo(1*b,(1-d)*c);a.lineTo(1*b,1*
c);a.lineTo(0,1*c);a.lineTo(0,(1-d)*c);a.lineTo((.5-d/2)*b,(1-d)*c);a.lineTo((.5-d/2)*b,d*c);a.lineTo(0,d*c);P(a);b=a.o;v.q(a);return b},Trapezoid:function(a,b,c){a=a?a.Ac:NaN;isNaN(a)&&(a=.2);var d=v.s();N(d,a*b,0,!0);d.lineTo((1-a)*b,0);d.lineTo(1*b,1*c);d.lineTo(0,1*c);P(d);b=d.o;b.B=new L(a,0);b.C=new L(1-a,1);v.q(d);return b},ManualLoop:"ManualOperation",ManualOperation:function(a,b,c){var d=a?a.Ac:NaN;isNaN(d)&&(d=0);a=v.s();N(a,d,0,!0);a.lineTo(0,0);a.lineTo(1*b,0);a.lineTo(.9*b,1*c);a.lineTo(.1*
b,1*c);P(a);b=a.o;b.B=new L(.1,0);b.C=new L(.9,1);v.q(a);return b},GenderMale:function(a,b,c){a=v.s();var d=.4*Xc,e=.4,f=v.L(),h=v.L(),k=v.L(),l=v.L();N(a,(.5-e)*b,.5*c,!0);O(a,(.5-e)*b,(.5-d)*c,(.5-d)*b,(.5-e)*c,.5*b,(.5-e)*c);Td(.5,.5-e,.5+d,.5-e,.5+e,.5-d,.5+e,.5,.44,k,l,h,f,f);O(a,k.x*b,k.y*c,l.x*b,l.y*c,h.x*b,h.y*c);var m=v.Mb(h.x,h.y);Td(.5,.5-e,.5+d,.5-e,.5+e,.5-d,.5+e,.5,.56,f,f,h,k,l);var n=v.Mb(h.x,h.y);a.lineTo((.1*m.x+.855)*b,.1*m.y*c);a.lineTo(.85*b,.1*m.y*c);a.lineTo(.85*b,0);a.lineTo(1*
b,0);a.lineTo(1*b,.15*c);a.lineTo((.1*n.x+.9)*b,.15*c);a.lineTo((.1*n.x+.9)*b,(.1*n.y+.05*.9)*c);a.lineTo(n.x*b,n.y*c);O(a,k.x*b,k.y*c,l.x*b,l.y*c,(.5+e)*b,.5*c);O(a,(.5+e)*b,(.5+d)*c,(.5+d)*b,(.5+e)*c,.5*b,(.5+e)*c);O(a,(.5-d)*b,(.5+e)*c,(.5-e)*b,(.5+d)*c,(.5-e)*b,.5*c);e=.35;d=.35*Xc;N(a,.5*b,(.5-e)*c,!0,!0);O(a,(.5-d)*b,(.5-e)*c,(.5-e)*b,(.5-d)*c,(.5-e)*b,.5*c);O(a,(.5-e)*b,(.5+d)*c,(.5-d)*b,(.5+e)*c,.5*b,(.5+e)*c);O(a,(.5+d)*b,(.5+e)*c,(.5+e)*b,(.5+d)*c,(.5+e)*b,.5*c);O(a,(.5+e)*b,(.5-d)*c,(.5+
d)*b,(.5-e)*c,.5*b,(.5-e)*c);N(a,(.5-e)*b,.5*c,!0);v.v(f);v.v(h);v.v(k);v.v(l);v.v(m);v.v(n);b=a.o;b.B=new L(.202,.257);b.C=new L(.692,.839);b.Md=Pi;v.q(a);return b},GenderFemale:function(a,b,c){a=v.s();var d=.375,e=0,f=-.125,h=4*(Math.SQRT2-1)/3*d;N(a,(.525+e)*b,(.5+d+f)*c,!0);O(a,(.5+h+e)*b,(.5+d+f)*c,(.5+d+e)*b,(.5+h+f)*c,(.5+d+e)*b,(.5+f)*c);O(a,(.5+d+e)*b,(.5-h+f)*c,(.5+h+e)*b,(.5-d+f)*c,(.5+e)*b,(.5-d+f)*c);O(a,(.5-h+e)*b,(.5-d+f)*c,(.5-d+e)*b,(.5-h+f)*c,(.5-d+e)*b,(.5+f)*c);O(a,(.5-d+e)*b,
(.5+h+f)*c,(.5-h+e)*b,(.5+d+f)*c,(.475+e)*b,(.5+d+f)*c);a.lineTo(.475*b,.85*c);a.lineTo(.425*b,.85*c);a.lineTo(.425*b,.9*c);a.lineTo(.475*b,.9*c);a.lineTo(.475*b,1*c);a.lineTo(.525*b,1*c);a.lineTo(.525*b,.9*c);a.lineTo(.575*b,.9*c);a.lineTo(.575*b,.85*c);a.lineTo(.525*b,.85*c);P(a);d=.325;e=0;f=-.125;h=4*(Math.SQRT2-1)/3*d;N(a,(.5+d+e)*b,(.5+f)*c,!0,!0);O(a,(.5+d+e)*b,(.5+h+f)*c,(.5+h+e)*b,(.5+d+f)*c,(.5+e)*b,(.5+d+f)*c);O(a,(.5-h+e)*b,(.5+d+f)*c,(.5-d+e)*b,(.5+h+f)*c,(.5-d+e)*b,(.5+f)*c);O(a,(.5-
d+e)*b,(.5-h+f)*c,(.5-h+e)*b,(.5-d+f)*c,(.5+e)*b,(.5-d+f)*c);O(a,(.5+h+e)*b,(.5-d+f)*c,(.5+d+e)*b,(.5-h+f)*c,(.5+d+e)*b,(.5+f)*c);N(a,(.525+e)*b,(.5+d+f)*c,!0);b=a.o;b.B=new L(.232,.136);b.C=new L(.782,.611);b.Md=Pi;v.q(a);return b},PlusLine:function(a,b,c){a=v.s();N(a,0,.5*c,!1);a.lineTo(1*b,.5*c);a.moveTo(.5*b,0);a.lineTo(.5*b,1*c);b=a.o;v.q(a);return b},XLine:function(a,b,c){a=v.s();N(a,0,1*c,!1);a.lineTo(1*b,0);a.moveTo(0,0);a.lineTo(1*b,1*c);b=a.o;v.q(a);return b},AsteriskLine:function(a,b,c){a=
v.s();var d=.2/Math.SQRT2;N(a,d*b,(1-d)*c,!1);a.lineTo((1-d)*b,d*c);a.moveTo(d*b,d*c);a.lineTo((1-d)*b,(1-d)*c);a.moveTo(0*b,.5*c);a.lineTo(1*b,.5*c);a.moveTo(.5*b,0*c);a.lineTo(.5*b,1*c);b=a.o;v.q(a);return b},CircleLine:function(a,b,c){var d=.5*Xc;a=v.s();N(a,1*b,.5*c,!1);O(a,1*b,(.5+d)*c,(.5+d)*b,1*c,.5*b,1*c);O(a,(.5-d)*b,1*c,0,(.5+d)*c,0,.5*c);O(a,0,(.5-d)*c,(.5-d)*b,0,.5*b,0);O(a,(.5+d)*b,0,1*b,(.5-d)*c,1*b,.5*c);b=a.o;b.B=new L(.146,.146);b.C=new L(.853,.853);b.Md=Pi;v.q(a);return b},Pie:function(a,
b,c){a=v.s();var d=4*(Math.SQRT2-1)/3*.5;N(a,(.5*Math.SQRT2/2+.5)*b,(.5-.5*Math.SQRT2/2)*c,!0);O(a,.7*b,0*c,.5*b,0*c,.5*b,0*c);O(a,(.5-d+0)*b,0*c,0*b,(.5-d+0)*c,0*b,.5*c);O(a,0*b,(.5+d+0)*c,(.5-d+0)*b,1*c,.5*b,1*c);O(a,(.5+d+0)*b,1*c,1*b,(.5+d+0)*c,1*b,.5*c);a.lineTo(.5*b,.5*c);P(a);b=a.o;v.q(a);return b},PiePiece:function(a,b,c){var d=Xc/Math.SQRT2*.5,e=Math.SQRT2/2,f=1-Math.SQRT2/2;a=v.s();N(a,b,c,!0);O(a,b,(1-d)*c,(e+d)*b,(f+d)*c,e*b,f*c);a.lineTo(0,c);P(a);b=a.o;v.q(a);return b},StopSign:function(a,
b,c){a=1/(Math.SQRT2+2);var d=v.s();N(d,a*b,0,!0);d.lineTo((1-a)*b,0);d.lineTo(1*b,a*c);d.lineTo(1*b,(1-a)*c);d.lineTo((1-a)*b,1*c);d.lineTo(a*b,1*c);d.lineTo(0,(1-a)*c);d.lineTo(0,a*c);P(d);b=d.o;b.B=new L(a/2,a/2);b.C=new L(1-a/2,1-a/2);v.q(d);return b},LogicImplies:function(a,b,c){var d=a?a.Ac:NaN;isNaN(d)&&(d=.2);a=v.s();N(a,(1-d)*b,0*c,!1);a.lineTo(1*b,.5*c);a.lineTo((1-d)*b,c);a.moveTo(0,.5*c);a.lineTo(b,.5*c);b=a.o;b.B=tb;b.C=new L(.8,.5);v.q(a);return b},LogicIff:function(a,b,c){var d=a?a.Ac:
NaN;isNaN(d)&&(d=.2);a=v.s();N(a,(1-d)*b,0*c,!1);a.lineTo(1*b,.5*c);a.lineTo((1-d)*b,c);a.moveTo(0,.5*c);a.lineTo(b,.5*c);a.moveTo(d*b,0);a.lineTo(0,.5*c);a.lineTo(d*b,c);b=a.o;b.B=new L(.2,0);b.C=new L(.8,.5);v.q(a);return b},LogicNot:function(a,b,c){a=v.s();N(a,0,0,!1);a.lineTo(1*b,0);a.lineTo(1*b,1*c);b=a.o;v.q(a);return b},LogicAnd:function(a,b,c){a=v.s();N(a,0,1*c,!1);a.lineTo(.5*b,0);a.lineTo(1*b,1*c);b=a.o;b.B=new L(.25,.5);b.C=new L(.75,1);v.q(a);return b},LogicOr:function(a,b,c){a=v.s();
N(a,0,0,!1);a.lineTo(.5*b,1*c);a.lineTo(1*b,0);b=a.o;b.B=new L(.219,0);b.C=new L(.78,.409);v.q(a);return b},LogicXor:function(a,b,c){a=v.s();N(a,.5*b,0,!1);a.lineTo(.5*b,1*c);a.moveTo(0,.5*c);a.lineTo(1*b,.5*c);var d=.5*Xc;O(a,1*b,(.5+d)*c,(.5+d)*b,1*c,.5*b,1*c);O(a,(.5-d)*b,1*c,0,(.5+d)*c,0,.5*c);O(a,0,(.5-d)*c,(.5-d)*b,0,.5*b,0);O(a,(.5+d)*b,0,1*b,(.5-d)*c,1*b,.5*c);b=a.o;b.Md=Pi;v.q(a);return b},LogicTruth:function(a,b,c){a=v.s();N(a,0,0,!1);a.lineTo(1*b,0);a.moveTo(.5*b,0);a.lineTo(.5*b,1*c);
b=a.o;v.q(a);return b},LogicFalsity:function(a,b,c){a=v.s();N(a,0,1*c,!1);a.lineTo(1*b,1*c);a.moveTo(.5*b,1*c);a.lineTo(.5*b,0);b=a.o;v.q(a);return b},LogicThereExists:function(a,b,c){a=v.s();N(a,0,0,!1);a.lineTo(1*b,0);a.lineTo(1*b,.5*c);a.lineTo(0,.5*c);a.moveTo(1*b,.5*c);a.lineTo(1*b,1*c);a.lineTo(0,1*c);b=a.o;v.q(a);return b},LogicForAll:function(a,b,c){a=v.s();N(a,0,0,!1);a.lineTo(.5*b,1*c);a.lineTo(1*b,0);a.moveTo(.25*b,.5*c);a.lineTo(.75*b,.5*c);b=a.o;b.B=new L(.25,0);b.C=new L(.75,.5);v.q(a);
return b},LogicIsDefinedAs:function(a,b,c){a=v.s();N(a,0,0,!1);a.lineTo(b,0);a.moveTo(0,.5*c);a.lineTo(b,.5*c);a.moveTo(0,c);a.lineTo(b,c);b=a.o;b.B=new L(.01,.01);b.C=new L(.99,.49);v.q(a);return b},LogicIntersect:function(a,b,c){var d=.5*Xc;a=v.s();N(a,0,1*c,!1);a.lineTo(0,.5*c);O(a,0,(.5-d)*c,(.5-d)*b,0,.5*b,0);O(a,(.5+d)*b,0,1*b,(.5-d)*c,1*b,.5*c);a.lineTo(1*b,1*c);b=a.o;b.B=new L(0,.5);b.C=Fb;v.q(a);return b},LogicUnion:function(a,b,c){var d=.5*Xc;a=v.s();N(a,1*b,0,!1);a.lineTo(1*b,.5*c);O(a,
1*b,(.5+d)*c,(.5+d)*b,1*c,.5*b,1*c);O(a,(.5-d)*b,1*c,0,(.5+d)*c,0,.5*c);a.lineTo(0,0);b=a.o;b.B=tb;b.C=new L(1,.5);v.q(a);return b},Arrow:function(a,b,c){var d=a?a.Ac:NaN,e=a?a.Mt:NaN;isNaN(d)&&(d=.3);isNaN(e)&&(e=.3);a=v.s();N(a,0,(.5-e/2)*c,!0);a.lineTo((1-d)*b,(.5-e/2)*c);a.lineTo((1-d)*b,0);a.lineTo(1*b,.5*c);a.lineTo((1-d)*b,1*c);a.lineTo((1-d)*b,(.5+e/2)*c);a.lineTo(0,(.5+e/2)*c);P(a);b=a.o;b.B=new L(0,.5-e/2);d=ae(0,.5+e/2,1,.5+e/2,1-d,1,1,.5,v.L());b.C=new L(d.x,d.y);v.v(d);v.q(a);return b},
ISOProcess:"Chevron",Chevron:function(a,b,c){a=v.s();N(a,0,0,!0);a.lineTo(.5*b,0);a.lineTo(1*b,.5*c);a.lineTo(.5*b,1*c);a.lineTo(0,1*c);a.lineTo(.5*b,.5*c);P(a);b=a.o;v.q(a);return b},DoubleArrow:function(a,b,c){a=v.s();N(a,0,0,!0);a.lineTo(.3*b,.214*c);a.lineTo(.3*b,0);a.lineTo(1*b,.5*c);a.lineTo(.3*b,1*c);a.lineTo(.3*b,.786*c);a.lineTo(0,1*c);P(a);N(a,.3*b,.214*c,!1);a.lineTo(.3*b,.786*c);a.Ya(!1);b=a.o;v.q(a);return b},DoubleEndArrow:function(a,b,c){a=v.s();N(a,1*b,.5*c,!0);a.lineTo(.7*b,1*c);
a.lineTo(.7*b,.7*c);a.lineTo(.3*b,.7*c);a.lineTo(.3*b,1*c);a.lineTo(0,.5*c);a.lineTo(.3*b,0);a.lineTo(.3*b,.3*c);a.lineTo(.7*b,.3*c);a.lineTo(.7*b,0);P(a);b=a.o;c=ae(0,.5,.3,0,0,.3,.3,.3,v.L());b.B=new L(c.x,c.y);c=ae(.7,1,1,.5,.7,.7,1,.7,c);b.C=new L(c.x,c.y);v.v(c);v.q(a);return b},IBeamArrow:function(a,b,c){a=v.s();N(a,1*b,.5*c,!0);a.lineTo(.7*b,1*c);a.lineTo(.7*b,.7*c);a.lineTo(.2*b,.7*c);a.lineTo(.2*b,1*c);a.lineTo(0,1*c);a.lineTo(0,0);a.lineTo(.2*b,0);a.lineTo(.2*b,.3*c);a.lineTo(.7*b,.3*c);
a.lineTo(.7*b,0);P(a);b=a.o;b.B=new L(0,.3);c=ae(.7,1,1,.5,.7,.7,1,.7,v.L());b.C=new L(c.x,c.y);v.v(c);v.q(a);return b},Pointer:function(a,b,c){a=v.s();N(a,1*b,.5*c,!0);a.lineTo(0,1*c);a.lineTo(.2*b,.5*c);a.lineTo(0,0);P(a);b=a.o;b.B=new L(.2,.35);c=ae(.2,.65,1,.65,0,1,1,.5,v.L());b.C=new L(c.x,c.y);v.v(c);v.q(a);return b},RoundedPointer:function(a,b,c){a=v.s();N(a,1*b,.5*c,!0);a.lineTo(0,1*c);O(a,.5*b,.75*c,.5*b,.25*c,0,0);P(a);b=a.o;b.B=new L(.4,.35);c=ae(.2,.65,1,.65,0,1,1,.5,v.L());b.C=new L(c.x,
c.y);v.v(c);v.q(a);return b},SplitEndArrow:function(a,b,c){a=v.s();N(a,1*b,.5*c,!0);a.lineTo(.7*b,1*c);a.lineTo(.7*b,.7*c);a.lineTo(0,.7*c);a.lineTo(.2*b,.5*c);a.lineTo(0,.3*c);a.lineTo(.7*b,.3*c);a.lineTo(.7*b,0);P(a);b=a.o;b.B=new L(.2,.3);c=ae(.7,1,1,.5,.7,.7,1,.7,v.L());b.C=new L(c.x,c.y);v.v(c);v.q(a);return b},MessageToUser:"SquareArrow",SquareArrow:function(a,b,c){a=v.s();N(a,1*b,.5*c,!0);a.lineTo(.7*b,1*c);a.lineTo(0,1*c);a.lineTo(0,0);a.lineTo(.7*b,0);P(a);b=a.o;b.B=tb;b.C=new L(.7,1);v.q(a);
return b},Cone1:function(a,b,c){var d=.5*Xc,e=.1*Xc;a=v.s();N(a,0,.9*c,!0);a.lineTo(.5*b,0);a.lineTo(1*b,.9*c);O(a,1*b,(.9+e)*c,(.5+d)*b,1*c,.5*b,1*c);O(a,(.5-d)*b,1*c,0,(.9+e)*c,0,.9*c);P(a);b=a.o;b.B=new L(.25,.5);b.C=new L(.75,.97);v.q(a);return b},Cone2:function(a,b,c){a=v.s();N(a,0,.9*c,!0);O(a,(1-.85/.9)*b,1*c,.85/.9*b,1*c,1*b,.9*c);a.lineTo(.5*b,0);a.lineTo(0,.9*c);P(a);N(a,0,.9*c,!1);O(a,(1-.85/.9)*b,.8*c,.85/.9*b,.8*c,1*b,.9*c);a.Ya(!1);b=a.o;b.B=new L(.25,.5);b.C=new L(.75,.82);v.q(a);return b},
Cube1:function(a,b,c){a=v.s();N(a,.5*b,1*c,!0);a.lineTo(1*b,.85*c);a.lineTo(1*b,.15*c);a.lineTo(.5*b,0*c);a.lineTo(0*b,.15*c);a.lineTo(0*b,.85*c);P(a);N(a,.5*b,1*c,!1);a.lineTo(.5*b,.3*c);a.lineTo(0,.15*c);a.moveTo(.5*b,.3*c);a.lineTo(1*b,.15*c);a.Ya(!1);b=a.o;b.B=new L(0,.3);b.C=new L(.5,.85);v.q(a);return b},Cube2:function(a,b,c){a=v.s();N(a,0,.3*c,!0);a.lineTo(0*b,1*c);a.lineTo(.7*b,c);a.lineTo(1*b,.7*c);a.lineTo(1*b,0*c);a.lineTo(.3*b,0*c);P(a);N(a,0,.3*c,!1);a.lineTo(.7*b,.3*c);a.lineTo(1*b,
0*c);a.moveTo(.7*b,.3*c);a.lineTo(.7*b,1*c);a.Ya(!1);b=a.o;b.B=new L(0,.3);b.C=new L(.7,1);v.q(a);return b},MagneticData:"Cylinder1",Cylinder1:function(a,b,c){var d=.5*Xc,e=.1*Xc;a=v.s();N(a,0,.1*c,!0);O(a,0,(.1-e)*c,(.5-d)*b,0,.5*b,0);O(a,(.5+d)*b,0,1*b,(.1-e)*c,1*b,.1*c);a.lineTo(b,.9*c);O(a,1*b,(.9+e)*c,(.5+d)*b,1*c,.5*b,1*c);O(a,(.5-d)*b,1*c,0,(.9+e)*c,0,.9*c);a.lineTo(0,.1*c);N(a,0,.1*c,!1);O(a,0,(.1+e)*c,(.5-d)*b,.2*c,.5*b,.2*c);O(a,(.5+d)*b,.2*c,1*b,(.1+e)*c,1*b,.1*c);a.Ya(!1);b=a.o;b.B=new L(0,
.2);b.C=new L(1,.9);v.q(a);return b},Cylinder2:function(a,b,c){var d=.5*Xc,e=.1*Xc;a=v.s();N(a,0,.9*c,!0);a.lineTo(0,.1*c);O(a,0,(.1-e)*c,(.5-d)*b,0,.5*b,0);O(a,(.5+d)*b,0,1*b,(.1-e)*c,1*b,.1*c);a.lineTo(1*b,.9*c);O(a,1*b,(.9+e)*c,(.5+d)*b,1*c,.5*b,1*c);O(a,(.5-d)*b,1*c,0,(.9+e)*c,0,.9*c);N(a,0,.9*c,!1);O(a,0,(.9-e)*c,(.5-d)*b,.8*c,.5*b,.8*c);O(a,(.5+d)*b,.8*c,1*b,(.9-e)*c,1*b,.9*c);a.Ya(!1);b=a.o;b.B=new L(0,.1);b.C=new L(1,.8);v.q(a);return b},Cylinder3:function(a,b,c){var d=.1*Xc,e=.5*Xc;a=v.s();
N(a,.1*b,0,!0);a.lineTo(.9*b,0);O(a,(.9+d)*b,0,1*b,(.5-e)*c,1*b,.5*c);O(a,1*b,(.5+e)*c,(.9+d)*b,1*c,.9*b,1*c);a.lineTo(.1*b,1*c);O(a,(.1-d)*b,1*c,0,(.5+e)*c,0,.5*c);O(a,0,(.5-e)*c,(.1-d)*b,0,.1*b,0);N(a,.1*b,0,!1);O(a,(.1+d)*b,0,.2*b,(.5-e)*c,.2*b,.5*c);O(a,.2*b,(.5+e)*c,(.1+d)*b,1*c,.1*b,1*c);a.Ya(!1);b=a.o;b.B=new L(.2,0);b.C=new L(.9,1);v.q(a);return b},DirectData:"Cylinder4",Cylinder4:function(a,b,c){var d=.1*Xc,e=.5*Xc;a=v.s();N(a,.9*b,0,!0);O(a,(.9+d)*b,0,1*b,(.5-e)*c,1*b,.5*c);O(a,1*b,(.5+
e)*c,(.9+d)*b,1*c,.9*b,1*c);a.lineTo(.1*b,1*c);O(a,(.1-d)*b,1*c,0,(.5+e)*c,0,.5*c);O(a,0,(.5-e)*c,(.1-d)*b,0,.1*b,0);a.lineTo(.9*b,0);N(a,.9*b,0,!1);O(a,(.9-d)*b,0,.8*b,(.5-e)*c,.8*b,.5*c);O(a,.8*b,(.5+e)*c,(.9-d)*b,1*c,.9*b,1*c);a.Ya(!1);b=a.o;b.B=new L(.1,0);b.C=new L(.8,1);v.q(a);return b},Prism1:function(a,b,c){a=v.s();N(a,.25*b,.25*c,!0);a.lineTo(.75*b,0);a.lineTo(b,.5*c);a.lineTo(.5*b,c);a.lineTo(0,c);P(a);N(a,.25*b,.25*c,!1);a.lineTo(.5*b,c);a.Ya(!1);b=a.o;b.B=new L(.408,.172);b.C=new L(.833,
.662);v.q(a);return b},Prism2:function(a,b,c){a=v.s();N(a,0,.25*c,!0);a.lineTo(.75*b,0);a.lineTo(1*b,.25*c);a.lineTo(.75*b,.75*c);a.lineTo(0,1*c);P(a);N(a,0,c,!1);a.lineTo(.25*b,.5*c);a.lineTo(b,.25*c);a.moveTo(0,.25*c);a.lineTo(.25*b,.5*c);a.Ya(!1);b=a.o;b.B=new L(.25,.5);b.C=new L(.75,.75);v.q(a);return b},Pyramid1:function(a,b,c){a=v.s();N(a,.5*b,0,!0);a.lineTo(b,.75*c);a.lineTo(.5*b,1*c);a.lineTo(0,.75*c);P(a);N(a,.5*b,0,!1);a.lineTo(.5*b,1*c);a.Ya(!1);b=a.o;b.B=new L(.25,.367);b.C=new L(.75,
.875);v.q(a);return b},Pyramid2:function(a,b,c){a=v.s();N(a,.5*b,0,!0);a.lineTo(b,.85*c);a.lineTo(.5*b,1*c);a.lineTo(0,.85*c);P(a);N(a,.5*b,0,!1);a.lineTo(.5*b,.7*c);a.lineTo(0,.85*c);a.moveTo(.5*b,.7*c);a.lineTo(1*b,.85*c);a.Ya(!1);b=a.o;b.B=new L(.25,.367);b.C=new L(.75,.875);v.q(a);return b},Actor:function(a,b,c){var d=.2*Xc,e=.1*Xc,f=.5,h=.1;a=v.s();N(a,f*b,(h+.1)*c,!0);O(a,(f-d)*b,(h+.1)*c,(f-.2)*b,(h+e)*c,(f-.2)*b,h*c);O(a,(f-.2)*b,(h-e)*c,(f-d)*b,(h-.1)*c,f*b,(h-.1)*c);O(a,(f+d)*b,(h-.1)*c,
(f+.2)*b,(h-e)*c,(f+.2)*b,h*c);O(a,(f+.2)*b,(h+e)*c,(f+d)*b,(h+.1)*c,f*b,(h+.1)*c);d=.05;e=Xc*d;N(a,.5*b,.2*c,!0);a.lineTo(.95*b,.2*c);f=.95;h=.25;O(a,(f+e)*b,(h-d)*c,(f+d)*b,(h-e)*c,(f+d)*b,h*c);a.lineTo(1*b,.6*c);a.lineTo(.85*b,.6*c);a.lineTo(.85*b,.35*c);d=.025;e=Xc*d;f=.825;h=.35;O(a,(f+d)*b,(h-e)*c,(f+e)*b,(h-d)*c,f*b,(h-d)*c);O(a,(f-e)*b,(h-d)*c,(f-d)*b,(h-e)*c,(f-d)*b,h*c);a.lineTo(.8*b,1*c);a.lineTo(.55*b,1*c);a.lineTo(.55*b,.7*c);d=.05;e=Xc*d;f=.5;h=.7;O(a,(f+d)*b,(h-e)*c,(f+e)*b,(h-d)*c,
f*b,(h-d)*c);O(a,(f-e)*b,(h-d)*c,(f-d)*b,(h-e)*c,(f-d)*b,h*c);a.lineTo(.45*b,1*c);a.lineTo(.2*b,1*c);a.lineTo(.2*b,.35*c);d=.025;e=Xc*d;f=.175;h=.35;O(a,(f+d)*b,(h-e)*c,(f+e)*b,(h-d)*c,f*b,(h-d)*c);O(a,(f-e)*b,(h-d)*c,(f-d)*b,(h-e)*c,(f-d)*b,h*c);a.lineTo(.15*b,.6*c);a.lineTo(0*b,.6*c);a.lineTo(0*b,.25*c);d=.05;e=Xc*d;f=.05;h=.25;O(a,(f-d)*b,(h-e)*c,(f-e)*b,(h-d)*c,f*b,(h-d)*c);a.lineTo(.5*b,.2*c);b=a.o;b.B=new L(.2,.2);b.C=new L(.8,.65);v.q(a);return b},Card:function(a,b,c){a=v.s();N(a,1*b,0*c,!0);
a.lineTo(1*b,1*c);a.lineTo(0*b,1*c);a.lineTo(0*b,.2*c);a.lineTo(.2*b,0*c);P(a);b=a.o;b.B=new L(0,.2);b.C=Fb;v.q(a);return b},Collate:function(a,b,c){a=v.s();N(a,.5*b,.5*c,!0);a.lineTo(0,0);a.lineTo(1*b,0);a.lineTo(.5*b,.5*c);N(a,.5*b,.5*c,!0);a.lineTo(1*b,1*c);a.lineTo(0,1*c);a.lineTo(.5*b,.5*c);b=a.o;b.B=new L(.25,0);b.C=new L(.75,.25);v.q(a);return b},CreateRequest:function(a,b,c){a=a?a.Ac:NaN;isNaN(a)&&(a=.1);var d=v.s();N(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(0,1*c);P(d);N(d,0,
a*c,!1);d.lineTo(1*b,a*c);d.moveTo(0,(1-a)*c);d.lineTo(1*b,(1-a)*c);d.Ya(!1);b=d.o;b.B=new L(0,a);b.C=new L(1,1-a);v.q(d);return b},Database:function(a,b,c){a=v.s();var d=.5*Xc,e=.1*Xc;N(a,1*b,.1*c,!0);a.lineTo(1*b,.9*c);O(a,1*b,(.9+e)*c,(.5+d)*b,1*c,.5*b,1*c);O(a,(.5-d)*b,1*c,0,(.9+e)*c,0,.9*c);a.lineTo(0,.1*c);O(a,0,(.1-e)*c,(.5-d)*b,0,.5*b,0);O(a,(.5+d)*b,0,1*b,(.1-e)*c,1*b,.1*c);N(a,1*b,.1*c,!1);O(a,1*b,(.1+e)*c,(.5+d)*b,.2*c,.5*b,.2*c);O(a,(.5-d)*b,.2*c,0,(.1+e)*c,0,.1*c);a.moveTo(1*b,.2*c);
O(a,1*b,(.2+e)*c,(.5+d)*b,.3*c,.5*b,.3*c);O(a,(.5-d)*b,.3*c,0,(.2+e)*c,0,.2*c);a.moveTo(1*b,.3*c);O(a,1*b,(.3+e)*c,(.5+d)*b,.4*c,.5*b,.4*c);O(a,(.5-d)*b,.4*c,0,(.3+e)*c,0,.3*c);a.Ya(!1);b=a.o;b.B=new L(0,.4);b.C=new L(1,.9);v.q(a);return b},StoredData:"DataStorage",DataStorage:function(a,b,c){a=v.s();N(a,0,0,!0);a.lineTo(.75*b,0);O(a,1*b,0,1*b,1*c,.75*b,1*c);a.lineTo(0,1*c);O(a,.25*b,.9*c,.25*b,.1*c,0,0);P(a);b=a.o;b.B=new L(.226,0);b.C=new L(.81,1);v.q(a);return b},DiskStorage:function(a,b,c){a=
v.s();var d=.5*Xc,e=.1*Xc;N(a,1*b,.1*c,!0);a.lineTo(1*b,.9*c);O(a,1*b,(.9+e)*c,(.5+d)*b,1*c,.5*b,1*c);O(a,(.5-d)*b,1*c,0,(.9+e)*c,0,.9*c);a.lineTo(0,.1*c);O(a,0,(.1-e)*c,(.5-d)*b,0,.5*b,0);O(a,(.5+d)*b,0,1*b,(.1-e)*c,1*b,.1*c);N(a,1*b,.1*c,!1);O(a,1*b,(.1+e)*c,(.5+d)*b,.2*c,.5*b,.2*c);O(a,(.5-d)*b,.2*c,0,(.1+e)*c,0,.1*c);a.moveTo(1*b,.2*c);O(a,1*b,(.2+e)*c,(.5+d)*b,.3*c,.5*b,.3*c);O(a,(.5-d)*b,.3*c,0,(.2+e)*c,0,.2*c);a.Ya(!1);b=a.o;b.B=new L(0,.3);b.C=new L(1,.9);v.q(a);return b},Display:function(a,
b,c){a=v.s();N(a,.25*b,0,!0);a.lineTo(.75*b,0);O(a,1*b,0,1*b,1*c,.75*b,1*c);a.lineTo(.25*b,1*c);a.lineTo(0,.5*c);P(a);b=a.o;b.B=new L(.25,0);b.C=new L(.75,1);v.q(a);return b},DividedEvent:function(a,b,c){a=a?a.Ac:NaN;isNaN(a)?a=.2:.15>a&&(a=.15);var d=v.s(),e=.2*Xc;N(d,0,.2*c,!0);O(d,0,(.2-e)*c,(.2-e)*b,0,.2*b,0);d.lineTo(.8*b,0);O(d,(.8+e)*b,0,1*b,(.2-e)*c,1*b,.2*c);d.lineTo(1*b,.8*c);O(d,1*b,(.8+e)*c,(.8+e)*b,1*c,.8*b,1*c);d.lineTo(.2*b,1*c);O(d,(.2-e)*b,1*c,0,(.8+e)*c,0,.8*c);d.lineTo(0,.2*c);
N(d,0,a*c,!1);d.lineTo(1*b,a*c);d.Ya(!1);b=d.o;b.B=new L(0,a);b.C=new L(1,1-a);v.q(d);return b},DividedProcess:function(a,b,c){a=a?a.Ac:NaN;if(isNaN(a)||.1>a)a=.1;var d=v.s();N(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(0,1*c);P(d);N(d,0,a*c,!1);d.lineTo(1*b,a*c);d.Ya(!1);b=d.o;b.B=new L(0,a);b.C=Fb;v.q(d);return b},Document:function(a,b,c){c/=.8;a=v.s();N(a,0,.7*c,!0);a.lineTo(0,0);a.lineTo(1*b,0);a.lineTo(1*b,.7*c);O(a,.5*b,.4*c,.5*b,1*c,0,.7*c);P(a);b=a.o;b.B=tb;b.C=new L(1,.6);v.q(a);
return b},ExternalOrganization:function(a,b,c){a=a?a.Ac:NaN;if(isNaN(a)||.2>a)a=.2;var d=v.s();N(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(0,1*c);P(d);N(d,a*b,0,!1);d.lineTo(0,a*c);d.moveTo(1*b,a*c);d.lineTo((1-a)*b,0);d.moveTo(0,(1-a)*c);d.lineTo(a*b,1*c);d.moveTo((1-a)*b,1*c);d.lineTo(1*b,(1-a)*c);d.Ya(!1);b=d.o;b.B=new L(a/2,a/2);b.C=new L(1-a/2,1-a/2);v.q(d);return b},ExternalProcess:function(a,b,c){a=v.s();N(a,.5*b,0,!0);a.lineTo(1*b,.5*c);a.lineTo(.5*b,1*c);a.lineTo(0,.5*c);P(a);
N(a,.1*b,.4*c,!1);a.lineTo(.1*b,.6*c);a.moveTo(.9*b,.6*c);a.lineTo(.9*b,.4*c);a.moveTo(.6*b,.1*c);a.lineTo(.4*b,.1*c);a.moveTo(.4*b,.9*c);a.lineTo(.6*b,.9*c);a.Ya(!1);b=a.o;b.B=new L(.25,.25);b.C=new L(.75,.75);v.q(a);return b},File:function(a,b,c){a=v.s();N(a,0,0,!0);a.lineTo(.75*b,0);a.lineTo(1*b,.25*c);a.lineTo(1*b,1*c);a.lineTo(0,1*c);P(a);N(a,.75*b,0,!1);a.lineTo(.75*b,.25*c);a.lineTo(1*b,.25*c);a.Ya(!1);b=a.o;b.B=new L(0,.25);b.C=Fb;v.q(a);return b},Interrupt:function(a,b,c){a=v.s();N(a,1*b,
.5*c,!0);a.lineTo(0,1*c);a.lineTo(0,0);a.lineTo(1*b,.5*c);N(a,1*b,.5*c,!1);a.lineTo(1*b,1*c);N(a,1*b,.5*c,!1);a.lineTo(1*b,0);b=a.o;b.B=new L(0,.25);b.C=new L(.5,.75);v.q(a);return b},InternalStorage:function(a,b,c){var d=a?a.Ac:NaN;a=a?a.Mt:NaN;isNaN(d)&&(d=.1);isNaN(a)&&(a=.1);var e=v.s();N(e,0,0,!0);e.lineTo(1*b,0);e.lineTo(1*b,1*c);e.lineTo(0,1*c);P(e);N(e,d*b,0,!1);e.lineTo(d*b,1*c);e.moveTo(0,a*c);e.lineTo(1*b,a*c);e.Ya(!1);b=e.o;b.B=new L(d,a);b.C=Fb;v.q(e);return b},Junction:function(a,b,
c){a=v.s();var d=1/Math.SQRT2,e=(1-1/Math.SQRT2)/2,f=.5*Xc;N(a,1*b,.5*c,!0);O(a,1*b,(.5+f)*c,(.5+f)*b,1*c,.5*b,1*c);O(a,(.5-f)*b,1*c,0,(.5+f)*c,0,.5*c);O(a,0,(.5-f)*c,(.5-f)*b,0,.5*b,0);O(a,(.5+f)*b,0,1*b,(.5-f)*c,1*b,.5*c);N(a,(e+d)*b,(e+d)*c,!1);a.lineTo(e*b,e*c);a.moveTo(e*b,(e+d)*c);a.lineTo((e+d)*b,e*c);a.Ya(!1);b=a.o;b.Md=Pi;v.q(a);return b},LinedDocument:function(a,b,c){c/=.8;a=v.s();N(a,0,.7*c,!0);a.lineTo(0,0);a.lineTo(1*b,0);a.lineTo(1*b,.7*c);O(a,.5*b,.4*c,.5*b,1*c,0,.7*c);P(a);N(a,.1*
b,0,!1);a.lineTo(.1*b,.75*c);a.Ya(!1);b=a.o;b.B=new L(.1,0);b.C=new L(1,.6);v.q(a);return b},LoopLimit:function(a,b,c){a=v.s();N(a,0,1*c,!0);a.lineTo(0,.25*c);a.lineTo(.25*b,0);a.lineTo(.75*b,0);a.lineTo(1*b,.25*c);a.lineTo(1*b,1*c);P(a);b=a.o;b.B=new L(0,.25);b.C=Fb;v.q(a);return b},SequentialData:"MagneticTape",MagneticTape:function(a,b,c){a=v.s();var d=.5*Xc;N(a,.5*b,1*c,!0);O(a,(.5-d)*b,1*c,0,(.5+d)*c,0,.5*c);O(a,0,(.5-d)*c,(.5-d)*b,0,.5*b,0);O(a,(.5+d)*b,0,1*b,(.5-d)*c,1*b,.5*c);O(a,1*b,(.5+
d)*c,(.5+d)*b,.9*c,.6*b,.9*c);a.lineTo(1*b,.9*c);a.lineTo(1*b,1*c);a.lineTo(.5*b,1*c);b=a.o;b.B=new L(.15,.15);b.C=new L(.85,.8);v.q(a);return b},ManualInput:function(a,b,c){a=v.s();N(a,1*b,0,!0);a.lineTo(1*b,1*c);a.lineTo(0,1*c);a.lineTo(0,.25*c);P(a);b=a.o;b.B=new L(0,.25);b.C=Fb;v.q(a);return b},MessageFromUser:function(a,b,c){a=a?a.Ac:NaN;isNaN(a)&&(a=.7);var d=v.s();N(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(a*b,.5*c);d.lineTo(1*b,1*c);d.lineTo(0,1*c);P(d);b=d.o;b.B=tb;b.C=new L(a,1);v.q(d);return b},
MicroformProcessing:function(a,b,c){a=a?a.Ac:NaN;isNaN(a)&&(a=.25);var d=v.s();N(d,0,0,!0);d.lineTo(.5*b,a*c);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(.5*b,(1-a)*c);d.lineTo(0,1*c);P(d);b=d.o;b.B=new L(0,a);b.C=new L(1,1-a);v.q(d);return b},MicroformRecording:function(a,b,c){a=v.s();N(a,0,0,!0);a.lineTo(.75*b,.25*c);a.lineTo(1*b,.15*c);a.lineTo(1*b,.85*c);a.lineTo(.75*b,.75*c);a.lineTo(0,1*c);P(a);b=a.o;b.B=new L(0,.25);b.C=new L(1,.75);v.q(a);return b},MultiDocument:function(a,b,c){c/=.8;a=v.s();
N(a,b,0,!0);a.lineTo(b,.5*c);O(a,.96*b,.47*c,.93*b,.45*c,.9*b,.44*c);a.lineTo(.9*b,.6*c);O(a,.86*b,.57*c,.83*b,.55*c,.8*b,.54*c);a.lineTo(.8*b,.7*c);O(a,.4*b,.4*c,.4*b,1*c,0,.7*c);a.lineTo(0,.2*c);a.lineTo(.1*b,.2*c);a.lineTo(.1*b,.1*c);a.lineTo(.2*b,.1*c);a.lineTo(.2*b,0);P(a);N(a,.1*b,.2*c,!1);a.lineTo(.8*b,.2*c);a.lineTo(.8*b,.54*c);a.moveTo(.2*b,.1*c);a.lineTo(.9*b,.1*c);a.lineTo(.9*b,.44*c);a.Ya(!1);b=a.o;b.B=new L(0,.25);b.C=new L(.8,.77);v.q(a);return b},MultiProcess:function(a,b,c){a=v.s();
N(a,.1*b,.1*c,!0);a.lineTo(.2*b,.1*c);a.lineTo(.2*b,0);a.lineTo(1*b,0);a.lineTo(1*b,.8*c);a.lineTo(.9*b,.8*c);a.lineTo(.9*b,.9*c);a.lineTo(.8*b,.9*c);a.lineTo(.8*b,1*c);a.lineTo(0,1*c);a.lineTo(0,.2*c);a.lineTo(.1*b,.2*c);P(a);N(a,.2*b,.1*c,!1);a.lineTo(.9*b,.1*c);a.lineTo(.9*b,.8*c);a.moveTo(.1*b,.2*c);a.lineTo(.8*b,.2*c);a.lineTo(.8*b,.9*c);a.Ya(!1);b=a.o;b.B=new L(0,.2);b.C=new L(.8,1);v.q(a);return b},OfflineStorage:function(a,b,c){a=a?a.Ac:NaN;isNaN(a)&&(a=.1);var d=1-a,e=v.s();N(e,0,0,!0);e.lineTo(1*
b,0);e.lineTo(.5*b,1*c);P(e);N(e,.5*a*b,a*c,!1);e.lineTo((1-.5*a)*b,a*c);e.Ya(!1);b=e.o;b.B=new L(d/4+.5*a,a);b.C=new L(3*d/4+.5*a,a+.5*d);v.q(e);return b},OffPageConnector:function(a,b,c){a=v.s();N(a,0,0,!0);a.lineTo(.75*b,0);a.lineTo(1*b,.5*c);a.lineTo(.75*b,1*c);a.lineTo(0,1*c);P(a);b=a.o;b.B=tb;b.C=new L(.75,1);v.q(a);return b},Or:function(a,b,c){a=v.s();var d=.5*Xc;N(a,1*b,.5*c,!0);O(a,1*b,(.5+d)*c,(.5+d)*b,1*c,.5*b,1*c);O(a,(.5-d)*b,1*c,0,(.5+d)*c,0,.5*c);O(a,0,(.5-d)*c,(.5-d)*b,0,.5*b,0);O(a,
(.5+d)*b,0,1*b,(.5-d)*c,1*b,.5*c);N(a,1*b,.5*c,!1);a.lineTo(0,.5*c);a.moveTo(.5*b,1*c);a.lineTo(.5*b,0);a.Ya(!1);b=a.o;b.Md=Pi;v.q(a);return b},PaperTape:function(a,b,c){c/=.8;a=v.s();N(a,0,.7*c,!0);a.lineTo(0,.3*c);O(a,.5*b,.6*c,.5*b,0,1*b,.3*c);a.lineTo(1*b,.7*c);O(a,.5*b,.4*c,.5*b,1*c,0,.7*c);P(a);b=a.o;b.B=new L(0,.49);b.C=new L(1,.75);v.q(a);return b},PrimitiveFromCall:function(a,b,c){var d=a?a.Ac:NaN;a=a?a.Mt:NaN;isNaN(d)&&(d=.1);isNaN(a)&&(a=.3);var e=v.s();N(e,0,0,!0);e.lineTo(1*b,0);e.lineTo((1-
a)*b,.5*c);e.lineTo(1*b,1*c);e.lineTo(0,1*c);P(e);b=e.o;b.B=new L(d,0);b.C=new L(1-a,1);v.q(e);return b},PrimitiveToCall:function(a,b,c){var d=a?a.Ac:NaN;a=a?a.Mt:NaN;isNaN(d)&&(d=.1);isNaN(a)&&(a=.3);var e=v.s();N(e,0,0,!0);e.lineTo((1-a)*b,0);e.lineTo(1*b,.5*c);e.lineTo((1-a)*b,1*c);e.lineTo(0,1*c);P(e);b=e.o;b.B=new L(d,0);b.C=new L(1-a,1);v.q(e);return b},Subroutine:"Procedure",Procedure:function(a,b,c){a=a?a.Ac:NaN;isNaN(a)&&(a=.1);var d=v.s();N(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(0,
1*c);P(d);N(d,(1-a)*b,0,!1);d.lineTo((1-a)*b,1*c);d.moveTo(a*b,0);d.lineTo(a*b,1*c);d.Ya(!1);b=d.o;b.B=new L(a,0);b.C=new L(1-a,1);v.q(d);return b},Process:function(a,b,c){a=a?a.Ac:NaN;isNaN(a)&&(a=.1);var d=v.s();N(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(0,1*c);P(d);N(d,a*b,0,!1);d.lineTo(a*b,1*c);d.Ya(!1);b=d.o;b.B=new L(a,0);b.C=Fb;v.q(d);return b},Sort:function(a,b,c){a=v.s();N(a,.5*b,0,!0);a.lineTo(1*b,.5*c);a.lineTo(.5*b,1*c);a.lineTo(0,.5*c);P(a);N(a,0,.5*c,!1);a.lineTo(1*b,.5*
c);a.Ya(!1);b=a.o;b.B=new L(.25,.25);b.C=new L(.75,.5);v.q(a);return b},Start:function(a,b,c){a=v.s();N(a,.25*b,0,!0);N(a,.25*b,0,!0);a.arcTo(270,180,.75*b,.5*c,.25*b,.5*c);a.arcTo(90,180,.25*b,.5*c,.25*b,.5*c);N(a,.25*b,0,!1);a.lineTo(.25*b,1*c);a.moveTo(.75*b,0);a.lineTo(.75*b,1*c);a.Ya(!1);b=a.o;b.B=new L(.25,0);b.C=new L(.75,1);v.q(a);return b},Terminator:function(a,b,c){a=v.s();N(a,.25*b,0,!0);a.arcTo(270,180,.75*b,.5*c,.25*b,.5*c);a.arcTo(90,180,.25*b,.5*c,.25*b,.5*c);b=a.o;b.B=new L(.23,0);
b.C=new L(.77,1);v.q(a);return b},TransmittalTape:function(a,b,c){a=a?a.Ac:NaN;isNaN(a)&&(a=.1);var d=v.s();N(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(.75*b,(1-a)*c);d.lineTo(0,(1-a)*c);P(d);b=d.o;b.B=tb;b.C=new L(1,1-a);v.q(d);return b},AndGate:function(a,b,c){a=v.s();var d=.5*Xc;N(a,0,0,!0);a.lineTo(.5*b,0);O(a,(.5+d)*b,0,1*b,(.5-d)*c,1*b,.5*c);O(a,1*b,(.5+d)*c,(.5+d)*b,1*c,.5*b,1*c);a.lineTo(0,1*c);P(a);b=a.o;b.B=tb;b.C=new L(.55,1);v.q(a);return b},Buffer:function(a,b,c){a=v.s();N(a,
0,0,!0);a.lineTo(1*b,.5*c);a.lineTo(0,1*c);P(a);b=a.o;b.B=new L(0,.25);b.C=new L(.5,.75);v.q(a);return b},Clock:function(a,b,c){a=v.s();var d=.5*Xc;N(a,1*b,.5*c,!0);O(a,1*b,(.5+d)*c,(.5+d)*b,1*c,.5*b,1*c);O(a,(.5-d)*b,1*c,0,(.5+d)*c,0,.5*c);O(a,0,(.5-d)*c,(.5-d)*b,0,.5*b,0);O(a,(.5+d)*b,0,1*b,(.5-d)*c,1*b,.5*c);N(a,1*b,.5*c,!1);a.lineTo(1*b,.5*c);N(a,.8*b,.75*c,!1);a.lineTo(.8*b,.25*c);a.lineTo(.6*b,.25*c);a.lineTo(.6*b,.75*c);a.lineTo(.4*b,.75*c);a.lineTo(.4*b,.25*c);a.lineTo(.2*b,.25*c);a.lineTo(.2*
b,.75*c);a.Ya(!1);b=a.o;b.Md=Pi;v.q(a);return b},Ground:function(a,b,c){a=v.s();N(a,.5*b,0,!1);a.lineTo(.5*b,.4*c);a.moveTo(.2*b,.6*c);a.lineTo(.8*b,.6*c);a.moveTo(.3*b,.8*c);a.lineTo(.7*b,.8*c);a.moveTo(.4*b,1*c);a.lineTo(.6*b,1*c);b=a.o;v.q(a);return b},Inverter:function(a,b,c){a=v.s();var d=.1*Xc;N(a,.8*b,.5*c,!0);a.lineTo(0,1*c);a.lineTo(0,0);a.lineTo(.8*b,.5*c);N(a,1*b,.5*c,!0);O(a,1*b,(.5+d)*c,(.9+d)*b,.6*c,.9*b,.6*c);O(a,(.9-d)*b,.6*c,.8*b,(.5+d)*c,.8*b,.5*c);O(a,.8*b,(.5-d)*c,(.9-d)*b,.4*
c,.9*b,.4*c);O(a,(.9+d)*b,.4*c,1*b,(.5-d)*c,1*b,.5*c);b=a.o;b.B=new L(0,.25);b.C=new L(.4,.75);v.q(a);return b},NandGate:function(a,b,c){a=v.s();var d=.5*Xc,e=.4*Xc,f=.1*Xc;N(a,.8*b,.5*c,!0);O(a,.8*b,(.5+e)*c,(.4+d)*b,1*c,.4*b,1*c);a.lineTo(0,1*c);a.lineTo(0,0);a.lineTo(.4*b,0);O(a,(.4+d)*b,0,.8*b,(.5-e)*c,.8*b,.5*c);N(a,1*b,.5*c,!0);O(a,1*b,(.5+f)*c,(.9+f)*b,.6*c,.9*b,.6*c);O(a,(.9-f)*b,.6*c,.8*b,(.5+f)*c,.8*b,.5*c);O(a,.8*b,(.5-f)*c,(.9-f)*b,.4*c,.9*b,.4*c);O(a,(.9+f)*b,.4*c,1*b,(.5-f)*c,1*b,.5*
c);b=a.o;b.B=new L(0,.05);b.C=new L(.55,.95);v.q(a);return b},NorGate:function(a,b,c){a=v.s();var d=.5,e=Xc*d,f=0,h=.5;N(a,.8*b,.5*c,!0);O(a,.7*b,(h+e)*c,(f+e)*b,(h+d)*c,0,1*c);O(a,.25*b,.75*c,.25*b,.25*c,0,0);O(a,(f+e)*b,(h-d)*c,.7*b,(h-e)*c,.8*b,.5*c);d=.1;e=.1*Xc;f=.9;h=.5;N(a,(f-d)*b,h*c,!0);O(a,(f-d)*b,(h-e)*c,(f-e)*b,(h-d)*c,f*b,(h-d)*c);O(a,(f+e)*b,(h-d)*c,(f+d)*b,(h-e)*c,(f+d)*b,h*c);O(a,(f+d)*b,(h+e)*c,(f+e)*b,(h+d)*c,f*b,(h+d)*c);O(a,(f-e)*b,(h+d)*c,(f-d)*b,(h+e)*c,(f-d)*b,h*c);b=a.o;b.B=
new L(.2,.25);b.C=new L(.6,.75);v.q(a);return b},OrGate:function(a,b,c){a=v.s();var d=.5*Xc;N(a,0,0,!0);O(a,(0+d+d)*b,0*c,.8*b,(.5-d)*c,1*b,.5*c);O(a,.8*b,(.5+d)*c,(0+d+d)*b,1*c,0,1*c);O(a,.25*b,.75*c,.25*b,.25*c,0,0);P(a);b=a.o;b.B=new L(.2,.25);b.C=new L(.75,.75);v.q(a);return b},XnorGate:function(a,b,c){a=v.s();var d=.5,e=Xc*d,f=.2,h=.5;N(a,.1*b,0,!1);O(a,.35*b,.25*c,.35*b,.75*c,.1*b,1*c);N(a,.8*b,.5*c,!0);O(a,.7*b,(h+e)*c,(f+e)*b,(h+d)*c,.2*b,1*c);O(a,.45*b,.75*c,.45*b,.25*c,.2*b,0);O(a,(f+e)*
b,(h-d)*c,.7*b,(h-e)*c,.8*b,.5*c);d=.1;e=.1*Xc;f=.9;h=.5;N(a,(f-d)*b,h*c,!0);O(a,(f-d)*b,(h-e)*c,(f-e)*b,(h-d)*c,f*b,(h-d)*c);O(a,(f+e)*b,(h-d)*c,(f+d)*b,(h-e)*c,(f+d)*b,h*c);O(a,(f+d)*b,(h+e)*c,(f+e)*b,(h+d)*c,f*b,(h+d)*c);O(a,(f-e)*b,(h+d)*c,(f-d)*b,(h+e)*c,(f-d)*b,h*c);b=a.o;b.B=new L(.4,.25);b.C=new L(.65,.75);v.q(a);return b},XorGate:function(a,b,c){a=v.s();var d=.5*Xc;N(a,.1*b,0,!1);O(a,.35*b,.25*c,.35*b,.75*c,.1*b,1*c);N(a,.2*b,0,!0);O(a,(.2+d)*b,0*c,.9*b,(.5-d)*c,1*b,.5*c);O(a,.9*b,(.5+d)*
c,(.2+d)*b,1*c,.2*b,1*c);O(a,.45*b,.75*c,.45*b,.25*c,.2*b,0);P(a);b=a.o;b.B=new L(.4,.25);b.C=new L(.8,.75);v.q(a);return b},Capacitor:function(a,b,c){a=v.s();N(a,0,0,!1);a.lineTo(0,1*c);a.moveTo(1*b,0);a.lineTo(1*b,1*c);b=a.o;v.q(a);return b},Resistor:function(a,b,c){a=v.s();N(a,0,.5*c,!1);a.lineTo(.1*b,0);a.lineTo(.2*b,1*c);a.lineTo(.3*b,0);a.lineTo(.4*b,1*c);a.lineTo(.5*b,0);a.lineTo(.6*b,1*c);a.lineTo(.7*b,.5*c);b=a.o;v.q(a);return b},Inductor:function(a,b,c){a=v.s();var d=.1*Xc,e=.1;N(a,(e-.5*
d)*b,c,!1);O(a,(e-d)*b,c,(e-.1)*b,0,(e+.1)*b,0);e=.3;O(a,(e+.1)*b,0,(e+d)*b,c,e*b,c);O(a,(e-d)*b,c,(e-.1)*b,0,(e+.1)*b,0);e=.5;O(a,(e+.1)*b,0,(e+d)*b,c,e*b,c);O(a,(e-d)*b,c,(e-.1)*b,0,(e+.1)*b,0);e=.7;O(a,(e+.1)*b,0,(e+d)*b,c,e*b,c);O(a,(e-d)*b,c,(e-.1)*b,0,(e+.1)*b,0);e=.9;O(a,(e+.1)*b,0,(e+d)*b,c,(e+.5*d)*b,c);b=a.o;v.q(a);return b},ACvoltageSource:function(a,b,c){a=v.s();var d=.5*Xc;N(a,0*b,.5*c,!1);O(a,0*b,(.5-d)*c,(.5-d)*b,0*c,.5*b,0*c);O(a,(.5+d)*b,0*c,1*b,(.5-d)*c,1*b,.5*c);O(a,1*b,(.5+d)*
c,(.5+d)*b,1*c,.5*b,1*c);O(a,(.5-d)*b,1*c,0*b,(.5+d)*c,0*b,.5*c);a.moveTo(.1*b,.5*c);O(a,.5*b,0*c,.5*b,1*c,.9*b,.5*c);b=a.o;b.Md=Pi;v.q(a);return b},DCvoltageSource:function(a,b,c){a=v.s();N(a,0,.75*c,!1);a.lineTo(0,.25*c);a.moveTo(1*b,0);a.lineTo(1*b,1*c);b=a.o;v.q(a);return b},Diode:function(a,b,c){a=v.s();N(a,1*b,0,!1);a.lineTo(1*b,.5*c);a.lineTo(0,1*c);a.lineTo(0,0);a.lineTo(1*b,.5*c);a.lineTo(1*b,1*c);b=a.o;b.B=new L(0,.25);b.C=new L(.5,.75);v.q(a);return b},Wifi:function(a,b,c){var d=b,e=c;
b*=.38;c*=.6;a=v.s();var f=.8*Xc,h=.8,k=0,l=.5,d=(d-b)/2,e=(e-c)/2;N(a,k*b+d,(l+h)*c+e,!0);O(a,(k-f)*b+d,(l+h)*c+e,(k-h)*b+d,(l+f)*c+e,(k-h)*b+d,l*c+e);O(a,(k-h)*b+d,(l-f)*c+e,(k-f)*b+d,(l-h)*c+e,k*b+d,(l-h)*c+e);O(a,k*b+d,(l-h)*c+e,(k-h+.5*f)*b+d,(l-f)*c+e,(k-h+.5*f)*b+d,l*c+e);O(a,(k-h+.5*f)*b+d,(l+f)*c+e,k*b+d,(l+h)*c+e,k*b+d,(l+h)*c+e);P(a);f=.4*Xc;h=.4;k=.2;l=.5;N(a,k*b+d,(l+h)*c+e,!0);O(a,(k-f)*b+d,(l+h)*c+e,(k-h)*b+d,(l+f)*c+e,(k-h)*b+d,l*c+e);O(a,(k-h)*b+d,(l-f)*c+e,(k-f)*b+d,(l-h)*c+e,k*
b+d,(l-h)*c+e);O(a,k*b+d,(l-h)*c+e,(k-h+.5*f)*b+d,(l-f)*c+e,(k-h+.5*f)*b+d,l*c+e);O(a,(k-h+.5*f)*b+d,(l+f)*c+e,k*b+d,(l+h)*c+e,k*b+d,(l+h)*c+e);P(a);f=.2*Xc;h=.2;l=k=.5;N(a,(k-h)*b+d,l*c+e,!0);O(a,(k-h)*b+d,(l-f)*c+e,(k-f)*b+d,(l-h)*c+e,k*b+d,(l-h)*c+e);O(a,(k+f)*b+d,(l-h)*c+e,(k+h)*b+d,(l-f)*c+e,(k+h)*b+d,l*c+e);O(a,(k+h)*b+d,(l+f)*c+e,(k+f)*b+d,(l+h)*c+e,k*b+d,(l+h)*c+e);O(a,(k-f)*b+d,(l+h)*c+e,(k-h)*b+d,(l+f)*c+e,(k-h)*b+d,l*c+e);f=.4*Xc;h=.4;k=.8;l=.5;N(a,k*b+d,(l-h)*c+e,!0);O(a,(k+f)*b+d,(l-
h)*c+e,(k+h)*b+d,(l-f)*c+e,(k+h)*b+d,l*c+e);O(a,(k+h)*b+d,(l+f)*c+e,(k+f)*b+d,(l+h)*c+e,k*b+d,(l+h)*c+e);O(a,k*b+d,(l+h)*c+e,(k+h-.5*f)*b+d,(l+f)*c+e,(k+h-.5*f)*b+d,l*c+e);O(a,(k+h-.5*f)*b+d,(l-f)*c+e,k*b+d,(l-h)*c+e,k*b+d,(l-h)*c+e);P(a);f=.8*Xc;h=.8;k=1;l=.5;N(a,k*b+d,(l-h)*c+e,!0);O(a,(k+f)*b+d,(l-h)*c+e,(k+h)*b+d,(l-f)*c+e,(k+h)*b+d,l*c+e);O(a,(k+h)*b+d,(l+f)*c+e,(k+f)*b+d,(l+h)*c+e,k*b+d,(l+h)*c+e);O(a,k*b+d,(l+h)*c+e,(k+h-.5*f)*b+d,(l+f)*c+e,(k+h-.5*f)*b+d,l*c+e);O(a,(k+h-.5*f)*b+d,(l-f)*c+
e,k*b+d,(l-h)*c+e,k*b+d,(l-h)*c+e);P(a);b=a.o;v.q(a);return b},Email:function(a,b,c){a=v.s();N(a,0,0,!0);a.lineTo(1*b,0);a.lineTo(1*b,1*c);a.lineTo(0,1*c);a.lineTo(0,0);P(a);N(a,0,0,!1);a.lineTo(.5*b,.6*c);a.lineTo(1*b,0);a.moveTo(0,1*c);a.lineTo(.45*b,.54*c);a.moveTo(1*b,1*c);a.lineTo(.55*b,.54*c);a.Ya(!1);b=a.o;v.q(a);return b},Ethernet:function(a,b,c){a=v.s();N(a,.35*b,0,!0);a.lineTo(.65*b,0);a.lineTo(.65*b,.4*c);a.lineTo(.35*b,.4*c);a.lineTo(.35*b,0);P(a);N(a,.1*b,1*c,!0,!0);a.lineTo(.4*b,1*c);
a.lineTo(.4*b,.6*c);a.lineTo(.1*b,.6*c);a.lineTo(.1*b,1*c);P(a);N(a,.6*b,1*c,!0,!0);a.lineTo(.9*b,1*c);a.lineTo(.9*b,.6*c);a.lineTo(.6*b,.6*c);a.lineTo(.6*b,1*c);P(a);N(a,0,.5*c,!1);a.lineTo(1*b,.5*c);a.moveTo(.5*b,.5*c);a.lineTo(.5*b,.4*c);a.moveTo(.75*b,.5*c);a.lineTo(.75*b,.6*c);a.moveTo(.25*b,.5*c);a.lineTo(.25*b,.6*c);a.Ya(!1);b=a.o;v.q(a);return b},Power:function(a,b,c){a=v.s();var d=.4*Xc,e=.4,f=v.L(),h=v.L(),k=v.L(),l=v.L();Td(.5,.5-e,.5+d,.5-e,.5+e,.5-d,.5+e,.5,.5,f,f,h,k,l);var m=v.Mb(h.x,
h.y);N(a,h.x*b,h.y*c,!0);O(a,k.x*b,k.y*c,l.x*b,l.y*c,(.5+e)*b,.5*c);O(a,(.5+e)*b,(.5+d)*c,(.5+d)*b,(.5+e)*c,.5*b,(.5+e)*c);O(a,(.5-d)*b,(.5+e)*c,(.5-e)*b,(.5+d)*c,(.5-e)*b,.5*c);Td(.5-e,.5,.5-e,.5-d,.5-d,.5-e,.5,.5-e,.5,k,l,h,f,f);O(a,k.x*b,k.y*c,l.x*b,l.y*c,h.x*b,h.y*c);d=.3*Xc;e=.3;Td(.5-e,.5,.5-e,.5-d,.5-d,.5-e,.5,.5-e,.5,k,l,h,f,f);a.lineTo(h.x*b,h.y*c);O(a,l.x*b,l.y*c,k.x*b,k.y*c,(.5-e)*b,.5*c);O(a,(.5-e)*b,(.5+d)*c,(.5-d)*b,(.5+e)*c,.5*b,(.5+e)*c);O(a,(.5+d)*b,(.5+e)*c,(.5+e)*b,(.5+d)*c,(.5+
e)*b,.5*c);Td(.5,.5-e,.5+d,.5-e,.5+e,.5-d,.5+e,.5,.5,f,f,h,k,l);O(a,l.x*b,l.y*c,k.x*b,k.y*c,h.x*b,h.y*c);P(a);N(a,.45*b,0,!0);a.lineTo(.45*b,.5*c);a.lineTo(.55*b,.5*c);a.lineTo(.55*b,0);P(a);v.v(f);v.v(h);v.v(k);v.v(l);v.v(m);b=a.o;b.B=new L(.25,.55);b.C=new L(.75,.8);v.q(a);return b},Fallout:function(a,b,c){a=v.s();var d=.5*Xc;N(a,0*b,.5*c,!0);O(a,0*b,(.5-d)*c,(.5-d)*b,0*c,.5*b,0*c);O(a,(.5+d)*b,0*c,1*b,(.5-d)*c,1*b,.5*c);O(a,1*b,(.5+d)*c,(.5+d)*b,1*c,.5*b,1*c);O(a,(.5-d)*b,1*c,0*b,(.5+d)*c,0*b,
.5*c);var e=d=0;N(a,(.3+d)*b,(.8+e)*c,!0,!0);a.lineTo((.5+d)*b,(.5+e)*c);a.lineTo((.1+d)*b,(.5+e)*c);a.lineTo((.3+d)*b,(.8+e)*c);d=.4;e=0;P(a);N(a,(.3+d)*b,(.8+e)*c,!0,!0);a.lineTo((.5+d)*b,(.5+e)*c);a.lineTo((.1+d)*b,(.5+e)*c);a.lineTo((.3+d)*b,(.8+e)*c);d=.2;e=-.3;P(a);N(a,(.3+d)*b,(.8+e)*c,!0,!0);a.lineTo((.5+d)*b,(.5+e)*c);a.lineTo((.1+d)*b,(.5+e)*c);a.lineTo((.3+d)*b,(.8+e)*c);P(a);b=a.o;b.Md=Pi;v.q(a);return b},IrritationHazard:function(a,b,c){a=v.s();N(a,.2*b,0*c,!0);a.lineTo(.5*b,.3*c);a.lineTo(.8*
b,0*c);a.lineTo(1*b,.2*c);a.lineTo(.7*b,.5*c);a.lineTo(1*b,.8*c);a.lineTo(.8*b,1*c);a.lineTo(.5*b,.7*c);a.lineTo(.2*b,1*c);a.lineTo(0*b,.8*c);a.lineTo(.3*b,.5*c);a.lineTo(0*b,.2*c);P(a);b=a.o;b.B=new L(.3,.3);b.C=new L(.7,.7);v.q(a);return b},ElectricalHazard:function(a,b,c){a=v.s();N(a,.37*b,0*c,!0);a.lineTo(.5*b,.11*c);a.lineTo(.77*b,.04*c);a.lineTo(.33*b,.49*c);a.lineTo(1*b,.37*c);a.lineTo(.63*b,.86*c);a.lineTo(.77*b,.91*c);a.lineTo(.34*b,1*c);a.lineTo(.34*b,.78*c);a.lineTo(.44*b,.8*c);a.lineTo(.65*
b,.56*c);a.lineTo(0*b,.68*c);P(a);b=a.o;v.q(a);return b},FireHazard:function(a,b,c){a=v.s();N(a,.1*b,1*c,!0);O(a,-.25*b,.63*c,.45*b,.44*c,.29*b,0*c);O(a,.48*b,.17*c,.54*b,.35*c,.51*b,.42*c);O(a,.59*b,.29*c,.58*b,.28*c,.59*b,.18*c);O(a,.8*b,.34*c,.88*b,.43*c,.75*b,.6*c);O(a,.87*b,.48*c,.88*b,.43*c,.88*b,.31*c);O(a,1.17*b,.76*c,.82*b,.8*c,.9*b,1*c);P(a);b=a.o;b.B=new L(.05,.645);b.C=new L(.884,.908);v.q(a);return b},BpmnActivityLoop:function(a,b,c){a=v.s();var d=4*(Math.SQRT2-1)/3*.5;N(a,.65*b,1*c,
!1);O(a,(1-d+0)*b,1*c,1*b,(.5+d+0)*c,1*b,.5*c);O(a,1*b,(.5-d+0)*c,(.5+d+0)*b,0*c,.5*b,0*c);O(a,(.5-d+0)*b,0*c,0*b,(.5-d+0)*c,0*b,.5*c);O(a,0*b,(.5+d+0)*c,(.5-d+0)*b,1*c,.35*b,.98*c);a.moveTo(.25*b,.8*c);a.lineTo(.35*b,1*c);a.lineTo(.1*b,1*c);b=a.o;v.q(a);return b},BpmnActivityParallel:function(a,b,c){a=v.s();N(a,0,0,!1);a.lineTo(0,1*c);a.moveTo(.5*b,0);a.lineTo(.5*b,1*c);a.moveTo(1*b,0);a.lineTo(1*b,1*c);b=a.o;v.q(a);return b},BpmnActivitySequential:function(a,b,c){a=v.s();N(a,0,0,!1);a.lineTo(1*
b,0);a.moveTo(0,.5*c);a.lineTo(1*b,.5*c);a.moveTo(0,1*c);a.lineTo(1*b,1*c);b=a.o;v.q(a);return b},BpmnActivityAdHoc:function(a,b,c){a=v.s();N(a,0,0,!1);N(a,1*b,1*c,!1);N(a,0,.5*c,!1);O(a,.2*b,.35*c,.3*b,.35*c,.5*b,.5*c);O(a,.7*b,.65*c,.8*b,.65*c,1*b,.5*c);b=a.o;v.q(a);return b},BpmnActivityCompensation:function(a,b,c){a=v.s();N(a,0,.5*c,!0);a.lineTo(.5*b,0);a.lineTo(.5*b,.5*c);a.lineTo(1*b,1*c);a.lineTo(1*b,0);a.lineTo(.5*b,.5*c);a.lineTo(.5*b,1*c);P(a);b=a.o;v.q(a);return b},BpmnTaskMessage:function(a,
b,c){a=v.s();N(a,0,.2*c,!0);a.lineTo(1*b,.2*c);a.lineTo(1*b,.8*c);a.lineTo(0,.8*c);a.lineTo(0,.8*c);P(a);N(a,0,.2*c,!1);a.lineTo(.5*b,.5*c);a.lineTo(1*b,.2*c);a.Ya(!1);b=a.o;v.q(a);return b},BpmnTaskScript:function(a,b,c){a=v.s();N(a,.7*b,1*c,!0);a.lineTo(.3*b,1*c);O(a,.6*b,.5*c,0,.5*c,.3*b,0);a.lineTo(.7*b,0);O(a,.4*b,.5*c,1*b,.5*c,.7*b,1*c);P(a);N(a,.45*b,.73*c,!1);a.lineTo(.7*b,.73*c);a.moveTo(.38*b,.5*c);a.lineTo(.63*b,.5*c);a.moveTo(.31*b,.27*c);a.lineTo(.56*b,.27*c);a.Ya(!1);b=a.o;v.q(a);return b},
BpmnTaskUser:function(a,b,c){a=v.s();N(a,0,0,!1);N(a,.335*b,(1-.555)*c,!0);a.lineTo(.335*b,.595*c);a.lineTo(.665*b,.595*c);a.lineTo(.665*b,(1-.555)*c);O(a,.88*b,.46*c,.98*b,.54*c,1*b,.68*c);a.lineTo(1*b,1*c);a.lineTo(0,1*c);a.lineTo(0,.68*c);O(a,.02*b,.54*c,.12*b,.46*c,.335*b,(1-.555)*c);a.lineTo(.365*b,.405*c);var d=.5-.285,e=Math.PI/4,f=4*(1-Math.cos(e))/(3*Math.sin(e)),e=f*d,f=f*d;O(a,(.5-(e+d)/2)*b,(d+(d+f)/2)*c,(.5-d)*b,(d+f)*c,(.5-d)*b,d*c);O(a,(.5-d)*b,(d-f)*c,(.5-e)*b,(d-d)*c,.5*b,(d-d)*c);
O(a,(.5+e)*b,(d-d)*c,(.5+d)*b,(d-f)*c,(.5+d)*b,d*c);O(a,(.5+d)*b,(d+f)*c,(.5+(e+d)/2)*b,(d+(d+f)/2)*c,.635*b,.405*c);a.lineTo(.635*b,.405*c);a.lineTo(.665*b,(1-.555)*c);a.lineTo(.665*b,.595*c);a.lineTo(.335*b,.595*c);N(a,.2*b,1*c,!1);a.lineTo(.2*b,.8*c);N(a,.8*b,1*c,!1);a.lineTo(.8*b,.8*c);b=a.o;v.q(a);return b},BpmnEventConditional:function(a,b,c){a=v.s();N(a,.1*b,0,!0);a.lineTo(.9*b,0);a.lineTo(.9*b,1*c);a.lineTo(.1*b,1*c);P(a);N(a,.2*b,.2*c,!1);a.lineTo(.8*b,.2*c);a.moveTo(.2*b,.4*c);a.lineTo(.8*
b,.4*c);a.moveTo(.2*b,.6*c);a.lineTo(.8*b,.6*c);a.moveTo(.2*b,.8*c);a.lineTo(.8*b,.8*c);a.Ya(!1);b=a.o;v.q(a);return b},BpmnEventError:function(a,b,c){a=v.s();N(a,0,1*c,!0);a.lineTo(.33*b,0);a.lineTo(.66*b,.5*c);a.lineTo(1*b,0);a.lineTo(.66*b,1*c);a.lineTo(.33*b,.5*c);P(a);b=a.o;v.q(a);return b},BpmnEventEscalation:function(a,b,c){a=v.s();N(a,0,0,!1);N(a,1*b,1*c,!1);N(a,.1*b,1*c,!0);a.lineTo(.5*b,0);a.lineTo(.9*b,1*c);a.lineTo(.5*b,.5*c);P(a);b=a.o;v.q(a);return b},BpmnEventTimer:function(a,b,c){a=
v.s();var d=.5*Xc;N(a,1*b,.5*c,!0);O(a,1*b,(.5+d)*c,(.5+d)*b,1*c,.5*b,1*c);O(a,(.5-d)*b,1*c,0,(.5+d)*c,0,.5*c);O(a,0,(.5-d)*c,(.5-d)*b,0,.5*b,0);O(a,(.5+d)*b,0,1*b,(.5-d)*c,1*b,.5*c);N(a,.5*b,0,!1);a.lineTo(.5*b,.15*c);a.moveTo(.5*b,1*c);a.lineTo(.5*b,.85*c);a.moveTo(0,.5*c);a.lineTo(.15*b,.5*c);a.moveTo(1*b,.5*c);a.lineTo(.85*b,.5*c);a.moveTo(.5*b,.5*c);a.lineTo(.58*b,.1*c);a.moveTo(.5*b,.5*c);a.lineTo(.78*b,.54*c);a.Ya(!1);b=a.o;b.Md=Pi;v.q(a);return b}},Wo;for(Wo in ko)ko[Wo.toLowerCase()]=Wo;
var Xo={"":"",Standard:"F1 m 0,0 l 8,4 -8,4 2,-4 z",Backward:"F1 m 8,0 l -2,4 2,4 -8,-4 z",Triangle:"F1 m 0,0 l 8,4.62 -8,4.62 z",BackwardTriangle:"F1 m 8,4 l 0,4 -8,-4 8,-4 0,4 z",Boomerang:"F1 m 0,0 l 8,4 -8,4 4,-4 -4,-4 z",BackwardBoomerang:"F1 m 8,0 l -8,4 8,4 -4,-4 4,-4 z",SidewaysV:"m 0,0 l 8,4 -8,4 0,-1 6,-3 -6,-3 0,-1 z",BackwardV:"m 8,0 l -8,4 8,4 0,-1 -6,-3 6,-3 0,-1 z",OpenTriangle:"m 0,0 l 8,4 -8,4",BackwardOpenTriangle:"m 8,0 l -8,4 8,4",OpenTriangleLine:"m 0,0 l 8,4 -8,4 m 8.5,0 l 0,-8",
BackwardOpenTriangleLine:"m 8,0 l  -8,4 8,4 m -8.5,0 l 0,-8",OpenTriangleTop:"m 0,0 l 8,4 m 0,4",BackwardOpenTriangleTop:"m 8,0 l -8,4 m 0,4",OpenTriangleBottom:"m 0,8 l 8,-4",BackwardOpenTriangleBottom:"m 0,4 l 8,4",HalfTriangleTop:"F1 m 0,0 l 0,4 8,0 z m 0,8",BackwardHalfTriangleTop:"F1 m 8,0 l 0,4 -8,0 z m 0,8",HalfTriangleBottom:"F1 m 0,4 l 0,4 8,-4 z",BackwardHalfTriangleBottom:"F1 m 8,4 l 0,4 -8,-4 z",ForwardSemiCircle:"m 4,0 b 270 180 0 4 4",BackwardSemiCircle:"m 4,8 b 90 180 0 -4 4",Feather:"m 0,0 l 3,4 -3,4",
BackwardFeather:"m 3,0 l -3,4 3,4",DoubleFeathers:"m 0,0 l 3,4 -3,4 m 3,-8 l 3,4 -3,4",BackwardDoubleFeathers:"m 3,0 l -3,4 3,4 m 3,-8 l -3,4 3,4",TripleFeathers:"m 0,0 l 3,4 -3,4 m 3,-8 l 3,4 -3,4 m 3,-8 l 3,4 -3,4",BackwardTripleFeathers:"m 3,0 l -3,4 3,4 m 3,-8 l -3,4 3,4 m 3,-8 l -3,4 3,4",ForwardSlash:"m 0,8 l 5,-8",BackSlash:"m 0,0 l 5,8",DoubleForwardSlash:"m 0,8 l 4,-8 m -2,8 l 4,-8",DoubleBackSlash:"m 0,0 l 4,8 m -2,-8 l 4,8",TripleForwardSlash:"m 0,8 l 4,-8 m -2,8 l 4,-8 m -2,8 l 4,-8",
TripleBackSlash:"m 0,0 l 4,8 m -2,-8 l 4,8 m -2,-8 l 4,8",Fork:"m 0,4 l 8,0 m -8,0 l 8,-4 m -8,4 l 8,4",BackwardFork:"m 8,4 l -8,0 m 8,0 l -8,-4 m 8,4 l -8,4",LineFork:"m 0,0 l 0,8 m 0,-4 l 8,0 m -8,0 l 8,-4 m -8,4 l 8,4",BackwardLineFork:"m 8,4 l -8,0 m 8,0 l -8,-4 m 8,4 l -8,4 m 8,-8 l 0,8",CircleFork:"F1 m 6,4 b 0 360 -3 0 3 z m 0,0 l 6,0 m -6,0 l 6,-4 m -6,4 l 6,4",BackwardCircleFork:"F1 m 0,4 l 6,0 m -6,-4 l 6,4 m -6,4 l 6,-4 m 6,0 b 0 360 -3 0 3",CircleLineFork:"F1 m 6,4 b 0 360 -3 0 3 z m 1,-4 l 0,8 m 0,-4 l 6,0 m -6,0 l 6,-4 m -6,4 l 6,4",
BackwardCircleLineFork:"F1 m 0,4 l 6,0 m -6,-4 l 6,4 m -6,4 l 6,-4 m 0,-4 l 0,8 m 7,-4 b 0 360 -3 0 3",Circle:"F1 m 8,4 b 0 360 -4 0 4 z",Block:"F1 m 0,0 l 0,8 8,0 0,-8 z",StretchedDiamond:"F1 m 0,3 l 5,-3 5,3 -5,3 -5,-3 z",Diamond:"F1 m 0,4 l 4,-4 4,4 -4,4 -4,-4 z",Chevron:"F1 m 0,0 l 5,0 3,4 -3,4 -5,0 3,-4 -3,-4 z",StretchedChevron:"F1 m 0,0 l 8,0 3,4 -3,4 -8,0 3,-4 -3,-4 z",NormalArrow:"F1 m 0,2 l 4,0 0,-2 4,4 -4,4 0,-2 -4,0 z",X:"m 0,0 l 8,8 m 0,-8 l -8,8",TailedNormalArrow:"F1 m 0,0 l 2,0 1,2 3,0 0,-2 2,4 -2,4 0,-2 -3,0 -1,2 -2,0 1,-4 -1,-4 z",
DoubleTriangle:"F1 m 0,0 l 4,4 -4,4 0,-8 z  m 4,0 l 4,4 -4,4 0,-8 z",BigEndArrow:"F1 m 0,0 l 5,2 0,-2 3,4 -3,4 0,-2 -5,2 0,-8 z",ConcaveTailArrow:"F1 m 0,2 h 4 v -2 l 4,4 -4,4 v -2 h -4 l 2,-2 -2,-2 z",RoundedTriangle:"F1 m 0,1 a 1,1 0 0 1 1,-1 l 7,3 a 0.5,1 0 0 1 0,2 l -7,3 a 1,1 0 0 1 -1,-1 l 0,-6 z",SimpleArrow:"F1 m 1,2 l -1,-2 2,0 1,2 -1,2 -2,0 1,-2 5,0 0,-2 2,2 -2,2 0,-2 z",AccelerationArrow:"F1 m 0,0 l 0,8 0.2,0 0,-8 -0.2,0 z m 2,0 l 0,8 1,0 0,-8 -1,0 z m 3,0 l 2,0 2,4 -2,4 -2,0 0,-8 z",BoxArrow:"F1 m 0,0 l 4,0 0,2 2,0 0,-2 2,4 -2,4 0,-2 -2,0 0,2 -4,0 0,-8 z",
TriangleLine:"F1 m 8,4 l -8,-4 0,8 8,-4 z m 0.5,4 l 0,-8",CircleEndedArrow:"F1 m 10,4 l -2,-3 0,2 -2,0 0,2 2,0 0,2 2,-3 z m -4,0 b 0 360 -3 0 3 z",DynamicWidthArrow:"F1 m 0,3 l 2,0 2,-1 2,-2 2,4 -2,4 -2,-2 -2,-1 -2,0 0,-2 z",EquilibriumArrow:"m 0,3 l 8,0 -3,-3 m 3,5 l -8,0 3,3",FastForward:"F1 m 0,0 l 3.5,4 0,-4 3.5,4 0,-4 1,0 0,8 -1,0 0,-4 -3.5,4 0,-4 -3.5,4 0,-8 z",Kite:"F1 m 0,4 l 2,-4 6,4 -6,4 -2,-4 z",HalfArrowTop:"F1 m 0,0 l 4,4 4,0 -8,-4 z m 0,8",HalfArrowBottom:"F1 m 0,8 l 4,-4 4,0 -8,4 z",
OpposingDirectionDoubleArrow:"F1 m 0,4 l 2,-4 0,2 4,0 0,-2 2,4 -2,4 0,-2 -4,0 0,2 -2,-4 z",PartialDoubleTriangle:"F1 m 0,0 4,3 0,-3 4,4 -4,4 0,-3 -4,3 0,-8 z",LineCircle:"F1 m 0,0 l 0,8 m 7 -4 b 0 360 -3 0 3 z",DoubleLineCircle:"F1 m 0,0 l 0,8 m 2,-8 l 0,8 m 7 -4 b 0 360 -3 0 3 z",TripleLineCircle:"F1 m 0,0 l 0,8 m 2,-8 l 0,8 m 2,-8 l 0,8 m 7 -4 b 0 360 -3 0 3 z",CircleLine:"F1 m 6 4 b 0 360 -3 0 3 z m 1,-4 l 0,8",DiamondCircle:"F1 m 8,4 l -4,4 -4,-4 4,-4 4,4 m 8,0 b 0 360 -4 0 4 z",PlusCircle:"F1 m 8,4 b 0 360 -4 0 4 l -8 0 z m -4 -4 l 0 8",
OpenRightTriangleTop:"m 8,0 l 0,4 -8,0 m 0,4",OpenRightTriangleBottom:"m 8,8 l 0,-4 -8,0",Line:"m 0,0 l 0,8",DoubleLine:"m 0,0 l 0,8 m 2,0 l 0,-8",TripleLine:"m 0,0 l 0,8 m 2,0 l 0,-8 m 2,0 l 0,8",PentagonArrow:"F1 m 8,4 l -4,-4 -4,0 0,8 4,0 4,-4 z"};
function oo(a){if(null!==Xo){for(var b in Xo){var c=bf(Xo[b],!1);Hd[b]=c;b.toLowerCase()!==b&&(Hd[b.toLowerCase()]=b)}Xo=null}b=Hd[a];if(void 0===b){b=a.toLowerCase();if("none"===b)return"None";b=Hd[b]}return"string"===typeof b?b:b instanceof re?a:null}
function R(a){0===arguments.length?D.call(this):D.call(this,a);this.Q=311807;this.Ik=this.di="";this.Yr=this.Vr=this.hs=this.er=null;this.ks="";this.bi=this.js=this.qm=null;this.Xr="";this.po=null;this.Wr=Cd;this.Zr="";this.qo=null;this.le="";this.lv=this.gq=this.uk=null;this.wi=(new z(NaN,NaN)).freeze();this.jr="";this.Nk=null;this.kr=tb;this.tr=kd;this.mr=ld;this.zq=null;this.fr=Yo;this.sm=jd;this.rm="gray";this.og=4;this.aC=-1;this.Ms=NaN;this.AF=new B;this.em=null;this.yj=NaN}v.Ja(R,D);
v.ea("Part",R);R.prototype.cloneProtected=function(a){D.prototype.cloneProtected.call(this,a);a.Q=this.Q&-4097|49152;a.di=this.di;a.Ik=this.Ik;a.er=this.er;a.hs=this.hs;a.Vr=this.Vr;a.Yr=this.Yr;a.ks=this.ks;a.js=this.js;a.bi=null;a.Xr=this.Xr;a.Wr=this.Wr.R();a.Zr=this.Zr;a.le=this.le;a.gq=this.gq;a.wi.assign(this.wi);a.jr=this.jr;a.kr=this.kr.R();a.tr=this.tr.R();a.mr=this.mr.R();a.zq=this.zq;a.fr=this.fr;a.sm=this.sm.R();a.rm=this.rm;a.og=this.og;a.Ms=this.Ms};
R.prototype.Vh=function(a){D.prototype.Vh.call(this,a);a.ql();a.qm=null;a.po=null;a.qo=null;a.Nk=null;a.em=null};R.prototype.toString=function(){var a=v.$e(Object.getPrototypeOf(this))+"#"+v.ld(this);null!==this.data&&(a+="("+Ef(this.data)+")");return a};R.LayoutNone=0;var ml;R.LayoutAdded=ml=1;var ul;R.LayoutRemoved=ul=2;R.LayoutShown=4;R.LayoutHidden=8;R.LayoutNodeSized=16;var Yl;R.LayoutGroupLayout=Yl=32;R.LayoutNodeReplaced=64;var Yo;R.LayoutStandard=Yo=ml|ul|28|Yl|64;R.LayoutAll=16777215;
R.prototype.an=function(a,b,c,d,e,f,h){var k=this.g;null!==k&&(a===Cf&&"elements"===b?e instanceof D?nl(e,function(a){pl(k,a);ol(k,a)}):e instanceof kk&&Kl(k,e):a===Df&&"elements"===b&&(e instanceof D?nl(e,function(a){tl(k,a);sl(k,a)}):e instanceof kk&&Ll(k,e)),k.Lc(a,b,c,d,e,f,h))};R.prototype.updateTargetBindings=R.prototype.Hb=function(a){D.prototype.Hb.call(this,a);if(null!==this.data){a=this.za.n;for(var b=a.length,c=0;c<b;c++){var d=a[c];d instanceof D&&nl(d,function(a){null!==a.data&&a.Hb()})}}};
R.prototype.updateRelationshipsFromData=function(){var a=this.data;if(null!==a){var b=this.g;if(null!==b){var c=b.ba;if(c instanceof Q){var d=c.Om(a),b=b.QG(d),e=this.Sa;b!==e&&(b=null!==e?c.gb(e.data):void 0,e=c.vp,"function"===typeof e?e(a,b):a[e]=b,c.lx(a,d))}}}};v.u(R,{gw:"adornments"},function(){return null===this.bi?xa:this.bi.nE});R.prototype.findAdornment=R.prototype.Vo=function(a){var b=this.bi;return null===b?null:b.ja(a)};
R.prototype.addAdornment=R.prototype.Wk=function(a,b){if(null!==b){var c=null,d=this.bi;null!==d&&(c=d.ja(a));if(c!==b){if(null!==c){var e=c.g;null!==e&&e.remove(c)}null===d&&(this.bi=d=new na("string",Tg));b.di!==a&&(b.gc=a);d.add(a,b);c=this.g;null!==c&&(c.add(b),b.data=this.data)}}};R.prototype.removeAdornment=R.prototype.$i=function(a){var b=this.bi;if(null!==b){var c=b.ja(a);if(null!==c){var d=c.g;null!==d&&d.remove(c)}b.remove(a);0===b.count&&(this.bi=null)}};
R.prototype.clearAdornments=R.prototype.Vs=function(){var a=this.bi;if(null!==a){for(var b=v.jb(),a=a.i;a.next();)b.push(a.key);for(var a=b.length,c=0;c<a;c++)this.$i(b[c]);v.sa(b)}};
R.prototype.updateAdornments=function(){var a=this.g;if(null!==a){a:{if(this.hb&&this.uI){var b=this.Wt;if(!(null!==b&&this.U.G()&&this.isVisible()&&b.Xi()&&b.U.G()))break a;var c=this.Vo("Selection");if(null===c){c=this.vI;null===c&&(c=this instanceof W?a.HH:this instanceof V?a.bH:a.YH);if(!(c instanceof Tg))break a;Vg(c);c=c.copy();null!==c&&(c.gc="Selection",c.Zb=b)}if(null!==c){var d=c.placeholder;if(null!==d){var e=b.Ri(),f=0;b instanceof X&&(f=b.lb);var h=v.El();h.m((b.Ha.width+f)*e,(b.Ha.height+
f)*e);d.xa=h;v.Zj(h)}c.angle=b.nl();c.type!==xi&&(d=v.L(),c.location=b.Xa(tb,d),v.v(d));this.Wk("Selection",c);break a}}this.$i("Selection")}Zo(this,a);for(a=this.gw;a.next();)b=a.value,b.Hb(),b.P()}};function Zo(a,b){b.Za.Ne.each(function(b){b.isEnabled&&b.updateAdornments(a)});b.Za.updateAdornments(a)}v.u(R,{layer:"layer"},function(){return this.lv});v.u(R,{g:"diagram"},function(){var a=this.lv;return null!==a?a.g:null});
v.defineProperty(R,{Tf:"layerName"},function(){return this.Ik},function(a){var b=this.Ik;if(b!==a){v.j(a,"string",R,"layerName");var c=this.g;if(null===c||null!==c.dt(a)&&!c.Rp)if(this.Ik=a,null!==c&&c.qc(),this.h("layerName",b,a),b=this.layer,null!==b&&b.name!==a&&(c=b.g,null!==c&&(a=c.dt(a),null!==a&&a!==b))){var d=b.Le(-1,this,!0);0<=d&&c.Lc(Df,"parts",b,this,null,d,!0);b.visible!==a.visible&&this.nd(a.visible);d=a.ep(99999999,this,!0);0<=d&&c.Lc(Cf,"parts",a,null,this,!0,d);d=this.zt;if(null!==
d){var e=c.Qa;c.Qa=!0;d(this,b,a);c.Qa=e}}}});v.defineProperty(R,{zt:"layerChanged"},function(){return this.er},function(a){var b=this.er;b!==a&&(null!==a&&v.j(a,"function",R,"layerChanged"),this.er=a,this.h("layerChanged",b,a))});v.defineProperty(R,{ln:"zOrder"},function(){return this.Ms},function(a){var b=this.Ms;if(b!==a){v.j(a,"number",R,"zOrder");this.Ms=a;var c=this.layer;null!==c&&bk(c,-1,this);this.h("zOrder",b,a);a=this.g;null!==a&&a.pa()}});
R.prototype.invalidateAdornments=R.prototype.Od=function(){var a=this.g;null!==a&&(xk(a),0!==(this.Q&16384)!==!0&&(this.Q|=16384,a.ue()))};function Xj(a){0!==(a.Q&16384)!==!1&&(a.updateAdornments(),a.Q&=-16385)}function Wm(a){if(!1===fl(a)){var b=a.g;null!==b&&(b.fg.add(a),a instanceof S&&!b.la.ib&&a.bf(),b.ue());$o(a,!0);a.ri()}}function ap(a){if(!1!==fl(a)){var b=a.position,c=a.location;c.G()&&b.G()||bp(a,b,c);var c=a.mc,d=c.copy();c.Oa();c.x=b.x;c.y=b.y;c.freeze();a.Tw(d,c);$o(a,!1)}}
v.u(R,{cc:"locationObject"},function(){if(null===this.Nk){var a=this.Lz;""!==a?(a=this.be(a),this.Nk=null!==a?a:this):this.Nk=this instanceof Tg?this.type!==xi&&null!==this.placeholder?this.placeholder:this:this}return this.Nk.visible?this.Nk:this});v.defineProperty(R,{RH:"minLocation"},function(){return this.tr},function(a){var b=this.tr;b.K(a)||(this.tr=a=a.R(),this.h("minLocation",b,a))});
v.defineProperty(R,{MH:"maxLocation"},function(){return this.mr},function(a){var b=this.mr;b.K(a)||(this.mr=a=a.R(),this.h("maxLocation",b,a))});v.defineProperty(R,{Lz:"locationObjectName"},function(){return this.jr},function(a){var b=this.jr;b!==a&&(this.jr=a,this.Nk=null,Qm(this),this.h("locationObjectName",b,a))});v.defineProperty(R,{df:"locationSpot"},function(){return this.kr},function(a){var b=this.kr;b.K(a)||(this.kr=a=a.R(),Qm(this),this.h("locationSpot",b,a))});
R.prototype.move=function(a){this.position=a};R.prototype.moveTo=R.prototype.moveTo=function(a,b){var c=v.Mb(a,b);this.move(c);v.v(c)};R.prototype.isVisible=function(){if(!this.visible)return!1;var a=this.layer;if(null!==a&&!a.visible)return!1;a=this.g;if(null!==a&&(a=a.Na,a.cf&&(a=a.gm.ja(this),null!==a&&a.ax)))return!0;a=this.Sa;return null===a||a.de&&a.isVisible()?!0:!1};
R.prototype.nd=function(a){var b=this.g;a?(this.H(4),this.Od(),null!==b&&b.fg.add(this)):(this.H(8),this.Vs());this.ql();null!==b&&(b.qc(),b.pa())};
R.prototype.findObject=R.prototype.be=function(a){if(this.name===a)return this;var b=this.em;null===b&&(this.em=b=new ta);if(void 0!==b[a])return b[a];for(var c=this.za.n,d=c.length,e=0;e<d;e++){var f=c[e];if(f.name===a)return b[a]=f;if(f instanceof D)if(null===f.tj&&null===f.hg){if(f=f.be(a),null!==f)return b[a]=f}else if(Il(f)&&(f=f.za.first(),null!==f&&f.name===a))return b[a]=f}return b[a]=null};
function cp(a,b,c,d){void 0===d&&(d=new z);c=c.se()?Ab:c;var e=b.Ha;d.m(e.width*c.x+c.offsetX,e.height*c.y+c.offsetY);if(null===b||b===a)return d;b.transform.ub(d);for(b=b.S;null!==b&&b!==a;)b.transform.ub(d),b=b.S;a.Mk.ub(d);d.offset(-a.kd.x,-a.kd.y);return d}R.prototype.ensureBounds=R.prototype.sf=function(){fj(this,Infinity,Infinity);this.Ec()};
function ak(a,b){var c=a.AF,d;isNaN(a.yj)&&(a.yj=ao(a));d=a.yj;var e=2*d;if(!a.vl)return c.m(b.x-1-d,b.y-1-d,b.width+2+e,b.height+2+e),c;d=b.x;var e=b.y,f=b.width,h=b.height,k=a.shadowBlur,l=a.AI,f=f+k,h=h+k;d-=k/2;e-=k/2;0<l.x?f+=l.x:(d+=l.x,f-=l.x);0<l.y?h+=l.y:(e+=l.y,h-=l.y);c.m(d-1,e-1,f+2,h+2);return c}
R.prototype.Ec=function(){this.ri();if(!1===el(this))ap(this);else{var a=this.mc,b=v.ag();b.assign(a);a.Oa();var c=Wj(this);this.Li(0,0,this.kd.width,this.kd.height);var d=this.position;bp(this,d,this.location);a.x=d.x;a.y=d.y;a.freeze();this.Tw(b,a);Gm(this,!1);b.K(a)?this.yf(c):!this.Pd()||K(b.width,a.width)&&K(b.height,a.height)||0<=this.aC&&this.H(16);v.Vb(b);$o(this,!1)}};
R.prototype.Tw=function(a,b){var c=this.g;if(null!==c){var d=!1;if(!1===c.qj&&a.G()){var e=c.Zd,f=c.padding,h=e.x+f.left,k=e.y+f.top,l=e.width-2*f.right,e=e.height-2*f.bottom;a.x>h&&a.y>k&&a.right<l&&a.bottom<e&&b.x>h&&b.y>k&&b.right<l&&b.bottom<e&&(d=!0)}0!==(this.Q&65536)!==!0&&a.K(b)||ql(this,d,c);c.pa();a.K(b)||this.ql()}};
v.defineProperty(R,{location:"location"},function(){return this.wi},function(a){var b=this.wi;if(!b.K(a)){a=a.R();var c;c=a;if(this instanceof W)c=!1;else{this.wi=c;if(!1===el(this)){Wm(this);var d=this.kb;if(d.G()){var e=d.copy();d.m(d.x+(c.x-b.x),d.y+(c.y-b.y));dp(this,this.g,d,e);this.h("position",e,d)}}c=!0}c&&this.h("location",b,a)}});
R.prototype.pA=function(a,b){if(this instanceof W||!a.G())return!1;var c=this.g;if(null!==c&&(dp(this,c,a,b),!0===c.la.ib))return!0;this.kb=a;c=this.wi;if(c.G()){var d=c.copy();c.m(c.x+(a.x-b.x),c.y+(a.y-b.y));this.h("location",d,c)}!1===fl(this)&&!1===el(this)&&(Wm(this),ap(this));return!0};function dp(a,b,c,d){null===b||a instanceof Tg||(b=b.Na,b.pc&&Kj(b,a,"position",d.copy(),c.copy(),!1))}
R.prototype.nx=function(a,b){var c=this.wi,d=this.kb;fl(this)||el(this)?c.m(NaN,NaN):c.m(c.x+a-d.x,c.y+b-d.y);d.m(a,b);Wm(this)};
function bp(a,b,c){var d=v.L(),e=a.df,f=a.cc;e.se()&&v.k("determineOffset: Part's locationSpot must be real: "+e.toString());var h=f.Ha,k=f instanceof X?f.lb:0;d.$t(0,0,h.width+k,h.height+k,e);if(f!==a)for(d.offset(-k/2,-k/2),f.transform.ub(d),e=f.S;null!==e&&e!==a;)e.transform.ub(d),e=e.S;a.Mk.ub(d);d.offset(-a.kd.x,-a.kd.y);e=a.g;c.G()?(f=b.x,h=b.y,b.m(c.x-d.x,c.y-d.y),null!==e&&(c=e.Na,(k=c.rj)||!c.pc||a instanceof Tg||Kj(c,a,"position",new z(f,h),b,!1),k||b.x===f&&b.y===h||(k=e.tb,e.tb=!0,a.h("position",
new z(f,h),b),e.tb=k))):b.G()&&(f=c.copy(),c.m(b.x+d.x,b.y+d.y),c.K(f)||null===e||(k=e.tb,e.tb=!0,a.h("location",f,c),e.tb=k));v.v(d);a.ri()}function ql(a,b,c){Hm(a,!1);a instanceof S&&fm(c,a);a.layer.Gc||b||c.qc();b=a.mc;var d=c.rb;d.G()?(Wj(a)?$a(b,d)||a.yf(!1):b.xg(d)&&a.yf(!0),a.updateAdornments()):c.Ek=!0}R.prototype.ul=function(){return!0};
function Yj(a,b){var c=a.mc;if(0!==c.width&&0!==c.height&&!isNaN(c.x)&&!isNaN(c.y)&&a.isVisible()){var d=a.transform;null!==a.nc&&(Mm(a,b,a.nc,!0,!0),b.fillRect(c.x,c.y,c.width,c.height));null===a.nc&&null===a.Ib&&(Mm(a,b,"rgba(0,0,0,0.4)",!0,!1),b.fillRect(c.x,c.y,c.width,c.height));null!==a.Ib&&(d.vt()||b.transform(d.m11,d.m12,d.m21,d.m22,d.dx,d.dy),c=a.Ha,Mm(a,b,a.Ib,!0,!1),b.fillRect(0,0,c.width,c.height),d.vt()||(c=1/(d.m11*d.m22-d.m12*d.m21),b.transform(d.m22*c,-d.m12*c,-d.m21*c,d.m11*c,c*(d.m21*
d.dy-d.m22*d.dx),c*(d.m12*d.dx-d.m11*d.dy))))}}R.prototype.Pd=function(){return!0};
v.defineProperty(R,{gc:"category"},function(){return this.di},function(a){var b=this.di;if(b!==a){v.j(a,"string",R,"category");var c=this.g,d=this.data,e=null;if(null!==c&&null!==d&&!(this instanceof Tg)){var f=c.ba.la;f.isEnabled&&!f.ib&&(e=this.clone(),e.za.Tc(this.za))}this.di=a;this.h("category",b,a);null===c||null===d||this instanceof Tg?(e=this.Mf,null!==e&&(a=e.bi,null!==a&&a.remove(b),e.Wk(this.gc,this))):(f=c.ba,f.la.ib||(this instanceof W?(f instanceof Q?f.VE(d,a):f instanceof Pf&&f.yI(d,
a),c=Sl(c,a),null!==c&&(Vg(c),c=c.copy(),null!==c&&ep(this,c,b,a))):(null!==f&&f.jx(d,a),c=Pl(c,d,a),null!==c&&(Vg(c),c=c.copy(),null===c||c instanceof W||(c.location=this.location,ep(this,c,b,a)))),null!==e&&(b=this.clone(),b.za.Tc(this.za),this.h("self",e,b))))}});v.defineProperty(R,{self:"self"},function(){return this},function(a){ep(this,a,this.gc,a.gc)});var fp=!1;
function ep(a,b,c,d){b.constructor===a.constructor||fp||(fp=!0,v.trace('Should not change the class of the Part when changing category from "'+c+'" to "'+d+'"'),v.trace("  Old class: "+v.$e(a)+", new class: "+v.$e(b)+", part: "+a.toString()));a.Vs();var e=a.data;c=a.Tf;var f=a.hb,h=a.fh,k=!0,l=!0,m=!1;if(a instanceof S)var n=a,k=n.Wi,l=n.md,m=n.hu;b.Vh(a);b.cloneProtected(a);a.di=d;a.P();a.pa();b=a.g;d=!0;null!==b&&(d=b.tb,b.tb=!0);a.ie=e;null!==e&&a.Hb();null!==b&&(b.tb=d);e=a.Tf;e!==c&&(a.Ik=c,
a.Tf=e);a instanceof S&&(n=a,n.Wi=k,n.md=l,n.hu=m,n.Pd()&&n.H(64));a.hb=f;a.fh=h}R.prototype.canCopy=function(){if(!this.zG)return!1;var a=this.layer;if(null===a)return!0;if(!a.Uj)return!1;a=a.g;return null===a?!0:a.Uj?!0:!1};R.prototype.canDelete=function(){if(!this.EG)return!1;var a=this.layer;if(null===a)return!0;if(!a.zm)return!1;a=a.g;return null===a?!0:a.zm?!0:!1};
R.prototype.canEdit=function(){if(!this.HI)return!1;var a=this.layer;if(null===a)return!0;if(!a.kw)return!1;a=a.g;return null===a?!0:a.kw?!0:!1};R.prototype.canGroup=function(){if(!this.cH)return!1;var a=this.layer;if(null===a)return!0;if(!a.hw)return!1;a=a.g;return null===a?!0:a.hw?!0:!1};R.prototype.canMove=function(){if(!this.WH)return!1;var a=this.layer;if(null===a)return!0;if(!a.$k)return!1;a=a.g;return null===a?!0:a.$k?!0:!1};
R.prototype.canReshape=function(){if(!this.jI)return!1;var a=this.layer;if(null===a)return!0;if(!a.iw)return!1;a=a.g;return null===a?!0:a.iw?!0:!1};R.prototype.canResize=function(){if(!this.kI)return!1;var a=this.layer;if(null===a)return!0;if(!a.Us)return!1;a=a.g;return null===a?!0:a.Us?!0:!1};R.prototype.canRotate=function(){if(!this.nI)return!1;var a=this.layer;if(null===a)return!0;if(!a.jw)return!1;a=a.g;return null===a?!0:a.jw?!0:!1};
R.prototype.canSelect=function(){if(!this.zl)return!1;var a=this.layer;if(null===a)return!0;if(!a.nf)return!1;a=a.g;return null===a?!0:a.nf?!0:!1};v.defineProperty(R,{zG:"copyable"},function(){return 0!==(this.Q&1)},function(a){var b=0!==(this.Q&1);b!==a&&(this.Q^=1,this.h("copyable",b,a))});v.defineProperty(R,{EG:"deletable"},function(){return 0!==(this.Q&2)},function(a){var b=0!==(this.Q&2);b!==a&&(this.Q^=2,this.h("deletable",b,a))});
v.defineProperty(R,{HI:"textEditable"},function(){return 0!==(this.Q&4)},function(a){var b=0!==(this.Q&4);b!==a&&(this.Q^=4,this.h("textEditable",b,a),this.Od())});v.defineProperty(R,{cH:"groupable"},function(){return 0!==(this.Q&8)},function(a){var b=0!==(this.Q&8);b!==a&&(this.Q^=8,this.h("groupable",b,a))});v.defineProperty(R,{WH:"movable"},function(){return 0!==(this.Q&16)},function(a){var b=0!==(this.Q&16);b!==a&&(this.Q^=16,this.h("movable",b,a))});
v.defineProperty(R,{uI:"selectionAdorned"},function(){return 0!==(this.Q&32)},function(a){var b=0!==(this.Q&32);b!==a&&(this.Q^=32,this.h("selectionAdorned",b,a),this.Od())});v.defineProperty(R,{Gz:"isInDocumentBounds"},function(){return 0!==(this.Q&64)},function(a){var b=0!==(this.Q&64);if(b!==a){this.Q^=64;var c=this.g;null!==c&&c.qc();this.h("isInDocumentBounds",b,a)}});
v.defineProperty(R,{iE:"isLayoutPositioned"},function(){return 0!==(this.Q&128)},function(a){var b=0!==(this.Q&128);b!==a&&(this.Q^=128,this.h("isLayoutPositioned",b,a),this.H(a?4:8))});v.defineProperty(R,{zl:"selectable"},function(){return 0!==(this.Q&256)},function(a){var b=0!==(this.Q&256);b!==a&&(this.Q^=256,this.h("selectable",b,a),this.Od())});
v.defineProperty(R,{jI:"reshapable"},function(){return 0!==(this.Q&512)},function(a){var b=0!==(this.Q&512);b!==a&&(this.Q^=512,this.h("reshapable",b,a),this.Od())});v.defineProperty(R,{kI:"resizable"},function(){return 0!==(this.Q&1024)},function(a){var b=0!==(this.Q&1024);b!==a&&(this.Q^=1024,this.h("resizable",b,a),this.Od())});v.defineProperty(R,{nI:"rotatable"},function(){return 0!==(this.Q&2048)},function(a){var b=0!==(this.Q&2048);b!==a&&(this.Q^=2048,this.h("rotatable",b,a),this.Od())});
v.defineProperty(R,{hb:"isSelected"},function(){return 0!==(this.Q&4096)},function(a){var b=0!==(this.Q&4096);if(b!==a){var c=this.g;if(!a||this.canSelect()&&!(null!==c&&c.selection.count>=c.NH)){this.Q^=4096;var d=!1;if(null!==c){d=c.tb;c.tb=!0;var e=c.selection;e.Oa();a?e.add(this):e.remove(this);e.freeze()}this.h("isSelected",b,a);this.Od();a=this.wI;null!==a&&a(this);null!==c&&(c.ue(),c.tb=d)}}});
v.defineProperty(R,{fh:"isHighlighted"},function(){return 0!==(this.Q&524288)},function(a){var b=0!==(this.Q&524288);if(b!==a){this.Q^=524288;var c=this.g;null!==c&&(c=c.Qm,c.Oa(),a?c.add(this):c.remove(this),c.freeze());this.h("isHighlighted",b,a);this.pa()}});v.defineProperty(R,{vl:"isShadowed"},function(){return 0!==(this.Q&8192)},function(a){var b=0!==(this.Q&8192);b!==a&&(this.Q^=8192,this.h("isShadowed",b,a),this.pa())});function fl(a){return 0!==(a.Q&32768)}
function $o(a,b){a.Q=b?a.Q|32768:a.Q&-32769}function Hm(a,b){a.Q=b?a.Q|65536:a.Q&-65537}function Wj(a){return 0!==(a.Q&131072)}R.prototype.yf=function(a){this.Q=a?this.Q|131072:this.Q&-131073};function gp(a,b){a.Q=b?a.Q|1048576:a.Q&-1048577}v.defineProperty(R,{aE:"isAnimated"},function(){return 0!==(this.Q&262144)},function(a){var b=0!==(this.Q&262144);b!==a&&(this.Q^=262144,this.h("isAnimated",b,a))});
v.defineProperty(R,{lA:"selectionObjectName"},function(){return this.ks},function(a){var b=this.ks;b!==a&&(this.ks=a,this.qm=null,this.h("selectionObjectName",b,a))});v.defineProperty(R,{vI:"selectionAdornmentTemplate"},function(){return this.hs},function(a){var b=this.hs;b!==a&&(this instanceof W&&(a.type=xi),this.hs=a,this.h("selectionAdornmentTemplate",b,a))});
v.u(R,{Wt:"selectionObject"},function(){if(null===this.qm){var a=this.lA;null!==a&&""!==a?(a=this.be(a),this.qm=null!==a?a:this):this instanceof W?(a=this.path,this.qm=null!==a?a:this):this.qm=this}return this.qm});v.defineProperty(R,{wI:"selectionChanged"},function(){return this.js},function(a){var b=this.js;b!==a&&(null!==a&&v.j(a,"function",R,"selectionChanged"),this.js=a,this.h("selectionChanged",b,a))});
v.defineProperty(R,{JE:"resizeAdornmentTemplate"},function(){return this.Vr},function(a){var b=this.Vr;b!==a&&(this.Vr=a,this.h("resizeAdornmentTemplate",b,a))});v.defineProperty(R,{LE:"resizeObjectName"},function(){return this.Xr},function(a){var b=this.Xr;b!==a&&(this.Xr=a,this.po=null,this.h("resizeObjectName",b,a))});v.u(R,{KE:"resizeObject"},function(){if(null===this.po){var a=this.LE;null!==a&&""!==a?(a=this.be(a),this.po=null!==a?a:this):this.po=this}return this.po});
v.defineProperty(R,{lI:"resizeCellSize"},function(){return this.Wr},function(a){var b=this.Wr;b.K(a)||(this.Wr=a=a.R(),this.h("resizeCellSize",b,a))});v.defineProperty(R,{oI:"rotateAdornmentTemplate"},function(){return this.Yr},function(a){var b=this.Yr;b!==a&&(this.Yr=a,this.h("rotateAdornmentTemplate",b,a))});v.defineProperty(R,{pI:"rotateObjectName"},function(){return this.Zr},function(a){var b=this.Zr;b!==a&&(this.Zr=a,this.qo=null,this.h("rotateObjectName",b,a))});
v.u(R,{NE:"rotateObject"},function(){if(null===this.qo){var a=this.pI;null!==a&&""!==a?(a=this.be(a),this.qo=null!==a?a:this):this.qo=this}return this.qo});v.defineProperty(R,{text:"text"},function(){return this.le},function(a){var b=this.le;b!==a&&(this.le=a,this.h("text",b,a))});
v.defineProperty(R,{Sa:"containingGroup"},function(){return this.uk},function(a){if(this.Pd()){var b=this.uk;if(b!==a){null===a||this!==a&&!a.Xh(this)||(this===a&&v.k("Cannot make a Group a member of itself: "+this.toString()),v.k("Cannot make a Group indirectly contain itself: "+this.toString()+" already contains "+a.toString()));this.H(ul);var c=this.g;null!==b?hp(b,this):this instanceof V&&null!==c&&c.Uk.remove(this);this.uk=a;null!==a?ip(a,this):this instanceof V&&null!==c&&c.Uk.add(this);this.H(ml);
if(null!==c){var d=this.data,e=c.ba;null!==d&&e instanceof Q&&e.lx(d,e.gb(null!==a?a.data:null))}d=this.uD;null!==d&&(e=!0,null!==c&&(e=c.Qa,c.Qa=!0),d(this,b,a),null!==c&&(c.Qa=e));if(this instanceof V)for(c=new J(R),Sg(c,this,!0,0,!0),c=c.i;c.next();)if(d=c.value,d instanceof S)for(d=d.te;d.next();)Nl(d.value);if(this instanceof S)for(d=this.te;d.next();)Nl(d.value);this.h("containingGroup",b,a);null!==a&&(b=a.layer,null!==b&&bk(b,-1,a))}}else v.k("cannot set the Part.containingGroup of a Link or Adornment")});
g=R.prototype;g.ql=function(){var a=this.Sa;null!==a&&(a.P(),null!==a.Gb&&a.Gb.P(),a.bf())};g.pa=function(){var a=this.g;null!==a&&!el(this)&&!fl(this)&&this.isVisible()&&this.mc.G()&&a.pa(ak(this,this.mc))};g.qt=function(a){a||(a=this.uk,null!==a&&ip(a,this))};g.rt=function(a){a||(a=this.uk,null!==a&&hp(a,this))};g.Lm=function(){var a=this.data;if(null!==a){var b=this.g;null!==b&&(b=b.ba,null!==b&&b.ex(a))}};
v.defineProperty(R,{uD:"containingGroupChanged"},function(){return this.gq},function(a){var b=this.gq;b!==a&&(null!==a&&v.j(a,"function",R,"containingGroupChanged"),this.gq=a,this.h("containingGroupChanged",b,a))});R.prototype.findSubGraphLevel=function(){return jp(this,this)};function jp(a,b){var c=b.Sa;return null!==c?1+jp(a,c):b instanceof S&&(c=b.ee,null!==c)?jp(a,c):0}R.prototype.findTopLevelPart=function(){return kp(this,this)};
function kp(a,b){var c=b.Sa;return null!==c?kp(a,c):b instanceof S&&(c=b.ee,null!==c)?kp(a,c):b}v.u(R,{gp:"isTopLevel"},function(){return null!==this.Sa||this instanceof S&&this.uf?!1:!0});R.prototype.isMemberOf=R.prototype.Xh=function(a){return a instanceof V?lp(this,this,a):!1};function lp(a,b,c){if(b===c||null===c)return!1;var d=b.Sa;return null===d||d!==c&&!lp(a,d,c)?b instanceof S&&(b=b.ee,null!==b)?lp(a,b,c):!1:!0}
R.prototype.findCommonContainingGroup=R.prototype.MG=function(a){if(null===a)return null;if(this===a)return this.Sa;for(var b=this;null!==b;)b instanceof V&&gp(b,!0),b=b.Sa;for(var c=null,b=a;null!==b;){if(0!==(b.Q&1048576)){c=b;break}b=b.Sa}for(b=this;null!==b;)b instanceof V&&gp(b,!1),b=b.Sa;return c};v.defineProperty(R,{CH:"layoutConditions"},function(){return this.fr},function(a){var b=this.fr;b!==a&&(this.fr=a,this.h("layoutConditions",b,a))});
R.prototype.canLayout=function(){if(!this.iE||!this.isVisible())return!1;var a=this.layer;return null!==a&&a.Gc||this instanceof S&&this.uf?!1:!0};R.prototype.invalidateLayout=R.prototype.H=function(a){void 0===a&&(a=16777215);var b;this.iE&&0!==(a&this.CH)?(b=this.layer,null!==b&&b.Gc||this instanceof S&&this.uf?b=!1:(b=this.g,b=null!==b&&b.la.ib?!1:!0)):b=!1;if(b)if(b=this.uk,null!==b){var c=b.Pb;null!==c?c.H():b.H(a)}else a=this.g,null!==a&&(c=a.Pb,null!==c&&c.H())};
function rl(a){if(!a.isVisible())return!1;a=a.layer;return null!==a&&a.Gc?!1:!0}v.defineProperty(R,{FD:"dragComputation"},function(){return this.zq},function(a){var b=this.zq;b!==a&&(null!==a&&v.j(a,"function",R,"dragComputation"),this.zq=a,this.h("dragComputation",b,a))});v.defineProperty(R,{AI:"shadowOffset"},function(){return this.sm},function(a){var b=this.sm;b.K(a)||(this.sm=a=a.R(),this.pa(),this.h("shadowOffset",b,a))});
v.defineProperty(R,{shadowColor:"shadowColor"},function(){return this.rm},function(a){var b=this.rm;b!==a&&(this.rm=a,this.pa(),this.h("shadowColor",b,a))});v.defineProperty(R,{shadowBlur:"shadowBlur"},function(){return this.og},function(a){var b=this.og;b!==a&&(this.og=a,this.pa(),this.h("shadowBlur",b,a))});function Tg(a){0===arguments.length?R.call(this,Si):R.call(this,a);this.Q&=-257;this.Ik="Adornment";this.Gb=this.Lg=this.Db=null}v.Ja(Tg,R);v.ea("Adornment",Tg);
Tg.prototype.toString=function(){var a=this.Mf;return"Adornment("+this.gc+")"+(null!==a?a.toString():"")};Tg.prototype.updateRelationshipsFromData=function(){};Tg.prototype.Wm=function(a){var b=this.Zb.Y,c=this.Zb;if(b instanceof W&&c instanceof X){var d=b.path,c=d.Ra;b.Wm(a);c=d.Ra;a=this.za.n;b=a.length;for(d=0;d<b;d++){var e=a[d];e.Rf&&e instanceof X&&(e.Ra=c)}}};v.u(Tg,{placeholder:"placeholder"},function(){return this.Gb});
v.defineProperty(Tg,{Zb:"adornedObject"},function(){return this.Db},function(a){var b=this.Mf,c=null;null!==a&&(c=a.Y);null===b||null!==a&&b===c||b.$i(this.gc);this.Db=a;null!==c&&c.Wk(this.gc,this)});v.u(Tg,{Mf:"adornedPart"},function(){var a=this.Db;return null!==a?a.Y:null});Tg.prototype.ul=function(){var a=this.Db;if(null===a)return!0;a=a.Y;return null===a||!el(a)};Tg.prototype.Pd=function(){return!1};v.u(Tg,{Sa:"containingGroup"},function(){return null});
Tg.prototype.an=function(a,b,c,d,e,f,h){if(a===Cf&&"elements"===b)if(e instanceof Mi){var k=e;null===this.Gb?this.Gb=k:this.Gb!==k&&v.k("Cannot insert a second Placeholder into the visual tree of an Adornment.")}else e instanceof D&&(k=e.ct(function(a){return a instanceof Mi}),k instanceof Mi&&(null===this.Gb?this.Gb=k:this.Gb!==k&&v.k("Cannot insert a second Placeholder into the visual tree of an Adornment.")));else a===Df&&"elements"===b&&null!==this.Gb&&(d===this.Gb?this.Gb=null:d instanceof D&&
this.Gb.tl(d)&&(this.Gb=null));R.prototype.an.call(this,a,b,c,d,e,f,h)};Tg.prototype.updateAdornments=function(){};Tg.prototype.Lm=function(){};function S(a){0===arguments.length?R.call(this,Si):R.call(this,a);this.fc=new I(W);this.$n=this.Lk=this.hr=this.gr=null;this.Zq=!0;this.Js=!1;this.Es=null;this.Zp=this.$q=!0;this.$p=id;this.Wd=this.sh=null;this.Nr=mp;this.Rj=!1}v.Ja(S,R);v.ea("Node",S);
S.prototype.cloneProtected=function(a){R.prototype.cloneProtected.call(this,a);a.gr=this.gr;a.hr=this.hr;a.Lk=this.Lk;a.Zq=this.Zq;a.Js=this.Js;a.Es=this.Es;a.$q=this.$q;a.Zp=this.Zp;a.$p=this.$p.R();a.Nr=this.Nr};S.prototype.Vh=function(a){R.prototype.Vh.call(this,a);a.bf();a.sh=this.sh;a.Wd=null};var np;S.DirectionDefault=np=v.p(S,"DirectionDefault",0);S.DirectionAbsolute=v.p(S,"DirectionAbsolute",1);var op;S.DirectionRotatedNode=op=v.p(S,"DirectionRotatedNode",2);var ym;
S.DirectionRotatedNodeOrthogonal=ym=v.p(S,"DirectionRotatedNodeOrthogonal",3);S.SpreadingNone=v.p(S,"SpreadingNone",10);var mp;S.SpreadingEvenly=mp=v.p(S,"SpreadingEvenly",11);var pp;S.SpreadingPacked=pp=v.p(S,"SpreadingPacked",12);function qp(a,b){null!==b&&(null===a.sh&&(a.sh=new J(lk)),a.sh.add(b))}
function rp(a,b,c,d){if(null===b||null===a.sh)return null;for(var e=a.sh.i;e.next();){var f=e.value;if(f.tp===a&&f.Jt===b&&f.Ww===c&&f.Xw===d||f.tp===b&&f.Jt===a&&f.Ww===d&&f.Xw===c)return f}return null}S.prototype.invalidateLinkBundle=function(a,b,c){if(void 0===b||null===b)b="";if(void 0===c||null===c)c="";a=rp(this,a,b,c);null!==a&&a.Rm()};
S.prototype.an=function(a,b,c,d,e,f,h){a===Cf&&"elements"===b?this.Wd=null:a===Df&&"elements"===b&&(null===this.g?this.Wd=null:d instanceof G&&Xn(this,d,function(a,b){Xm(a,b,!0)}));R.prototype.an.call(this,a,b,c,d,e,f,h)};S.prototype.invalidateConnectedLinks=S.prototype.bf=function(a){void 0===a&&(a=null);for(var b=this.te;b.next();){var c=b.value;null!==a&&a.contains(c)||(sp(this,c.bd),sp(this,c.Fd),c.ic())}};function sp(a,b){if(null!==b){b.km=null;var c=a.Sa;null===c||c.de||sp(c,c.jl(""))}}
S.prototype.ul=function(){return!0};v.defineProperty(S,{dI:"portSpreading"},function(){return this.Nr},function(a){var b=this.Nr;b!==a&&(this.Nr=a,this.h("portSpreading",b,a),a=this.g,null!==a&&a.la.ib||this.bf())});v.defineProperty(S,{mD:"avoidable"},function(){return this.Zp},function(a){var b=this.Zp;if(b!==a){this.Zp=a;var c=this.g;null!==c&&fm(c,this);this.h("avoidable",b,a)}});
v.defineProperty(S,{iG:"avoidableMargin"},function(){return this.$p},function(a){"number"===typeof a?a=new eb(a):v.A(a,eb,S,"avoidableMargin");var b=this.$p;if(!b.K(a)){this.$p=a=a.R();var c=this.g;null!==c&&fm(c,this);this.h("avoidableMargin",b,a)}});S.prototype.canAvoid=function(){return this.mD&&!this.uf};S.prototype.getAvoidableRect=function(a){a.set(this.U);a.fw(this.iG);return a};S.prototype.findVisibleNode=function(){for(var a=this;null!==a&&!a.isVisible();)a=a.Sa;return a};
S.prototype.isVisible=function(){if(!R.prototype.isVisible.call(this))return!1;var a=!0,b=!0,c=this.g;if(null!==c){a=c.Na;if(a.cf&&(a=a.gm.ja(this),null!==a&&a.ax))return!0;a=c.Qd;b=c.zA===hk}if(b){if(c=this.kl(),null!==c&&!c.md)return!1}else if(c=a?this.OG():this.PG(),0<c.count&&c.all(function(a){return!a.md}))return!1;c=this.ee;return null!==c?c.isVisible():!0};S.prototype.nd=function(a){R.prototype.nd.call(this,a);for(var b=this.te;b.next();)b.value.nd(a)};v.u(S,{te:"linksConnected"},function(){return this.fc.i});
S.prototype.findLinksConnected=S.prototype.KD=function(a){void 0===a&&(a=null);if(null===a)return this.fc.i;var b=new Aa(this.fc),c=this;b.Zm=function(b){return b.T===c&&b.wg===a||b.Z===c&&b.qh===a};return b};S.prototype.findLinksOutOf=S.prototype.Aw=function(a){void 0===a&&(a=null);var b=new Aa(this.fc),c=this;b.Zm=function(b){return b.T!==c?!1:null===a?!0:b.wg===a};return b};
S.prototype.findLinksInto=S.prototype.vg=function(a){void 0===a&&(a=null);var b=new Aa(this.fc),c=this;b.Zm=function(b){return b.Z!==c?!1:null===a?!0:b.qh===a};return b};
S.prototype.findNodesConnected=S.prototype.LD=function(a){void 0===a&&(a=null);for(var b=null,c=null,d=this.fc.i;d.next();){var e=d.value;if(e.T===this){if(null===a||e.wg===a)e=e.Z,null!==b?b.add(e):null!==c&&c!==e?(b=new J(S),b.add(c),b.add(e)):c=e}else e.Z!==this||null!==a&&e.qh!==a||(e=e.T,null!==b?b.add(e):null!==c&&c!==e?(b=new J(S),b.add(c),b.add(e)):c=e)}return null!==b?b.i:null!==c?new ya(c):xa};
S.prototype.findNodesOutOf=S.prototype.PG=function(a){void 0===a&&(a=null);for(var b=null,c=null,d=this.fc.i;d.next();){var e=d.value;e.T!==this||null!==a&&e.wg!==a||(e=e.Z,null!==b?b.add(e):null!==c&&c!==e?(b=new J(S),b.add(c),b.add(e)):c=e)}return null!==b?b.i:null!==c?new ya(c):xa};
S.prototype.findNodesInto=S.prototype.OG=function(a){void 0===a&&(a=null);for(var b=null,c=null,d=this.fc.i;d.next();){var e=d.value;e.Z!==this||null!==a&&e.qh!==a||(e=e.T,null!==b?b.add(e):null!==c&&c!==e?(b=new J(S),b.add(c),b.add(e)):c=e)}return null!==b?b.i:null!==c?new ya(c):xa};
S.prototype.findLinksBetween=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);var d=new Aa(this.fc),e=this;d.Zm=function(d){return(d.T!==e||d.Z!==a||null!==b&&d.wg!==b||null!==c&&d.qh!==c)&&(d.T!==a||d.Z!==e||null!==c&&d.wg!==c||null!==b&&d.qh!==b)?!1:!0};return d};S.prototype.findLinksTo=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);var d=new Aa(this.fc),e=this;d.Zm=function(d){return d.T!==e||d.Z!==a||null!==b&&d.wg!==b||null!==c&&d.qh===c?!1:!0};return d};
v.defineProperty(S,{EH:"linkConnected"},function(){return this.gr},function(a){var b=this.gr;b!==a&&(null!==a&&v.j(a,"function",S,"linkConnected"),this.gr=a,this.h("linkConnected",b,a))});v.defineProperty(S,{FH:"linkDisconnected"},function(){return this.hr},function(a){var b=this.hr;b!==a&&(null!==a&&v.j(a,"function",S,"linkDisconnected"),this.hr=a,this.h("linkDisconnected",b,a))});
v.defineProperty(S,{Pw:"linkValidation"},function(){return this.Lk},function(a){var b=this.Lk;b!==a&&(null!==a&&v.j(a,"function",S,"linkValidation"),this.Lk=a,this.h("linkValidation",b,a))});
function tp(a,b,c){sp(a,c);if(!a.fc.contains(b)){a.fc.add(b);var d=a.EH;if(null!==d){var e=!0,f=a.g;null!==f&&(e=f.Qa,f.Qa=!0);d(a,b,c);null!==f&&(f.Qa=e)}b.Hc&&(c=b.T,b=b.Z,null!==c&&null!==b&&c!==b&&(d=!0,f=a.g,null!==f&&(d=f.Qd),a=d?b:c,e=d?c:b,a.Rj||(a.Rj=e),!e.Wi||null!==f&&f.la.ib||(d?c===e&&(e.Wi=!1):b===e&&(e.Wi=!1))))}}
function up(a,b,c){sp(a,c);if(a.fc.remove(b)){var d=a.FH,e=a.g;if(null!==d){var f=!0;null!==e&&(f=e.Qa,e.Qa=!0);d(a,b,c);null!==e&&(e.Qa=f)}b.Hc&&(c=!0,null!==e&&(c=e.Qd),a=c?b.Z:b.T,b=c?b.T:b.Z,null!==a&&(a.Rj=!1),null===b||b.Wi||(0===b.fc.count?(b.Rj=null,null!==e&&e.la.ib||(b.Wi=!0)):Zl(b)))}}
function Zl(a){a.Rj=!1;if(0!==a.fc.count){var b=!0,c=a.g;if(null===c||!c.la.ib){null!==c&&(b=c.Qd);for(c=a.fc.i;c.next();){var d=c.value;if(d.Hc)if(b){if(d.T===a){a.Wi=!1;return}}else if(d.Z===a){a.Wi=!1;return}}a.Wi=!0}}}
S.prototype.updateRelationshipsFromData=function(){R.prototype.updateRelationshipsFromData.call(this);var a=this.data;if(null!==a){var b=this.g;if(null!==b){var c=b.ba;if(c instanceof Pf){var d=c.Pm(a),b=b.Ye(d),e=this.kl();b!==e&&(b=null!==e?c.gb(e.data):void 0,e=c.wp,"function"===typeof e?e(a,b):a[e]=b,c.oh(a,d))}}}};S.prototype.qt=function(a){R.prototype.qt.call(this,a);a||(Zl(this),a=this.$n,null!==a&&vp(a,this))};
S.prototype.rt=function(a){R.prototype.rt.call(this,a);a||(a=this.$n,null!==a&&null!==a.Ce&&(a.Ce.remove(this),a.P()))};S.prototype.Lm=function(){if(0<this.fc.count){var a=this.g;if(null!==a)for(var b=a.nb.ED,c=this.fc.copy().i;c.next();){var d=c.value;b?a.remove(d):(d.T===this&&(d.T=null),d.Z===this&&(d.Z=null))}}this.ee=null;R.prototype.Lm.call(this)};v.u(S,{uf:"isLinkLabel"},function(){return null!==this.$n});
v.defineProperty(S,{ee:"labeledLink"},function(){return this.$n},function(a){var b=this.$n;if(b!==a){var c=this.g,d=this.data;if(null!==b&&(null!==b.Ce&&(b.Ce.remove(this),b.P()),null!==c&&null!==d&&!c.la.ib)){var e=b.data,f=c.ba;if(null!==e&&f instanceof Q){var h=f.gb(d);void 0!==h&&f.EE(e,h)}}this.$n=a;null!==a&&(vp(a,this),null===c||null===d||c.la.ib||(e=a.data,f=c.ba,null!==e&&f instanceof Q&&(h=f.gb(d),void 0!==h&&f.Xy(e,h))));Qm(this);this.h("labeledLink",b,a)}});
S.prototype.findPort=S.prototype.jl=function(a){if(null===this.Wd){if(""===a&&!1===this.Ah)return this;Ym(this)}var b=this.Wd.ja(a);return null!==b||""!==a&&(b=this.Wd.ja(""),null!==b)?b:this};v.u(S,{port:"port"},function(){return this.jl("")});v.u(S,{ports:"ports"},function(){null===this.Wd&&Ym(this);return this.Wd.nE});function Ym(a){null===a.Wd?a.Wd=new na("string",G):a.Wd.clear();Xn(a,a,function(a,c){var d=c.Sd;null!==d&&a.Wd.add(d,c)});0===a.Wd.count&&a.Wd.add("",a)}
function Xm(a,b,c){var d=b.Sd;if(null!==d&&(null!==a.Wd&&a.Wd.remove(d),b=a.g,null!==b&&c)){c=null;for(d=a.KD(d);d.next();)a=d.value,null===c&&(c=v.jb()),c.push(a);if(null!==c){for(d=0;d<c.length;d++)a=c[d],b.remove(a);v.sa(c)}}}
S.prototype.isInTreeOf=function(a){if(null===a||a===this)return!1;var b=!0,c=this.g;null!==c&&(b=c.Qd);c=this;if(b)for(;c!==a;){for(var b=null,d=c.fc.i;d.next();){var e=d.value;if(e.Hc&&(b=e.T,b!==c&&b!==this))break}if(b===this||null===b||b===c)return!1;c=b}else for(;c!==a;){b=null;for(d=c.fc.i;d.next()&&(e=d.value,!e.Hc||(b=e.Z,b===c||b===this)););if(b===this||null===b||b===c)return!1;c=b}return!0};
S.prototype.findTreeRoot=function(){var a=!0,b=this.g;null!==b&&(a=b.Qd);b=this;if(a)for(;;){for(var a=null,c=b.fc.i;c.next();){var d=c.value;if(d.Hc&&(a=d.T,a!==b&&a!==this))break}if(a===this)return this;if(null===a||a===b)return b;b=a}else for(;;){a=null;for(c=b.fc.i;c.next()&&(d=c.value,!d.Hc||(a=d.Z,a===b||a===this)););if(a===this)return this;if(null===a||a===b)return b;b=a}};
S.prototype.findCommonTreeParent=function(a){if(null===a)return null;if(this===a)return this;for(var b=this;null!==b;)gp(b,!0),b=b.kl();for(var c=null,b=a;null!==b;){if(0!==(b.Q&1048576)){c=b;break}b=b.kl()}for(b=this;null!==b;)gp(b,!1),b=b.kl();return c};S.prototype.findTreeParentLink=S.prototype.Yo=function(){var a=!0,b=this.g;null!==b&&(a=b.Qd);b=this.fc.i;if(a)for(;b.next();){if(a=b.value,a.Hc&&a.T!==this)return a}else for(;b.next();)if(a=b.value,a.Hc&&a.Z!==this)return a;return null};
S.prototype.findTreeParentNode=S.prototype.kl=function(){var a=this.Rj;if(null===a)return null;if(a instanceof S)return a;var b=!0,a=this.g;null!==a&&(b=a.Qd);a=this.fc.i;if(b)for(;a.next();){if(b=a.value,b.Hc&&(b=b.T,b!==this))return this.Rj=b}else for(;a.next();)if(b=a.value,b.Hc&&(b=b.Z,b!==this))return this.Rj=b;return this.Rj=null};S.prototype.findTreeParentChain=function(){function a(b,d){if(null!==b){d.add(b);var e=b.Yo();null!==e&&(d.add(e),a(b.kl(),d))}}var b=new J(R);a(this,b);return b};
S.prototype.findTreeLevel=function(){return wp(this,this)};function wp(a,b){var c=b.kl();return null===c?0:1+wp(a,c)}S.prototype.findTreeChildrenLinks=S.prototype.Cw=function(){var a=!0,b=this.g;null!==b&&(a=b.Qd);if(a){var a=new Aa(this.fc),c=this;a.Zm=function(a){return a.Hc&&a.T===c?!0:!1}}else a=new Aa(this.fc),c=this,a.Zm=function(a){return a.Hc&&a.Z===c?!0:!1};return a};
S.prototype.findTreeChildrenNodes=S.prototype.ND=function(){var a=!0,b=this.g;null!==b&&(a=b.Qd);var c=b=null,d=this.fc.i;if(a)for(;d.next();)a=d.value,a.Hc&&a.T===this&&(a=a.Z,null!==b?b.add(a):null!==c&&c!==a?(b=new I(S),b.add(c),b.add(a)):c=a);else for(;d.next();)a=d.value,a.Hc&&a.Z===this&&(a=a.T,null!==b?b.add(a):null!==c&&c!==a?(b=new I(S),b.add(c),b.add(a)):c=a);return null!==b?b.i:null!==c?new ya(c):xa};
S.prototype.findTreeParts=function(a){void 0===a&&(a=Infinity);v.j(a,"number",S,"collapseTree:level");var b=new J(R);Sg(b,this,!1,a,!0);return b};S.prototype.collapseTree=S.prototype.collapseTree=function(a){void 0===a&&(a=1);v.Ue(a,S,"collapseTree:level");1>a&&(a=1);var b=this.g;if(null!==b&&!b.eh){b.eh=!0;var c=new J(S);c.add(this);xp(this,c,b.Qd,a,b.Na,this,b.zA===hk);b.eh=!1}};
function xp(a,b,c,d,e,f,h){if(1<d)for(var k=c?a.Aw():a.vg();k.next();){var l=k.value;l.Hc&&(l=l.yz(a),null===l||l===a||b.contains(l)||(b.add(l),xp(l,b,c,d-1,e,f,h)))}else yp(a,b,c,e,f,h)}function yp(a,b,c,d,e,f){for(var h=e===a?!0:a.md,k=c?a.Aw():a.vg();k.next();){var l=k.value;l.Hc&&(l=l.yz(a),null===l||l===a||b.contains(l)||(b.add(l),h&&(f&&Tj(d,l,e),l.ql(),l.nd(!1)),l.md&&(l.hu=l.md,yp(l,b,c,d,e,f))))}a.md=!1}
S.prototype.expandTree=S.prototype.expandTree=function(a){void 0===a&&(a=2);v.Ue(a,S,"expandTree:level");2>a&&(a=2);var b=this.g;if(null!==b&&!b.eh){b.eh=!0;var c=new J(S);c.add(this);zp(this,c,b.Qd,a,b.Na,this,b.zA===hk);b.eh=!1}};function zp(a,b,c,d,e,f,h){for(var k=f===a?!1:a.md,l=c?a.Aw():a.vg();l.next();){var m=l.value;m.Hc&&(k||m.Lf||m.ic(),m=m.yz(a),null!==m&&m!==a&&!b.contains(m)&&(b.add(m),k||(m.nd(!0),m.ql(),h&&Sj(e,m,f)),2<d||m.hu))&&(m.hu=!1,zp(m,b,c,d-1,e,f,h))}a.md=!0}
v.defineProperty(S,{md:"isTreeExpanded"},function(){return this.Zq},function(a){var b=this.Zq;if(b!==a){this.Zq=a;var c=this.g;this.h("isTreeExpanded",b,a);b=this.OI;if(null!==b){var d=!0;null!==c&&(d=c.Qa,c.Qa=!0);b(this);null!==c&&(c.Qa=d)}null!==c&&c.la.ib?this.nd(a):a?this.expandTree():this.collapseTree()}});v.defineProperty(S,{hu:"wasTreeExpanded"},function(){return this.Js},function(a){var b=this.Js;b!==a&&(this.Js=a,this.h("wasTreeExpanded",b,a))});
v.defineProperty(S,{OI:"treeExpandedChanged"},function(){return this.Es},function(a){var b=this.Es;b!==a&&(null!==a&&v.j(a,"function",S,"treeExpandedChanged"),this.Es=a,this.h("treeExpandedChanged",b,a))});v.defineProperty(S,{Wi:"isTreeLeaf"},function(){return this.$q},function(a){var b=this.$q;b!==a&&(this.$q=a,this.h("isTreeLeaf",b,a))});
function W(){R.call(this,xi);this.eg=null;this.zh="";this.qg=this.Hq=null;this.Rh="";this.Ds=null;this.Ur=this.Tr=this.Sr=!1;this.ar=!0;this.Sp=Ai;this.lq=0;this.oq=Ai;this.pq=NaN;this.mm=fn;this.ss=.5;this.Ce=null;this.Zc=(new I(z)).freeze();this.ro=this.Ee=null;this.Lf=!1;this.Hv=null;this.Ly=!1;this.Lg=this.Gn=this.Cn=this.ci=this.pi=this.Ra=null;this.Oy=new z;this.KC=this.IC=null;this.Nx=!1;this.ga=null}v.Ja(W,R);v.ea("Link",W);
W.prototype.cloneProtected=function(a){R.prototype.cloneProtected.call(this,a);a.zh=this.zh;a.Hq=this.Hq;a.Rh=this.Rh;a.Ds=this.Ds;a.Sr=this.Sr;a.Tr=this.Tr;a.Ur=this.Ur;a.ar=this.ar;a.Sp=this.Sp;a.lq=this.lq;a.oq=this.oq;a.pq=this.pq;a.mm=this.mm;a.ss=this.ss;null!==this.ga&&(a.ga=this.ga.copy())};W.prototype.Vh=function(a){R.prototype.Vh.call(this,a);this.zh=a.zh;this.Rh=a.Rh;a.Ee=null;a.ic();a.ci=this.ci};var fn;W.Normal=fn=v.p(W,"Normal",1);W.Orthogonal=v.p(W,"Orthogonal",2);
W.AvoidsNodes=v.p(W,"AvoidsNodes",6);var Ap;W.AvoidsNodesStraight=Ap=v.p(W,"AvoidsNodesStraight",7);var Ai;W.None=Ai=v.p(W,"None",0);var Hi;W.Bezier=Hi=v.p(W,"Bezier",9);var zi;W.JumpGap=zi=v.p(W,"JumpGap",10);var yi;W.JumpOver=yi=v.p(W,"JumpOver",11);var cn;W.End=cn=v.p(W,"End",17);var dn;W.Scale=dn=v.p(W,"Scale",18);var en;W.Stretch=en=v.p(W,"Stretch",19);var qo;W.OrientAlong=qo=v.p(W,"OrientAlong",21);var Bp;W.OrientPlus90=Bp=v.p(W,"OrientPlus90",22);var Cp;
W.OrientMinus90=Cp=v.p(W,"OrientMinus90",23);var Dp;W.OrientOpposite=Dp=v.p(W,"OrientOpposite",24);var Ep;W.OrientUpright=Ep=v.p(W,"OrientUpright",25);var Fp;W.OrientPlus90Upright=Fp=v.p(W,"OrientPlus90Upright",26);var Gp;W.OrientMinus90Upright=Gp=v.p(W,"OrientMinus90Upright",27);var Hp;W.OrientUpright45=Hp=v.p(W,"OrientUpright45",28);W.prototype.Ke=function(){null===this.ga&&(this.ga=new xm)};
W.prototype.ul=function(){var a=this.T;if(null!==a){var b=a.findVisibleNode();null!==b&&(a=b);if(el(a)||fl(a))return!1}a=this.Z;return null!==a&&(b=a.findVisibleNode(),null!==b&&(a=b),el(a)||fl(a))?!1:!0};W.prototype.pA=function(){return!1};W.prototype.Pd=function(){return!1};
W.prototype.computeAngle=function(a,b,c){a=0;switch(b){default:case Ai:a=0;break;case qo:a=c;break;case Bp:a=c+90;break;case Cp:a=c-90;break;case Dp:a=c+180;break;case Ep:a=ne(c);90<a&&270>a&&(a-=180);break;case Fp:a=ne(c+90);90<a&&270>a&&(a-=180);break;case Gp:a=ne(c-90);90<a&&270>a&&(a-=180);break;case Hp:a=ne(c);if(45<a&&135>a||225<a&&315>a)return 0;90<a&&270>a&&(a-=180)}return ne(a)};
v.defineProperty(W,{T:"fromNode"},function(){return this.eg},function(a){var b=this.eg;if(b!==a){var c=this.bd;null!==b&&(this.qg!==b&&up(b,this,c),Ip(this),this.H(ul));this.eg=a;null!==a&&this.nd(a.isVisible());this.pi=null;this.ic();var d=this.g;if(null!==d){var e=this.data,f=d.ba;if(null!==e)if(f instanceof Q){var h=null!==a?a.data:null;f.kx(e,f.gb(h))}else f instanceof Pf&&(h=null!==a?a.data:null,d.Qd?f.oh(e,f.gb(h)):(null!==b&&f.oh(b.data,void 0),f.oh(h,f.gb(null!==this.qg?this.qg.data:null))))}e=
this.bd;f=this.QD;null!==f&&(h=!0,null!==d&&(h=d.Qa,d.Qa=!0),f(this,c,e),null!==d&&(d.Qa=h));null!==a&&(this.qg!==a&&tp(a,this,e),Jp(this),this.H(ml));this.h("fromNode",b,a);Nl(this)}});
v.defineProperty(W,{wg:"fromPortId"},function(){return this.zh},function(a){var b=this.zh;if(b!==a){var c=this.bd;null!==c&&sp(this.T,c);Ip(this);this.zh=a;var d=this.bd;null!==d&&sp(this.T,d);var e=this.g;if(null!==e){var f=this.data,h=e.ba;null!==f&&h instanceof Q&&h.nA(f,a)}c!==d&&(this.pi=null,this.ic(),f=this.QD,null!==f&&(h=!0,null!==e&&(h=e.Qa,e.Qa=!0),f(this,c,d),null!==e&&(e.Qa=h)));Jp(this);this.h("fromPortId",b,a)}});v.u(W,{bd:"fromPort"},function(){var a=this.eg;return null===a?null:a.jl(this.zh)});
v.defineProperty(W,{QD:"fromPortChanged"},function(){return this.Hq},function(a){var b=this.Hq;b!==a&&(null!==a&&v.j(a,"function",W,"fromPortChanged"),this.Hq=a,this.h("fromPortChanged",b,a))});
v.defineProperty(W,{Z:"toNode"},function(){return this.qg},function(a){var b=this.qg;if(b!==a){var c=this.Fd;null!==b&&(this.eg!==b&&up(b,this,c),Ip(this),this.H(ul));this.qg=a;null!==a&&this.nd(a.isVisible());this.pi=null;this.ic();var d=this.g;if(null!==d){var e=this.data,f=d.ba;if(null!==e)if(f instanceof Q){var h=null!==a?a.data:null;f.ox(e,f.gb(h))}else f instanceof Pf&&(h=null!==a?a.data:null,d.Qd?(null!==b&&f.oh(b.data,void 0),f.oh(h,f.gb(null!==this.eg?this.eg.data:null))):f.oh(e,f.gb(h)))}e=
this.Fd;f=this.fF;null!==f&&(h=!0,null!==d&&(h=d.Qa,d.Qa=!0),f(this,c,e),null!==d&&(d.Qa=h));null!==a&&(this.eg!==a&&tp(a,this,e),Jp(this),this.H(ml));this.h("toNode",b,a);Nl(this)}});
v.defineProperty(W,{qh:"toPortId"},function(){return this.Rh},function(a){var b=this.Rh;if(b!==a){var c=this.Fd;null!==c&&sp(this.Z,c);Ip(this);this.Rh=a;var d=this.Fd;null!==d&&sp(this.Z,d);var e=this.g;if(null!==e){var f=this.data,h=e.ba;null!==f&&h instanceof Q&&h.qA(f,a)}c!==d&&(this.pi=null,this.ic(),f=this.fF,null!==f&&(h=!0,null!==e&&(h=e.Qa,e.Qa=!0),f(this,c,d),null!==e&&(e.Qa=h)));Jp(this);this.h("toPortId",b,a)}});v.u(W,{Fd:"toPort"},function(){var a=this.qg;return null===a?null:a.jl(this.Rh)});
v.defineProperty(W,{fF:"toPortChanged"},function(){return this.Ds},function(a){var b=this.Ds;b!==a&&(null!==a&&v.j(a,"function",W,"toPortChanged"),this.Ds=a,this.h("toPortChanged",b,a))});v.defineProperty(W,{xb:"fromSpot"},function(){return null!==this.ga?this.ga.oj:fc},function(a){this.Ke();var b=this.ga.oj;b.K(a)||(a=a.R(),this.ga.oj=a,this.h("fromSpot",b,a),this.ic())});
v.defineProperty(W,{ll:"fromEndSegmentLength"},function(){return null!==this.ga?this.ga.mj:NaN},function(a){this.Ke();var b=this.ga.mj;b!==a&&(0>a&&v.va(a,">= 0",W,"fromEndSegmentLength"),this.ga.mj=a,this.h("fromEndSegmentLength",b,a),this.ic())});v.defineProperty(W,{kt:"fromEndSegmentDirection"},function(){return null!==this.ga?this.ga.lj:np},function(a){this.Ke();var b=this.ga.lj;b!==a&&(this.ga.lj=a,this.h("fromEndSegmentDirection",b,a),this.ic())});
v.defineProperty(W,{lt:"fromShortLength"},function(){return null!==this.ga?this.ga.nj:NaN},function(a){this.Ke();var b=this.ga.nj;b!==a&&(this.ga.nj=a,this.h("fromShortLength",b,a),this.ic())});v.defineProperty(W,{yb:"toSpot"},function(){return null!==this.ga?this.ga.Qj:fc},function(a){this.Ke();var b=this.ga.Qj;b.K(a)||(a=a.R(),this.ga.Qj=a,this.h("toSpot",b,a),this.ic())});
v.defineProperty(W,{Fl:"toEndSegmentLength"},function(){return null!==this.ga?this.ga.Oj:NaN},function(a){this.Ke();var b=this.ga.Oj;b!==a&&(0>a&&v.va(a,">= 0",W,"toEndSegmentLength"),this.ga.Oj=a,this.h("toEndSegmentLength",b,a),this.ic())});v.defineProperty(W,{eu:"toEndSegmentDirection"},function(){return null!==this.ga?this.ga.Nj:np},function(a){this.Ke();var b=this.ga.Nj;b!==a&&(this.ga.Nj=a,this.h("toEndSegmentDirection",b,a),this.ic())});
v.defineProperty(W,{fu:"toShortLength"},function(){return null!==this.ga?this.ga.Pj:NaN},function(a){this.Ke();var b=this.ga.Pj;b!==a&&(this.ga.Pj=a,this.h("toShortLength",b,a),this.ic())});function Nl(a){var b=a.T,c=a.Z;null!==b?null!==c?Kp(a,b.MG(c)):Kp(a,null):Kp(a,null)}function Kp(a,b){var c=a.uk;if(c!==b){null!==c&&hp(c,a);a.uk=b;null!==b&&ip(b,a);var d=a.uD;if(null!==d){var e=!0,f=a.g;null!==f&&(e=f.Qa,f.Qa=!0);d(a,c,b);null!==f&&(f.Qa=e)}!a.Lf||a.IC!==c&&a.KC!==c||a.ic()}}
W.prototype.ql=function(){var a=this.Sa;null!==a&&this.T!==a&&this.Z!==a&&a.qw&&R.prototype.ql.call(this)};W.prototype.getOtherNode=W.prototype.yz=function(a){var b=this.T;return a===b?this.Z:b};W.prototype.getOtherPort=function(a){var b=this.bd;return a===b?this.Fd:b};v.u(W,{UJ:"isLabeledLink"},function(){return null===this.Ce?!1:0<this.Ce.count});v.u(W,{Sf:"labelNodes"},function(){return null===this.Ce?xa:this.Ce.i});function vp(a,b){null===a.Ce&&(a.Ce=new J(S));a.Ce.add(b);a.P()}
W.prototype.qt=function(a){R.prototype.qt.call(this,a);Lp(this)&&Bi(this,this.U);a||(a=this.eg,null!==a&&tp(a,this,this.bd),a=this.qg,null!==a&&tp(a,this,this.Fd),Jp(this))};W.prototype.rt=function(a){R.prototype.rt.call(this,a);Lp(this)&&Bi(this,this.U);a||(a=this.eg,null!==a&&up(a,this,this.bd),a=this.qg,null!==a&&up(a,this,this.Fd),Ip(this))};
W.prototype.Lm=function(){this.Lf=!0;if(null!==this.Ce){var a=this.g;if(null!==a)for(var b=this.Ce.copy().i;b.next();)a.remove(b.value)}b=this.data;null!==b&&(a=this.g,null!==a&&(a=a.ba,a instanceof Q?a.bx(b):a instanceof Pf&&a.oh(b,void 0)))};
W.prototype.updateRelationshipsFromData=function(){var a=this.data;if(null!==a){var b=this.g;if(null!==b){var c=b.ba;if(c instanceof Q){var d=c.ol(a),e=b.Ye(d),f=this.T;e!==f&&(e=null!==f?c.gb(f.data):void 0,f=c.jp,"function"===typeof f?f(a,e):a[f]=e,c.kx(a,d));d=c.pl(a);e=b.Ye(d);f=this.Z;e!==f&&(e=null!==f?c.gb(f.data):void 0,f=c.lp,"function"===typeof f?f(a,e):a[f]=e,c.ox(a,d));var h=c.$j(a);if(0<h.length||0<this.Sf.count){if(1===h.length&&1===this.Sf.count&&(b=h[0],d=this.Sf.first(),c.gb(d.data)===
b))return;var d=(new J).Tc(h),k=new J;this.Sf.each(function(a){null!==a.data&&(a=c.gb(a.data),void 0!==a&&k.add(a))});b=k.copy();b.fA(d);d=d.copy();d.fA(k);if(0<b.count||0<d.count)h.length=0,k.each(function(a){h.push(a)}),b.each(function(b){c.EE(a,b)}),d.each(function(b){c.Xy(a,b)})}}}}};
W.prototype.move=function(a){var b=this.position,c=b.x;isNaN(c)&&(c=0);b=b.y;isNaN(b)&&(b=0);c=a.x-c;b=a.y-b;R.prototype.move.call(this,a);this.wl(c,b);for(a=this.Sf;a.next();){var d=a.value,e=d.position;d.moveTo(e.x+c,e.y+b)}};v.defineProperty(W,{gI:"relinkableFrom"},function(){return this.Sr},function(a){var b=this.Sr;b!==a&&(this.Sr=a,this.h("relinkableFrom",b,a),this.Od())});
v.defineProperty(W,{hI:"relinkableTo"},function(){return this.Tr},function(a){var b=this.Tr;b!==a&&(this.Tr=a,this.h("relinkableTo",b,a),this.Od())});W.prototype.canRelinkFrom=function(){if(!this.gI)return!1;var a=this.layer;if(null===a)return!0;if(!a.Am)return!1;a=a.g;return null===a||a.Am?!0:!1};W.prototype.canRelinkTo=function(){if(!this.hI)return!1;var a=this.layer;if(null===a)return!0;if(!a.Am)return!1;a=a.g;return null===a||a.Am?!0:!1};
v.defineProperty(W,{Rt:"resegmentable"},function(){return this.Ur},function(a){var b=this.Ur;b!==a&&(this.Ur=a,this.h("resegmentable",b,a),this.Od())});v.defineProperty(W,{Hc:"isTreeLink"},function(){return this.ar},function(a){var b=this.ar;b!==a&&(this.ar=a,this.h("isTreeLink",b,a),null!==this.T&&Zl(this.T),null!==this.Z&&Zl(this.Z))});v.u(W,{path:"path"},function(){var a=this.Xe();return a instanceof X?a:null});
v.u(W,{bn:"routeBounds"},function(){this.hn();var a=new B;var b=Infinity,c=Infinity,d=this.oa;if(0===d)a.m(NaN,NaN,0,0);else{if(1===d)d=this.l(0),b=Math.min(d.x,b),c=Math.min(d.y,c),a.m(d.x,d.y,0,0);else if(2===d){var e=this.l(0),f=this.l(1),b=Math.min(e.x,f.x),c=Math.min(e.y,f.y);a.m(e.x,e.y,0,0);a.dj(f)}else if(this.computeCurve()===Hi&&3<=d&&!this.jc)if(e=this.l(0),b=e.x,c=e.y,a.m(b,c,0,0),3===d)d=this.l(1),b=Math.min(d.x,b),c=Math.min(d.y,c),f=this.l(2),b=Math.min(f.x,b),c=Math.min(f.y,c),Rd(e.x,
e.y,d.x,d.y,d.x,d.y,f.x,f.y,.5,a);else for(var h=3;h<d;h+=3){var k=this.l(h-2);h+3>=d&&(h=d-1);var l=this.l(h-1),f=this.l(h);Rd(e.x,e.y,k.x,k.y,l.x,l.y,f.x,f.y,.5,a);b=Math.min(f.x,b);c=Math.min(f.y,c);e=f}else for(e=this.l(0),f=this.l(1),b=Math.min(e.x,f.x),c=Math.min(e.y,f.y),a.m(e.x,e.y,0,0),a.dj(f),h=2;h<d;h++)e=this.l(h),b=Math.min(e.x,b),c=Math.min(e.y,c),a.dj(e);this.Oy.m(b-a.x,c-a.y)}return this.Hv=a});v.u(W,{tE:"midPoint"},function(){this.hn();return this.computeMidPoint(new z)});
W.prototype.computeMidPoint=function(a){var b=this.oa;if(0===b)return a.assign(Bd),a;if(1===b)return a.assign(this.l(0)),a;if(2===b){var c=this.l(0),d=this.l(1);a.m((c.x+d.x)/2,(c.y+d.y)/2);return a}if(this.computeCurve()===Hi&&3<=b&&!this.jc){if(3===b)return this.l(1);var c=(b-1)/3|0,e=3*(c/2|0);if(1===c%2){var c=this.l(e),d=this.l(e+1),f=this.l(e+2),e=this.l(e+3),b=d.x,h=d.y,d=f.x,k=f.y,f=(b+d)/2,l=(h+k)/2,h=((c.y+h)/2+l)/2,k=(l+(k+e.y)/2)/2;a.x=(((c.x+b)/2+f)/2+(f+(d+e.x)/2)/2)/2;a.y=(h+k)/2}else a.assign(this.l(e));
return a}e=0;f=v.jb();for(h=0;h<b-1;h++)c=0,c=this.l(h),d=this.l(h+1),Ec(c.x,d.x)?(c=d.y-c.y,0>c&&(c=-c)):Ec(c.y,d.y)?(c=d.x-c.x,0>c&&(c=-c)):c=Math.sqrt(c.rf(d)),f.push(c),e+=c;for(d=h=c=0;c<e/2&&h<b;){d=f[h];if(c+d>e/2)break;c+=d;h++}v.sa(f);b=this.l(h);f=this.l(h+1);b.x===f.x?b.y>f.y?a.m(b.x,b.y-(e/2-c)):a.m(b.x,b.y+(e/2-c)):b.y===f.y?b.x>f.x?a.m(b.x-(e/2-c),b.y):a.m(b.x+(e/2-c),b.y):(c=(e/2-c)/d,a.m(b.x+c*(f.x-b.x),b.y+c*(f.y-b.y)));return a};v.u(W,{sE:"midAngle"},function(){this.hn();return this.computeMidAngle()});
W.prototype.computeMidAngle=function(){var a=this.oa;if(2>a)return NaN;if(this.computeCurve()===Hi&&4<=a&&!this.jc){var b=(a-1)/3|0,c=3*(b/2|0);if(1===b%2){var c=Math.floor(c),b=this.l(c),d=this.l(c+1),a=this.l(c+2),c=this.l(c+3),e=d.x,d=d.y,f=a.x,a=a.y,h=(e+f)/2,k=(d+a)/2;return Wa(((b.x+e)/2+h)/2,((b.y+d)/2+k)/2,(h+(f+c.x)/2)/2,(k+(a+c.y)/2)/2)}if(0<c&&c+1<a)return b=this.l(c-1),d=this.l(c+1),b.xc(d)}c=a/2|0;if(0===a%2)return b=this.l(c-1),d=this.l(c),4<=a&&b.Fc(d)&&(b=this.l(c-2),a=this.l(c+1),
c=b.rf(d),e=d.rf(a),!(c>e+10))?e>c+10?d.xc(a):b.xc(a):b.xc(d);b=this.l(c-1);d=this.l(c);a=this.l(c+1);c=b.rf(d);e=d.rf(a);return c>e+10?b.xc(d):e>c+10?d.xc(a):b.xc(a)};
v.defineProperty(W,{points:"points"},function(){return this.Zc},function(a){var b=this.Zc;if(b!==a){var c=null;if(Array.isArray(a)){var d=0===a.length%2;if(d)for(var e=0;e<a.length;e++)if("number"!==typeof a[e]||isNaN(a[e])){d=!1;break}if(d)for(c=new I(z),d=0;d<a.length/2;d++)e=(new z(a[2*d],a[2*d+1])).freeze(),c.add(e);else{for(var f=!0,d=0;d<a.length;d++)if(e=a[d],!v.Ta(e)||"number"!==typeof e.x||isNaN(e.x)||"number"!==typeof e.y||isNaN(e.y)){f=!1;break}if(f)for(c=new I(z),d=0;d<a.length;d++)e=
a[d],c.add((new z(e.x,e.y)).freeze());else v.k("Link.points array must contain only an even number of numbers or objects with x and y properties, not: "+a)}}else if(a instanceof I)for(c=a.copy(),a=c.i;a.next();)a.value.freeze();else v.k("Link.points value is not an instance of List or Array: "+a);c.freeze();this.Zc=c;this.re();this.P();Mp(this);a=this.g;null!==a&&(a.Tm||a.la.ib||a.Qw.add(this),a.Na.pc&&(this.ro=c));this.h("points",b,c)}});v.u(W,{oa:"pointsCount"},function(){return this.Zc.count});
W.prototype.getPoint=W.prototype.l=function(a){return this.Zc.n[a]};W.prototype.setPoint=W.prototype.zf=function(a,b){null===this.Ee&&v.k("Call Link.startRoute before modifying the points of the route.");this.Zc.Hg(a,b)};W.prototype.setPointAt=W.prototype.V=function(a,b,c){null===this.Ee&&v.k("Call Link.startRoute before modifying the points of the route.");this.Zc.Hg(a,new z(b,c))};
W.prototype.insertPoint=function(a,b){null===this.Ee&&v.k("Call Link.startRoute before modifying the points of the route.");this.Zc.Ad(a,b)};W.prototype.insertPointAt=W.prototype.w=function(a,b,c){null===this.Ee&&v.k("Call Link.startRoute before modifying the points of the route.");this.Zc.Ad(a,new z(b,c))};W.prototype.addPoint=W.prototype.Sh=function(a){null===this.Ee&&v.k("Call Link.startRoute before modifying the points of the route.");this.Zc.add(a)};
W.prototype.addPointAt=W.prototype.Yk=function(a,b){null===this.Ee&&v.k("Call Link.startRoute before modifying the points of the route.");this.Zc.add(new z(a,b))};W.prototype.removePoint=W.prototype.FE=function(a){null===this.Ee&&v.k("Call Link.startRoute before modifying the points of the route.");this.Zc.Vc(a)};W.prototype.clearPoints=W.prototype.Po=function(){null===this.Ee&&v.k("Call Link.startRoute before modifying the points of the route.");this.Zc.clear()};
W.prototype.movePoints=W.prototype.wl=function(a,b){for(var c=new I(z),d=this.Zc.i;d.next();){var e=d.value;c.add((new z(e.x+a,e.y+b)).freeze())}c.freeze();d=this.Zc;this.Zc=c;this.P();Mp(this);e=this.g;null!==e&&e.Na.pc&&(this.ro=c);this.h("points",d,c)};W.prototype.startRoute=W.prototype.Bl=function(){null===this.Ee&&(this.Ee=this.Zc,this.Zc=this.Zc.copy())};
W.prototype.commitRoute=W.prototype.Ni=function(){if(null!==this.Ee){for(var a=this.Ee,b=this.Zc,c=Infinity,d=Infinity,e=a.n,f=e.length,h=0;h<f;h++)var k=e[h],c=Math.min(k.x,c),d=Math.min(k.y,d);for(var l=Infinity,m=Infinity,n=b.n,p=n.length,h=0;h<p;h++)k=n[h],l=Math.min(k.x,l),m=Math.min(k.y,m),k.freeze();b.freeze();if(p===f)for(h=0;h<p;h++){if(f=e[h],k=n[h],f.x-c!==k.x-l||f.y-d!==k.y-m){this.P();this.re();break}}else this.P(),this.re();this.Ee=null;c=this.g;null!==c&&c.Na.pc&&(this.ro=b);Mp(this);
this.h("points",a,b)}};W.prototype.rollbackRoute=W.prototype.mI=function(){null!==this.Ee&&(this.Zc=this.Ee,this.Ee=null)};function Mp(a){0===a.Zc.count?a.Lf=!1:(a.Lf=!0,a.Cn=a.l(0).copy(),a.Gn=a.l(a.oa-1).copy(),Np(a,!1))}
W.prototype.invalidateRoute=W.prototype.ic=function(){if(!this.Ly){var a=this.g;if(!a||!(a.Qw.contains(this)||a.la.ib||a.Na.wH&&!a.Na.cf))if(a=this.path,null!==a){if(this.Lf){var b=this.bd;null!==b&&(b=b.km,null!==b&&b.Rm());b=this.Fd;null!==b&&(b=b.km,null!==b&&b.Rm())}this.Lf=!1;this.P();a.P()}}};v.defineProperty(W,{Kp:"suspendsRouting"},function(){return this.Ly},function(a){this.Ly=a});
W.prototype.updateRoute=W.prototype.hn=function(){if(!this.Lf&&!this.Nx){var a=!0;try{this.Nx=!0,this.Bl(),a=this.computePoints()}finally{this.Nx=!1,a?this.Ni():this.mI()}}};
W.prototype.computePoints=function(){var a=this.g;if(null===a)return!1;var b=this.T,c=null;null===b?(a.nm||(a.Iv=new X,a.Iv.xa=md,a.Iv.lb=0,a.nm=new S,a.nm.add(a.Iv),a.nm.sf()),this.Cn&&(a.nm.position=a.nm.location=this.Cn,a.nm.sf(),b=a.nm,c=a.Iv)):c=this.bd;if(null!==c&&!b.isVisible()){var d=b.findVisibleNode();null!==d&&d!==b?(b=d,c=d.jl("")):b=d}this.IC=b;if(null===b||!b.location.G())return!1;for(;!(null===c||c.U.G()&&c.Xi());)c=c.S;if(null===c)return!1;var d=this.Z,e=null;null===d?(a.om||(a.Jv=
new X,a.Jv.xa=md,a.Jv.lb=0,a.om=new S,a.om.add(a.Jv),a.om.sf()),this.Gn&&(a.om.position=a.om.location=this.Gn,a.om.sf(),d=a.om,e=a.Jv)):e=this.Fd;null===e||d.isVisible()||(a=d.findVisibleNode(),null!==a&&a!==d?(d=a,e=a.jl("")):d=a);this.KC=d;if(null===d||!d.location.G())return!1;for(;!(null===e||e.U.G()&&e.Xi());)e=e.S;if(null===e)return!1;var a=this.oa,f=Op(this,c),h=Pp(this,e),k=c===e&&null!==c,l=this.jc,m=this.Ie===Hi;this.pi=k&&!l?m=!0:!1;var n=this.Qs===Ai||k;if(l||f!==sb||h!==sb||k){m=this.rl;
n&&(l&&m||k)&&this.Po();var p=k?this.computeCurviness():0,q=this.getLinkPoint(b,c,f,!0,l,d,e),r=0,s=0,t=0;if(l||f!==sb||k){var u=this.computeEndSegmentLength(b,c,f,!0),t=this.getLinkDirection(b,c,q,f,!0,l,d,e);k&&(t-=l?90:30,0>p&&(t-=180));0>t?t+=360:360<=t&&(t-=360);k&&(u+=Math.abs(p));0===t?r=u:90===t?s=u:180===t?r=-u:270===t?s=-u:(r=u*Math.cos(t*Math.PI/180),s=u*Math.sin(t*Math.PI/180));if(f.se()&&k){var y=c.Xa(Ab,v.L()),w=v.Mb(y.x+1E3*r,y.y+1E3*s);this.getLinkPointFromPoint(b,c,y,w,!0,q);v.v(y);
v.v(w)}}var u=this.getLinkPoint(d,e,h,!1,l,b,c),x=0,A=0,H=0;if(l||h!==sb||k)y=this.computeEndSegmentLength(d,e,h,!1),H=this.getLinkDirection(d,e,u,h,!1,l,b,c),k&&(H+=l?0:30,0>p&&(H+=180)),0>H?H+=360:360<=H&&(H-=360),k&&(y+=Math.abs(p)),0===H?x=y:90===H?A=y:180===H?x=-y:270===H?A=-y:(x=y*Math.cos(H*Math.PI/180),A=y*Math.sin(H*Math.PI/180)),h.se()&&k&&(y=e.Xa(Ab,v.L()),w=v.Mb(y.x+1E3*x,y.y+1E3*A),this.getLinkPointFromPoint(d,e,y,w,!1,u),v.v(y),v.v(w));e=q;if(l||f!==sb||k)e=new z(q.x+r,q.y+s);c=u;if(l||
h!==sb||k)c=new z(u.x+x,u.y+A);!n&&!l&&f===sb&&3<a&&this.adjustPoints(0,q,a-2,c)?this.zf(a-1,u):!n&&!l&&h===sb&&3<a&&this.adjustPoints(1,e,a-1,u)?this.zf(0,q):!n&&!l&&4<a&&this.adjustPoints(1,e,a-2,c)?(this.zf(0,q),this.zf(a-1,u)):!n&&l&&6<=a&&!m&&this.adjustPoints(1,e,a-2,c)?(this.zf(0,q),this.zf(a-1,u)):(this.Po(),this.Sh(q),(l||f!==sb||k)&&this.Sh(e),l&&this.addOrthoPoints(e,t,c,H,b,d),(l||h!==sb||k)&&this.Sh(c),this.Sh(u))}else f=!1,!n&&3<=a&&(n=this.getLinkPoint(b,c,sb,!0,!1,d,e),h=this.getLinkPoint(d,
e,sb,!1,!1,b,c),f=this.adjustPoints(0,n,a-1,h))&&(n=this.getLinkPoint(b,c,sb,!0,!1,d,e),h=this.getLinkPoint(d,e,sb,!1,!1,b,c),this.adjustPoints(0,n,a-1,h)),f||(this.Po(),m?(a=this.getLinkPoint(b,c,sb,!0,!1,d,e),n=this.getLinkPoint(d,e,sb,!1,!1,b,c),f=n.x-a.x,h=n.y-a.y,k=this.computeCurviness(),m=l=0,q=a.x+f/3,t=a.y+h/3,r=q,s=t,K(h,0)?s=0<f?s-k:s+k:(l=-f/h,m=Math.sqrt(k*k/(l*l+1)),0>k&&(m=-m),r=(0>h?-1:1)*m+q,s=l*(r-q)+t),q=a.x+2*f/3,t=a.y+2*h/3,u=q,x=t,K(h,0)?x=0<f?x-k:x+k:(u=(0>h?-1:1)*m+q,x=l*(u-
q)+t),this.Po(),this.Sh(a),this.Yk(r,s),this.Yk(u,x),this.Sh(n),this.zf(0,this.getLinkPoint(b,c,sb,!0,!1,d,e)),this.zf(3,this.getLinkPoint(d,e,sb,!1,!1,b,c))):(a=d,d=this.getLinkPoint(b,c,sb,!0,!1,a,e),e=this.getLinkPoint(a,e,sb,!1,!1,b,c),this.hasCurviness()?(h=e.x-d.x,b=e.y-d.y,c=this.computeCurviness(),a=d.x+h/2,n=d.y+b/2,f=a,k=n,K(b,0)?k=0<h?k-c:k+c:(h=-h/b,f=Math.sqrt(c*c/(h*h+1)),0>c&&(f=-f),f=(0>b?-1:1)*f+a,k=h*(f-a)+n),this.Sh(d),this.Yk(f,k)):this.Sh(d),this.Sh(e)));return!0};
function Qp(a,b){Math.abs(b.x-a.x)>Math.abs(b.y-a.y)?(b.x=b.x>=a.x?a.x+9E9:a.x-9E9,b.y=a.y):(b.y=b.y>=a.y?a.y+9E9:a.y-9E9,b.x=a.x);return b}
W.prototype.getLinkPointFromPoint=function(a,b,c,d,e,f){void 0===f&&(f=new z);if(null===a||null===b)return f.assign(c),f;a.isVisible()||(e=a.findVisibleNode(),null!==e&&e!==a&&(b=e.port));var h=e=0,k=0,l=0;a=null;e=b.S;null===e||e.Kg()||(e=e.S);if(null===e)e=d.x,h=d.y,k=c.x,l=c.y;else{a=e.Yg;e=1/(a.m11*a.m22-a.m12*a.m21);var k=a.m22*e,l=-a.m12*e,m=-a.m21*e,n=a.m11*e,p=e*(a.m21*a.dy-a.m22*a.dx),q=e*(a.m12*a.dx-a.m11*a.dy);e=d.x*k+d.y*m+p;h=d.x*l+d.y*n+q;k=c.x*k+c.y*m+p;l=c.x*l+c.y*n+q}b.bp(e,h,k,l,
f);null!==a&&f.transform(a);return f};function Rp(a,b){var c=b.km;null===c&&(c=new Sp,c.port=b,c.Jc=b.Y,b.km=c);return Tp(c,a)}
W.prototype.getLinkPoint=function(a,b,c,d,e,f,h,k){void 0===k&&(k=new z);if(c.cd())return b.Xa(c,k),k;if(c.Ui()&&(c=Rp(this,b),null!==c)){k.assign(c.kp);if(e&&this.hx===Ap){var l=Rp(this,h);if(null!==l&&c.Jm<l.Jm){c=v.L();var l=v.L(),m=new B(b.Xa(tb,c),b.Xa(Fb,l)),n=this.computeSpot(!d);a=this.getLinkPoint(f,h,n,!d,e,a,b,l);a.x>=m.x&&a.x<=m.x+m.width?k.x=a.x:a.y>=m.y&&a.y<=m.y+m.height&&(k.y=a.y);v.v(c);v.v(l)}}return k}f=b.Xa(Ab,v.L());l=c=null;this.oa>(e?6:2)?(l=d?this.l(1):this.l(this.oa-2),e&&
(l=Qp(f,l.copy()))):(c=v.L(),l=h.Xa(Ab,c),e&&(l=Qp(f,l)),v.v(c));this.getLinkPointFromPoint(a,b,f,l,d,k);v.v(f);return k};
W.prototype.getLinkDirection=function(a,b,c,d,e,f,h,k){a:if(d.cd())c=d.x>d.y?d.x>1-d.y?0:d.x<1-d.y?270:315:d.x<d.y?d.x>1-d.y?90:d.x<1-d.y?180:135:.5>d.x?225:.5<d.x?45:0;else{if(d.Ui()){var l=Rp(this,b);if(null!==l)switch(l.fe){case v.ed:c=270;break a;case v.Nc:c=180;break a;default:case v.Xc:c=0;break a;case v.Wc:c=90;break a}}var l=b.Xa(Ab,v.L()),m=null,n=null;this.oa>(f?6:2)?(n=e?this.l(1):this.l(this.oa-2),n=f?Qp(l,n.copy()):c):(m=v.L(),n=k.Xa(Ab,m),v.v(m));c=0;c=Math.abs(n.x-l.x)>Math.abs(n.y-
l.y)?n.x>=l.x?0:180:n.y>=l.y?90:270;v.v(l)}d.se()&&h.Xh(a)&&(c+=180,360<=c&&(c-=360));a=np;a=e?this.kt:this.eu;a===np&&(a=e?b.kt:b.eu);switch(a){case op:b=b.nl();c+=b;360<=c&&(c-=360);break;case np:case ym:b=b.nl();if(0===b)break;45<=b&&135>b?c+=90:135<=b&&225>b?c+=180:225<=b&&315>b&&(c+=270);360<=c&&(c-=360)}return c};
W.prototype.computeEndSegmentLength=function(a,b,c,d){if(null!==b&&c.Ui()&&(a=Rp(this,b),null!==a))return a.ww;a=NaN;a=d?this.ll:this.Fl;null!==b&&isNaN(a)&&(a=d?b.ll:b.Fl);isNaN(a)&&(a=10);return a};W.prototype.computeSpot=function(a){return a?Op(this,this.bd):Pp(this,this.Fd)};function Op(a,b){if(null===b)return Ab;var c=a.xb;c.Uc()&&(void 0===b&&(b=a.bd),null!==b&&(c=b.xb));return c===fc?sb:c}
function Pp(a,b){if(null===b)return Ab;var c=a.yb;c.Uc()&&(void 0===b&&(b=a.Fd),null!==b&&(c=b.yb));return c===fc?sb:c}W.prototype.computeOtherPoint=function(a,b){var c=b.Xa(Ab),d;d=b.km;d=null!==d?Tp(d,this):null;null!==d&&(c=d.kp);return c};W.prototype.computeShortLength=function(a){return a?Up(this):Vp(this)};function Up(a){var b=a.lt;isNaN(b)&&(a=a.bd,null!==a&&(b=a.lt));return isNaN(b)?0:b}function Vp(a){var b=a.fu;isNaN(b)&&(a=a.Fd,null!==a&&(b=a.fu));return isNaN(b)?0:b}
W.prototype.Yj=function(a,b,c,d,e,f){if(!1===this.Gg)return!1;void 0===b&&(b=null);void 0===c&&(c=null);var h=f;void 0===f&&(h=v.ph(),h.reset());h.multiply(this.transform);if(this.Gm(a,h))return bo(this,b,c,e),void 0===f&&v.Ze(h),!0;if(this.xg(a,h)){var k=!1;if(!this.Pg)for(var l=this.za.n,m=l.length;m--;){var n=l[m];if(n.visible||n===this.cc){var p=n.U,q=this.Ha;if(!(p.x>q.width||p.y>q.height||0>p.x+p.width||0>p.y+p.height)){p=v.ph();p.set(h);if(n instanceof D)k=n.Yj(a,b,c,d,e,p);else if(this.path===
n){var k=n,r=a,s=d,q=p;if(!1===k.Gg)k=!1;else if(q.multiply(k.transform),s)b:{var t=r,u=q;if(k.Gm(t,u))k=!0;else{if(void 0===u&&(u=k.transform,t.Wj(k.U))){k=!0;break b}var q=t.left,r=t.right,s=t.top,t=t.bottom,y=v.L(),w=v.L(),x=v.L(),A=v.ph();A.set(u);A.vE(k.transform);A.Dz();w.x=r;w.y=s;w.transform(A);y.x=q;y.y=s;y.transform(A);u=!1;no(k,y,w,x)?u=!0:(y.x=r,y.y=t,y.transform(A),no(k,y,w,x)?u=!0:(w.x=q,w.y=t,w.transform(A),no(k,y,w,x)?u=!0:(y.x=q,y.y=s,y.transform(A),no(k,y,w,x)&&(u=!0))));v.Ze(A);
v.v(y);v.v(w);v.v(x);k=u}}else k=k.Gm(r,q)}else k=Dm(n,a,d,p);k&&(null!==b&&(n=b(n)),n&&(null===c||c(n))&&e.add(n));v.Ze(p)}}}void 0===f&&v.Ze(h);return k||null!==this.background||null!==this.Bm}void 0===f&&v.Ze(h);return!1};v.u(W,{jc:"isOrthogonal"},function(){return 2===(this.mm.value&2)});v.u(W,{rl:"isAvoiding"},function(){return 4===(this.mm.value&4)});W.prototype.computeCurve=function(){if(null===this.pi){var a=this.bd,b=this.jc;this.pi=null!==a&&a===this.Fd&&!b}return this.pi?Hi:this.Ie};
W.prototype.computeCorner=function(){if(this.Ie===Hi)return 0;var a=this.iz;if(isNaN(a)||0>a)a=10;return a};W.prototype.findMidLabel=function(){for(var a=this.path,b=this.za.n,c=b.length,d=0;d<c;d++){var e=b[d];if(e!==a&&!e.Rf&&(-Infinity===e.ve||isNaN(e.ve)))return e}for(a=this.Sf;a.next();)if(b=a.value,-Infinity===b.ve||isNaN(b.ve))return b;return null};
W.prototype.computeSpacing=function(){if(!this.isVisible())return 0;var a;a=Math.max(14,this.computeThickness());var b=this.bd,c=this.Fd;if(null!==b&&null!==c){var d=this.findMidLabel();if(null!==d){var e=d.Ha,f=d.margin,h=isNaN(e.width)?30:e.width*d.scale+f.left+f.right,e=isNaN(e.height)?14:e.height*d.scale+f.top+f.bottom,d=d.Vt;d===qo||d===Ep||d===Dp?a=Math.max(a,e):d===Cp||d===Gp||d===Bp||d===Fp?a=Math.max(a,h):(b=b.Xa(Ab).xc(c.Xa(Ab))/180*Math.PI,a=Math.max(a,Math.abs(Math.sin(b)*h)+Math.abs(Math.cos(b)*
e)+1));this.Ie===Hi&&(a*=1.333)}}return a};W.prototype.arrangeBundledLinks=function(a,b){if(b)for(var c=0;c<a.length;c++)a[c].ic()};W.prototype.computeCurviness=function(){var a=this.sw;if(isNaN(a)){var a=16,b=this.ci;if(null!==b){for(var c=v.jb(),d=0,e=b.links,f=0;f<e.length;f++){var h=e[f],h=h.computeSpacing();c.push(h);d+=h}d=-d/2;for(f=0;f<e.length;f++){h=e[f];if(h===this){a=d+c[f]/2;break}d+=c[f]}b.tp===this.T&&(a=-a);v.sa(c)}}return a};
W.prototype.computeThickness=function(){if(!this.isVisible())return 0;var a=this.path;return null!==a?Math.max(a.lb,1):1};W.prototype.hasCurviness=function(){return!isNaN(this.sw)||null!==this.ci};
W.prototype.adjustPoints=function(a,b,c,d){var e=this.Qs;if(this.jc){if(e===dn)return!1;e===en&&(e=cn)}switch(e){case dn:var f=this.l(a),h=this.l(c);if(!f.Fc(b)||!h.Fc(d)){var e=f.x,f=f.y,k=h.x-e,l=h.y-f,m=Math.sqrt(k*k+l*l);if(!Ec(m,0)){var n=0;Ec(k,0)?n=0>l?-Math.PI/2:Math.PI/2:(n=Math.atan(l/Math.abs(k)),0>k&&(n=Math.PI-n));var h=b.x,p=b.y,l=d.x-h,q=d.y-p,r=Math.sqrt(l*l+q*q),k=0;Ec(l,0)?k=0>q?-Math.PI/2:Math.PI/2:(k=Math.atan(q/Math.abs(l)),0>l&&(k=Math.PI-k));m=r/m;n=k-n;this.zf(a,b);for(a+=
1;a<c;a++)b=this.l(a),k=b.x-e,l=b.y-f,b=Math.sqrt(k*k+l*l),Ec(b,0)||(q=0,Ec(k,0)?q=0>l?-Math.PI/2:Math.PI/2:(q=Math.atan(l/Math.abs(k)),0>k&&(q=Math.PI-q)),k=q+n,b*=m,this.V(a,h+b*Math.cos(k),p+b*Math.sin(k)));this.zf(c,d)}}return!0;case en:f=this.l(a);p=this.l(c);if(!f.Fc(b)||!p.Fc(d)){var e=f.x,f=f.y,h=p.x,p=p.y,m=(h-e)*(h-e)+(p-f)*(p-f),k=b.x,n=b.y,l=d.x,q=d.y,r=0,s=1;0!==l-k?(r=(q-n)/(l-k),s=Math.sqrt(1+1/(r*r))):r=9E9;this.zf(a,b);for(a+=1;a<c;a++){b=this.l(a);var t=b.x,u=b.y,y=.5;0!==m&&(y=
((e-t)*(e-h)+(f-u)*(f-p))/m);var w=e+y*(h-e),x=f+y*(p-f);b=Math.sqrt((t-w)*(t-w)+(u-x)*(u-x));u<r*(t-w)+x&&(b=-b);0<r&&(b=-b);t=k+y*(l-k);y=n+y*(q-n);0!==r?(b=t+b/s,this.V(a,b,y-(b-t)/r)):this.V(a,t,y+b)}this.zf(c,d)}return!0;case cn:return this.jc&&(e=this.l(a),f=this.l(a+1),h=this.l(a+2),K(e.y,f.y)?K(f.x,h.x)?this.V(a+1,f.x,b.y):K(f.y,h.y)&&this.V(a+1,b.x,f.y):K(e.x,f.x)&&(K(f.y,h.y)?this.V(a+1,b.x,f.y):K(f.x,h.x)&&this.V(a+1,f.x,b.y)),e=this.l(c),f=this.l(c-1),h=this.l(c-2),K(e.y,f.y)?K(f.x,h.x)?
this.V(c-1,f.x,d.y):K(f.y,h.y)&&this.V(c-1,d.x,f.y):K(e.x,f.x)&&(K(f.y,h.y)?this.V(c-1,d.x,f.y):K(f.x,h.x)&&this.V(c-1,f.x,d.y))),this.zf(a,b),this.zf(c,d),!0;default:return!1}};
W.prototype.addOrthoPoints=function(a,b,c,d,e,f){b=-45<=b&&45>b?0:45<=b&&135>b?90:135<=b&&225>b?180:270;d=-45<=d&&45>d?0:45<=d&&135>d?90:135<=d&&225>d?180:270;var h=e.U.copy(),k=f.U.copy();if(h.G()&&k.G()){h.Qf(8,8);k.Qf(8,8);h.dj(a);k.dj(c);var l,m;if(0===b)if(c.x>a.x||270===d&&c.y<a.y&&k.right>a.x||90===d&&c.y>a.y&&k.right>a.x)l=new z(c.x,a.y),m=new z(c.x,(a.y+c.y)/2),180===d?(l.x=this.computeMidOrthoPosition(a.x,c.x,!1),m.x=l.x,m.y=c.y):270===d&&c.y<a.y||90===d&&c.y>a.y?(l.x=a.x<k.left?this.computeMidOrthoPosition(a.x,
k.left,!1):a.x<k.right&&(270===d&&a.y<k.top||90===d&&a.y>k.bottom)?this.computeMidOrthoPosition(a.x,c.x,!1):k.right,m.x=l.x,m.y=c.y):0===d&&a.x<k.left&&a.y>k.top&&a.y<k.bottom&&(l.x=a.x,l.y=a.y<c.y?Math.min(c.y,k.top):Math.max(c.y,k.bottom),m.y=l.y);else{l=new z(a.x,c.y);m=new z((a.x+c.x)/2,c.y);if(180===d||90===d&&c.y<h.top||270===d&&c.y>h.bottom)180===d&&(k.Fa(a)||h.Fa(c))?l.y=this.computeMidOrthoPosition(a.y,c.y,!0):c.y<a.y&&(180===d||90===d)?l.y=this.computeMidOrthoPosition(h.top,Math.max(c.y,
k.bottom),!0):c.y>a.y&&(180===d||270===d)&&(l.y=this.computeMidOrthoPosition(h.bottom,Math.min(c.y,k.top),!0)),m.x=c.x,m.y=l.y;if(l.y>h.top&&l.y<h.bottom)if(c.x>=h.left&&c.x<=a.x||a.x<=k.right&&a.x>=c.x){if(90===d||270===d)l=new z(Math.max((a.x+c.x)/2,a.x),a.y),m=new z(l.x,c.y)}else l.y=270===d||(0===d||180===d)&&c.y<a.y?Math.min(c.y,0===d?h.top:Math.min(h.top,k.top)):Math.max(c.y,0===d?h.bottom:Math.max(h.bottom,k.bottom)),m.x=c.x,m.y=l.y}else if(180===b)if(c.x<a.x||270===d&&c.y<a.y&&k.left<a.x||
90===d&&c.y>a.y&&k.left<a.x)l=new z(c.x,a.y),m=new z(c.x,(a.y+c.y)/2),0===d?(l.x=this.computeMidOrthoPosition(a.x,c.x,!1),m.x=l.x,m.y=c.y):270===d&&c.y<a.y||90===d&&c.y>a.y?(l.x=a.x>k.right?this.computeMidOrthoPosition(a.x,k.right,!1):a.x>k.left&&(270===d&&a.y<k.top||90===d&&a.y>k.bottom)?this.computeMidOrthoPosition(a.x,c.x,!1):k.left,m.x=l.x,m.y=c.y):180===d&&a.x>k.right&&a.y>k.top&&a.y<k.bottom&&(l.x=a.x,l.y=a.y<c.y?Math.min(c.y,k.top):Math.max(c.y,k.bottom),m.y=l.y);else{l=new z(a.x,c.y);m=new z((a.x+
c.x)/2,c.y);if(0===d||90===d&&c.y<h.top||270===d&&c.y>h.bottom)0===d&&(k.Fa(a)||h.Fa(c))?l.y=this.computeMidOrthoPosition(a.y,c.y,!0):c.y<a.y&&(0===d||90===d)?l.y=this.computeMidOrthoPosition(h.top,Math.max(c.y,k.bottom),!0):c.y>a.y&&(0===d||270===d)&&(l.y=this.computeMidOrthoPosition(h.bottom,Math.min(c.y,k.top),!0)),m.x=c.x,m.y=l.y;if(l.y>h.top&&l.y<h.bottom)if(c.x<=h.right&&c.x>=a.x||a.x>=k.left&&a.x<=c.x){if(90===d||270===d)l=new z(Math.min((a.x+c.x)/2,a.x),a.y),m=new z(l.x,c.y)}else l.y=270===
d||(0===d||180===d)&&c.y<a.y?Math.min(c.y,180===d?h.top:Math.min(h.top,k.top)):Math.max(c.y,180===d?h.bottom:Math.max(h.bottom,k.bottom)),m.x=c.x,m.y=l.y}else if(90===b)if(c.y>a.y||180===d&&c.x<a.x&&k.bottom>a.y||0===d&&c.x>a.x&&k.bottom>a.y)l=new z(a.x,c.y),m=new z((a.x+c.x)/2,c.y),270===d?(l.y=this.computeMidOrthoPosition(a.y,c.y,!0),m.x=c.x,m.y=l.y):180===d&&c.x<a.x||0===d&&c.x>a.x?(l.y=a.y<k.top?this.computeMidOrthoPosition(a.y,k.top,!0):a.y<k.bottom&&(180===d&&a.x<k.left||0===d&&a.x>k.right)?
this.computeMidOrthoPosition(a.y,c.y,!0):k.bottom,m.x=c.x,m.y=l.y):90===d&&a.y<k.top&&a.x>k.left&&a.x<k.right&&(l.x=a.x<c.x?Math.min(c.x,k.left):Math.max(c.x,k.right),l.y=a.y,m.x=l.x);else{l=new z(c.x,a.y);m=new z(c.x,(a.y+c.y)/2);if(270===d||0===d&&c.x<h.left||180===d&&c.x>h.right)270===d&&(k.Fa(a)||h.Fa(c))?l.x=this.computeMidOrthoPosition(a.x,c.x,!1):c.x<a.x&&(270===d||0===d)?l.x=this.computeMidOrthoPosition(h.left,Math.max(c.x,k.right),!1):c.x>a.x&&(270===d||180===d)&&(l.x=this.computeMidOrthoPosition(h.right,
Math.min(c.x,k.left),!1)),m.x=l.x,m.y=c.y;if(l.x>h.left&&l.x<h.right)if(c.y>=h.top&&c.y<=a.y||a.y<=k.bottom&&a.y>=c.y){if(0===d||180===d)l=new z(a.x,Math.max((a.y+c.y)/2,a.y)),m=new z(c.x,l.y)}else l.x=180===d||(90===d||270===d)&&c.x<a.x?Math.min(c.x,90===d?h.left:Math.min(h.left,k.left)):Math.max(c.x,90===d?h.right:Math.max(h.right,k.right)),m.x=l.x,m.y=c.y}else if(c.y<a.y||180===d&&c.x<a.x&&k.top<a.y||0===d&&c.x>a.x&&k.top<a.y)l=new z(a.x,c.y),m=new z((a.x+c.x)/2,c.y),90===d?(l.y=this.computeMidOrthoPosition(a.y,
c.y,!0),m.x=c.x,m.y=l.y):180===d&&c.x<a.x||0===d&&c.x>=a.x?(l.y=a.y>k.bottom?this.computeMidOrthoPosition(a.y,k.bottom,!0):a.y>k.top&&(180===d&&a.x<k.left||0===d&&a.x>k.right)?this.computeMidOrthoPosition(a.y,c.y,!0):k.top,m.x=c.x,m.y=l.y):270===d&&a.y>k.bottom&&a.x>k.left&&a.x<k.right&&(l.x=a.x<c.x?Math.min(c.x,k.left):Math.max(c.x,k.right),l.y=a.y,m.x=l.x);else{l=new z(c.x,a.y);m=new z(c.x,(a.y+c.y)/2);if(90===d||0===d&&c.x<h.left||180===d&&c.x>h.right)90===d&&(k.Fa(a)||h.Fa(c))?l.x=this.computeMidOrthoPosition(a.x,
c.x,!1):c.x<a.x&&(90===d||0===d)?l.x=this.computeMidOrthoPosition(h.left,Math.max(c.x,k.right),!1):c.x>a.x&&(90===d||180===d)&&(l.x=this.computeMidOrthoPosition(h.right,Math.min(c.x,k.left),!1)),m.x=l.x,m.y=c.y;if(l.x>h.left&&l.x<h.right)if(c.y<=h.bottom&&c.y>=a.y||a.y>=k.top&&a.y<=c.y){if(0===d||180===d)l=new z(a.x,Math.min((a.y+c.y)/2,a.y)),m=new z(c.x,l.y)}else l.x=180===d||(90===d||270===d)&&c.x<a.x?Math.min(c.x,270===d?h.left:Math.min(h.left,k.left)):Math.max(c.x,270===d?h.right:Math.max(h.right,
k.right)),m.x=l.x,m.y=c.y}var n=l,p=m;if(this.rl){var q=this.g,r;(r=null===q)||(q.Na.cf?r=!1:(r=q.Wa,r=r instanceof Xg?!r.Xv||r.nH:!0),r=!r);if(r||h.Fa(c)&&!f.Xh(e)||k.Fa(a)&&!e.Xh(f)||e===f||this.layer.Gc)a=!1;else if(e=am(q,!0,this.Sa,null),e.ip(Math.min(a.x,n.x),Math.min(a.y,n.y),Math.abs(a.x-n.x),Math.abs(a.y-n.y))&&e.ip(Math.min(n.x,p.x),Math.min(n.y,p.y),Math.abs(n.x-p.x),Math.abs(n.y-p.y))&&e.ip(Math.min(p.x,c.x),Math.min(p.y,c.y),Math.abs(p.x-c.x),Math.abs(p.y-c.y)))a=!1;else if(h=h.copy().ai(k),
h.Qf(2*e.Oo,2*e.Mo),Wp(e,a,b,c,d,h),k=Xp(e,c.x,c.y),e.abort||999999!==k||(dm(e),k=e.$E,h.Qf(e.Oo*k,e.Mo*k),Wp(e,a,b,c,d,h),k=Xp(e,c.x,c.y)),e.abort||999999!==k||(dm(e),k=e.oE,h.Qf(e.Oo*k,e.Mo*k),Wp(e,a,b,c,d,h),k=Xp(e,c.x,c.y)),!e.abort&&999999===k&&e.sF&&(dm(e),Wp(e,a,b,c,d,e.mb),k=Xp(e,c.x,c.y)),!e.abort&&999999>k&&0!==Xp(e,c.x,c.y)){Yp(this,e,c.x,c.y,d,!0);d=this.l(2);if(4>this.oa)0===b||180===b?(d.x=a.x,d.y=c.y):(d.x=c.x,d.y=a.y),this.V(2,d.x,d.y),this.w(3,d.x,d.y);else if(c=this.l(3),0===b||
180===b)K(d.x,c.x)?(b=0===b?Math.max(d.x,a.x):Math.min(d.x,a.x),this.V(2,b,a.y),this.V(3,b,c.y)):K(d.y,c.y)?(Math.abs(a.y-d.y)<=e.Mo/2&&(this.V(2,d.x,a.y),this.V(3,c.x,a.y)),this.w(2,d.x,a.y)):this.V(2,a.x,d.y);else if(90===b||270===b)K(d.y,c.y)?(b=90===b?Math.max(d.y,a.y):Math.min(d.y,a.y),this.V(2,a.x,b),this.V(3,c.x,b)):K(d.x,c.x)?(Math.abs(a.x-d.x)<=e.Oo/2&&(this.V(2,a.x,d.y),this.V(3,a.x,c.y)),this.w(2,a.x,d.y)):this.V(2,d.x,a.y);a=!0}else a=!1}else a=!1;a||(this.Sh(l),this.Sh(m))}};
W.prototype.computeMidOrthoPosition=function(a,b){if(this.hasCurviness()){var c=this.computeCurviness();return(a+b)/2+c}return(a+b)/2};function Gh(a){if(null===a.g||!a.rl)return!1;var b=a.points.n,c=b.length;if(4>c)return!1;a=am(a.g,!0,a.Sa,null);for(var d=1;d<c-2;d++){var e=b[d],f=b[d+1];if(!a.ip(Math.min(e.x,f.x),Math.min(e.y,f.y),Math.abs(e.x-f.x),Math.abs(e.y-f.y)))return!0}return!1}
function Yp(a,b,c,d,e,f){var h=b.Oo,k=b.Mo,l=Xp(b,c,d),m=c,n=d;for(0===e?m+=h:90===e?n+=k:180===e?m-=h:n-=k;1<l&&Xp(b,m,n)===l-1;)c=m,d=n,0===e?m+=h:90===e?n+=k:180===e?m-=h:n-=k,l-=1;if(f){if(1<l)if(180===e||0===e)c=Math.floor(c/h)*h+h/2;else if(90===e||270===e)d=Math.floor(d/k)*k+k/2}else c=Math.floor(c/h)*h+h/2,d=Math.floor(d/k)*k+k/2;1<l&&(f=e,m=c,n=d,0===e?(f=90,n+=k):90===e?(f=180,m-=h):180===e?(f=270,n-=k):270===e&&(f=0,m+=h),Xp(b,m,n)===l-1?Yp(a,b,m,n,f,!1):(m=c,n=d,0===e?(f=270,n-=k):90===
e?(f=0,m+=h):180===e?(f=90,n+=k):270===e&&(f=180,m-=h),Xp(b,m,n)===l-1&&Yp(a,b,m,n,f,!1)));a.Yk(c,d)}W.prototype.findClosestSegment=function(a){var b=a.x;a=a.y;for(var c=this.l(0),d=this.l(1),e=Ta(b,a,c.x,c.y,d.x,d.y),f=0,h=1;h<this.oa-1;h++){var c=this.l(h+1),k=Ta(b,a,d.x,d.y,c.x,c.y),d=c;k<e&&(f=h,e=k)}return f};W.prototype.invalidateGeometry=W.prototype.re=function(){this.Ra=null};v.u(W,{ml:"geometry"},function(){null===this.Ra&&(this.hn(),this.Ra=this.makeGeometry());return this.Ra});
W.prototype.Wm=function(a){if(!a){if(!1===this.Lf)return;a=this.Xe();if(null!==this.Ra&&(null===a||null!==a.ml))return}this.Ra=this.makeGeometry();a=this.path;if(null!==a){a.Ra=this.Ra;for(var b=this.za.n,c=b.length,d=0;d<c;d++){var e=b[d];e!==a&&e.Rf&&e instanceof X&&(e.Ra=this.Ra)}}};
W.prototype.makeGeometry=function(){var a=this.oa;if(2>a){var b=new re(De),c=new Ee(0,0);b.Ub.add(c);return b}var d=!1,b=this.g;null!==b&&Lp(this)&&b.xk.contains(this)&&null!==this.Hv&&(d=!0);var c=b=0,e=this.l(0).copy(),f=e.copy(),b=this.Zc.n,h=this.computeCurve();if(h===Hi&&3<=a&&!Ec(this.Ip,0))if(3===a)var k=this.l(1),b=Math.min(e.x,k.x),c=Math.min(e.y,k.y),k=this.l(2),b=Math.min(b,k.x),c=Math.min(c,k.y);else{if(this.jc)for(k=0;k<a;k++)c=b[k],f.x=Math.min(c.x,f.x),f.y=Math.min(c.y,f.y);else for(k=
3;k<a;k+=3)k+3>=a&&(k=a-1),b=this.l(k),f.x=Math.min(b.x,f.x),f.y=Math.min(b.y,f.y);b=f.x;c=f.y}else{for(k=0;k<a;k++)c=b[k],f.x=Math.min(c.x,f.x),f.y=Math.min(c.y,f.y);b=f.x;c=f.y}b-=this.Oy.x;c-=this.Oy.y;e.x-=b;e.y-=c;if(2===a){var l=this.l(1).copy();l.x-=b;l.y-=c;0!==Up(this)&&Zp(this,e,!0,f);0!==Vp(this)&&Zp(this,l,!1,f);b=new re(Ge);b.Aa=e.x;b.ya=e.y;b.I=l.x;b.J=l.y}else{l=v.s();0!==Up(this)&&Zp(this,e,!0,f);N(l,e.x,e.y,!1,!1);if(h===Hi&&3<=a&&!Ec(this.Ip,0))if(3===a)k=this.l(1),a=k.x-b,d=k.y-
c,k=this.l(2).copy(),k.x-=b,k.y-=c,0!==Vp(this)&&Zp(this,k,!1,f),O(l,a,d,a,d,k.x,k.y);else if(this.jc){for(var f=new z(b,c),e=this.l(1).copy(),h=new z(b,c),a=new z(b,c),d=this.l(0),m=null,n=this.Ip/3,k=1;k<this.oa-1;k++){var m=this.l(k),p=d,q=m,r=this.l($p(this,m,k,!1));if(!Ec(p.x,q.x)||!Ec(q.x,r.x))if(!Ec(p.y,q.y)||!Ec(q.y,r.y)){var s=n,t=h,u=a;isNaN(s)&&(s=this.Ip/3);var y=p.x,p=p.y,w=q.x,q=q.y,x=r.x,r=r.y,A=s*aq(y,p,w,q),s=s*aq(w,q,x,r);Ec(p,q)&&Ec(w,x)&&(w>y?r>q?(t.x=w-A,t.y=q-A,u.x=w+s,u.y=q+
s):(t.x=w-A,t.y=q+A,u.x=w+s,u.y=q-s):r>q?(t.x=w+A,t.y=q-A,u.x=w-s,u.y=q+s):(t.x=w+A,t.y=q+A,u.x=w-s,u.y=q-s));Ec(y,w)&&Ec(q,r)&&(q>p?(x>w?(t.x=w-A,t.y=q-A,u.x=w+s):(t.x=w+A,t.y=q-A,u.x=w-s),u.y=q+s):(x>w?(t.x=w-A,t.y=q+A,u.x=w+s):(t.x=w+A,t.y=q+A,u.x=w-s),u.y=q-s));if(Ec(y,w)&&Ec(w,x)||Ec(p,q)&&Ec(q,r))y=.5*(y+x),p=.5*(p+r),t.x=y,t.y=p,u.x=y,u.y=p;1===k?(e.x=.5*(d.x+m.x),e.y=.5*(d.y+m.y)):2===k&&Ec(d.x,this.l(0).x)&&Ec(d.y,this.l(0).y)&&(e.x=.5*(d.x+m.x),e.y=.5*(d.y+m.y));O(l,e.x-b,e.y-c,h.x-b,h.y-
c,m.x-b,m.y-c);f.set(h);e.set(a);d=m}}k=d.x;d=d.y;f=this.l(this.oa-1);k=.5*(k+f.x);d=.5*(d+f.y);O(l,a.x-b,a.y-c,k-b,d-c,f.x-b,f.y-c)}else for(k=3;k<a;k+=3)d=this.l(k-2),k+3>=a&&(k=a-1),f=this.l(k-1),e=this.l(k),k===a-1&&0!==Vp(this)&&(e=e.copy(),Zp(this,e,!1,Yc)),O(l,d.x-b,d.y-c,f.x-b,f.y-c,e.x-b,e.y-c);else{f=v.L();f.assign(this.l(0));for(k=1;k<a;){k=$p(this,f,k,1<k);t=this.l(k);if(k>=a-1){f!==t&&(0!==Vp(this)&&(t=t.copy(),Zp(this,t,!1,Yc)),bq(this,l,-b,-c,f,t,d));break}k=$p(this,t,k+1,k<a-3);e=
l;h=-b;m=-c;n=f;u=this.l(k);y=f;p=d;K(n.y,t.y)&&K(t.x,u.x)?(s=this.computeCorner(),s=Math.min(s,Math.abs(t.x-n.x)/2),s=w=Math.min(s,Math.abs(u.y-t.y)/2),K(s,0)?(bq(this,e,h,m,n,t,p),y.assign(t)):(q=t.x,x=t.y,r=q,A=x,q=t.x>n.x?t.x-s:t.x+s,A=u.y>t.y?t.y+w:t.y-w,bq(this,e,h,m,n,new z(q,x),p),cf(e,t.x+h,t.y+m,r+h,A+m),y.m(r,A))):K(n.x,t.x)&&K(t.y,u.y)?(s=this.computeCorner(),w=Math.min(s,Math.abs(t.y-n.y)/2),w=s=Math.min(w,Math.abs(u.x-t.x)/2),K(s,0)?(bq(this,e,h,m,n,t,p),y.assign(t)):(q=t.x,A=x=t.y,
x=t.y>n.y?t.y-w:t.y+w,r=u.x>t.x?t.x+s:t.x-s,bq(this,e,h,m,n,new z(q,x),p),cf(e,t.x+h,t.y+m,r+h,A+m),y.m(r,A))):(bq(this,e,h,m,n,t,p),y.assign(t))}v.v(f)}b=l.o;v.q(l)}return b};function aq(a,b,c,d){a=c-a;if(isNaN(a)||Infinity===a||-Infinity===a)return NaN;0>a&&(a=-a);b=d-b;if(isNaN(b)||Infinity===b||-Infinity===b)return NaN;0>b&&(b=-b);return Ec(a,0)?b:Ec(b,0)?a:Math.sqrt(a*a+b*b)}
function Zp(a,b,c,d){var e=a.oa;if(!(2>e))if(c){var f=a.l(1);c=f.x-d.x;d=f.y-d.y;f=aq(b.x,b.y,c,d);0!==f&&(e=2===e?.5*f:f,a=Up(a),a>e&&(a=e),c=a*(c-b.x)/f,a=a*(d-b.y)/f,b.x+=c,b.y+=a)}else f=a.l(e-2),c=f.x-d.x,d=f.y-d.y,f=aq(b.x,b.y,c,d),0!==f&&(e=2===e?.5*f:f,a=Vp(a),a>e&&(a=e),c=a*(b.x-c)/f,a=a*(b.y-d)/f,b.x-=c,b.y-=a)}
function $p(a,b,c,d){for(var e=a.oa,f=b;Ec(b.x,f.x)&&Ec(b.y,f.y);){if(c>=e)return e-1;f=a.l(c++)}if(!Ec(b.x,f.x)&&!Ec(b.y,f.y))return c-1;for(var h=f;Ec(b.x,f.x)&&Ec(f.x,h.x)&&(!d||(b.y>=f.y?f.y>=h.y:f.y<=h.y))||Ec(b.y,f.y)&&Ec(f.y,h.y)&&(!d||(b.x>=f.x?f.x>=h.x:f.x<=h.x));){if(c>=e)return e-1;h=a.l(c++)}return c-2}
function bq(a,b,c,d,e,f,h){if(!h&&Lp(a)){h=[];var k=0;a.isVisible()&&(k=cq(a,e,f,h));var l=e.x,l=e.y;if(0<k)if(K(e.y,f.y))if(e.x<f.x)for(var m=0;m<k;){var n=Math.max(e.x,Math.min(h[m++]-5,f.x-10));b.lineTo(n+c,f.y+d);for(var l=n+c,p=Math.min(n+10,f.x);m<k;){var q=h[m];if(q<p+10)m++,p=Math.min(q+5,f.x);else break}q=(n+p)/2+c;q=f.y-10+d;n=p+c;p=f.y+d;a.Ie===zi?N(b,n,p,!1,!1):O(b,l,q,n,q,n,p)}else for(m=k-1;0<=m;){n=Math.min(e.x,Math.max(h[m--]+5,f.x+10));b.lineTo(n+c,f.y+d);l=n+c;for(p=Math.max(n-10,
f.x);0<=m;)if(q=h[m],q>p-10)m--,p=Math.max(q-5,f.x);else break;q=f.y-10+d;n=p+c;p=f.y+d;a.Ie===zi?N(b,n,p,!1,!1):O(b,l,q,n,q,n,p)}else if(K(e.x,f.x))if(e.y<f.y)for(m=0;m<k;){n=Math.max(e.y,Math.min(h[m++]-5,f.y-10));b.lineTo(f.x+c,n+d);l=n+d;for(p=Math.min(n+10,f.y);m<k;)if(q=h[m],q<p+10)m++,p=Math.min(q+5,f.y);else break;q=f.x-10+c;n=f.x+c;p+=d;a.Ie===zi?N(b,n,p,!1,!1):O(b,q,l,q,p,n,p)}else for(m=k-1;0<=m;){n=Math.min(e.y,Math.max(h[m--]+5,f.y+10));b.lineTo(f.x+c,n+d);l=n+d;for(p=Math.max(n-10,f.y);0<=
m;)if(q=h[m],q>p-10)m--,p=Math.max(q-5,f.y);else break;q=f.x-10+c;n=f.x+c;p+=d;a.Ie===zi?N(b,n,p,!1,!1):O(b,q,l,q,p,n,p)}}b.lineTo(f.x+c,f.y+d)}
function cq(a,b,c,d){var e=a.g;if(null===e||b.K(c))return 0;for(e=e.Ow;e.next();){var f=e.value;if(null!==f&&f.visible)for(var f=f.wb.n,h=f.length,k=0;k<h;k++){var l=f[k];if(l instanceof W){if(l===a)return 0<d.length&&d.sort(function(a,b){return a-b}),d.length;if(l.isVisible()&&Lp(l)){var m=l.bn;m.G()&&a.bn.xg(m)&&!a.usesSamePort(l)&&(m=l.path,null!==m&&m.Xi()&&dq(b,c,d,l))}}}}0<d.length&&d.sort(function(a,b){return a-b});return d.length}
function dq(a,b,c,d){for(var e=K(a.y,b.y),f=d.oa,h=d.l(0),k=v.L(),l=1;l<f;l++){var m=d.l(l);if(l<f-1){var n=d.l(l+1);if(h.y===m.y&&m.y===n.y){if(m.x>h.x&&n.x>m.x||m.x<h.x&&n.x<m.x)m=n,l++}else h.x===m.x&&m.x===n.x&&(m.y>h.y&&n.y>m.y||m.y<h.y&&n.y<m.y)&&(m=n,l++)}a:{var n=k,p=a.x,q=a.y,r=b.x,s=b.y,t=h.x,h=h.y,u=m.x,y=m.y;if(!K(p,r)){if(K(q,s)&&K(t,u)&&Math.min(p,r)<t&&Math.max(p,r)>t&&Math.min(h,y)<q&&Math.max(h,y)>q&&!K(h,y)){n.x=t;n.y=q;n=!0;break a}}else if(!K(q,s)&&K(h,y)&&Math.min(q,s)<h&&Math.max(q,
s)>h&&Math.min(t,u)<p&&Math.max(t,u)>p&&!K(t,u)){n.x=p;n.y=h;n=!0;break a}n.x=0;n.y=0;n=!1}n&&(e?c.push(k.x):c.push(k.y));h=m}v.v(k)}v.u(W,{ht:"firstPickIndex"},function(){return 2>=this.oa?0:this.jc||Op(this)!==sb?1:0});v.u(W,{Nw:"lastPickIndex"},function(){var a=this.oa;return 0===a?0:2>=a?a-1:this.jc||Pp(this)!==sb?a-2:a-1});function Lp(a){a=a.Ie;return a===yi||a===zi}function Np(a,b){if(b||Lp(a)){var c=a.g;null===c||c.xk.contains(a)||null===a.Hv||c.xk.add(a,a.Hv)}}
function Bi(a,b){var c=a.layer;if(null!==c&&c.visible&&!c.Gc){var d=c.g;if(null!==d)for(var e=!1,d=d.Ow;d.next();){var f=d.value;if(f.visible)if(f===c)for(var e=!0,h=!1,f=f.wb.n,k=f.length,l=0;l<k;l++){var m=f[l];m instanceof W&&(m===a?h=!0:h&&eq(a,m,b))}else if(e)for(f=f.wb.n,k=f.length,l=0;l<k;l++)m=f[l],m instanceof W&&eq(a,m,b)}}}function eq(a,b,c){if(null!==b&&null!==b.Ra&&Lp(b)){var d=b.bn;d.G()&&(a.bn.xg(d)||c.xg(d))&&(a.usesSamePort(b)||b.re())}}
W.prototype.usesSamePort=function(a){var b=this.oa,c=a.oa;if(0<b&&0<c){var d=this.l(0),e=a.l(0);if(d.Fc(e))return!0;b=this.l(b-1);a=a.l(c-1);if(b.Fc(a)||d.Fc(a)||b.Fc(e))return!0}else if(this.T===a.T||this.Z===a.Z||this.T===a.Z||this.Z===a.T)return!0;return!1};
W.prototype.isVisible=function(){if(!R.prototype.isVisible.call(this))return!1;var a=this.Sa,b=!0,c=this.g;null!==c&&(b=c.Qd);c=this.T;if(null!==c){if(this.Hc&&b&&!c.md)return!1;if(c===a)return!0;c=c.findVisibleNode();if(null===c||c===a)return!1}c=this.Z;if(null!==c){if(this.Hc&&!b&&!c.md)return!1;if(c===a)return!0;b=c.findVisibleNode();if(null===b||b===a)return!1}return!0};W.prototype.nd=function(a){R.prototype.nd.call(this,a);null!==this.ci&&this.ci.Rm();if(null!==this.Ce)for(var b=this.Ce.i;b.next();)b.value.nd(a)};
v.defineProperty(W,{Qs:"adjusting"},function(){return this.Sp},function(a){var b=this.Sp;b!==a&&(this.Sp=a,this.h("adjusting",b,a))});v.defineProperty(W,{iz:"corner"},function(){return this.lq},function(a){var b=this.lq;b!==a&&(this.lq=a,this.re(),this.h("corner",b,a))});v.defineProperty(W,{Ie:"curve"},function(){return this.oq},function(a){var b=this.oq;b!==a&&(this.oq=a,this.ic(),this.re(),Np(this,b===zi||b===yi||a===zi||a===yi),this.h("curve",b,a))});
v.defineProperty(W,{sw:"curviness"},function(){return this.pq},function(a){var b=this.pq;b!==a&&(this.pq=a,this.ic(),this.re(),this.h("curviness",b,a))});v.defineProperty(W,{hx:"routing"},function(){return this.mm},function(a){var b=this.mm;b!==a&&(this.mm=a,this.pi=null,this.ic(),Np(this,2===(b.value&2)||2===(a.value&2)),this.h("routing",b,a))});v.defineProperty(W,{Ip:"smoothness"},function(){return this.ss},function(a){var b=this.ss;b!==a&&(this.ss=a,this.re(),this.h("smoothness",b,a))});
function Jp(a){var b=a.eg;if(null!==b){var c=a.qg;if(null!==c){var d=a.zh;a=a.Rh;for(var e=null,f=null,h=b.fc.n,k=h.length,l=0;l<k;l++){var m=h[l];if(m.eg===b&&m.zh===d&&m.qg===c&&m.Rh===a||m.eg===c&&m.zh===a&&m.qg===b&&m.Rh===d)null===f?f=m:(null===e&&(e=[],e.push(f)),e.push(m))}if(null!==e){f=rp(b,c,d,a);null===f&&(f=new lk,f.tp=b,f.Ww=d,f.Jt=c,f.Xw=a,qp(b,f),qp(c,f));f.links=e;for(l=0;l<e.length;l++)m=e[l],m.ci=f;f.Rm()}}}}
function Ip(a){var b=a.ci;null!==b&&(a.ci=null,a=b.links.indexOf(a),0<=a&&(v.Th(b.links,a),b.Rm()))}function lk(){v.ec(this);this.fv=!1;this.Xw=this.Jt=this.Ww=this.tp=null;this.links=[]}lk.prototype.Rm=function(){if(!this.fv){var a=this.links;0<a.length&&(a=a[0].g,null!==a&&a.KB.add(this))}this.fv=!0};
lk.prototype.BA=function(){if(this.fv){this.fv=!1;var a=this.links;if(0<a.length){var b=a[0],c=b.g;b.arrangeBundledLinks(a,null===c||c.Tm);1===a.length&&(b.ci=null,a.length=0)}0===a.length&&(a=this.tp,null!==this&&null!==a.sh&&a.sh.remove(this),a=this.Jt,null!==this&&null!==a.sh&&a.sh.remove(this))}};v.Nd(lk,{tp:!0,Ww:!0,Jt:!0,Xw:!0,links:!0,spacing:!0});
function bm(){v.ec(this);this.sA=this.group=null;this.st=!0;this.abort=!1;this.lg=this.kg=1;this.or=this.nr=-1;this.he=this.ge=8;this.oc=null;this.sF=!1;this.$E=22;this.oE=111}v.Nd(bm,{group:!0,sA:!0,st:!0,abort:!0,sF:!0,$E:!0,oE:!0});
bm.prototype.initialize=function(a){if(!(0>=a.width||0>=a.height)){var b=a.y,c=a.x+a.width,d=a.y+a.height;this.kg=Math.floor((a.x-this.ge)/this.ge)*this.ge;this.lg=Math.floor((b-this.he)/this.he)*this.he;this.nr=Math.ceil((c+2*this.ge)/this.ge)*this.ge;this.or=Math.ceil((d+2*this.he)/this.he)*this.he;a=1+(Math.ceil((this.nr-this.kg)/this.ge)|0);b=1+(Math.ceil((this.or-this.lg)/this.he)|0);if(null===this.oc||this.Go<a-1||this.Ho<b-1){c=[];for(d=0;d<=a;d++){for(var e=[],f=0;f<=b;f++)e[f]=0;c[d]=e}this.oc=
c;this.Go=a-1;this.Ho=b-1}if(null!==this.oc)for(a=0;a<=this.Go;a++)for(b=0;b<=this.Ho;b++)this.oc[a][b]=999999}};v.u(bm,{mb:null},function(){return new B(this.kg,this.lg,this.nr-this.kg,this.or-this.lg)});v.defineProperty(bm,{Oo:null},function(){return this.ge},function(a){0<a&&a!==this.ge&&(this.ge=a,this.initialize(this.mb))});v.defineProperty(bm,{Mo:null},function(){return this.he},function(a){0<a&&a!==this.he&&(this.he=a,this.initialize(this.mb))});
function fq(a,b,c){return a.kg<=b&&b<=a.nr&&a.lg<=c&&c<=a.or}function Xp(a,b,c){if(!fq(a,b,c))return 0;b-=a.kg;b/=a.ge;c-=a.lg;c/=a.he;return a.oc[b|0][c|0]}function em(a,b,c){fq(a,b,c)&&(b-=a.kg,b/=a.ge,c-=a.lg,c/=a.he,a.oc[b|0][c|0]=0)}function dm(a){if(null!==a.oc)for(var b=0;b<=a.Go;b++)for(var c=0;c<=a.Ho;c++)1<=a.oc[b][c]&&(a.oc[b][c]|=999999)}
bm.prototype.ip=function(a,b,c,d){if(a>this.nr||a+c<this.kg||b>this.or||b+d<this.lg)return!0;a=(a-this.kg)/this.ge|0;b=(b-this.lg)/this.he|0;c=Math.max(0,c)/this.ge+1|0;var e=Math.max(0,d)/this.he+1|0;0>a&&(c+=a,a=0);0>b&&(e+=b,b=0);if(0>c||0>e)return!0;d=Math.min(a+c-1,this.Go)|0;for(c=Math.min(b+e-1,this.Ho)|0;a<=d;a++)for(e=b;e<=c;e++)if(0===this.oc[a][e])return!1;return!0};
function gq(a,b,c,d,e,f,h,k,l){if(!(b<f||b>h||c<k||c>l)){var m,n;m=b|0;n=c|0;var p=a.oc[m][n];if(1<=p&&999999>p)for(e?n+=d:m+=d,p+=1;f<=m&&m<=h&&k<=n&&n<=l&&!(p>=a.oc[m][n]);)a.oc[m][n]=p,p+=1,e?n+=d:m+=d;m=e?n:m;if(e)if(0<d)for(c+=d;c<m;c+=d)gq(a,b,c,1,!e,f,h,k,l),gq(a,b,c,-1,!e,f,h,k,l);else for(c+=d;c>m;c+=d)gq(a,b,c,1,!e,f,h,k,l),gq(a,b,c,-1,!e,f,h,k,l);else if(0<d)for(b+=d;b<m;b+=d)gq(a,b,c,1,!e,f,h,k,l),gq(a,b,c,-1,!e,f,h,k,l);else for(b+=d;b>m;b+=d)gq(a,b,c,1,!e,f,h,k,l),gq(a,b,c,-1,!e,f,h,
k,l)}}function hq(a,b,c,d,e,f,h,k,l,m,n){for(var p=b|0,q=c|0,r=a.oc[p][q];0===r&&p>k&&p<l&&q>m&&q<n;)if(h?q+=f:p+=f,r=a.oc[p][q],1>=Math.abs(p-d)&&1>=Math.abs(q-e))return a.abort=!0,0;p=b|0;q=c|0;r=a.oc[p][q];b=1;for(a.oc[p][q]=b;0===r&&p>k&&p<l&&q>m&&q<n;)h?q+=f:p+=f,r=a.oc[p][q],a.oc[p][q]=b,b+=1;return h?q:p}
function Wp(a,b,c,d,e,f){if(null!==a.oc){a.abort=!1;var h=b.x,k=b.y;if(fq(a,h,k)){var h=h-a.kg,h=h/a.ge,k=k-a.lg,k=k/a.he,l=d.x,m=d.y;if(fq(a,l,m))if(l-=a.kg,l/=a.ge,m-=a.lg,m/=a.he,1>=Math.abs(h-l)&&1>=Math.abs(k-m))a.abort=!0;else{var n=f.x;b=f.y;d=f.x+f.width;var p=f.y+f.height,n=n-a.kg,n=n/a.ge;b-=a.lg;b/=a.he;d-=a.kg;d/=a.ge;p-=a.lg;p/=a.he;f=Math.max(0,Math.min(a.Go,n|0));d=Math.min(a.Go,Math.max(0,d|0));b=Math.max(0,Math.min(a.Ho,b|0));var p=Math.min(a.Ho,Math.max(0,p|0)),h=h|0,k=k|0,l=l|0,
m=m|0,n=h,q=k,r=0===c||90===c?1:-1;(c=90===c||270===c)?q=hq(a,h,k,l,m,r,c,f,d,b,p):n=hq(a,h,k,l,m,r,c,f,d,b,p);if(!a.abort){a:{c=0===e||90===e?1:-1;e=90===e||270===e;for(var r=l|0,s=m|0,t=a.oc[r][s];0===t&&r>f&&r<d&&s>b&&s<p;)if(e?s+=c:r+=c,t=a.oc[r][s],1>=Math.abs(r-h)&&1>=Math.abs(s-k)){a.abort=!0;break a}r=l|0;s=m|0;t=a.oc[r][s];for(a.oc[r][s]=999999;0===t&&r>f&&r<d&&s>b&&s<p;)e?s+=c:r+=c,t=a.oc[r][s],a.oc[r][s]=999999}a.abort||(gq(a,n,q,1,!1,f,d,b,p),gq(a,n,q,-1,!1,f,d,b,p),gq(a,n,q,1,!0,f,d,
b,p),gq(a,n,q,-1,!0,f,d,b,p))}}}}}function Sp(){v.ec(this);this.port=this.Jc=null;this.Xf=[];this.sp=!1}v.Nd(Sp,{Jc:!0,port:!0,Xf:!0,sp:!0});Sp.prototype.toString=function(){for(var a=this.Xf,b=this.Jc.toString()+" "+a.length.toString()+":",c=0;c<a.length;c++){var d=a[c];null!==d&&(b+="\n  "+d.toString())}return b};
function iq(a,b,c,d){b=b.offsetY;switch(b){case v.Wc:return 90;case v.Nc:return 180;case v.ed:return 270;case v.Xc:return 0}switch(b){case v.Wc|v.ed:return 180<c?270:90;case v.Nc|v.Xc:return 90<c&&270>=c?180:0}a=180*Math.atan2(a.height,a.width)/Math.PI;switch(b){case v.Nc|v.ed:return c>a&&c<=180+a?180:270;case v.ed|v.Xc:return c>180-a&&c<=360-a?270:0;case v.Xc|v.Wc:return c>a&&c<=180+a?90:0;case v.Wc|v.Nc:return c>180-a&&c<=360-a?180:90;case v.Nc|v.ed|v.Xc:return 90<c&&c<=180+a?180:c>180+a&&c<=360-
a?270:0;case v.ed|v.Xc|v.Wc:return 180<c&&c<=360-a?270:c>a&&180>=c?90:0;case v.Xc|v.Wc|v.Nc:return c>a&&c<=180-a?90:c>180-a&&270>=c?180:0;case v.Wc|v.Nc|v.ed:return c>180-a&&c<=180+a?180:c>180+a?270:90}d&&b!==(v.Nc|v.ed|v.Xc|v.Wc)&&(c-=15,0>c&&(c+=360));return c>a&&c<180-a?90:c>=180-a&&c<=180+a?180:c>180+a&&c<360-a?270:0}Sp.prototype.Rm=function(){this.Xf.length=0};
function Tp(a,b){var c=a.Xf;if(0===c.length){a:if(!a.sp){c=a.sp;a.sp=!0;var d,e=null,f=a.Jc,f=f instanceof V?f:null;if(null===f||f.de)d=a.Jc.KD(a.port.Sd);else{if(!f.U.G()){a.sp=c;break a}e=f;d=e.JD()}var h=a.Xf.length=0,k=a.port.Xa(tb,v.L()),l=a.port.Xa(Fb,v.L()),f=v.gk(k.x,k.y,0,0);f.dj(l);v.v(k);v.v(l);k=v.Mb(f.x+f.width/2,f.y+f.height/2);for(d=d.i;d.next();)if(l=d.value,l.isVisible()){var m=sb,n=l.bd===a.port||l.T.Xh(e),m=n?Op(l,a.port):Pp(l,a.port);if(m.Ui()&&(n=n?l.Fd:l.bd,null!==n)){var p=
n.Y;if(null!==p){var n=l.computeOtherPoint(p,n),p=k.xc(n),m=iq(f,m,p,l.jc),q=0;0===m?(q=v.Xc,180<p&&(p-=360)):q=90===m?v.Wc:180===m?v.Nc:v.ed;m=a.Xf[h];void 0===m?(m=new jq(l,p,q),a.Xf[h]=m):(m.link=l,m.angle=p,m.fe=q);m.Uw.set(n);h++}}}v.v(k);a.Xf.sort(Sp.prototype.XH);e=a.Xf.length;k=-1;for(h=d=0;h<e;h++)m=a.Xf[h],void 0!==m&&(m.fe!==k&&(k=m.fe,d=0),m.dp=d,d++);k=-1;d=0;for(h=e-1;0<=h;h--)m=a.Xf[h],void 0!==m&&(m.fe!==k&&(k=m.fe,d=m.dp+1),m.Jm=d);h=a.Xf;n=a.port;e=a.Jc.dI;k=v.L();d=v.L();l=v.L();
m=v.L();n.Xa(tb,k);n.Xa(vb,d);n.Xa(Fb,l);n.Xa(Db,m);var r=q=p=n=0;if(e===pp)for(var s=0;s<h.length;s++){var t=h[s];if(null!==t){var u=t.link.computeThickness();switch(t.fe){case v.Wc:q+=u;break;case v.Nc:r+=u;break;case v.ed:n+=u;break;default:case v.Xc:p+=u}}}for(var y=0,w=0,x=1,s=0;s<h.length;s++)if(t=h[s],null!==t){var A,H;if(y!==t.fe){y=t.fe;switch(y){case v.Wc:A=l;H=m;break;case v.Nc:A=m;H=k;break;case v.ed:A=k;H=d;break;default:case v.Xc:A=d,H=l}var C=H.x-A.x;H=H.y-A.y;switch(y){case v.Wc:q>
Math.abs(C)?(x=Math.abs(C)/q,q=Math.abs(C)):x=1;break;case v.Nc:r>Math.abs(H)?(x=Math.abs(H)/r,r=Math.abs(H)):x=1;break;case v.ed:n>Math.abs(C)?(x=Math.abs(C)/n,n=Math.abs(C)):x=1;break;default:case v.Xc:p>Math.abs(H)?(x=Math.abs(H)/p,p=Math.abs(H)):x=1}w=0}var T=t.kp;if(e===pp){u=t.link.computeThickness();u*=x;T.set(A);switch(y){case v.Wc:T.x=A.x+C/2+q/2-w-u/2;break;case v.Nc:T.y=A.y+H/2+r/2-w-u/2;break;case v.ed:T.x=A.x+C/2-n/2+w+u/2;break;default:case v.Xc:T.y=A.y+H/2-p/2+w+u/2}w+=u}else u=.5,
e===mp&&(u=(t.dp+1)/(t.Jm+1)),T.x=A.x+C*u,T.y=A.y+H*u}v.v(k);v.v(d);v.v(l);v.v(m);A=a.Xf;for(C=0;C<A.length;C++)H=A[C],null!==H&&(H.ww=a.computeEndSegmentLength(H));a.sp=c;v.Vb(f)}c=a.Xf}for(f=0;f<c.length;f++)if(A=c[f],null!==A&&A.link===b)return A;return null}Sp.prototype.XH=function(a,b){return a===b?0:null===a?-1:null===b?1:a.fe<b.fe?-1:a.fe>b.fe?1:a.angle<b.angle?-1:a.angle>b.angle?1:0};
Sp.prototype.computeEndSegmentLength=function(a){var b=a.link,c=b.computeEndSegmentLength(this.Jc,this.port,sb,b.bd===this.port),d=a.dp;if(0>d)return c;var e=a.Jm;if(1>=e||!b.jc)return c;var b=a.Uw,f=a.kp;if(a.fe===v.Nc||a.fe===v.Wc)d=e-1-d;return((a=a.fe===v.Nc||a.fe===v.Xc)?b.y<f.y:b.x<f.x)?c+8*d:(a?b.y===f.y:b.x===f.x)?c:c+8*(e-1-d)};function jq(a,b,c){this.link=a;this.angle=b;this.fe=c;this.Uw=new z;this.Jm=this.dp=0;this.kp=new z;this.ww=0}
v.Nd(jq,{link:!0,angle:!0,fe:!0,Uw:!0,dp:!0,Jm:!0,kp:!0,ww:!0});jq.prototype.toString=function(){return this.link.toString()+" "+this.angle.toString()+" "+this.fe.toString()+":"+this.dp.toString()+"/"+this.Jm.toString()+" "+this.kp.toString()+" "+this.ww.toString()+" "+this.Uw.toString()};function xm(){this.Qj=this.oj=fc;this.Oj=this.mj=NaN;this.Nj=this.lj=np;this.Pj=this.nj=NaN}
xm.prototype.copy=function(){var a=new xm;a.oj=this.oj.R();a.Qj=this.Qj.R();a.mj=this.mj;a.Oj=this.Oj;a.lj=this.lj;a.Nj=this.Nj;a.nj=this.nj;a.Pj=this.Pj;return a};function V(a){0===arguments.length?S.call(this,Si):S.call(this,a);this.qr=new J(R);this.jo=new J(V);this.Ok=this.rr=this.pr=null;this.Gs=!1;this.Xq=!0;this.Is=!1;this.Gb=this.ws=null;this.dq=!1;this.eq=!0;this.Mq=this.fq=!1;this.Ud=new Yf;this.Ud.group=this;this.Cy=!1}v.Ja(V,S);v.ea("Group",V);
V.prototype.cloneProtected=function(a){S.prototype.cloneProtected.call(this,a);a.pr=this.pr;a.rr=this.rr;a.Ok=this.Ok;a.Gs=this.Gs;a.Xq=this.Xq;a.Is=this.Is;a.ws=this.ws;var b=a.ct(function(a){return a instanceof Mi});a.Gb=b instanceof Mi?b:null;a.dq=this.dq;a.eq=this.eq;a.fq=this.fq;a.Mq=this.Mq;null!==this.Ud?(a.Ud=this.Ud.copy(),a.Ud.group=a):(null!==a.Ud&&(a.Ud.group=null),a.Ud=null)};
V.prototype.Vh=function(a){S.prototype.Vh.call(this,a);var b=a.Wo();for(a=a.Ic;a.next();){var c=a.value;c.P();c.H(8);c.Vs();if(c instanceof S)c.bf(b);else if(c instanceof W)for(c=c.Sf;c.next();)c.value.bf(b)}};
V.prototype.an=function(a,b,c,d,e,f,h){if(a===Cf&&"elements"===b)if(e instanceof Mi){var k=e;null===this.Gb?this.Gb=k:this.Gb!==k&&v.k("Cannot insert a second Placeholder into the visual tree of a Group.")}else e instanceof D&&(k=e.ct(function(a){return a instanceof Mi}),k instanceof Mi&&(null===this.Gb?this.Gb=k:this.Gb!==k&&v.k("Cannot insert a second Placeholder into the visual tree of a Group.")));else a===Df&&"elements"===b&&null!==this.Gb&&(d===this.Gb?this.Gb=null:d instanceof D&&this.Gb.tl(d)&&
(this.Gb=null));S.prototype.an.call(this,a,b,c,d,e,f,h)};V.prototype.Li=function(a,b,c,d){this.Nk=this.Gb;D.prototype.Li.call(this,a,b,c,d)};V.prototype.ul=function(){if(!S.prototype.ul.call(this))return!1;for(var a=this.Ic;a.next();){var b=a.value;if(b instanceof S){if(b.isVisible()&&el(b))return!1}else if(b instanceof W&&b.isVisible()&&el(b)&&b.T!==this&&b.Z!==this)return!1}return!0};v.u(V,{placeholder:"placeholder"},function(){return this.Gb});
v.defineProperty(V,{tD:"computesBoundsAfterDrag"},function(){return this.dq},function(a){var b=this.dq;b!==a&&(v.j(a,"boolean",V,"computesBoundsAfterDrag"),this.dq=a,this.h("computesBoundsAfterDrag",b,a))});v.defineProperty(V,{qw:"computesBoundsIncludingLinks"},function(){return this.eq},function(a){v.j(a,"boolean",V,"computesBoundsIncludingLinks");var b=this.eq;b!==a&&(this.eq=a,this.h("computesBoundsIncludingLinks",b,a))});
v.defineProperty(V,{tG:"computesBoundsIncludingLocation"},function(){return this.fq},function(a){v.j(a,"boolean",V,"computesBoundsIncludingLocation");var b=this.fq;b!==a&&(this.fq=a,this.h("computesBoundsIncludingLocation",b,a))});v.defineProperty(V,{dH:"handlesDragDropForMembers"},function(){return this.Mq},function(a){v.j(a,"boolean",V,"handlesDragDropForMembers");var b=this.Mq;b!==a&&(this.Mq=a,this.h("handlesDragDropForMembers",b,a))});v.u(V,{Ic:"memberParts"},function(){return this.qr.i});
function ip(a,b){if(a.qr.add(b)){b instanceof V&&a.jo.add(b);var c=a.OH;if(null!==c){var d=!0,e=a.g;null!==e&&(d=e.Qa,e.Qa=!0);c(a,b);null!==e&&(e.Qa=d)}a.isVisible()&&a.de||b.nd(!1)}b instanceof W&&!a.qw||(c=a.Gb,null===c&&(c=a),c.P())}function hp(a,b){if(a.qr.remove(b)){b instanceof V&&a.jo.remove(b);var c=a.PH;if(null!==c){var d=!0,e=a.g;null!==e&&(d=e.Qa,e.Qa=!0);c(a,b);null!==e&&(e.Qa=d)}a.isVisible()&&a.de||b.nd(!0)}b instanceof W&&!a.qw||(c=a.Gb,null===c&&(c=a),c.P())}
V.prototype.Lm=function(){if(0<this.qr.count){var a=this.g;if(null!==a)for(var b=this.qr.copy().i;b.next();)a.remove(b.value)}S.prototype.Lm.call(this)};v.defineProperty(V,{Pb:"layout"},function(){return this.Ud},function(a){var b=this.Ud;b!==a&&(null!==a&&v.A(a,Yf,V,"layout"),null!==b&&(b.g=null,b.group=null),this.Ud=a,null!==a&&(a.g=this.g,a.group=this),this.h("layout",b,a))});
v.defineProperty(V,{OH:"memberAdded"},function(){return this.pr},function(a){var b=this.pr;b!==a&&(null!==a&&v.j(a,"function",V,"memberAdded"),this.pr=a,this.h("memberAdded",b,a))});v.defineProperty(V,{PH:"memberRemoved"},function(){return this.rr},function(a){var b=this.rr;b!==a&&(null!==a&&v.j(a,"function",V,"memberRemoved"),this.rr=a,this.h("memberRemoved",b,a))});
v.defineProperty(V,{Pz:"memberValidation"},function(){return this.Ok},function(a){var b=this.Ok;b!==a&&(null!==a&&v.j(a,"function",V,"memberValidation"),this.Ok=a,this.h("memberValidation",b,a))});V.prototype.canAddMembers=function(a){var b=this.g;if(null===b)return!1;b=b.nb;for(a=Zg(a).i;a.next();)if(!b.isValidMember(this,a.value))return!1;return!0};
V.prototype.addMembers=function(a,b){var c=this.g;if(null===c)return!1;for(var c=c.nb,d=!0,e=Zg(a).i;e.next();){var f=e.value;!b||c.isValidMember(this,f)?f.Sa=this:d=!1}return d};v.defineProperty(V,{RI:"ungroupable"},function(){return this.Gs},function(a){var b=this.Gs;b!==a&&(v.j(a,"boolean",V,"ungroupable"),this.Gs=a,this.h("ungroupable",b,a))});V.prototype.canUngroup=function(){if(!this.RI)return!1;var a=this.layer;if(null!==a&&!a.lw)return!1;a=a.g;return null===a||a.lw?!0:!1};
V.prototype.bf=function(a){void 0===a&&(a=null);S.prototype.bf.call(this,a);for(var b=this.JD();b.next();){var c=b.value;if(null===a||!a.contains(c)){var d=c.T;null!==d&&d!==this&&d.Xh(this)&&!d.isVisible()?c.ic():(d=c.Z,null!==d&&d!==this&&d.Xh(this)&&!d.isVisible()&&c.ic())}}};V.prototype.findExternalLinksConnected=V.prototype.JD=function(){var a=this.Wo();a.add(this);for(var b=new J(W),c=a.i;c.next();){var d=c.value;if(d instanceof S)for(d=d.te;d.next();){var e=d.value;a.contains(e)||b.add(e)}}return b.i};
V.prototype.findExternalNodesConnected=function(){var a=this.Wo();a.add(this);for(var b=new J(S),c=a.i;c.next();){var d=c.value;if(d instanceof S)for(d=d.te;d.next();){var e=d.value,f=e.T;a.contains(f)&&f!==this||b.add(f);e=e.Z;a.contains(e)&&e!==this||b.add(e)}}return b.i};V.prototype.findContainingGroupChain=function(){function a(b,d){null!==b&&(d.add(b),a(b.Sa,d))}var b=new J(R);a(this,b);return b};
V.prototype.findSubGraphParts=V.prototype.Wo=function(){var a=new J(R);Sg(a,this,!0,0,!0);a.remove(this);return a};V.prototype.nd=function(a){S.prototype.nd.call(this,a);for(var b=this.Ic;b.next();)b.value.nd(a)};V.prototype.collapseSubGraph=V.prototype.collapseSubGraph=function(){var a=this.g;if(null!==a&&!a.eh){a.eh=!0;var b=this.Wo();kq(this,b,a.Na,this);a.eh=!1}};
function kq(a,b,c,d){for(var e=a.Ic;e.next();){var f=e.value;f.nd(!1);if(f instanceof V){var h=f;h.de&&(h.DA=h.de,kq(h,b,c,d))}if(f instanceof S)f.bf(b),Tj(c,f,d);else if(f instanceof W)for(f=f.Sf;f.next();)f.value.bf(b)}a.de=!1}V.prototype.expandSubGraph=V.prototype.expandSubGraph=function(){var a=this.g;if(null!==a&&!a.eh){a.eh=!0;var b=this.Wo();lq(this,b,a.Na,this);a.eh=!1}};
function lq(a,b,c,d){for(var e=a.Ic;e.next();){var f=e.value;f.nd(!0);if(f instanceof V){var h=f;h.DA&&(h.DA=!1,lq(h,b,c,d))}if(f instanceof S)f.bf(b),Sj(c,f,d);else if(f instanceof W)for(f=f.Sf;f.next();)f.value.bf(b)}a.de=!0}
v.defineProperty(V,{de:"isSubGraphExpanded"},function(){return this.Xq},function(a){var b=this.Xq;if(b!==a){v.j(a,"boolean",V,"isSubGraphExpanded");this.Xq=a;var c=this.g;this.h("isSubGraphExpanded",b,a);b=this.FI;if(null!==b){var d=!0;null!==c&&(d=c.Qa,c.Qa=!0);b(this);null!==c&&(c.Qa=d)}null!==c&&c.la.ib?null!==this.Gb&&this.Gb.P():a?this.expandSubGraph():this.collapseSubGraph()}});
v.defineProperty(V,{DA:"wasSubGraphExpanded"},function(){return this.Is},function(a){var b=this.Is;b!==a&&(v.j(a,"boolean",V,"wasSubGraphExpanded"),this.Is=a,this.h("wasSubGraphExpanded",b,a))});v.defineProperty(V,{FI:"subGraphExpandedChanged"},function(){return this.ws},function(a){var b=this.ws;b!==a&&(null!==a&&v.j(a,"function",V,"subGraphExpandedChanged"),this.ws=a,this.h("subGraphExpandedChanged",b,a))});
V.prototype.move=function(a){var b=this.position,c=b.x;isNaN(c)&&(c=0);b=b.y;isNaN(b)&&(b=0);var c=a.x-c,b=a.y-b,d=v.Mb(c,b);S.prototype.move.call(this,a);for(a=this.Wo().i;a.next();){var e=a.value;if(!(e instanceof W||e instanceof S&&e.uf)){var f=e.position,h=e.location;f.G()?(d.x=f.x+c,d.y=f.y+b,e.position=d):h.G()&&(d.x=h.x+c,d.y=h.y+b,e.location=d)}}for(a.reset();a.next();)e=a.value,e instanceof W&&(f=e.position,d.x=f.x+c,d.y=f.y+b,e.move(d));v.v(d)};
function Mi(){G.call(this);this.Se=$c;this.bs=new B(NaN,NaN,NaN,NaN)}v.Ja(Mi,G);v.ea("Placeholder",Mi);Mi.prototype.cloneProtected=function(a){G.prototype.cloneProtected.call(this,a);a.Se=this.Se.R();a.bs=this.bs.copy()};Mi.prototype.Vj=function(a){if(null===this.background&&null===this.Bm)return!1;var b=this.Ha;return db(0,0,b.width,b.height,a.x,a.y)};
Mi.prototype.np=function(){var a=this.Y;null!==a&&(a instanceof V||a instanceof Tg)||v.k("Placeholder is not inside a Group or Adornment.");if(a instanceof V){var b=this.computeBorder(this.bs),c=this.Qc;Xa(c,b.width||0,b.height||0);Bm(this,0,0,c.width,c.height);for(var c=a.Ic,d=!1;c.next();)if(c.value.isVisible()){d=!0;break}!d||isNaN(b.x)||isNaN(b.y)||(c=new z,c.Yt(b,a.df),a.location=new z(c.x,c.y))}else{var b=this.xa,c=this.Qc,d=this.padding,e=d.left+d.right,f=d.top+d.bottom;if(b.G())Xa(c,b.width+
e||0,b.height+f||0),Bm(this,-d.left,-d.top,c.width,c.height);else{var h=a.Zb,k=h.Xa(tb,v.L()),b=v.gk(k.x,k.y,0,0);b.dj(h.Xa(Fb,k));b.dj(h.Xa(vb,k));b.dj(h.Xa(Db,k));a.wi.m(b.x,b.y);Xa(c,b.width+e||0,b.height+f||0);Bm(this,-d.left,-d.top,c.width,c.height);v.v(k);v.Vb(b)}}};Mi.prototype.Li=function(a,b,c,d){var e=this.U;e.x=a;e.y=b;e.width=c;e.height=d};
Mi.prototype.computeBorder=function(a){var b=this.Y;if(b instanceof V){var c=b;if(c.tD&&this.bs.G()){var d=c.g;if(null!==d&&(d=d.Wa,d instanceof Xg&&!d.Aq&&null!==d.bc&&!d.bc.contains(c)))return a.assign(this.bs),a}}var c=v.ag(),d=this.computeMemberBounds(c),e=this.padding;a.m(d.x-e.left,d.y-e.top,d.width+e.left+e.right,d.height+e.top+e.bottom);v.Vb(c);b instanceof V&&(c=b,c.tG&&c.location.G()&&a.dj(c.location));return a};
Mi.prototype.computeMemberBounds=function(a){if(!(this.Y instanceof V))return a.m(0,0,0,0),a;for(var b=this.Y,c=Infinity,d=Infinity,e=-Infinity,f=-Infinity,h=b.Ic;h.next();){var k=h.value;if(k.isVisible()){if(k instanceof W){var l=k;if(!b.qw)continue;if(dl(l))continue;if(l.T===b||l.Z===b)continue}k=k.U;k.left<c&&(c=k.left);k.top<d&&(d=k.top);k.right>e&&(e=k.right);k.bottom>f&&(f=k.bottom)}}isFinite(c)&&isFinite(d)?a.m(c,d,e-c,f-d):(b=b.location,c=this.padding,a.m(b.x+c.left,b.y+c.top,0,0));return a};
v.defineProperty(Mi,{padding:"padding"},function(){return this.Se},function(a){"number"===typeof a?((isNaN(a)||0>a)&&v.va(a,">= 0",Mi,"padding"),a=new eb(a)):(v.A(a,eb,Mi,"padding"),(isNaN(a.left)||0>a.left)&&v.va(a.left,">= 0",Mi,"padding:value.left"),(isNaN(a.right)||0>a.right)&&v.va(a.right,">= 0",Mi,"padding:value.right"),(isNaN(a.top)||0>a.top)&&v.va(a.top,">= 0",Mi,"padding:value.top"),(isNaN(a.bottom)||0>a.bottom)&&v.va(a.bottom,">= 0",Mi,"padding:value.bottom"));var b=this.Se;b.K(a)||(this.Se=
a=a.R(),this.h("padding",b,a))});function Yf(){0<arguments.length&&v.dd(Yf);v.ec(this);this.my=this.W=null;this.sj=this.Tq=!0;this.br=!1;this.Wp=(new z(0,0)).freeze();this.Vq=this.Wq=!0;this.wB="";this.Yn=!1;this.Dy=null}v.ea("Layout",Yf);Yf.prototype.cloneProtected=function(a){a.Tq=this.Tq;a.sj=this.sj;a.br=this.br;a.Wp.assign(this.Wp);a.Wq=this.Wq;a.Vq=this.Vq;a.wB=this.wB;this.sj||(a.Yn=!0)};Yf.prototype.copy=function(){var a=new this.constructor;this.cloneProtected(a);return a};
Yf.prototype.toString=function(){var a=v.$e(Object.getPrototypeOf(this)),a=a+"(";null!==this.group&&(a+=" in "+this.group);null!==this.g&&(a+=" for "+this.g);return a+")"};v.defineProperty(Yf,{g:"diagram"},function(){return this.W},function(a){null!==a&&v.A(a,E,Yf,"diagram");this.W=a});v.defineProperty(Yf,{group:"group"},function(){return this.my},function(a){this.my!==a&&(null!==a&&v.A(a,V,Yf,"group"),this.my=a,null!==a&&(this.W=a.g))});
v.defineProperty(Yf,{uH:"isOngoing"},function(){return this.Tq},function(a){this.Tq!==a&&(v.j(a,"boolean",Yf,"isOngoing"),this.Tq=a)});v.defineProperty(Yf,{hE:"isInitial"},function(){return this.sj},function(a){v.j(a,"boolean",Yf,"isInitial");this.sj=a;a||(this.Yn=!0)});v.defineProperty(Yf,{Lw:"isViewportSized"},function(){return this.br},function(a){this.br!==a&&(v.j(a,"boolean",Yf,"isViewportSized"),(this.br=a)&&this.H())});
v.defineProperty(Yf,{xt:"isRouting"},function(){return this.Wq},function(a){this.Wq!==a&&(v.j(a,"boolean",Yf,"isRouting"),this.Wq=a)});v.defineProperty(Yf,{jE:"isRealtime"},function(){return this.Vq},function(a){this.Vq!==a&&(v.j(a,"boolean",Yf,"isRealtime"),this.Vq=a)});v.defineProperty(Yf,{wf:"isValidLayout"},function(){return this.Yn},function(a){this.Yn!==a&&(v.j(a,"boolean",Yf,"isValidLayout"),this.Yn=a,a||(a=this.g,null!==a&&(a.vu=!0)))});
Yf.prototype.invalidateLayout=Yf.prototype.H=function(){if(this.Yn){var a=this.g;if(null!==a&&!a.la.ib){var b=a.Na;!b.Xn&&(b.cf&&b.Zh(),this.uH&&a.Tm||this.hE&&!a.Tm)&&(this.wf=!1,a.ue())}}};v.defineProperty(Yf,{network:"network"},function(){return this.Dy},function(a){var b=this.Dy;b!==a&&(null!==a&&v.A(a,Zf,Yf,"network"),null!==b&&(b.Pb=null),this.Dy=a,null!==a&&(a.Pb=this))});Yf.prototype.createNetwork=function(){return new Zf};
Yf.prototype.makeNetwork=function(a){var b=this.createNetwork();b.Pb=this;a instanceof E?(b.Sj(a.Ag,!0),b.Sj(a.links,!0)):a instanceof V?b.Sj(a.Ic):b.Sj(a.i);return b};Yf.prototype.updateParts=function(){var a=this.g;if(null===a&&null!==this.network)for(var b=this.network.vertexes.i;b.next();){var c=b.value.Jc;if(null!==c&&(a=c.g,null!==a))break}this.wf=!0;try{null!==a&&a.Qb("Layout"),this.commitLayout()}finally{null!==a&&a.wd("Layout")}};
Yf.prototype.commitLayout=function(){for(var a=this.network.vertexes.i;a.next();)a.value.commit();if(this.xt)for(a=this.network.edges.i;a.next();)a.value.commit()};
Yf.prototype.doLayout=function(a){null===a&&v.k("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts");var b=new J(R);a instanceof E?(mq(this,b,a.Ag,!0,this.Uz,!0,!1,!0),mq(this,b,a.dk,!0,this.Uz,!0,!1,!0)):a instanceof V?mq(this,b,a.Ic,!1,this.Uz,!0,!1,!0):b.Tc(a.i);var c=b.count;if(0<c){a=this.g;null!==a&&a.Qb("Layout");for(var c=Math.ceil(Math.sqrt(c)),d=this.Xd.x,e=d,f=this.Xd.y,h=0,k=0,b=b.i;b.next();){var l=b.value;l.sf();var m=l.Ca,n=m.width,
m=m.height;l.moveTo(e,f);l.Cy=!1;e+=Math.max(n,50)+20;k=Math.max(k,Math.max(m,50));h>=c-1?(h=0,e=d,f+=k+20,k=0):h++}null!==a&&a.wd("Layout")}this.wf=!0};Yf.prototype.Uz=function(a){return!a.location.G()||a instanceof V&&a.Cy?!0:!1};
function mq(a,b,c,d,e,f,h,k){for(c=c.i;c.next();){var l=c.value;d&&!l.gp||null!==e&&!e(l)||!l.canLayout()||(f&&l instanceof S?l.uf||(l instanceof V?null===l.Pb?mq(a,b,l.Ic,!1,e,f,h,k):b.add(l):b.add(l)):h&&l instanceof W?b.add(l):!k||!l.Pd()||l instanceof S||b.add(l))}}
Yf.prototype.collectParts=Yf.prototype.nG=function(a){var b=new J(R);a instanceof E?(mq(this,b,a.Ag,!0,null,!0,!0,!0),mq(this,b,a.links,!0,null,!0,!0,!0),mq(this,b,a.dk,!0,null,!0,!0,!0)):a instanceof V?mq(this,b,a.Ic,!1,null,!0,!0,!0):mq(this,b,a.i,!1,null,!0,!0,!0);return b};v.defineProperty(Yf,{Xd:"arrangementOrigin"},function(){return this.Wp},function(a){v.A(a,z,Yf,"arrangementOrigin");this.Wp.K(a)||(this.Wp.assign(a),this.H())});
Yf.prototype.initialOrigin=function(a){var b=this.group;if(null!==b){var c=b.position.copy();(isNaN(c.x)||isNaN(c.y))&&c.set(a);b=b.placeholder;null!==b&&(c=b.Xa(tb),(isNaN(c.x)||isNaN(c.y))&&c.set(a),c.x+=b.padding.left,c.y+=b.padding.top);return c}return a};function Zf(){v.ec(this);this.Ud=null;this.clear()}v.ea("LayoutNetwork",Zf);
Zf.prototype.clear=function(){if(this.vertexes)for(var a=this.vertexes.i;a.next();){var b=a.value;b.clear();b.network=null}if(this.edges)for(a=this.edges.i;a.next();)b=a.value,b.clear(),b.network=null;this.vertexes=new J(nq);this.edges=new J(oq);this.Yz=new na(S,nq);this.Kz=new na(W,oq)};
Zf.prototype.toString=function(a){void 0===a&&(a=0);var b="LayoutNetwork"+(null!==this.Pb?"("+this.Pb.toString()+")":"");if(0>=a)return b;b+=" vertexes: "+this.vertexes.count+" edges: "+this.edges.count;if(1<a){for(var c=this.vertexes.i;c.next();)b+="\n    "+c.value.toString(a-1);for(c=this.edges.i;c.next();)b+="\n    "+c.value.toString(a-1)}return b};v.defineProperty(Zf,{Pb:"layout"},function(){return this.Ud},function(a){this.Ud=a});Zf.prototype.createVertex=function(){return new nq};
Zf.prototype.createEdge=function(){return new oq};
Zf.prototype.addParts=Zf.prototype.Sj=function(a,b,c){if(null!==a){void 0===b&&(b=!1);v.j(b,"boolean",Zf,"addParts:toplevelonly");void 0===c&&(c=null);null===c&&(c=function(a){if(a instanceof S)return!a.uf;if(a instanceof W){var b=a.T;if(null===b||b.uf)return!1;a=a.Z;return null===a||a.uf?!1:!0}return!1});for(a=a.i;a.next();){var d=a.value;if(d instanceof S&&(!b||d.gp)&&d.canLayout()&&c(d))if(d instanceof V&&null===d.Pb)this.Sj(d.Ic,!1);else if(null===this.Nm(d)){var e=this.createVertex();e.Jc=d;
this.Zk(e)}}for(a.reset();a.next();)if(d=a.value,d instanceof W&&(!b||d.gp)&&d.canLayout()&&c(d)&&null===this.yw(d)){var f=d.T,e=d.Z;null!==f&&null!==e&&f!==e&&(f=this.findGroupVertex(f),e=this.findGroupVertex(e),null!==f&&null!==e&&this.mp(f,e,d))}}};Zf.prototype.findGroupVertex=function(a){if(null===a)return null;var b=a.findVisibleNode();if(null===b)return null;a=this.Nm(b);if(null!==a)return a;for(b=b.Sa;null!==b;){a=this.Nm(b);if(null!==a)return a;b=b.Sa}return null};
Zf.prototype.addVertex=Zf.prototype.Zk=function(a){if(null!==a){this.vertexes.add(a);var b=a.Jc;null!==b&&this.Yz.add(b,a);a.network=this}};Zf.prototype.addNode=Zf.prototype.Ps=function(a){if(null===a)return null;var b=this.Nm(a);null===b&&(b=this.createVertex(),b.Jc=a,this.Zk(b));return b};Zf.prototype.deleteVertex=Zf.prototype.DD=function(a){if(null!==a&&pq(this,a)){for(var b=a.Oe,c=b.count-1;0<=c;c--){var d=b.da(c);this.So(d)}b=a.Je;for(c=b.count-1;0<=c;c--)d=b.da(c),this.So(d)}};
function pq(a,b){if(null===b)return!1;var c=a.vertexes.remove(b);c&&(a.Yz.remove(b.Jc),b.network=null);return c}Zf.prototype.deleteNode=function(a){null!==a&&(a=this.Nm(a),null!==a&&this.DD(a))};Zf.prototype.findVertex=Zf.prototype.Nm=function(a){return null===a?null:this.Yz.ja(a)};Zf.prototype.addEdge=Zf.prototype.Jo=function(a){if(null!==a){this.edges.add(a);var b=a.link;null!==b&&null===this.yw(b)&&this.Kz.add(b,a);b=a.toVertex;null!==b&&b.eD(a);b=a.fromVertex;null!==b&&b.dD(a);a.network=this}};
Zf.prototype.addLink=function(a){if(null===a)return null;var b=a.T,c=a.Z,d=this.yw(a);null===d?(d=this.createEdge(),d.link=a,null!==b&&(d.fromVertex=this.Ps(b)),null!==c&&(d.toVertex=this.Ps(c)),this.Jo(d)):(d.fromVertex=null!==b?this.Ps(b):null,d.toVertex=null!==c?this.Ps(c):null);return d};Zf.prototype.deleteEdge=Zf.prototype.So=function(a){if(null!==a){var b=a.toVertex;null!==b&&b.CD(a);b=a.fromVertex;null!==b&&b.BD(a);qq(this,a)}};
function qq(a,b){null!==b&&a.edges.remove(b)&&(a.Kz.remove(b.link),b.network=null)}Zf.prototype.deleteLink=function(a){null!==a&&(a=this.yw(a),null!==a&&this.So(a))};Zf.prototype.findEdge=Zf.prototype.yw=function(a){return null===a?null:this.Kz.ja(a)};Zf.prototype.linkVertexes=Zf.prototype.mp=function(a,b,c){if(null===a||null===b)return null;if(a.network===this&&b.network===this){var d=this.createEdge();d.link=c;d.fromVertex=a;d.toVertex=b;this.Jo(d);return d}return null};
Zf.prototype.reverseEdge=Zf.prototype.gx=function(a){if(null!==a){var b=a.fromVertex,c=a.toVertex;null!==b&&null!==c&&(b.BD(a),c.CD(a),a.gx(),b.eD(a),c.dD(a))}};Zf.prototype.deleteSelfEdges=Zf.prototype.uw=function(){for(var a=v.jb(),b=this.edges.i;b.next();){var c=b.value;c.fromVertex===c.toVertex&&a.push(c)}b=a.length;for(c=0;c<b;c++)this.So(a[c]);v.sa(a)};
Zf.prototype.deleteArtificialVertexes=function(){for(var a=v.jb(),b=this.vertexes.i;b.next();){var c=b.value;null===c.Jc&&a.push(c)}c=a.length;for(b=0;b<c;b++)this.DD(a[b]);c=v.jb();for(b=this.edges.i;b.next();){var d=b.value;null===d.link&&c.push(d)}d=c.length;for(b=0;b<d;b++)this.So(c[b]);v.sa(a);v.sa(c)};function rq(a){for(var b=v.jb(),c=a.edges.i;c.next();){var d=c.value;null!==d.fromVertex&&null!==d.toVertex||b.push(d)}c=b.length;for(d=0;d<c;d++)a.So(b[d]);v.sa(b)}
Zf.prototype.splitIntoSubNetworks=Zf.prototype.EI=function(){this.deleteArtificialVertexes();rq(this);this.uw();for(var a=new I(Zf),b=!0;b;)for(var b=!1,c=this.vertexes.i;c.next();){var d=c.value;if(0<d.Oe.count||0<d.Je.count){b=this.Pb.createNetwork();a.add(b);sq(this,b,d);b=!0;break}}a.sort(function(a,b){return null===a||null===b||a===b?0:b.vertexes.count-a.vertexes.count});return a};
function sq(a,b,c){if(null!==c&&c.network!==b){pq(a,c);b.Zk(c);for(var d=c.lc;d.next();){var e=d.value;e.network!==b&&(qq(a,e),b.Jo(e),sq(a,b,e.fromVertex))}for(d=c.ac;d.next();)c=d.value,c.network!==b&&(qq(a,c),b.Jo(c),sq(a,b,c.toVertex))}}Zf.prototype.findAllParts=function(){for(var a=new J(R),b=this.vertexes.i;b.next();)a.add(b.value.Jc);for(b=this.edges.i;b.next();)a.add(b.value.link);return a};
function nq(){v.ec(this);this.network=null;this.M=(new B(0,0,10,10)).freeze();this.O=(new z(5,5)).freeze();this.clear()}v.ea("LayoutVertex",nq);nq.prototype.clear=function(){this.qd=this.ie=null;this.Oe=new I(oq);this.Je=new I(oq)};
nq.prototype.toString=function(a){void 0===a&&(a=0);var b="LayoutVertex#"+v.ld(this);if(0<a&&(b+=null!==this.Jc?"("+this.Jc.toString()+")":"",1<a)){a="";for(var c=!0,d=this.Oe.i;d.next();){var e=d.value;c?c=!1:a+=",";a+=e.toString(0)}e="";c=!0;for(d=this.Je.i;d.next();){var f=d.value;c?c=!1:e+=",";e+=f.toString(0)}b+=" sources: "+a+" destinations: "+e}return b};
v.defineProperty(nq,{data:"data"},function(){return this.ie},function(a){this.ie=a;if(null!==a){var b=a.bounds;a=b.x;var c=b.y,d=b.width,b=b.height;this.O.m(d/2,b/2);this.M.m(a,c,d,b)}});v.defineProperty(nq,{Jc:"node"},function(){return this.qd},function(a){if(this.qd!==a){this.qd=a;a.sf();var b=a.U,c=b.x,d=b.y,e=b.width,b=b.height;isNaN(c)&&(c=0);isNaN(d)&&(d=0);this.M.m(c,d,e,b);if(!(a instanceof V)&&(a=a.cc.Xa(Ab),a.G())){this.O.m(a.x-c,a.y-d);return}this.O.m(e/2,b/2)}});
v.defineProperty(nq,{mb:"bounds"},function(){return this.M},function(a){this.M.K(a)||this.M.assign(a)});v.defineProperty(nq,{focus:"focus"},function(){return this.O},function(a){this.O.K(a)||this.O.assign(a)});v.defineProperty(nq,{na:"centerX"},function(){return this.M.x+this.O.x},function(a){var b=this.M;b.x+this.O.x!==a&&(b.Oa(),b.x=a-this.O.x,b.freeze())});v.defineProperty(nq,{ua:"centerY"},function(){return this.M.y+this.O.y},function(a){var b=this.M;b.y+this.O.y!==a&&(b.Oa(),b.y=a-this.O.y,b.freeze())});
v.defineProperty(nq,{it:"focusX"},function(){return this.O.x},function(a){var b=this.O;b.x!==a&&(b.Oa(),b.x=a,b.freeze())});v.defineProperty(nq,{jt:"focusY"},function(){return this.O.y},function(a){var b=this.O;b.y!==a&&(b.Oa(),b.y=a,b.freeze())});v.defineProperty(nq,{x:"x"},function(){return this.M.x},function(a){var b=this.M;b.x!==a&&(b.Oa(),b.x=a,b.freeze())});v.defineProperty(nq,{y:"y"},function(){return this.M.y},function(a){var b=this.M;b.y!==a&&(b.Oa(),b.y=a,b.freeze())});
v.defineProperty(nq,{width:"width"},function(){return this.M.width},function(a){var b=this.M;b.width!==a&&(b.Oa(),b.width=a,b.freeze())});v.defineProperty(nq,{height:"height"},function(){return this.M.height},function(a){var b=this.M;b.height!==a&&(b.Oa(),b.height=a,b.freeze())});
nq.prototype.commit=function(){var a=this.ie;if(null!==a){var b=this.mb,c=a.bounds;v.Ta(c)?(c.x=b.x,c.y=b.y,c.width=b.width,c.height=b.height):a.bounds=b.copy()}else if(a=this.Jc,null!==a){b=this.mb;if(!(a instanceof V)){var c=a.U,d=a.cc.Xa(Ab);if(c.G()&&d.G()){a.moveTo(b.x+this.it-(d.x-c.x),b.y+this.jt-(d.y-c.y));return}}a.moveTo(b.x,b.y)}};nq.prototype.addSourceEdge=nq.prototype.eD=function(a){null!==a&&(this.Oe.contains(a)||this.Oe.add(a))};
nq.prototype.deleteSourceEdge=nq.prototype.CD=function(a){null!==a&&this.Oe.remove(a)};nq.prototype.addDestinationEdge=nq.prototype.dD=function(a){null!==a&&(this.Je.contains(a)||this.Je.add(a))};nq.prototype.deleteDestinationEdge=nq.prototype.BD=function(a){null!==a&&this.Je.remove(a)};v.u(nq,{DI:"sourceVertexes"},function(){for(var a=new J(nq),b=this.lc;b.next();)a.add(b.value.fromVertex);return a.i});
v.u(nq,{GG:"destinationVertexes"},function(){for(var a=new J(nq),b=this.ac;b.next();)a.add(b.value.toVertex);return a.i});v.u(nq,{vertexes:"vertexes"},function(){for(var a=new J(nq),b=this.lc;b.next();)a.add(b.value.fromVertex);for(b=this.ac;b.next();)a.add(b.value.toVertex);return a.i});v.u(nq,{lc:"sourceEdges"},function(){return this.Oe.i});v.u(nq,{ac:"destinationEdges"},function(){return this.Je.i});
v.u(nq,{edges:"edges"},function(){for(var a=new I(oq),b=this.lc;b.next();)a.add(b.value);for(b=this.ac;b.next();)a.add(b.value);return a.i});v.u(nq,{LG:"edgesCount"},function(){return this.Oe.count+this.Je.count});var tq;nq.standardComparer=tq=function(a,b){var c=a.qd,d=b.qd;return c?d?(c=c.text,d=d.text,c<d?-1:c>d?1:0):1:null!==d?-1:0};
nq.smartComparer=function(a,b){if(null!==a){if(null!==b){var c=a.qd,d=b.qd;if(null!==c){if(null!==d){for(var c=c.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/),d=d.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/),e=0;e<c.length;e++)if(""!==d[e]&&void 0!==d[e]){var f=parseFloat(c[e]),h=parseFloat(d[e]);if(isNaN(f)){if(!isNaN(h))return 1;if(0!==c[e].localeCompare(d[e]))return c[e].localeCompare(d[e])}else{if(isNaN(h))return-1;if(0!==f-h)return f-
h}}else if(""!==c[e])return 1;return""!==d[e]&&void 0!==d[e]?-1:0}return 1}return null!==d?-1:0}return 1}return null!==b?-1:0};function oq(){v.ec(this);this.network=null;this.clear()}v.ea("LayoutEdge",oq);oq.prototype.clear=function(){this.toVertex=this.fromVertex=this.link=this.data=null};
oq.prototype.toString=function(a){void 0===a&&(a=0);var b="LayoutEdge#"+v.ld(this);0<a&&(b+=null!==this.link?"("+this.link.toString()+")":"",1<a&&(b+=" "+(this.fromVertex?this.fromVertex.toString():"null")+" --\x3e "+(this.toVertex?this.toVertex.toString():"null")));return b};oq.prototype.gx=function(){var a=this.fromVertex;this.fromVertex=this.toVertex;this.toVertex=a};oq.prototype.commit=function(){};
oq.prototype.getOtherVertex=oq.prototype.WG=function(a){return this.toVertex===a?this.fromVertex:this.fromVertex===a?this.toVertex:null};function lm(){0<arguments.length&&v.dd(lm);Yf.call(this);this.Lw=!0;this.Ks=this.Ls=NaN;this.hj=(new ia(NaN,NaN)).freeze();this.Mh=(new ia(10,10)).freeze();this.Ae=tn;this.fd=mn;this.Lh=hn;this.uh=uq}v.Ja(lm,Yf);v.ea("GridLayout",lm);
lm.prototype.cloneProtected=function(a){Yf.prototype.cloneProtected.call(this,a);a.Ls=this.Ls;a.Ks=this.Ks;a.hj.assign(this.hj);a.Mh.assign(this.Mh);a.Ae=this.Ae;a.fd=this.fd;a.Lh=this.Lh;a.uh=this.uh};
lm.prototype.doLayout=function(a){null===a&&v.k("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts");this.Xd=this.initialOrigin(this.Xd);var b=this.g;a=this.nG(a);for(var c=a.copy().i;c.next();){var d=c.value;if(d instanceof W){var e=d;if(null!==e.T||null!==e.Z){a.remove(e);continue}}d.sf();if(d instanceof V)for(d=d.Ic;d.next();)a.remove(d.value)}e=a.dc();if(0!==e.length){switch(this.sorting){case ln:e.reverse();break;case hn:e.sort(this.comparer);
break;case jn:e.sort(this.comparer),e.reverse()}var f=this.VI;isNaN(f)&&(f=0);var h=this.vF,h=isNaN(h)&&null!==b?Math.max(b.rb.width-b.padding.left-b.padding.right,0):Math.max(this.vF,0);0>=f&&0>=h&&(f=1);a=this.spacing.width;isFinite(a)||(a=0);c=this.spacing.height;isFinite(c)||(c=0);null!==b&&b.Qb("Layout");d=[];switch(this.alignment){case un:var k=a,l=c,m=Math.max(this.No.width,1);if(!isFinite(m))for(var n=m=0;n<e.length;n++)var p=e[n],q=p.Ca,m=Math.max(m,q.width);var m=Math.max(m+k,1),r=Math.max(this.No.height,
1);if(!isFinite(r))for(n=r=0;n<e.length;n++)p=e[n],q=p.Ca,r=Math.max(r,q.height);for(var r=Math.max(r+l,1),s=this.Nf,t=this.Xd.x,u=t,y=this.Xd.y,w=0,x=0,n=0;n<e.length;n++){var p=e[n],q=p.Ca,A=Math.ceil((q.width+k)/m)*m,H=Math.ceil((q.height+l)/r)*r,C=0;switch(s){case nn:C=Math.abs(u-q.width);break;default:C=u+q.width}if(0<f&&w>f-1||0<h&&0<w&&C>h)d.push(new B(0,y,h+k,x)),w=0,u=t,y+=x,x=0;x=Math.max(x,H);H=0;switch(s){case nn:H=-q.width;break;default:H=0}p.moveTo(u+H,y);switch(s){case nn:u-=A;break;
default:u+=A}w++}d.push(new B(0,y,h+k,x));break;case tn:k=f;l=a;m=c;n=Math.max(this.No.width,1);p=y=A=0;q=v.L();for(f=0;f<e.length;f++)r=e[f],s=r.Ca,t=cp(r,r.cc,r.df,q),A=Math.max(A,t.x),y=Math.max(y,s.width-t.x),p=Math.max(p,t.y);u=this.Nf;switch(u){case nn:A+=l;break;default:y+=l}var n=isFinite(n)?Math.max(n+l,1):Math.max(A+y,1),T=y=this.Xd.x,w=this.Xd.y,x=0;h>=A&&(h-=A);for(var A=H=0,C=Math.max(this.No.height,1),ba=p=0,U=!0,M=v.L(),f=0;f<e.length;f++){r=e[f];s=r.Ca;t=cp(r,r.cc,r.df,q);if(0<x)switch(u){case nn:T=
(T-y-(s.width-t.x))/n;T=Ec(Math.round(T),T)?Math.round(T):Math.floor(T);T=T*n+y;break;default:T=(T-y+t.x)/n,T=Ec(Math.round(T),T)?Math.round(T):Math.ceil(T),T=T*n+y}else switch(u){case nn:H=T+t.x+s.width;break;default:H=T-t.x}var aa=0;switch(u){case nn:aa=-(T+t.x)+H;break;default:aa=T+s.width-t.x-H}if(0<k&&x>k-1||0<h&&0<x&&aa>h){d.push(new B(0,U?w-p:w,h+l,ba+p+m));for(T=0;T<x&&f!==x;T++){var aa=e[f-x+T],Ia=cp(aa,aa.cc,aa.df,M);aa.moveTo(aa.position.x,aa.position.y+p-Ia.y)}ba+=m;w=U?w+ba:w+(ba+p);
x=ba=p=0;T=y;U=!1}T===y&&(A=u===nn?Math.max(A,s.width-t.x):Math.min(A,-t.x));p=Math.max(p,t.y);ba=Math.max(ba,s.height-t.y);isFinite(C)&&(ba=Math.max(ba,Math.max(s.height,C)-t.y));U?r.moveTo(T-t.x,w-t.y):r.moveTo(T-t.x,w);switch(u){case nn:T-=t.x+l;break;default:T+=s.width-t.x+l}x++}d.push(new B(0,w,h+l,(U?ba:ba+p)+m));for(T=0;T<x&&f!==x;T++)aa=e[f-x+T],Ia=cp(aa,aa.cc,aa.df,q),aa.moveTo(aa.position.x,aa.position.y+p-Ia.y);v.v(q);v.v(M);if(u===nn)for(f=0;f<d.length;f++)e=d[f],e.width+=A,e.x-=A;else for(f=
0;f<d.length;f++)e=d[f],e.x>A&&(e.width+=e.x-A,e.x=A)}for(k=f=h=e=0;k<d.length;k++)l=d[k],e=Math.min(e,l.x),h=Math.min(h,l.y),f=Math.max(f,l.x+l.width);this.Nf===nn?this.commitLayers(d,new z(e+a/2-(f+e),h-c/2)):this.commitLayers(d,new z(e-a/2,h-c/2));null!==b&&b.wd("Layout");this.wf=!0}};lm.prototype.commitLayers=function(){};
v.defineProperty(lm,{vF:"wrappingWidth"},function(){return this.Ls},function(a){this.Ls!==a&&(v.j(a,"number",lm,"wrappingWidth"),0<a||isNaN(a))&&(this.Ls=a,this.Lw=isNaN(a),this.H())});v.defineProperty(lm,{VI:"wrappingColumn"},function(){return this.Ks},function(a){this.Ks!==a&&(v.j(a,"number",lm,"wrappingColumn"),0<a||isNaN(a))&&(this.Ks=a,this.H())});v.defineProperty(lm,{No:"cellSize"},function(){return this.hj},function(a){v.A(a,ia,lm,"cellSize");this.hj.K(a)||(this.hj.assign(a),this.H())});
v.defineProperty(lm,{spacing:"spacing"},function(){return this.Mh},function(a){v.A(a,ia,lm,"spacing");this.Mh.K(a)||(this.Mh.assign(a),this.H())});v.defineProperty(lm,{alignment:"alignment"},function(){return this.Ae},function(a){this.Ae!==a&&(v.pb(a,lm,lm,"alignment"),a===tn||a===un)&&(this.Ae=a,this.H())});v.defineProperty(lm,{Nf:"arrangement"},function(){return this.fd},function(a){this.fd!==a&&(v.pb(a,lm,lm,"arrangement"),a===mn||a===nn)&&(this.fd=a,this.H())});
v.defineProperty(lm,{sorting:"sorting"},function(){return this.Lh},function(a){this.Lh!==a&&(v.pb(a,lm,lm,"sorting"),a===kn||a===ln||a===hn||a===jn)&&(this.Lh=a,this.H())});v.defineProperty(lm,{comparer:"comparer"},function(){return this.uh},function(a){this.uh!==a&&(v.j(a,"function",lm,"comparer"),this.uh=a,this.H())});var uq;lm.standardComparer=uq=function(a,b){var c=a.text,d=b.text;return c<d?-1:c>d?1:0};
lm.smartComparer=function(a,b){if(null!==a){if(null!==b){for(var c=a.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/),d=b.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/),e=0;e<c.length;e++)if(""!==d[e]&&void 0!==d[e]){var f=parseFloat(c[e]),h=parseFloat(d[e]);if(isNaN(f)){if(!isNaN(h))return 1;if(0!==c[e].localeCompare(d[e]))return c[e].localeCompare(d[e])}else{if(isNaN(h))return-1;if(0!==f-h)return f-h}}else if(""!==c[e])return 1;return""!==
d[e]&&void 0!==d[e]?-1:0}return 1}return null!==b?-1:0};var un;lm.Position=un=v.p(lm,"Position",0);var tn;lm.Location=tn=v.p(lm,"Location",1);var mn;lm.LeftToRight=mn=v.p(lm,"LeftToRight",2);var nn;lm.RightToLeft=nn=v.p(lm,"RightToLeft",3);var kn;lm.Forward=kn=v.p(lm,"Forward",4);var ln;lm.Reverse=ln=v.p(lm,"Reverse",5);var hn;lm.Ascending=hn=v.p(lm,"Ascending",6);var jn;lm.Descending=jn=v.p(lm,"Descending",7);
function vn(){0<arguments.length&&v.dd(vn);Yf.call(this);this.ay=this.Kn=this.Jd=0;this.Bq=360;this.$x=Hn;this.yk=0;this.DB=Hn;this.Du=this.sg=this.TC=0;this.cw=new vq;this.Fu=this.hm=0;this.MF=600;this.Pr=NaN;this.Yp=1;this.vs=0;this.xs=360;this.fd=Hn;this.aa=Dn;this.Lh=An;this.uh=tq;this.Mh=6;this.Er=Kn}v.Ja(vn,Yf);v.ea("CircularLayout",vn);
vn.prototype.cloneProtected=function(a){Yf.prototype.cloneProtected.call(this,a);a.Pr=this.Pr;a.Yp=this.Yp;a.vs=this.vs;a.xs=this.xs;a.fd=this.fd;a.aa=this.aa;a.Lh=this.Lh;a.uh=this.uh;a.Mh=this.Mh;a.Er=this.Er};vn.prototype.createNetwork=function(){return new wq};
vn.prototype.doLayout=function(a){null===a&&v.k("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts");null===this.network&&(this.network=this.makeNetwork(a));a=this.network.vertexes;if(1>=a.count)1===a.count&&(a=a.first(),a.na=0,a.ua=0);else{var b=new I(xq);b.Tc(a.i);a=new I(xq);var c=new I(xq),d;d=this.sort(b);var e=this.$x,f=this.DB,h=this.Jd,k=this.Kn,l=this.ay,m=this.Bq,b=this.yk,n=this.TC,p=this.sg,q=this.Du,e=this.Nf,f=this.Rw,h=this.eI;if(!isFinite(h)||
0>=h)h=NaN;k=this.hG;if(!isFinite(k)||0>=k)k=1;l=this.xe;isFinite(l)||(l=0);m=this.Af;if(!isFinite(m)||360<m||1>m)m=360;b=this.spacing;isFinite(b)||(b=NaN);e===In&&f===Jn?e=Hn:e===In&&f!==Jn&&(f=Jn,e=this.Nf);if((this.direction===Bn||this.direction===Cn)&&this.sorting!==An){for(var r=0;!(r>=d.length);r+=2){a.add(d.da(r));if(r+1>=d.length)break;c.add(d.da(r+1))}this.direction===Bn?(this.Nf===In&&a.reverse(),d=new I(xq),d.Tc(a),d.Tc(c)):(this.Nf===In&&c.reverse(),d=new I(xq),d.Tc(c),d.Tc(a))}for(var s=
d.length,t=n=0,r=0;r<d.length;r++){var p=l+m*t*(this.direction===Dn?1:-1)/s,u=d.da(r).diameter;isNaN(u)&&(u=yq(d.da(r),p));360>m&&(0===r||r===d.length-1)&&(u/=2);n+=u;t++}if(isNaN(h)||e===In){isNaN(b)&&(b=6);if(e!==Hn&&e!==In){u=-Infinity;for(r=0;r<s;r++){var q=d.da(r),y=d.da(r===s-1?0:r+1);isNaN(q.diameter)&&yq(q,0);isNaN(y.diameter)&&yq(y,0);u=Math.max(u,(q.diameter+y.diameter)/2)}q=u+b;e===Fn?(p=2*Math.PI/s,h=(u+b)/p):h=zq(this,q*(360<=m?s:s-1),k,l*Math.PI/180,m*Math.PI/180)}else h=zq(this,n+(360<=
m?s:s-1)*(e!==In?b:1.6*b),k,l*Math.PI/180,m*Math.PI/180);p=h*k}else if(p=h*k,t=Aq(this,h,p,l*Math.PI/180,m*Math.PI/180),isNaN(b)){if(e===Hn||e===In)b=(t-n)/(360<=m?s:s-1)}else if(e===Hn||e===In)r=(t-n)/(360<=m?s:s-1),r<b?(h=zq(this,n+b*(360<=m?s:s-1),k,l*Math.PI/180,m*Math.PI/180),p=h*k):b=r;else{u=-Infinity;for(r=0;r<s;r++)q=d.da(r),y=d.da(r===s-1?0:r+1),isNaN(q.diameter)&&yq(q,0),isNaN(y.diameter)&&yq(y,0),u=Math.max(u,(q.diameter+y.diameter)/2);q=u+b;r=zq(this,q*(360<=m?s:s-1),k,l*Math.PI/180,
m*Math.PI/180);r>h?(h=r,p=h*k):q=t/(360<=m?s:s-1)}this.$x=e;this.DB=f;this.Jd=h;this.Kn=k;this.ay=l;this.Bq=m;this.yk=b;this.TC=n;this.sg=p;this.Du=q;b=d;d=this.$x;e=this.Jd;f=this.ay;k=this.Bq;l=this.yk;m=this.sg;n=this.Du;if(this.direction!==Bn&&this.direction!==Cn||d!==In)if(this.direction===Bn||this.direction===Cn){h=0;switch(d){case Gn:h=180*Bq(this,e,m,f,n)/Math.PI;break;case Hn:n=b=0;h=a.first();null!==h&&(b=yq(h,Math.PI/2));h=c.first();null!==h&&(n=yq(h,Math.PI/2));h=180*Bq(this,e,m,f,l+(b+
n)/2)/Math.PI;break;case Fn:h=k/b.length}if(this.direction===Bn){switch(d){case Gn:Cq(this,a,f,En);break;case Hn:Dq(this,a,f,En);break;case Fn:Eq(this,a,k/2,f,En)}switch(d){case Gn:Cq(this,c,f+h,Dn);break;case Hn:Dq(this,c,f+h,Dn);break;case Fn:Eq(this,c,k/2,f+h,Dn)}}else{switch(d){case Gn:Cq(this,c,f,En);break;case Hn:Dq(this,c,f,En);break;case Fn:Eq(this,c,k/2,f,En)}switch(d){case Gn:Cq(this,a,f+h,Dn);break;case Hn:Dq(this,a,f+h,Dn);break;case Fn:Eq(this,a,k/2,f+h,Dn)}}}else switch(d){case Gn:Cq(this,
b,f,this.direction);break;case Hn:Dq(this,b,f,this.direction);break;case Fn:Eq(this,b,k,f,this.direction);break;case In:Fq(this,b,k,f,this.direction)}else Fq(this,b,k,f-k/2,Dn)}this.updateParts();this.network=null;this.wf=!0};
function Eq(a,b,c,d,e){var f=a.Bq,h=a.Jd;a=a.sg;d=d*Math.PI/180;c=c*Math.PI/180;for(var k=b.length,l=0;l<k;l++){var m=d+(e===Dn?l*c/(360<=f?k:k-1):-(l*c)/k),n=b.da(l),p=h*Math.tan(m)/a,p=Math.sqrt((h*h+a*a*p*p)/(1+p*p));n.na=p*Math.cos(m);n.ua=p*Math.sin(m);n.actualAngle=180*m/Math.PI}}
function Dq(a,b,c,d){var e=a.Jd,f=a.sg,h=a.yk;c=c*Math.PI/180;for(var k=b.length,l=0;l<k;l++){var m=b.da(l),n=b.da(l===k-1?0:l+1),p=f*Math.sin(c);m.na=e*Math.cos(c);m.ua=p;m.actualAngle=180*c/Math.PI;isNaN(m.diameter)&&yq(m,0);isNaN(n.diameter)&&yq(n,0);m=Bq(a,e,f,d===Dn?c:-c,(m.diameter+n.diameter)/2+h);c+=d===Dn?m:-m}}
function Cq(a,b,c,d){var e=a.Jd,f=a.sg,h=a.Du;c=c*Math.PI/180;for(var k=b.length,l=0;l<k;l++){var m=b.da(l);m.na=e*Math.cos(c);m.ua=f*Math.sin(c);m.actualAngle=180*c/Math.PI;m=Bq(a,e,f,d===Dn?c:-c,h);c+=d===Dn?m:-m}}function Fq(a,b,c,d,e){var f=a.Fu,f=a.Bq;a.hm=0;a.cw=new vq;if(360>c){for(f=d+(e===Dn?f:-f);0>f;)f+=360;f%=360;180<f&&(f-=360);f*=Math.PI/180;a.Fu=f;Gq(a,b,c,d,e)}else Hq(a,b,c,d,e);a.cw.commit(b)}
function Hq(a,b,c,d,e){var f=a.Jd,h=a.yk,k=a.Kn,l=f*Math.cos(d*Math.PI/180),m=a.sg*Math.sin(d*Math.PI/180),n=b.dc();if(3===n.length)n[0].na=f,n[0].ua=0,n[1].na=n[0].na-n[0].width/2-n[1].width/2-h,n[1].y=n[0].y,n[2].na=(n[0].na+n[1].na)/2,n[2].y=n[0].y-n[2].height-h;else if(4===n.length)n[0].na=f,n[0].ua=0,n[2].na=-n[0].na,n[2].ua=n[0].ua,n[1].na=0,n[1].y=Math.min(n[0].y,n[2].y)-n[1].height-h,n[3].na=0,n[3].y=Math.max(n[0].y+n[0].height+h,n[2].y+n[2].height+h);else{for(var f=v.L(),p=0;p<n.length;p++){n[p].na=
l;n[p].ua=m;if(p>=n.length-1)break;Iq(a,l,m,n,p,e,f)||Jq(a,l,m,n,p,e,f);l=f.x;m=f.y}v.v(f);a.hm++;if(!(23<a.hm)){var l=n[0].na,m=n[0].ua,f=n[n.length-1].na,p=n[n.length-1].ua,q=Math.abs(l-f)-((n[0].width+n[n.length-1].width)/2+h),r=Math.abs(m-p)-((n[0].height+n[n.length-1].height)/2+h),h=0;1>Math.abs(r)?Math.abs(l-f)<(n[0].width+n[n.length-1].width)/2&&(h=0):h=0<r?r:1>Math.abs(q)?0:q;q=!1;q=Math.abs(f)>Math.abs(p)?0<f!==m>p:0<p!==l<f;if(q=e===Dn?q:!q)h=-Math.abs(h),h=Math.min(h,-n[n.length-1].width),
h=Math.min(h,-n[n.length-1].height);a.cw.compare(h,n);1<Math.abs(h)&&(a.Jd=8>a.hm?a.Jd-h/(2*Math.PI):5>n.length&&10<h?a.Jd/2:a.Jd-(0<h?1.7:-2.3),a.sg=a.Jd*k,Hq(a,b,c,d,e))}}}
function Gq(a,b,c,d,e){for(var f=a.Jd,h=a.sg,k=a.Kn,l=f*Math.cos(d*Math.PI/180),m=h*Math.sin(d*Math.PI/180),n=v.L(),p=b.dc(),q=0;q<p.length;q++){p[q].na=l;p[q].ua=m;if(q>=p.length-1)break;Iq(a,l,m,p,q,e,n)||Jq(a,l,m,p,q,e,n);l=n.x;m=n.y}v.v(n);a.hm++;if(!(23<a.hm)){l=Math.atan2(m,l);l=e===Dn?a.Fu-l:l-a.Fu;l=Math.abs(l)<Math.abs(l-2*Math.PI)?l:l-2*Math.PI;f=l*(f+h)/2;h=a.cw;if(Math.abs(f)<Math.abs(h.Zo))for(h.Zo=f,h.kn=[],h.Mp=[],l=0;l<p.length;l++)h.kn[l]=p[l].mb.x,h.Mp[l]=p[l].mb.y;1<Math.abs(f)&&
(a.Jd=8>a.hm?a.Jd-f/(2*Math.PI):a.Jd-(0<f?1.7:-2.3),a.sg=a.Jd*k,Gq(a,b,c,d,e))}}function Iq(a,b,c,d,e,f,h){var k=a.Jd,l=a.sg,m=0,n=0;a=(d[e].width+d[e+1].width)/2+a.yk;var p=!1;if(0<=c!==(f===Dn)){if(m=b+a,m>k){m=b-a;if(m<-k)return h.x=m,h.y=n,!1;p=!0}}else if(m=b-a,m<-k){m=b+a;if(m>k)return h.x=m,h.y=n,!1;p=!0}n=Math.sqrt(1-Math.min(1,m*m/(k*k)))*l;0>c!==p&&(n=-n);if(Math.abs(c-n)>(d[e].height+d[e+1].height)/2)return h.x=m,h.y=n,!1;h.x=m;h.y=n;return!0}
function Jq(a,b,c,d,e,f,h){var k=a.Jd,l=a.sg,m=0,n=0;a=(d[e].height+d[e+1].height)/2+a.yk;d=!1;if(0<=b!==(f===Dn)){if(n=c-a,n<-l){n=c+a;if(n>l){h.x=m;h.y=n;return}d=!0}}else if(n=c+a,n>l){n=c-a;if(n<-l){h.x=m;h.y=n;return}d=!0}m=Math.sqrt(1-Math.min(1,n*n/(l*l)))*k;0>b!==d&&(m=-m);h.x=m;h.y=n}vn.prototype.commitLayout=function(){this.commitNodes();this.xt&&this.commitLinks()};
vn.prototype.commitNodes=function(){for(var a=this.XF,b=this.network.vertexes.i;b.next();){var c=b.value;c.x+=a.x;c.y+=a.y;c.commit()}};vn.prototype.commitLinks=function(){for(var a=this.network.edges.i;a.next();)a.value.commit()};
function Aq(a,b,c,d,e){var f=a.MF;if(.001>Math.abs(a.Kn-1))return void 0!==d&&void 0!==e?e*b:2*Math.PI*b;a=b>c?Math.sqrt(b*b-c*c)/b:Math.sqrt(c*c-b*b)/c;for(var h=0,k=0,k=void 0!==d&&void 0!==e?e/(f+1):Math.PI/(2*(f+1)),l=0,m=0;m<=f;m++)l=void 0!==d&&void 0!==e?d+m*e/f:m*Math.PI/(2*f),l=Math.sin(l),h+=Math.sqrt(1-a*a*l*l)*k;return void 0!==d&&void 0!==e?(b>c?b:c)*h:4*(b>c?b:c)*h}function zq(a,b,c,d,e){var f=0,f=void 0!==d&&void 0!==e?Aq(a,1,c,d,e):Aq(a,1,c);return b/f}
function Bq(a,b,c,d,e){if(.001>Math.abs(a.Kn-1))return e/b;var f=b>c?Math.sqrt(b*b-c*c)/b:Math.sqrt(c*c-b*b)/c,h=0;a=2*Math.PI/(700*a.network.vertexes.count);b>c&&(d+=Math.PI/2);for(var k=0;;k++){var l=Math.sin(d+k*a),h=h+(b>c?b:c)*Math.sqrt(1-f*f*l*l)*a;if(h>=e)return k*a}}
vn.prototype.sort=function(a){switch(this.sorting){case yn:break;case zn:a.reverse();break;case wn:a.sort(this.comparer);break;case xn:a.sort(this.comparer);a.reverse();break;case An:for(var b=[],c=0;c<a.length;c++)b.push(0);for(var d=new I(xq),c=0;c<a.length;c++){var e=-1,f=-1;if(0===c)for(var h=0;h<a.length;h++){var k=a.da(h).LG;k>e&&(e=k,f=h)}else for(h=0;h<a.length;h++)k=b[h],k>e&&(e=k,f=h);d.add(a.da(f));b[f]=-1;f=a.da(f);e=0;for(h=f.lc;h.next();)e=a.indexOf(h.value.fromVertex),0>e||0<=b[e]&&
b[e]++;for(f=f.ac;f.next();)e=a.indexOf(f.value.toVertex),0>e||0<=b[e]&&b[e]++}a=[];for(b=0;b<d.length;b++){h=d.da(b);a[b]=[];for(var l=0,c=h.ac;c.next();)l=d.indexOf(c.value.toVertex),l!==b&&0>a[b].indexOf(l)&&a[b].push(l);for(c=h.lc;c.next();)l=d.indexOf(c.value.fromVertex),l!==b&&0>a[b].indexOf(l)&&a[b].push(l)}h=[];for(b=0;b<a.length;b++)h[b]=0;for(var c=[],k=[],m=[],e=[],f=new I(xq),n=0,b=0;b<a.length;b++){var p=a[b].length;if(1===p)e.push(b);else if(0===p)f.add(d.da(b));else{if(0===n)c.push(b);
else{for(var q=Infinity,r=Infinity,s=-1,t=[],p=0;p<c.length;p++)0>a[c[p]].indexOf(c[p===c.length-1?0:p+1])&&t.push(p===c.length-1?0:p+1);if(0===t.length)for(p=0;p<c.length;p++)t.push(p);for(p=0;p<t.length;p++){var u=t[p],y,l=a[b];y=k;for(var w=m,x=h,A=u,H=c,C=0,T=0;T<y.length;T++){var ba=x[y[T]],U=x[w[T]],M=0,aa=0;ba<U?(M=ba,aa=U):(M=U,aa=ba);if(M<A&&A<=aa)for(ba=0;ba<l.length;ba++)U=l[ba],0>H.indexOf(U)||M<x[U]&&x[U]<aa||M===x[U]||aa===x[U]||C++;else for(ba=0;ba<l.length;ba++)U=l[ba],0>H.indexOf(U)||
M<x[U]&&x[U]<aa&&M!==x[U]&&aa!==x[U]&&C++}y=C;for(x=w=0;x<a[b].length;x++)l=c.indexOf(a[b][x]),0<=l&&(l=Math.abs(u-(l>=u?l+1:l)),w+=l<c.length+1-l?l:c.length+1-l);for(x=0;x<k.length;x++)l=h[k[x]],A=h[m[x]],l>=u&&l++,A>=u&&A++,l>A&&(H=A,A=l,l=H),A-l<(c.length+2)/2===(l<u&&u<=A)&&w++;if(y<q||y===q&&w<r)q=y,r=w,s=u}c.splice(s,0,b);for(p=0;p<c.length;p++)h[c[p]]=p;for(p=0;p<a[b].length;p++)q=a[b][p],0<=c.indexOf(q)&&(k.push(b),m.push(q))}n++}}k=!1;for(m=c.length;;){k=!0;for(h=0;h<e.length;h++)if(b=e[h],
n=a[b][0],l=c.indexOf(n),0<=l){for(r=p=0;r<a[n].length;r++)q=a[n][r],q=c.indexOf(q),0>q||q===l||(s=q>l?q-l:l-q,p+=q<l!==s>m-s?1:-1);c.splice(0>p?l:l+1,0,b);e.splice(h,1);h--}else k=!1;if(k)break;else c.push(e[0]),e.splice(0,1)}for(b=0;b<c.length;b++)l=c[b],f.add(d.da(l));return f;default:v.k("Invalid sorting type.")}return a};v.defineProperty(vn,{eI:"radius"},function(){return this.Pr},function(a){this.Pr!==a&&(v.j(a,"number",vn,"radius"),0<a||isNaN(a))&&(this.Pr=a,this.H())});
v.defineProperty(vn,{hG:"aspectRatio"},function(){return this.Yp},function(a){this.Yp!==a&&(v.j(a,"number",vn,"aspectRatio"),0<a&&(this.Yp=a,this.H()))});v.defineProperty(vn,{xe:"startAngle"},function(){return this.vs},function(a){this.vs!==a&&(v.j(a,"number",vn,"startAngle"),this.vs=a,this.H())});v.defineProperty(vn,{Af:"sweepAngle"},function(){return this.xs},function(a){this.xs!==a&&(v.j(a,"number",vn,"sweepAngle"),this.xs=0<a&&360>=a?a:360,this.H())});
v.defineProperty(vn,{Nf:"arrangement"},function(){return this.fd},function(a){this.fd!==a&&(v.pb(a,vn,vn,"arrangement"),a===In||a===Hn||a===Gn||a===Fn)&&(this.fd=a,this.H())});v.defineProperty(vn,{direction:"direction"},function(){return this.aa},function(a){this.aa!==a&&(v.pb(a,vn,vn,"direction"),a===Dn||a===En||a===Bn||a===Cn)&&(this.aa=a,this.H())});
v.defineProperty(vn,{sorting:"sorting"},function(){return this.Lh},function(a){this.Lh!==a&&(v.pb(a,vn,vn,"sorting"),a===yn||a===zn||a===wn||xn||a===An)&&(this.Lh=a,this.H())});v.defineProperty(vn,{comparer:"comparer"},function(){return this.uh},function(a){this.uh!==a&&(v.j(a,"function",vn,"comparer"),this.uh=a,this.H())});v.defineProperty(vn,{spacing:"spacing"},function(){return this.Mh},function(a){this.Mh!==a&&(v.j(a,"number",vn,"spacing"),this.Mh=a,this.H())});
v.defineProperty(vn,{Rw:"nodeDiameterFormula"},function(){return this.Er},function(a){this.Er!==a&&(v.pb(a,vn,vn,"nodeDiameterFormula"),a===Kn||a===Jn)&&(this.Er=a,this.H())});v.u(vn,{YF:"actualXRadius"},function(){return this.Jd});v.u(vn,{ZF:"actualYRadius"},function(){return this.sg});v.u(vn,{YI:"actualSpacing"},function(){return this.yk});v.u(vn,{XF:"actualCenter"},function(){return isNaN(this.Xd.x)||isNaN(this.Xd.y)?new z(0,0):new z(this.Xd.x+this.YF,this.Xd.y+this.ZF)});var Hn;
vn.ConstantSpacing=Hn=v.p(vn,"ConstantSpacing",0);var Gn;vn.ConstantDistance=Gn=v.p(vn,"ConstantDistance",1);var Fn;vn.ConstantAngle=Fn=v.p(vn,"ConstantAngle",2);var In;vn.Packed=In=v.p(vn,"Packed",3);var Dn;vn.Clockwise=Dn=v.p(vn,"Clockwise",4);var En;vn.Counterclockwise=En=v.p(vn,"Counterclockwise",5);var Bn;vn.BidirectionalLeft=Bn=v.p(vn,"BidirectionalLeft",6);var Cn;vn.BidirectionalRight=Cn=v.p(vn,"BidirectionalRight",7);var yn;vn.Forwards=yn=v.p(vn,"Forwards",8);var zn;
vn.Reverse=zn=v.p(vn,"Reverse",9);var wn;vn.Ascending=wn=v.p(vn,"Ascending",10);var xn;vn.Descending=xn=v.p(vn,"Descending",11);var An;vn.Optimized=An=v.p(vn,"Optimized",12);var Kn;vn.Pythagorean=Kn=v.p(vn,"Pythagorean",13);var Jn;vn.Circular=Jn=v.p(vn,"Circular",14);function vq(){this.Zo=-Infinity;this.Mp=this.kn=null}
vq.prototype.compare=function(a,b){if(0<a&&0>this.Zo||Math.abs(a)<Math.abs(this.Zo)&&!(0>a&&0<this.Zo)){this.Zo=a;this.kn=[];this.Mp=[];for(var c=0;c<b.length;c++)this.kn[c]=b[c].mb.x,this.Mp[c]=b[c].mb.y}};vq.prototype.commit=function(a){if(null!==this.kn&&null!==this.Mp)for(var b=0;b<this.kn.length;b++){var c=a.da(b);c.x=this.kn[b];c.y=this.Mp[b]}};function wq(){Zf.call(this)}v.Ja(wq,Zf);v.ea("CircularNetwork",wq);wq.prototype.createVertex=function(){return new xq};wq.prototype.createEdge=function(){return new Kq};
function xq(){nq.call(this);this.actualAngle=this.diameter=NaN}v.Ja(xq,nq);v.ea("CircularVertex",xq);
function yq(a,b){var c=a.network;if(null===c)return NaN;c=c.Pb;if(null===c)return NaN;if(c.Nf===In)if(c.Rw===Jn)a.diameter=Math.max(a.width,a.height);else{var c=Math.abs(Math.sin(b)),d=Math.abs(Math.cos(b));if(0===c)return a.width;if(0===d)return a.height;a.diameter=Math.min(a.height/c,a.width/d)}else a.diameter=c.Rw===Jn?Math.max(a.width,a.height):Math.sqrt(a.width*a.width+a.height*a.height);return a.diameter}function Kq(){oq.call(this)}v.Ja(Kq,oq);v.ea("CircularEdge",Kq);
function Lq(){0<arguments.length&&v.dd(Lq);Yf.call(this);this.rg=null;this.dr=0;this.bg=(new ia(100,100)).freeze();this.Xp=!1;this.Kh=!0;this.th=!1;this.io=100;this.Dq=1;this.Bh=1E3;this.Qr=Math;this.Fn=.05;this.En=50;this.Bn=150;this.Dn=0;this.tq=10;this.sq=5}v.Ja(Lq,Yf);v.ea("ForceDirectedLayout",Lq);
Lq.prototype.cloneProtected=function(a){Yf.prototype.cloneProtected.call(this,a);a.bg.assign(this.bg);a.Xp=this.Xp;a.Kh=this.Kh;a.th=this.th;a.io=this.io;a.Dq=this.Dq;a.Bh=this.Bh;a.Qr=this.Qr;a.Fn=this.Fn;a.En=this.En;a.Bn=this.Bn;a.Dn=this.Dn;a.tq=this.tq;a.sq=this.sq};Lq.prototype.createNetwork=function(){return new Mq};
Lq.prototype.doLayout=function(a){null===a&&v.k("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts");null===this.network&&(this.network=this.makeNetwork(a));a=this.Oz;if(0<this.network.vertexes.count){this.network.uw();for(var b=this.network.vertexes.i;b.next();){var c=b.value;c.charge=this.electricalCharge(c);c.mass=this.gravitationalMass(c)}for(b=this.network.edges.i;b.next();)c=b.value,c.stiffness=this.springStiffness(c),c.length=this.springLength(c);
this.Zy();this.dr=0;if(this.needsClusterLayout()){b=this.network;for(c=b.EI().i;c.next();){this.network=c.value;for(var d=this.network.vertexes.i;d.next();){var e=d.value;e.Zf=e.vertexes.count;e.ck=1;e.Fm=null;e.nh=null}Nq(this,0,a)}this.network=b;c.reset();for(var d=this.jD,f=c.count,h=!0,k=e=0,l=v.jb(),m=0;m<f+b.vertexes.count+2;m++)l[m]=null;f=0;c.reset();for(var n=v.ag();c.next();)if(m=c.value,this.$g(m,n),h)h=!1,e=n.x+n.width/2,k=n.y+n.height/2,l[0]=new z(n.x+n.width+d.width,n.y),l[1]=new z(n.x,
n.y+n.height+d.height),f=2;else{var p=Oq(l,f,e,k,n.width,n.height,d),q=l[p],r=new z(q.x+n.width+d.width,q.y),s=new z(q.x,q.y+n.height+d.height);p+1<f&&l.splice(p+1,0,null);l[p]=r;l[p+1]=s;f++;p=q.x-n.x;q=q.y-n.y;for(m=m.vertexes.i;m.next();)r=m.value,r.na+=p,r.ua+=q}v.Vb(n);for(m=b.vertexes.i;m.next();)h=m.value,n=h.mb,2>f?(e=n.x+n.width/2,k=n.y+n.height/2,l[0]=new z(n.x+n.width+d.width,n.y),l[1]=new z(n.x,n.y+n.height+d.height),f=2):(p=Oq(l,f,e,k,n.width,n.height,d),q=l[p],r=new z(q.x+n.width+d.width,
q.y),s=new z(q.x,q.y+n.height+d.height),p+1<f&&l.splice(p+1,0,null),l[p]=r,l[p+1]=s,f++,h.na=q.x+h.width/2,h.ua=q.y+h.height/2);v.sa(l);for(c.reset();c.next();){d=c.value;for(e=d.vertexes.i;e.next();)b.Zk(e.value);for(d=d.edges.i;d.next();)b.Jo(d.value)}}Pq(this,a);this.updateParts()}this.io=a;this.network=null;this.wf=!0};
Lq.prototype.needsClusterLayout=function(){if(3>this.network.vertexes.count)return!1;for(var a=0,b=0,c=this.network.vertexes.first().mb,d=this.network.vertexes.i;d.next();){if(d.value.mb.xg(c)&&(a++,2<a))return!0;if(10<b)break;b++}return!1};Lq.prototype.$g=function(a,b){for(var c=!0,d=a.vertexes.i;d.next();){var e=d.value;c?(c=!1,b.set(e.mb)):b.ai(e.mb)}return b};
function Nq(a,b,c){if(Qq(a,b)){var d=a.Bh;a.Bh*=1+1/(b+1);var e=Zq(a,b),f=Math.max(0,Math.max(Math.min(a.network.vertexes.count,c*(b+1)/11),10));a.Oz+=f;Nq(a,b+1,c);Pq(a,f);er(a,e);b=a.rg;null===b?b=new I(fr):b.clear();b.Tc(e.vertexes);b.sort(function(a,b){return null===a||null===b||a===b?0:b.Zf-a.Zf});for(e=b.i;e.next();)gr(a,e.value);a.Bh=d}}
function Qq(a,b){if(10<b||3>a.network.vertexes.count)return!1;null===a.rg?a.rg=new I(fr):a.rg.clear();a.rg.Tc(a.network.vertexes);var c=a.rg;c.sort(function(a,b){return null===a||null===b||a===b?0:b.Zf-a.Zf});for(var d=c.count-1;0<=d&&1>=c.da(d).Zf;)d--;return 1<c.count-d}
function Zq(a,b){for(var c=a.network,d=new Mq,e=a.rg.i;e.next();){var f=e.value;if(1<f.Zf){d.Zk(f);var h=new hr;h.yx=f.Zf;h.zx=f.width;h.xx=f.height;h.HA=f.O.x;h.IA=f.O.y;null===f.nh&&(f.nh=new I(hr));f.nh.add(h);f.iA=f.nh.count-1}else break}for(var k=c.edges.i;k.next();)if(e=k.value,e.fromVertex.network===d&&e.toVertex.network===d)d.Jo(e);else if(e.fromVertex.network===d){var l=e.fromVertex.Fm;null===l&&(l=new I(fr),e.fromVertex.Fm=l);l.add(e.toVertex);e.fromVertex.Zf--;e.fromVertex.ck+=e.toVertex.ck}else e.toVertex.network===
d&&(l=e.toVertex.Fm,null===l&&(l=new I(fr),e.toVertex.Fm=l),l.add(e.fromVertex),e.toVertex.Zf--,e.toVertex.ck+=e.fromVertex.ck);for(e=d.edges.i;e.next();)f=e.value,f.length*=Math.max(1,Jd((f.fromVertex.ck+f.toVertex.ck)/(4*b+1)));for(e=d.vertexes.i;e.next();)if(f=e.value,l=f.Fm,null!==l&&0<l.count&&(h=f.nh.da(f.nh.count-1),h=h.yx-f.Zf,!(0>=h))){for(var m=0,n=0,p=l.count-h;p<l.count;p++){for(var q=l.da(p),r=null,k=q.edges.i;k.next();){var s=k.value;if(s.WG(q)===f){r=s;break}}null!==r&&(n+=r.length,
m+=q.width*q.height)}l=f.na;k=f.ua;p=f.width;q=f.height;r=f.O;s=p*q;1>s&&(s=1);m=Jd((m+s+n*n*4/(h*h))/s);h=(m-1)*p/2;m=(m-1)*q/2;f.mb=new B(l-r.x-h,k-r.y-m,p+2*h,q+2*m);f.focus=new z(r.x+h,r.y+m)}a.network=d;return c}function er(a,b){for(var c=a.network.vertexes.i;c.next();){var d=c.value;d.network=b;if(null!==d.nh){var e=d.nh.da(d.iA);d.Zf=e.yx;var f=e.HA,h=e.IA;d.mb=new B(d.na-f,d.ua-h,e.zx,e.xx);d.focus=new z(f,h);d.iA--}}for(c=a.network.edges.i;c.next();)c.value.network=b;a.network=b}
function gr(a,b){var c=b.Fm;if(null!==c&&0!==c.count){var d=b.na,e=b.ua,f=b.width,h=b.height;null!==b.nh&&0<b.nh.count&&(h=b.nh.da(0),f=h.zx,h=h.xx);for(var f=Jd(f*f+h*h)/2,k=!1,l=h=0,m=0,n=b.vertexes.i;n.next();){var p=n.value;1>=p.Zf?l++:(k=!0,m++,h+=Math.atan2(b.ua-p.ua,b.na-p.na))}if(0!==l)for(0<m&&(h/=m),n=m=0,m=k?2*Math.PI/(l+1):2*Math.PI/l,0===l%2&&(n=m/2),1<c.count&&c.sort(function(a,b){return null===a||null===b||a===b?0:b.width*b.height-a.width*a.height}),k=0===l%2?0:1,c=c.i;c.next();)if(l=
c.value,!(1<l.Zf||a.isFixed(l))){for(var p=null,q=l.edges.i;q.next();){p=q.value;break}var q=l.width,r=l.height,q=Jd(q*q+r*r)/2,p=f+p.length+q,q=h+(m*(k/2>>1)+n)*(0===k%2?1:-1);l.na=d+p*Math.cos(q);l.ua=e+p*Math.sin(q);k++}}}function Oq(a,b,c,d,e,f,h){var k=9E19,l=-1,m=0;a:for(;m<b;m++){var n=a[m],p=n.x-c,q=n.y-d,p=p*p+q*q;if(p<k){for(q=m-1;0<=q;q--)if(a[q].y>n.y&&a[q].x-n.x<e+h.width)continue a;for(q=m+1;q<b;q++)if(a[q].x>n.x&&a[q].y-n.y<f+h.height)continue a;l=m;k=p}}return l}
Lq.prototype.Zy=function(){if(this.comments)for(var a=this.network.vertexes.i;a.next();)this.addComments(a.value)};
Lq.prototype.addComments=function(a){var b=a.Jc;if(null!==b)for(b=b.LD();b.next();){var c=b.value;if("Comment"===c.gc&&c.isVisible()){var d=this.network.Nm(c);null===d&&(d=this.network.Ps(c));d.charge=this.BG;for(var c=null,e=d.ac;e.next();){var f=e.value;if(f.toVertex===a){c=f;break}}if(null===c)for(e=d.lc;e.next();)if(f=e.value,f.fromVertex===a){c=f;break}null===c&&(c=this.network.mp(a,d,null));c.length=this.CG}}};
function ir(a,b){var c=a.M,d=c.x,e=c.y,f=c.width,c=c.height,h=b.M,k=h.x,l=h.y,m=h.width,h=h.height;return d+f<k?e>l+h?(d=d+f-k,e=e-l-h,Jd(d*d+e*e)):e+c<l?(d=d+f-k,e=e+c-l,Jd(d*d+e*e)):k-(d+f):d>k+m?e>l+h?(d=d-k-m,e=e-l-h,Jd(d*d+e*e)):e+c<l?(d=d-k-m,e=e+c-l,Jd(d*d+e*e)):d-(k+m):e>l+h?e-(l+h):e+c<l?l-(e+c):.1}function Pq(a,b){a.rg=null;for(var c=a.dr+b;a.dr<c&&(a.dr++,jr(a)););a.rg=null}
function jr(a){null===a.rg&&(a.rg=new I(fr),a.rg.Tc(a.network.vertexes));var b=a.rg.n;if(0>=b.length)return!1;var c=b[0];c.forceX=0;c.forceY=0;for(var d=c.na,e=d,f=c.ua,h=f,c=1;c<b.length;c++){var k=b[c];k.forceX=0;k.forceY=0;var l=k.na,k=k.ua,d=Math.min(d,l),e=Math.max(e,l),f=Math.min(f,k),h=Math.max(h,k)}(f=e-d>h-f)?b.sort(function(a,b){return null===a||null===b||a===b?0:a.na-b.na}):b.sort(function(a,b){return null===a||null===b||a===b?0:a.ua-b.ua});for(var h=a.Bh,m=0,n=0,p=0,c=0;c<b.length;c++){var k=
b[c],l=k.M,q=k.O,d=l.x+q.x,l=l.y+q.y,n=k.charge*a.electricalFieldX(d,l),p=k.charge*a.electricalFieldY(d,l),n=n+k.mass*a.gravitationalFieldX(d,l),p=p+k.mass*a.gravitationalFieldY(d,l);k.forceX+=n;k.forceY+=p;for(q=c+1;q<b.length;q++)if(e=b[q],e!==k){var r=e.M,n=e.O,p=r.x+n.x,r=r.y+n.y;if(d-p>h||p-d>h){if(f)break}else if(l-r>h||r-l>h){if(!f)break}else{var s=ir(k,e);1>s?(n=a.Zw,null===n&&(a.Zw=n=new va(0)),m=n.random(),s=n.random(),d>p?(n=Math.abs(e.M.right-k.M.x),n=(1+n)*m):d<p?(n=Math.abs(e.M.x-k.M.right),
n=-(1+n)*m):(n=Math.max(e.width,k.width),n=(1+n)*m-n/2),l>r?(p=Math.abs(e.M.bottom-k.M.y),p=(1+p)*s):d<p?(p=Math.abs(e.M.y-k.M.bottom),p=-(1+p)*s):(p=Math.max(e.height,k.height),p=(1+p)*s-p/2)):(m=-(k.charge*e.charge)/(s*s),n=(p-d)/s*m,p=(r-l)/s*m);k.forceX+=n;k.forceY+=p;e.forceX-=n;e.forceY-=p}}}for(c=a.network.edges.i;c.next();)f=c.value,k=f.fromVertex,e=f.toVertex,l=k.M,q=k.O,d=l.x+q.x,l=l.y+q.y,r=e.M,n=e.O,p=r.x+n.x,r=r.y+n.y,s=ir(k,e),1>s?(n=a.Zw,null===n&&(a.Zw=n=new va(0)),m=n.random(),s=
n.random(),n=(d>p?1:-1)*(1+(e.width>k.width)?e.width:k.width)*m,p=(l>r?1:-1)*(1+(e.height>k.height)?e.height:k.height)*s):(m=f.stiffness*(s-f.length),n=(p-d)/s*m,p=(r-l)/s*m),k.forceX+=n,k.forceY+=p,e.forceX-=n,e.forceY-=p;c=0;d=Math.max(a.Bh/20,50);for(e=0;e<b.length;e++)k=b[e],a.isFixed(k)?a.moveFixedVertex(k):(f=k.forceX,h=k.forceY,f<-d?f=-d:f>d&&(f=d),h<-d?h=-d:h>d&&(h=d),k.na+=f,k.ua+=h,c=Math.max(c,f*f+h*h));return c>a.HD*a.HD}Lq.prototype.moveFixedVertex=function(){};
Lq.prototype.commitLayout=function(){this.oA();this.commitNodes();this.xt&&this.commitLinks()};Lq.prototype.oA=function(){if(this.Gp)for(var a=this.network.edges.i;a.next();){var b=a.value.link;null!==b&&(b.xb=fc,b.yb=fc)}};Lq.prototype.commitNodes=function(){var a=0,b=0;if(this.gG){var c=v.ag();this.$g(this.network,c);b=this.Xd;a=b.x-c.x;b=b.y-c.y;v.Vb(c)}for(var c=v.ag(),d=this.network.vertexes.i;d.next();){var e=d.value;if(0!==a||0!==b)c.assign(e.mb),c.x+=a,c.y+=b,e.mb=c;e.commit()}v.Vb(c)};
Lq.prototype.commitLinks=function(){for(var a=this.network.edges.i;a.next();)a.value.commit()};Lq.prototype.springStiffness=function(a){a=a.stiffness;return isNaN(a)?this.Fn:a};Lq.prototype.springLength=function(a){a=a.length;return isNaN(a)?this.En:a};Lq.prototype.electricalCharge=function(a){a=a.charge;return isNaN(a)?this.Bn:a};Lq.prototype.electricalFieldX=function(){return 0};Lq.prototype.electricalFieldY=function(){return 0};
Lq.prototype.gravitationalMass=function(a){a=a.mass;return isNaN(a)?this.Dn:a};Lq.prototype.gravitationalFieldX=function(){return 0};Lq.prototype.gravitationalFieldY=function(){return 0};Lq.prototype.isFixed=function(a){return a.isFixed};v.u(Lq,{yJ:"currentIteration"},function(){return this.dr});v.defineProperty(Lq,{jD:"arrangementSpacing"},function(){return this.bg},function(a){v.A(a,ia,Lq,"arrangementSpacing");this.bg.K(a)||(this.bg.assign(a),this.H())});
v.defineProperty(Lq,{gG:"arrangesToOrigin"},function(){return this.Xp},function(a){this.Xp!==a&&(v.j(a,"boolean",Lq,"arrangesToOrigin"),this.Xp=a,this.H())});v.defineProperty(Lq,{Gp:"setsPortSpots"},function(){return this.Kh},function(a){this.Kh!==a&&(v.j(a,"boolean",Lq,"setsPortSpots"),this.Kh=a,this.H())});v.defineProperty(Lq,{comments:"comments"},function(){return this.th},function(a){this.th!==a&&(v.j(a,"boolean",Lq,"comments"),this.th=a,this.H())});
v.defineProperty(Lq,{Oz:"maxIterations"},function(){return this.io},function(a){this.io!==a&&(v.j(a,"number",Lq,"maxIterations"),0<=a&&(this.io=a,this.H()))});v.defineProperty(Lq,{HD:"epsilonDistance"},function(){return this.Dq},function(a){this.Dq!==a&&(v.j(a,"number",Lq,"epsilonDistance"),0<a&&(this.Dq=a,this.H()))});v.defineProperty(Lq,{PJ:"infinityDistance"},function(){return this.Bh},function(a){this.Bh!==a&&(v.j(a,"number",Lq,"infinityDistance"),1<a&&(this.Bh=a,this.H()))});
v.defineProperty(Lq,{Zw:"randomNumberGenerator"},function(){return this.Qr},function(a){this.Qr!==a&&(null!==a&&"function"!==typeof a.random&&v.k('ForceDirectedLayout.randomNumberGenerator must have a "random()" function on it: '+a),this.Qr=a)});v.defineProperty(Lq,{KJ:"defaultSpringStiffness"},function(){return this.Fn},function(a){this.Fn!==a&&(v.j(a,"number",Lq,"defaultSpringStiffness"),this.Fn=a,this.H())});
v.defineProperty(Lq,{JJ:"defaultSpringLength"},function(){return this.En},function(a){this.En!==a&&(v.j(a,"number",Lq,"defaultSpringLength"),this.En=a,this.H())});v.defineProperty(Lq,{DJ:"defaultElectricalCharge"},function(){return this.Bn},function(a){this.Bn!==a&&(v.j(a,"number",Lq,"defaultElectricalCharge"),this.Bn=a,this.H())});v.defineProperty(Lq,{EJ:"defaultGravitationalMass"},function(){return this.Dn},function(a){this.Dn!==a&&(v.j(a,"number",Lq,"defaultGravitationalMass"),this.Dn=a,this.H())});
v.defineProperty(Lq,{CG:"defaultCommentSpringLength"},function(){return this.tq},function(a){this.tq!==a&&(v.j(a,"number",Lq,"defaultCommentSpringLength"),this.tq=a,this.H())});v.defineProperty(Lq,{BG:"defaultCommentElectricalCharge"},function(){return this.sq},function(a){this.sq!==a&&(v.j(a,"number",Lq,"defaultCommentElectricalCharge"),this.sq=a,this.H())});function hr(){this.IA=this.HA=this.xx=this.zx=this.yx=0}function Mq(){Zf.call(this)}v.Ja(Mq,Zf);v.ea("ForceDirectedNetwork",Mq);
Mq.prototype.createVertex=function(){return new fr};Mq.prototype.createEdge=function(){return new kr};function fr(){nq.call(this);this.isFixed=!1;this.mass=this.charge=NaN;this.ck=this.Zf=this.forceY=this.forceX=0;this.nh=this.Fm=null;this.iA=0}v.Ja(fr,nq);v.ea("ForceDirectedVertex",fr);function kr(){oq.call(this);this.length=this.stiffness=NaN}v.Ja(kr,oq);v.ea("ForceDirectedEdge",kr);
function gn(){0<arguments.length&&v.dd(gn);Yf.call(this);this.Hd=this.$l=25;this.aa=0;this.An=lr;this.bo=mr;this.Sn=nr;this.Xl=4;this.nn=or;this.Di=pr;this.Kh=!0;this.ir=4;this.Fb=this.ov=this.ob=-1;this.Hf=this.sr=0;this.Lb=this.Ef=this.Ff=this.ig=this.Td=null;this.Ar=0;this.zr=this.dm=null;this.mg=0;this.Br=null;this.Sg=[];this.Sg.length=100}v.Ja(gn,Yf);v.ea("LayeredDigraphLayout",gn);
gn.prototype.cloneProtected=function(a){Yf.prototype.cloneProtected.call(this,a);a.$l=this.$l;a.Hd=this.Hd;a.aa=this.aa;a.An=this.An;a.bo=this.bo;a.Sn=this.Sn;a.Xl=this.Xl;a.nn=this.nn;a.Di=this.Di;a.Kh=this.Kh;a.ir=this.ir};gn.prototype.createNetwork=function(){return new qr};
gn.prototype.doLayout=function(a){null===a&&v.k("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts");null===this.network&&(this.network=this.makeNetwork(a));this.Xd=this.initialOrigin(this.Xd);this.ov=-1;this.Hf=this.sr=0;this.Br=this.zr=this.dm=null;for(a=0;a<this.Sg.length;a++)this.Sg[a]=null;if(0<this.network.vertexes.count){this.network.uw();for(a=this.network.edges.i;a.next();)a.value.rev=!1;switch(this.An){default:case rr:var b=0,c=this.network.vertexes.count-
1;a=[];a.length=c+1;for(var d=this.network.vertexes.i;d.next();)d.value.valid=!0;for(;null!==sr(this.network);){for(d=tr(this.network);null!==d;)a[c]=d,c--,d.valid=!1,d=tr(this.network);for(d=ur(this.network);null!==d;)a[b]=d,b++,d.valid=!1,d=ur(this.network);for(var d=null,e=0,f=this.network.vertexes.i;f.next();){var h=f.value;if(h.valid){for(var k=0,l=h.ac;l.next();)l.value.toVertex.valid&&k++;for(var l=0,m=h.lc;m.next();)m.value.fromVertex.valid&&l++;if(null===d||e<k-l)d=h,e=k-l}}null!==d&&(a[b]=
d,b++,d.valid=!1)}for(b=0;b<this.network.vertexes.count;b++)a[b].index=b;for(a=this.network.edges.i;a.next();)b=a.value,b.fromVertex.index>b.toVertex.index&&(this.network.gx(b),b.rev=!0);break;case lr:for(b=this.network.vertexes.i;b.next();)a=b.value,a.To=-1,a.finish=-1;for(a=this.network.edges.i;a.next();)a.value.forest=!1;this.Ar=0;for(b.reset();b.next();)c=b.value,0===c.lc.count&&vr(this,c);for(b.reset();b.next();)c=b.value,-1===c.To&&vr(this,c);for(a.reset();a.next();)b=a.value,b.forest||(c=b.fromVertex,
d=c.finish,e=b.toVertex,f=e.finish,e.To<c.To&&d<f&&(this.network.gx(b),b.rev=!0))}for(a=this.network.vertexes.i;a.next();)a.value.layer=-1;this.ob=-1;this.assignLayers();for(a.reset();a.next();)this.ob=Math.max(this.ob,a.value.layer);a=[];for(c=this.network.edges.i;c.next();)b=c.value,b.valid=!1,a.push(b);for(c=0;c<a.length;c++)if(b=a[c],!b.valid&&(null!==b.fromVertex.qd&&null!==b.toVertex.qd||b.fromVertex.layer!==b.toVertex.layer)){l=h=k=f=0;e=b.fromVertex;d=b.toVertex;if(null!==b.link){k=b.link;
if(null===k)continue;var n=e.qd,f=d.qd;if(null===n||null===f)continue;var p=k.T,h=k.Z,q=k.bd,l=k.Fd;b.rev&&(k=p,m=q,p=h,q=l,h=k,l=m);var r=e.O,k=b.toVertex.O,s=b.rev?d.M:e.M,m=v.L();s.G()?(cp(p,q,Ab,m),m.G()||m.assign(r)):m.assign(r);n!==p&&s.G()&&p.isVisible()&&(n=e.M,n.G()&&(m.x+=s.x-n.x,m.y+=s.y-n.y));p=b.rev?e.M:d.M;n=v.L();p.G()?(cp(h,l,Ab,n),n.G()||n.assign(k)):n.assign(k);f!==h&&p.G()&&h.isVisible()&&(f=d.M,f.G()&&(n.x+=p.x-f.x,n.y+=p.y-f.y));90===this.aa||270===this.aa?(f=Math.round((m.x-
r.x)/this.Hd),h=m.x,k=Math.round((n.x-k.x)/this.Hd),l=n.x):(f=Math.round((m.y-r.y)/this.Hd),h=m.y,k=Math.round((n.y-k.y)/this.Hd),l=n.y);v.v(m);v.v(n);b.portFromColOffset=f;b.portFromPos=h;b.portToColOffset=k;b.portToPos=l}else b.portFromColOffset=0,b.portFromPos=0,b.portToColOffset=0,b.portToPos=0;m=e.layer;r=d.layer;a:if(n=b,p=0,s=n.link,null!==s){var t=s.bd,u=s.Fd;if(null!==t&&null!==u){var y=s.T,q=s.Z;if(null!==y&&null!==q){var w=t.xb,x=u.yb;this.Gp||(s.xb.Uc()||(w=s.xb),s.yb.Uc()||(x=s.yb));
var A=s.jc,H=wr(this,!0);if(w.Uc()||w===sb)w=H;var C=wr(this,!1);if(x.Uc()||x===sb)x=C;if(w.Ui()&&w.cp(C)&&x.Ui()&&x.cp(H)){p=0;break a}H=s.getLinkPoint(y,t,w,!0,A,q,u,v.L());w=s.getLinkDirection(y,t,H,w,!0,A,q,u);v.v(H);w===xr(this,n,!0)?p+=1:this.Gp&&null!==y&&1===y.ports.count&&n.rev&&(p+=1);w=s.getLinkPoint(q,u,x,!1,A,y,t,v.L());s=s.getLinkDirection(q,u,w,x,!1,A,y,t);v.v(w);s===xr(this,n,!1)?p+=2:this.Gp&&null!==q&&1===q.ports.count&&n.rev&&(p+=2)}}}n=1===p||3===p?!0:!1;if(p=2===p||3===p?!0:!1)q=
this.network.createVertex(),q.qd=null,q.Cm=1,q.layer=m,q.near=e,this.network.Zk(q),e=this.network.mp(e,q,b.link),e.valid=!1,e.rev=b.rev,e.portFromColOffset=f,e.portToColOffset=0,e.portFromPos=h,e.portToPos=0,e=q;s=1;n&&s--;if(m-r>s&&0<m){b.valid=!1;q=this.network.createVertex();q.qd=null;q.Cm=2;q.layer=m-1;this.network.Zk(q);e=this.network.mp(e,q,b.link);e.valid=!0;e.rev=b.rev;e.portFromColOffset=p?0:f;e.portToColOffset=0;e.portFromPos=p?0:h;e.portToPos=0;e=q;for(m--;m-r>s&&0<m;)q=this.network.createVertex(),
q.qd=null,q.Cm=3,q.layer=m-1,this.network.Zk(q),e=this.network.mp(e,q,b.link),e.valid=!0,e.rev=b.rev,e.portFromColOffset=0,e.portToColOffset=0,e.portFromPos=0,e.portToPos=0,e=q,m--;e=this.network.mp(q,d,b.link);e.valid=!n;n&&(q.near=d);e.rev=b.rev;e.portFromColOffset=0;e.portToColOffset=k;e.portFromPos=0;e.portToPos=l}else b.valid=!0}b=this.Td=[];for(c=0;c<=this.ob;c++)b[c]=0;for(a=this.network.vertexes.i;a.next();)c=a.value,c.index=-1;this.initializeIndices();this.ov=-1;for(c=this.Hf=this.sr=0;c<=
this.ob;c++)b[c]>b[this.Hf]&&(this.ov=b[c]-1,this.Hf=c),b[c]<b[this.sr]&&(this.sr=c);this.Br=[];for(c=0;c<b.length;c++)this.Br[c]=[];for(a.reset();a.next();)b=a.value,c=this.Br[b.layer],c[b.index]=b;this.Fb=-1;for(a=0;a<=this.ob;a++){b=yr(this,a);c=0;d=this.Td[a];for(e=0;e<d;e++)f=b[e],c+=this.nodeMinColumnSpace(f,!0),f.column=c,c+=1,c+=this.nodeMinColumnSpace(f,!1);this.Fb=Math.max(this.Fb,c-1);zr(this,a,b)}this.reduceCrossings();this.straightenAndPack();this.updateParts()}this.network=null;this.wf=
!0};gn.prototype.linkMinLength=function(a){var b=a.toVertex,c=0;for(a=a.fromVertex.ac;a.next();)a.value.toVertex===b&&c++;return 1};function Ar(a){var b=a.fromVertex.qd;a=a.toVertex.qd;return null===b&&null===a?8:null===b||null===a?4:1}gn.prototype.nodeMinLayerSpace=function(a,b){return null===a.qd?0:90===this.aa||270===this.aa?b?a.O.y+10:a.M.height-a.O.y+10:b?a.O.x+10:a.M.width-a.O.x+10};
gn.prototype.nodeMinColumnSpace=function(a,b){if(null===a.qd)return 0;var c=b?a.Wz:a.Vz;if(null!==c)return c;c=this.aa;return 90===c||270===c?b?a.Wz=a.O.x/this.Hd+1|0:a.Vz=(a.M.width-a.O.x)/this.Hd+1|0:b?a.Wz=a.O.y/this.Hd+1|0:a.Vz=(a.M.height-a.O.y)/this.Hd+1|0};function Br(a){null===a.dm&&(a.dm=[]);for(var b=0,c=a.network.vertexes.i;c.next();){var d=c.value;a.dm[b]=d.layer;b++;a.dm[b]=d.column;b++;a.dm[b]=d.index;b++}return a.dm}
function Cr(a,b){for(var c=0,d=a.network.vertexes.i;d.next();){var e=d.value;e.layer=b[c];c++;e.column=b[c];c++;e.index=b[c];c++}}
function Dr(a,b,c){var d=yr(a,b),e=a.Td[b];if(null===a.zr||a.zr.length<e*e)a.zr=[];for(var f=a.zr,h=0;h<e;h++){var k=0,l=d[h],m=l.near,n=0;if(null!==m&&m.layer===l.layer)if(n=m.index,n>h)for(var p=h+1;p<n;p++)l=d[p],l.near===m&&l.Cm===m.Cm||k++;else for(p=h-1;p>n;p--)l=d[p],l.near===m&&l.Cm===m.Cm||k++;var m=0,q,r=q=p=l=0,s,t=0,u=0;s=0;var y;if(0<=c)for(n=d[h].Oe,m=0;m<n.count;m++)if(q=n.n[m],q.valid&&q.fromVertex.layer!==b)for(l=q.fromVertex.index,p=q.portToPos,q=q.portFromPos,r=m+1;r<n.count;r++)s=
n.n[r],s.valid&&s.fromVertex.layer!==b&&(t=s.fromVertex.index,u=s.portToPos,s=s.portFromPos,p<u&&(l>t||l===t&&q>s)&&k++,u<p&&(t>l||t===l&&s>q)&&k++);if(0>=c)for(n=d[h].Je,m=0;m<n.count;m++)if(q=n.n[m],q.valid&&q.toVertex.layer!==b)for(l=q.toVertex.index,p=q.portToPos,q=q.portFromPos,r=m+1;r<n.count;r++)s=n.n[r],s.valid&&s.toVertex.layer!==b&&(t=s.toVertex.index,u=s.portToPos,s=s.portFromPos,q<s&&(l>t||l===t&&p>u)&&k++,s<q&&(t>l||t===l&&u>p)&&k++);f[h*e+h]=k;for(n=h+1;n<e;n++){var w=0,x=0;if(0<=c)for(k=
d[h].Oe,y=d[n].Oe,m=0;m<k.count;m++)if(q=k.n[m],q.valid&&q.fromVertex.layer!==b)for(l=q.fromVertex.index,q=q.portFromPos,r=0;r<y.count;r++)s=y.n[r],s.valid&&s.fromVertex.layer!==b&&(t=s.fromVertex.index,s=s.portFromPos,(l<t||l===t&&q<s)&&x++,(t<l||t===l&&s<q)&&w++);if(0>=c)for(k=d[h].Je,y=d[n].Je,m=0;m<k.count;m++)if(q=k.n[m],q.valid&&q.toVertex.layer!==b)for(l=q.toVertex.index,p=q.portToPos,r=0;r<y.count;r++)s=y.n[r],s.valid&&s.toVertex.layer!==b&&(t=s.toVertex.index,u=s.portToPos,(l<t||l===t&&p<
u)&&x++,(t<l||t===l&&u<p)&&w++);f[h*e+n]=w;f[n*e+h]=x}}zr(a,b,d);return f}gn.prototype.countCrossings=function(){for(var a=0,b=0;b<=this.ob;b++)for(var c=Dr(this,b,1),d=this.Td[b],e=0;e<d;e++)for(var f=e;f<d;f++)a+=c[e*d+f];return a};
function Er(a){for(var b=0,c=0;c<=a.ob;c++){for(var d=a,e=c,f=yr(d,e),h=d.Td[e],k=0,l=0;l<h;l++){var m=null,m=f[l].Je,n,p=0,q=0;if(null!==m)for(var r=0;r<m.count;r++)n=m.n[r],n.valid&&n.toVertex.layer!==e&&(p=n.fromVertex.column+n.portFromColOffset,q=n.toVertex.column+n.portToColOffset,k+=(Math.abs(p-q)+1)*Ar(n))}zr(d,e,f);b+=k}return b}
gn.prototype.normalize=function(){var a=Infinity;this.Fb=-1;for(var b=this.network.vertexes.i;b.next();){var c=b.value,a=Math.min(a,c.column-this.nodeMinColumnSpace(c,!0));this.Fb=Math.max(this.Fb,c.column+this.nodeMinColumnSpace(c,!1))}for(b.reset();b.next();)b.value.column-=a;this.Fb-=a};
function Fr(a,b,c){for(var d=yr(a,b),e=a.Td[b],f=[],h=0;h<e;h++){var k=d[h],l=null;0>=c&&(l=k.Oe);var m=null;0<=c&&(m=k.Je);var n=0,p=0,q=k.near;null!==q&&q.layer===k.layer&&(n+=q.column-1,p++);if(null!==l)for(q=0;q<l.count;q++){var k=l.n[q],r=k.fromVertex;k.valid&&!k.rev&&r.layer!==b&&(n+=r.column+k.portFromColOffset,p++)}if(null!==m)for(l=0;l<m.count;l++)k=m.n[l],q=k.toVertex,k.valid&&!k.rev&&q.layer!==b&&(n+=q.column+k.portToColOffset,p++);f[h]=0===p?-1:n/p}zr(a,b,d);return f}
function Gr(a,b,c){for(var d=yr(a,b),e=a.Td[b],f=[],h=0;h<e;h++){var k=d[h],l=null;0>=c&&(l=k.Oe);var m=null;0<=c&&(m=k.Je);var n=0,p=[],q=k.near;null!==q&&q.layer===k.layer&&(p[n]=q.column-1,n++);if(null!==l)for(q=0;q<l.count;q++){var k=l.n[q],r=k.fromVertex;k.valid&&!k.rev&&r.layer!==b&&(p[n]=r.column+k.portFromColOffset,n++)}if(null!==m)for(l=0;l<m.count;l++)k=m.n[l],q=k.toVertex,k.valid&&!k.rev&&q.layer!==b&&(p[n]=q.column+k.portToColOffset,n++);0===n?f[h]=-1:(p.sort(function(a,b){return a-b}),
m=n>>1,f[h]=n&1?p[m]:p[m-1]+p[m]>>1)}zr(a,b,d);return f}function Hr(a,b,c,d,e,f){if(b.component===d){b.component=c;var h=0,k=0;if(e)for(var l=b.ac;l.next();){var k=l.value,m=k.toVertex,h=b.layer-m.layer,k=a.linkMinLength(k);h===k&&Hr(a,m,c,d,e,f)}if(f)for(l=b.lc;l.next();)k=l.value,m=k.fromVertex,h=m.layer-b.layer,k=a.linkMinLength(k),h===k&&Hr(a,m,c,d,e,f)}}
function Ir(a,b,c,d,e,f){if(b.component===d){b.component=c;if(e)for(var h=b.ac;h.next();)Ir(a,h.value.toVertex,c,d,e,f);if(f)for(b=b.lc;b.next();)Ir(a,b.value.fromVertex,c,d,e,f)}}function sr(a){for(a=a.vertexes.i;a.next();){var b=a.value;if(b.valid)return b}return null}function tr(a){for(a=a.vertexes.i;a.next();){var b=a.value;if(b.valid){for(var c=!0,d=b.ac;d.next();)if(d.value.toVertex.valid){c=!1;break}if(c)return b}}return null}
function ur(a){for(a=a.vertexes.i;a.next();){var b=a.value;if(b.valid){for(var c=!0,d=b.lc;d.next();)if(d.value.fromVertex.valid){c=!1;break}if(c)return b}}return null}function vr(a,b){b.To=a.Ar;a.Ar++;for(var c=b.ac;c.next();){var d=c.value,e=d.toVertex;-1===e.To&&(d.forest=!0,vr(a,e))}b.finish=a.Ar;a.Ar++}
gn.prototype.assignLayers=function(){switch(this.bo){case Jr:Kr(this);break;case Lr:for(var a=0,b=this.network.vertexes.i;b.next();)a=Mr(this,b.value),this.ob=Math.max(a,this.ob);for(b.reset();b.next();)a=b.value,a.layer=this.ob-a.layer;break;default:case mr:Kr(this);for(b=this.network.vertexes.i;b.next();)b.value.valid=!1;for(b.reset();b.next();)a=b.value,0===a.lc.count&&Nr(this,a);a=Infinity;for(b.reset();b.next();)a=Math.min(a,b.value.layer);this.ob=-1;for(b.reset();b.next();){var c=b.value;c.layer-=
a;this.ob=Math.max(this.ob,c.layer)}}};function Kr(a){for(var b=a.network.vertexes.i;b.next();){var c=Or(a,b.value);a.ob=Math.max(c,a.ob)}}function Or(a,b){var c=0;if(-1===b.layer){for(var d=b.ac;d.next();)var e=d.value,f=e.toVertex,e=a.linkMinLength(e),c=Math.max(c,Or(a,f)+e);b.layer=c}else c=b.layer;return c}function Mr(a,b){var c=0;if(-1===b.layer){for(var d=b.lc;d.next();)var e=d.value,f=e.fromVertex,e=a.linkMinLength(e),c=Math.max(c,Mr(a,f)+e);b.layer=c}else c=b.layer;return c}
function Nr(a,b){if(!b.valid){b.valid=!0;for(var c=b.ac;c.next();)Nr(a,c.value.toVertex);for(c=a.network.vertexes.i;c.next();)c.value.component=-1;for(var d=b.Oe.n,e=d.length,f=0;f<e;f++){var h=d[f],k=a.linkMinLength(h);h.fromVertex.layer-h.toVertex.layer>k&&Hr(a,h.fromVertex,0,-1,!0,!1)}for(Hr(a,b,1,-1,!0,!0);0!==b.component;){for(var k=0,d=Infinity,l=0,m=null,n=a.network.vertexes.i;n.next();){var p=n.value;if(1===p.component){for(var q=0,r=!1,s=p.Oe.n,e=s.length,f=0;f<e;f++){var h=s[f],t=h.fromVertex,
q=q+1;1!==t.component&&(k+=1,t=t.layer-p.layer,h=a.linkMinLength(h),d=Math.min(d,t-h))}h=p.Je.n;e=h.length;for(f=0;f<e;f++)s=h[f].toVertex,q-=1,1!==s.component?k-=1:r=!0;(null===m||q<l)&&!r&&(m=p,l=q)}}if(0<k){for(c.reset();c.next();)e=c.value,1===e.component&&(e.layer+=d);b.component=0}else m.component=0}for(c=a.network.vertexes.i;c.next();)c.value.component=-1;for(Hr(a,b,1,-1,!0,!1);0!==b.component;){f=0;e=Infinity;d=0;k=null;for(l=a.network.vertexes.i;l.next();)if(m=l.value,1===m.component){n=
0;p=!1;h=m.Oe.n;q=h.length;for(r=0;r<q;r++)s=h[r].fromVertex,n+=1,1!==s.component?f+=1:p=!0;h=m.Je.n;q=h.length;for(r=0;r<q;r++)s=h[r],t=s.toVertex,n-=1,1!==t.component&&(f-=1,t=m.layer-t.layer,s=a.linkMinLength(s),e=Math.min(e,t-s));(null===k||n>d)&&!p&&(k=m,d=n)}if(0>f){for(c.reset();c.next();)f=c.value,1===f.component&&(f.layer-=e);b.component=0}else k.component=0}}}
function xr(a,b,c){return 90===a.aa?c&&!b.rev||!c&&b.rev?270:90:180===a.aa?c&&!b.rev||!c&&b.rev?0:180:270===a.aa?c&&!b.rev||!c&&b.rev?90:270:c&&!b.rev||!c&&b.rev?180:0}
gn.prototype.initializeIndices=function(){switch(this.Sn){default:case Pr:for(var a=this.network.vertexes.i;a.next();){var b=a.value,c=b.layer;b.index=this.Td[c];this.Td[c]++}break;case nr:a=this.network.vertexes.i;for(b=this.ob;0<=b;b--)for(a.reset();a.next();)c=a.value,c.layer===b&&-1===c.index&&Qr(this,c);break;case Rr:for(a=this.network.vertexes.i,b=0;b<=this.ob;b++)for(a.reset();a.next();)c=a.value,c.layer===b&&-1===c.index&&Sr(this,c)}};
function Qr(a,b){var c=b.layer;b.index=a.Td[c];a.Td[c]++;for(var c=b.Je.dc(),d=!0;d;)for(var d=!1,e=0;e<c.length-1;e++){var f=c[e],h=c[e+1];f.portFromColOffset>h.portFromColOffset&&(d=!0,c[e]=h,c[e+1]=f)}for(e=0;e<c.length;e++)d=c[e],d.valid&&(d=d.toVertex,-1===d.index&&Qr(a,d))}
function Sr(a,b){var c=b.layer;b.index=a.Td[c];a.Td[c]++;for(var c=b.Oe.dc(),d=!0,e=0;d;)for(d=!1,e=0;e<c.length-1;e++){var f=c[e],h=c[e+1];f.portToColOffset>h.portToColOffset&&(d=!0,c[e]=h,c[e+1]=f)}for(e=0;e<c.length;e++)d=c[e],d.valid&&(d=d.fromVertex,-1===d.index&&Sr(a,d))}
gn.prototype.reduceCrossings=function(){for(var a=this.countCrossings(),b=Br(this),c=0,d=0,e=0,c=0;c<this.Xl;c++){for(d=0;d<=this.ob;d++)Tr(this,d,1),Ur(this,d,1);e=this.countCrossings();e<a&&(a=e,b=Br(this));for(d=this.ob;0<=d;d--)Tr(this,d,-1),Ur(this,d,-1);e=this.countCrossings();e<a&&(a=e,b=Br(this))}Cr(this,b);for(c=0;c<this.Xl;c++){for(d=0;d<=this.ob;d++)Tr(this,d,0),Ur(this,d,0);e=this.countCrossings();e<a&&(a=e,b=Br(this));for(d=this.ob;0<=d;d--)Tr(this,d,0),Ur(this,d,0);e=this.countCrossings();
e<a&&(a=e,b=Br(this))}Cr(this,b);var f=!1,h=c=0,k=0,d=0;switch(this.nn){case Vr:break;case Wr:for(k=a+1;(d=this.countCrossings())<k;)for(k=d,c=this.ob;0<=c;c--)for(h=0;h<=c;h++){for(f=!0;f;)for(f=!1,d=c;d>=h;d--)f=Ur(this,d,-1)||f;e=this.countCrossings();e>=a?Cr(this,b):(a=e,b=Br(this));for(f=!0;f;)for(f=!1,d=c;d>=h;d--)f=Ur(this,d,1)||f;e=this.countCrossings();e>=a?Cr(this,b):(a=e,b=Br(this));for(f=!0;f;)for(f=!1,d=h;d<=c;d++)f=Ur(this,d,1)||f;e>=a?Cr(this,b):(a=e,b=Br(this));for(f=!0;f;)for(f=!1,
d=h;d<=c;d++)f=Ur(this,d,-1)||f;e>=a?Cr(this,b):(a=e,b=Br(this));for(f=!0;f;)for(f=!1,d=c;d>=h;d--)f=Ur(this,d,0)||f;e>=a?Cr(this,b):(a=e,b=Br(this));for(f=!0;f;)for(f=!1,d=h;d<=c;d++)f=Ur(this,d,0)||f;e>=a?Cr(this,b):(a=e,b=Br(this))}break;default:case or:for(c=this.ob,h=0,k=a+1;(d=this.countCrossings())<k;){k=d;for(f=!0;f;)for(f=!1,d=c;d>=h;d--)f=Ur(this,d,-1)||f;e=this.countCrossings();e>=a?Cr(this,b):(a=e,b=Br(this));for(f=!0;f;)for(f=!1,d=c;d>=h;d--)f=Ur(this,d,1)||f;e=this.countCrossings();
e>=a?Cr(this,b):(a=e,b=Br(this));for(f=!0;f;)for(f=!1,d=h;d<=c;d++)f=Ur(this,d,1)||f;e>=a?Cr(this,b):(a=e,b=Br(this));for(f=!0;f;)for(f=!1,d=h;d<=c;d++)f=Ur(this,d,-1)||f;e>=a?Cr(this,b):(a=e,b=Br(this));for(f=!0;f;)for(f=!1,d=c;d>=h;d--)f=Ur(this,d,0)||f;e>=a?Cr(this,b):(a=e,b=Br(this));for(f=!0;f;)for(f=!1,d=h;d<=c;d++)f=Ur(this,d,0)||f;e>=a?Cr(this,b):(a=e,b=Br(this))}}Cr(this,b)};
function Tr(a,b,c){var d=0,e=yr(a,b),f=a.Td[b],h=Gr(a,b,c);c=Fr(a,b,c);for(d=0;d<f;d++)-1===c[d]&&(c[d]=e[d].column),-1===h[d]&&(h[d]=e[d].column);for(var k=!0,l;k;)for(k=!1,d=0;d<f-1;d++)if(h[d+1]<h[d]||h[d+1]===h[d]&&c[d+1]<c[d])k=!0,l=h[d],h[d]=h[d+1],h[d+1]=l,l=c[d],c[d]=c[d+1],c[d+1]=l,l=e[d],e[d]=e[d+1],e[d+1]=l;for(d=h=0;d<f;d++)l=e[d],l.index=d,h+=a.nodeMinColumnSpace(l,!0),l.column=h,h+=1,h+=a.nodeMinColumnSpace(l,!1);zr(a,b,e)}
function Ur(a,b,c){var d=yr(a,b),e=a.Td[b];c=Dr(a,b,c);var f=0,h;h=[];for(f=0;f<e;f++)h[f]=-1;var k;k=[];for(f=0;f<e;f++)k[f]=-1;for(var l=!1,m=!0;m;)for(m=!1,f=0;f<e-1;f++){var n=c[d[f].index*e+d[f+1].index],p=c[d[f+1].index*e+d[f].index],q=0,r=0,s=d[f].column,t=d[f+1].column,u=a.nodeMinColumnSpace(d[f],!0),y=a.nodeMinColumnSpace(d[f],!1),w=a.nodeMinColumnSpace(d[f+1],!0),x=a.nodeMinColumnSpace(d[f+1],!1),u=s-u+w,y=t-y+x,w=w=0,A=d[f].lc.i;for(A.reset();A.next();)if(x=A.value,x.valid&&x.fromVertex.layer===
b){x=x.fromVertex;for(w=0;d[w]!==x;)w++;w<f&&(q+=2*(f-w),r+=2*(f+1-w));w===f+1&&(q+=1);w>f+1&&(q+=4*(w-f),r+=4*(w-(f+1)))}A=d[f].ac.i;for(A.reset();A.next();)if(x=A.value,x.valid&&x.toVertex.layer===b){x=x.toVertex;for(w=0;d[w]!==x;)w++;w===f+1&&(r+=1)}A=d[f+1].lc.i;for(A.reset();A.next();)if(x=A.value,x.valid&&x.fromVertex.layer===b){x=x.fromVertex;for(w=0;d[w]!==x;)w++;w<f&&(q+=2*(f+1-w),r+=2*(f-w));w===f&&(r+=1);w>f+1&&(q+=4*(w-(f+1)),r+=4*(w-f))}A=d[f+1].ac.i;for(A.reset();A.next();)if(x=A.value,
x.valid&&x.toVertex.layer===b){x=x.toVertex;for(w=0;d[w]!==x;)w++;w===f&&(q+=1)}var w=x=0,A=h[d[f].index],H=k[d[f].index],C=h[d[f+1].index],T=k[d[f+1].index];-1!==A&&(x+=Math.abs(A-s),w+=Math.abs(A-y));-1!==H&&(x+=Math.abs(H-s),w+=Math.abs(H-y));-1!==C&&(x+=Math.abs(C-t),w+=Math.abs(C-u));-1!==T&&(x+=Math.abs(T-t),w+=Math.abs(T-u));if(r<q-.5||r===q&&p<n-.5||r===q&&p===n&&w<x-.5)m=l=!0,d[f].column=y,d[f+1].column=u,n=d[f],d[f]=d[f+1],d[f+1]=n}for(f=0;f<e;f++)d[f].index=f;zr(a,b,d);return l}
gn.prototype.straightenAndPack=function(){var a=0,b=!1,c=0!==(this.Di&Xr),a=this.Di===pr;1E3<this.network.edges.count&&!a&&(c=!1);if(c){b=[];for(a=a=0;a<=this.ob;a++)b[a]=0;for(var d=0,e=this.network.vertexes.i;e.next();){var f=e.value,a=f.layer,d=f.column,f=this.nodeMinColumnSpace(f,!1);b[a]=Math.max(b[a],d+f)}for(e.reset();e.next();)f=e.value,a=f.layer,d=f.column,f.column=(8*(this.Fb-b[a])>>1)+8*d;this.Fb*=8}if(0!==(this.Di&Yr))for(b=!0;b;){b=!1;for(a=this.Hf+1;a<=this.ob;a++)b=Zr(this,a,1)||b;
for(a=this.Hf-1;0<=a;a--)b=Zr(this,a,-1)||b;b=Zr(this,this.Hf,0)||b}if(0!==(this.Di&$r)){for(a=this.Hf+1;a<=this.ob;a++)as(this,a,1);for(a=this.Hf-1;0<=a;a--)as(this,a,-1);as(this,this.Hf,0)}c&&(bs(this,-1),bs(this,1));if(0!==(this.Di&Yr))for(b=!0;b;){b=!1;b=Zr(this,this.Hf,0)||b;for(a=this.Hf+1;a<=this.ob;a++)b=Zr(this,a,0)||b;for(a=this.Hf-1;0<=a;a--)b=Zr(this,a,0)||b}};function Zr(a,b,c){for(var d=!1;cs(a,b,c);)d=!0;return d}
function cs(a,b,c){var d=0,e=yr(a,b),f=a.Td[b],h=Fr(a,b,-1);if(0<c)for(d=0;d<f;d++)h[d]=-1;var k=Fr(a,b,1);if(0>c)for(d=0;d<f;d++)k[d]=-1;for(var l=!1,m=!0;m;)for(m=!1,d=0;d<f;d++){var n=e[d].column,p=a.nodeMinColumnSpace(e[d],!0),q=a.nodeMinColumnSpace(e[d],!1),r=0,r=0>d-1||n-e[d-1].column-1>p+a.nodeMinColumnSpace(e[d-1],!1)?n-1:n,p=0,p=d+1>=f||e[d+1].column-n-1>q+a.nodeMinColumnSpace(e[d+1],!0)?n+1:n,s=q=0,t=0,u=0,y=0,w=0;if(0>=c)for(var x=e[d].lc.i;x.next();){var A=x.value;A.valid&&A.fromVertex.layer!==
b&&(u=Ar(A),y=A.portFromColOffset,w=A.portToColOffset,A=A.fromVertex.column,q+=(Math.abs(n+w-(A+y))+1)*u,s+=(Math.abs(r+w-(A+y))+1)*u,t+=(Math.abs(p+w-(A+y))+1)*u)}if(0<=c)for(x=e[d].ac.i;x.next();)A=x.value,A.valid&&A.toVertex.layer!==b&&(u=Ar(A),y=A.portFromColOffset,w=A.portToColOffset,A=A.toVertex.column,q+=(Math.abs(n+y-(A+w))+1)*u,s+=(Math.abs(r+y-(A+w))+1)*u,t+=(Math.abs(p+y-(A+w))+1)*u);w=y=u=0;x=h[e[d].index];A=k[e[d].index];-1!==x&&(u+=Math.abs(x-n),y+=Math.abs(x-r),w+=Math.abs(x-p));-1!==
A&&(u+=Math.abs(A-n),y+=Math.abs(A-r),w+=Math.abs(A-p));if(s<q||s===q&&y<u)m=l=!0,e[d].column=r;if(t<q||t===q&&w<u)m=l=!0,e[d].column=p}zr(a,b,e);a.normalize();return l}
function as(a,b,c){var d=0,e=yr(a,b),f=a.Td[b],h=Gr(a,b,c);c=[];for(d=0;d<f;d++)c[d]=h[d];for(h=!0;h;)for(h=!1,d=0;d<f;d++){var k=e[d].column,l=a.nodeMinColumnSpace(e[d],!0),m=a.nodeMinColumnSpace(e[d],!1),n=0,p=0,q=0,q=p=0;-1===c[d]?0===d&&d===f-1?n=k:0===d?(p=e[d+1].column,n=p-k===m+a.nodeMinColumnSpace(e[d+1],!0)?k-1:k):d===f-1?(q=e[d-1].column,n=k-q===l+a.nodeMinColumnSpace(e[d-1],!1)?k+1:k):(q=e[d-1].column,q=q+a.nodeMinColumnSpace(e[d-1],!1)+l+1,p=e[d+1].column,p=p-a.nodeMinColumnSpace(e[d+
1],!0)-m-1,n=(q+p)/2|0):0===d&&d===f-1?n=c[d]:0===d?(p=e[d+1].column,p=p-a.nodeMinColumnSpace(e[d+1],!0)-m-1,n=Math.min(c[d],p)):d===f-1?(q=e[d-1].column,q=q+a.nodeMinColumnSpace(e[d-1],!1)+l+1,n=Math.max(c[d],q)):(q=e[d-1].column,q=q+a.nodeMinColumnSpace(e[d-1],!1)+l+1,p=e[d+1].column,p=p-a.nodeMinColumnSpace(e[d+1],!0)-m-1,q<c[d]&&c[d]<p?n=c[d]:q>=c[d]?n=q:p<=c[d]&&(n=p));n!==k&&(h=!0,e[d].column=n)}zr(a,b,e);a.normalize()}
function ds(a,b){for(var c=!0,d=a.network.vertexes.i;d.next();){var e=d.value,f=a.nodeMinColumnSpace(e,!0),h=a.nodeMinColumnSpace(e,!1);if(e.column-f<=b&&e.column+h>=b){c=!1;break}}e=!1;if(c)for(d.reset();d.next();)c=d.value,c.column>b&&(c.column-=1,e=!0);return e}
function es(a,b){for(var c=b,c=b+1,d=0,e=[],f=[],d=0;d<=a.ob;d++)e[d]=!1,f[d]=!1;for(var h=a.network.vertexes.i;h.next();){var d=h.value,k=d.column-a.nodeMinColumnSpace(d,!0),l=d.column+a.nodeMinColumnSpace(d,!1);k<=b&&l>=b&&(e[d.layer]=!0);k<=c&&l>=c&&(f[d.layer]=!0)}k=!0;c=!1;for(d=0;d<=a.ob;d++)k=k&&!(e[d]&&f[d]);if(k)for(h.reset();h.next();)e=h.value,e.column>b&&(e.column-=1,c=!0);return c}
function bs(a,b){for(var c=0;c<=a.Fb;c++)for(;ds(a,c););a.normalize();for(c=0;c<a.Fb;c++)for(;es(a,c););a.normalize();var c=0,d,e=0,f=0,h=0;if(0<b)for(c=0;c<=a.Fb;c++)for(d=Br(a),e=Er(a),f=e+1;e<f;)f=e,fs(a,c,1),h=Er(a),h>e?Cr(a,d):h<e&&(e=h,d=Br(a));if(0>b)for(c=a.Fb;0<=c;c--)for(d=Br(a),e=Er(a),f=e+1;e<f;)f=e,fs(a,c,-1),h=Er(a),h>e?Cr(a,d):h<e&&(e=h,d=Br(a));a.normalize()}
function fs(a,b,c){a.mg=0;for(var d=a.network.vertexes.i;d.next();)d.value.component=-1;if(0<c)for(d.reset();d.next();){var e=d.value;e.column-a.nodeMinColumnSpace(e,!0)<=b&&(e.component=a.mg)}if(0>c)for(d.reset();d.next();)e=d.value,e.column+a.nodeMinColumnSpace(e,!1)>=b&&(e.component=a.mg);a.mg++;for(d.reset();d.next();)b=d.value,-1===b.component&&(Ir(a,b,a.mg,-1,!0,!0),a.mg++);var f=0;b=[];for(f=0;f<a.mg*a.mg;f++)b[f]=!1;e=[];for(f=0;f<(a.ob+1)*(a.Fb+1);f++)e[f]=-1;for(d.reset();d.next();)for(var f=
d.value,h=f.layer,k=Math.max(0,f.column-a.nodeMinColumnSpace(f,!0)),l=Math.min(a.Fb,f.column+a.nodeMinColumnSpace(f,!1));k<=l;k++)e[h*(a.Fb+1)+k]=f.component;for(f=0;f<=a.ob;f++){if(0<c)for(k=0;k<a.Fb;k++)-1!==e[f*(a.Fb+1)+k]&&-1!==e[f*(a.Fb+1)+k+1]&&e[f*(a.Fb+1)+k]!==e[f*(a.Fb+1)+k+1]&&(b[e[f*(a.Fb+1)+k]*a.mg+e[f*(a.Fb+1)+k+1]]=!0);if(0>c)for(k=a.Fb;0<k;k--)-1!==e[f*(a.Fb+1)+k]&&-1!==e[f*(a.Fb+1)+k-1]&&e[f*(a.Fb+1)+k]!==e[f*(a.Fb+1)+k-1]&&(b[e[f*(a.Fb+1)+k]*a.mg+e[f*(a.Fb+1)+k-1]]=!0)}e=[];for(f=
0;f<a.mg;f++)e[f]=!0;h=new I("number");h.add(0);for(l=0;0!==h.count;)if(l=h.n[h.count-1],h.Vc(h.count-1),e[l])for(e[l]=!1,f=0;f<a.mg;f++)b[l*a.mg+f]&&h.Ad(0,f);if(0<c)for(d.reset();d.next();)a=d.value,e[a.component]&&(a.column-=1);if(0>c)for(d.reset();d.next();)c=d.value,e[c.component]&&(c.column+=1)}
gn.prototype.commitLayout=function(){if(this.Gp)for(var a=wr(this,!0),b=wr(this,!1),c=this.network.edges.i;c.next();){var d=c.value.link;null!==d&&(d.xb=a,d.yb=b)}this.commitNodes();this.dz();this.xt&&this.commitLinks()};function wr(a,b){return 270===a.aa?b?pc:sc:90===a.aa?b?sc:pc:180===a.aa?b?qc:rc:b?rc:qc}
gn.prototype.commitNodes=function(){this.ig=[];this.Ff=[];this.Ef=[];this.Lb=[];for(var a=0;a<=this.ob;a++)this.ig[a]=0,this.Ff[a]=0,this.Ef[a]=0,this.Lb[a]=0;for(a=this.network.vertexes.i;a.next();){var b=a.value,c=b.layer;this.ig[c]=Math.max(this.ig[c],this.nodeMinLayerSpace(b,!0));this.Ff[c]=Math.max(this.Ff[c],this.nodeMinLayerSpace(b,!1))}for(var b=0,d=this.$l,c=0;c<=this.ob;c++){var e=d;0>=this.ig[c]+this.Ff[c]&&(e=0);0<c&&(b+=e/2);90===this.aa||0===this.aa?(b+=this.Ff[c],this.Ef[c]=b,b+=this.ig[c]):
(b+=this.ig[c],this.Ef[c]=b,b+=this.Ff[c]);c<this.ob&&(b+=e/2);this.Lb[c]=b}d=b;b=this.Xd.copy();null!==this.group&&(270===this.direction||90===this.direction?b.y-=10:b.x-=10,270===this.direction||90===this.direction?b.x-=this.qD/2:b.y-=this.qD/2);for(c=0;c<=this.ob;c++)270===this.aa?this.Ef[c]=b.y+this.Ef[c]:90===this.aa?(this.Ef[c]=b.y+d-this.Ef[c],this.Lb[c]=d-this.Lb[c]):180===this.aa?this.Ef[c]=b.x+this.Ef[c]:(this.Ef[c]=b.x+d-this.Ef[c],this.Lb[c]=d-this.Lb[c]);for(a.reset();a.next();){var c=
a.value,d=c.layer,e=c.column|0,f=0,h=0;270===this.aa||90===this.aa?(f=b.x+this.Hd*e,h=this.Ef[d]):(f=this.Ef[d],h=b.y+this.Hd*e);c.na=f;c.ua=h;c.commit()}};
gn.prototype.dz=function(){for(var a=0,b=this.$l,c=0;c<=this.ob;c++)a+=this.ig[c],a+=this.Ff[c];for(var a=a+this.ob*b,b=[],c=this.Hd*this.Fb,d=this.KH;0<=d;d--)270===this.aa?0===d?b.push(new B(0,0,c,Math.abs(this.Lb[0]))):b.push(new B(0,this.Lb[d-1],c,Math.abs(this.Lb[d-1]-this.Lb[d]))):90===this.aa?0===d?b.push(new B(0,this.Lb[0],c,Math.abs(this.Lb[0]-a))):b.push(new B(0,this.Lb[d],c,Math.abs(this.Lb[d-1]-this.Lb[d]))):180===this.aa?0===d?b.push(new B(0,0,Math.abs(this.Lb[0]),c)):b.push(new B(this.Lb[d-
1],0,Math.abs(this.Lb[d-1]-this.Lb[d]),c)):0===d?b.push(new B(this.Lb[0],0,Math.abs(this.Lb[0]-a),c)):b.push(new B(this.Lb[d],0,Math.abs(this.Lb[d-1]-this.Lb[d]),c));this.commitLayers(b,Yc)};gn.prototype.commitLayers=function(){};
gn.prototype.commitLinks=function(){for(var a=this.network.edges.i,b;a.next();)b=a.value.link,null!==b&&(b.Bl(),b.Po(),b.Ni());for(a.reset();a.next();)b=a.value.link,null!==b&&b.hn();for(a.reset();a.next();){var c=a.value;b=c.link;if(null!==b){b.Bl();var d=b,e=d.T,f=d.Z,h=d.bd,k=d.Fd;if(c.valid){if(b.Ie===Hi&&4===b.oa){if(c.rev)var l=e,e=f,f=l,m=h,h=k,k=m;if(c.fromVertex.column===c.toVertex.column){var n=b.getLinkPoint(e,h,b.computeSpot(!0),!0,!1,f,k),p=b.getLinkPoint(f,k,b.computeSpot(!1),!1,!1,
e,h);n.G()||n.set(e.U.bl);p.G()||p.set(f.U.bl);b.Po();b.Yk(n.x,n.y);b.Yk((2*n.x+p.x)/3,(2*n.y+p.y)/3);b.Yk((n.x+2*p.x)/3,(n.y+2*p.y)/3);b.Yk(p.x,p.y)}else{var q=!1,r=!1;null!==h&&b.computeSpot(!0)===sb&&(q=!0);null!==k&&b.computeSpot(!1)===sb&&(r=!0);if(q||r){var s=b.l(0).x,t=b.l(0).y,u=b.l(1).x,y=b.l(1).y,w=b.l(2).x,x=b.l(2).y,A=b.l(3).x,H=b.l(3).y;if(q){90===this.aa||270===this.aa?(u=s,y=(t+H)/2):(u=(s+A)/2,y=t);b.V(1,u,y);var C=b.getLinkPoint(e,h,b.computeSpot(!0),!0,!1,f,k);C.G()||C.set(e.U.bl);
b.V(0,C.x,C.y)}r&&(90===this.aa||270===this.aa?(w=A,x=(t+H)/2):(w=(s+A)/2,x=H),b.V(2,w,x),C=b.getLinkPoint(f,k,b.computeSpot(!1),!1,!1,e,h),C.G()||C.set(f.U.bl),b.V(3,C.x,C.y))}}}b.Ni()}else if(c.fromVertex.layer===c.toVertex.layer)b.Ni();else{var T=!1,ba=!1,U=0,M=b.ht+1;if(b.jc)ba=!0,U=b.oa,4<U&&b.points.removeRange(2,U-3);else if(b.Ie===Hi)T=!0,U=b.oa,4<U&&b.points.removeRange(2,U-3),M=2;else{var U=b.oa,aa=b.computeSpot(!0)===sb,Ia=b.computeSpot(!1)===sb;2<U&&aa&&Ia?b.points.removeRange(1,U-2):
3<U&&aa&&!Ia?b.points.removeRange(1,U-3):3<U&&!aa&&Ia?b.points.removeRange(2,U-2):4<U&&!aa&&!Ia&&b.points.removeRange(2,U-3)}var oa=c.fromVertex,Ga=c.toVertex,pa,Ca;if(c.rev){for(var ua=0;null!==Ga&&oa!==Ga;){Ca=pa=null;for(var gc=Ga.lc.i;gc.next();){var rd=gc.value;if(rd.link===c.link&&(pa=rd.fromVertex,Ca=rd.toVertex,null===pa.qd))break}if(pa!==oa)if(qb=b.l(M-1).x,gb=b.l(M-1).y,ka=pa.na,la=pa.ua,ba)180===this.aa||0===this.aa?2===M?(b.w(M++,qb,gb),b.w(M++,qb,la)):(se=null!==Ca?Ca.ua:gb,se!==la&&
(rb=this.Lb[pa.layer-1],b.w(M++,rb,gb),b.w(M++,rb,la))):2===M?(b.w(M++,qb,gb),b.w(M++,ka,gb)):(ud=null!==Ca?Ca.na:qb,ud!==ka&&(rb=this.Lb[pa.layer-1],b.w(M++,qb,rb),b.w(M++,ka,rb)));else if(2===M)if(Va=Math.max(10,this.ig[Ga.layer]),Ra=Math.max(10,this.Ff[Ga.layer]),T)180===this.aa?ka<=Ga.M.x?(ua=Ga.M.x,b.w(M++,ua-Va,la),b.w(M++,ua,la),b.w(M++,ua+Ra,la)):(b.w(M++,ka-Va,la),b.w(M++,ka,la),b.w(M++,ka+Ra,la)):90===this.aa?la>=Ga.M.bottom?(ua=Ga.M.y+Ga.M.height,b.w(M++,ka,ua+Ra),b.w(M++,ka,ua),b.w(M++,
ka,ua-Va)):(b.w(M++,ka,la+Ra),b.w(M++,ka,la),b.w(M++,ka,la-Va)):270===this.aa?la<=Ga.M.y?(ua=Ga.M.y,b.w(M++,ka,ua-Va),b.w(M++,ka,ua),b.w(M++,ka,ua+Ra)):(b.w(M++,ka,la-Va),b.w(M++,ka,la),b.w(M++,ka,la+Ra)):0===this.aa&&(ka>=Ga.M.right?(ua=Ga.M.x+Ga.M.width,b.w(M++,ua+Ra,la),b.w(M++,ua,la),b.w(M++,ua-Va,la)):(b.w(M++,ka+Ra,la),b.w(M++,ka,la),b.w(M++,ka-Va,la)));else{b.w(M++,qb,gb);var sd=0;if(180===this.aa||0===this.aa){if(180===this.aa?ka>=Ga.M.right:ka<=Ga.M.x)sd=(0===this.aa?-Va:Ra)/2;b.w(M++,qb+
sd,la)}else{if(270===this.aa?la>=Ga.M.bottom:la<=Ga.M.y)sd=(90===this.aa?-Va:Ra)/2;b.w(M++,ka,gb+sd)}b.w(M++,ka,la)}else Va=Math.max(10,this.ig[pa.layer]),Ra=Math.max(10,this.Ff[pa.layer]),180===this.aa?(T&&b.w(M++,ka-Va,la),b.w(M++,ka,la),T&&b.w(M++,ka+Ra,la)):90===this.aa?(T&&b.w(M++,ka,la+Ra),b.w(M++,ka,la),T&&b.w(M++,ka,la-Va)):270===this.aa?(T&&b.w(M++,ka,la-Va),b.w(M++,ka,la),T&&b.w(M++,ka,la+Ra)):(T&&b.w(M++,ka+Ra,la),b.w(M++,ka,la),T&&b.w(M++,ka-Va,la));Ga=pa}if(null===k||b.computeSpot(!1)!==
sb||ba)if(qb=b.l(M-1).x,gb=b.l(M-1).y,ka=b.l(M).x,la=b.l(M).y,ba){var Je=this.Ff[oa.layer],Wb=0;180===this.aa||0===this.aa?(Wb=gb,Wb>=oa.M.y&&Wb<=oa.M.bottom&&(180===this.aa?ka>=oa.M.x:ka<=oa.M.right)&&(ua=oa.na+(180===this.aa?-Je:Je),Wb=Wb<oa.M.y+oa.M.height/2?oa.M.y-this.Hd/2:oa.M.bottom+this.Hd/2,b.w(M++,ua,gb),b.w(M++,ua,Wb)),b.w(M++,ka,Wb)):(Wb=qb,Wb>=oa.M.x&&Wb<=oa.M.right&&(270===this.aa?la>=oa.M.y:la<=oa.M.bottom)&&(ua=oa.ua+(270===this.aa?-Je:Je),Wb=Wb<oa.M.x+oa.M.width/2?oa.M.x-this.Hd/
2:oa.M.right+this.Hd/2,b.w(M++,qb,ua),b.w(M++,Wb,ua)),b.w(M++,Wb,la));b.w(M++,ka,la)}else if(T)Va=Math.max(10,this.ig[oa.layer]),Ra=Math.max(10,this.Ff[oa.layer]),180===this.aa&&ka>=oa.M.x?(ua=oa.M.x+oa.M.width,b.V(M-2,ua,gb),b.V(M-1,ua+Ra,gb)):90===this.aa&&la<=oa.M.bottom?(ua=oa.M.y,b.V(M-2,qb,ua),b.V(M-1,qb,ua-Va)):270===this.aa&&la>=oa.M.y?(ua=oa.M.y+oa.M.height,b.V(M-2,qb,ua),b.V(M-1,qb,ua+Ra)):0===this.aa&&ka<=oa.M.right&&(ua=oa.M.x,b.V(M-2,ua,gb),b.V(M-1,ua-Va,gb));else{Va=Math.max(10,this.ig[oa.layer]);
Ra=Math.max(10,this.Ff[oa.layer]);sd=0;if(180===this.aa||0===this.aa){if(180===this.aa?ka<=oa.M.x:ka>=oa.M.right)sd=(0===this.aa?Ra:-Va)/2;b.w(M++,ka+sd,gb)}else{if(270===this.aa?la<=oa.M.y:la>=oa.M.bottom)sd=(90===this.aa?Ra:-Va)/2;b.w(M++,qb,la+sd)}b.w(M++,ka,la)}}else{for(;null!==oa&&oa!==Ga;){Ca=pa=null;for(var Gg=oa.ac.i;Gg.next();){var td=Gg.value;if(td.link===c.link&&(pa=td.toVertex,Ca=td.fromVertex,null!==Ca.qd&&(Ca=null),null===pa.qd))break}var qb=0,gb=0,ka=0,la=0,rb=0,Va=0,Ra=0;if(pa!==
Ga)if(qb=b.l(M-1).x,gb=b.l(M-1).y,ka=pa.na,la=pa.ua,ba)if(180===this.aa||0===this.aa){var se=null!==Ca?Ca.ua:gb;se!==la&&(rb=this.Lb[pa.layer],2===M&&(rb=0===this.aa?Math.max(rb,qb):Math.min(rb,qb)),b.w(M++,rb,gb),b.w(M++,rb,la))}else{var ud=null!==Ca?Ca.na:qb;ud!==ka&&(rb=this.Lb[pa.layer],2===M&&(rb=90===this.aa?Math.max(rb,gb):Math.min(rb,gb)),b.w(M++,qb,rb),b.w(M++,ka,rb))}else Va=Math.max(10,this.ig[pa.layer]),Ra=Math.max(10,this.Ff[pa.layer]),180===this.aa?(b.w(M++,ka+Ra,la),T&&b.w(M++,ka,la),
b.w(M++,ka-Va,la)):90===this.aa?(b.w(M++,ka,la-Va),T&&b.w(M++,ka,la),b.w(M++,ka,la+Ra)):270===this.aa?(b.w(M++,ka,la+Ra),T&&b.w(M++,ka,la),b.w(M++,ka,la-Va)):(b.w(M++,ka-Va,la),T&&b.w(M++,ka,la),b.w(M++,ka+Ra,la));oa=pa}ba&&(qb=b.l(M-1).x,gb=b.l(M-1).y,ka=b.l(M).x,la=b.l(M).y,180===this.aa||0===this.aa?gb!==la&&(rb=0===this.aa?Math.min(Math.max((ka+qb)/2,this.Lb[Ga.layer]),ka):Math.max(Math.min((ka+qb)/2,this.Lb[Ga.layer]),ka),b.w(M++,rb,gb),b.w(M++,rb,la)):qb!==ka&&(rb=90===this.aa?Math.min(Math.max((la+
gb)/2,this.Lb[Ga.layer]),la):Math.max(Math.min((la+gb)/2,this.Lb[Ga.layer]),la),b.w(M++,qb,rb),b.w(M++,ka,rb)))}if(null!==d&&T){if(null!==h){if(b.computeSpot(!0)===sb){var be=b.l(0),Ld=b.l(2);be.K(Ld)||b.V(1,(be.x+Ld.x)/2,(be.y+Ld.y)/2)}C=b.getLinkPoint(e,h,sb,!0,!1,f,k);C.G()||C.set(e.U.bl);b.V(0,C.x,C.y)}null!==k&&(b.computeSpot(!1)===sb&&(be=b.l(b.oa-1),Ld=b.l(b.oa-3),be.K(Ld)||b.V(b.oa-2,(be.x+Ld.x)/2,(be.y+Ld.y)/2)),C=b.getLinkPoint(f,k,sb,!1,!1,e,h),C.G()||C.set(f.U.bl),b.V(b.oa-1,C.x,C.y))}b.Ni();
c.commit()}}}for(var ce=new I(W),Hg=this.network.edges.i;Hg.next();){var ad=Hg.value.link;null!==ad&&ad.jc&&!ce.contains(ad)&&ce.add(ad)}if(0<ce.count)if(90===this.aa||270===this.aa){for(var gi=0,xb=new I(gs),Xb,Md,jh=ce.i;jh.next();){var Lb=jh.value;if(null!==Lb&&Lb.jc)for(var jb=2;jb<Lb.oa-3;jb++)if(Xb=Lb.l(jb),Md=Lb.l(jb+1),hs(Xb.y,Md.y)&&!hs(Xb.x,Md.x)){var bb=new gs;bb.layer=Math.floor(Xb.y/2);var Ke=Lb.l(0),Nd=Lb.l(Lb.oa-1);bb.first=Ke.x*Ke.x+Ke.y;bb.Rd=Nd.x*Nd.x+Nd.y;bb.Ge=Math.min(Xb.x,Md.x);
bb.Yd=Math.max(Xb.x,Md.x);bb.index=jb;bb.link=Lb;if(jb+2<Lb.oa){var Ig=Lb.l(jb-1),Jg=Lb.l(jb+2),hc=0;Ig.y<Xb.y?hc=Jg.y<Xb.y?3:Xb.x<Md.x?2:1:Ig.y>Xb.y&&(hc=Jg.y>Xb.y?0:Md.x<Xb.x?2:1);bb.$h=hc}xb.add(bb)}}if(1<xb.count){xb.sort(this.RE);for(var ic=0;ic<xb.count;){for(var de=xb.n[ic].layer,bd=ic+1;bd<xb.count&&xb.n[bd].layer===de;)bd++;if(1<bd-ic)for(var lb=ic;lb<bd;){for(var Oc=xb.n[lb].Yd,Ka=ic+1;Ka<bd&&xb.n[Ka].Ge<Oc;)Oc=Math.max(Oc,xb.n[Ka].Yd),Ka++;var mb=Ka-lb;if(1<mb){xb.Jp(this.ix,lb,lb+mb);
for(var tc=1,vd=xb.n[lb].Rd,jb=lb;jb<Ka;jb++){var Ib=xb.n[jb];Ib.Rd!==vd&&(tc++,vd=Ib.Rd)}xb.Jp(this.QE,lb,lb+mb);for(var ee=1,vd=xb.n[lb].first,jb=lb;jb<Ka;jb++)Ib=xb.n[jb],Ib.first!==vd&&(ee++,vd=Ib.first);var qf=!0,Le=ee;tc<ee?(qf=!1,Le=tc,vd=xb.n[lb].Rd,xb.Jp(this.ix,lb,lb+mb)):vd=xb.n[lb].first;for(var Jf=0,jb=lb;jb<Ka;jb++){Ib=xb.n[jb];(qf?Ib.first:Ib.Rd)!==vd&&(Jf++,vd=qf?Ib.first:Ib.Rd);Lb=Ib.link;Xb=Lb.l(Ib.index);Md=Lb.l(Ib.index+1);var Jb=this.pE*(Jf-(Le-1)/2);gi++;Lb.Bl();Lb.V(Ib.index,
Xb.x,Xb.y+Jb);Lb.V(Ib.index+1,Md.x,Md.y+Jb);Lb.Ni()}}lb=Ka}ic=bd}}}else{for(var te=0,Mb=new I(gs),jc,Qb,Fc=ce.i;Fc.next();){var nb=Fc.value;if(null!==nb&&nb.jc)for(var Cb=2;Cb<nb.oa-3;Cb++)if(jc=nb.l(Cb),Qb=nb.l(Cb+1),hs(jc.x,Qb.x)&&!hs(jc.y,Qb.y)){var Kb=new gs;Kb.layer=Math.floor(jc.x/2);var Od=nb.l(0),Kg=nb.l(nb.oa-1);Kb.first=Od.x+Od.y*Od.y;Kb.Rd=Kg.x+Kg.y*Kg.y;Kb.Ge=Math.min(jc.y,Qb.y);Kb.Yd=Math.max(jc.y,Qb.y);Kb.index=Cb;Kb.link=nb;if(Cb+2<nb.oa){var kh=nb.l(Cb-1),lh=nb.l(Cb+2),ue=0;kh.x<jc.x?
ue=lh.x<jc.x?3:jc.y<Qb.y?2:1:kh.x>jc.x&&(ue=lh.x>jc.x?0:Qb.y<jc.y?2:1);Kb.$h=ue}Mb.add(Kb)}}if(1<Mb.count){Mb.sort(this.RE);for(var cd=0;cd<Mb.count;){for(var rf=Mb.n[cd].layer,kc=cd+1;kc<Mb.count&&Mb.n[kc].layer===rf;)kc++;if(1<kc-cd)for(var yb=cd;yb<kc;){for(var zb=Mb.n[yb].Yd,Gc=cd+1;Gc<kc&&Mb.n[Gc].Ge<zb;)zb=Math.max(zb,Mb.n[Gc].Yd),Gc++;var Lg=Gc-yb;if(1<Lg){Mb.Jp(this.ix,yb,yb+Lg);for(var Pc=1,Qc=Mb.n[yb].Rd,Cb=yb;Cb<Gc;Cb++){var kb=Mb.n[Cb];kb.Rd!==Qc&&(Pc++,Qc=kb.Rd)}Mb.Jp(this.QE,yb,yb+Lg);
for(var Rc=1,Qc=Mb.n[yb].first,Cb=yb;Cb<Gc;Cb++)kb=Mb.n[Cb],kb.first!==Qc&&(Rc++,Qc=kb.first);var Pd=!0,fe=Rc;Pc<Rc?(Pd=!1,fe=Pc,Qc=Mb.n[yb].Rd,Mb.Jp(this.ix,yb,yb+Lg)):Qc=Mb.n[yb].first;for(var sf=0,Cb=yb;Cb<Gc;Cb++){kb=Mb.n[Cb];(Pd?kb.first:kb.Rd)!==Qc&&(sf++,Qc=Pd?kb.first:kb.Rd);nb=kb.link;jc=nb.l(kb.index);Qb=nb.l(kb.index+1);var hi=this.pE*(sf-(fe-1)/2);te++;nb.Bl();nb.V(kb.index,jc.x+hi,jc.y);nb.V(kb.index+1,Qb.x+hi,Qb.y);nb.Ni()}}yb=Gc}cd=kc}}}};
gn.prototype.RE=function(a,b){return a instanceof gs&&b instanceof gs&&a!==b?a.layer<b.layer?-1:a.layer>b.layer?1:a.Ge<b.Ge?-1:a.Ge>b.Ge?1:a.Yd<b.Yd?-1:a.Yd>b.Yd?1:0:0};gn.prototype.QE=function(a,b){return a instanceof gs&&b instanceof gs&&a!==b?a.first<b.first?-1:a.first>b.first||a.$h<b.$h?1:a.$h>b.$h||a.Ge<b.Ge?-1:a.Ge>b.Ge?1:a.Yd<b.Yd?-1:a.Yd>b.Yd?1:0:0};
gn.prototype.ix=function(a,b){return a instanceof gs&&b instanceof gs&&a!==b?a.Rd<b.Rd?-1:a.Rd>b.Rd||a.$h<b.$h?1:a.$h>b.$h||a.Ge<b.Ge?-1:a.Ge>b.Ge?1:a.Yd<b.Yd?-1:a.Yd>b.Yd?1:0:0};function hs(a,b){var c=a-b;return-1<c&&1>c}function yr(a,b){var c,d=a.Td[b];if(d>=a.Sg.length){c=[];for(var e=0;e<a.Sg.length;e++)c[e]=a.Sg[e];a.Sg=c}void 0===a.Sg[d]||null===a.Sg[d]?c=[]:(c=a.Sg[d],a.Sg[d]=null);d=a.Br[b];for(e=0;e<d.length;e++){var f=d[e];c[f.index]=f}return c}function zr(a,b,c){a.Sg[a.Td[b]]=c}
v.defineProperty(gn,{layerSpacing:"layerSpacing"},function(){return this.$l},function(a){this.$l!==a&&(v.j(a,"number",gn,"layerSpacing"),0<=a&&(this.$l=a,this.H()))});v.defineProperty(gn,{qD:"columnSpacing"},function(){return this.Hd},function(a){this.Hd!==a&&(v.j(a,"number",gn,"columnSpacing"),0<a&&(this.Hd=a,this.H()))});
v.defineProperty(gn,{direction:"direction"},function(){return this.aa},function(a){this.aa!==a&&(v.j(a,"number",gn,"direction"),0===a||90===a||180===a||270===a?(this.aa=a,this.H()):v.k("LayeredDigraphLayout.direction must be 0, 90, 180, or 270"))});v.defineProperty(gn,{angle:"angle"},function(){return this.direction},function(a){this.direction=a});
v.defineProperty(gn,{AG:"cycleRemoveOption"},function(){return this.An},function(a){this.An!==a&&(v.pb(a,gn,gn,"cycleRemoveOption"),a===rr||a===lr)&&(this.An=a,this.H())});v.defineProperty(gn,{BH:"layeringOption"},function(){return this.bo},function(a){this.bo!==a&&(v.pb(a,gn,gn,"layeringOption"),a===mr||a===Jr||a===Lr)&&(this.bo=a,this.H())});
v.defineProperty(gn,{lH:"initializeOption"},function(){return this.Sn},function(a){this.Sn!==a&&(v.pb(a,gn,gn,"initializeOption"),a===nr||a===Rr||a===Pr)&&(this.Sn=a,this.H())});v.defineProperty(gn,{ZJ:"iterations"},function(){return this.Xl},function(a){this.Xl!==a&&(v.Ue(a,qr,"iterations"),0<=a&&(this.Xl=a,this.H()))});v.defineProperty(gn,{eG:"aggressiveOption"},function(){return this.nn},function(a){this.nn!==a&&(v.pb(a,gn,gn,"aggressiveOption"),a===Vr||a===or||a===Wr)&&(this.nn=a,this.H())});
v.defineProperty(gn,{vK:"packOption"},function(){return this.Di},function(a){this.Di!==a&&(v.j(a,"number",gn,"packOption"),0<=a&&8>a&&(this.Di=a,this.H()))});v.defineProperty(gn,{Gp:"setsPortSpots"},function(){return this.Kh},function(a){this.Kh!==a&&(v.j(a,"boolean",gn,"setsPortSpots"),this.Kh=a,this.H())});v.defineProperty(gn,{pE:"linkSpacing"},function(){return this.ir},function(a){this.ir!==a&&(v.j(a,"number",gn,"linkSpacing"),0<=a&&(this.ir=a,this.H()))});v.u(gn,{KH:"maxLayer"},function(){return this.ob});
v.u(gn,{eK:"maxIndex"},function(){return this.ov});v.u(gn,{dK:"maxColumn"},function(){return this.Fb});v.u(gn,{jK:"minIndexLayer"},function(){return this.sr});v.u(gn,{fK:"maxIndexLayer"},function(){return this.Hf});var lr;gn.CycleDepthFirst=lr=v.p(gn,"CycleDepthFirst",0);var rr;gn.CycleGreedy=rr=v.p(gn,"CycleGreedy",1);var mr;gn.LayerOptimalLinkLength=mr=v.p(gn,"LayerOptimalLinkLength",0);var Jr;gn.LayerLongestPathSink=Jr=v.p(gn,"LayerLongestPathSink",1);var Lr;
gn.LayerLongestPathSource=Lr=v.p(gn,"LayerLongestPathSource",2);var nr;gn.InitDepthFirstOut=nr=v.p(gn,"InitDepthFirstOut",0);var Rr;gn.InitDepthFirstIn=Rr=v.p(gn,"InitDepthFirstIn",1);var Pr;gn.InitNaive=Pr=v.p(gn,"InitNaive",2);var Vr;gn.AggressiveNone=Vr=v.p(gn,"AggressiveNone",0);var or;gn.AggressiveLess=or=v.p(gn,"AggressiveLess",1);var Wr;gn.AggressiveMore=Wr=v.p(gn,"AggressiveMore",2);gn.PackNone=0;var Xr;gn.PackExpand=Xr=1;var Yr;gn.PackStraighten=Yr=2;var $r;gn.PackMedian=$r=4;var pr;
gn.PackAll=pr=7;function gs(){this.index=this.Yd=this.Ge=this.Rd=this.first=this.layer=0;this.link=null;this.$h=0}v.Nd(gs,{layer:!0,first:!0,Rd:!0,Ge:!0,Yd:!0,index:!0,link:!0,$h:!0});function qr(){Zf.call(this)}v.Ja(qr,Zf);v.ea("LayeredDigraphNetwork",qr);qr.prototype.createVertex=function(){return new is};qr.prototype.createEdge=function(){return new js};
function is(){nq.call(this);this.index=this.column=this.layer=-1;this.component=NaN;this.near=null;this.valid=!1;this.finish=this.To=NaN;this.Cm=0;this.Vz=this.Wz=null}v.Ja(is,nq);v.ea("LayeredDigraphVertex",is);function js(){oq.call(this);this.forest=this.rev=this.valid=!1;this.portToPos=this.portFromPos=NaN;this.portToColOffset=this.portFromColOffset=0}v.Ja(js,oq);v.ea("LayeredDigraphEdge",js);
function Z(){0<arguments.length&&v.dd(Z);Yf.call(this);this.ud=new J(Object);this.Kr=ks;this.Df=ls;this.Fs=ms;this.mv=ns;this.PA=null;this.th=!0;this.fd=os;this.bg=(new ia(10,10)).freeze();this.ra=new ps;this.ta=new ps;this.My=[]}v.Ja(Z,Yf);v.ea("TreeLayout",Z);Z.prototype.cloneProtected=function(a){Yf.prototype.cloneProtected.call(this,a);a.Kr=this.Kr;a.Fs=this.Fs;a.mv=this.mv;a.th=this.th;a.fd=this.fd;a.bg.assign(this.bg);a.ra.copyInheritedPropertiesFrom(this.ra);a.ta.copyInheritedPropertiesFrom(this.ta)};
Z.prototype.createNetwork=function(){return new qs};Z.prototype.makeNetwork=function(a){function b(a){if(a instanceof S)return!a.uf&&"Comment"!==a.gc;if(a instanceof W){var b=a.T;if(null===b||b.uf||"Comment"===b.gc)return!1;a=a.Z;return null===a||a.uf||"Comment"===a.gc?!1:!0}return!1}var c=this.createNetwork();c.Pb=this;a instanceof E?(c.Sj(a.Ag,!0,b),c.Sj(a.links,!0,b)):a instanceof V?c.Sj(a.Ic,!1,b):c.Sj(a.i,!1,b);return c};
Z.prototype.doLayout=function(a){null===a&&v.k("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts");null===this.network&&(this.network=this.makeNetwork(a));this.Nf!==rs&&(this.Xd=this.initialOrigin(this.Xd));var b=this.g;null===b&&a instanceof E&&(b=a);this.Df=this.path===ks&&null!==b?b.Qd?ls:ss:this.path===ks?ls:this.path;if(0<this.network.vertexes.count){this.network.uw();for(a=this.network.vertexes.i;a.next();)b=a.value,b.initialized=!1,b.level=
0,b.parent=null,b.children=[];if(0<this.ud.count){a=new J(ps);for(b=this.ud.i;b.next();){var c=b.value;c instanceof S?(c=this.network.Nm(c),null!==c&&a.add(c)):c instanceof ps&&a.add(c)}this.ud=a}0===this.ud.count&&this.findRoots();for(a=this.ud.copy().i;a.next();)b=a.value,b.initialized||(b.initialized=!0,ts(this,b));for(a=this.ud.i;a.next();)b=a.value,b instanceof ps&&us(this,b);for(a=this.ud.i;a.next();)b=a.value,b instanceof ps&&vs(this,b);for(a=this.ud.i;a.next();)b=a.value,b instanceof ps&&
ws(this,b);this.Zy();if(this.Iz===xs){c=[];for(a=this.network.vertexes.i;a.next();){var d=a.value,b=d.parent;null===b&&(b=d);var b=0===b.angle||180===b.angle,e=c[d.level];void 0===e&&(e=0);c[d.level]=Math.max(e,b?d.width:d.height)}for(d=0;d<c.length;d++)void 0===c[d]&&(c[d]=0);this.PA=c;for(a=this.network.vertexes.i;a.next();)d=a.value,b=d.parent,null===b&&(b=d),0===b.angle||180===b.angle?(180===b.angle&&(d.it+=c[d.level]-d.width),d.width=c[d.level]):(270===b.angle&&(d.jt+=c[d.level]-d.height),d.height=
c[d.level])}else if(this.Iz===ys)for(a=this.network.vertexes.i;a.next();){c=a.value;b=0===c.angle||180===c.angle;e=-1;for(d=0;d<c.children.length;d++)var f=c.children[d],e=Math.max(e,b?f.width:f.height);if(0<=e)for(d=0;d<c.children.length;d++)f=c.children[d],b?(180===c.angle&&(f.it+=e-f.width),f.width=e):(270===c.angle&&(f.jt+=e-f.height),f.height=e)}for(a=this.ud.i;a.next();)b=a.value,b instanceof ps&&this.layoutTree(b);this.arrangeTrees();this.updateParts()}this.network=null;this.ud=new J(Object);
this.wf=!0};
Z.prototype.findRoots=function(){for(var a=this.network.vertexes.i;a.next();){var b=a.value;switch(this.Df){case ls:0===b.lc.count&&this.ud.add(b);break;case ss:0===b.ac.count&&this.ud.add(b);break;default:v.k("Unhandled path value "+this.Df.toString())}}if(0===this.ud.count){for(var a=999999,b=null,c=this.network.vertexes.i;c.next();){var d=c.value;switch(this.Df){case ls:d.lc.count<a&&(a=d.lc.count,b=d);break;case ss:d.ac.count<a&&(a=d.ac.count,b=d);break;default:v.k("Unhandled path value "+this.Df.toString())}}null!==
b&&this.ud.add(b)}};
function ts(a,b){if(null!==b){switch(a.Df){case ls:if(0<b.ac.count){for(var c=new I(ps),d=b.GG;d.next();){var e=d.value;zs(a,b,e)&&c.add(e)}0<c.count&&(b.children=c.dc())}break;case ss:if(0<b.lc.count){c=new I(ps);for(d=b.DI;d.next();)e=d.value,zs(a,b,e)&&c.add(e);0<c.count&&(b.children=c.dc())}break;default:v.k("Unhandled path value"+a.Df.toString())}c=b.children;d=c.length;for(e=0;e<d;e++){var f=c[e];f.initialized=!0;f.level=b.level+1;f.parent=b;a.ud.remove(f)}for(e=0;e<d;e++)f=c[e],ts(a,f)}}
function zs(a,b,c){if(c.initialized){var d;if(null===b)d=!1;else{for(d=b.parent;null!==d&&d!==c;)d=d.parent;d=d===c}if(d||c.level>b.level)return!1;a.removeChild(c.parent,c)}return!0}Z.prototype.removeChild=function(a,b){if(null!==a&&null!==b){for(var c=a.children,d=0,e=0;e<c.length;e++)c[e]===b&&d++;if(0<d){for(var d=Array(c.length-d),f=0,e=0;e<c.length;e++)c[e]!==b&&(d[f++]=c[e]);a.children=d}}};
function us(a,b){if(null!==b){a.initializeTreeVertexValues(b);b.alignment===As&&a.sortTreeVertexChildren(b);for(var c=0,d=b.Em,e=0,f=b.children,h=f.length,k=0;k<h;k++){var l=f[k];us(a,l);c+=l.descendantCount+1;d=Math.max(d,l.maxChildrenCount);e=Math.max(e,l.maxGenerationCount)}b.descendantCount=c;b.maxChildrenCount=d;b.maxGenerationCount=0<d?e+1:0}}
function Bs(a,b){switch(a.Fs){default:case ms:return null!==b.parent?b.parent:a.ra;case Cs:return null===b.parent?a.ra:null===b.parent.parent?a.ta:b.parent;case Ds:return null!==b.parent?null!==b.parent.parent?b.parent.parent:a.ta:a.ra;case Es:var c=!0;if(0===b.Em)c=!1;else for(var d=b.children,e=d.length,f=0;f<e;f++)if(0<d[f].Em){c=!1;break}return c&&null!==b.parent?a.ta:null!==b.parent?b.parent:a.ra}}
Z.prototype.initializeTreeVertexValues=function(a){var b=Bs(this,a);a.copyInheritedPropertiesFrom(b);if(null!==a.parent&&a.parent.alignment===As){for(var b=a.angle,c=a.parent.children,d=0;d<c.length&&a!==c[d];)d++;0===d%2?d!==c.length-1&&(b=90===b?180:180===b?270:270===b?180:270):b=90===b?0:180===b?90:270===b?0:90;a.angle=b}a.initialized=!0};function vs(a,b){if(null!==b){a.assignTreeVertexValues(b);for(var c=b.children,d=c.length,e=0;e<d;e++)vs(a,c[e])}}Z.prototype.assignTreeVertexValues=function(){};
function ws(a,b){if(null!==b){b.alignment!==As&&a.sortTreeVertexChildren(b);for(var c=b.children,d=c.length,e=0;e<d;e++)ws(a,c[e])}}Z.prototype.sortTreeVertexChildren=function(a){switch(a.sorting){case Fs:break;case Gs:a.children.reverse();break;case Hs:a.children.sort(a.comparer);break;case Is:a.children.sort(a.comparer);a.children.reverse();break;default:v.k("Unhandled sorting value "+a.sorting.toString())}};Z.prototype.Zy=function(){if(this.comments)for(var a=this.network.vertexes.i;a.next();)this.addComments(a.value)};
Z.prototype.addComments=function(a){var b=a.angle,c=a.parent,d=0,e=Js,e=!1;null!==c&&(d=c.angle,e=c.alignment,e=Ks(e));var b=90===b||270===b,d=90===d||270===d,c=0===a.Em,f=0,h=0,k=0,l=a.commentSpacing;if(null!==a.Jc)for(var m=a.Jc.LD();m.next();){var n=m.value;"Comment"===n.gc&&n.canLayout()&&(null===a.comments&&(a.comments=[]),a.comments.push(n),n.sf(),n=n.Ca,b&&!c||!e&&!d&&c||e&&d&&c?(f=Math.max(f,n.width),h+=n.height+Math.abs(k)):(f+=n.width+Math.abs(k),h=Math.max(h,n.height)),k=l)}null!==a.comments&&
(b&&!c||!e&&!d&&c||e&&d&&c?(f+=Math.abs(a.commentMargin),h=Math.max(0,h-a.height)):(h+=Math.abs(a.commentMargin),f=Math.max(0,f-a.width)),e=v.gk(0,0,a.M.width+f,a.M.height+h),a.mb=e,v.Vb(e))};function Ks(a){return a===Ls||a===As||a===Ms||a===Ns}function Os(a){return a===Ls||a===As}function Ps(a){var b=a.parent;if(null!==b){var c=b.alignment;if(Ks(c)){if(Os(c)){b=b.children;for(c=0;c<b.length&&a!==b[c];)c++;return 0===c%2}if(c===Ms)return!0}}return!1}
Z.prototype.layoutComments=function(a){if(null!==a.comments){var b=a.Jc.Ca,c=a.parent,d=a.angle,e=0,f=Js,f=!1;null!==c&&(e=c.angle,f=c.alignment,f=Ks(f));for(var c=90===d||270===d,d=90===e||270===e,h=0===a.Em,k=Ps(a),l=0,m=a.comments,n=m.length,p=v.L(),q=0;q<n;q++){var r=m[q],s=r.Ca;if(c&&!h||!f&&!d&&h||f&&d&&h){if(135<e&&!f||d&&k)if(0<=a.commentMargin)for(p.m(a.M.x-a.commentMargin-s.width,a.M.y+l),r.move(p),r=r.vg();r.next();){var t=r.value;t.xb=qc;t.yb=rc}else for(p.m(a.M.x+2*a.O.x-a.commentMargin,
a.M.y+l),r.move(p),r=r.vg();r.next();)t=r.value,t.xb=rc,t.yb=qc;else if(0<=a.commentMargin)for(p.m(a.M.x+2*a.O.x+a.commentMargin,a.M.y+l),r.move(p),r=r.vg();r.next();)t=r.value,t.xb=rc,t.yb=qc;else for(p.m(a.M.x+a.commentMargin-s.width,a.M.y+l),r.move(p),r=r.vg();r.next();)t=r.value,t.xb=qc,t.yb=rc;l=0<=a.commentSpacing?l+(s.height+a.commentSpacing):l+(a.commentSpacing-s.height)}else{if(135<e&&!f||!d&&k)if(0<=a.commentMargin)for(p.m(a.M.x+l,a.M.y-a.commentMargin-s.height),r.move(p),r=r.vg();r.next();)t=
r.value,t.xb=pc,t.yb=sc;else for(p.m(a.M.x+l,a.M.y+2*a.O.y-a.commentMargin),r.move(p),r=r.vg();r.next();)t=r.value,t.xb=sc,t.yb=pc;else if(0<=a.commentMargin)for(p.m(a.M.x+l,a.M.y+2*a.O.y+a.commentMargin),r.move(p),r=r.vg();r.next();)t=r.value,t.xb=sc,t.yb=pc;else for(p.m(a.M.x+l,a.M.y+a.commentMargin-s.height),r.move(p),r=r.vg();r.next();)t=r.value,t.xb=pc,t.yb=sc;l=0<=a.commentSpacing?l+(s.width+a.commentSpacing):l+(a.commentSpacing-s.width)}}v.v(p);b=l-a.commentSpacing-(c?b.height:b.width);if(this.Df===
ls)for(e=a.ac;e.next();)a=e.value.link,null===a||a.rl||(a.ll=0<b?b:NaN);else for(e=a.lc;e.next();)a=e.value.link,null===a||a.rl||(a.Fl=0<b?b:NaN)}};
Z.prototype.layoutTree=function(a){if(null!==a){for(var b=a.children,c=b.length,d=0;d<c;d++)this.layoutTree(b[d]);switch(a.compaction){case Qs:Rs(this,a);break;case Ss:if(a.alignment===As)Rs(this,a);else if(0===a.Em){var d=a.parent,b=!1,c=0,e=Js;null!==d&&(c=d.angle,e=d.alignment,b=Ks(e));d=Ps(a);a.ma.m(0,0);a.fb.m(a.width,a.height);null===a.parent||null===a.comments||(180!==c&&270!==c||b)&&!d?a.Ma.m(0,0):180===c&&!b||(90===c||270===c)&&d?a.Ma.m(a.width-2*a.O.x,0):a.Ma.m(0,a.height-2*a.O.y);a.At=
null;a.St=null}else{for(var f=Ts(a),b=90===f||270===f,h=0,k=a.children,l=k.length,m=0;m<l;m++)var n=k[m],h=Math.max(h,b?n.fb.width:n.fb.height);var p=a.alignment,d=p===Us,q=p===Vs,r=Ks(p),s=Math.max(0,a.breadthLimit),c=Ws(a),t=a.nodeSpacing,u=Xs(a),y=a.rowSpacing,w=0;if(d||q||a.Bp||a.Cp&&1===a.maxGenerationCount)w=Math.max(0,a.rowIndent);var d=a.width,e=a.height,x=0,A=0,H=0,C=null,T=null,ba=0,U=0,M=0,aa=0,Ia=0,oa=0,Ga=0,pa=0,n=0;r&&!Os(p)&&135<f&&k.reverse();if(Os(p))if(1<l)for(m=0;m<l;m++)0===m%
2&&m!==l-1?pa=Math.max(pa,b?k[m].fb.width:k[m].fb.height):0!==m%2&&(n=Math.max(n,b?k[m].fb.width:k[m].fb.height));else 1===l&&(pa=b?k[0].fb.width:k[0].fb.height);if(r){switch(p){case Ls:U=135>f?Ys(a,k,pa,x,A):Zs(a,k,pa,x,A);pa=U.x;x=U.width;A=U.height;break;case Ms:for(m=0;m<l;m++){var n=k[m],Ca=n.fb,C=0===oa?0:y;b?(n.ma.m(h-Ca.width,aa+C),x=Math.max(x,Ca.width),A=Math.max(A,aa+C+Ca.height),aa+=C+Ca.height):(n.ma.m(M+C,h-Ca.height),x=Math.max(x,M+C+Ca.width),A=Math.max(A,Ca.height),M+=C+Ca.width);
oa++}break;case Ns:for(m=0;m<l;m++)n=k[m],Ca=n.fb,C=0===oa?0:y,b?(n.ma.m(t/2+a.O.x,aa+C),x=Math.max(x,Ca.width),A=Math.max(A,aa+C+Ca.height),aa+=C+Ca.height):(n.ma.m(M+C,t/2+a.O.y),x=Math.max(x,M+C+Ca.width),A=Math.max(A,Ca.height),M+=C+Ca.width),oa++}C=$s(this,2);T=$s(this,2);b?(C[0].m(0,0),C[1].m(0,A),T[0].m(x,0)):(C[0].m(0,0),C[1].m(x,0),T[0].m(0,A));T[1].m(x,A)}else for(m=0;m<l;m++){n=k[m];Ca=n.fb;if(b){0<s&&0<oa&&M+t+Ca.width>s&&(M<h&&at(a,p,h-M,0,Ga,m-1),Ia++,oa=0,Ga=m,H=A,M=0,aa=135<f?-A-y:
A+y);gt(this,n,0,aa);var ua=0;if(0===oa){if(C=n.At,T=n.St,ba=Ca.width,U=Ca.height,null===C||null===T||f!==Ts(n))C=$s(this,2),T=$s(this,2),C[0].m(0,0),C[1].m(0,U),T[0].m(ba,0),T[1].m(ba,U)}else{var gc=v.jb(),U=ht(this,a,n,C,T,ba,U,gc),ua=U.x,C=gc[0],T=gc[1],ba=U.width,U=U.height;v.sa(gc);M<Ca.width&&0>ua&&(it(a,-ua,0,Ga,m-1),jt(C,-ua,0),jt(T,-ua,0),ua=0)}n.ma.m(ua,aa);x=Math.max(x,ba);A=Math.max(A,H+(0===Ia?0:y)+Ca.height);M=ba}else{0<s&&0<oa&&aa+t+Ca.height>s&&(aa<h&&at(a,p,0,h-aa,Ga,m-1),Ia++,oa=
0,Ga=m,H=x,aa=0,M=135<f?-x-y:x+y);gt(this,n,M,0);ua=0;if(0===oa){if(C=n.At,T=n.St,ba=Ca.width,U=Ca.height,null===C||null===T||f!==Ts(n))C=$s(this,2),T=$s(this,2),C[0].m(0,0),C[1].m(ba,0),T[0].m(0,U),T[1].m(ba,U)}else gc=v.jb(),U=ht(this,a,n,C,T,ba,U,gc),ua=U.x,C=gc[0],T=gc[1],ba=U.width,U=U.height,v.sa(gc),aa<Ca.height&&0>ua&&(it(a,0,-ua,Ga,m-1),jt(C,0,-ua),jt(T,0,-ua),ua=0);n.ma.m(M,ua);A=Math.max(A,U);x=Math.max(x,H+(0===Ia?0:y)+Ca.width);aa=U}oa++}0<Ia&&(b?(A+=Math.max(0,c),M<x&&at(a,p,x-M,0,Ga,
l-1),0<w&&(q||it(a,w,0,0,l-1),x+=w)):(x+=Math.max(0,c),aa<A&&at(a,p,0,A-aa,Ga,l-1),0<w&&(q||it(a,0,w,0,l-1),A+=w)));q=h=0;switch(p){case kt:b?h+=x/2-a.O.x-u/2:q+=A/2-a.O.y-u/2;break;case Js:0<Ia?b?h+=x/2-a.O.x-u/2:q+=A/2-a.O.y-u/2:b?(m=k[0].ma.x+k[0].Ma.x,pa=k[l-1].ma.x+k[l-1].Ma.x+2*k[l-1].O.x,h+=m+(pa-m)/2-a.O.x-u/2):(m=k[0].ma.y+k[0].Ma.y,pa=k[l-1].ma.y+k[l-1].Ma.y+2*k[l-1].O.y,q+=m+(pa-m)/2-a.O.y-u/2);break;case Us:b?(h-=u,x+=u):(q-=u,A+=u);break;case Vs:b?(h+=x-a.width+u,x+=u):(q+=A-a.height+
u,A+=u);break;case Ls:b?h=1<l?h+(pa+t/2-a.O.x):h+(k[0].O.x-a.O.x+k[0].Ma.x):q=1<l?q+(pa+t/2-a.O.y):q+(k[0].O.y-a.O.y+k[0].Ma.y);break;case Ms:b?h+=x+t/2-a.O.x:q+=A+t/2-a.O.y;break;case Ns:break;default:v.k("Unhandled alignment value "+p.toString())}for(m=0;m<l;m++)n=k[m],b?n.ma.m(n.ma.x+n.Ma.x-h,n.ma.y+(135<f?(r?-A:-n.fb.height)+n.Ma.y-c:e+c+n.Ma.y)):n.ma.m(n.ma.x+(135<f?(r?-x:-n.fb.width)+n.Ma.x-c:d+c+n.Ma.x),n.ma.y+n.Ma.y-q);l=k=0;r?b?(x=lt(a,x,h),0>h&&(h=0),135<f&&(q+=A+c),A+=e+c,p===Ns&&(k+=t/
2+a.O.x),l+=e+c):(135<f&&(h+=x+c),x+=d+c,A=mt(a,A,q),0>q&&(q=0),p===Ns&&(l+=t/2+a.O.y),k+=d+c):b?(null===a.comments?d>x&&(p=nt(p,d-x,0),k=p.x,l=p.y,x=d,h=0):x=lt(a,x,h),0>h&&(k-=h,h=0),135<f&&(q+=A+c),A=Math.max(Math.max(A,e),A+e+c),l+=e+c):(135<f&&(h+=x+c),x=Math.max(Math.max(x,d),x+d+c),null===a.comments?e>A&&(p=nt(p,0,e-A),k=p.x,l=p.y,A=e,q=0):A=mt(a,A,q),0>q&&(l-=q,q=0),k+=d+c);if(0<Ia)f=$s(this,4),p=$s(this,4),b?(f[2].m(0,e+c),f[3].m(f[2].x,A),p[2].m(x,f[2].y),p[3].m(p[2].x,f[3].y)):(f[2].m(d+
c,0),f[3].m(x,f[2].y),p[2].m(f[2].x,A),p[3].m(f[3].x,p[2].y));else{f=$s(this,C.length+2);p=$s(this,T.length+2);for(m=0;m<C.length;m++)r=C[m],f[m+2].m(r.x+k,r.y+l);for(m=0;m<T.length;m++)r=T[m],p[m+2].m(r.x+k,r.y+l)}b?(f[0].m(h,0),f[1].m(f[0].x,e),f[2].y<f[1].y&&(f[2].x>f[0].x?f[2].assign(f[1]):f[1].assign(f[2])),f[3].y<f[2].y&&(f[3].x>f[0].x?f[3].assign(f[2]):f[2].assign(f[3])),p[0].m(h+d,0),p[1].m(p[0].x,e),p[2].y<p[1].y&&(p[2].x<p[0].x?p[2].assign(p[1]):p[1].assign(p[2])),p[3].y<p[2].y&&(p[3].x<
p[0].x?p[3].assign(p[2]):p[2].assign(p[3])),f[2].y-=c/2,p[2].y-=c/2):(f[0].m(0,q),f[1].m(d,f[0].y),f[2].x<f[1].x&&(f[2].y>f[0].y?f[2].assign(f[1]):f[1].assign(f[2])),f[3].x<f[2].x&&(f[3].y>f[0].y?f[3].assign(f[2]):f[2].assign(f[3])),p[0].m(0,q+e),p[1].m(d,p[0].y),p[2].x<p[1].x&&(p[2].y<p[0].y?p[2].assign(p[1]):p[1].assign(p[2])),p[3].x<p[2].x&&(p[3].y<p[0].y?p[3].assign(p[2]):p[2].assign(p[3])),f[2].x-=c/2,p[2].x-=c/2);ot(this,C);ot(this,T);a.At=f;a.St=p;a.Ma.m(h,q);a.fb.m(x,A)}break;default:v.k("Unhandled compaction value "+
a.compaction.toString())}}};
function Rs(a,b){if(0===b.Em){var c=!1,d=0,e=Js;null!==b.parent&&(d=b.parent.angle,e=b.parent.alignment,c=Ks(e));e=Ps(b);b.ma.m(0,0);b.fb.m(b.width,b.height);null===b.parent||null===b.comments||(180!==d&&270!==d||c)&&!e?b.Ma.m(0,0):180===d&&!c||(90===d||270===d)&&e?b.Ma.m(b.width-2*b.O.x,0):b.Ma.m(0,b.height-2*b.O.y)}else{for(var c=Ts(b),d=90===c||270===c,f=0,e=b.children,h=e.length,k=0;k<h;k++)var l=e[k],f=Math.max(f,d?l.fb.width:l.fb.height);var m=b.alignment,n=m===Us,p=m===Vs,q=Ks(m),r=Math.max(0,
b.breadthLimit),s=Ws(b),t=b.nodeSpacing,u=Xs(b),y=n||p?0:u/2,w=b.rowSpacing,x=0;if(n||p||b.Bp||b.Cp&&1===b.maxGenerationCount)x=Math.max(0,b.rowIndent);var n=b.width,A=b.height,H=0,C=0,T=0,ba=0,U=0,M=0,aa=0,Ia=0,oa=0,Ga=0;q&&!Os(m)&&135<c&&e.reverse();if(Os(m))if(1<h)for(k=0;k<h;k++){var l=e[k],pa=l.fb;0===k%2&&k!==h-1?oa=Math.max(oa,(d?pa.width:pa.height)+pt(l)-t):0!==k%2&&(Ga=Math.max(Ga,(d?pa.width:pa.height)+pt(l)-t))}else 1===h&&(oa=d?e[0].fb.width:e[0].fb.height);if(q)switch(m){case Ls:case As:C=
135>c?Ys(b,e,oa,H,C):Zs(b,e,oa,H,C);oa=C.x;H=C.width;C=C.height;break;case Ms:for(k=0;k<h;k++)l=e[k],pa=l.fb,r=0===aa?0:w,d?(l.ma.m(f-pa.width,U+r),H=Math.max(H,pa.width),C=Math.max(C,U+r+pa.height),U+=r+pa.height):(l.ma.m(ba+r,f-pa.height),H=Math.max(H,ba+r+pa.width),C=Math.max(C,pa.height),ba+=r+pa.width),aa++;break;case Ns:for(f=0;f<h;f++)l=e[f],pa=l.fb,r=0===aa?0:w,d?(l.ma.m(t/2+b.O.x,U+r),H=Math.max(H,pa.width),C=Math.max(C,U+r+pa.height),U+=r+pa.height):(l.ma.m(ba+r,t/2+b.O.y),H=Math.max(H,
ba+r+pa.width),C=Math.max(C,pa.height),ba+=r+pa.width),aa++}else for(k=0;k<h;k++)l=e[k],pa=l.fb,d?(0<r&&0<aa&&ba+t+pa.width>r&&(ba<f&&at(b,m,f-ba,0,Ia,k-1),M++,aa=0,Ia=k,T=C,ba=0,U=135<c?-C-w:C+w),Ga=0===aa?y:t,gt(a,l,0,U),l.ma.m(ba+Ga,U),H=Math.max(H,ba+Ga+pa.width),C=Math.max(C,T+(0===M?0:w)+pa.height),ba+=Ga+pa.width):(0<r&&0<aa&&U+t+pa.height>r&&(U<f&&at(b,m,0,f-U,Ia,k-1),M++,aa=0,Ia=k,T=H,U=0,ba=135<c?-H-w:H+w),Ga=0===aa?y:t,gt(a,l,ba,0),l.ma.m(ba,U+Ga),C=Math.max(C,U+Ga+pa.height),H=Math.max(H,
T+(0===M?0:w)+pa.width),U+=Ga+pa.height),aa++;0<M&&(d?(C+=Math.max(0,s),ba<H&&at(b,m,H-ba,0,Ia,h-1),0<x&&(p||it(b,x,0,0,h-1),H+=x)):(H+=Math.max(0,s),U<C&&at(b,m,0,C-U,Ia,h-1),0<x&&(p||it(b,0,x,0,h-1),C+=x)));x=p=0;switch(m){case kt:d?p+=H/2-b.O.x-u/2:x+=C/2-b.O.y-u/2;break;case Js:0<M?d?p+=H/2-b.O.x-u/2:x+=C/2-b.O.y-u/2:d?(m=e[0].ma.x+e[0].Ma.x,t=e[h-1].ma.x+e[h-1].Ma.x+2*e[h-1].O.x,p+=m+(t-m)/2-b.O.x-u/2):(m=e[0].ma.y+e[0].Ma.y,t=e[h-1].ma.y+e[h-1].Ma.y+2*e[h-1].O.y,x+=m+(t-m)/2-b.O.y-u/2);break;
case Us:d?(p-=u,H+=u):(x-=u,C+=u);break;case Vs:d?(p+=H-b.width+u,H+=u):(x+=C-b.height+u,C+=u);break;case Ls:case As:d?p=1<h?p+(oa+t/2-b.O.x):p+(e[0].O.x-b.O.x+e[0].Ma.x):x=1<h?x+(oa+t/2-b.O.y):x+(e[0].O.y-b.O.y+e[0].Ma.y);break;case Ms:d?p+=H+t/2-b.O.x:x+=C+t/2-b.O.y;break;case Ns:break;default:v.k("Unhandled alignment value "+m.toString())}for(k=0;k<h;k++)l=e[k],d?l.ma.m(l.ma.x+l.Ma.x-p,l.ma.y+(135<c?(q?-C:-l.fb.height)+l.Ma.y-s:A+s+l.Ma.y)):l.ma.m(l.ma.x+(135<c?(q?-H:-l.fb.width)+l.Ma.x-s:n+s+
l.Ma.x),l.ma.y+l.Ma.y-x);d?(H=lt(b,H,p),0>p&&(p=0),135<c&&(x+=C+s),C+=A+s):(135<c&&(p+=H+s),H+=n+s,C=mt(b,C,x),0>x&&(x=0));b.Ma.m(p,x);b.fb.m(H,C)}}
function Ys(a,b,c,d,e){var f=b.length;if(0===f)return new B(c,0,d,e);if(1===f){var h=b[0];d=h.fb.width;e=h.fb.height;return new B(c,0,d,e)}for(var k=a.nodeSpacing,l=a.rowSpacing,m=90===Ts(a),n=0,p=0,q=0,r=0;r<f;r++)if(!(0!==r%2||1<f&&r===f-1)){var h=b[r],s=h.fb,t=0===n?0:l;if(m){var u=pt(h)-k;h.ma.m(c-(s.width+u),q+t);d=Math.max(d,s.width+u);e=Math.max(e,q+t+s.height);q+=t+s.height}else u=pt(h)-k,h.ma.m(p+t,c-(s.height+u)),e=Math.max(e,s.height+u),d=Math.max(d,p+t+s.width),p+=t+s.width;n++}var n=
0,y=p,w=q;m?(p=c+k,q=0):(p=0,q=c+k);for(r=0;r<f;r++)0!==r%2&&(h=b[r],s=h.fb,t=0===n?0:l,m?(u=pt(h)-k,h.ma.m(p+u,q+t),d=Math.max(d,p+s.width+u),e=Math.max(e,q+t+s.height),q+=t+s.height):(u=pt(h)-k,h.ma.m(p+t,q+u),d=Math.max(d,p+t+s.width),e=Math.max(e,q+s.height+u),p+=t+s.width),n++);1<f&&1===f%2&&(h=b[f-1],s=h.fb,b=qt(h,m?Math.max(Math.abs(w),Math.abs(q)):Math.max(Math.abs(y),Math.abs(p))),m?(h.ma.m(c+k/2-h.O.x-h.Ma.x,e+b),m=c+k/2-h.O.x-h.Ma.x,d=Math.max(d,m+s.width),0>m&&(d-=m),e=Math.max(e,Math.max(w,
q)+b+s.height),0>h.ma.x&&(c=rt(a,h.ma.x,!1,c,k))):(h.ma.m(d+b,c+k/2-h.O.y-h.Ma.y),d=Math.max(d,Math.max(y,p)+b+s.width),m=c+k/2-h.O.y-h.Ma.y,e=Math.max(e,m+s.height),0>m&&(e-=m),0>h.ma.y&&(c=rt(a,h.ma.y,!0,c,k))));return new B(c,0,d,e)}
function Zs(a,b,c,d,e){var f=b.length;if(0===f)return new B(c,0,d,e);if(1===f){var h=b[0];d=h.fb.width;e=h.fb.height;return new B(c,0,d,e)}for(var k=a.nodeSpacing,l=a.rowSpacing,m=270===Ts(a),n=0,p=0,q=0,r=0;r<f;r++)if(!(0!==r%2||1<f&&r===f-1)){var h=b[r],s=h.fb,t=0===n?0:l;if(m){var u=pt(h)-k,q=q-(t+s.height);h.ma.m(c-(s.width+u),q);d=Math.max(d,s.width+u);e=Math.max(e,Math.abs(q))}else u=pt(h)-k,p-=t+s.width,h.ma.m(p,c-(s.height+u)),e=Math.max(e,s.height+u),d=Math.max(d,Math.abs(p));n++}var n=0,
y=p,w=q;m?(p=c+k,q=0):(p=0,q=c+k);for(r=0;r<f;r++)0!==r%2&&(h=b[r],s=h.fb,t=0===n?0:l,m?(u=pt(h)-k,q-=t+s.height,h.ma.m(p+u,q),d=Math.max(d,p+s.width+u),e=Math.max(e,Math.abs(q))):(u=pt(h)-k,p-=t+s.width,h.ma.m(p,q+u),e=Math.max(e,q+s.height+u),d=Math.max(d,Math.abs(p))),n++);1<f&&1===f%2&&(h=b[f-1],s=h.fb,l=qt(h,m?Math.max(Math.abs(w),Math.abs(q)):Math.max(Math.abs(y),Math.abs(p))),m?(h.ma.m(c+k/2-h.O.x-h.Ma.x,-e-s.height-l),p=c+k/2-h.O.x-h.Ma.x,d=Math.max(d,p+s.width),0>p&&(d-=p),e=Math.max(e,Math.abs(Math.min(w,
q))+l+s.height),0>h.ma.x&&(c=rt(a,h.ma.x,!1,c,k))):(h.ma.m(-d-s.width-l,c+k/2-h.O.y-h.Ma.y),d=Math.max(d,Math.abs(Math.min(y,p))+l+s.width),p=c+k/2-h.O.y-h.Ma.y,e=Math.max(e,p+s.height),0>p&&(e-=p),0>h.ma.y&&(c=rt(a,h.ma.y,!0,c,k))));for(r=0;r<f;r++)h=b[r],m?h.ma.m(h.ma.x,h.ma.y+e):h.ma.m(h.ma.x+d,h.ma.y);return new B(c,0,d,e)}function pt(a){return null===a.parent?0:a.parent.nodeSpacing}function qt(a){return null===a.parent?0:a.parent.rowSpacing}
function rt(a,b,c,d,e){a=a.children;for(var f=a.length,h=0;h<f;h++)c?a[h].ma.m(a[h].ma.x,a[h].ma.y-b):a[h].ma.m(a[h].ma.x-b,a[h].ma.y);b=a[f-1];return Math.max(d,c?b.Ma.y+b.O.y-e/2:b.Ma.x+b.O.x-e/2)}
function lt(a,b,c){switch(a.alignment){case Js:case kt:var d=b;c+a.width>d&&(d=c+a.width);0>c&&(d-=c);return d;case Us:return a.width>b?a.width:b;case Vs:return 2*a.O.x>b?a.width:b+a.width-2*a.O.x;case Ls:case As:return d=Math.min(0,c),c=Math.max(b,c+a.width),Math.max(a.width,c-d);case Ms:return a.width-a.O.x+a.nodeSpacing/2+b;case Ns:return Math.max(a.width,a.O.x+a.nodeSpacing/2+b);default:return b}}
function mt(a,b,c){switch(a.alignment){case Js:case kt:var d=b;c+a.height>d&&(d=c+a.height);0>c&&(d-=c);return d;case Us:return a.height>b?a.height:b;case Vs:return 2*a.O.y>b?a.height:b+a.height-2*a.O.y;case Ls:case As:return d=Math.min(0,c),c=Math.max(b,c+a.height),Math.max(a.height,c-d);case Ms:return a.height-a.O.y+a.nodeSpacing/2+b;case Ns:return Math.max(a.height,a.O.y+a.nodeSpacing/2+b);default:return b}}
function nt(a,b,c){switch(a){case kt:b/=2;c/=2;break;case Js:b/=2;c/=2;break;case Us:c=b=0;break;case Vs:break;default:v.k("Unhandled alignment value "+a.toString())}return new z(b,c)}function at(a,b,c,d,e,f){b=nt(b,c,d);it(a,b.x,b.y,e,f)}function it(a,b,c,d,e){if(0!==b||0!==c)for(a=a.children;d<=e;d++){var f=a[d].ma;f.x+=b;f.y+=c}}
function gt(a,b,c,d){var e=b.parent;switch(a.Df){case ls:for(a=b.lc;a.next();)b=a.value,b.fromVertex===e&&b.Rr.m(c,d);break;case ss:for(a=b.ac;a.next();)b=a.value,b.toVertex===e&&b.Rr.m(c,d);break;default:v.k("Unhandled path value "+a.Df.toString())}}function jt(a,b,c){for(var d=0;d<a.length;d++){var e=a[d];e.x+=b;e.y+=c}}
function ht(a,b,c,d,e,f,h,k){var l=Ts(b),m=90===l||270===l,n=b.nodeSpacing;b=d;var p=e;d=f;e=h;var q=c.At,r=c.St;h=c.fb;var s=m?Math.max(e,h.height):Math.max(d,h.width);if(null===q||l!==Ts(c))q=$s(a,2),r=$s(a,2),m?(q[0].m(0,0),q[1].m(0,h.height),r[0].m(h.width,0),r[1].m(r[0].x,q[1].y)):(q[0].m(0,0),q[1].m(h.width,0),r[0].m(0,h.height),r[1].m(q[1].x,r[0].y));if(m){c=d;d=9999999;if(!(null===p||2>p.length||null===q||2>q.length))for(m=l=0;l<p.length&&m<q.length;){e=p[l];var t=q[m];f=t.x;var u=t.y;f+=
c;var y=e;l+1<p.length&&(y=p[l+1]);var w=t,t=w.x,w=w.y;m+1<q.length&&(w=q[m+1],t=w.x,w=w.y,t+=c);var x=d;e.y===u?x=f-e.x:e.y>u&&e.y<w?x=f+(e.y-u)/(w-u)*(t-f)-e.x:u>e.y&&u<y.y&&(x=f-(e.x+(u-e.y)/(y.y-e.y)*(y.x-e.x)));x<d&&(d=x);y.y<=e.y?l++:w<=u?m++:(y.y<=w&&l++,w<=y.y&&m++)}c-=d;c+=n;l=q;m=c;if(null===b||2>b.length||null===l||2>l.length)d=null;else{n=$s(a,b.length+l.length);for(d=f=e=0;f<l.length&&l[f].y<b[0].y;)u=l[f++],n[d++].m(u.x+m,u.y);for(;e<b.length;)u=b[e++],n[d++].m(u.x,u.y);for(e=b[b.length-
1].y;f<l.length&&l[f].y<=e;)f++;for(;f<l.length&&l[f].y>e;)u=l[f++],n[d++].m(u.x+m,u.y);l=$s(a,d);for(e=0;e<d;e++)l[e].assign(n[e]);ot(a,n);d=l}f=r;u=c;if(null===p||2>p.length||null===f||2>f.length)e=null;else{n=$s(a,p.length+f.length);for(m=y=l=0;l<p.length&&p[l].y<f[0].y;)e=p[l++],n[m++].m(e.x,e.y);for(;y<f.length;)e=f[y++],n[m++].m(e.x+u,e.y);for(f=f[f.length-1].y;l<p.length&&p[l].y<=f;)l++;for(;l<p.length&&p[l].y>f;)e=p[l++],n[m++].m(e.x,e.y);e=$s(a,m);for(l=0;l<m;l++)e[l].assign(n[l]);ot(a,n)}f=
Math.max(0,c)+h.width;h=s}else{c=e;d=9999999;if(!(null===p||2>p.length||null===q||2>q.length))for(m=l=0;l<p.length&&m<q.length;)e=p[l],t=q[m],f=t.x,u=t.y,u+=c,y=e,l+1<p.length&&(y=p[l+1]),w=t,t=w.x,w=w.y,m+1<q.length&&(w=q[m+1],t=w.x,w=w.y,w+=c),x=d,e.x===f?x=u-e.y:e.x>f&&e.x<t?x=u+(e.x-f)/(t-f)*(w-u)-e.y:f>e.x&&f<y.x&&(x=u-(e.y+(f-e.x)/(y.x-e.x)*(y.y-e.y))),x<d&&(d=x),y.x<=e.x?l++:t<=f?m++:(y.x<=t&&l++,t<=y.x&&m++);c-=d;c+=n;l=q;m=c;if(null===b||2>b.length||null===l||2>l.length)d=null;else{n=$s(a,
b.length+l.length);for(d=f=e=0;f<l.length&&l[f].x<b[0].x;)u=l[f++],n[d++].m(u.x,u.y+m);for(;e<b.length;)u=b[e++],n[d++].m(u.x,u.y);for(e=b[b.length-1].x;f<l.length&&l[f].x<=e;)f++;for(;f<l.length&&l[f].x>e;)u=l[f++],n[d++].m(u.x,u.y+m);l=$s(a,d);for(e=0;e<d;e++)l[e].assign(n[e]);ot(a,n);d=l}f=r;u=c;if(null===p||2>p.length||null===f||2>f.length)e=null;else{n=$s(a,p.length+f.length);for(m=y=l=0;l<p.length&&p[l].x<f[0].x;)e=p[l++],n[m++].m(e.x,e.y);for(;y<f.length;)e=f[y++],n[m++].m(e.x,e.y+u);for(f=
f[f.length-1].x;l<p.length&&p[l].x<=f;)l++;for(;l<p.length&&p[l].x>f;)e=p[l++],n[m++].m(e.x,e.y);e=$s(a,m);for(l=0;l<m;l++)e[l].assign(n[l]);ot(a,n)}f=s;h=Math.max(0,c)+h.height}ot(a,b);ot(a,q);ot(a,p);ot(a,r);k[0]=d;k[1]=e;return new B(c,0,f,h)}function $s(a,b){var c=a.My[b];if(void 0!==c&&(c=c.pop(),void 0!==c))return c;for(var c=[],d=0;d<b;d++)c[d]=new z;return c}function ot(a,b){var c=b.length,d=a.My[c];void 0===d&&(d=[],a.My[c]=d);d.push(b)}
Z.prototype.arrangeTrees=function(){if(this.fd===rs)for(var a=this.ud.i;a.next();){var b=a.value;if(b instanceof ps){var c=b.Jc;if(null!==c){var d=c.position,c=d.x,d=d.y;isFinite(c)||(c=0);isFinite(d)||(d=0);st(this,b,c,d)}}}else{c=[];for(a=this.ud.i;a.next();)b=a.value,b instanceof ps&&c.push(b);switch(this.sorting){case Fs:break;case Gs:c.reverse();break;case Hs:c.sort(this.comparer);break;case Is:c.sort(this.comparer);c.reverse();break;default:v.k("Unhandled sorting value "+this.sorting.toString())}b=
this.Xd;a=b.x;b=b.y;for(d=0;d<c.length;d++){var e=c[d];st(this,e,a+e.Ma.x,b+e.Ma.y);switch(this.fd){case os:b+=e.fb.height+this.bg.height;break;case tt:a+=e.fb.width+this.bg.width;break;default:v.k("Unhandled arrangement value "+this.fd.toString())}}}};function st(a,b,c,d){if(null!==b){b.x=c;b.y=d;b=b.children;for(var e=b.length,f=0;f<e;f++){var h=b[f];st(a,h,c+h.ma.x,d+h.ma.y)}}}Z.prototype.commitLayout=function(){this.oA();this.commitNodes();this.dz();this.xt&&this.commitLinks()};
Z.prototype.commitNodes=function(){for(var a=this.network.vertexes.i;a.next();)a.value.commit();for(a.reset();a.next();)this.layoutComments(a.value)};
Z.prototype.dz=function(){if(this.Iz===xs){for(var a=this.PA,b=[],c=null,d=this.network.vertexes.i;d.next();){var e=d.value;null===c?c=e.mb.copy():c.ai(e.mb);var f=b[e.level],f=void 0===f?Ws(e):Math.max(f,Ws(e));b[e.level]=f}for(d=0;d<b.length;d++)void 0===b[d]&&(b[d]=0);90===this.angle||270===this.angle?(c.Qf(this.nodeSpacing/2,this.layerSpacing),e=new z(-this.nodeSpacing/2,-this.layerSpacing/2)):(c.Qf(this.layerSpacing,this.nodeSpacing/2),e=new z(-this.layerSpacing/2,-this.nodeSpacing/2));var f=
[],c=90===this.angle||270===this.angle?c.width:c.height,h=0;if(180===this.angle||270===this.angle)for(d=0;d<a.length;d++)h+=a[d]+b[d];for(d=0;d<a.length;d++){var k=a[d]+b[d];270===this.angle?(h-=k,f.push(new B(0,h,c,k))):90===this.angle?(f.push(new B(0,h,c,k)),h+=k):180===this.angle?(h-=k,f.push(new B(h,0,k,c))):(f.push(new B(h,0,k,c)),h+=k)}this.commitLayers(f,e)}};Z.prototype.commitLayers=function(){};Z.prototype.commitLinks=function(){for(var a=this.network.edges.i;a.next();)a.value.commit()};
Z.prototype.oA=function(){for(var a=this.ud.i;a.next();){var b=a.value;b instanceof ps&&ut(this,b)}};function ut(a,b){if(null!==b){a.setPortSpots(b);for(var c=b.children,d=c.length,e=0;e<d;e++)ut(a,c[e])}}
Z.prototype.setPortSpots=function(a){var b=a.alignment;if(Ks(b)){var c=this.Df===ls,d=Ts(a),e;switch(d){case 0:e=rc;break;case 90:e=sc;break;case 180:e=qc;break;default:e=pc}var f=a.children,h=f.length;switch(b){case Ls:case As:for(b=0;b<h;b++){var k=f[b],k=(c?k.lc:k.ac).first();if(null!==k&&(k=k.link,null!==k)){var l=90===d||270===d?qc:pc;if(1===h||b===h-1&&1===h%2)switch(d){case 0:l=qc;break;case 90:l=pc;break;case 180:l=rc;break;default:l=sc}else 0===b%2&&(l=90===d||270===d?rc:sc);c?(a.setsPortSpot&&
(k.xb=e),a.setsChildPortSpot&&(k.yb=l)):(a.setsPortSpot&&(k.xb=l),a.setsChildPortSpot&&(k.yb=e))}}break;case Ms:l=90===d||270===d?rc:sc;for(d=c?a.ac:a.lc;d.next();)k=d.value.link,null!==k&&(c?(a.setsPortSpot&&(k.xb=e),a.setsChildPortSpot&&(k.yb=l)):(a.setsPortSpot&&(k.xb=l),a.setsChildPortSpot&&(k.yb=e)));break;case Ns:for(l=90===d||270===d?qc:pc,d=c?a.ac:a.lc;d.next();)k=d.value.link,null!==k&&(c?(a.setsPortSpot&&(k.xb=e),a.setsChildPortSpot&&(k.yb=l)):(a.setsPortSpot&&(k.xb=l),a.setsChildPortSpot&&
(k.yb=e)))}}else if(c=Ts(a),this.Df===ls)for(e=a.ac;e.next();){if(d=e.value.link,null!==d){if(a.setsPortSpot)if(a.portSpot.Uc())switch(c){case 0:d.xb=rc;break;case 90:d.xb=sc;break;case 180:d.xb=qc;break;default:d.xb=pc}else d.xb=a.portSpot;if(a.setsChildPortSpot)if(a.childPortSpot.Uc())switch(c){case 0:d.yb=qc;break;case 90:d.yb=pc;break;case 180:d.yb=rc;break;default:d.yb=sc}else d.yb=a.childPortSpot}}else for(e=a.lc;e.next();)if(d=e.value.link,null!==d){if(a.setsPortSpot)if(a.portSpot.Uc())switch(c){case 0:d.yb=
rc;break;case 90:d.yb=sc;break;case 180:d.yb=qc;break;default:d.yb=pc}else d.yb=a.portSpot;if(a.setsChildPortSpot)if(a.childPortSpot.Uc())switch(c){case 0:d.xb=qc;break;case 90:d.xb=pc;break;case 180:d.xb=rc;break;default:d.xb=sc}else d.xb=a.childPortSpot}};function Ts(a){a=a.angle;return 45>=a?0:135>=a?90:225>=a?180:315>=a?270:0}
function Ws(a){var b=Ts(a),b=90===b||270===b,c=a.layerSpacing;if(0<a.layerSpacingParentOverlap)var d=Math.min(1,a.layerSpacingParentOverlap),c=c-(b?a.height*d:a.width*d);c<(b?-a.height:-a.width)&&(c=b?-a.height:-a.width);return c}function Xs(a){var b=Ts(a),b=90===b||270===b,c=a.nodeIndent;if(0<a.nodeIndentPastParent)var d=Math.min(1,a.nodeIndentPastParent),c=c+(b?a.width*d:a.height*d);return c=Math.max(0,c)}
v.defineProperty(Z,{CK:"roots"},function(){return this.ud},function(a){this.ud!==a&&(v.A(a,J,Z,"roots"),this.ud=a,this.H())});v.defineProperty(Z,{path:"path"},function(){return this.Kr},function(a){this.Kr!==a&&(v.pb(a,Z,Z,"path"),this.Kr=a,this.H())});v.defineProperty(Z,{QI:"treeStyle"},function(){return this.Fs},function(a){this.fd!==a&&(v.pb(a,Z,Z,"treeStyle"),a===ms||a===Ds||a===Es||a===Cs)&&(this.Fs=a,this.H())});
v.defineProperty(Z,{Iz:"layerStyle"},function(){return this.mv},function(a){this.fd!==a&&(v.pb(a,Z,Z,"layerStyle"),a===ns||a===ys||a===xs)&&(this.mv=a,this.H())});v.defineProperty(Z,{comments:"comments"},function(){return this.th},function(a){this.th!==a&&(v.j(a,"boolean",Z,"comments"),this.th=a,this.H())});v.defineProperty(Z,{Nf:"arrangement"},function(){return this.fd},function(a){this.fd!==a&&(v.pb(a,Z,Z,"arrangement"),a===os||a===tt||a===rs)&&(this.fd=a,this.H())});
v.defineProperty(Z,{jD:"arrangementSpacing"},function(){return this.bg},function(a){v.A(a,ia,Z,"arrangementSpacing");this.bg.K(a)||(this.bg.assign(a),this.H())});v.defineProperty(Z,{BK:"rootDefaults"},function(){return this.ra},function(a){this.ra!==a&&(v.A(a,ps,Z,"rootDefaults"),this.ra=a,this.H())});v.defineProperty(Z,{gJ:"alternateDefaults"},function(){return this.ta},function(a){this.ta!==a&&(v.A(a,ps,Z,"alternateDefaults"),this.ta=a,this.H())});
v.defineProperty(Z,{sorting:"sorting"},function(){return this.ra.sorting},function(a){this.ra.sorting!==a&&(v.pb(a,Z,Z,"sorting"),a===Fs||a===Gs||a===Hs||Is)&&(this.ra.sorting=a,this.H())});v.defineProperty(Z,{comparer:"comparer"},function(){return this.ra.comparer},function(a){this.ra.comparer!==a&&(v.j(a,"function",Z,"comparer"),this.ra.comparer=a,this.H())});
v.defineProperty(Z,{angle:"angle"},function(){return this.ra.angle},function(a){this.ra.angle!==a&&(v.j(a,"number",Z,"angle"),0===a||90===a||180===a||270===a?(this.ra.angle=a,this.H()):v.k("TreeLayout.angle must be 0, 90, 180, or 270"))});v.defineProperty(Z,{alignment:"alignment"},function(){return this.ra.alignment},function(a){this.ra.alignment!==a&&(v.pb(a,Z,Z,"alignment"),this.ra.alignment=a,this.H())});
v.defineProperty(Z,{nodeIndent:"nodeIndent"},function(){return this.ra.nodeIndent},function(a){this.ra.nodeIndent!==a&&(v.j(a,"number",Z,"nodeIndent"),0<=a&&(this.ra.nodeIndent=a,this.H()))});v.defineProperty(Z,{nodeIndentPastParent:"nodeIndentPastParent"},function(){return this.ra.nodeIndentPastParent},function(a){this.ra.nodeIndentPastParent!==a&&(v.j(a,"number",Z,"nodeIndentPastParent"),0<=a&&1>=a&&(this.ra.nodeIndentPastParent=a,this.H()))});
v.defineProperty(Z,{nodeSpacing:"nodeSpacing"},function(){return this.ra.nodeSpacing},function(a){this.ra.nodeSpacing!==a&&(v.j(a,"number",Z,"nodeSpacing"),this.ra.nodeSpacing=a,this.H())});v.defineProperty(Z,{layerSpacing:"layerSpacing"},function(){return this.ra.layerSpacing},function(a){this.ra.layerSpacing!==a&&(v.j(a,"number",Z,"layerSpacing"),this.ra.layerSpacing=a,this.H())});
v.defineProperty(Z,{layerSpacingParentOverlap:"layerSpacingParentOverlap"},function(){return this.ra.layerSpacingParentOverlap},function(a){this.ra.layerSpacingParentOverlap!==a&&(v.j(a,"number",Z,"layerSpacingParentOverlap"),0<=a&&1>=a&&(this.ra.layerSpacingParentOverlap=a,this.H()))});v.defineProperty(Z,{compaction:"compaction"},function(){return this.ra.compaction},function(a){this.ra.compaction!==a&&(v.pb(a,Z,Z,"compaction"),a===Qs||a===Ss)&&(this.ra.compaction=a,this.H())});
v.defineProperty(Z,{breadthLimit:"breadthLimit"},function(){return this.ra.breadthLimit},function(a){this.ra.breadthLimit!==a&&(v.j(a,"number",Z,"breadthLimit"),0<=a&&(this.ra.breadthLimit=a,this.H()))});v.defineProperty(Z,{rowSpacing:"rowSpacing"},function(){return this.ra.rowSpacing},function(a){this.ra.rowSpacing!==a&&(v.j(a,"number",Z,"rowSpacing"),this.ra.rowSpacing=a,this.H())});
v.defineProperty(Z,{rowIndent:"rowIndent"},function(){return this.ra.rowIndent},function(a){this.ra.rowIndent!==a&&(v.j(a,"number",Z,"rowIndent"),0<=a&&(this.ra.rowIndent=a,this.H()))});v.defineProperty(Z,{commentSpacing:"commentSpacing"},function(){return this.ra.commentSpacing},function(a){this.ra.commentSpacing!==a&&(v.j(a,"number",Z,"commentSpacing"),this.ra.commentSpacing=a,this.H())});
v.defineProperty(Z,{commentMargin:"commentMargin"},function(){return this.ra.commentMargin},function(a){this.ra.commentMargin!==a&&(v.j(a,"number",Z,"commentMargin"),this.ra.commentMargin=a,this.H())});v.defineProperty(Z,{setsPortSpot:"setsPortSpot"},function(){return this.ra.setsPortSpot},function(a){this.ra.setsPortSpot!==a&&(v.j(a,"boolean",Z,"setsPortSpot"),this.ra.setsPortSpot=a,this.H())});
v.defineProperty(Z,{portSpot:"portSpot"},function(){return this.ra.portSpot},function(a){v.A(a,L,Z,"portSpot");this.ra.portSpot.K(a)||(this.ra.portSpot=a,this.H())});v.defineProperty(Z,{setsChildPortSpot:"setsChildPortSpot"},function(){return this.ra.setsChildPortSpot},function(a){this.ra.setsChildPortSpot!==a&&(v.j(a,"boolean",Z,"setsChildPortSpot"),this.ra.setsChildPortSpot=a,this.H())});
v.defineProperty(Z,{childPortSpot:"childPortSpot"},function(){return this.ra.childPortSpot},function(a){v.A(a,L,Z,"childPortSpot");this.ra.childPortSpot.K(a)||(this.ra.childPortSpot=a,this.H())});v.defineProperty(Z,{rJ:"alternateSorting"},function(){return this.ta.sorting},function(a){this.ta.sorting!==a&&(v.pb(a,Z,Z,"alternateSorting"),a===Fs||a===Gs||a===Hs||Is)&&(this.ta.sorting=a,this.H())});
v.defineProperty(Z,{fJ:"alternateComparer"},function(){return this.ta.comparer},function(a){this.ta.comparer!==a&&(v.j(a,"function",Z,"alternateComparer"),this.ta.comparer=a,this.H())});v.defineProperty(Z,{$I:"alternateAngle"},function(){return this.ta.angle},function(a){this.ta.angle!==a&&(v.j(a,"number",Z,"alternateAngle"),0===a||90===a||180===a||270===a)&&(this.ta.angle=a,this.H())});
v.defineProperty(Z,{ZI:"alternateAlignment"},function(){return this.ta.alignment},function(a){this.ta.alignment!==a&&(v.pb(a,Z,Z,"alternateAlignment"),this.ta.alignment=a,this.H())});v.defineProperty(Z,{jJ:"alternateNodeIndent"},function(){return this.ta.nodeIndent},function(a){this.ta.nodeIndent!==a&&(v.j(a,"number",Z,"alternateNodeIndent"),0<=a&&(this.ta.nodeIndent=a,this.H()))});
v.defineProperty(Z,{kJ:"alternateNodeIndentPastParent"},function(){return this.ta.nodeIndentPastParent},function(a){this.ta.nodeIndentPastParent!==a&&(v.j(a,"number",Z,"alternateNodeIndentPastParent"),0<=a&&1>=a&&(this.ta.nodeIndentPastParent=a,this.H()))});v.defineProperty(Z,{lJ:"alternateNodeSpacing"},function(){return this.ta.nodeSpacing},function(a){this.ta.nodeSpacing!==a&&(v.j(a,"number",Z,"alternateNodeSpacing"),this.ta.nodeSpacing=a,this.H())});
v.defineProperty(Z,{hJ:"alternateLayerSpacing"},function(){return this.ta.layerSpacing},function(a){this.ta.layerSpacing!==a&&(v.j(a,"number",Z,"alternateLayerSpacing"),this.ta.layerSpacing=a,this.H())});v.defineProperty(Z,{iJ:"alternateLayerSpacingParentOverlap"},function(){return this.ta.layerSpacingParentOverlap},function(a){this.ta.layerSpacingParentOverlap!==a&&(v.j(a,"number",Z,"alternateLayerSpacingParentOverlap"),0<=a&&1>=a&&(this.ta.layerSpacingParentOverlap=a,this.H()))});
v.defineProperty(Z,{eJ:"alternateCompaction"},function(){return this.ta.compaction},function(a){this.ta.compaction!==a&&(v.pb(a,Z,Z,"alternateCompaction"),a===Qs||a===Ss)&&(this.ta.compaction=a,this.H())});v.defineProperty(Z,{aJ:"alternateBreadthLimit"},function(){return this.ta.breadthLimit},function(a){this.ta.breadthLimit!==a&&(v.j(a,"number",Z,"alternateBreadthLimit"),0<=a&&(this.ta.breadthLimit=a,this.H()))});
v.defineProperty(Z,{oJ:"alternateRowSpacing"},function(){return this.ta.rowSpacing},function(a){this.ta.rowSpacing!==a&&(v.j(a,"number",Z,"alternateRowSpacing"),this.ta.rowSpacing=a,this.H())});v.defineProperty(Z,{nJ:"alternateRowIndent"},function(){return this.ta.rowIndent},function(a){this.ta.rowIndent!==a&&(v.j(a,"number",Z,"alternateRowIndent"),0<=a&&(this.ta.rowIndent=a,this.H()))});
v.defineProperty(Z,{dJ:"alternateCommentSpacing"},function(){return this.ta.commentSpacing},function(a){this.ta.commentSpacing!==a&&(v.j(a,"number",Z,"alternateCommentSpacing"),this.ta.commentSpacing=a,this.H())});v.defineProperty(Z,{cJ:"alternateCommentMargin"},function(){return this.ta.commentMargin},function(a){this.ta.commentMargin!==a&&(v.j(a,"number",Z,"alternateCommentMargin"),this.ta.commentMargin=a,this.H())});
v.defineProperty(Z,{qJ:"alternateSetsPortSpot"},function(){return this.ta.setsPortSpot},function(a){this.ta.setsPortSpot!==a&&(v.j(a,"boolean",Z,"alternateSetsPortSpot"),this.ta.setsPortSpot=a,this.H())});v.defineProperty(Z,{mJ:"alternatePortSpot"},function(){return this.ta.portSpot},function(a){v.A(a,L,Z,"alternatePortSpot");this.ta.portSpot.K(a)||(this.ta.portSpot=a,this.H())});
v.defineProperty(Z,{pJ:"alternateSetsChildPortSpot"},function(){return this.ta.setsChildPortSpot},function(a){this.ta.setsChildPortSpot!==a&&(v.j(a,"boolean",Z,"alternateSetsChildPortSpot"),this.ta.setsChildPortSpot=a,this.H())});v.defineProperty(Z,{bJ:"alternateChildPortSpot"},function(){return this.ta.childPortSpot},function(a){v.A(a,L,Z,"alternateChildPortSpot");this.ta.childPortSpot.K(a)||(this.ta.childPortSpot=a,this.H())});var ks;Z.PathDefault=ks=v.p(Z,"PathDefault",-1);var ls;
Z.PathDestination=ls=v.p(Z,"PathDestination",0);var ss;Z.PathSource=ss=v.p(Z,"PathSource",1);var Fs;Z.SortingForwards=Fs=v.p(Z,"SortingForwards",10);var Gs;Z.SortingReverse=Gs=v.p(Z,"SortingReverse",11);var Hs;Z.SortingAscending=Hs=v.p(Z,"SortingAscending",12);var Is;Z.SortingDescending=Is=v.p(Z,"SortingDescending",13);var kt;Z.AlignmentCenterSubtrees=kt=v.p(Z,"AlignmentCenterSubtrees",20);var Js;Z.AlignmentCenterChildren=Js=v.p(Z,"AlignmentCenterChildren",21);var Us;
Z.AlignmentStart=Us=v.p(Z,"AlignmentStart",22);var Vs;Z.AlignmentEnd=Vs=v.p(Z,"AlignmentEnd",23);var Ls;Z.AlignmentBus=Ls=v.p(Z,"AlignmentBus",24);var As;Z.AlignmentBusBranching=As=v.p(Z,"AlignmentBusBranching",25);var Ms;Z.AlignmentTopLeftBus=Ms=v.p(Z,"AlignmentTopLeftBus",26);var Ns;Z.AlignmentBottomRightBus=Ns=v.p(Z,"AlignmentBottomRightBus",27);var Qs;Z.CompactionNone=Qs=v.p(Z,"CompactionNone",30);var Ss;Z.CompactionBlock=Ss=v.p(Z,"CompactionBlock",31);var ms;
Z.StyleLayered=ms=v.p(Z,"StyleLayered",40);var Es;Z.StyleLastParents=Es=v.p(Z,"StyleLastParents",41);var Ds;Z.StyleAlternating=Ds=v.p(Z,"StyleAlternating",42);var Cs;Z.StyleRootOnly=Cs=v.p(Z,"StyleRootOnly",43);var os;Z.ArrangementVertical=os=v.p(Z,"ArrangementVertical",50);var tt;Z.ArrangementHorizontal=tt=v.p(Z,"ArrangementHorizontal",51);var rs;Z.ArrangementFixedRoots=rs=v.p(Z,"ArrangementFixedRoots",52);var ns;Z.LayerIndividual=ns=v.p(Z,"LayerIndividual",60);var ys;
Z.LayerSiblings=ys=v.p(Z,"LayerSiblings",61);var xs;Z.LayerUniform=xs=v.p(Z,"LayerUniform",62);function qs(){Zf.call(this)}v.Ja(qs,Zf);v.ea("TreeNetwork",qs);qs.prototype.createVertex=function(){return new ps};qs.prototype.createEdge=function(){return new vt};
function ps(){nq.call(this);this.initialized=!1;this.parent=null;this.children=[];this.maxGenerationCount=this.maxChildrenCount=this.descendantCount=this.level=0;this.comments=null;this.ma=new z(0,0);this.fb=new ia(0,0);this.Ma=new z(0,0);this.Cp=this.Bp=this.rI=!1;this.St=this.At=null;this.sorting=Fs;this.comparer=tq;this.angle=0;this.alignment=Js;this.nodeIndentPastParent=this.nodeIndent=0;this.nodeSpacing=20;this.layerSpacing=50;this.layerSpacingParentOverlap=0;this.compaction=Ss;this.breadthLimit=
0;this.rowSpacing=25;this.commentSpacing=this.rowIndent=10;this.commentMargin=20;this.setsPortSpot=!0;this.portSpot=fc;this.setsChildPortSpot=!0;this.childPortSpot=fc}v.Ja(ps,nq);v.ea("TreeVertex",ps);
ps.prototype.copyInheritedPropertiesFrom=function(a){null!==a&&(this.sorting=a.sorting,this.comparer=a.comparer,this.angle=a.angle,this.alignment=a.alignment,this.nodeIndent=a.nodeIndent,this.nodeIndentPastParent=a.nodeIndentPastParent,this.nodeSpacing=a.nodeSpacing,this.layerSpacing=a.layerSpacing,this.layerSpacingParentOverlap=a.layerSpacingParentOverlap,this.compaction=a.compaction,this.breadthLimit=a.breadthLimit,this.rowSpacing=a.rowSpacing,this.rowIndent=a.rowIndent,this.commentSpacing=a.commentSpacing,
this.commentMargin=a.commentMargin,this.setsPortSpot=a.setsPortSpot,this.portSpot=a.portSpot,this.setsChildPortSpot=a.setsChildPortSpot,this.childPortSpot=a.childPortSpot)};v.u(ps,{Em:"childrenCount"},function(){return this.children.length});v.defineProperty(ps,{AK:"relativePosition"},function(){return this.ma},function(a){this.ma.set(a)});v.defineProperty(ps,{MK:"subtreeSize"},function(){return this.fb},function(a){this.fb.set(a)});
v.defineProperty(ps,{LK:"subtreeOffset"},function(){return this.Ma},function(a){this.Ma.set(a)});function vt(){oq.call(this);this.Rr=new z(0,0)}v.Ja(vt,oq);v.ea("TreeEdge",vt);
vt.prototype.commit=function(){var a=this.link;if(null!==a&&!a.rl){var b=this.network.Pb,c=null,d=null;switch(b.Df){case ls:c=this.fromVertex;d=this.toVertex;break;case ss:c=this.toVertex;d=this.fromVertex;break;default:v.k("Unhandled path value "+b.Df.toString())}if(null!==c&&null!==d)if(b=this.Rr,0!==b.x||0!==b.y||c.rI){var d=c.mb,e=Ts(c),f=Ws(c),h=c.rowSpacing;a.hn();var k=a.Ie===Hi,l=a.jc,m=0,n,p;a.Bl();if(l||k){for(m=2;4<a.oa;)a.FE(2);n=a.l(1);p=a.l(2)}else{for(m=1;3<a.oa;)a.FE(1);n=a.l(0);p=
a.l(a.oa-1)}var q=a.l(a.oa-1),r=0;0===e?(c.alignment===Vs?(r=d.bottom+b.y,0===b.y&&n.y>q.y+c.rowIndent&&(r=Math.min(r,Math.max(n.y,r-Xs(c))))):c.alignment===Us?(r=d.top+b.y,0===b.y&&n.y<q.y-c.rowIndent&&(r=Math.max(r,Math.min(n.y,r+Xs(c))))):r=c.Bp||c.Cp&&1===c.maxGenerationCount?d.top-c.Ma.y+b.y:d.y+d.height/2+b.y,k?(a.w(m,n.x,r),m++,a.w(m,d.right+f,r),m++,a.w(m,d.right+f+(b.x-h)/3,r),m++,a.w(m,d.right+f+2*(b.x-h)/3,r),m++,a.w(m,d.right+f+(b.x-h),r),m++,a.w(m,p.x,r)):(l&&(a.w(m,d.right+f/2,n.y),
m++),a.w(m,d.right+f/2,r),m++,a.w(m,d.right+f+b.x-(l?h/2:h),r),m++,l&&a.w(m,a.l(m-1).x,p.y))):90===e?(c.alignment===Vs?(r=d.right+b.x,0===b.x&&n.x>q.x+c.rowIndent&&(r=Math.min(r,Math.max(n.x,r-Xs(c))))):c.alignment===Us?(r=d.left+b.x,0===b.x&&n.x<q.x-c.rowIndent&&(r=Math.max(r,Math.min(n.x,r+Xs(c))))):r=c.Bp||c.Cp&&1===c.maxGenerationCount?d.left-c.Ma.x+b.x:d.x+d.width/2+b.x,k?(a.w(m,r,n.y),m++,a.w(m,r,d.bottom+f),m++,a.w(m,r,d.bottom+f+(b.y-h)/3),m++,a.w(m,r,d.bottom+f+2*(b.y-h)/3),m++,a.w(m,r,d.bottom+
f+(b.y-h)),m++,a.w(m,r,p.y)):(l&&(a.w(m,n.x,d.bottom+f/2),m++),a.w(m,r,d.bottom+f/2),m++,a.w(m,r,d.bottom+f+b.y-(l?h/2:h)),m++,l&&a.w(m,p.x,a.l(m-1).y))):180===e?(c.alignment===Vs?(r=d.bottom+b.y,0===b.y&&n.y>q.y+c.rowIndent&&(r=Math.min(r,Math.max(n.y,r-Xs(c))))):c.alignment===Us?(r=d.top+b.y,0===b.y&&n.y<q.y-c.rowIndent&&(r=Math.max(r,Math.min(n.y,r+Xs(c))))):r=c.Bp||c.Cp&&1===c.maxGenerationCount?d.top-c.Ma.y+b.y:d.y+d.height/2+b.y,k?(a.w(m,n.x,r),m++,a.w(m,d.left-f,r),m++,a.w(m,d.left-f+(b.x+
h)/3,r),m++,a.w(m,d.left-f+2*(b.x+h)/3,r),m++,a.w(m,d.left-f+(b.x+h),r),m++,a.w(m,p.x,r)):(l&&(a.w(m,d.left-f/2,n.y),m++),a.w(m,d.left-f/2,r),m++,a.w(m,d.left-f+b.x+(l?h/2:h),r),m++,l&&a.w(m,a.l(m-1).x,p.y))):270===e?(c.alignment===Vs?(r=d.right+b.x,0===b.x&&n.x>q.x+c.rowIndent&&(r=Math.min(r,Math.max(n.x,r-Xs(c))))):c.alignment===Us?(r=d.left+b.x,0===b.x&&n.x<q.x-c.rowIndent&&(r=Math.max(r,Math.min(n.x,r+Xs(c))))):r=c.Bp||c.Cp&&1===c.maxGenerationCount?d.left-c.Ma.x+b.x:d.x+d.width/2+b.x,k?(a.w(m,
r,n.y),m++,a.w(m,r,d.top-f),m++,a.w(m,r,d.top-f+(b.y+h)/3),m++,a.w(m,r,d.top-f+2*(b.y+h)/3),m++,a.w(m,r,d.top-f+(b.y+h)),m++,a.w(m,r,p.y)):(l&&(a.w(m,n.x,d.top-f/2),m++),a.w(m,r,d.top-f/2),m++,a.w(m,r,d.top-f+b.y+(l?h/2:h)),m++,l&&a.w(m,p.x,a.l(m-1).y))):v.k("Invalid angle "+e);a.Ni()}else e=c,f=d,a=this.link,c=Ts(e),c!==Ts(f)&&(b=Ws(e),d=e.mb,e=f.mb,0===c&&e.left-d.right<b+1||90===c&&e.top-d.bottom<b+1||180===c&&d.left-e.right<b+1||270===c&&d.top-e.bottom<b+1||(a.hn(),e=a.Ie===Hi,f=a.jc,h=Ks(this.fromVertex.alignment),
a.Bl(),0===c?(c=d.right+b/2,e?4===a.oa&&(b=a.l(3).y,a.V(1,c-20,a.l(1).y),a.w(2,c-20,b),a.w(3,c,b),a.w(4,c+20,b),a.V(5,a.l(5).x,b)):f?h?a.V(3,a.l(2).x,a.l(4).y):6===a.oa&&(a.V(2,c,a.l(2).y),a.V(3,c,a.l(3).y)):4===a.oa?a.w(2,c,a.l(2).y):3===a.oa?a.V(1,c,a.l(2).y):2===a.oa&&a.w(1,c,a.l(1).y)):90===c?(b=d.bottom+b/2,e?4===a.oa&&(c=a.l(3).x,a.V(1,a.l(1).x,b-20),a.w(2,c,b-20),a.w(3,c,b),a.w(4,c,b+20),a.V(5,c,a.l(5).y)):f?h?a.V(3,a.l(2).x,a.l(4).y):6===a.oa&&(a.V(2,a.l(2).x,b),a.V(3,a.l(3).x,b)):4===a.oa?
a.w(2,a.l(2).x,b):3===a.oa?a.V(1,a.l(2).x,b):2===a.oa&&a.w(1,a.l(1).x,b)):180===c?(c=d.left-b/2,e?4===a.oa&&(b=a.l(3).y,a.V(1,c+20,a.l(1).y),a.w(2,c+20,b),a.w(3,c,b),a.w(4,c-20,b),a.V(5,a.l(5).x,b)):f?h?a.V(3,a.l(2).x,a.l(4).y):6===a.oa&&(a.V(2,c,a.l(2).y),a.V(3,c,a.l(3).y)):4===a.oa?a.w(2,c,a.l(2).y):3===a.oa?a.V(1,c,a.l(2).y):2===a.oa&&a.w(1,c,a.l(1).y)):270===c&&(b=d.top-b/2,e?4===a.oa&&(c=a.l(3).x,a.V(1,a.l(1).x,b+20),a.w(2,c,b+20),a.w(3,c,b),a.w(4,c,b-20),a.V(5,c,a.l(5).y)):f?h?a.V(3,a.l(2).x,
a.l(4).y):6===a.oa&&(a.V(2,a.l(2).x,b),a.V(3,a.l(3).x,b)):4===a.oa?a.w(2,a.l(2).x,b):3===a.oa?a.V(1,a.l(2).x,b):2===a.oa&&a.w(1,a.l(1).x,b)),a.Ni()))}};v.defineProperty(vt,{zK:"relativePoint"},function(){return this.Rr},function(a){this.Rr.set(a)});function wt(){G.call(this);this.Pe=null}v.Ja(wt,G);wt.prototype.cloneProtected=function(a){G.prototype.cloneProtected.call(this,a);a.element=this.Pe.cloneNode(!0)};wt.prototype.toString=function(){return"HTMLHost("+this.Pe.toString()+")#"+v.ld(this)};
wt.prototype.Xj=function(a,b){var c=this.Pe;if(null!==c){var d=this.Xa(Ab);d.x-=this.U.width/2;d.y-=this.U.height/2;d.x-=this.U.x;d.y-=this.U.y;var d=b.mF(d),e=b.hl;null===e||e.contains(c)||e.appendChild(c);e=this.transform;c.style.transform="matrix("+e.m11+","+e.m12+","+e.m21+","+e.m22+","+e.dx+","+e.dy+")";c.style.transformOrigin="0 0";e=d.y;c.style.left=d.x+"px";c.style.top=e+"px"}};
wt.prototype.np=function(a,b,c,d){var e=this.xa;isFinite(e.width)&&(a=e.width);isFinite(e.height)&&(b=e.height);var e=this.xf,f=this.ih;c=Math.max(c,f.width);d=Math.max(d,f.height);a=Math.min(e.width,a);b=Math.min(e.height,b);a=Math.max(c,a);b=Math.max(d,b);c=this.Pe;null!==c&&(b=c.getBoundingClientRect(),a=b.width,b=b.height);Xa(this.Qc,a,b);Bm(this,0,0,a,b)};wt.prototype.Li=function(a,b,c,d){Fm(this,a,b,c,d)};v.u(wt,{Ha:"naturalBounds"},function(){return this.Qc});
v.defineProperty(wt,{element:"element"},function(){return this.Pe},function(a){var b=this.Pe;b!==a&&(a instanceof HTMLElement||v.k("HTMLHost.element must be an instance of HTMLElement."),this.Pe=a,a.className="HTMLHost",this.h("element",b,a),this.pa())});ea.version="1.6.16";
window&&(window.module&&"object"===typeof window.module&&"object"===typeof window.module.exports?window.module.exports=ea:window.define&&"function"===typeof window.define&&window.define.amd?(window.go=ea,window.define(ea)):window.go=ea);"undefined"!==typeof module&&"object"===typeof module.exports&&(module.exports=ea); })(window);
