(function() {
  'use strict';

  angular
    .module( 'app.publicDashboard', [] )
    .config( config );

  config.$inject = [
    '$stateProvider',
    '$urlRouterProvider'
  ];

  function config( $stateProvider, $urlRouterProvider ) {
    $stateProvider
      .state( 'publicDashboard', {
        url: '/public',
        views: {
          'main': {
            templateUrl: 'templates/publicDashboard.html',
            controller: 'PublicDashboardController as dashboard'
          }
        }
      } );
  }
})();
