(function() {
  'use strict';

  angular
    .module('app')
    .directive('searchCheckbox', searchCheckbox);

  function searchCheckbox() {
    return {
      restrict: 'E',
      scope: {
        predicate: '@',
        ngTrueValue: '@',
        ngFalseValue: '@'
      },
      require: '^stTable',
      templateUrl: 'directives/searchCheckbox/searchCheckbox.html',
      link: link
    };

    function link( scope, element, attr, ctrl ) {
      scope.change = function change() {
        ctrl.search(scope.value, scope.predicate);
      }
    }
  }
})();
