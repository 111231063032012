(function() {
  'use strict';

  angular
    .module('app')
    .service('userService', userService);

  userService.$inject = [];

  function userService() {

    return {
      getLengthOptions: getLengthOptions,
      getUserRoles: getUserRoles
    };

    function getLengthOptions() {
      return [
        { value: 20, label: 20 },
        { value: 40, label: 40 },
        { value: 60, label: 60 },
        { value: -1, label: 'All' }
      ]
    }

    function getUserRoles() {
      return [ 'ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_API' ]
    }
  }
})();
