(function() {
  'use strict';

  angular
    .module('app')
    .service('helpers', helpers);

  helpers.$inject = [
    '$state',
    'webStorageService'
  ];

  function helpers( $state, webStorageService ) {
    return {
      buildParams: buildParams,
      calculateNumberPages: calculateNumberPages,
      isAuthenticated: isAuthenticated,
      redirectByPermissions: redirectByPermissions
    };

    function buildParams( tableState, numberPerPages, filters = {} ) {
      // Pagination
      let params = { offset: tableState.pagination.start || 0, limit: numberPerPages };

      // Order By
      if( Object.keys(tableState.sort).length ) {
        params[ `order_by[${tableState.sort.predicate}]` ] = tableState.sort.reverse ? 'desc' : 'asc'
      }

      // Custom Filters
      if( Object.keys(filters).length ||
        (tableState.search.predicateObject && !tableState.search.predicateObject[ '$' ])
      ) {
        Object.keys(filters).forEach(function( key ) {
          params[ `filters[${key}]` ] = filters[ key ];
        });

        Object.keys(tableState.search.predicateObject).forEach(function( key ) {
          params[ `filters[${key}]` ] = tableState.search.predicateObject[ key ];
        });
      }

      // General Search
      if( tableState.search.predicateObject && tableState.search.predicateObject[ '$' ]) {
        params[ 'filters[criteria]' ] = tableState.search.predicateObject[ '$' ];
      }

      return params;
    }

    function calculateNumberPages( dataLength, numberPerPages ) {
      return Math.ceil(dataLength / numberPerPages)
    }

    function isAuthenticated() {
      return webStorageService.getObject('session').access_token;
    }

    function getRole() {
      const roles = webStorageService.getObject( 'session' ).roles;
      let isAdmin;
      let isSupervisor;
      let isApi;
      if( roles ) {
        isAdmin = roles.indexOf( 'ROLE_ADMIN' ) !== -1;
        if( !isAdmin ) isSupervisor = roles.indexOf( 'ROLE_SUPERVISOR' ) !== -1;
        if( !isAdmin && !isSupervisor ) isApi = roles.indexOf( 'ROLE_API' ) !== -1;
      }

      return { isAdmin, isSupervisor, isApi }
    }

    function redirectByPermissions( toState ) {
      if( !isAuthenticated() ) {
        if( toState !== 'login' && toState !== 'publicDashboard' )
          $state.transitionTo( 'login', { reload: true, inherit: false } );
      } else {
        if( toState === 'login' ) {
          window.location.replace('/#')
        }
        /*if( getRole().isApi && (toState !== 'app.dashboard') ) {
          window.location.replace('/#')
        }
        if( getRole().isSupervisor && (toState !== 'app.dashboard' && toState !== 'app.work' && toState !== 'app.workDetail') ) {
          window.location.replace('/#')
        }*/
      }
    }
  }
})();
