( function() {
  'use strict';

  angular
    .module( 'app.dashboard' )
    .controller( 'ReportsController', ReportsController );

  ReportsController.$inject = [
    'userDataService',
    'entityObjectDataService',
    'lovDataService',
    'workDataService',
    'incmsDataService',
    '$scope',
    'helpers',
    'workService'
  ];

  function ReportsController( userDataService, entityObjectDataService, lovDataService, workDataService,
    incmsDataService, $scope, helpers, workService ) {

    let vm = this;
    vm.getWorks = getWorks;
    vm.data = [];
    vm.lengthOptions = workService.getLengthOptions();
    vm.numberPerPages = 20;
    vm.isLoading = true;

    function getWorks( tableState ) {
      vm.tableIsLoading = true;
      vm.params = helpers.buildParams( tableState, vm.numberPerPages );

      workDataService.getWorksDone( { params: vm.params } ).then( function( response ) {
        if( response.status === 200 ) {
          const total = parseInt( response.data.total );
          vm.data = response.data.data;
          tableState.pagination.numberOfPages = helpers.calculateNumberPages( total, vm.numberPerPages );
          tableState.pagination.totalItemCount = total;
          vm.tableIsLoading = false;
          vm.isLoading = false;
        }
        else {
          vm.isLoading = false;
          $scope.showNotification( 'The data could not be loaded', 'error' );
        }
      } );
    }
  }
} )();
