( function() {
  'use strict';

  angular
    .module( 'app.user', [] )
    .config( config );

  config.$inject = [
    '$stateProvider'
  ];

  function config( $stateProvider ) {
    $stateProvider
      .state( 'app.user', {
        url: '/users',
        views: {
          'content': {
            templateUrl: 'templates/users.html',
            controller: 'UserController as user'
          }
        },
        ncyBreadcrumb: {
          label: 'users'
        }
      } )
      .state( 'app.userCreate', {
        url: '/users/create',
        views: {
          'content': {
            templateUrl: 'templates/usersEdit.html',
            controller: 'UserEditController as userEdit'
          }
        },
        ncyBreadcrumb: {
          parent: 'app.user',
          label: ' '
        }
      } )
      .state( 'app.userEdit', {
        url: '/users/edit/{id:int}',
        views: {
          'content': {
            templateUrl: 'templates/usersEdit.html',
            controller: 'UserEditController as userEdit'
          }
        },
        ncyBreadcrumb: {
          parent: 'app.user',
          label: 'edit'
        }
      } )
      .state( 'app.userPasswordReset', {
        url: '/users/reset-password/{id:int}',
        views: {
          'content': {
            templateUrl: 'templates/userResetPassword.html',
            controller: 'UserResetPasswordController as userResetPassword'
          }
        },
        ncyBreadcrumb: {
          parent: 'app.user',
          label: 'resetPassword'
        }
      } );
  }
} )();
