(function() {
  'use strict';

  angular
    .module( 'app' )
    .service( 'profileService', profileService );

  profileService.$inject = [
    'DTColumnDefBuilder',
    'DTOptionsBuilder'
  ];

  function profileService( DTColumnDefBuilder, DTOptionsBuilder ) {

    return {
      getDTColumnDefs: getDTColumnDefs,
      getDTOptions: getDTOptions
    };

    function getDTColumnDefs() {
      return [
        DTColumnDefBuilder.newColumnDef(2).notSortable().withOption('sWidth', '7%'),
        DTColumnDefBuilder.newColumnDef(3).notSortable().withOption('sWidth', '7%')
      ]
    }

    function getDTOptions(createAction) {
      return DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('dom', '<"row"<"col-sm-12"<"pull-right"f>>><"row"<"col-sm-12"<"pull-right"B>>>t<"row"<"col-md-8 col-sm-12"<"inline-controls"i>><"col-md-4 col-sm-12"p>>')
        .withButtons([
          {
            text: '<span class="icon fa fa-plus"></span> Create',
            className: 'btn btn-primary', action: function () { createAction(); }
          },
          {
            text: '<span class="icon fa fa-share"></span> Export',
            className: 'btn btn-primary',
            action: function () {}
          }
        ])
    }
  }
})();
