( function() {
  'use strict';

  angular
    .module( 'app' )
    .factory( 'webStorageService', webStorageService );

  webStorageService.$inject = [
    '$window'
  ];

  function webStorageService( $window ) {
    return {
      get        : get,
      getObject  : getObject,
      removeAll  : removeAll,
      removeItem : removeItem,
      set        : set,
      setObject  : setObject
    };

    /**
     * Fetch item from web storage
     * @param  {[string/int]}           key           [item key]
     * @param  {[string]}               type          [web storage type]
     * @param  {[string/int/boolean]}   defaultValue  [value to return if item doesn't have one]
     * @return {[string]}                             [item value]
     */
    function get( key, type, defaultValue ) {
      switch ( type ) {
        case 'session':
          return $window.sessionStorage[key] || defaultValue;
        default:
          return $window.localStorage[key] || defaultValue;
      }
    }

    /**
     * Fetch an object item from web storage
     * @param  {[string/int]}   key   [item key]
     * @param  {[string]}       type  [web storage type]
     * @return {[object]}             [item]
     */
    function getObject( key, type ) {
      switch ( type ) {
        case 'session':
          return JSON.parse( $window.sessionStorage[key] || '{}' );
        default:
          return JSON.parse( $window.localStorage[key] || '{}' );
      }
    }

    /**
     * Remove all items from local storage
     * @param  {[string]}   type  [web storage type]
     */
    function removeAll( type ) {
      switch ( type ) {
        case 'session':
          $window.sessionStorage.clear();
          break;
        case 'all':
          $window.localStorage.clear();
          $window.sessionStorage.clear();
          break;
        default:
          $window.localStorage.clear();
          break;
      }
    }

    /**
     * Remove one item from local storage
     * @param  {[string/int]}   key   [item key]
     * @param  {[string]}       type  [web storage type]
     */
    function removeItem( key, type ) {
      switch ( type ) {
        case 'session':
          $window.sessionStorage.removeItem( key );
          break;
        default:
          $window.localStorage.removeItem( key );
          break;
      }
    }

    /**
     * Storage an item on local storage
     * @param  {[string/int]}          key     [item key]
     * @param  {[string/int/boolean]}  value   [item value]
     * @param  {[string]}              type    [web storage type]
     */
    function set( key, value, type ) {
      switch ( type ) {
        case 'session':
          $window.sessionStorage[key] = value;
          break;
        default:
          $window.localStorage[key] = value;
          break;
      }
    }

    /**
     * Storage an object item on local storage
     * @param  {[string/int]}   key     [item key]
     * @param  {[object]}       value   [item value]
     * @param  {[string]}       type    [web storage type]
     */
    function setObject( key, value, type ) {
      switch ( type ) {
        case 'session':
          $window.sessionStorage[key] = JSON.stringify( value );
          break;
        default:
          $window.localStorage[key] = JSON.stringify( value );
          break;
      }
    }
  }
} )();
