(function() {
    'use strict';

    angular
        .module('app.group')
        .controller('GroupEditController', GroupEditController);

    GroupEditController.$inject = [
        '$scope',
        '$stateParams',
        'groupDataService',
        'userDataService',
        'lodash',
        'helpers'
    ];

    function GroupEditController(
        $scope,
        $stateParams,
        groupDataService,
        userDataService,
        lodash,
        helpers
    ) {
        let vm = this;

        vm.entity = { users: [] };
        vm.entityId = $stateParams.id;
        vm.isLoading = true;
        vm.onSubmit = onSubmit;

        init();

        function init() {
            if( vm.entityId ) {
                getGroup();
            }
            else
                vm.isLoading = false;
        }

        function getGroup() {
            groupDataService.getGroup(vm.entityId).then(function( response ) {
                if( response.status === 200 ) {
                    vm.entity = response.data;
                    vm.entity.users = lodash.map(vm.entity.users, 'id');

                    vm.isLoading = false;
                }
                else {
                    vm.isLoading = false;
                    $scope.showNotification('The data could not be loaded', 'error');
                }
            });
        }

        function onSubmit( form ) {
            if( !form.$invalid ) {
                vm.isLoading = true;

                const promise = vm.entityId ? groupDataService.updateGroup(vm.entityId, vm.entity) :
                    groupDataService.saveGroup(vm.entity);

                promise.then(function (response) {
                    if (response.status === 201 || response.status === 200) {
                        vm.entity = response.data;
                        vm.isLoading = false;
                        $scope.showNotification('Data Saved');
                    } else {
                        vm.isLoading = false;
                        $scope.showNotification('The action could not be performed successfully', 'error');
                    }
                })
            }
        }
    }
})();
