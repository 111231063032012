(function() {
  'use strict';

  angular
    .module( 'app' )
    .service( 'lovMeterDataService', lovMeterDataService );

  lovMeterDataService.$inject = [
    'config',
    '$http'
  ];

  function lovMeterDataService( config, $http ) {

    return {
      getLovMeter: getLovMeter,
      getLovsMeter: getLovsMeter,
      deleteLovMeter: deleteLovMeter,
      saveLovMeter: saveLovMeter,
      updateLovMeter: updateLovMeter
    };

    function getLovsMeter( params ) {
      return $http.get( config.endpoints.lovMeter, params )
        .then( function( response ) {
          return { data: response.data, status: response.status };
        } )
        .catch( function( error ) {
          return { status: error.status, message: error.data.message };
        } );
    }

    function getLovMeter( entityId ) {
      return $http.get( `${config.endpoints.lovMeter}/${entityId}` )
        .then( function( response ) {
          return { data: response.data, status: response.status };
        } )
        .catch( function( error ) {
          return { status: error.status, message: error.data.message };
        } );
    }

    function deleteLovMeter( entityId ) {
      return $http.delete( `${config.endpoints.lovMeter}/${entityId}` )
        .then( function( response ) {
          return { data: response.data, status: response.status };
        } )
        .catch( function( error ) {
          return { status: error.status, message: error.data.message };
        } );
    }

    function saveLovMeter( data ) {
      return $http.post( `${config.endpoints.lovMeter}`, data )
        .then( function( response ) {
          return { data: response.data, status: response.status };
        } )
        .catch( function( error ) {
          return { status: error.status, message: error.data.message };
        } );
    }

    function updateLovMeter( entityId, data ) {
      return $http.put( `${config.endpoints.lovMeter}/${entityId}`, data )
        .then( function( response ) {
          return { data: response.data, status: response.status };
        } )
        .catch( function( error ) {
          return { status: error.status, message: error.data.message };
        } );
    }
  }
})();
