(function() {
  'use strict';

  angular
    .module('app')
    .controller('TableWrapperController', TableWrapperController)
    .component('tableWrapper', {
      bindings: {
        advancedSearch: '<',
        isCreatable: '<',
        createAction: '&?',
        refreshAction: '&?',
        importAction: '&?',
        createUrl: '@',
        filter: '=',
        lengthOptions: '<',
        numberPerPages: '=',
      },
      transclude: {
        'table': 'table',
        'advancedSearch': '?advancedSearch'
      },
      controller: 'TableWrapperController',
      templateUrl: 'components/tableWrapper/tableWrapper.html'
    });

  TableWrapperController.$inject = [];

  function TableWrapperController() {
    let vm = this

    vm.closed = true;
  }
})();
