(function() {
  'use strict';

  angular
    .module('app.profile', [])
    .config(config);

  config.$inject = [
    '$stateProvider'
  ];

  function config( $stateProvider ) {
    $stateProvider
      .state('app.profile', {
        url: '/profile',
        views: {
          'content': {
            templateUrl: 'templates/profile.html',
            controller: 'ProfileController as profile'
          }
        },
        ncyBreadcrumb: {
          label: 'profile'
        }
      })
  }
})();
