(function() {
  'use strict';

  angular
    .module('app')
    .controller('EntityObjectDetailController', EntityObjectDetailController);

  EntityObjectDetailController.$inject = [
    'entityId',
    'entityObjectDataService',
    'lodash',
    '$uibModalInstance'
  ];

  function EntityObjectDetailController(
    entityId,
    entityObjectDataService,
    lodash,
    $uibModalInstance
  ) {
    let vm = this;
    
    vm.cancel = cancel;

    init();

    function init() {
      getEntityObject();
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }

    function getEntityObject() {
      entityObjectDataService.getEntityObject(entityId).then(function( response ) {
        if( response.status === 200 ) {
          vm.entity = response.data;
          vm.attributes = lodash.map(vm.entity.csEntityAttributes, 'AttributeName').join(', ');
          vm.childs = lodash.map(vm.entity.csEntityChilds, 'csChildEntityObject.objectName').join(', ');
        }
      });
    }

  }
})();
