(function() {
  'use strict';

  angular
    .module('app.entityObject', [])
    .config(config);

  config.$inject = [
    '$stateProvider'
  ];

  function config( $stateProvider ) {
    $stateProvider
      .state('app.entityObject', {
        url: '/entity-object',
        views: {
          'content': {
            templateUrl: 'templates/entityObject.html',
            controller: 'EntityObjectController as entityObject'
          }
        },
        ncyBreadcrumb: {
          label: 'entityObject'
        }
      })
      .state('app.entityObjectCreate', {
        url: '/entity-object/create',
        views: {
          'content': {
            templateUrl: 'templates/entityObjectEdit.html',
            controller: 'EntityObjectEditController as entityObjectEdit'
          }
        },
        ncyBreadcrumb: {
          parent: 'app.entityObject',
          label: ' '
        }
      })
      .state('app.entityObjectEdit', {
        url: '/entity-object/edit/{id:int}',
        views: {
          'content': {
            templateUrl: 'templates/entityObjectEdit.html',
            controller: 'EntityObjectEditController as entityObjectEdit'
          }
        },
        ncyBreadcrumb: {
          parent: 'app.entityObject',
          label: 'edit'
        }
      });
  }
})();
