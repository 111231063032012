(function() {
  'use strict';

  angular
    .module('app')
    .service('attributeDataService', attributeDataService);

  attributeDataService.$inject = [
    'config',
    '$http'
  ];

  function attributeDataService( config, $http ) {

    return {
      deleteAttribute: deleteAttribute,
      getAttribute: getAttribute,
      saveAttribute: saveAttribute,
      updateAttribute: updateAttribute
    };

    function getAttribute( entityId ) {
      return $http.get(`${config.endpoints.attribute}/${entityId}`)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function deleteAttribute( entityId ) {
      return $http.delete(`${config.endpoints.attribute}/${entityId}`)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function saveAttribute( data ) {
      return $http.post(`${config.endpoints.attribute}`, data)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function updateAttribute( entityId, data ) {
      return $http.put(`${config.endpoints.attribute}/${entityId}`, data)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }
  }
})();
