(function() {
  'use strict';

  angular
    .module('app.entityObject')
    .controller('LovEditController', LovEditController);

  LovEditController.$inject = [
    'lovDataService',
    'lovService',
    '$scope',
    '$stateParams',
    '$uibModal',
    'valueDataService'
  ];

  function LovEditController( lovDataService,
                              lovService,
                              $scope,
                              $stateParams,
                              $uibModal,
                              valueDataService ) {
    let vm = this;

    vm.deleteValue = deleteValue;
    vm.entityId = $stateParams.id;
    vm.isLoading = true;
    vm.onSubmit = onSubmit;
    vm.openEditValueModal = openEditValueModal;
    vm.valueslengthOptions = lovService.getLengthOptions();
    vm.valuesPerPages = 20;

    init();

    function init() {
      if( vm.entityId ) {
        getLov()
      } else {
        vm.entity = { lovInUse: 2 };
        vm.isLoading = false
      }
    }

    function deleteValue( entityId ) {
      vm.isLoading = true;

      valueDataService.deleteValue(entityId).then(function( response ) {
        if( response.status === 204 ) {
          getLov()
        }
        else {
            vm.isLoading = false;
            $scope.showNotification('The action could not be performed successfully', 'error');
        }
      });
    }

    function getLov() {
      lovDataService.getLov(vm.entityId).then(function( response ) {
        if( response.status === 200 ) {
          vm.entity = response.data;
          vm.isLoading = false
        }
        else {
            vm.isLoading = false;
            $scope.showNotification('The data could not be loaded', 'error');
        }
      });
    }

    function onSubmit( form ) {
      if( !form.$invalid ) {
        vm.isLoading = true;

        const promise = vm.entityId ? lovDataService.updateLov(vm.entityId, vm.entity) :
          lovDataService.saveLov(vm.entity);

        promise.then(function( response ) {
          if( response.status === 201 || response.status === 200 ) {
            vm.entity = response.data;
            vm.isLoading = false;
            $scope.showNotification('Data Saved');
          }
          else {
              vm.isLoading = false;
              $scope.showNotification('The action could not be performed successfully', 'error');
          }
        })
      }
    }

    function openEditValueModal( entityId ) {
      let modalInstance = $uibModal.open({
        templateUrl: 'templates/valueEdit.html',
        controller: 'ValueEditController',
        controllerAs: 'valueEdit',
        size: 'md',
        resolve: {
          entityId: function() {
            return entityId;
          },
          entityParentId: function() {
            return vm.entityId;
          }
        }
      });

      modalInstance.result.then(function( result ) {
        if( result ) {
          vm.isLoading = true;
          let promise = result.id ? valueDataService.updateValue(result.id, result) : valueDataService.saveValue(result);
          promise.then(function( response ) {
            if( response.status === 201 || response.status === 200 ) {
              getLov()
            }
            else {
                vm.isLoading = false;
                $scope.showNotification('The action could not be performed successfully', 'error');
            }
          });
        }
      });
    }
  }
})();
