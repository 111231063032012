(function() {
  'use strict';

  angular
    .module('app')
    .service('securityDataService', securityDataService);

  securityDataService.$inject = [
    'config',
    '$http',
    'webStorageService'
  ];

  function securityDataService(
    config,
    $http,
    webStorageService
  ) {
    return {
      login: login
    };

    function login( credentials ) {
      return $http({
        method: 'POST',
        url: config.endpoints.login,
        data: {
          'username': credentials.user,
          'password': credentials.password,
          'grant_type': 'password',
          'client_id': config.auth.clientId,
          'client_secret': config.auth.clientSecret
        }
      })
        .then(function( response ) {
          webStorageService.setObject('session', {
            access_token: response.data.access_token,
            expires_in: response.data.expires_in,
            refresh_token: response.data.refresh_token
          });
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }
  }
})();
