(function() {
  'use strict';

  angular
    .module('app.profile')
    .controller('ProfileController', ProfileController);

  ProfileController.$inject = [
    'profileDataService',
    '$scope',
    '$state',
    'userDataService',
    'userService'
  ];

  function ProfileController( profileDataService, $scope, $state, userDataService, userService ) {
    let vm = this;

    vm.isLoading = true;
    vm.onSubmit = onSubmit;

    init();

    function init() {
      getProfile()
    }

    function getProfile() {
      profileDataService.getUserProfile().then(function( response ) {
        if( response.status === 201 ) {
          vm.entity = response.data;

          vm.roles = userService.getUserRoles();
          vm.isLoading = false
        }
        else {
            vm.isLoading = false;
            $scope.showNotification('The data could not be loaded', 'error');
        }
      })
    }

    function onSubmit( form ) {
      if( !form.$invalid ) {
        vm.isLoading = true;
        const toSave = { password: vm.password, currentPassword: vm.currentPassword };

        userDataService.updateUser(vm.entity.id, toSave).then(function( response ) {
          if( response.status === 200 ) {
            $scope.showNotification('Profile Updated');
            $state.go('app.dashboard')
          } else {
              vm.isLoading = false;
              $scope.showNotification('The action could not be performed successfully', 'error');
          }
          vm.isLoading = false;
        })
      }
    }
  }
})();
