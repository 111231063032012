(function() {
  'use strict';

  angular
    .module('app.work')
    .controller('WorkDetailController', WorkDetailController);

  WorkDetailController.$inject = [
    'config',
    'entityObjectDataService',
    'lodash',
    '$scope',
    '$stateParams',
    '$uibModal',
    'workDataService',
    'workService',
    'groupDataService',
    'userDataService'
  ];

  function WorkDetailController( config,
    entityObjectDataService,
    lodash,
    $scope,
    $stateParams,
    $uibModal,
    workDataService,
    workService,
    groupDataService,
    userDataService) {
    let vm = this;
    let detailNodeModal;
    let changeStatusModal;
    let editModal;
    let originalZoom = null;

    vm.closeEditModal = closeEditModal;
    vm.openEditModal = openEditModal;
    vm.saveEntity = saveEntity;
    vm.changeStatus = changeStatus;
    vm.closeChangeStatusModal = closeChangeStatusModal;
    vm.closeNodeModal = closeNodeModal;
    vm.entityId = $stateParams.id;
    vm.isLoading = true;
    vm.showNodeIds = true;
    vm.loadUpload = loadUpload;
    vm.onMarkerClick = onMarkerClick;
    vm.onLineClick = onLineClick;
    vm.polylinesTree = [];
    vm.clearSelectedNodeInMap = clearSelectedNodeInMap;
    vm.clearSelectedLineInMap = clearSelectedLineInMap;
    vm.selectNodeInMap = selectNodeInMap;
    vm.selectNodeLineInMap = selectNodeLineInMap;
    vm.selectObjectByMarker = selectObjectByMarker;
    vm.showModalStatus = showModalStatus;
    vm.showWorkMarkers = showWorkMarkers;
    vm.showIds = showIds;
    vm.tree = [];
    vm.treeNodeTpl = workService.getTreeTpl();
    vm.uploadsLengthOptions = workService.getLengthOptions();
    vm.uploadsPerPages = 20;
    vm.uploadsTableIsLoading = true;

    vm.markerWindowOptions = {
      boxClass: "marker-info",
      enableEventPropagation: false
    };

    init();

    function init() {
      getWork();
    }

    function changeStatus() {
      vm.isLoading = true;

      workDataService.updateWorkStatus(vm.selectedUpload.id, {
        commentary: vm.statusReason,
        workStatus: vm.statusApproved ? 'APPROVED' : 'REJECTED'
      })
        .then(function( response ) {
          if( response.status === 200 ) {
            closeChangeStatusModal();
            getWork();
            vm.selectedUpload = null
          } else {
            vm.isLoading = false;
          }
        });
    }

    function closeChangeStatusModal() {
      changeStatusModal.close()
    }

    function closeNodeModal() {
      detailNodeModal.close()
    }

    function formatDataForMap( entity ) {
      vm.markers = [];
      vm.polylines = [];

      let coordinates = {
        latitude: lodash.get(entity, 'cs_entity_datas.0.gpsX', entity.gpsX),
        longitude:  lodash.get(entity, 'cs_entity_datas.0.gpsY', entity.gpsY)
      };

      let zoom = !originalZoom || originalZoom < 15 ? 15 : originalZoom;
      vm.map = {
        center: coordinates,
        zoom: zoom,
        fullscreenControl:true
      };

      let firstLevelMarkers = lodash.filter(entity.cs_entity_datas, { refCsEntityDataId: 0 });
      firstLevelMarkers.map(function( item ) {
        let icon = item.csEntityObject.marker ?
          `${config.endpoints.markerImages}/${item.csEntityObject.marker}@2x.png` :
          `${config.endpoints.markerImages}/default@2x.png`;

        vm.markers.push(Object.assign({}, item, {
          latitude: item.gpsX, longitude: item.gpsY, options: {
            icon: {
              url: icon,
              scaledSize: { width: 32, height: 32 }
            }
          }
        }));
      });

      entity.cs_polylines.map(function( item ) {
        let line = {
          ...item,
          path: [{ latitude: item.csEntityData1.gpsX, longitude: item.csEntityData1.gpsY }],
          stroke: { color: '#000' },
        };
        delete line.editable;

        let waypoints = lodash.sortBy(item.csWaypoints, 'order');
        waypoints.map(function( point ) {
          line.path.push({
            latitude: point.csEntityData.gpsX, longitude: point.csEntityData.gpsY
          })
        });
        line.path.push({
          latitude: item.csEntityData2.gpsX,
          longitude: item.csEntityData2.gpsY
        });

        vm.polylines.push(line);
      });
    }

    function getPolylinesTree( entity ) {
      vm.polylinesTree = [];

      lodash.forEach(entity.cs_polylines, function( item ) {
        vm.polylinesTree.push( {
          label: `${item.csEntityObject.objectName} - ${item.id}`,
          id: item.id,
          name: item.csEntityObject.objectName,
          type: 'polyline'
        } );
      });
    }

    function getTree( entity ) {
      vm.tree = [];
      let tree = lodash.sortBy(entity.cs_entity_datas, [ 'id', 'refCsEntityDataId' ]);

      lodash.forEach(tree, function( item ) {
        if( !item.refCsEntityDataId ) {
          let menu = renderTree(entity, item, item.id);
          vm.tree.push(menu);
        }
      });
    }

    function getWork() {
      workDataService.getWork(vm.entityId).then(function( response ) {
        if( response.status === 200 ) {
          vm.entity = response.data;
          formatDataForMap(vm.entity);
          getTree(vm.entity);
          getPolylinesTree(vm.entity);

          getUploads();
        }
        else {
            vm.isLoading = false;
            $scope.showNotification('The data could not be loaded', 'error');
        }
      });
    }

    function getUploads() {
      let status = vm.entity.work_status === 'APPROVED' ? 'APPROVED,GATHERED,REJECTED,INVALIDATED' : 'GATHERED,REJECTED,INVALIDATED';
      let params = {};
      params[ `filters[refId]` ] = vm.entityId;
      params[ `filters[mStatus]` ] = status;
      workDataService.getWorks({ params: params })
        .then(function( response ) {
          if( response.status === 200 ) {
            vm.uploads = response.data.data;
            vm.uploadsTableIsLoading = false;

            getUsers();
          }
          else {
              vm.isLoading = false;
              $scope.showNotification('The data could not be loaded', 'error');
          }
        });
    }

    function loadUpload( upload ) {
      vm.isLoading = true;
      originalZoom = upload.zoom ? parseInt(upload.zoom) : null;
      vm.selectedUpload = vm.selectedUpload && upload.id === vm.selectedUpload.id ? null : upload;
      let entity = vm.selectedUpload ? vm.selectedUpload : vm.entity;
      vm.workMarkers = false;

      if(vm.selectedUpload) {
        workDataService.getWorkUpload(entity.id)
          .then(function( response ) {
            if( response.status === 200 ) {
              vm.selectedUpload = response.data;
              vm.isLoading = false;
              formatDataForMap(response.data);
              getTree(response.data);
              getPolylinesTree(response.data);
            }
            else {
                vm.isLoading = false;
            }
          });
      } else {
        formatDataForMap(entity);
        getTree(entity);
        getPolylinesTree(entity);
        vm.isLoading = false;
      }
    }

    function onMarkerClick( marker, eventName, model ) {
      vm.activeLineModel = null;
      clearSelectedLineInMap();
      clearSelectedNodeInMap();

      model.show = !model.show;
      vm.activeModel = model;
      selectObjectByMarker( model );
    }

    function onLineClick( line, eventName, model, argument ) {
      vm.activeModel = null;
      clearSelectedLineInMap();
      clearSelectedNodeInMap();

      const modelClone = lodash.cloneDeep(model);
      modelClone.show = !modelClone.show;
      vm.activeLineModel = { ...modelClone, latitude: argument[0].latLng.lat(), longitude: argument[0].latLng.lng() };

       let lineIndex = lodash.findIndex( vm.polylinesTree, { id: model.id } );
       vm.polylinesTree[lineIndex].selected = true;
    }

    function renderTree( entity, item, firstLevelId ) {
      let parent = { label: `${item.csEntityObject.objectName} - ${item.id}`, id: item.id, name: item.csEntityObject.objectName };
      if( item.refCsEntityDataId ) {
        parent[ 'firstLevelId' ] = firstLevelId
      }

      let result = lodash.filter(entity.cs_entity_datas, { 'refCsEntityDataId': item.id });

      if( result.length ) {
        if( !parent.children )
          parent.children = [];
        result.map(val => {
          let children = renderTree(entity, val, firstLevelId);
          parent.children.push(children)
        })
      }
      return parent
    }

    function showChildrenIds( item ) {
      const newItem = {
        label: vm.showNodeIds ? `${item.name} - ${item.id}` : item.name,
        id: item.id,
        name: item.name,
        firstLevelId: item.firstLevelId
      };
      if( item.selectedChildren ) newItem.selectedChildren = true;
      if( item.selected ) newItem.selected = true;
      if( item.children ) newItem.children = item.children.map( showChildrenIds );

      return newItem
    }

    function showIds() {
      vm.showNodeIds = !vm.showNodeIds;
      vm.tree = lodash.map( vm.tree, ( item ) => {
        const newItem = {
          label: vm.showNodeIds ? `${item.name} - ${item.id}` : item.name,
          id: item.id,
          name: item.name,
        };

        if( item.selectedChildren ) newItem.selectedChildren = true;
        if( item.selected ) newItem.selected = true;
        if( item.children ) newItem.children = item.children.map( showChildrenIds );

        return newItem
      } );

      vm.polylinesTree = lodash.map( vm.polylinesTree, ( item ) => {
        return {
          label: vm.showNodeIds ? `${item.name} - ${item.id}` : item.name,
          id: item.id,
          name: item.name,
        };
      } )
    }

    function showModalLineNode( node ) {
      let entity = vm.selectedUpload ? vm.selectedUpload : vm.entity;
      vm.detailEntity = lodash.find(entity.cs_polylines, { id: node.id });

      detailNodeModal = $uibModal.open({
        templateUrl: 'templates/nodeLineDetail.html',
        bindToController: true,
        scope: $scope,
        size: 'md'
      });
    }

    function showModalObjectNode( node ) {
      let entity = vm.selectedUpload ? vm.selectedUpload : vm.entity;
      vm.detailEntity = lodash.find(entity.cs_entity_datas, { id: node.id });

      if( node.firstLevelId ) {
        let parentEntity = vm.selectedUpload ? vm.selectedUpload : vm.entity;
        let parent = lodash.find(parentEntity.cs_entity_datas, { id: node.firstLevelId });
        vm.detailEntity[ 'parentGpsx' ] = parent.gpsX;
        vm.detailEntity[ 'parentGpsy' ] = parent.gpsY;
        vm.detailEntity[ 'parentId' ] = parent.id;
      }

      lodash.map(vm.detailEntity.cs_entity_attributes_data, function( item ) {
        item.valueName = item.value_lov ? item.value_lov : item.value;
      });

      detailNodeModal = $uibModal.open({
        templateUrl: 'templates/nodeDetail.html',
        bindToController: true,
        scope: $scope,
        size: 'md'
      });
    }

    function selectNodeInMap() {
      if(originalZoom && originalZoom >= 15)
        vm.map.zoom = originalZoom;
      vm.map.center = {
        latitude: vm.detailEntity.parentGpsx ? vm.detailEntity.parentGpsx  : vm.detailEntity.gpsX,
        longitude:  vm.detailEntity.parentGpsy ? vm.detailEntity.parentGpsy : vm.detailEntity.gpsY
      };

      clearSelectedNodeInMap();
      clearSelectedLineInMap();

      if( vm.detailEntity.parentId ) {
        let parentNodeIndex = lodash.findIndex( vm.tree, { id: vm.detailEntity.parentId } );
        let childrenNodeIndex = lodash.findIndex( vm.tree[parentNodeIndex].children, { id: vm.detailEntity.id } );
        lodash.set( vm.tree, `${parentNodeIndex}.children.${childrenNodeIndex}.selected`, true );
        vm.tree[parentNodeIndex].selectedChildren = true;
      } else {
        let parentNodeIndex = lodash.findIndex( vm.tree, { id: vm.detailEntity.id } );
        vm.tree[parentNodeIndex].selected = true;
      }

      const index = lodash.findIndex( vm.markers, { id: vm.detailEntity.parentId ? vm.detailEntity.parentId : vm.detailEntity.id } );
      vm.markers[index].selected = vm.markers[index].options.icon;
      vm.markers[index].options.icon = '';

      closeNodeModal()
    }

    function selectObjectByMarker(node) {
      clearSelectedNodeInMap();

      let nodeIndex = lodash.findIndex( vm.tree, { id: node.id } );
      vm.tree[nodeIndex].selected = true;
    }

    function clearSelectedNodeInMap() {
      let selectedParentNodeIndex = lodash.findIndex( vm.tree, { selected: true } );
      if(selectedParentNodeIndex !== -1) {
        delete vm.tree[selectedParentNodeIndex].selected;
      } else {
        let selectedParentNodeIndex = lodash.findIndex( vm.tree, { selectedChildren: true } );
        if( selectedParentNodeIndex !== -1 ) {
          delete vm.tree[selectedParentNodeIndex].selectedChildren;

          let selectedChildrenNodeIndex = lodash.findIndex( vm.tree[selectedParentNodeIndex].children, { selected: true } );
          if( selectedChildrenNodeIndex !== -1 )
            delete vm.tree[selectedParentNodeIndex].children[selectedChildrenNodeIndex].selected;
        }
      }

      const selectedMarkerIndex = lodash.findIndex( vm.markers, ( item ) => item.selected );
      if( selectedMarkerIndex !== -1 ) {
        vm.markers[selectedMarkerIndex].options.icon = vm.markers[selectedMarkerIndex].selected;
        delete vm.markers[selectedMarkerIndex].selected;
      }
    }

    function selectNodeLineInMap() {
      clearSelectedLineInMap();
      clearSelectedNodeInMap();
      const lines = lodash.cloneDeep(vm.polylines);

      const selectedLineIndex = lodash.findIndex( lines, { id: vm.detailEntity.id } );
      delete lines[selectedLineIndex].editable;
      lines[selectedLineIndex].stroke = { color: '#FF0000' };
      vm.polylines = lines;
      vm.polylinesTree[selectedLineIndex].selected = true;

      if(originalZoom && originalZoom >= 15)
        vm.map.zoom = originalZoom;
      vm.map.center = {
        latitude: lines[selectedLineIndex].path[0].latitude,
        longitude: lines[selectedLineIndex].path[0].longitude
      };

      closeNodeModal();
    }

    function clearSelectedLineInMap() {
      let selectedLineIndex = lodash.findIndex( vm.polylines, { stroke: { color: '#FF0000' } } );
      const selectedLineTreeIndex = lodash.findIndex( vm.polylinesTree, { selected: true } );

      if( selectedLineIndex !== -1 ) vm.polylines[selectedLineIndex] = {
        ...vm.polylines[selectedLineIndex],
        stroke: { color: '#000000' }
      };

      if( selectedLineTreeIndex !== -1 ) vm.polylinesTree[selectedLineTreeIndex].selected = false;
    }

    function showModalStatus( status ) {
      vm.statusApproved = status;

      changeStatusModal = $uibModal.open({
        templateUrl: 'templates/changeStatus.html',
        bindToController: true,
        scope: $scope,
        size: 'md'
      });
    }

    function showWorkMarkers() {
      let entity = vm.workMarkers ? vm.entity : vm.selectedUpload;
      formatDataForMap(entity);
    }

    $scope.$on('trvwClick', function( event, node ) {
      if( node.type === 'polyline' ) {
        showModalLineNode(node)
      } else {
        showModalObjectNode(node)
      }
    });

    function closeEditModal() {
        editModal.dismiss();
    }

    function openEditModal( entity ) {
        vm.entityToEdit = Object.assign({}, entity, { id: !lodash.isUndefined(entity) });

        vm.entityToEdit.responsible = vm.entityToEdit.responsible.toString();
        vm.entityToEdit.work_group = vm.entityToEdit.work_group.toString();

        editModal = $uibModal.open({
            templateUrl: 'templates/workEdit.html',
            binController: true,
            scope: $scope,
            size: 'md'
        })
    }

    function saveEntity( form ) {
        if( form.$valid ) {
            vm.isLoading = true;

            vm.entity.responsible = vm.entityToEdit.responsible != '-1' ? vm.entityToEdit.responsible : '';
            vm.entity.workGroup = vm.entityToEdit.work_group != '-1' ? vm.entityToEdit.work_group : '';

            const promise = workDataService.updateWork(vm.entityId, vm.entity);

            promise.then(function( response ) {
                if( response.status === 201 || response.status === 200 ) {
                    vm.entity = response.data;
                    editModal.dismiss();

                    if(vm.entity.responsible)
                    {
                        let responsible = lodash.find(vm.users, { id: vm.entity.responsible });
                        vm.entity.responsible_name = responsible ? responsible.name + ' ' + responsible.lastname : '---';
                    }
                    else
                        vm.entity.responsible_name = '---';

                    if(vm.entity.work_group)
                    {
                        let group = lodash.find(vm.groups, { id: vm.entity.work_group });
                        vm.entity.group_name = group ? group.name : '---';
                    }
                    else
                        vm.entity.group_name = '---';

                    vm.isLoading = false;
                    $scope.showNotification('Data Saved');
                } else {
                    vm.isLoading = false;
                    $scope.showNotification('The action could not be performed successfully', 'error');
                }
            })
        }
    }

    function getGroups() {
        groupDataService.getGroups({ params: {limit: 1000, offset: 0}}).then(function( response ) {
            if( response.status === 200 ) {
                vm.groups = response.data.data;

                if(vm.entity.responsible)
                {
                    let responsible = lodash.find(vm.users, { id: parseInt(vm.entity.responsible) });
                    vm.entity.responsible_name = responsible ? responsible.name + ' ' + responsible.lastname : '---';
                }
                else
                    vm.entity.responsible_name = '---';

                if(vm.entity.work_group)
                {
                    let group = lodash.find(vm.groups, { id: parseInt(vm.entity.work_group) });
                    vm.entity.group_name = group ? group.name : '---';
                }
                else
                    vm.entity.group_name = '---';

                vm.isLoading = false;
            }
            else {
                vm.isLoading = false;
                $scope.showNotification('The groups could not be loaded', 'error');
            }
        });
    }

    function getUsers() {
        userDataService.getUsers({ params: {limit: 1000, offset: 0}}).then(function( response ) {
            if( response.status === 201 ) {
                vm.users = response.data;

                getGroups();
            }
            else {
                vm.isLoading = false;
                $scope.showNotification('The users could not be loaded', 'error');
            }
        });
    }
  }
})();
