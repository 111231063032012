(function() {
  'use strict';

  angular
    .module('app')
    .directive('refreshTable', refreshTable);

  function refreshTable() {
    return {
      restrict: 'A',
      require: '^stTable',
      link: link
    };

    function link( scope, elem, attr, table ) {
      scope.$on("refreshTable", function() {
        table.pipe(table.tableState());
      });
    }
  }
})();
