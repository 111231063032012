(function() {
  'use strict';

  angular
    .module('app.entityObject')
    .controller('ValueEditController', ValueEditController);

  ValueEditController.$inject = [
    'entityId',
    'entityParentId',
    '$uibModalInstance',
    'valueDataService'
  ];

  function ValueEditController( entityId,
                                entityParentId,
                                $uibModalInstance,
                                valueDataService ) {
    let vm = this;

    vm.cancel = cancel;
    vm.entity = {};
    vm.entityId = entityId;
    vm.onSubmit = onSubmit;

    init();

    function init() {
      if( entityId ) {
        getValue()
      } else {
        vm.entity[ 'csLov' ] = entityParentId;
      }
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }

    function getValue() {
      valueDataService.getValue(entityId).then(function( response ) {
        if( response.status === 200 ) {
          vm.entity = response.data;
          vm.entity[ 'csLov' ] = entityParentId;
        }
        else {
          vm.isLoading = false;
        }
      });
    }

    function onSubmit( form ) {
      if( !form.$invalid ) {
        $uibModalInstance.close(vm.entity);
      }
    }
  }
})();
