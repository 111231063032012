(function() {
  'use strict';

  angular
    .module('app')
    .factory('authInterceptor', authInterceptor);

  authInterceptor.$inject = [
    '$q',
    '$injector',
    'webStorageService'
  ];

  function authInterceptor( $q, $injector, webStorageService ) {
    return {
      request: function( config ) {
        if( webStorageService.getObject('session').access_token && config.url.indexOf('translate') === -1) {
          config.headers.authorization = 'Bearer ' + webStorageService.getObject('session').access_token;
        }
        return config || $q.when(config);
      },
      responseError: function( rejection ) {
        if( rejection.status === 401 ) {
          webStorageService.removeItem('session');
          let stateService = $injector.get('$state');
          stateService.transitionTo('login', { reload: true, inherit: false });
        }
        return $q.reject(rejection);
      }
    }
  }
})();
