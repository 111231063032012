(function() {
  'use strict';

  angular
    .module( 'app.dashboard' )
    .controller( 'DashboardController', DashboardController );

  DashboardController.$inject = [
    'userDataService',
    'entityObjectDataService',
    'lovDataService',
    'workDataService',
    'incmsDataService',
    '$scope',
    'helpers',
    'workService'
  ];

  function DashboardController( userDataService, entityObjectDataService, lovDataService, workDataService,
                                incmsDataService, $scope, helpers, workService ) {
    let vm = this;
    vm.works = 0;
    vm.workUploads = 0;
    vm.workDownloads = 0;
    vm.users = 0;
    vm.entityObjects = 0;
    vm.lovs = 0;
    vm.lengthOptions = workService.getLengthOptions();
    vm.numberPerPages = 20;

    init();

    function init() {
      vm.isLoading = true;

      incmsDataService.getAllAmounts().then( function( response ) {
        if( response.status !== 200 ) {
          vm.isLoading = false;
          return;
        }

        vm.works = response.data.works;
        vm.workUploads = response.data.worksUploaded;
        vm.workDownloads = response.data.worksDownloaded;
        vm.users = response.data.users;
        vm.entityObjects = response.data.entityObjects;
        vm.lovs = response.data.lovs;

        vm.isLoading = false;
      } );
    }
  }
})();
