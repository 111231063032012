(function() {
  'use strict';

  angular
    .module('app.entityObject')
    .controller('ChildrenCreateController', ChildrenCreateController);

  ChildrenCreateController.$inject = [
    'entityObjectDataService',
    'entityParentId',
    'inUse',
    '$uibModalInstance'
  ];

  function ChildrenCreateController(
    entityObjectDataService,
    entityParentId,
    inUse,
    $uibModalInstance
  ) {
    let vm = this;

    vm.cancel = cancel;
    vm.onSubmit = onSubmit;
    vm.entity = { csEntityObject: entityParentId };

    init();

    function init() {
      getEntityObjects();
    }

    function getEntityObjects() {
      entityObjectDataService.getEntityObjects().then(function( response ) {
        if( response.status === 200 ) {
          vm.entityObjects = response.data.data;
          vm.entityObjects = vm.entityObjects.filter(item => {
            return inUse.indexOf(item.id) === -1
          })
        }
      });
    }

    function cancel() {
      $uibModalInstance.dismiss('close');
    }

    function onSubmit( form ) {
      if( !form.$invalid ) {
        $uibModalInstance.close(vm.entity);
      }
    }
  }
})();
