(function() {
    'use strict';

    angular
        .module('app')
        .service('groupDataService', groupDataService);

    groupDataService.$inject = [
        'config',
        '$http'
    ];

    function groupDataService( config, $http ) {

        return {
            getGroups: getGroups,
            getGroup: getGroup,
            saveGroup: saveGroup,
            updateGroup: updateGroup,
            deleteGroup: deleteGroup,
        };

        function getGroups( params ) {
            return $http.get(config.endpoints.group, params)
                .then(function( response ) {
                    return { data: response.data, status: response.status };
                })
                .catch(function( error ) {
                    return { status: error.status, message: error.data.message };
                });
        }

        function getGroup( entityId ) {
            return $http.get(`${config.endpoints.group}/${entityId}`)
                .then(function( response ) {
                    return { data: response.data, status: response.status };
                })
                .catch(function( error ) {
                    return { status: error.status, message: error.data.message };
                });
        }

        function saveGroup( data ) {
            return $http.post(`${config.endpoints.group}`, data)
                .then(function( response ) {
                    return { data: response.data, status: response.status };
                })
                .catch(function( error ) {
                    return { status: error.status, message: error.data.message };
                });
        }

        function updateGroup( entityId, data ) {
            return $http.put(`${config.endpoints.group}/${entityId}`, data)
                .then(function( response ) {
                    return { data: response.data, status: response.status };
                })
                .catch(function( error ) {
                    return { status: error.status, message: error.data.message };
                });
        }

        function deleteGroup( entityId ) {
            return $http.delete(`${config.endpoints.group}/${entityId}`)
                .then(function( response ) {
                    return { data: response.data, status: response.status };
                })
                .catch(function( error ) {
                    return { status: error.status, message: error.data.message };
                });
        }
    }
})();
