(function() {
  'use strict';

  angular
    .module( 'app.lovMeter' )
    .controller( 'LovMeterEditController', LovMeterEditController );

  LovMeterEditController.$inject = [
    'lovMeterDataService',
    '$scope',
    '$stateParams'
  ];

  function LovMeterEditController( lovMeterDataService, $scope, $stateParams ) {
    let vm = this;

    vm.entityId = $stateParams.id;
    vm.isLoading = true;
    vm.onSubmit = onSubmit;

    init();

    function init() {
      if( vm.entityId ) {
        getLovMeter()
      } else {
        vm.entity = { lovInUse: false };
        vm.isLoading = false
      }
    }

    function getLovMeter() {
      lovMeterDataService.getLovMeter( vm.entityId ).then( function( response ) {
        if( response.status === 200 ) {
          vm.entity = response.data;
          vm.isLoading = false
        }
        else {
            vm.isLoading = false;
            $scope.showNotification('The data could not be loaded', 'error');
        }
      } );
    }

    function onSubmit( form ) {
      if( !form.$invalid ) {
        vm.isLoading = true;

        const promise = vm.entityId ? lovMeterDataService.updateLovMeter( vm.entityId, vm.entity ) :
          lovMeterDataService.saveLovMeter( vm.entity );

        promise.then( function( response ) {
          vm.isLoading = false;
          if( response.status === 201 || response.status === 200 ) {
            vm.entity = response.data;
            $scope.showNotification( 'Data Saved' );
          }
          else {
              vm.isLoading = false;
              $scope.showNotification('The action could not be performed successfully', 'error');
          }
        } )
      }
    }
  }
})();
