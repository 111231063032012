(function() {
  'use strict';

  angular
    .module('app.lov', [])
    .config(config);

  config.$inject = [
    '$stateProvider'
  ];

  function config( $stateProvider ) {
    $stateProvider
      .state('app.lov', {
        url: '/lov',
        views: {
          'content': {
            templateUrl: 'templates/lov.html',
            controller: 'LovController as lov'
          }
        },
        ncyBreadcrumb: {
          label: 'lov'
        }
      })
      .state('app.lovCreate', {
        url: '/lov/create',
        views: {
          'content': {
            templateUrl: 'templates/lovEdit.html',
            controller: 'LovEditController as lovEdit'
          }
        },
        ncyBreadcrumb: {
          parent: 'app.lov',
          label: ' '
        }
      })
      .state('app.lovEdit', {
        url: '/lov/edit/{id:int}',
        views: {
          'content': {
            templateUrl: 'templates/lovEdit.html',
            controller: 'LovEditController as lovEdit'
          }
        },
        ncyBreadcrumb: {
          parent: 'app.lov',
          label: 'edit'
        }
      });
  }
})();
