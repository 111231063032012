(function() {
  'use strict';

  angular
    .module('app')
    .controller('TableActionsController', TableActionsController)
    .component('tableActions', {
      bindings: {
        deleteAction: '&',
        editAction: '&',
        entityId: '<',
        editRoute: '@',
        action: '@',
        type: '@',
        label: '@'
      },
      controller: 'TableActionsController',
      templateUrl: 'components/tableActions/tableActions.html'
    });

  TableActionsController.$inject = [
    'config',
    'SweetAlert',
    '$translate'
  ];

  function TableActionsController( config, SweetAlert, $translate ) {
    let vm = this;

    vm.showDeleteModal = showDeleteModal;
    vm.onEdit = onEdit;
    vm.$onInit = onInit;

    function onInit() {
      $translate([ 'tableDeleteConfirmation', 'tableDeleteMessage', 'tableDeleteConfirmButton', 'tableDeleteCancelButton' ])
        .then(function( translations ) {
          vm.tableDeleteConfirmation = translations.tableDeleteConfirmation;
          vm.tableDeleteMessage = translations.tableDeleteMessage;
          vm.tableDeleteConfirmButton = translations.tableDeleteConfirmButton;
          vm.tableDeleteCancelButton = translations.tableDeleteCancelButton;
        });
    }

    function onEdit() {
      vm.editAction({ entityId: vm.entityId })
    }

    function showDeleteModal() {
      SweetAlert.swal({
        title: vm.tableDeleteConfirmation,
        text: `${vm.tableDeleteMessage} ${vm.type}: ${vm.label}`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: config.colors.danger,
        confirmButtonText: vm.tableDeleteConfirmButton,
        cancelButtonText: vm.tableDeleteCancelButton,
        closeOnConfirm: true,
        closeOnCancel: true
      }, function( isConfirm ) {
        if( isConfirm ) {
          vm.deleteAction({ entityId: vm.entityId })
        }
      });
    }
  }
})();
