(function() {
  'use strict';

  angular
    .module('app.work', [])
    .config(config);

  config.$inject = [
    '$stateProvider'
  ];

  function config( $stateProvider ) {
    $stateProvider
      .state('app.work', {
        url: '/work',
        views: {
          'content': {
            templateUrl: 'templates/work.html',
            controller: 'WorkController as work'
          }
        },
        ncyBreadcrumb: {
          label: 'work'
        }
      })
      .state('app.workDetail', {
        url: '/work/{id:int}',
        views: {
          'content': {
            templateUrl: 'templates/workDetail.html',
            controller: 'WorkDetailController as workDetail'
          }
        },
        ncyBreadcrumb: {
          parent: 'app.work',
          label: 'detail'
        }
      });
  }
})();
