(function() {
  'use strict';

  angular
    .module('app')
    .service('entityObjectService', entityObjectService);

  entityObjectService.$inject = [];

  function entityObjectService() {

    return {
      getLengthOptions: getLengthOptions,
      getTopologyOptions: getTopologyOptions,
      getTypeOptions: getTypeOptions,
      getMarkerOptions: getMarkerOptions,
    };

    function getLengthOptions() {
      return [
        { value: 20, label: 20 },
        { value: 40, label: 40 },
        { value: 60, label: 60 },
        { value: 100000, label: 'All' }
      ]
    }

    function getMarkerOptions() {
      return [
        { id: 'default', name: '<img src="images/markers/default@2x.png" style="width: 20px" /> Default' },
        { id: 'marker1', name: '<img src="images/markers/marker1@2x.png" style="width: 20px" /> Marker 1' },
        { id: 'marker2', name: '<img src="images/markers/marker2@2x.png" style="width: 20px" /> Marker 2' },
        { id: 'marker3', name: '<img src="images/markers/marker3@2x.png" style="width: 20px" /> Marker 3' },
        { id: 'marker4', name: '<img src="images/markers/marker4@2x.png" style="width: 20px" /> Marker 4' },
        { id: 'marker5', name: '<img src="images/markers/marker5@2x.png" style="width: 20px" /> Marker 5' },
        { id: 'tower1', name: '<img src="images/markers/tower1@2x.png" style="width: 20px" /> Tower 1' },
        { id: 'tower2', name: '<img src="images/markers/tower2@2x.png" style="width: 20px" /> Tower 2' },
        { id: 'tower3', name: '<img src="images/markers/tower3@2x.png" style="width: 20px" /> Tower 3' },
        { id: 'tower4', name: '<img src="images/markers/tower4@2x.png" style="width: 20px" /> Tower 4' },
        { id: 'substation1', name: '<img src="images/markers/substation1@2x.png" style="width: 20px" /> Substation 1' },
        { id: 'substation2', name: '<img src="images/markers/substation2@2x.png" style="width: 20px" /> Substation 2' },
      ]
    }

    function getTopologyOptions() {
      return [
        { id: 'Node', name: 'Node' },
        { id: 'Line', name: 'Line' },
      ]
    }

    function getTypeOptions() {
      return [
        { id: 'Alphanumerical', name: 'Alphanumerical' },
        { id: 'Graphical', name: 'Graphical' },
      ]
    }
  }
})();
