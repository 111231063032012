( function() {
  'use strict';

  angular
    .module( 'app.group', [] )
    .config( config );

  config.$inject = [
    '$stateProvider'
  ];

  function config( $stateProvider ) {
    $stateProvider
      .state( 'app.group', {
        url: '/groups',
        views: {
          'content': {
            templateUrl: 'templates/group.html',
            controller: 'GroupController as group'
          }
        },
        ncyBreadcrumb: {
          label: 'groups'
        }
      } )
      .state( 'app.groupCreate', {
        url: '/groups/create',
        views: {
          'content': {
            templateUrl: 'templates/groupEdit.html',
            controller: 'GroupEditController as groupEdit'
          }
        },
        ncyBreadcrumb: {
          parent: 'app.group',
          label: 'create'
        }
      } )
      .state( 'app.groupEdit', {
        url: '/groups/edit/{id:int}',
        views: {
          'content': {
            templateUrl: 'templates/groupEdit.html',
            controller: 'GroupEditController as groupEdit'
          }
        },
        ncyBreadcrumb: {
          parent: 'app.group',
          label: 'edit'
        }
      } );
  }
} )();
