(function() {
  'use strict';

  angular
    .module('app.user')
    .controller('UserEditController', UserEditController);

  UserEditController.$inject = [
    '$scope',
    '$stateParams',
    'userDataService',
    'groupDataService',
    'userService',
  ];

  function UserEditController(
    $scope,
    $stateParams,
    userDataService,
    groupDataService,
    userService
  ) {
    let vm = this;

    vm.entity = { roles: [] };
    vm.entityId = $stateParams.id;
    vm.isLoading = true;
    vm.onSubmit = onSubmit;

    init();

    function init() {
      if( vm.entityId ) {
        getUser();
      } else {
        getRoles();
      }
    }

    function getRoles() {
      vm.roles = userService.getUserRoles();

      getGroups();
    }

    function getGroups() {
        groupDataService.getGroups({ params: {limit: 1000, offset: 0}}).then(function( response ) {
            if( response.status === 200 ) {
                vm.groups = response.data.data;

                vm.isLoading = false;
            }
            else {
                vm.isLoading = false;
                $scope.showNotification('The groups could not be loaded', 'error');
            }
        });
    }

    function getUser() {
      userDataService.getUser(vm.entityId).then(function( response ) {
        if( response.status === 200 ) {
          vm.entity = response.data;
          vm.entity.user_group = vm.entity.user_group ? vm.entity.user_group.id : '';

          getRoles();
        }
        else {
            vm.isLoading = false;
            $scope.showNotification('The data could not be loaded', 'error');
        }
      });
    }

    function onSubmit( form ) {
      if( !form.$invalid ) {
        vm.isLoading = true;

        vm.entity.userGroup = vm.entity.user_group=='-1' ? '' : vm.entity.user_group;

        const promise = vm.entityId ? userDataService.updateUser(vm.entityId, vm.entity) :
          userDataService.saveUser(vm.entity);

        promise.then(function( response ) {
          if( response.status === 201 || response.status === 200 ) {
            vm.entity.id = response.data.id;
              vm.isLoading = false;
            $scope.showNotification('Data Saved');
          } else {
              vm.isLoading = false;
              $scope.showNotification('The action could not be performed successfully', 'error');
          }

        })
      }
    }
  }
})();
