(function() {
  'use strict';

  angular
    .module('app.lov')
    .controller('LovController', LovController);

  LovController.$inject = [
    'helpers',
    'lovDataService',
    'lovService',
    '$scope',
    '$uibModal'
  ];

  function LovController( helpers, lovDataService, lovService, $scope, $uibModal ) {
    let vm = this;

    vm.deleteLov = deleteLov;
    vm.getLovs = getLovs;
    vm.isLoading = true;
    vm.lengthOptions = lovService.getLengthOptions();
    vm.numberPerPages = 20;
    vm.openDetailModal = openDetailModal;

    init();

    function init() {}

    function deleteLov( entityId ) {
      vm.isLoading = true;

      lovDataService.deleteLov(entityId).then(function( response ) {
        if( response.status === 204 ) {
          $scope.$broadcast('refreshTable');
        }
        else {
            vm.isLoading = false;
            $scope.showNotification('The action could not be performed successfully', 'error');
        }
      });
    }

    function getLovs( tableState ) {
      vm.tableIsLoading = true;
      vm.params = helpers.buildParams(tableState, vm.numberPerPages);

      lovDataService.getLovs( { params: vm.params } ).then( function( response ) {
        if( response.status === 200 ) {
          const total = parseInt( response.data.total );
          vm.data = response.data.data;
          tableState.pagination.numberOfPages = helpers.calculateNumberPages( total, vm.numberPerPages );
          tableState.pagination.totalItemCount = total;
          vm.tableIsLoading = false;
          vm.isLoading = false;
        }
        else {
            vm.isLoading = false;
            $scope.showNotification('The data could not be loaded', 'error');
        }
      } );
    }

    function openDetailModal( entity ) {
      $uibModal.open({
        templateUrl: 'templates/lovDetail.html',
        controller: 'LovDetailController',
        controllerAs: 'lovDetail',
        size: 'md',
        resolve: {
          entityId: function() {
            return entity.id;
          }
        }
      });
    }
  }
})();
