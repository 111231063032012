(function() {
  'use strict';

  angular
    .module('app.entityObject')
    .controller('EntityObjectEditController', EntityObjectEditController);

  EntityObjectEditController.$inject = [
    'attributeDataService',
    'dependentDataService',
    'entityObjectDataService',
    'entityObjectService',
    'lovDataService',
    '$scope',
    '$stateParams',
    '$uibModal'
  ];

  function EntityObjectEditController(
    attributeDataService,
    dependentDataService,
    entityObjectDataService,
    entityObjectService,
    lovDataService,
    $scope,
    $stateParams,
    $uibModal
  ) {
    let vm = this;

    vm.attributesLengthOptions = entityObjectService.getLengthOptions();
    vm.attributesPerPages = 20;
    vm.deleteAttribute = deleteAttribute;
    vm.deleteChild = deleteChild;
    vm.dependentsLengthOptions = entityObjectService.getLengthOptions();
    vm.dependentsPerPages = 20;
    vm.entityId = $stateParams.id;
    vm.filterLovNull = filterLovNull;
    vm.isLoading = true;
    vm.numberPerPages = 1;
    vm.onSubmit = onSubmit;
    vm.openCreateChildrenModal = openCreateChildrenModal;
    vm.openDetailAttributeModal = openDetailAttributeModal;
    vm.openEditAttributeModal = openEditAttributeModal;

    init();

    function init() {
      if( vm.entityId ) {
        getEntityObject();
      } else {
        initSelects();
        vm.entity = { objecActive: 1 };
        vm.isLoading = false
      }
      getLovs();

      vm.markerSelectOptions = {
        allowClear: true,
        escapeMarkup: function( data ) {
          return data
        }
      };
    }

    function filterLovNull( item ) {
      return !item.lov
    }

    function initSelects() {
      vm.topologyOptions = entityObjectService.getTopologyOptions();
      vm.typeOptions = entityObjectService.getTypeOptions();
      vm.markerOptions = entityObjectService.getMarkerOptions();
    }

    function deleteAttribute( entityId ) {
      vm.isLoading = true;

      attributeDataService.deleteAttribute(entityId).then(function( response ) {
        if( response.status === 204 ) {
          getEntityObject()
        }
        else {
            vm.isLoading = false;
            $scope.showNotification('The action could not be performed successfully', 'error');
        }
      });
    }

    function deleteChild( entityId ) {
      vm.isLoading = true;

      dependentDataService.deleteDependent(entityId).then(function( response ) {
        if( response.status === 204 ) {
          getEntityObject()
        }
        else {
            vm.isLoading = false;
            $scope.showNotification('The action could not be performed successfully', 'error');
        }
      });
    }

    function getEntityObject() {
      entityObjectDataService.getEntityObject(vm.entityId).then(function( response ) {
        if( response.status === 200 ) {
          vm.entity = response.data;
          vm.entity.identifierAttr = vm.entity.identifierAttr ? vm.entity.identifierAttr.id : null;
          initSelects();
          vm.isLoading = false;
        }
      });
    }

    function getLovs() {
      lovDataService.getLovs({params: { 'filters[lovInUse]': 1, limit: -1 }}).then(function( response ) {
        if( response.status === 200 ) {
          vm.lovs = response.data.data;
        }
        else {
            vm.isLoading = false;
        }
      });
    }

    function onSubmit( form ) {
      if( !form.$invalid ) {
        vm.isLoading = true;

        const promise = vm.entityId ? entityObjectDataService.updateEntityObject(vm.entityId, vm.entity) :
          entityObjectDataService.saveEntityObject(vm.entity);

        promise.then(function( response ) {
          if( response.status === 200 || response.status === 201 ) {
            vm.entity = response.data;
            vm.isLoading = false;
            $scope.showNotification('Data Saved');
          }
          else {
              vm.isLoading = false;
              $scope.showNotification('The action could not be performed successfully', 'error');
          }
        })
      }
    }

    function openCreateChildrenModal() {
      let usedIds = [ vm.entity.id ];

      if( vm.entity.csEntityChilds ) {
        vm.entity.csEntityChilds.map(item => usedIds.push(item.csChildEntityObject.id));
      }

      let modalInstance = $uibModal.open({
        templateUrl: 'templates/childrenCreate.html',
        controller: 'ChildrenCreateController',
        controllerAs: 'childrenCreate',
        size: 'md',
        resolve: {
          entityParentId: function() {
            return vm.entityId;
          },
          inUse: function() {
            return usedIds
          }
        }
      });

      modalInstance.result.then(function( result ) {
        if( result ) {
          vm.isLoading = true;
          dependentDataService.saveDependent(result).then(function( response ) {
            if( response.status === 201 ) {
              getEntityObject()
            }
          });
        }
      });
    }

    function openDetailAttributeModal( entity ) {
      $uibModal.open({
        templateUrl: 'templates/lovDetail.html',
        controller: 'LovDetailController',
        controllerAs: 'lovDetail',
        size: 'md',
        resolve: {
          entityId: function() {
            return entity.id;
          }
        }
      });
    }

    function openEditAttributeModal( entityId ) {
      let modalInstance = $uibModal.open({
        templateUrl: 'templates/attributeEdit.html',
        controller: 'AttributeEditController',
        controllerAs: 'attributeEdit',
        size: 'md',
        resolve: {
          entityId: function() {
            return entityId;
          },
          lovs: function() {
            return vm.lovs
          },
          entityParentId: function() {
            return vm.entityId;
          }
        }
      });

      modalInstance.result.then(function( result ) {
        if( result ) {
          vm.isLoading = true;
          if(result.id) {
            attributeDataService.updateAttribute(result.id, result).then(function( response ) {
              if( response.status === 200 ) {
                getEntityObject()
              }
              else {
                  vm.isLoading = false;
                  $scope.showNotification('The action could not be performed successfully', 'error');
              }
            });
          } else {
            attributeDataService.saveAttribute(result).then(function( response ) {
              if( response.status === 201 ) {
                getEntityObject()
              }
              else {
                  vm.isLoading = false;
                  $scope.showNotification('The action could not be performed successfully', 'error');
              }
            });
          }
        }
      });
    }
  }
})();
