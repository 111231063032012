(function() {
  'use strict';

  angular
    .module('app')
    .service('entityObjectDataService', entityObjectDataService);

  entityObjectDataService.$inject = [
    'config',
    '$http'
  ];

  function entityObjectDataService( config, $http ) {

    return {
      deleteEntityObject: deleteEntityObject,
      getEntityObject: getEntityObject,
      getEntityObjects: getEntityObjects,
      getEntityObjectsCount: getEntityObjectsCount,
      saveEntityObject: saveEntityObject,
      updateEntityObject: updateEntityObject,
    };

    function deleteEntityObject( entityId ) {
      return $http.delete(`${config.endpoints.entityObject}/${entityId}`)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function getEntityObject( entityId ) {
      return $http.get(`${config.endpoints.entityObject}/${entityId}`)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function getEntityObjects( params ) {
      return $http.get(config.endpoints.entityObject, params)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function getEntityObjectsCount() {
      return $http.get(config.endpoints.entityObject, { params: { count: 1 } })
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function saveEntityObject( data ) {
      return $http.post(`${config.endpoints.entityObject}`, data)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function updateEntityObject( entityId, data ) {
      return $http.put(`${config.endpoints.entityObject}/${entityId}`, data)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }
  }
})();
