(function() {
  'use strict';

  angular
    .module('app')
    .service('workDataService', workDataService);

  workDataService.$inject = [
    'config',
    '$http'
  ];

  function workDataService( config, $http ) {

    return {
      deleteWork: deleteWork,
      getWork: getWork,
      getWorks: getWorks,
      getWorkUpload: getWorkUpload,
      getWorksCount: getWorksCount,
      updateWorkStatus: updateWorkStatus,
      updateWork: updateWork,
      getWorksDone: getWorksDone
    };

    function deleteWork( entityId ) {
      return $http.delete(`${config.endpoints.work}/${entityId}`)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function getWork( entityId ) {
      return $http.get(`${config.endpoints.work}/${entityId}/objectdependecy`)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function getWorks( params ) {
      return $http.get(`${config.endpoints.work}/works/timeline`, params)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function getWorkUpload( uploadId ) {
      return $http.get(`${config.endpoints.work}/${uploadId}/objectdependecy`)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function getWorksCount() {
      return $http.get(config.endpoints.work, { params: { count: 1 } })
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function updateWorkStatus( entityId, data ) {
      return $http.put(`${config.endpoints.work}/${entityId}/status`, data)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function updateWork( entityId, data ) {
        return $http.put(`${config.endpoints.work}/${entityId}`, data)
            .then(function( response ) {
                return { data: response.data, status: response.status };
            })
            .catch(function( error ) {
                return { status: error.status, message: error.data.message };
            });
    }

    function getWorksDone(params) {
        return $http.get(`${config.endpoints.work}/dones/works`, params)
            .then(function( response ) {
                return { data: response.data, status: response.status };
            })
            .catch(function( error ) {
                return { status: error.status, message: error.data.message };
            });
    }
  }
})();
