(function() {
  'use strict';

  angular
    .module( 'app' )
    .directive( 'searchSelect', searchSelect );

  function searchSelect() {
    return {
      restrict: 'E',
      scope: {
        predicate: '@',
        options: '<',
      },
      require: '^stTable',
      templateUrl: 'directives/searchSelect/searchSelect.html',
      link: link
    };

    function link( scope, element, attr, ctrl ) {
      scope.change = function change() {
        ctrl.search( this.selectedOption, scope.predicate );
      }
    }
  }
})();
