(function() {
  'use strict';

  angular
    .module('app')
    .directive('passwordCheck', passwordCheck);

  function passwordCheck() {

    return {
      restrict: 'A',
      require: '?ngModel',
      link: link
    };

    function link( scope, elem, attrs, ngModel ) {
      if( !ngModel ) return;

      scope.$watch(attrs.ngModel, function() {
        validate();
      });

      attrs.$observe('passwordCheck', function( val ) {
        validate()
      });

      function validate() {
        ngModel.$setValidity('passwordMatch', ngModel.$viewValue === attrs.passwordCheck);
      }
    }
  }
})();
