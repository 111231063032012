(function() {
  'use strict';

  angular
    .module('app')
    .service('settingsDataService', settingsDataService);

  settingsDataService.$inject = [
    'config',
    '$http'
  ];

  function settingsDataService( config, $http ) {

    return {
      getSettings: getSettings,
      updateSettings: updateSettings,
    };

    function getSettings( entityId ) {
      return $http.get(`${config.endpoints.settings}/${entityId}/config`)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }

    function updateSettings( entityId, data ) {
      return $http.put (`${config.endpoints.settings}/${entityId}`, data)
        .then(function( response ) {
          return { data: response.data, status: response.status };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.message };
        });
    }
  }
})();
