( function() {
  'use strict';

  angular
    .module( 'app', [
      'ui.router',
      'ngAnimate',
      'ngSanitize',
      'ui.bootstrap',
      'ngTouch',
      'ui.jq',
      'ui.select2',
      'ngLodash',
      'oitozero.ngSweetAlert',
      'ui.bootstrap.datetimepicker',
      'ivh.treeview',
      'pascalprecht.translate',
      'ncy-angular-breadcrumb',
      'uiGmapgoogle-maps',
      'smart-table',
      'ngNumeraljs',
      'mp.colorPicker',
      'app.templates',
      'app.dashboard',
      'app.entityObject',
      'app.login',
      'app.lov',
      'app.lovMeter',
      'app.profile',
      'app.settings',
      'app.user',
      'app.work',
      'app.publicDashboard',
      'app.group',
      'app.reports',
    ] );
} )();
