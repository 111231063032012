(function() {
  'use strict';

  angular
    .module('app.login')
    .controller('LoginController', LoginController);

  LoginController.$inject = [
    'lodash',
    '$scope',
    'securityDataService',
    'userDataService',
    '$state',
    '$translate',
    'webStorageService',
    '$window'
  ];

  function LoginController( lodash, $scope, securityDataService, userDataService, $state, $translate, webStorageService, $window ) {
    let vm = this;
    vm.submitForm = submitForm;
    vm.setLang = setLang;

    vm.languages = [
      { key: 'en', 'lang': 'English' },
      { key: 'es', 'lang': 'Español' },
      { key: 'pt', 'lang': 'Português' }
    ];

    init()

    function init() {
      let langKey = webStorageService.get('language') || $translate.proposedLanguage();
      vm.selectedLanguage = lodash.filter(vm.languages, { key: langKey })[ 0 ]
    }

    function setLang( langKey ) {
      $scope.setLang(langKey);
      vm.selectedLanguage = lodash.find(vm.languages, { key: langKey })
    }

    function submitForm( form ) {
      vm.error = false;

      if( !form.$invalid ) {
        securityDataService.login( { user: vm.user, password: vm.password } ).then( function( response ) {
          if( response.status === 200 ) {

            userDataService.getUsers( { params: { me: true } } )
              .then( function( response ) {
                if( response.status === 201 ) {
                  const session = { ...webStorageService.getObject( 'session' ), roles: response.data.roles };
                  webStorageService.setObject( 'session', session );

                  $state.go( 'app.dashboard' ).then(() => {
                    $window.location.reload();
                  });
                } else {
                  $translate( 'loginError' ).then( function( translation ) {
                    vm.error = translation
                  } )
                }
              } );
            } else {
            $translate( 'loginError' ).then( function( translation ) {
              vm.error = translation
            } )
          }
        } );
      }
    }
  }
})();
