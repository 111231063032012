(function() {
  'use strict';

  angular
    .module('app')
    .service('profileDataService', profileDataService);

  profileDataService.$inject = [
    'config',
    'helpers',
    '$http'
  ];

  function profileDataService( config, helpers, $http ) {

    return {
      getUserProfile: getUserProfile
    };

    function getUserProfile() {
      return $http.get(config.endpoints.user, { params: { me: true } })
        .then(function( response ) {
          return { status: response.status, data: response.data };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.message };
        });
    }
  }
})();
