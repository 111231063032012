(function() {
    'use strict';

    angular
        .module('app')
        .service('incmsDataService', incmsDataService);

    incmsDataService.$inject = [
        'config',
        '$http'
    ];

    function incmsDataService( config, $http ) {

        return {
            getAllAmounts: getAllAmounts
        };

        function getAllAmounts() {
            return $http.get(`${config.endpoints.incms}/all/amounts`)
                .then(function( response ) {
                    return { status: response.status, data: response.data };
                })
                .catch(function( error ) {
                    return { status: error.status, message: error.message };
                });
        }
    }
})();
