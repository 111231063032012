(function() {
  'use strict';

  angular
    .module( 'app.lovMeter', [] )
    .config( config );

  config.$inject = [
    '$stateProvider'
  ];

  function config( $stateProvider ) {
    $stateProvider
      .state( 'app.lovMeter', {
        url: '/lov-meter',
        views: {
          'content': {
            templateUrl: 'templates/lovMeter.html',
            controller: 'LovMeterController as lov'
          }
        },
        ncyBreadcrumb: {
          label: 'lovMeter'
        }
      } )
      .state( 'app.lovMeterCreate', {
        url: '/lov-meter/create',
        views: {
          'content': {
            templateUrl: 'templates/lovMeterEdit.html',
            controller: 'LovMeterEditController as lovEdit'
          }
        },
        ncyBreadcrumb: {
          parent: 'app.lovMeter',
          label: ' '
        }
      } )
      .state( 'app.lovMeterEdit', {
        url: '/lov-meter/edit/{id:int}',
        views: {
          'content': {
            templateUrl: 'templates/lovMeterEdit.html',
            controller: 'LovMeterEditController as lovEdit'
          }
        },
        ncyBreadcrumb: {
          parent: 'app.lovMeter',
          label: 'edit'
        }
      } );
  }
})();
