(function() {
  'use strict';

  angular
    .module( 'app.dashboard' )
    .controller( 'PublicDashboardController', PublicDashboardController );

  PublicDashboardController.$inject = [];

  function PublicDashboardController() {
    
  }
})();
