(function() {
  'use strict';

  angular
    .module('app')
    .controller('LovDetailController', LovDetailController);

  LovDetailController.$inject = [
    'entityId',
    'lovDataService',
    'lovService',
    '$uibModalInstance'
  ];

  function LovDetailController(
    entityId,
    lovDataService,
    lovService,
    $uibModalInstance
  ) {
    let vm = this;

    vm.cancel = cancel;
    vm.isLoading = true;
    vm.valueslengthOptions = lovService.getDetailLengthOptions();
    vm.valuesPerPages = 10;

    init();

    function init() {
      getLov();
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }

    function getLov() {
      lovDataService.getLov(entityId).then(function( response ) {
        if( response.status === 200 ) {
          vm.entity = response.data;
          vm.isLoading = false;
        }
        else {
            vm.isLoading = false;
        }
      });
    }
  }
})();
