(function() {
  'use strict';

  angular
    .module('app.entityObject')
    .controller('AttributeEditController', AttributeEditController);

  AttributeEditController.$inject = [
    'attributeDataService',
    'attributeService',
    'entityId',
    'entityParentId',
    'lodash',
    'lovs',
    '$uibModalInstance'
  ];

  function AttributeEditController(
    attributeDataService,
    attributeService,
    entityId,
    entityParentId,
    lodash,
    lovs,
    $uibModalInstance
  ) {
    let vm = this;

    vm.cancel = cancel;
    vm.entity = {};
    vm.entityId = entityId;
    vm.onSubmit = onSubmit;

    init();

    function init() {
      if( entityId ) {
        getAttribute()
      } else {
        initSelects();
        vm.entity['csEntityObject'] = entityParentId;
      }
    }

    function getAttribute() {
      attributeDataService.getAttribute(entityId).then(function( response ) {
        if( response.status === 200 ) {
          vm.entity = response.data;
          vm.entity['csEntityObject'] = entityParentId;
          initSelects()
        }
      });
    }

    function initSelects() {
      vm.lovs = lovs;
      vm.typeOptions = attributeService.getTypeOptions()
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }

    function onSubmit( form ) {
      if( !form.$invalid ) {
        vm.entity.lov = lodash.get(vm.entity, 'lov.id', null);
        $uibModalInstance.close(vm.entity);
      }
    }
  }
})();
