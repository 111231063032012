(function() {
    'use strict';

    angular
        .module('app.group')
        .controller('GroupController', GroupController);

    GroupController.$inject = [
        'helpers',
        '$scope',
        '$uibModal',
        'groupDataService',
        'groupService'
    ];

    function GroupController( helpers, $scope, $uibModal, groupDataService, groupService ) {
        let vm = this;

        vm.deleteGroup = deleteGroup;
        vm.getGroups = getGroups;
        vm.isLoading = true;
        vm.lengthOptions = groupService.getLengthOptions();
        vm.numberPerPages = 20;

        init();

        function init() {}

        function deleteGroup( entityId ) {
            vm.isLoading = true;

            groupDataService.deleteGroup(entityId).then(function( response ) {
                if( response.status === 204 ) {
                    $scope.$broadcast('refreshTable');
                }
                else {
                    vm.isLoading = false;
                    $scope.showNotification('The group could not be deleted, it is possible that it is being used by a user', 'error');
                }
            });
        }

        function getGroups( tableState ) {
            vm.tableIsLoading = true;
            vm.params = helpers.buildParams(tableState, vm.numberPerPages);

            groupDataService.getGroups( { params: vm.params } ).then( function( response ) {
                if( response.status === 200 ) {
                    const total = parseInt( response.data.total );
                    vm.data = response.data.data;
                    tableState.pagination.numberOfPages = helpers.calculateNumberPages( total, vm.numberPerPages );
                    tableState.pagination.totalItemCount = total;
                    vm.tableIsLoading = false;
                    vm.isLoading = false;
                }
                else {
                    vm.isLoading = false;
                    $scope.showNotification('The data could not be loaded', 'error');
                }
            } );
        }
    }
})();
