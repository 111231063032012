(function () {
  'use strict';

  angular
    .module('app')
    .controller('UserDetailController', UserDetailController);

  UserDetailController.$inject = [
    'entity',
    '$uibModalInstance'
  ];

  function UserDetailController(
    entity,
    $uibModalInstance
  ) {
    var vm = this;

    vm.entity = entity;
    vm.entity.group_name = vm.entity.user_group ? vm.entity.user_group.name : '---';
    vm.cancel = cancel;

    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }
  }
})();
