( function() {
  'use strict';

  angular
    .module( 'app.login', [] )
    .config( config );

  config.$inject = [
    '$stateProvider'
  ];

  function config( $stateProvider ) {
    $stateProvider
      .state( 'login', {
        url: '/login',
        views: {
          'main': {
            templateUrl: 'templates/login.html',
            controller: 'LoginController as login'
          }
        },
        data: {
          appClasses: 'signin'
        }
      } );
  }
} )();
