(function() {
  'use strict';

  angular
    .module( 'app.lovMeter' )
    .controller( 'LovMeterController', LovMeterController );

  LovMeterController.$inject = [
    'helpers',
    'lovMeterDataService',
    'lovService',
    '$scope',
    '$uibModal'
  ];

  function LovMeterController( helpers, lovMeterDataService, lovService, $scope, $uibModal ) {
    let vm = this;

    vm.isLoading = true;
    vm.lengthOptions = lovService.getLengthOptions();
    vm.numberPerPages = 20;
    vm.getLovsMeter = getLovsMeter;
    vm.openDetailModal = openDetailModal;
    vm.deleteLovMeter = deleteLovMeter;

    function getLovsMeter( tableState ) {
      vm.tableIsLoading = true;
      vm.params = helpers.buildParams( tableState, vm.numberPerPages );

      lovMeterDataService.getLovsMeter( { params: vm.params } ).then( function( response ) {
        if( response.status === 200 ) {
          const total = parseInt( response.data.total );
          vm.data = response.data.data;
          tableState.pagination.numberOfPages = helpers.calculateNumberPages( total, vm.numberPerPages );
          tableState.pagination.totalItemCount = total;
          vm.tableIsLoading = false;
          vm.isLoading = false;
        }
        else {
            vm.isLoading = false;
            $scope.showNotification('The data could not be loaded', 'error');
        }
      } );
    }

    function deleteLovMeter( entityId ) {
      vm.isLoading = true;

      lovMeterDataService.deleteLovMeter( entityId ).then( function( response ) {
        if( response.status === 204 ) {
          $scope.$broadcast( 'refreshTable' );
        }
        else {
            vm.isLoading = false;
            $scope.showNotification('The action could not be performed successfully', 'error');
        }
      } );
    }

    function openDetailModal( entity ) {
      $uibModal.open( {
        templateUrl: 'templates/lovMeterDetail.html',
        controller: 'LovMeterDetailController',
        controllerAs: 'lovDetail',
        size: 'md',
        resolve: {
          entityId: function() {
            return entity.id;
          }
        }
      } );
    }
  }
})();
