(function() {
    'use strict';

    angular
        .module('app')
        .service('groupService', groupService);

    groupService.$inject = [];

    function groupService() {

        return {
            getLengthOptions: getLengthOptions
        };

        function getLengthOptions() {
            return [
                { value: 20, label: 20 },
                { value: 40, label: 40 },
                { value: 60, label: 60 },
                { value: 100000, label: 'All' }
            ]
        }
    }
})();
