(function() {
  'use strict';

  angular
    .module('app')
    .service('userDataService', userDataService);

  userDataService.$inject = [
    'config',
    '$http'
  ];

  function userDataService( config, $http ) {

    return {
      deleteUser: deleteUser,
      getUser: getUser,
      getUsers: getUsers,
      getUsersCount: getUsersCount,
      saveUser: saveUser,
      updateUser: updateUser,
      partialUpdateUser: partialUpdateUser
    };

    function deleteUser( entityId ) {
      return $http.delete(`${config.endpoints.user}/${entityId}`)
        .then(function( response ) {
          return { status: response.status, data: response.data };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.message };
        });
    }

    function getUser( entityId ) {
      return $http.get(`${config.endpoints.user}/${entityId}`)
        .then(function( response ) {
          return { status: response.status, data: response.data };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.message };
        });
    }

    function getUsers( params ) {
      return $http.get(config.endpoints.user, params)
        .then(function( response ) {
          return { status: response.status, data: response.data };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.message };
        });
    }

    function getUsersCount() {
      return $http.get(config.endpoints.user, { params: { count: true } })
        .then(function( response ) {
          return { status: response.status, data: response.data };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.message };
        });
    }

    function saveUser( data ) {
      return $http.post(config.endpoints.user, data)
        .then(function( response ) {
          return { status: response.status, data: response.data };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.errors };
        });
    }

    function updateUser( entityId, data ) {
      return $http.put(`${config.endpoints.user}/${entityId}`, data)
        .then(function( response ) {
          return { status: response.status, data: response.data };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.errors };
        });
    }

    function partialUpdateUser( entityId, data ) {
      return $http.patch(`${config.endpoints.user}/${entityId}`, data)
        .then(function( response ) {
          return { status: response.status, data: response.data };
        })
        .catch(function( error ) {
          return { status: error.status, message: error.data.errors };
        });
    }
  }
})();
