( function() {
  'use strict';

  angular
    .module( 'app.user' )
    .controller( 'UserResetPasswordController', UserResetPasswordController );

  UserResetPasswordController.$inject = [
    '$scope',
    '$stateParams',
    'userDataService',
  ];

  function UserResetPasswordController( $scope,
    $stateParams,
    userDataService ) {
    let vm = this;

    vm.password = null;
    vm.entityId = $stateParams.id;
    vm.isLoading = true;
    vm.onSubmit = onSubmit;

    init();

    function init() {
      if( vm.entityId ) {
        getUser();
      }
    }

    function getUser() {
      userDataService.getUser( vm.entityId ).then( function( response ) {
        vm.isLoading = false;
        if( response.status === 200 ) {
          vm.entity = response.data;
        }
        else {
          $scope.showNotification( 'The data could not be loaded', 'error' );
        }
      } );
    }

    function onSubmit( form ) {
      if( !form.$invalid ) {
        vm.isLoading = true;
        const promise = userDataService.partialUpdateUser( vm.entityId, {
          password: vm.password
        } );

        promise.then( function( response ) {
          if( response.status === 200 ) {
            vm.entity.id = response.data.id;
            vm.isLoading = false;
            $scope.showNotification( 'Data Saved' );
          } else {
            vm.isLoading = false;
            $scope.showNotification( 'The action could not be performed successfully', 'error' );
          }

        } )
      }
    }
  }
} )();
